import { PROFILE_INSTANCES, PROFILE_STAGES } from 'apps/dashboard/constants/buildYourProfile';
import { useNavigate } from 'react-router-dom';
import { getNewHEDUrl } from 'utils/links';

import Debt from './Debt';

// @ts-expect-error TS(7031): Binding element 'instance' implicitly has an 'any'... Remove this comment to see the full error message
const DebtSlider = ({ instance, noConfirm, updateStages, form, onDebtNext, initialEditConfig, isSingleLienEdit }) => {
  const navigate = useNavigate();

  const onBack = () => {
    if (instance === PROFILE_INSTANCES.EDIT_PROFILE && noConfirm) {
      return null;
    }

    if (instance === PROFILE_INSTANCES.EDIT_PROFILE && !noConfirm && isSingleLienEdit) {
      return () => updateStages(PROFILE_STAGES.EDIT_YOUR_PROFILE);
    }

    if (instance === PROFILE_INSTANCES.SETUP_PROFILE && isSingleLienEdit) {
      return () => updateStages(PROFILE_STAGES.DEBT_CONFIRMATION_STEP);
    }

    // Only the back button during the setup flow
    return () => updateStages(PROFILE_STAGES.DEBT_SELECTION_STEP);
  };

  const onNext = () => {
    // When editing a single lien, we only need to save and go back to the dashboard
    if (instance === PROFILE_INSTANCES.EDIT_PROFILE && isSingleLienEdit && noConfirm) {
      return () => navigate(getNewHEDUrl());
    }
    return onDebtNext;
  };

  return (
    <Debt
      onNext={initialEditConfig?.lienType ? onNext() : onDebtNext}
      onBack={onBack()}
      form={form}
      instance={instance}
    />
  );
};

export default DebtSlider;
