import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAsync } from '@hometap/htco-components';

import { buildNewHEDUrl } from 'utils/links';
import EquityRenovationDetails from './EquityRenovationDetails';
import EquityRenovationSelector from './EquityRenovationSelector';
import EquityRenovationGoals from './EquityRenovationGoals';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RenovationFinancingSection from './RenovationFinancingSection';
import Footer from 'components/Footer/Footer';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { saveRenovationToScenario } from './data/requests';
import { createRenovationFromTemplate, parseRenovationKind } from './data/renovationUtils';
import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import useRenovationTemplates from './hooks/useRenovationTemplates';
import useComputedState from 'hooks/useComputedState';
import { useCurrentHome } from 'hooks/useCurrentHome';
import './EquityRenovations.scss';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import useProgressBar from 'hooks/useProgressBar';

const VIEWS = {
  SELECTOR: 'selector',
  GOALS: 'goals',
  DETAILS: 'details',
};

// @ts-expect-error TS(7006): Parameter 'currentView' implicitly has an 'any' ty... Remove this comment to see the full error message
const getProgressData = (currentView, showOtherGoalQuestion) => {
  const viewsOrder = [VIEWS.SELECTOR, VIEWS.GOALS, VIEWS.GOALS, VIEWS.DETAILS];
  let currStep = viewsOrder.indexOf(currentView) + 1;
  const steps = viewsOrder.length;
  if (currentView === VIEWS.GOALS && showOtherGoalQuestion) {
    currStep += 1;
  }
  return { currStep, steps };
};

const EquityRenovationController = () => {
  const { project, renovationId } = useParams();
  const { showTappableEquityCard } = useFlags();
  const navigate = useNavigate();

  const [selectedGoal, setSelectedGoal] = useState('');
  const [otherGoalText, setOtherGoalText] = useState('');
  const [cardContainerHeight, setCardContainerHeight] = useState(0);
  const [showOtherGoalQuestion, setShowOtherGoalQuestion] = useState(false);
  const cardContainerRef = useRef(null);
  const selectorRef = useRef(null);
  const goalRef = useRef(null);
  const { createFutureScenario, setIsFutureView, changeScenarioRenovations, futureScenario, initialScenario } =
    useEquityScenarioContext();
  const { home } = useCurrentHome();
  const [currentView, setCurrentView] = useState(VIEWS.SELECTOR);
  const [renoType, setRenoType] = useState(null);

  const isSelectorView = !project;

  // @ts-expect-error TS(2345): Argument of type '{ currStep: number; steps: numbe... Remove this comment to see the full error message
  useProgressBar(getProgressData(currentView, showOtherGoalQuestion));

  useEffect(() => {
    if (project === VIEWS.GOALS && !isSelectorView && !renovationId) {
      setCurrentView(VIEWS.GOALS);
    } else if (project) {
      setCurrentView(VIEWS.DETAILS);
    } else {
      setCurrentView(VIEWS.SELECTOR);
    }
  }, [setCurrentView, isSelectorView, renovationId, project]);

  useEffect(() => {
    const calculateChildHeight = () => {
      if (currentView === VIEWS.SELECTOR && selectorRef.current) {
        // @ts-expect-error TS(2339): Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
        setCardContainerHeight(selectorRef.current.getBoundingClientRect().height);
      } else if (currentView === VIEWS.GOALS && goalRef.current) {
        // @ts-expect-error TS(2339): Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
        setCardContainerHeight(goalRef.current.getBoundingClientRect().height);
      }
    };

    setTimeout(() => {
      calculateChildHeight();
    }, 0);

    window.addEventListener('resize', calculateChildHeight);

    return () => {
      window.removeEventListener('resize', calculateChildHeight);
    };
  }, [currentView, cardContainerHeight, showOtherGoalQuestion]);

  const handleNavigate = useCallback(
    // @ts-expect-error TS(7006): Parameter 'renoType' implicitly has an 'any' type.
    renoType => {
      setRenoType(renoType);
      if (currentView === VIEWS.SELECTOR) {
        setCurrentView(VIEWS.GOALS);
        navigate('/dashboard/home-equity/renovations/goals');
      } else {
        setCurrentView(VIEWS.DETAILS);
        navigate(buildNewHEDUrl({ renoType }));
      }
    },
    [navigate, currentView],
  );

  // @ts-expect-error TS(7006): Parameter 'goal' implicitly has an 'any' type.
  const handleGoalSelection = (goal, otherText) => {
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    setIsFutureView(true);
    if (goal) {
      setSelectedGoal(goal);
    }
    if (otherText) {
      setOtherGoalText(otherText);
    }
    navigate(buildNewHEDUrl({ renoType }));
  };

  const { hasTappableEquity } = useAccessHomeEquityCTA();

  const {
    loading: isRenovationTemplatesLoading,
    error: renovationTemplatesError,
    getRenovationProjectOptions,
    getRenovationTemplatesByProject,
    matchTemplateForRenovation,
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
  } = useRenovationTemplates(home?.address?.state);
  const projectTemplates = useMemo(
    () => getRenovationTemplatesByProject(project),
    [getRenovationTemplatesByProject, project],
  );
  const [currentRenovation, setCurrentRenovation, resetCurrentRenovation] = useComputedState(() => {
    if (futureScenario && renovationId) {
      // @ts-expect-error TS(2339): Property 'renovations' does not exist on type '{}'... Remove this comment to see the full error message
      return futureScenario?.renovations?.find(({ id }) => id === renovationId);
    }

    let foundByProject;
    if (futureScenario && project) {
      // @ts-expect-error TS(2339): Property 'renovations' does not exist on type '{}'... Remove this comment to see the full error message
      foundByProject = futureScenario?.renovations?.find(
        // @ts-expect-error TS(7031): Binding element 'renovation_kind' implicitly has a... Remove this comment to see the full error message
        ({ renovation_kind }) => parseRenovationKind(renovation_kind).project.toLowerCase() === project.toLowerCase(),
      );
    }

    return foundByProject || (projectTemplates && createRenovationFromTemplate(projectTemplates[0]));
  }, [futureScenario, renovationId, project, projectTemplates]);

  const renovationProjectOptions = useMemo(
    () =>
      // @ts-expect-error TS(2339): Property 'renovations' does not exist on type '{}'... Remove this comment to see the full error message
      getRenovationProjectOptions(futureScenario?.renovations || initialScenario?.renovations || [], !!renovationId),
    // @ts-expect-error TS(2339): Property 'renovations' does not exist on type '{}'... Remove this comment to see the full error message
    [futureScenario?.renovations, getRenovationProjectOptions, renovationId, initialScenario?.renovations],
  );

  const selectedTemplate = useMemo(
    () => matchTemplateForRenovation(currentRenovation),
    [currentRenovation, matchTemplateForRenovation],
  );
  const setSelectedTemplate = useCallback(
    // @ts-expect-error TS(7006): Parameter 'template' implicitly has an 'any' type.
    template => setCurrentRenovation({ ...(currentRenovation || {}), ...createRenovationFromTemplate(template) }),
    [currentRenovation, setCurrentRenovation],
  );
  const handleRenoTypeChange = useCallback(
    // @ts-expect-error TS(7006): Parameter 'renoType' implicitly has an 'any' type.
    renoType => {
      resetCurrentRenovation();
      handleNavigate(renoType);
    },
    [handleNavigate, resetCurrentRenovation],
  );

  const saveRenovation = useCallback(async () => {
    const renoBody = { ...currentRenovation };

    if (selectedGoal) {
      renoBody.renovation_goal = selectedGoal;
    }
    if (otherGoalText) {
      renoBody.renovation_goal_other = otherGoalText;
    }

    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    if (!futureScenario?.id) {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
      await createFutureScenario(renoBody);
    } else {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const data = await saveRenovationToScenario(home.id, futureScenario?.id, renoBody);
      if (data) {
        // @ts-expect-error TS(2339): Property 'renovations' does not exist on type '{}'... Remove this comment to see the full error message
        const renosWithoutCurrent = futureScenario?.renovations?.filter(({ id }) => id !== data.id);
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        changeScenarioRenovations([...renosWithoutCurrent, data]);
      }
    }
  }, [
    currentRenovation,
    selectedGoal,
    otherGoalText,
    createFutureScenario,
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    home.id,
    futureScenario,
    changeScenarioRenovations,
  ]);

  const { execute: handleSaveRenovation, loading, error } = useAsync(saveRenovation, { executeThrow: true });

  return (
    <>
      {currentView === VIEWS.DETAILS ? (
        <PrimaryLayoutGrid className="EquityRenovation">
          <div className="EquityRenovationWrapper col-span-full">
            <EquityRenovationDetails
              project={project}
              currentRenovation={currentRenovation}
              renovationProjectOptions={renovationProjectOptions}
              projectTemplates={projectTemplates}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              renovationSaveError={error}
              renovationSaveLoading={loading}
              onChangeRenovation={handleRenoTypeChange}
              onSaveRenovation={handleSaveRenovation}
              selectedGoal={selectedGoal}
              // @ts-expect-error TS(2322): Type '{ project: string | undefined; currentRenova... Remove this comment to see the full error message
              otherGoalText={otherGoalText}
            />
          </div>
        </PrimaryLayoutGrid>
      ) : (
        // @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
        <PrimaryLayoutGrid>
          <div className="EquityRenovationCardContainer col-span-full" ref={cardContainerRef}>
            {currentView === VIEWS.SELECTOR && (
              <div className="EquityRenovationSlideCard">
                <div className="EquityRenovationWrapper" ref={selectorRef}>
                  <EquityRenovationSelector
                    isLoading={isRenovationTemplatesLoading}
                    error={renovationTemplatesError}
                    renovationProjectOptions={renovationProjectOptions}
                    onSelect={handleNavigate}
                  />
                </div>
              </div>
            )}

            {currentView === VIEWS.GOALS && (
              <div className="EquityRenovationSlideCard">
                <div className="EquityRenovationWrapper" ref={goalRef}>
                  <EquityRenovationGoals
                    onSelect={handleGoalSelection}
                    showOtherGoalQuestion={showOtherGoalQuestion}
                    setShowOtherGoalQuestion={setShowOtherGoalQuestion}
                  />
                </div>
              </div>
            )}
          </div>
        </PrimaryLayoutGrid>
      )}

      {currentView !== VIEWS.DETAILS && (
        // @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
        <PrimaryLayoutGrid>
          {/* @ts-expect-error TS(2741): Property 'theme' is missing in type '{ className: ... Remove this comment to see the full error message */}
          <Footer className="col-span-full" />
        </PrimaryLayoutGrid>
      )}

      {currentView === VIEWS.DETAILS && (
        <>
          {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
          <PrimaryLayoutGrid>
            <div className="col-span-full mt-6 text-[10px] italic leading-4 text-neutral-dark-75 mix-blend-normal sm:mt-8">
              Data source: Provided by Kukun © {new Date().getFullYear()}, a leading real estate data company. Kukun
              uses data about your home’s location and value to create a highly accurate cost estimate of the renovation
              types and ROI based on material type.
            </div>
          </PrimaryLayoutGrid>

          <div className="EquityRenovationSectionWrapper bg-blue-dark-100">
            <RenovationFinancingSection
              isEligibleForHEI={hasTappableEquity && showTappableEquityCard}
              onSaveRenovation={handleSaveRenovation}
            />

            {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
            <PrimaryLayoutGrid>
              <Footer theme="dark" className="col-span-full" />
            </PrimaryLayoutGrid>
          </div>
        </>
      )}
    </>
  );
};

export default EquityRenovationController;
