import { useCallback } from 'react';

import { Icon, Tooltip } from '@hometap/htco-components';
import HomeGraph from 'components/HomeGraph/HomeGraph';
import { percent } from 'utils/numbers';
import { convertDateStringToHumanReadable } from 'utils/date';
import { hometapPercentagesTerms, investmentTerms } from '../constants/originalTerms';
import { homeGraphFootnotesGeneral, homeGraphFootnotesLegendNotes } from '../constants/homeGraphFootnotesCopy';
import { makePercentFormatter } from 'components/HomeGraph/utils';

export const sharePercentageFormatter = makePercentFormatter(1, 3);

// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const TermsRow = ({ title, value, testId }) => (
  <div className="grid grid-cols-2 gap-4 border-0 border-b border-solid border-neutral-light-100 py-4 text-sm/[1.5] sm:text-base/[1.5]">
    <div>{title}</div>
    <div className="text-right font-bold" data-testid={testId}>
      {value}
    </div>
  </div>
);

// @ts-expect-error TS(7031): Binding element 'superscript' implicitly has an 'a... Remove this comment to see the full error message
const ShareLabel = ({ superscript, children }) => (
  <div className="flex items-center gap-1">
    {children}
    <div>
      <sub className="relative -top-[3px] align-super text-[8px]">{superscript}</sub>
    </div>
  </div>
);

// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const ShareLegendTitle = ({ title, superscript, tooltipContent }) => {
  return (
    <div className="flex flex-wrap items-center">
      <div className="flex gap-px">
        <ShareLabel superscript={superscript}>{title}</ShareLabel>
      </div>
      <Tooltip className="ml-2 normal-case" content={tooltipContent}>
        <Icon name="question-circle" />
      </Tooltip>
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'statement' implicitly has an 'any... Remove this comment to see the full error message
const QuarterlyStatementContent = ({ statement, shareFraction }) => {
  const isAppreciationPositive = Number(statement?.appreciation_percent) >= 0;
  const appreciationString = isAppreciationPositive ? 'increase' : 'decrease';

  const configureGraph = useCallback(
    () => ({
      container: {
        className: 'rounded-[12px] sm:rounded-[12px] sm:p-6 md:p-12 border border-solid border-blue-15',
      },

      heading: {
        title: {
          value: `Estimate as of ${convertDateStringToHumanReadable(statement.quarter.end)}`,
          className: 'text-xl/[1.5] sm:text-2xl/[1.5] mb-1',
        },
        subtitle: {
          value:
            'Keep in mind, this estimate is for informational purposes only — it might differ from the final values at settlement.',
          className: 'mb-8 text-[16px] leading-[26px]',
        },
      },

      legend: {
        badge: { formatter: sharePercentageFormatter },
        title: {
          value: <ShareLabel superscript="1">Home Value</ShareLabel>,
          className: 'text-sm sm:text-sm',
        },
        homeValue: {
          className: 'text-neutral-dark-100 text-[28px]/[1.5] sm:text-[38px]/[1.5]',
        },
        subheading: {
          value: statement?.appreciation_percent && (
            <>
              <Icon className="mr-0.5 mt-0.5" name={`arrow-${appreciationString}-2`} />
              <span>
                {percent(Math.abs(Number(statement?.appreciation_percent)))} {appreciationString} from beginning home
                value
              </span>
            </>
          ),
          className: 'text-xs/[1.5] flex',
        },
        labels: {
          className: 'text-sm sm:text-sm -mt-0.5',
        },
        values: {
          className: 'text-neutral-dark-100 text-xl/[1.5] sm:text-2xl/[1.5]',
        },
        helpText: {
          className: 'block text-xs/[1.5]',
        },
      },

      graphedShares: [
        {
          key: 'homeownerShare',
          legend: {
            title: (
              <ShareLegendTitle
                superscript="2"
                title="Your Share"
                tooltipContent="This is the portion of your home’s value that you own and will keep at settlement. It may include borrowed funds, such as mortgages or other debts on your home."
              />
            ),
          },
        },
        {
          key: 'hometapShare',
          legend: {
            title: (
              <ShareLegendTitle
                title="Hometap Share"
                superscript="3"
                tooltipContent="This is the portion of your home’s value that Hometap owns, and we’ll keep when you settle your Investment."
              />
            ),
          },
        },
      ],
    }),
    [statement?.appreciation_percent, appreciationString, statement?.quarter.end],
  );

  return (
    <div>
      <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-[fit-content(368px)_auto] md:grid-cols-[fit-content(448px)_auto]">
        <div className="rounded-[12px] border border-solid border-neutral-light-100 p-6 md:py-12">
          <h3>Investment terms</h3>
          {investmentTerms.map(term => (
            <TermsRow key={term.title} title={term.title} value={term.value(statement)} testId={term.testId} />
          ))}
          <div className="pb-4 pt-8">
            <div className="mb-2 text-sm/[1.5] uppercase tracking-[1px]">Hometap Percentage</div>
            <div className="pb-4 text-xs/[1.5] text-neutral-dark-75">
              This percentage is multiplied by the home’s current value to determine the Hometap Share. It’s lower if
              your home’s value decreases, and higher if your home’s value increases or stays the same.
            </div>
            <div className="text-xs/[18px] italic">If your current home value is...</div>
          </div>
          {hometapPercentagesTerms.map(term => (
            <TermsRow
              key={term.title(statement)}
              title={term.title(statement)}
              value={term.value(shareFraction)}
              testId={term.testId}
            />
          ))}
        </div>
        {statement && (
          <HomeGraph
            configOverrides={configureGraph}
            hometapShare={statement.hometapShare}
            homeownerShare={statement.homeownerShare}
            homeValue={statement.homeValue}
          />
        )}
      </div>
      <div className="text-xs/[1.5] text-neutral-dark-75">
        <ol className="my-6 ml-3 list-outside list-decimal">
          {homeGraphFootnotesLegendNotes.map((legendNote, i) => (
            <li key={`legend-note-${i}`} className="mb-2">
              {legendNote}
            </li>
          ))}
        </ol>
        {homeGraphFootnotesGeneral.map((footnote, i) => (
          <p key={`footnote-${i}`} className="mb-0">
            {footnote}
          </p>
        ))}
      </div>
    </div>
  );
};

export default QuarterlyStatementContent;
