import { ReactNode } from 'react';

const BuildProfileFlowLayout = ({
  stepImage,
  stepImageAlt,
  children,
}: {
  stepImage: string;
  stepImageAlt?: string;
  children: ReactNode;
}) => (
  <div
    className="flex flex-col gap-8 px-4 pb-20 pt-8 xs:px-10 sm:flex-row sm:gap-10 sm:px-18 sm:pb-26 sm:pt-12 md:gap-16 md:px-26"
    data-testid="debt-info"
  >
    <div className="flex-1">{children}</div>
    <div className="flex flex-1 items-center justify-center">
      <img className="w-full max-w-xl object-scale-down object-top sm:max-w-2xl" src={stepImage} alt={stepImageAlt} />
    </div>
  </div>
);

export default BuildProfileFlowLayout;
