import { useNavigate } from 'react-router-dom';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { useEffect } from 'react';

const useAppRedirect = () => {
  const navigate = useNavigate();
  // @ts-expect-error TS2339
  const { loading: loadingTracks, pendingTracks } = useUserTracksContext();
  // @ts-expect-error TS7006
  const unconfirmedUserSSN = pendingTracks?.find(track => track?.membership?.status === 'pending');

  useEffect(() => {
    if (unconfirmedUserSSN) {
      navigate(`/track/${unconfirmedUserSSN.id}/co-applicant/`, { replace: true, state: { unconfirmedUserSSN } });
    }
  }, [unconfirmedUserSSN, navigate, pendingTracks, loadingTracks]);
};

export default useAppRedirect;
