import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { NotFoundBlock } from '@hometap/htco-components';
import FAQList from 'components/FAQList/FAQList';
import Footer from 'components/Footer/Footer';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ModalPageLayoutLite from 'components/Layouts/ModalPageLayoutLite/ModalPageLayoutLite';
import { investmentStatementFAQs } from 'apps/dashboard/constants';
import QuarterlyStatementHeader from './components/QuarterlyStatementHeader';
import useInvestmentStatementPage, { useInvestmentStatementMarkAsViewed } from './hooks/useInvestmentStatementPage';
import QuarterlyStatementContent from './components/QuarterlyStatementContent';
import QuarterlyStatementResourcesSection from './components/QuarterlyStatementResourcesSection';
import { QUARTERLY_STATEMENTS_RESOURCES } from './constants/resources';
import { getYearsBetweenDates } from 'utils/date';
import useShareFractions from 'hooks/useShareFractions';
import { findShareFractionsByYearRange } from 'utils/shareFractions';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import useGoBackWithDefault from 'hooks/useGoBackWithDefault';

const QuarterlyStatementController = () => {
  const { investmentId, statementId } = useParams();
  // @ts-expect-error Object literal may only specify known properties, and 'defaultUrl' does not exist in type '{ replace?: boolean | undefined; }'.ts(2353)
  const { goBack } = useGoBackWithDefault({ defaultUrl: getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS) });

  const navigate = useNavigate();
  const { track, isPrimaryUser, statement, applicants, loading } = useInvestmentStatementPage(
    investmentId,
    statementId,
  );

  const { shareFractions } = useShareFractions(
    statement?.investment?.beginning_home_value,
    statement?.investment?.investment_amount,
    statement?.investment?.product_id,
  );

  const shareFractionYear = getYearsBetweenDates(
    statement?.home_valuation?.as_of_date,
    statement?.investment?.effective_date,
    true,
  );

  const shareFraction = findShareFractionsByYearRange(shareFractions, shareFractionYear);

  useInvestmentStatementMarkAsViewed(statement);

  const faqContents = useMemo(
    () =>
      investmentStatementFAQs.map(faq => {
        const faqContext = {
          documentsLink: track ? `/track/${track.id}/documents` : '#',
        };

        return typeof faq.answer === 'function' ? { ...faq, answer: faq.answer(faqContext) } : faq;
      }),
    [track],
  );
  const isNotFound = !loading && !track;

  const shouldRedirectToDocumentsPage = !statement && !loading && track;
  if (shouldRedirectToDocumentsPage) {
    return navigate(`/track/${track?.id}/documents`);
  }

  return (
    <ScrollToTop>
      {/* @ts-expect-error TS(2741): Property 'headerContent' is missing in type '{ chi... Remove this comment to see the full error message */}
      <ModalPageLayoutLite onModalClose={goBack}>
        {isNotFound ? (
          <NotFoundBlock />
        ) : (
          // @ts-expect-error TS(2739): Type '{ children: (false | Element)[]; loading: an... Remove this comment to see the full error message
          <SectionLoadingWrapper loading={loading} className="bg-white">
            <div className="PageBodyCenteredContainer pt-10">
              <QuarterlyStatementHeader
                homeAddress={track?.home.address}
                investmentId={track?.friendly_id}
                quarter={statement?.quarter}
                applicants={applicants}
                document={statement?.document}
              />
              <QuarterlyStatementContent
                statement={statement}
                // @ts-expect-error TS(2322): Type '{ statement: any; homeAddress: any; shareFra... Remove this comment to see the full error message
                homeAddress={track?.home.address}
                shareFraction={shareFraction}
              />
            </div>
            <div className="PageBodyBottomGradient">
              <div className="PageBodyCenteredContainer">
                <div className="py-20 pb-8 md:py-26 md:pb-14">
                  {/* @ts-expect-error TS(2322): Type '{ heading: string; faqs: ({ question: string... Remove this comment to see the full error message */}
                  <FAQList heading="Getting ready to settle?" faqs={faqContents} />
                </div>
                {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ theme: ... Remove this comment to see the full error message */}
                {!isPrimaryUser && <Footer theme="light" />}
              </div>
            </div>
            {isPrimaryUser && (
              <div className="bg-blue-dark-100">
                {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
                <PrimaryLayoutGrid>
                  <div className="col-span-full">
                    <QuarterlyStatementResourcesSection
                      title="Additional resources for you"
                      subTitle="Explore calculators, maintenance tips, and more."
                      // @ts-expect-error TS(2322): Type '({ image: any; title: string; subTitle: stri... Remove this comment to see the full error message
                      resources={QUARTERLY_STATEMENTS_RESOURCES}
                      investmentId={statement?.investment.id}
                    />
                    {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ theme: ... Remove this comment to see the full error message */}
                    <Footer theme="dark" />
                  </div>
                </PrimaryLayoutGrid>
              </div>
            )}
          </SectionLoadingWrapper>
        )}
      </ModalPageLayoutLite>
    </ScrollToTop>
  );
};

export default QuarterlyStatementController;
