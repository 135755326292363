import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import sentry from 'utils/sentry';
import { useMutationObserver } from './useMutationObserver';
import useCurrentUser from './useCurrentUser';
import { debounce } from 'lodash';

/**
 * useGliaIdentification - identify user for glia, it will assign the current user's email as externalId
 * @returns
 */
export const useGliaIdentification = () => {
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const [gliaStatus, setGliaStatus] = useState();
  // @ts-expect-error TS(2339): Property 'sm' does not exist on type 'typeof globa... Remove this comment to see the full error message
  const { sm } = global;

  useEffect(() => {
    if (sm?.getApi && user) {
      // @ts-expect-error TS(7006): Parameter 'glia' implicitly has an 'any' type.
      sm.getApi({ version: 'v1' }).then(function (glia) {
        glia
          .updateInformation({
            externalId: user.email.toLowerCase(),
          })
          .then(() => {
            // @ts-expect-error TS(2345): Argument of type '"success"' is not assignable to ... Remove this comment to see the full error message
            setGliaStatus('success');
          })
          // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
          .catch(e => {
            console.error('Error updating information for Glia', e);
            // @ts-expect-error TS(2345): Argument of type '"error"' is not assignable to pa... Remove this comment to see the full error message
            setGliaStatus('error');
          });
      });
    }
  }, [sm, user]);

  return gliaStatus;
};

/**
 * useEnableGlia - hook to enable a certain page to show on Glia cobrowsing/live session
 * @param {boolean} enable - is Glia enabled for this page?
 * @param {boolean} interactive - is the page interactive for co-browsing session?
 * @returns
 */
export const useEnableGlia = (enable = false, interactive = true) => {
  useEffect(() => {
    // Had to set the class on #App container as we're using bodyAttributes to set light/dark theme for the page
    const appContainer = document.querySelector('#App');
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    appContainer.classList.toggle('sm_cobrowsing_hidden_field', !enable); // !enable because we hide the class when we want to enable Glia
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    appContainer.classList.toggle('sm_cobrowsing_disabled_field', !interactive);

    return () => {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      appContainer.classList.toggle('sm_cobrowsing_hidden_field', true); // By default it should have the class, so when we unmount we add the class back
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      appContainer.classList.toggle('sm_cobrowsing_disabled_field', false);
    };
  }, [enable, interactive]);

  return [enable, interactive];
};

const GLIA_VISIBLE_PATHS = [
  '/track/:trackId/application',
  '/track/:trackId',
  '/dashboard/investments',
  '/rundown/:id',
  '/offer/:id?',
  '/dashboard/overview',
];

// these paths are visible and also interactive through cobrowsing
const GLIA_ENABLED_PATHS = ['/track/:trackId', '/rundown/:id', '/offer/:id?', '/dashboard/overview'];

// some useful constants
const MISSING_HIDDEN_GLIA_CLASS = 'Missing Glia class: sm_cobrowsing_hidden_field';
const MISSING_DISABLED_GLIA_CLASS = 'Missing Glia class: sm_cobrowsing_disabled_field';
const GLIA_HIDDEN_CLASS = 'sm_cobrowsing_hidden_field';
const GLIA_DISABLED_CLASS = 'sm_cobrowsing_disabled_field';
const APP_CONTAINER_SELECTOR = '#App';
const DISABLED_FIELDS_ELEMENTS_SELECTORS = [
  '#application-form-continue-button',
  '.TrackDetailsTodoCardCTA',
  '#offer-banner',
  '#accept-offer-modal',
];

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const logError = (message, additionalData) => {
  console.warn(message, additionalData);
  sentry.logError(message, additionalData);
};

const longDebouncedLogError = debounce(logError, 5000);
const shortDebouncedLogError = debounce(logError, 100);

export const useLogGliaMissingClassToSentry = () => {
  const { pathname } = useLocation();

  const testElementsHaveGliaClass = useCallback(
    // @ts-expect-error TS(7006): Parameter 'element' implicitly has an 'any' type.
    element => {
      const matchesVisiblePath = GLIA_VISIBLE_PATHS.some(path => !!matchPath(path, pathname));
      const matchesEnabledPath = GLIA_ENABLED_PATHS.some(path => !!matchPath(path, pathname));

      const isAppContainer = element?.matches?.(APP_CONTAINER_SELECTOR);
      const hasGliaHiddenClass = element?.classList?.contains(GLIA_HIDDEN_CLASS);
      const hasGliaDisabledClass = element?.classList?.contains(GLIA_DISABLED_CLASS);

      if (isAppContainer) {
        // if it's not a page that should be visible, it should have the hidden class
        if (!matchesVisiblePath && !hasGliaHiddenClass) {
          longDebouncedLogError(MISSING_HIDDEN_GLIA_CLASS, { pathname, element });
          return;
        }

        // if the page should be visible but not interactive, it should have the disabled class
        if (matchesVisiblePath && !matchesEnabledPath && !hasGliaDisabledClass) {
          longDebouncedLogError(MISSING_DISABLED_GLIA_CLASS, { pathname, element });
          return;
        }

        // cancel call to logError if everything's fine with appcontainer
        longDebouncedLogError.cancel();
      }

      // then check for individual elements that should be disabled
      const isElementThatShouldBeGliaDisabled = DISABLED_FIELDS_ELEMENTS_SELECTORS.some(
        selector => element?.matches?.(selector),
      );
      if (isElementThatShouldBeGliaDisabled && !hasGliaDisabledClass) {
        shortDebouncedLogError(MISSING_DISABLED_GLIA_CLASS, { pathname, element });
      }
    },
    [pathname],
  );

  // check on initial render
  useEffect(() => {
    const allElementsToTest = document.querySelectorAll(
      [APP_CONTAINER_SELECTOR, ...DISABLED_FIELDS_ELEMENTS_SELECTORS].join(', '),
    );
    allElementsToTest.forEach(testElementsHaveGliaClass);
  }, [testElementsHaveGliaClass]);

  // in case any changes are made to the DOM, we want to re-check
  const mutationRef = useRef(document.body);
  // @ts-expect-error TS(7006): Parameter 'mutations' implicitly has an 'any' type... Remove this comment to see the full error message
  const onMutation = mutations => {
    // @ts-expect-error TS(7006): Parameter 'mutation' implicitly has an 'any' type.
    mutations.forEach(mutation => {
      const { target } = mutation;
      testElementsHaveGliaClass(target);
    });
  };
  useMutationObserver(mutationRef, onMutation);
};
