import ResultsController from './controllers/ResultController';
import PageSEO from '../components/PageSEO';
import Layout from '../components/Layout';

const FitQuizResults = () => {
  return (
    <PageSEO
      description="View your fit quiz results!"
      title="Fit Quiz Results"
      opengraphTitle="Hometap | Is Hometap right for me?"
      opengraphDescription="View your fit quiz results!"
    >
      {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
      <Layout>
        <ResultsController />
      </Layout>
    </PageSEO>
  );
};

export default FitQuizResults;
