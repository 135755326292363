import { Icon, Tooltip } from '@hometap/htco-components';
import './PricingMatrixValue.scss';

// @ts-expect-error TS(7031): Binding element 'valueData' implicitly has an 'any... Remove this comment to see the full error message
const PricingMatrixValue = ({ valueData }) => {
  const { value, tooltip } = valueData;
  return (
    <span className="PricingMatrixValue">
      {value || '---'}{' '}
      {tooltip && (
        <span>
          <Tooltip action="hover" content={tooltip}>
            <Icon name="info" />
          </Tooltip>
        </span>
      )}
    </span>
  );
};

export default PricingMatrixValue;
