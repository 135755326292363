import { useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import apiWithAuth from 'utils/api';
import useCurrentUser from 'hooks/useCurrentUser';
import useTrack from 'hooks/useTrack';
import useApplicants from 'apps/application/hooks/useApplicants';
// @ts-expect-error TS(7031): Binding element 'queryKey' implicitly has an 'any'... Remove this comment to see the full error message
const fetchTrackDocuments = async ({ queryKey }) => {
  const [, trackId] = queryKey;
  return await apiWithAuth.v2.get(`/tracks/${trackId}/documents`);
};

// @ts-expect-error TS(7031): Binding element 'propTrackId' implicitly has an 'a... Remove this comment to see the full error message
const useTrackDocuments = ({ trackId: propTrackId, fetchImmediately = false }) => {
  const { trackId: paramsTrackId } = useParams();
  const trackId = propTrackId || paramsTrackId;

  if (!trackId) {
    throw new Error('trackId is required for useTrackDocuments to work correctly');
  }

  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user, isClient } = useCurrentUser();
  const { track } = useTrack();
  const { applicants = [], executeFetchApplicants, ...applicantsAsyncState } = useApplicants();

  const {
    data: results = [],
    isLoading: isDocumentsLoading,
    error,
    refetch: executeFetchTrackDocuments,
  } = useQuery({
    queryKey: ['track.documents', trackId],
    queryFn: fetchTrackDocuments,
    enabled: (!!user && isClient) || fetchImmediately,
  });

  // @ts-expect-error TS(2551): Property 'loading' does not exist on type '{ isLoa... Remove this comment to see the full error message
  const isLoading = isDocumentsLoading || applicantsAsyncState.loading;

  useEffect(() => {
    if (track?.active_application_id) {
      executeFetchApplicants({ applicationId: track.active_application_id });
    }
  }, [track?.active_application_id, executeFetchApplicants]);

  const getApplicantFromDocumentContext = useCallback(
    // @ts-expect-error TS(7006): Parameter 'document' implicitly has an 'any' type.
    document => {
      // @ts-expect-error TS(7006): Parameter 'applicant' implicitly has an 'any' type... Remove this comment to see the full error message
      return applicants.find(applicant =>
        // @ts-expect-error TS(7006): Parameter 'context' implicitly has an 'any' type.
        (document?.contexts || []).some(context => context.context_id === applicant.person_id),
      );
    },
    [applicants],
  );

  // @ts-expect-error TS(7031): Binding element 'createdBy' implicitly has an 'any... Remove this comment to see the full error message
  const getCreatedByDisplayName = useCallback(({ createdBy, isShared }) => {
    if (!isShared) return 'You';
    return createdBy?.user_type === 'client' ? createdBy.full_name : 'Hometap team';
  }, []);

  const documents = useMemo(() => {
    // @ts-expect-error TS(2339): Property 'map' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
    return results.map(document => {
      const isShared = document.created_by?.id !== user?.id;
      const createdByDisplayName = getCreatedByDisplayName({ createdBy: document.created_by, isShared });

      return {
        ...document,
        isShared,
        createdByDisplayName,
        uploadedFor: {
          applicant: getApplicantFromDocumentContext(document),
        },
      };
    });
  }, [results, user, getCreatedByDisplayName, getApplicantFromDocumentContext]);

  // @ts-expect-error TS(7031): Binding element 'is_unread_by_homeowner' implicitl... Remove this comment to see the full error message
  const hasUnreadDocs = documents.some(({ is_unread_by_homeowner }) => is_unread_by_homeowner);
  return {
    error,
    documents,
    isLoading,
    hasUnreadDocs,
    executeFetchTrackDocuments,
  };
};

export default useTrackDocuments;
