import './InquiryFormRow.scss';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const InquiryFormRow = ({ children }) => {
  return (
    <div className={'InquiryDQRow'} data-testid="dq-row">
      {children}
    </div>
  );
};
export default InquiryFormRow;
