import { COOKIES, STAGES } from 'apps/dashboard/constants';
import { getNewInquiryUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { useCookies } from 'react-cookie';
import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import { useNavigate } from 'react-router-dom';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { useEffect, useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { useCurrentHome } from 'hooks/useCurrentHome';

const useAccessHomeEquityCTA = (allowInvestedUsers = true) => {
  const [, setCookie] = useCookies();
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  const { setSessionPropertyData } = useInitialInquiryData();
  // @ts-expect-error TS(2339): Property 'tracks' does not exist on type '{}'.
  const { tracks = [] } = useUserTracksContext();
  const navigate = useNavigate();
  const { showTappableEquityCard, accessEquityHeaderCta } = useFlags();
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const { home } = useCurrentHome();
  const { prequalRange } = useHedPrequalRange();

  const [hasTappableEquity, setHasTappableEquity] = useState(false);
  const [showCtaForInvestedUser, setShowCtaForInvestedUser] = useState(false);

  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const currentHomeTracks = tracks?.filter(track => track?.home?.id === home?.id);

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'operationalState' does not exist on type... Remove this comment to see the full error message
    if (!showTappableEquityCard || !home?.operationalState) {
      setHasTappableEquity(false);
      return;
    }
    const tappableEquityCardTracks = currentHomeTracks?.every(
      // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
      track =>
        track?.current_stage === STAGES.SETTLED ||
        track?.current_stage === STAGES.OFFER_DECLINED ||
        track?.current_stage === STAGES.CLOSED_LOST,
    );

    // If a user is invested, we want to show the CTA and send them to Re-Inquiry
    // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
    const isInvested = currentHomeTracks?.some(track => track?.current_stage === STAGES.INVESTED);
    if (allowInvestedUsers && isInvested) {
      setShowCtaForInvestedUser(true);
      return;
    }

    if (!currentHomeTracks?.length || tappableEquityCardTracks) {
      // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
      if (prequalRange?.max || 0) {
        setHasTappableEquity(true);
        return;
      }
    }
    setShowCtaForInvestedUser(false);
    setHasTappableEquity(false);
  }, [allowInvestedUsers, showTappableEquityCard, home, currentHomeTracks, prequalRange]);

  const showAccessEquityCta = accessEquityHeaderCta && (hasTappableEquity || showCtaForInvestedUser);

  const openAccessEquityWindow = () => {
    // simplify original logic and just send users with tracks to investments tab
    if (currentHomeTracks?.length) {
      navigate(`/${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.INVESTMENTS}`);
    }
    const ctaLink = showCtaForInvestedUser ? getNewInquiryUrl() : 'https://hometap.com/compare/hei-vs-loans';
    if (showAccessEquityCta) {
      const inquirySessionData = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
        street: home?.address.street,
        // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
        unit: home?.address.unit,
        // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
        city: home?.address.city,
        // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
        state: home?.address.state,
        // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
        zip_code: home?.address.zip_code,
      };
      setCookie(COOKIES.DISABLE_PROPERTY_MODAL, true, { path: '/' });
      setSessionPropertyData(inquirySessionData);
      window.open(ctaLink, '_blank');
    }
  };

  return {
    hasTappableEquity,
    showAccessEquityCta,
    // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
    tappableEquity: prequalRange?.max,
    openAccessEquityWindow,
  };
};

export default useAccessHomeEquityCTA;
