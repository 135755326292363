import { Button, Dropdown, useForm } from '@hometap/htco-components';
import { twJoin } from 'tailwind-merge';

const EquityViewSettingsForm = ({
  // @ts-expect-error TS(7031): Binding element 'chartForecast' implicitly has an ... Remove this comment to see the full error message
  chartForecast,
  // @ts-expect-error TS(7031): Binding element 'currentAppreciationRate' implicit... Remove this comment to see the full error message
  currentAppreciationRate,
  // @ts-expect-error TS(7031): Binding element 'forecastOptions' implicitly has a... Remove this comment to see the full error message
  forecastOptions,
  // @ts-expect-error TS(7031): Binding element 'appreciationRateOptions' implicit... Remove this comment to see the full error message
  appreciationRateOptions,
  // @ts-expect-error TS(7031): Binding element 'isInline' implicitly has an 'any'... Remove this comment to see the full error message
  isInline,
  // @ts-expect-error TS(7031): Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
  onSubmit,
}) => {
  const { handleSubmit, registerField } = useForm({ chartForecast, currentAppreciationRate });

  const chartForecastField = registerField('chartForecast');
  const currentAppreciationRateField = registerField('currentAppreciationRate');

  const chartForecastOnChange = isInline
    ? // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
      (value, name) => {
        chartForecastField.onChange(value, name);
        onSubmit({ chartForecast: value, currentAppreciationRate });
      }
    : chartForecastField.onChange;

  const currentAppreciationRateOnChange = isInline
    ? // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
      (value, name) => {
        currentAppreciationRateField.onChange(value, name);
        onSubmit({ chartForecast, currentAppreciationRate: value });
      }
    : currentAppreciationRateField.onChange;

  return (
    <form
      className={twJoin('flex gap-[16px]', isInline ? 'align-center' : 'align-stretch w-full flex-col xs:w-[329px]')}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="equity-view-settings-form"
    >
      <Dropdown
        {...chartForecastField}
        onChange={chartForecastOnChange}
        label="Forecast"
        options={forecastOptions}
        fullWidth
      />
      <Dropdown
        {...currentAppreciationRateField}
        onChange={currentAppreciationRateOnChange}
        label="Appreciation"
        options={appreciationRateOptions}
        fullWidth
      />
      {!isInline && <Button type="submit">Update chart</Button>}
    </form>
  );
};

export default EquityViewSettingsForm;
