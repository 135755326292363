import { getPortalsUrl } from './links';
// Cypress makes it difficult to test javascript redirects
// https://docs.cypress.io/guides/guides/web-security.html#JavaScript-Redirects
// So this lets us stub and test window functions
// @ts-expect-error TS(2339): Property 'api' does not exist on type 'Window & ty... Remove this comment to see the full error message
window.api = {
  // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
  redirect: url => {
    window.location.replace(url);
  },
};

// @ts-expect-error TS(2339): Property 'htap' does not exist on type 'Window & t... Remove this comment to see the full error message
window.htap = {
  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  redirectToTrackInnerIndex: track => {
    window.location.assign(getPortalsUrl(`/track/${track.id}`));
    return null;
  },
  // @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
  redirectToTrackStage: (trackId, stagePath = '') => {
    window.location.assign(getPortalsUrl(`/track/${trackId}/${stagePath}`));
    return null;
  },
  // @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
  redirectToReInquiryReview: key => {
    window.location.assign(`${window.location.origin}/re-inquiry/${key}`);
    return null;
  },

  // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
  openNewTab: url => {
    window.open(url, '_blank');
    return null;
  },
};
