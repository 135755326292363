import { Container } from '@hometap/htco-components';
import ProgressLoadingModal from 'components/modals/ProgressLoadingModal/ProgressLoadingModal';

// NOTE: A wrapper to the dashboard pages
// which prepend a flash loader to it.
// All <div /> is for dirty placeholders.

const loadingModalConfig = {
  title: 'Preparing home value...',
  footer:
    'Over 50,000 homeowners like you have taken the first step towards getting more from homeownership with Hometap!',
  spinners: [
    { label: 'Analyzing your property' },
    { label: 'Crunching the numbers' },
    { label: 'Working our magic', async: true },
  ],
};

const { title, footer, spinners } = loadingModalConfig;

// @ts-expect-error TS(7031): Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
const PlannerProtectedFlash = ({ data, onFinish }) => {
  return (
    <Container className="PlannerContainer DashboardFlashPage">
      <ProgressLoadingModal
        show={true}
        title={title}
        footer={footer}
        spinners={spinners}
        data={data}
        onFinish={onFinish}
      />
      <div className="header">
        <div />
        <div />
      </div>
      <div className="body">
        <div className="left">
          <div />
          <div>
            <div />
            <div />
            <div />
            <div />
          </div>
          <div />
          <div />
        </div>
        <div className="right">
          <div />
        </div>
      </div>
    </Container>
  );
};

export default PlannerProtectedFlash;
