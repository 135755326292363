import { Icon, Tooltip } from '@hometap/htco-components';

const KeyValue = ({
  // @ts-expect-error TS(7031): Binding element 'keyName' implicitly has an 'any' ... Remove this comment to see the full error message
  keyName,
  separator = ':',
  // @ts-expect-error TS(7031): Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  value,
  // @ts-expect-error TS(7031): Binding element 'valueClassName' implicitly has an... Remove this comment to see the full error message
  valueClassName,
  // @ts-expect-error TS(7031): Binding element 'keyClassName' implicitly has an '... Remove this comment to see the full error message
  keyClassName,
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(7031): Binding element 'testId' implicitly has an 'any' t... Remove this comment to see the full error message
  testId,
  noValueDisplay = '--',
  // @ts-expect-error TS(7031): Binding element 'toolTipContent' implicitly has an... Remove this comment to see the full error message
  toolTipContent,
  // @ts-expect-error TS(7031): Binding element 'toolTipIcon' implicitly has an 'a... Remove this comment to see the full error message
  toolTipIcon,
  // @ts-expect-error TS(7031): Binding element 'toolTipClassName' implicitly has ... Remove this comment to see the full error message
  toolTipClassName,
}) => {
  return (
    <div className={className} data-testid={testId}>
      <span className={keyClassName}>
        {keyName}
        {separator}
      </span>{' '}
      <span className={valueClassName}>{value ? value : noValueDisplay}</span>
      {toolTipContent && (
        <Tooltip className={toolTipClassName} content={toolTipContent}>
          <Icon name={toolTipIcon} />
        </Tooltip>
      )}
    </div>
  );
};

export default KeyValue;
