import { useEffect } from 'react';

import { Button, Icon } from '@hometap/htco-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OnboardingLayout, CallToAction, Panel } from '../template/OnboardingTemplate';
import phones from 'images/equity-planner/onboarding/onboarding-welcome.png';
import './OnboardingWelcome.scss';
import cx from 'classnames';
import { browserIdentify, browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';

// @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
const OnboardingWelcome = ({ onNext }) => {
  const flags = useFlags();
  const { isonboardingCtaHigher } = flags;

  useEffect(() => {
    if (!Object.keys(flags).length) return;

    browserIdentify(undefined, {
      flags,
    });
    if (flags.isonboardingCtaHigher) {
      browserTrack.onboardingCTAHigher({ version: getVersion() });
    }
  }, [flags]);

  return (
    <div className="OnboardingWelcome" data-testid="welcome-page">
      <OnboardingLayout>
        {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
        <Panel reducePadding>
          <h1>
            Protect, grow, and take control of your largest asset: <span className="GreenText">your home</span>
          </h1>
          <div className="OnboardingWelcomePanelBodyText" data-testid="subtitle">
            The Home Equity Dashboard has the tools and resources you need to make smarter financial decisions and make
            the most of homeownership.
          </div>
          {isonboardingCtaHigher && (
            <div className={cx('OnboardingPageCTA', { isCTAHigher: true })}>
              <Button onClick={onNext} data-testid="next-button">
                Let’s get started
              </Button>
            </div>
          )}
          <div>
            <div className="OnboardingWelcomeIconGroup" data-testid="icon-group">
              <Icon className="OnboardingWelcomeIcon" name="icon-house-value" color="#366CED" data-testid="icon-1" />
              <span>Get an accurate, real-time home value estimate that’s updated every 90 days</span>
            </div>
            <div className="OnboardingWelcomeIconGroup">
              <Icon
                className="OnboardingWelcomeIcon"
                name="icon-normal-appreciation"
                color="#366CED"
                data-testid="icon-4"
              />
              <span>Keep track of your home’s value and equity — today and up to 10 years down the road</span>
            </div>
            <div className="OnboardingWelcomeIconGroup">
              <Icon className="OnboardingWelcomeIcon" name="icon-lightbulb" color="#366CED" data-testid="icon-2" />
              <span>Receive insights and tips, customized to your home and financial situation</span>
            </div>
          </div>

          <div className="OnboardingWelcomeIconGroup">
            <Icon className="OnboardingWelcomeIcon" name="clock" color="#366CED" data-testid="icon-3" />
            <span>
              <b>Sign up for free in 2 minutes</b>
            </span>
          </div>
          {isonboardingCtaHigher ? (
            <div className="OnboardingWelcomeDivider" />
          ) : (
            <CallToAction>
              <Button onClick={onNext} data-testid="next-button">
                Let’s get started
              </Button>
            </CallToAction>
          )}
        </Panel>
        {/* @ts-expect-error TS(2739): Type '{ children: Element; containsVisual: true; }... Remove this comment to see the full error message */}
        <Panel containsVisual>
          <img src={phones} alt="A sample of the application on a mobile phone" data-testid="panel-image" />
        </Panel>
      </OnboardingLayout>
    </div>
  );
};

export default OnboardingWelcome;
