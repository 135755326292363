import { Button, NotFoundBlock } from '@hometap/htco-components';
import CardWrapper from 'components/CardWrapper';
import Footer from 'components/Footer/Footer';
import TextHighlighter from 'components/TextHighlighter';

const FriendBuy404 = () => {
  const title = <TextHighlighter text="Our referral program has been discontinued" highlight="referral program" />;
  return (
    <div className="PageBodyCenteredContainer">
      {/* @ts-expect-error Property 'testId' is missing in type '{ children: Element; className: string; }' but required in type '{ children: any; className: any; testId: any; }' */}
      <CardWrapper className="mb-26 mt-12">
        <NotFoundBlock error="" buttonLink="https://hometap.com/" title={title} buttonLabel="Head on home">
          <span className="mt-4 block">
            For any questions about the program, please reach out to{' '}
            <Button theme="link" href="mailto:hello@hometap.com">
              hello@hometap.com
            </Button>
          </span>
        </NotFoundBlock>
      </CardWrapper>
      {/* @ts-expect-error  Type '{}' is missing the following properties from type... */}
      <Footer />
    </div>
  );
};

export default FriendBuy404;
