import { useContext, createContext, useEffect, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useLocalState from 'apps/htdc/utils/useLocalState';
import useCurrentUser from './useCurrentUser';
import { fetchHomes, patchHome } from 'apps/dashboard/data';
import useStates from './useStates';
import { USER_ROLES } from 'utils/globalConstants';
import { addNewProperty } from 'apps/dashboard/data';

const CurrentHomeContext = createContext({
  home: null,
  userCanAccessHome: false,
});

export const useAddPropertyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNewProperty,
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ['user.homes'] });
    },
  });
};

export const useCurrentHome = () => useContext(CurrentHomeContext);
export const useUserHomes = () => {
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user, isClient } = useCurrentUser();
  return useQuery({
    queryFn: fetchHomes,
    queryKey: ['user.homes'],
    enabled: !!user && isClient,
  });
};

export const useCurrentHomeMutation = () => {
  const { home } = useCurrentHome();
  const queryClient = useQueryClient();

  return useMutation({
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    mutationFn: data => patchHome(home.id, data),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['user.homes'] });
    },
  });
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const CurrentHomeProvider = ({ children }) => {
  const [currentHomeId, setCurrentHomeId] = useLocalState(null, 'selectedHomeId');
  const { data: homes, isLoading, isRefetching } = useUserHomes();
  const { operationalStatesList } = useStates();

  useEffect(() => {
    if (isLoading || isRefetching || !homes) return;
    const selectedHome = selectBestHomeForId(homes, currentHomeId) || getDefaultHome(homes);
    if (selectedHome && selectedHome.id !== currentHomeId) {
      setCurrentHomeId(selectedHome.id);
    }
  }, [homes, currentHomeId, isLoading, isRefetching, setCurrentHomeId]);

  const home = useMemo(() => {
    // @ts-expect-error TS(2339): Property 'find' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
    const selectedHome = homes?.find(h => h.id === currentHomeId);
    if (!selectedHome) return null;
    return {
      ...selectedHome,
      operationalState: getOperationalStateForHome(operationalStatesList, selectedHome),
    };
  }, [homes, currentHomeId, operationalStatesList]);

  // @ts-expect-error TS(7031): Binding element 'home' implicitly has an 'any' typ... Remove this comment to see the full error message
  const hasHEDAccessToCurrentHome = ({ home, userCanAccessHome }) => {
    if (!home) {
      return false;
    }
    return userCanAccessHome;
  };

  const userCanAccessHome = userHasAccessToHome(home);

  const contextData = {
    home,
    setHomeById: setCurrentHomeId,
    userCanAccessHome,
    loading: isLoading,
    hasHEDAccessToCurrentHome: hasHEDAccessToCurrentHome({ home, userCanAccessHome }),
  };
  return <CurrentHomeContext.Provider value={contextData}>{children}</CurrentHomeContext.Provider>;
};

// @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
const userHasPrimaryMembershipToTrack = home =>
  // @ts-expect-error TS(7031): Binding element 'role' implicitly has an 'any' typ... Remove this comment to see the full error message
  !!home?.track_memberships?.find(({ role }) => role === USER_ROLES.PRIMARY);
// @ts-expect-error TS(7006): Parameter 'business_key' implicitly has an 'any' t... Remove this comment to see the full error message
const businessKeyMatches = business_key => home => home?.address.business_key === business_key;

/*
 * Given a "Selected Home ID" and a list of homes, validate that the selected home is present and
 * return the best-fit home from the list for that address.
 *
 * If a user has two homes for the same address, prioritize the one with a Primary Track membership
 */
// @ts-expect-error TS(7006): Parameter 'homes' implicitly has an 'any' type.
const selectBestHomeForId = (homes, homeId) => {
  // @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
  const homeForId = homes.find(home => home.id === homeId);
  const prioritizedHomeForAddress = homes
    .filter(userHasPrimaryMembershipToTrack)
    .find(businessKeyMatches(homeForId?.address.business_key));
  return prioritizedHomeForAddress || homeForId || null;
};

// @ts-expect-error TS(7006): Parameter 'homes' implicitly has an 'any' type.
const getDefaultHome = homes => (homes?.length > 0 ? homes[0] : null);

// @ts-expect-error TS(7006): Parameter 'operationalStates' implicitly has an 'a... Remove this comment to see the full error message
const getOperationalStateForHome = (operationalStates, home) => {
  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  return operationalStates?.find(state => state.abbreviation === home?.address.state) || null;
};

// @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
const userHasAccessToHome = home => userHasPrimaryMembershipToTrack(home) || !home?.track_memberships?.length;
