// --------------------------------
// OTHER OPTIONS CONSIDERED
// --------------------------------
const WHAT = 'What is this?';
const CHECKBOX_HELP = {
  home_equity_loan: {
    title: WHAT,
    text: `A home equity loan is a second mortgage that allows you to borrow against the equity 
    you already have in your home, using your house as collateral. You receive a lump sum of
    cash from a lender upfront and repay it over time with fixed monthly payments.`,
  },
  heloc: {
    title: WHAT,
    text: `With a home equity line of credit, you receive approval for a maximum amount of 
    money available to borrow based on your equity, and you borrow what you need. You likely 
    make interest-only payments during a draw period before you’re required to make larger amortizing payments.`,
  },
  personal_loan: {
    title: WHAT,
    text: `A personal loan offers a lump sum of cash without collateral. The lender will grant the 
    loan amount based on your income and credit history.`,
  },
  reverse_mortgate: {
    title: WHAT,
    text: `Typically geared at homeowners over 62, a reverse mortgage allows you to borrow 
    from your home equity without monthly payments. The loan is paid back when you sell the home or pass away.`,
  },
  refinance: {
    title: WHAT,
    text: `A cash-out refinance is a new mortgage that replaces a homeowner’s existing mortgage, 
    for a greater amount than the homeowner owes on the property. The difference is given to the
    homeowner in cash.`,
  },
  other_investor: {
    title: WHAT,
    text: `Similar to Hometap, other equity investors are businesses that provide homeowners 
    cash in exchange for a stake in their property without a loan. They may offer different terms than Hometap, 
    such as a share of the appreciation instead of a share in the home value.`,
  },
};

// -------------------------------------
// INVESTMENT ALTERNATIVES CONSIDERED
// -------------------------------------
export const ALTERNATIVE_OPTIONS = [
  { label: 'Home equity loan', value: 'home equity loan', helptip: CHECKBOX_HELP.home_equity_loan },
  { label: 'Home equity line of credit (HELOC)', value: 'heloc', helptip: CHECKBOX_HELP.heloc },
  { label: 'Personal loan', value: 'personal loan', helptip: CHECKBOX_HELP.personal_loan },
  { label: 'Reverse mortgage', value: 'reverse mortgage', helptip: CHECKBOX_HELP.reverse_mortgate },
  { label: 'Cash-out refinance', value: 'cash-out refinance', helptip: CHECKBOX_HELP.refinance },
  { label: 'Another home equity investor', value: 'other investor', helptip: CHECKBOX_HELP.other_investor },
  { label: 'None of the above', value: 'none' },
];

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
export const shouldAlternativesResultShow = value => {
  return !value.includes('none');
};

// @ts-expect-error TS(7006): Parameter 'selected_values' implicitly has an 'any... Remove this comment to see the full error message
export const getAlternativeOptionsLabels = selected_values => {
  // @ts-expect-error TS(7034): Variable 'labels' implicitly has type 'any[]' in s... Remove this comment to see the full error message
  const labels = [];
  ALTERNATIVE_OPTIONS.forEach(option => {
    if (selected_values.includes(option.value)) {
      labels.push(option.label);
    }
  });
  // @ts-expect-error TS(7005): Variable 'labels' implicitly has an 'any[]' type.
  return labels;
};

// --------------------------------
// IDEAL INVESTMENT AMOUNT
// --------------------------------
export const INVESTMENT_BUCKET_OPTIONS = [
  '$15,000 - $50,000',
  '$50,000 - $100,000',
  '$100,000 - $200,000',
  '$200,000 - $300,000',
  '$300,000 - $400,000',
  '$400,000 - $600,000',
];

// --------------------------------
// IS FORMEVER HOME?
// --------------------------------
export const TERM_OPTIONS = [
  {
    label: 'Yes, I don’t plan to move.',
    value: 'never',
  },
  { label: 'No, I plan to sell in 6-10 years', value: 'In 6-10 yrs' },
  { label: 'No, I plan to sell in 1-5 years', value: 'In 1-5 yrs' },
  { label: 'I don’t know', value: 'dont_know' },
];

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
export const doesTermAnswerQualify = value => {
  return value !== 'never';
};

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
export const checkTermResult = value => {
  return value === 'dont_know' || value === 'never';
};

// --------------------------------
// RAINY DAY FUND
// --------------------------------
export const RAINY_DAY_FUND_OPTIONS = [
  { label: 'I don’t have one', value: '1 - none' },
  { label: 'I have some cushion, but wouldn’t be prepared in a big emergency', value: '2 - some' },
  { label: 'I feel pretty good about what I’ve saved, but it could always be better', value: '3 - good' },
  { label: 'I feel very confident in the amount I have saved.', value: '4 - confident' },
];

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
export const doesRainyDayFundAnswerQualify = value => {
  return value === '1 - none' || value === '2 - some';
};

// --------------------------------
// WORRY ABOUT MONTHLY EXPENSES?
// --------------------------------
export const MONTHY_EXPENSE_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
export const doesExpenseAnswerQualify = value => {
  return value === 'yes';
};

// --------------------------------
// OUTCOME UTILITIES
// --------------------------------
// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export const getConfidenceLevel = data => {
  const { term, rainy_day_fund, monthly_expenses_concern } = data;
  if (!doesTermAnswerQualify(term)) {
    return 'low';
  } else if (!doesRainyDayFundAnswerQualify(rainy_day_fund) && !doesExpenseAnswerQualify(monthly_expenses_concern)) {
    return 'low';
  }
  return 'high';
};

const WHITELIST = [
  'result',
  'use_case',
  'term',
  'rainy_day_fund',
  'alternatives_considered',
  `monthly_expenses_concern`,
  'first_name',
  'state',
];

export const getFieldsForUrl = (origObj = {}) => {
  const newObj = {};
  Object.keys(origObj).forEach(key => {
    if (WHITELIST.includes(key)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newObj[key] = origObj[key];
    }
  });
  return newObj;
};

// @ts-expect-error TS(7006): Parameter 'phoneNumberString' implicitly has an 'a... Remove this comment to see the full error message
export const formatPhoneNumberPortals = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = /^(1|)?(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
  if (match) {
    return [match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export const convertIsHomeownerParam = data => {
  if (data === 'Yes' || data === 'No') {
    return data.toLowerCase();
  }
  return 'multiple';
};
