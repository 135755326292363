import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Loader, NotFoundBlock, useAsync } from '@hometap/htco-components';
import { browserTrack } from 'utils/segment';
import { fetchRundown, viewRundown } from './data/rundownRequests';

import RundownLayout from './components/RundownLayout';
import { getVersion } from 'utils/env';
import { getDashboardUrl } from 'utils/links';
import useLogAsyncError from 'hooks/useLogAsyncError';
import useTrack from 'hooks/useTrack';
import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';

const RundownController = () => {
  const { id } = useParams();
  const rundownAsync = useAsync(fetchRundown, { immediate: true, params: [id], defaultLoading: true });
  const rundown = rundownAsync.results;
  const { loading: trackLoading, setTrackId, track } = useTrack();

  useEnableGlia(true);
  useGliaIdentification();

  useEffect(() => {
    if (rundown) {
      setTrackId(rundown.track_id);

      if (rundown && track) {
        viewRundown(rundown.id);
        browserTrack.homeownerInvestmentRundownViewed({
          id: rundown.id,
          document_type: 'rundown',
          description: rundown.description,
          track_role: track?.membership.role,
          version_number: getVersion(),
        });
      }
    }
  }, [rundown, setTrackId, track]);

  useLogAsyncError('Failed to load Rundown', rundownAsync.error);
  if (rundownAsync.error) {
    return <NotFoundBlock buttonLink={getDashboardUrl()} />;
  }

  if (rundownAsync.loading || trackLoading) {
    return (
      <div style={{ textAlign: 'center', margin: '50pt' }}>
        <Loader size="large"></Loader>
      </div>
    );
  }

  return <RundownLayout dataAsync={rundownAsync} type="rundowns" />;
};

export default RundownController;
