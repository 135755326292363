import { useEffect } from 'react';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchTrackRundowns } from 'apps/track-details/data/trackDetailsRequests';
import { showNotification } from 'utils/toasts';
import { getInvestmentDashboardUrl, getRundownOrOfferUrl } from 'utils/links';
import useLogAsyncError from 'hooks/useLogAsyncError';

import { Loader } from '@hometap/htco-components';
import { isTrackExpiredOrClosed } from 'utils/tracksUtils';
import { useQuery } from '@tanstack/react-query';

const RUNDOWN_REDIRECT_NOTIFICATIONS = {
  GENERIC_API_ERROR: {
    type: 'error',
    title: 'Error',
    description: 'Failed to load Estimate. Please retry.',
    options: { toastId: 'api-error' },
  },
  TRACK_NOT_FOUND: {
    type: 'error',
    title: 'Access restricted',
    description: 'Log in to another account or reach out to your Investment Manager',
    options: { toastId: 'track-not-found' },
  },
  TRACK_CLOSED_OR_EXPIRED: {
    type: 'error',
    title: 'Estimate unavailable',
    description: 'This Investment has been closed, so Estimates are no longer available',
    options: { toastId: 'track-closed' },
  },
  NO_VISIBLE_RUNDOWNS: {
    type: 'error',
    title: 'Estimate unavailable',
    description: 'We can’t find an Estimate for this Investment',
    options: { toastId: 'no-rundowns' },
  },
};

const INVESTMENTS_DASHBOARD_URL = getInvestmentDashboardUrl();

/* 
  Attempts to redirect to the most recent Rundown for a Track (given `friendlyId` in params).
  Handles various errors and edge cases by redirecting to the appropriate page and showing a toast.
*/
const TrackRedirector = () => {
  const { friendlyId } = useParams();
  const navigate = useNavigate();

  // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
  const redirectAndNotify = (url, notification, passIsFromNestedPage) => {
    // Supports passing isFromNestedPage when redirecting so if there is a back button on the page
    // being redirected to, it will be able to go back to a default page, rather than triggering
    // a browser "back" action. Using `navigate` with `replace: true` also prevents going "back" from going to
    // RundownRedirector and redirecting again with another notification, which is a confusing UX.
    navigate(url, {
      replace: true,
      state: { isFromNestedPage: passIsFromNestedPage },
    });
    showNotification(notification);
    return null;
  };

  // @ts-expect-error TS(2339): Property 'getTrackByFriendlyId' does not exist on ... Remove this comment to see the full error message
  const { getTrackByFriendlyId, loading: tracksLoading, error: tracksError } = useUserTracksContext();
  const matchingTrack = getTrackByFriendlyId(friendlyId);

  // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleRedirect = async ({ track }) => {
    if (!track) {
      return redirectAndNotify(INVESTMENTS_DASHBOARD_URL, RUNDOWN_REDIRECT_NOTIFICATIONS.TRACK_NOT_FOUND, false);
    }

    if (isTrackExpiredOrClosed(track)) {
      return redirectAndNotify(
        INVESTMENTS_DASHBOARD_URL,
        RUNDOWN_REDIRECT_NOTIFICATIONS.TRACK_CLOSED_OR_EXPIRED,
        false,
      );
    }

    const rundowns = await fetchTrackRundowns(track.id);
    // @ts-expect-error TS(2339): Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
    if (!rundowns?.length) {
      // The TLP page we are redirecting to has a back button, so we use `passIsFromNestedPage` to
      // ensure the back button brings them back to their dashboard.
      return redirectAndNotify(`/track/${track.id}`, RUNDOWN_REDIRECT_NOTIFICATIONS.NO_VISIBLE_RUNDOWNS, true);
    }

    // @ts-expect-error TS(2339): Property 'sort' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
    const [mostRecentRundown] = rundowns.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    // The rundown page has a "close button" similar to a back button, but it already handles
    // navigating back to TLP with a working back button, so no need to pass `isFromNestedPage`.
    navigate(getRundownOrOfferUrl(mostRecentRundown), { replace: true });
    return null;
  };

  const rundownsQuery = useQuery({
    queryFn: async () => await handleRedirect({ track: matchingTrack }),
    queryKey: ['track.rundowns', friendlyId],
    enabled: !tracksLoading,
    // Allowing retries makes the cypress tests more complicated. The rundowns API is so unlikely to
    // fail (and this route isn't that important) that it's not worth the complexity here.
    retry: false,
  });

  useLogAsyncError('Failed to load Tracks', tracksError);
  useLogAsyncError('Failed to load Rundowns', rundownsQuery.error);

  useEffect(() => {
    if (rundownsQuery.isError || tracksError) {
      navigate(INVESTMENTS_DASHBOARD_URL, { replace: true });
      // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
      showNotification(RUNDOWN_REDIRECT_NOTIFICATIONS.GENERIC_API_ERROR);
    }
  }, [navigate, rundownsQuery.isError, tracksError]);

  if (tracksLoading || rundownsQuery.isLoading) {
    return (
      <div className="PageLoader">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return null;
};

export default TrackRedirector;
