import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Button, Icon } from '@hometap/htco-components';

import { mapResource } from 'apps/track-details/utils/sidebar';
import TrackDetailsSidebarIconWrapper from '../TrackDetailsSidebarIconWrapper/TrackDetailsSidebarIconWrapper';
import './TrackDetailsResourceList.scss';
import { DOCUMENTS_LABEL } from '../../../../utils/constants';
import NewBadge from 'components/NewBadge/NewBadge';

// @ts-expect-error TS(7031): Binding element 'icon' implicitly has an 'any' typ... Remove this comment to see the full error message
const ResourceItem = ({ icon, dateCopy, description, isLocked, className, linkProps = {} }) => {
  // @ts-expect-error TS(2339): Property 'to' does not exist on type '{}'.
  const isInternalLink = !!linkProps.to;

  const link = (
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    <Button theme="link" {...linkProps} className={cx('TrackDetailsResourceListItemDescription', linkProps.className)}>
      {description}
      {
        // TODO: This allows the `Link` component to sit on top of our styled `Button` We need to
        // update the `Button` component to remove the need for this hack
      }
      {/* @ts-expect-error TS(2339): Property 'to' does not exist on type '{}'. */}
      {isInternalLink && <Link className="TrackDetailsResourceListLinkMask" to={linkProps.to} />}
    </Button>
  );

  return (
    <li className={cx('TrackDetailsResourceListItem', { isLocked })}>
      {(icon || isLocked) && (
        <div>
          {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
          <TrackDetailsSidebarIconWrapper isDisabled={isLocked}>
            <Icon name={isLocked ? 'lock' : icon} />
          </TrackDetailsSidebarIconWrapper>
        </div>
      )}
      <div className={cx('TrackDetailsResourceListItemMeta', className, { isCentered: isLocked })}>
        {isLocked ? <p className="TrackDetailsResourceListItemDescription isLocked">Not yet available.</p> : link}
        {dateCopy && <small className="TrackDetailsResourceListItemDateCopy">{dateCopy}</small>}
      </div>
    </li>
  );
};

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const ResourceSectionList = ({ sectionResources = [], track, sectionKey, isRolloutFlagEnabled, heapId }) => {
  return sectionResources.length
    ? sectionResources.map((sectionResource, i) => {
        const resourceItem = mapResource(sectionResource, track);
        // @ts-expect-error TS(2339): Property 'icon' does not exist on type '{ icon: st... Remove this comment to see the full error message
        const { icon, dateCopy, description, id, className, isVisible, linkProps } = resourceItem;
        const isSectionResourceVisible = isVisible ? isVisible({ isRolloutFlagEnabled }) : true;

        if (!isSectionResourceVisible) return null;

        if (heapId) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          linkProps['data-heap-id'] = heapId;
        }

        return (
          <ResourceItem
            icon={icon}
            // @ts-expect-error TS(2339): Property 'resourceType' does not exist on type 'ne... Remove this comment to see the full error message
            key={`${sectionResource.resourceType}-${i}`}
            dateCopy={dateCopy}
            description={description}
            // @ts-expect-error TS(2322): Type '{ icon: any; key: string; dateCopy: any; des... Remove this comment to see the full error message
            id={id}
            className={className}
            linkProps={linkProps}
          />
        );
      })
    : // @ts-expect-error TS(2739): Type '{ key: string; isLocked: true; }' is missing... Remove this comment to see the full error message
      [<ResourceItem key={`${sectionKey}-locked`} isLocked />];
};

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const TrackDetailsResourceList = ({ track, resources = [], isRolloutFlagEnabled, hasUnreadDocs }) => {
  return (
    <>
      <div className="TrackDetailsResourceList">
        {resources.map(({ sectionHeading, sectionResources, extraContent }) => {
          const hasNewDocument = hasUnreadDocs && sectionHeading === DOCUMENTS_LABEL;

          return (
            <ul className="TrackDetailsResourceGroup" key={`resource-section-${sectionHeading}`}>
              {sectionHeading && (
                <>
                  <small className="TrackDetailsResourceListHeading">{sectionHeading}</small>
                  {/* Check sectionHeading to make sure this is Documents section to add badge */}
                  {hasNewDocument && <NewBadge testId="new-document-label" className="ml-2" />}
                </>
              )}
              {/* @ts-expect-error TS(2339): Property 'length' does not exist on type 'never'. */}
              <ul className={cx('TrackDetailsResourceListItems', { isEmpty: !sectionResources?.length })}>
                <ResourceSectionList
                  track={track}
                  sectionResources={sectionResources}
                  sectionKey={sectionHeading}
                  isRolloutFlagEnabled={isRolloutFlagEnabled}
                  heapId={hasNewDocument ? 'new-document-label' : undefined}
                />
                {/* @ts-expect-error TS(2339): Property 'isVisible' does not exist on type 'never... Remove this comment to see the full error message */}
                {extraContent?.isVisible && extraContent.content}
              </ul>
            </ul>
          );
        })}
      </div>
    </>
  );
};

export default TrackDetailsResourceList;
