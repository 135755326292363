import ReInquiryAddress from './ReInquiryAddress';

import { formatPhoneNumberSeparator } from 'utils/stringFormatters';
import useConfigurationOptions from 'hooks/useConfigurationOptions';
import { currency } from 'utils/numbers';

// @ts-expect-error TS(7031): Binding element 'inquiry' implicitly has an 'any' ... Remove this comment to see the full error message
const ReInquiryReviewFields = ({ inquiry }) => {
  const { getConfigurationValueDisplay } = useConfigurationOptions({
    names: ['home_property_type', 'home_residence_type', 'inquiry_when_interested', 'inquiry_use_case'],
  });

  const fields = [
    { heading: 'Address', value: <ReInquiryAddress address={inquiry.address} /> },
    {
      heading: 'Phone number',
      value: formatPhoneNumberSeparator(inquiry.phone_number, false),
    },
    {
      heading: 'Property Type',
      value: getConfigurationValueDisplay('home_property_type', inquiry.property_type),
    },
    {
      heading: 'How are you most likely to use a Hometap Investment?',
      value: getConfigurationValueDisplay('inquiry_use_case', inquiry.primary_use_case),
    },
    {
      heading: 'Property Use',
      value: getConfigurationValueDisplay('home_residence_type', inquiry.residence_type),
    },
    {
      heading: 'When would you want to work with us?',
      value: getConfigurationValueDisplay('inquiry_when_interested', inquiry.when_interested),
    },
    {
      heading: 'Email address',
      value: inquiry.email,
    },
    {
      heading: 'Mortgage balance',
      value: currency(inquiry.mortgage_balance),
    },
  ];

  return fields.map(({ heading, value }) => {
    return (
      <div className="ReInquiryReviewData" key={heading}>
        <p className="ReInquiryReviewDataHeading">{heading}</p>
        <p className="ReInquiryReviewDataValue">{value || '-'}</p>
      </div>
    );
  });
};

export default ReInquiryReviewFields;
