import { useState } from 'react';
import { uniqBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useCookies } from 'react-cookie';

import { Modal, SelectionSet, Button } from '@hometap/htco-components';

import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import { getNewInquiryUrl } from 'utils/links';

import ReInquiryAddress from './ReInquiryAddress';
import { isActiveAndPrimary } from '../data/reInquiryUtils';
import { MEMBERSHIP_ROLES, SELECTOR_VALUES } from '../constants/ReInquiryConstants';
import { COOKIES } from 'apps/dashboard/constants';
import useCurrentUser from 'hooks/useCurrentUser';

import '../ReInquiry.scss';

// @ts-expect-error TS(7006): Parameter 'tracks' implicitly has an 'any' type.
const getUniqueTrackAddresses = tracks => {
  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const addresses = tracks?.map(track => {
    return track?.home?.address;
  });
  return uniqBy(addresses, 'business_key');
};

// @ts-expect-error TS(7006): Parameter 'homes' implicitly has an 'any' type.
const getUniqueHomeAddresses = homes => {
  // @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
  const addresses = homes?.map(home => {
    return home?.address;
  });
  return uniqBy(addresses, 'business_key');
};

const ReInquiryAddressPickerModal = ({
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(7031): Binding element 'open' implicitly has an 'any' typ... Remove this comment to see the full error message
  open,
  // @ts-expect-error TS(7031): Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
  onClose,
  // @ts-expect-error TS(7031): Binding element 'homeownerTracks' implicitly has a... Remove this comment to see the full error message
  homeownerTracks,
  // @ts-expect-error TS(7031): Binding element 'homeownerEquityPlannerHomes' impl... Remove this comment to see the full error message
  homeownerEquityPlannerHomes,
  // @ts-expect-error TS(7031): Binding element 'onSelectEquityPlannerHome' implic... Remove this comment to see the full error message
  onSelectEquityPlannerHome,
  isInquiry = false,
}) => {
  const [selected, setSelected] = useState('');
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  const { setSessionPropertyData } = useInitialInquiryData();
  const [, setCookie] = useCookies([COOKIES.DISABLE_PROPERTY_MODAL]);
  const navigate = useNavigate();
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const isLoggedIn = !!user;

  // @ts-expect-error TS(7006): Parameter 'selectedKey' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleSelectedKey = selectedKey => {
    if (selectedKey === SELECTOR_VALUES.NEW) {
      if (isInquiry) {
        setSessionPropertyData({ isNewProperty: true });
        return onClose();
      }
      setSessionPropertyData({});
      setCookie(COOKIES.DISABLE_PROPERTY_MODAL, true, { path: '/' });
      return navigate(getNewInquiryUrl());
    }

    if (homeownerEquityPlannerHomes?.length) {
      // @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
      const equityPlannerHome = homeownerEquityPlannerHomes.find(home => home.address.business_key === selectedKey);
      if (equityPlannerHome) {
        return onSelectEquityPlannerHome(equityPlannerHome);
      }
    }

    // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
    const tracks = homeownerTracks.filter(track => track.home.address.business_key === selectedKey);
    const activeTracks = tracks.filter(isActiveAndPrimary);

    // if there’s an active track for that address, direct to that track's “inner index” page
    if (activeTracks.length > 0) {
      // @ts-expect-error TS(2339): Property 'htap' does not exist on type 'Window & t... Remove this comment to see the full error message
      return window.htap.redirectToTrackInnerIndex(activeTracks[0]);
    }

    // if the user was a co-applicant for that track - direct to the Inquiry form with the address pre-filled
    const [reinquirableTrack] = tracks || [];
    if (reinquirableTrack.membership.role === MEMBERSHIP_ROLES.COAPPLICANT) {
      const { address, property_type } = reinquirableTrack.home;
      if (isLoggedIn) {
        setSessionPropertyData({ ...address, property_type, coapplicant: user?.email });
        if (isInquiry) {
          return onClose();
        }
      }
      return navigate(getNewInquiryUrl());
    }

    // @ts-expect-error TS(2339): Property 'htap' does not exist on type 'Window & t... Remove this comment to see the full error message
    return window.htap.redirectToReInquiryReview(selectedKey);
  };

  return (
    <Modal
      className={cx('ReInquiryAddressPicker', className)}
      open={open}
      onClose={() => {
        setSessionPropertyData({ isNewProperty: true });
        return onClose();
      }}
    >
      <h2>We’re glad you’re back!</h2>
      <p>Which property would you like to use to reopen your application?</p>
      {/* Modal component selects first child input element found for a11y, causing unwanted focusing on open */}
      <input type="hidden" tabIndex={0} />
      <SelectionSet
        labelWidth="100%"
        onChange={setSelected}
        value={selected}
        options={[
          ...getUniqueTrackAddresses(homeownerTracks).map(address => {
            return {
              // @ts-expect-error TS(2322): Type '{ toString: () => string; charAt: (pos: numb... Remove this comment to see the full error message
              label: <ReInquiryAddress address={address} />,
              // @ts-expect-error TS(2339): Property 'business_key' does not exist on type '{ ... Remove this comment to see the full error message
              value: address.business_key,
            };
          }),
          ...getUniqueHomeAddresses(homeownerEquityPlannerHomes).map(address => {
            return {
              // @ts-expect-error TS(2322): Type '{ toString: () => string; charAt: (pos: numb... Remove this comment to see the full error message
              label: <ReInquiryAddress address={address} />,
              // @ts-expect-error TS(2339): Property 'business_key' does not exist on type '{ ... Remove this comment to see the full error message
              value: address.business_key,
            };
          }),
          { label: 'I’d like to apply with a new property', value: SELECTOR_VALUES.NEW },
        ]}
      />
      <Button
        disabled={!selected}
        label="Continue"
        onClick={() => handleSelectedKey(selected)}
        data-testid="re-inquiry-continue-button"
      />
    </Modal>
  );
};

export default ReInquiryAddressPickerModal;
