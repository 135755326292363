import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { currency, percent } from 'utils/numbers';

import { NotFoundBlock, Toggle } from '@hometap/htco-components';
import RundownHeader from './RundownHeader/RundownHeader';
import RundownFooter from './RundownFooter/RundownFooter';
import RundownInvestmentCalculator from './RundownInvestmentCalculator';
import RundownScenariosAndCosts from './RundownScenariosAndCosts/RundownScenariosAndCosts';
import useScenarioCalculator from 'apps/rundowns/data/useScenarioCalculator';
import { INVESTMENT_TERM_UNIT } from 'apps/rundowns/data/rundownUtils';
import useTrack from 'hooks/useTrack';

// @ts-expect-error TS(7031): Binding element 'dataAsync' implicitly has an 'any... Remove this comment to see the full error message
const RundownLayout = ({ dataAsync, type }) => {
  const [rundown, setRundown] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [investmentLength, setInvestmentLength] = useState(null);
  const [investmentTermUnit, setInvestmentTermUnit] = useState(INVESTMENT_TERM_UNIT.YEARS);

  const { isRolloutFlagEnabled } = useTrack();
  const appreciationDefaultFlagEnabled = isRolloutFlagEnabled('appreciation_default');

  const {
    scenarios,
    scenarioCalculation,
    isFetchingScenarioCalculation,
    fetchScenarioCalculationError,

    homeShareRatios,
    isFetchingHomeShareRatios,
    isErrorFetchingHomeShareRatios,
  } = useScenarioCalculator({
    type,
    investmentAmount,
    investmentLength,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    rundownId: rundown?.id || null,
    // @ts-expect-error TS(2339): Property 'home_value' does not exist on type 'neve... Remove this comment to see the full error message
    startingHomeValue: rundown?.home_value || null,
    investmentTermUnit,
    appreciationDefaultFlagEnabled,
  });

  const getInvestmentLengthRangeForInvestmentTermUnit = () => {
    if (investmentTermUnit === INVESTMENT_TERM_UNIT.YEARS) {
      // @ts-expect-error TS(2339): Property 'term_years' does not exist on type 'neve... Remove this comment to see the full error message
      return { min: 1, max: rundown?.term_years };
    }

    return { min: 3, max: 18 };
  };

  // @ts-expect-error TS(7006): Parameter 'termLength' implicitly has an 'any' typ... Remove this comment to see the full error message
  const formatTermUnit = termLength => (termLength <= 1 ? investmentTermUnit.replace(/s$/, '') : investmentTermUnit);

  const { min: minInvestmentLength, max: maxInvestmentLength } = getInvestmentLengthRangeForInvestmentTermUnit();
  const investmentLengthSlider = {
    title: 'Length of Investment',
    min: minInvestmentLength,
    max: maxInvestmentLength,
    step: minInvestmentLength,
    breakpoints: [
      { position: minInvestmentLength, label: `${minInvestmentLength} ${formatTermUnit(minInvestmentLength)}` },
      {
        position: maxInvestmentLength,
        label: `${maxInvestmentLength} ${investmentTermUnit}`,
      },
    ],
    // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
    valueRenderer: value => (
      <div className="RundownCalculatorSliderValue">
        {value} {formatTermUnit(value)}
        <Toggle
          className="RundownCalculatorInvestmentSliderToggle"
          name="investmentTermUnit"
          value={investmentTermUnit}
          options={[
            { label: 'In months', value: 'months' },
            { label: 'In years', value: 'years' },
          ]}
          onChange={setInvestmentTermUnit}
        />
      </div>
    ),
  };

  // This modification hacks the slider to work with a step that doesn't perfectly divide the range.
  // If it is not evenly divisible, then it creates a final step that is the same distance, but displays the lower value.
  // e.g. {min: 15000 max: 400781 step: 1000} will have the final steps 399000, 400000, 400781.
  const step = 1000;
  // @ts-expect-error TS(2339): Property 'amount_min' does not exist on type 'neve... Remove this comment to see the full error message
  const actualMin = rundown?.amount_min || 0;
  // @ts-expect-error TS(2339): Property 'amount_max' does not exist on type 'neve... Remove this comment to see the full error message
  const actualMax = rundown?.amount_max || step;
  const modifiedMax = Math.ceil((actualMax - actualMin) / step) * step + actualMin;
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  const valueModifier = value => {
    if (value === modifiedMax) {
      return actualMax;
    }
    return value;
  };
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  const modifiedInvestmentAmountSetter = value => {
    setInvestmentAmount(valueModifier(value));
  };
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  const investmentSliderValue = investmentAmount >= actualMax ? modifiedMax : investmentAmount;

  const investmentAmountSlider = {
    title: 'Ideal Investment Amount',
    min: actualMin,
    max: modifiedMax,
    step: step,
    breakpoints: [
      { position: actualMin, label: `${currency(actualMin)}` },
      { position: modifiedMax, label: `${currency(actualMax)}` },
    ],
    // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
    valueRenderer: value => {
      return <div className="RundownCalculatorSliderValue">{currency(valueModifier(value))}</div>;
    },
    subtitleRenderer: rundown
      ? // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
        value => `${percent(valueModifier(value) / rundown.home_value, '0')} of home value`
      : null,
  };

  useEffect(() => {
    const rundown = dataAsync.results;
    if (!rundown) {
      return;
    }
    setRundown(rundown);
    setInvestmentAmount(rundown.amount_requested);
    setInvestmentLength(rundown.term_years);
  }, [dataAsync]);

  useEffect(() => {
    setInvestmentLength(maxInvestmentLength);
  }, [maxInvestmentLength]);

  if (dataAsync.error || fetchScenarioCalculationError || isErrorFetchingHomeShareRatios) {
    return <NotFoundBlock buttonLink="http://hometap.com" />;
  }

  return (
    <div data-testid="rundown-container">
      {rundown && (
        <>
          <RundownHeader rundown={rundown} />
          <RundownInvestmentCalculator
            // @ts-expect-error TS(2339): Property 'home_value' does not exist on type 'neve... Remove this comment to see the full error message
            homeValue={rundown.home_value}
            // @ts-expect-error TS(2339): Property 'scenarios_enabled' does not exist on typ... Remove this comment to see the full error message
            areScenariosEnabled={rundown.scenarios_enabled}
            scenarioCalculation={scenarioCalculation}
            isFetchingScenarioCalculation={isFetchingScenarioCalculation}
            homeShareRatios={homeShareRatios}
            isFetchingHomeShareRatios={type === 'rundowns' ? isFetchingHomeShareRatios : false}
            // @ts-expect-error TS(2339): Property 'is_amount_locked' does not exist on type... Remove this comment to see the full error message
            isInvestmentAmountSliderEnabled={type === 'rundowns' && !rundown.is_amount_locked}
            investmentAmountSlider={investmentAmountSlider}
            // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'number'.
            investmentAmount={investmentAmount}
            setInvestmentAmount={modifiedInvestmentAmountSetter}
            investmentLengthSlider={investmentLengthSlider}
            investmentLength={investmentLength}
            setInvestmentLength={setInvestmentLength}
            investmentTermUnit={investmentTermUnit}
            investmentSliderValue={investmentSliderValue}
          />
          <RundownScenariosAndCosts rundown={rundown} investmentAmount={investmentAmount} scenarios={scenarios} />
        </>
      )}

      <RundownFooter />
    </div>
  );
};

RundownLayout.propTypes = {
  dataAsync: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['rundowns', 'offers']),
};

export default RundownLayout;
