import { Button, SelectionSet, useWindowSize } from '@hometap/htco-components';
import { useEffect, useState } from 'react';
import image_desktop from 'images/equity-planner/renovations/renovations-type-desktop.png';
import image_mobile from 'images/equity-planner/renovations/renovations-type-mobile.png';
import Insight from 'components/insight/Insight';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { scrollIntoViewByElementId, scrollTop } from 'utils/domScripts';

const renovationButtonId = 'renovation-continue-button-id';

const insightText =
  'It pays to pick and choose renovations! Some projects will earn you a higher return than others, so a little research goes a long way.';

// @ts-expect-error TS(7031): Binding element 'isLoading' implicitly has an 'any... Remove this comment to see the full error message
const EquityRenovationSelector = ({ isLoading, error, renovationProjectOptions, onSelect }) => {
  const { isScreenSize } = useWindowSize({
    breakpoints: {
      sm: 1100,
    },
  });
  const isTablet = isScreenSize('sm');
  const [selectedType, setSelectedType] = useState();

  useEffect(() => {
    scrollTop();
  }, []);

  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  const handleRenoTypeChange = value => {
    scrollIntoViewByElementId(renovationButtonId);
    setSelectedType(value);
  };

  let projectSelectView;
  if (isLoading) {
    projectSelectView = (
      <div className="EquityRenovationSelectionLoading">
        {[...new Array(5)].map((_, i) => (
          <div key={`EquityRenovationSelectionLoading-${i}`} className="EquityRenovationSelectionLoadingItem" />
        ))}
      </div>
    );
  } else if (error) {
    projectSelectView = (
      <>
        <div>
          {/* @ts-expect-error TS(2739): Type '{ children: string; }' is missing the follow... Remove this comment to see the full error message */}
          <ErrorMessage>We are unable to reach the server, please try again.</ErrorMessage>
        </div>
        <div>
          <Button className="EquityRenovationSelectionErrorRefresh" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      </>
    );
  } else {
    projectSelectView = (
      <>
        <div className="EquityRenovationSelectionSetContainer">
          <SelectionSet
            name="renovationType"
            value={selectedType}
            labelWidth="100%"
            labelGrow={false}
            options={renovationProjectOptions.slice(0, Math.ceil(renovationProjectOptions.length / 2))}
            onChange={handleRenoTypeChange}
          />
          <SelectionSet
            name="renovationType"
            value={selectedType}
            labelWidth="100%"
            labelGrow={false}
            options={renovationProjectOptions.slice(Math.ceil(renovationProjectOptions.length / 2))}
            onChange={handleRenoTypeChange}
          />
        </div>
        <Button
          id={renovationButtonId}
          className="my-6 w-full scroll-mb-6 xs:w-auto sm:mb-12 sm:mt-8 sm:scroll-mb-12"
          disabled={!selectedType}
          onClick={() => onSelect(selectedType)}
        >
          Continue
        </Button>
      </>
    );
  }

  return (
    <div className="EquityRenovationSelector">
      <div className="EquityRenovationSelectorRenovationTypes">
        <h3>What type of renovation are you interested in?</h3>
        {projectSelectView}
        <Insight text={insightText} />
      </div>
      <div className="EquityRenovationSelectorImgContainer">
        <img
          src={isTablet ? image_mobile : image_desktop}
          className="EquityRenovationSelectorImg"
          alt="People planning"
          width="100%"
        />
      </div>
    </div>
  );
};

export default EquityRenovationSelector;
