import { ACCEPTED_FILE_TYPE_AND_EXTENSIONS } from './constants';

export const DOCUMENT_STATUS = {
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  UPLOADED: 'uploaded',
};

// Generate a randomID for reference of the file since file.name is not necessarily unique.
// @ts-expect-error TS(7006): Parameter 'file' implicitly has an 'any' type.
export const formatFileId = file => {
  const randomKey = (Math.random() + 1).toString(36).substring(7);
  return `${file.name}-${file.size}-${randomKey}`;
};

// Format a file for display to include id and status, ids on file param
// should take precedence so that they are not overridden.
// @ts-expect-error TS(7006): Parameter 'file' implicitly has an 'any' type.
export const formatFileObject = (file, status) => {
  if (file.file) {
    return {
      id: formatFileId(file.file),
      ...file,
      status,
    };
  }

  return {
    id: formatFileId(file),
    file,
    status,
  };
};

export const getFlattenAcceptedFileTypes = () => {
  return Object.keys(ACCEPTED_FILE_TYPE_AND_EXTENSIONS).reduce((arr, fileType) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return arr.concat(ACCEPTED_FILE_TYPE_AND_EXTENSIONS[fileType]);
  }, []);
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
// @ts-expect-error TS(7006): Parameter 'bytes' implicitly has an 'any' type.
export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  // sizes larger than GB not needed, included anyways to decrease likelihood out of bounds exception
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
