import { STAGES } from 'apps/dashboard/constants';
import { config } from './config';
import { TRACK_STAGE_TO_UI_STAGE } from './constants';

// @ts-expect-error TS(7031): Binding element 'investmentSummary' implicitly has... Remove this comment to see the full error message
export const determineShouldShowSettlementCalculator = ({ investmentSummary }) => {
  // We attempt to only show the settlement calculator if it will work. We prefer this to
  // showing the calculator but erroring when a HO tries to use it. For the settlement
  // calculator to work, the Investment must have all the requisite data in the database.
  // Sometimes this data has not been entered yet or is temporarily missing. This frontend check
  // is a best effort to only show the calculator when it will work and is repetitive of the
  // checks done in the API (see `TrackScenarioSerializer` in eng_portals).
  if (!investmentSummary) return false;

  return (
    investmentSummary.effective_date &&
    investmentSummary.settlement_deadline &&
    investmentSummary.beginning_home_value &&
    investmentSummary.investment_amount &&
    investmentSummary.price_buckets &&
    // If there is one price bucket, only `settlement_deadline` (checked above) is required for
    // settlement calc to work.
    (investmentSummary.price_buckets.length === 1 ||
      // But if there are thee price buckets, both pricing_tier_1_expiration_date and
      // pricing_tier_2_expiration_date are required for settlement calc to work.
      (investmentSummary.price_buckets.length === 3 &&
        investmentSummary.pricing_tier_1_expiration_date &&
        investmentSummary.pricing_tier_2_expiration_date))
  );
};

// @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
export const isTrackFunded = track =>
  track?.current_stage === STAGES.INVESTED || track?.current_stage === STAGES.SETTLED;

// @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
export const getCurrentStageConfig = track => {
  const currentUIStage = TRACK_STAGE_TO_UI_STAGE[track?.current_stage];

  if (!currentUIStage) return {};

  const allUIProgressStepperStages = Object.keys(config).filter(key => !config[key].showWithoutOtherStages);
  const currentUIStageConfig = config[currentUIStage.key];

  if (!currentUIStageConfig) return {};

  let lastActiveStageIndex = Object.keys(config).findIndex(stage => stage === currentUIStage.key);
  const shouldHoldOnPreviousStage =
    currentUIStageConfig.determineIsActive && !currentUIStageConfig.determineIsActive({ track });
  if (shouldHoldOnPreviousStage && lastActiveStageIndex > 0) {
    lastActiveStageIndex = lastActiveStageIndex - 1;
  }

  let renderedStagesConfigs;
  if (currentUIStageConfig.showWithoutOtherStages) {
    renderedStagesConfigs = [currentUIStageConfig];
  } else {
    renderedStagesConfigs = Object.values(config)
      // @ts-expect-error TS(2345): Argument of type '{ title: string; getSubtitle(): ... Remove this comment to see the full error message
      .slice(lastActiveStageIndex, config.length)
      .filter(uiStage => !uiStage.showWithoutOtherStages);
  }

  const canShowProgressStepper = lastActiveStageIndex >= 0 && lastActiveStageIndex < allUIProgressStepperStages.length;

  return {
    currentUIStage,
    lastActiveStageIndex,
    allUIProgressStepperStages,
    renderedStagesConfigs,
    currentUIStageConfig,
    canShowProgressStepper,
  };
};
