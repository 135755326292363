import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DownloadFileButton from 'components/DownloadFileButton/DownloadFileButton';
import { convertDateStringToHumanReadableMonth } from 'utils/date';

// @ts-expect-error TS(7031): Binding element 'homeAddress' implicitly has an 'a... Remove this comment to see the full error message
const QuarterlyStatementHeader = ({ homeAddress, quarter, investmentId, applicants, document }) => {
  const { showQasDownloadButton } = useFlags();
  // @ts-expect-error TS(7006): Parameter 'applicant' implicitly has an 'any' type... Remove this comment to see the full error message
  const homeOwnerNames = (applicants || []).map(applicant => applicant.full_name).join(', ');
  const startDate = quarter && convertDateStringToHumanReadableMonth(quarter.start);
  const endDate = quarter && convertDateStringToHumanReadableMonth(quarter.end);

  const addressLine = useMemo(
    () =>
      homeAddress &&
      [homeAddress.street, homeAddress.unit, homeAddress.city, homeAddress.state, homeAddress.zip_code]
        .filter(Boolean)
        .join(', '),
    [homeAddress],
  );
  return (
    <div className="block justify-between gap-10 border-0 border-solid border-neutral-light-100 sm:flex">
      <div>
        <h1 className="mb-1 flex items-baseline gap-1">
          <span>Quarterly statement</span>
          {showQasDownloadButton && document && (
            // @ts-expect-error TS(2741): Property 'children' is missing in type '{ classNam... Remove this comment to see the full error message
            <DownloadFileButton
              className="[&_i]:!text-[24px] [&_i]:sm:!text-[32px]"
              filename={document.filename}
              fileURL={document.file_url}
              data-testid="statement-download-button"
            />
          )}
        </h1>
        <div
          className="text-[22px] text-base/[1.5] leading-[34px] text-neutral-dark-75"
          data-testid="quarterly_statement_quarter_bounds"
        >
          {startDate} – {endDate}
        </div>
      </div>
      <div className="mt-4 sm:mt-0 sm:text-right">
        <div data-testid="quarterly_statement_home_address" className="pb-2 font-bold">
          {addressLine}
        </div>
        <div className="mb-2 whitespace-nowrap">
          <span>Investment ID:</span>&nbsp;
          <span data-testid="quarterly_statement_track_id">{investmentId}</span>
        </div>
        <div data-testid="quarterly_statement_applicants">{homeOwnerNames}</div>
      </div>
    </div>
  );
};

export default QuarterlyStatementHeader;
