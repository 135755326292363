const valueScenario = () => {
  return {
    name: '',
    idx: 0,
    effective_period: {
      length: 10,
      unit: 'years',
    },
    starting_home_value: 1000000,
    investment_amount: 100000,
    appreciated_home_value: 1343916.3793441223,
    appreciation_percent: {
      annualized: true,
      value: 0.03,
    },
    label: 'Moderate Appreciation',
    hometap_share: {
      dollars: 223986.0632240204,
      percent: 0.16666666666666669,
    },
    homeowner_share: {
      dollars: 1119930.3161201018,
      percent: 0.8333333333333333,
    },
    is_share_capped: false,
    crr_percent: 20,
    term: '30f',
    rate: 3.5,
    balance: 250000,
    loan: 330000,
    payment: 1000,
  };
};

const SCENARIO_PERIOD_OPTIONS = [
  { value: '30_year_fixed', label: '30-year fixed' },
  { value: '20_year_fixed', label: '20-year fixed' },
  { value: '15_year_fixed', label: '15-year fixed' },
  { value: '10_year_fixed', label: '10-year fixed' },
  { value: 'three_one_arm', label: '3/1 ARM' },
  { value: 'five_one_arm', label: '5/1 ARM' },
  { value: 'seven_one_arm', label: '7/1 ARM' },
  { value: 'ten_one_arm', label: '10/1 ARM' },
];

const APPRECIATION_RATE_MAP = {
  0.02: 'low',
  0.03: 'low',
  0.04: 'moderate',
  0.05: 'moderate',
  0.06: 'high',
  0.07: 'high',
};

// @ts-expect-error TS(7006): Parameter 'term' implicitly has an 'any' type.
const getPeriodLabel = term => {
  const t = SCENARIO_PERIOD_OPTIONS.find(o => o.value === term);
  return t ? t.label : '';
};

export { valueScenario, SCENARIO_PERIOD_OPTIONS, getPeriodLabel, APPRECIATION_RATE_MAP };
