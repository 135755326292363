import cx from 'classnames';

import { Link } from 'react-router-dom';

import { Icon, useWindowSize } from '@hometap/htco-components';
import AccountDropdown from '../../AccountDropdown/AccountDropdown';
import useGoBackWithDefault from 'hooks/useGoBackWithDefault';

// @ts-expect-error TS(7031): Binding element 'as' implicitly has an 'any' type.
const BackButton = ({ as, title }) => {
  const { component, ...props } = as;
  const As = component;
  return (
    <As
      {...props}
      data-testid="back-button"
      className={cx(
        'htco- group z-50 mr-6 flex items-center border-0 border-solid border-neutral-light-100 px-6 text-base focus-visible:outline-none xs:pl-8 sm:pl-16 md:pl-26',
        { 'border-r': title },
      )}
    >
      <div className="flex flex-row rounded outline-1 outline-offset-2 outline-blue-100 group-focus-visible:outline">
        <Icon
          name="chevron2-left"
          className="h-6 pt-[3px] !text-[24px] text-neutral-dark-75 group-hover:text-blue-100 group-active:text-blue-medium-100"
        />
        <p
          className={cx(
            'm-0 pl-2 pr-1 font-sans text-base font-bold text-neutral-dark-75 group-hover:text-blue-100 group-active:text-blue-medium-100',
            { 'hidden sm:block': title },
          )}
        >
          Back
        </p>
      </div>
    </As>
  );
};

const SecondaryLayout = ({
  // @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031): Binding element 'prevUrl' implicitly has an 'any' ... Remove this comment to see the full error message
  prevUrl,
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  allowAccountDropdown = false,
  // @ts-expect-error TS(7031): Binding element 'disableNativeBack' implicitly has... Remove this comment to see the full error message
  disableNativeBack,
  // @ts-expect-error TS(7031): Binding element 'isNestedPage' implicitly has an '... Remove this comment to see the full error message
  isNestedPage,
}) => {
  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize('sm');
  // @ts-expect-error Object literal may only specify known properties, and 'defaultUrl' does not exist in type '{ replace?: boolean | undefined; }'
  const { canGoBack, goBack } = useGoBackWithDefault({ defaultUrl: prevUrl });
  return (
    <aside className="h-screen">
      <div className="fixed z-50 flex h-18 w-full min-w-0 flex-row justify-between border-0 border-b border-solid border-neutral-light-100 bg-white sm:h-22">
        <div className="flex flex-row">
          <BackButton
            title={title}
            as={
              canGoBack && !disableNativeBack
                ? { component: 'button', onClick: goBack }
                : { component: Link, to: prevUrl, state: { isFromNestedPage: isNestedPage } }
            }
          />
          {title && (
            <div className="flex items-center overflow-hidden pr-6 sm:pr-8 md:pr-18 lg:pr-27">
              <p data-testid="title" className="truncate font-bold">
                {title}
              </p>
            </div>
          )}
        </div>
        {allowAccountDropdown && !isTablet && (
          <div className="content-center pr-18 md:pr-27">
            <AccountDropdown />
          </div>
        )}
      </div>
      <div className="pt-18 sm:pt-22">{children}</div>
    </aside>
  );
};

export default SecondaryLayout;
