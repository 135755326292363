import { Loader, NotFoundBlock } from '@hometap/htco-components';

export const defaultErrorProps = {
  buttonLink: '/dashboard',
  errorHeading: "We're having trouble fetching this data. Please try refreshing",
  errorText: 'If the problem persists please reach out to your Investment Manager.',
  buttonLabel: 'Head on home',
};

// @ts-expect-error TS(7031): Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
const SectionLoadingWrapper = ({ loading, children, error, errorProps, className }) => {
  errorProps = { ...defaultErrorProps, ...(errorProps || {}) };
  if (error) {
    return (
      <NotFoundBlock
        error={`${error.response?.status !== 404 ? 'Whoops!' : 404}`}
        buttonLink={errorProps.buttonLink}
        title={errorProps.errorHeading}
        buttonLabel={errorProps.buttonLabel}
      >
        {errorProps.errorText}
      </NotFoundBlock>
    );
  }

  let content;
  if (loading) {
    content = (
      <div className="col-start-6 flex min-h-screen-minus-header items-center justify-center">
        <Loader type="dot-pulse" />
      </div>
    );
  } else if (className) {
    content = <div className={className}>{children}</div>;
  } else {
    content = children;
  }

  return content;
};

export default SectionLoadingWrapper;
