import { MEMBERSHIP_ROLES } from '../constants/ReInquiryConstants';
import { STAGES } from 'apps/dashboard/constants';

const NONACTIVE_ARRAY = [STAGES.INVESTED, STAGES.SETTLED, STAGES.CLOSED_LOST];

// @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
export const isActiveAndPrimary = track =>
  !track.has_expired &&
  track.membership.role === MEMBERSHIP_ROLES.PRIMARY &&
  !NONACTIVE_ARRAY.includes(track.current_stage);
