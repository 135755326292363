import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { NotFoundBlock, Paper, FormError, useAsync } from '@hometap/htco-components';
import { Helmet } from 'react-helmet-async';
import InvestmentInfo from './components/SettlementInvestmentInfo';
import SettlementForm from './components/SettlementForm';
import { fetchInvestmentSummary, fetchScenarios, sendCalculatorFormEvent } from './data/settlementRequests';
import SettlementResult from './components/SettlementResult';
import { getDashboardUrl } from 'utils/links';
import { getDateDifference, formatDate } from 'utils/date';
import useUserTracksContext from 'hooks/useUserTracksContext';
import useTrack from 'hooks/useTrack';
import SettlementDisclaimer from './components/SettlementDisclaimer';
import './SettlementCalculator.scss';

const SettlementCalculatorController = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({ date: '', estimatedHomeValue: '' });
  const [estimatedSettlementDate, setEstimatedSettlementDate] = useState({});
  const investmentSummaryAsync = useAsync(fetchInvestmentSummary, { immediate: true, params: [id] });
  const investment = investmentSummaryAsync.results;
  const { setTrackId } = useTrack();
  // @ts-expect-error TS(2339): Property 'tracks' does not exist on type '{}'.
  const { tracks } = useUserTracksContext();

  const scenarioAsync = useAsync(fetchScenarios);
  const scenarios = scenarioAsync.results?.scenarios ? scenarioAsync.results.scenarios[0] : {};

  // Using the investment to set the current track context by matching investment ids
  // @ts-expect-error TS(7006): Parameter 't' implicitly has an 'any' type.
  const trackFromInvestment = tracks?.find(t => t.active_investment_id === id);
  useEffect(() => {
    if (trackFromInvestment) {
      setTrackId(trackFromInvestment.id);
    }
  }, [trackFromInvestment, setTrackId]);

  // @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
  const handleSubmitForm = async formData => {
    setFormData(formData);
    const effectiveDate = investment.effective_date;
    const estimatedSettlementDate = moment(formData.date, 'MM-DD-YYYY');
    setEstimatedSettlementDate(estimatedSettlementDate);
    const effectivePeriodLength = getDateDifference(effectiveDate, estimatedSettlementDate, 'days');
    const request = {
      scenarios: [
        {
          idx: 0,
          effective_period: {
            length: effectivePeriodLength,
            unit: 'days',
          },
          appreciated_home_value: Math.round(formData.estimatedHomeValue),
        },
      ],
    };
    const settlementCalcData = await scenarioAsync.execute(trackFromInvestment.friendly_id, request);

    const settlementExpectedShare =
      settlementCalcData?.scenarios?.length && settlementCalcData.scenarios[0].hometap_share?.dollars;
    sendCalculatorFormEvent(formatDate(estimatedSettlementDate), formData.estimatedHomeValue, settlementExpectedShare);
  };

  const handleReset = () => {
    scenarioAsync.setResults({});
  };

  const hometapShare = scenarios?.hometap_share;

  if (investmentSummaryAsync.error) {
    return <NotFoundBlock buttonLink={getDashboardUrl()} />;
  }

  return (
    <div className="SettlementCalculator">
      <Helmet>
        <title>Settlement Calculator | Hometap</title>
      </Helmet>
      <div className="SettlementCalculatorContainer">
        <Paper theme="elevated" className="SettlementCalculatorWrapper">
          <h1 className="SettlementCalculatorTitle">Settlement Calculator</h1>
          <div className="SettlementCalculatorSubTitle">
            Curious about how much it might cost you to settle your Investment? Simply plug in your details below to
            find out.
          </div>
          <InvestmentInfo
            hometapShare={hometapShare}
            effectiveDate={investment?.effective_date}
            expirationDate={investment?.settlement_deadline}
            beginningHomeValue={investment?.beginning_home_value}
            investmentAmount={investment?.investment_amount}
            settlementDate={estimatedSettlementDate}
            appreciatedHomeValue={scenarios?.appreciated_home_value}
          />
          {hometapShare ? (
            <SettlementResult
              onBackButton={handleReset}
              settlementAmount={Math.round(scenarios?.hometap_share.dollars)}
            />
          ) : (
            <>
              <SettlementForm
                onSubmit={handleSubmitForm}
                isFetching={scenarioAsync.loading}
                expirationDate={investment?.settlement_deadline}
                initialValues={formData}
              />
              {scenarioAsync?.error && (
                <FormError
                  className="SettlementCalculatorFormError"
                  error="We had an issue calculating your settlement amount. Please try again or contact your Investment Manager for support."
                />
              )}
            </>
          )}
        </Paper>
        {hometapShare && <SettlementDisclaimer />}
      </div>
    </div>
  );
};

export default SettlementCalculatorController;
