import { convertToMoneyString } from '@hometap/htco-components/src/utils/formatting';
import { twMerge } from 'tailwind-merge';
import { mergeWith } from 'lodash';

// @ts-expect-error TS(7006): Parameter 'amount' implicitly has an 'any' type.
const getFormattedDollars = amount => {
  return convertToMoneyString(amount, { stripDecimal: true });
};

// @ts-expect-error TS(7006): Parameter 'minPlaces' implicitly has an 'any' type... Remove this comment to see the full error message
export const makePercentFormatter = (minPlaces, maxPlaces) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: minPlaces,
    maximumFractionDigits: maxPlaces,
  });
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  return value => formatter.format(value / 100);
};

export const getFormattedPercent = makePercentFormatter(1, 1);

export const LEGEND_HEADING_STYLE_DEFAULTS =
  'm-0 text-sm leading-6 font-bold uppercase tracking-wide sm:text-xs sm:leading-6 font-sans';

// Merges custom class names with default classNames from a configuration object.
// @ts-expect-error TS(7006): Parameter 'objValue' implicitly has an 'any' type.
const handleMergeClassNames = (objValue, srcValue, key) => {
  if (key === 'className') {
    // merge classnames to allow default classes to still be overridden if needed.
    return twMerge(objValue, srcValue);
  }
};

// @ts-expect-error TS(7006): Parameter 'objValue' implicitly has an 'any' type.
const handleMergeGraphedShapes = (objValue, srcValue, key) => {
  if (key === 'graphedShares' && objValue) {
    // @ts-expect-error TS(7006): Parameter 'objShare' implicitly has an 'any' type.
    return objValue.map(objShare => {
      // @ts-expect-error TS(7006): Parameter 'share' implicitly has an 'any' type.
      const srcShare = srcValue.find(share => share.key === objShare.key);
      return srcShare ? mergeWith({}, objShare, srcShare, handleMergeClassNames) : objShare;
    });
  }
};

// @ts-expect-error TS(7019): Rest parameter 'mergeArgs' implicitly has an 'any[... Remove this comment to see the full error message
const handleMergeConfig = (...mergeArgs) =>
  // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
  handleMergeClassNames(...mergeArgs) ?? handleMergeGraphedShapes(...mergeArgs);

/*
 * Retrieves the configuration for the HomeGraph component, incorporating default values while
 * permitting custom overrides. Specifically for `className` properties, the function merges
 * overrides with the default configuration. This ensures that default styles are preserved unless
 * explicitly overridden with similar utility classes (see
 * https://www.npmjs.com/package/tailwind-merge), yet also allows for the addition of custom class
 * names. for custom class names to be added.
 * @example
 * const config = {
 *    container: {
 *      className: 'flex justify-center bg-blue-100 text-lg'
 *    }
 * }
 *
 * <HomeGraph
 *    configOverrides={config => {
 *      return {...config, container: { className: 'bg-red-100 text-xl' }};
 *    }
 * />
 *
 * output: { container: { className: 'flex justify-center bg-red-100 text-xl' } }
 */
// @ts-expect-error TS(7031): Binding element 'homeownerShare' implicitly has an... Remove this comment to see the full error message
export const getHomeGraphConfig = ({ homeownerShare, mortgageBalance, hometapShare, homeValue, configOverrides }) => {
  // @ts-expect-error TS(7006): Parameter 'percent' implicitly has an 'any' type.
  const getPercentString = percent => `${percent}%`;

  const initialConfig = {
    graphedShares: [
      {
        bgClassName: 'bg-blue-30',
        key: 'homeownerShare',
        legend: { title: 'Your share', helpText: '', value: homeownerShare },
        graph: { height: getPercentString(homeownerShare?.percent) },
      },
      {
        bgClassName: 'bg-blue-50',
        key: 'mortgageBalance',
        legend: { title: 'Mortgage balance', helpText: '', value: mortgageBalance },
        graph: { height: getPercentString(mortgageBalance?.percent) },
      },
      {
        bgClassName: 'bg-blue-75',
        key: 'hometapShare',
        legend: { title: 'Hometap share', helpText: '', value: hometapShare },
        graph: { height: getPercentString(hometapShare?.percent) },
        withOverlay: true,
      },
    ],

    container: {
      className: 'h-full border border-solid border-blue-15 bg-blue-5 p-6 rounded-xl sm:p-10',
    },

    legend: {
      title: { value: 'Projected Home Value', className: LEGEND_HEADING_STYLE_DEFAULTS },

      badge: { formatter: getFormattedPercent },

      homeValue: {
        formatter: getFormattedDollars,
        value: homeValue,
        className: 'font-serif text-3xl text-blue-100 leading-10',
      },

      subheading: {
        value: '',
        className: 'm-0 text-sm text-neutral-dark-75',
      },

      labels: { className: LEGEND_HEADING_STYLE_DEFAULTS },

      helpText: { className: 'text-xs text-neutral-dark-75' },

      values: {
        formatter: getFormattedDollars,
        className: 'm-0 font-serif text-xl font-semibold leading-7 text-blue-100',
      },
    },

    heading: {
      title: { value: '', className: 'm-0 text-3xl font-serif' },
      subtitle: { value: '', className: 'm-0 text-xs text-neutral-dark-75' },
    },

    bottomContent: {
      isVisible: hometapShare.isShareCapped,
      badge: {
        label: 'PROTECTED BY THE HOMETAP CAP',
      },
      content: (
        <p className="m-0 leading-[26px]">
          In this scenario, the “Hometap Cap” would come into effect and limit the Hometap Share to a
          <strong> {hometapShare.crrPercent}%</strong> annualized rate of return on the original Investment amount.
        </p>
      ),
      className: 'mt-4 flex flex-col items-start gap-2 rounded-lg border border-blue-15 bg-white p-6 sm:mt-8 sm:px-4',
    },
  };

  const config = configOverrides
    ? mergeWith({}, initialConfig, configOverrides(initialConfig), handleMergeConfig)
    : initialConfig;

  return config;
};
