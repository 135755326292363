import { Button } from '@hometap/htco-components';
import './RenovationFinanceCard.scss';

const RenovationFinanceCard = ({
  // @ts-expect-error TS(7031): Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031): Binding element 'description' implicitly has an 'a... Remove this comment to see the full error message
  description,
  // @ts-expect-error TS(7031): Binding element 'ctaButtonText' implicitly has an ... Remove this comment to see the full error message
  ctaButtonText,
  // @ts-expect-error TS(7031): Binding element 'secondaryButtonText' implicitly h... Remove this comment to see the full error message
  secondaryButtonText,
  // @ts-expect-error TS(7031): Binding element 'onCtaButtonClick' implicitly has ... Remove this comment to see the full error message
  onCtaButtonClick,
  // @ts-expect-error TS(7031): Binding element 'onLearnMoreClick' implicitly has ... Remove this comment to see the full error message
  onLearnMoreClick,
  // @ts-expect-error TS(7031): Binding element 'tag' implicitly has an 'any' type... Remove this comment to see the full error message
  tag,
  // @ts-expect-error TS(7031): Binding element 'fees' implicitly has an 'any' typ... Remove this comment to see the full error message
  fees,
  // @ts-expect-error TS(7031): Binding element 'timeToSettle' implicitly has an '... Remove this comment to see the full error message
  timeToSettle,
  // @ts-expect-error TS(7031): Binding element 'monthlyPayment' implicitly has an... Remove this comment to see the full error message
  monthlyPayment,
  // @ts-expect-error TS(7031): Binding element 'interest' implicitly has an 'any'... Remove this comment to see the full error message
  interest,
}) => {
  return (
    <div className="RenovationFinanceCard">
      {tag && <div className="RenovationFinanceCardTag">{tag}</div>}
      <div className="RenovationFinanceCardContent">
        <div className="RenovationFinanceCardLabel">{label}</div>
        <p className="RenovationFinanceCardDescription">{description}</p>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Fees:</span>
          <span className="RenovationFinanceCardStatsInfo">{fees}</span>
        </div>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Time to settle:</span>
          <span className="RenovationFinanceCardStatsInfo">{timeToSettle}</span>
        </div>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Monthly payments:</span>
          <span className="RenovationFinanceCardStatsInfo">{monthlyPayment}</span>
        </div>
        <div className="RenovationFinanceCardStatsSection">
          <span className="RenovationFinanceCardStatsBold">Interest:</span>
          <span className="RenovationFinanceCardStatsInfo">{interest}</span>
        </div>
      </div>
      <div className="RenovationFinanceCardButtonsWrapper">
        {ctaButtonText && <Button theme="primary" label={ctaButtonText} size="small" onClick={onCtaButtonClick} />}
        <Button theme="secondary" label={secondaryButtonText} size="small" onClick={onLearnMoreClick} />
      </div>
    </div>
  );
};

export default RenovationFinanceCard;
