import { Loader } from '@hometap/htco-components';
import '../PartnerInquiry.scss';

const SpinnerLanding = () => {
  return (
    <div className="SpinnerLandingContainer">
      <h1>One moment, please...</h1>
      <div className="SpinnerLandingSubtitle">Thanks for holding tight while we process your information.</div>
      <Loader />
    </div>
  );
};

export default SpinnerLanding;
