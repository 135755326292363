import ModalPageLayoutLite from 'components/Layouts/ModalPageLayoutLite/ModalPageLayoutLite';
import ValueMissingPanel from 'apps/dashboard/components/home-equity-controller/ValueMissingPanel';
import { useNavigate, useLocation } from 'react-router-dom';
import { getNewDashboardUrl, getNewHEDUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { useCurrentHome } from 'hooks/useCurrentHome';
import { useHomeValuation } from '../hooks/useHomeValuation';
import { useEffect } from 'react';

const MissingHomeValuationModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { home } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist on type '{}'.
  const { isLoading, displayedHomeValuation } = useHomeValuation();

  const overviewPageURL = getNewDashboardUrl(HOMEOWNER_DASHBOARD.OVERVIEW);
  const homeEquityURL = getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY);
  const buildHomeProfileUrl = getNewDashboardUrl(
    `${HOMEOWNER_DASHBOARD.HOME_EQUITY}/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`,
  );

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'hed_debts_confirmed' does not exist on type 'never'
    if (!!displayedHomeValuation.value && home && !home.hed_debts_confirmed) {
      navigate(getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`), { replace: true });
    } else if (displayedHomeValuation.value) {
      navigate(overviewPageURL, { replace: true });
    }
  }, [home, displayedHomeValuation.value, navigate, overviewPageURL, homeEquityURL, buildHomeProfileUrl, location]);

  if (isLoading) {
    return null;
  }

  return (
    // @ts-expect-error TS(2741): Property 'headerContent' is missing in type '{ children: Element; logoLink: string; onModalClose: () => void; }'
    <ModalPageLayoutLite logoLink={getNewDashboardUrl()} onModalClose={() => navigate(overviewPageURL)}>
      <ValueMissingPanel />
    </ModalPageLayoutLite>
  );
};

export default MissingHomeValuationModal;
