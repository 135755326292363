import { RESOURCE_CATEGORIES, RESOURCE_TYPES } from '../constants/resources';
import PrioritizingFinancialGoals from './components/pay-off-debt-build-savings-prioritizing-financial-goals';
import WhyPropertyTaxesIncrease from './components/why-property-taxes-increase';
import ShouldISellMyHome from './components/should-i-sell-my-home';
import FinancingHomeRenovation from './components/renovations/financing-home-renovation';
import SaveForHomeRepairs from './components/renovations/save-for-home-repairs';
import PrioritizeHomeRenovations from './components/renovations/prioritize-home-renovations';
import SettlingHeiWithHomeSale from './components/settling-your-home-equity-investment-with-a-home-sale';
import Trends from './components/home-equity/trends';
import InflationReductionAct from './components/home-equity/inflation-reduction-act';
import HowToRefinance from './components/home-equity/how-to-refinance';
import EfficiencyUpgrades from './components/maintenance/efficiency-upgrades';
import SettlingHeiWithCash from './components/settling-your-home-equity-investment-with-cash-savings';
import SettlingHeiWithRefinance from './components/settling-your-home-equity-investment-with-a-refinance';
import SettlingHeiWithHomeLoanOrHeloc from './components/settling-your-home-equity-investment-with-a-home-loan-or-heloc';
import FinancialHealth from './components/why-your-financial-health-matters';
import kitchenDesignTrendsContent from './components/kitchen-design-trends';
import laborCrisisContent from './components/what-labor-crisis-could-mean-homeowners';
import calculateEquityContent from './components/how-to-calculate-home-equity';
import makeHomeownershipLessStressfulContent from './components/make-homeownership-less-stressful';
import investmentThroughLifeEventContent from './components/home-equity-investment-through-life-events';
import missingMortgagePaymentContent from './components/missing-mortgage-payment';
import questionsForContractorContent from './components/questions-for-contractor';
import homeMaintenanceMistakesContent from './components/home-maintenance-mistakes';

import payDebt from 'images/dashboard/pay-debt-or-save-hero-scaled.png';

import propertyTaxes from 'images/dashboard/property-taxes-hero-scaled.png';

import sellOrTap from 'images/dashboard/sell_or_tap_hero-scaled.png';

import houseAppreciation from 'images/dashboard/house-appreciation-hero-1-scaled.png';

import solarCredit from 'images/dashboard/solar-credit-hero-scaled.png';

import lake from 'images/dashboard/lakefront.png';

import greenHome from 'images/dashboard/green-home-hero-scaled.png';

import simpliSafe from 'images/dashboard/simpli-safe.png';
import ladder from 'images/dashboard/ladder.png';
import coins from 'images/dashboard/coins.png';

import walnutWood from 'images/dashboard/walnut_wood.png';

import heiHomeSale from 'images/dashboard/hei-home-sale.png';

import heiRefinance from 'images/dashboard/hei-refinance.png';
import heiCash from 'images/dashboard/hei-cash.png';

import heiHeloc from 'images/dashboard/hei-heloc.png';

import financeHealth from 'images/dashboard/finance-health.png';

import kitchenDesignTrendsImage from 'images/dashboard/kitchen-design-trends.jpg';

import laborCrisisImage from 'images/dashboard/labor-crisis.jpg';

import calculateEquityImage from 'images/dashboard/how-to-calculate-home-equity.jpg';

import makeHomeownershipLessStressfulImage from 'images/dashboard/make-homeownership-less-stressful.jpg';

import investmentThroughLifeEventImage from 'images/dashboard/home-equity-investment-through-life-events.jpg';

import missingMortgagePaymentImage from 'images/dashboard/missing-mortgage-payment.jpg';

import hiringContractorImage from 'images/dashboard/hiring-contractor.png';

import homeMaintenanceMistakesImage from 'images/dashboard/home-maintenance-mistakes.jpg';

import { FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID } from '../constants';

const KITCHEN_DESIGN_TRENDS = {
  title: 'Kitchen Design Trends to Consider for Your 2025 Renovation',
  content: kitchenDesignTrendsContent,
  excerpt: 'Kitchen Design Trends to Consider for Your 2025 Renovation',
  coverImage: kitchenDesignTrendsImage,
  date: '2024-03-04',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 8, // in minutes
  urlSlug: 'kitchen-trends-ideas',
};

const FINANCIAL_HEALTH = {
  title: 'Why Your Financial Health Matters',
  content: FinancialHealth,
  excerpt: 'Why Your Financial Health Matters',
  coverImage: financeHealth,
  date: '2024-09-05',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'why-your-financial-health-matters',
};

const LABOR_CRISIS = {
  title: 'What a Potential Labor Crisis Could Mean for Homeowners',
  content: laborCrisisContent,
  excerpt: 'What a Potential Labor Crisis Could Mean for Homeowners',
  coverImage: laborCrisisImage,
  date: '2024-10-03',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MARKET_INSIGHTS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 4, // in minutes
  urlSlug: 'what-labor-crisis-could-mean-homeowners',
};

const CRASH_COURSE_HOME_EQUITY = {
  title: 'A Crash Course on Home Equity',
  content: calculateEquityContent,
  excerpt: 'A Crash Course on Home Equity',
  coverImage: calculateEquityImage,
  date: '2021-02-22',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 11, // in minutes
  urlSlug: 'how-to-calculate-home-equity',
};

const SETTLING_HEI_WITH_HOME_SALE = {
  title: 'Settling Your Home Equity Investment with a Home Sale',
  content: SettlingHeiWithHomeSale,
  excerpt: 'Settling Your Home Equity Investment with a Home Sale',
  coverImage: heiHomeSale,
  date: '2024-09-04',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'settling-your-home-equity-investment-with-a-home-sale',
};

const TIPS_TO_STREAMLINE_HOMEOWNERSHIP = {
  title: '9 Tips to Streamline Homeownership and Minimize Stress',
  content: makeHomeownershipLessStressfulContent,
  excerpt: '9 Tips to Streamline Homeownership and Minimize Stress',
  coverImage: makeHomeownershipLessStressfulImage,
  date: '2024-05-17',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'make-homeownership-less-stressful',
};

const MANAGING_LIFE_EVENT = {
  title: 'Managing Your Home Equity Investment Through Life Events',
  content: investmentThroughLifeEventContent,
  excerpt: 'Managing Your Home Equity Investment Through Life Events',
  coverImage: investmentThroughLifeEventImage,
  date: '2024-10-16',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 4, // in minutes
  urlSlug: 'your-home-equity-investment-through-life-events',
};

const PRIORITIZING_FINANCIAL_GOALS = {
  title: 'Pay Off Debt or Build Savings? Prioritizing Your Financial Goals',
  content: PrioritizingFinancialGoals,
  excerpt: 'Pay Off Debt or Build Savings? Prioritizing Your Financial Goals',
  coverImage: payDebt,
  date: '2022-08-17',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'pay-off-debt-build-savings-prioritizing-financial-goals',
};

const UNDERSTANDING_PROPERTY_TAXES = {
  title: 'Understanding Property Taxes and Why They Increase',
  content: WhyPropertyTaxesIncrease,
  excerpt: 'Understanding Property Taxes and Why They Increase',
  coverImage: propertyTaxes,
  date: '2021-08-10',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'why-property-taxes-increase',
};

const SHOULD_I_SELL_MY_HOME = {
  title: 'Should You Sell Your Home or Tap Into Your Home Equity?',
  content: ShouldISellMyHome,
  excerpt: 'Should You Sell Your Home or Tap Into Your Home Equity?',
  coverImage: sellOrTap,
  date: '2021-06-01',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 5, // in minutes
  urlSlug: 'should-i-sell-my-home',
};

const TRENDS_HOME_APPRECIATION = {
  title: 'What Trends Are Causing House Prices to Appreciate?',
  content: Trends,
  excerpt: 'What Trends Are Causing House Prices to Appreciate?',
  coverImage: houseAppreciation,
  date: '2020-04-13',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'what-causes-home-value-to-appreciate',
};

const MISSING_MORTGAGE_PAYMENT = {
  title: 'Missing a Mortgage Payment After Receiving Your HEI: A Guide',
  content: missingMortgagePaymentContent,
  excerpt: 'Missing a Mortgage Payment After Receiving Your HEI: A Guide',
  coverImage: missingMortgagePaymentImage,
  date: '2024-10-30',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'missing-mortgage-payment-after-home-equity-investment',
};

const INFLATION_REDUCTION_ACT = {
  title: 'How the Inflation Reduction Act is Helping Homeowners Go Solar',
  content: InflationReductionAct,
  excerpt: 'How the Inflation Reduction Act is Helping Homeowners Go Solar',
  coverImage: solarCredit,
  date: '2023-04-11',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'inflation-reduction-act-helps-homeowners-go-solar',
};

const HOW_TO_REFINANCE = {
  title: 'How to Refinance: A Guide to Refinancing Your Mortgage',
  content: HowToRefinance,
  excerpt: 'How to Refinance: A Guide to Refinancing Your Mortgage',
  coverImage: lake,
  date: '2022-04-01',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'how-to-refinance-mortgage',
};

const SETTLING_HEI_WITH_CASH = {
  title: 'Settling Your Home Equity Investment with Savings',
  content: SettlingHeiWithCash,
  excerpt: 'Settling Your Home Equity Investment with Savings',
  coverImage: heiCash,
  date: '2024-09-06',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 5, // in minutes
  urlSlug: 'settling-your-home-equity-investment-with-cash-savings',
};

const SETTLING_HEI_WITH_REFINANCE = {
  title: 'Settling Your Home Equity Investment with a Refinance',
  content: SettlingHeiWithRefinance,
  excerpt: 'Settling Your Home Equity Investment with a Refinance',
  coverImage: heiRefinance,
  date: '2024-09-05',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 5, // in minutes
  urlSlug: 'settling-your-home-equity-investment-with-a-refinance',
};

const SETTLING_HEI_WITH_HOME_LOAN_OR_HELOC = {
  title: 'Settling Your Home Equity Investment with a Home Loan or HELOC',
  content: SettlingHeiWithHomeLoanOrHeloc,
  excerpt: 'Settling Your Home Equity Investment with a Home Loan or HELOC',
  coverImage: heiHeloc,
  date: '2024-09-07',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'settling-your-home-equity-investment-with-a-home-loan-or-heloc',
};

const QUESTIONS_FOR_CONTRACTOR = {
  title: '11 Questions Homeowners Should Ask When Hiring a Contractor',
  content: questionsForContractorContent,
  excerpt: '11 Questions Homeowners Should Ask When Hiring a Contractor',
  coverImage: hiringContractorImage,
  date: '2023-09-22',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 10, // in minutes
  urlSlug: '11-questions-homeowners-should-ask-when-hiring-contractor',
};

const AVOIDABLE_HOME_MAINTENANCE_MISTAKES = {
  title: 'Top 6 Avoidable Home Maintenance Mistakes, According to Home Inspectors',
  content: homeMaintenanceMistakesContent,
  excerpt: 'Top 6 Avoidable Home Maintenance Mistakes, According to Home Inspectors',
  coverImage: homeMaintenanceMistakesImage,
  date: '2023-09-07',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 7, // in minutes
  urlSlug: 'home-maintenance-mistakes-according-to-inspectors',
};

const FINANCING_HOME_RENOVATION = {
  title: 'Financing a Home Renovation: What Renovation Loan Is Best?',
  content: FinancingHomeRenovation,
  excerpt: 'Financing a Home Renovation: What Renovation Loan Is Best?',
  coverImage: ladder,
  date: '2020-07-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.HOME_FINANCING,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'home-renovation-loans',
};

const SAVING_FOR_HOME_REPAIRS = {
  title: 'How to Save for Home Repairs Before They’re Needed',
  content: SaveForHomeRepairs,
  excerpt: 'How to Save for Home Repairs Before They’re Needed',
  coverImage: coins,
  date: '2019-03-23',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'how-much-to-save-for-home-maintenance-and-repairs',
};

const PRIORITIZE_HOME_RENOVATION = {
  title: 'How to Prioritize Home Renovations and Repairs',
  content: PrioritizeHomeRenovations,
  excerpt: 'How to Prioritize Home Renovations and Repairs',
  coverImage: walnutWood,
  date: '2023-05-03',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6, // in minutes
  urlSlug: 'prioritize-renovations-and-repairs',
};

export const SIMPLI_SAFE = {
  id: FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID,
  theme: 'primary',
  title: 'Give your family the gift of a safer home.',
  text: 'Receive an exclusive 50% Hometap discount on SimpliSafe + 2 months of free monitoring.',
  linkText: 'Save now',
  href: 'https://simplisafe.com/hometap?utm_source=hometap&utm_medium=partnerdigital&utm_campaign=sr',
  image: simpliSafe,
  disclaimer: 'Hometap may receive compensation when you purchase a SimpliSafe kit.',
};

export const EFFICIENCY_UPGRADES = {
  title: '9 Home Efficiency Upgrades Homeowners Should Know About',
  content: EfficiencyUpgrades,
  excerpt: '9 Home Efficiency Upgrades Homeowners Should Know About',
  coverImage: greenHome,
  date: '2021-04-15',
  author: 'Hometap',
  category: RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  subCategory: '',
  type: RESOURCE_TYPES.ARTICLE,
  length: 6,
  urlSlug: 'green-home-efficiency-upgrades',
};

export const FEATURED_RESOURCE = {
  ...KITCHEN_DESIGN_TRENDS,
};

export const FEATURED_RESOURCES_LIST = [
  FINANCIAL_HEALTH,
  LABOR_CRISIS,
  CRASH_COURSE_HOME_EQUITY,
  TIPS_TO_STREAMLINE_HOMEOWNERSHIP,
];

export const FINANCING_MY_GOALS_LIST = [
  PRIORITIZING_FINANCIAL_GOALS,
  UNDERSTANDING_PROPERTY_TAXES,
  SHOULD_I_SELL_MY_HOME,
];

export const PROTECT_MY_INVESTMENT_LIST = [
  SETTLING_HEI_WITH_CASH,
  SETTLING_HEI_WITH_REFINANCE,
  SETTLING_HEI_WITH_HOME_LOAN_OR_HELOC,
];

export const GROW_MY_HOME_VALUE_AND_EQUITY_LIST = [TRENDS_HOME_APPRECIATION, INFLATION_REDUCTION_ACT, HOW_TO_REFINANCE];

export const RENOVATIONS = [FINANCING_HOME_RENOVATION, SAVING_FOR_HOME_REPAIRS, PRIORITIZE_HOME_RENOVATION];

export const RESOURCE_ARTICLE_TILES = [
  PRIORITIZING_FINANCIAL_GOALS,
  UNDERSTANDING_PROPERTY_TAXES,
  MANAGING_LIFE_EVENT,
  TRENDS_HOME_APPRECIATION,
  MISSING_MORTGAGE_PAYMENT,
  SHOULD_I_SELL_MY_HOME,
  SETTLING_HEI_WITH_HOME_SALE,
  QUESTIONS_FOR_CONTRACTOR,
  AVOIDABLE_HOME_MAINTENANCE_MISTAKES,
  SETTLING_HEI_WITH_CASH,
  SETTLING_HEI_WITH_REFINANCE,
  SETTLING_HEI_WITH_HOME_LOAN_OR_HELOC,
];

export const ALL_POSTS = [
  FEATURED_RESOURCE,
  ...FEATURED_RESOURCES_LIST,
  ...FINANCING_MY_GOALS_LIST,
  ...GROW_MY_HOME_VALUE_AND_EQUITY_LIST,
  ...RENOVATIONS,
  ...PROTECT_MY_INVESTMENT_LIST,
  ...RESOURCE_ARTICLE_TILES,
  EFFICIENCY_UPGRADES,
];
