import { FormError, SelectionSet, useErrorScroll, useForm } from '@hometap/htco-components';
import TrackButton from '../../../../../../components/TrackButton/TrackButton';
import { USE_CASES } from '../../../constants/useCases';

import './QuizUseCaseForm.scss';

// @ts-expect-error TS(7031): Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
const QuizUseCaseForm = ({ onSubmit }) => {
  const { handleSubmit, registerField, formSubmitError } = useForm();
  useErrorScroll(formSubmitError);

  return (
    <div className="QuizUseCaseForm">
      <FormError standalone error={formSubmitError} />
      <h1>How could a Hometap Investment most help you?</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectionSet
          label={
            <em className="QuizUseCaseFormSubtext">
              Please choose one. If several options apply, let us know which is most important to you.
            </em>
          }
          labelWidth="50%"
          labelGrow={false}
          options={USE_CASES.map(use => {
            return { label: use.key, value: use.value, icon: use.icon };
          })}
          required
          {...registerField('use_case')}
        />

        <TrackButton eventProperties={{ destination: '/fit-quiz' }} type="submit">
          Continue
        </TrackButton>
      </form>
    </div>
  );
};

export default QuizUseCaseForm;
