import cx from 'classnames';
import PropTypes from 'prop-types';

import { SlideDown, Icon } from '@hometap/htco-components';

import { triggerClickOnKeyPress } from 'utils/a11y';

import NavImageAndTitle from '../NavImageAndTitle/NavImageAndTitle';
import useNavAccordion, { NavAccordionProvider } from '../hooks/useNavAccordion/useNavAccordion';
import { twMerge } from 'tailwind-merge';
import NavTooltip from '../NavTooltip/NavTooltip';
import { buildHomeProfileUrl } from 'utils/links';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const NavAccordion = ({ children, open, onToggle, className, dataTestId, canExpand = true }) => {
  return (
    <NavAccordionProvider canExpand={canExpand} onToggle={onToggle} open={open}>
      <div className={twMerge(cx('w-full', className))} data-testid={dataTestId}>
        <div className="w-full">{children}</div>
      </div>
    </NavAccordionProvider>
  );
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const NavAccordionContent = ({ children, animationDuration }) => {
  // @ts-expect-error TS(2339): Property 'open' does not exist on type 'unknown'.
  const { open } = useNavAccordion();
  return (
    <SlideDown duration={animationDuration} visible={open}>
      {children}
    </SlideDown>
  );
};

// @ts-expect-error TS(7031): Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
const AccessibleTouchPoint = ({ onClick, onKeyDown, onPressProps }) => {
  return (
    <div
      data-testid="property-dropdown-container"
      className="absolute left-0 right-0 top-1/2 z-[1] h-full min-h-[40px] -translate-y-1/2 cursor-pointer focus:rounded focus:outline-none focus:outline-1 focus:outline-blue-100"
      onClick={open => {
        onClick(open);
      }}
      tabIndex="0"
      role="button"
      onKeyDown={onKeyDown}
      {...onPressProps}
    />
  );
};

// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const NavAccordionToggle = ({ title, titleIndicator, onPressProps, disabled }) => {
  // @ts-expect-error TS(2339): Property 'open' does not exist on type 'unknown'.
  const { open, onToggle, canExpand } = useNavAccordion();

  return (
    <div className="relative min-h-[29px] w-full" title={typeof title === 'string' ? title : ''}>
      {!disabled && (
        <AccessibleTouchPoint onClick={onToggle} onKeyDown={triggerClickOnKeyPress} onPressProps={onPressProps} />
      )}
      <div
        className={cx('flex flex-1 items-center', {
          'justify-between': canExpand,
        })}
      >
        <NavImageAndTitle title={canExpand && title} titleIndicator={titleIndicator} />
        {canExpand && (
          <div
            className={cx('transform pl-1 transition-transform duration-300', { 'rotate-180': open })}
            data-testid="nav-accordion-icon"
          >
            {disabled ? (
              <NavTooltip
                title="Finish your home profile"
                description="Add more details about your property to unlock the scenario planner"
                link={buildHomeProfileUrl()}
                linkText="Finish your profile"
              >
                <Icon name="lock" />
              </NavTooltip>
            ) : (
              <Icon name="chevron2-down" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

NavAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  testid: PropTypes.string,
  onToggle: PropTypes.func,
  canExpand: PropTypes.bool,
};

NavAccordionToggle.propTypes = {
  title: PropTypes.node,
  titleIndicator: PropTypes.node,
  onPressProps: PropTypes.object,
};

NavAccordionContent.propTypes = {
  children: PropTypes.node.isRequired,
  animationDuration: PropTypes.number,
};

AccessibleTouchPoint.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPressProps: PropTypes.object,
};

NavAccordion.Toggle = NavAccordionToggle;
NavAccordion.Content = NavAccordionContent;
export default NavAccordion;
