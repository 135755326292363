import { apiWithAuth } from 'utils/api';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';

// IMPORTANT: Be sure to always call `viewRundown` when a homeowner views a Rundown. This is
// necessary for a legal audit trail.

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchRundown = async id => {
  return await apiWithAuth.v1.get(`/rundowns/${id}`);
};

// @ts-expect-error TS(7006): Parameter 'home_value' implicitly has an 'any' typ... Remove this comment to see the full error message
export const createAndFetchShareFractions = async (home_value, investment_amount) => {
  return await apiWithAuth.v1.post('/pricing/share-fractions', { home_value, investment_amount });
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchShareFraction = async (id, type, amount_gross, home_value) => {
  const params = type === 'rundowns' ? { amount_gross, home_value } : {};
  const results = await apiWithAuth.v1.get(`/${type}/${id}/share-fractions`, { params });
  // @ts-expect-error TS(2339): Property 'sort' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
  return results.sort((a, b) => a.range_years_upper - b.range_years_upper);
};

// @ts-expect-error TS(7006): Parameter 'type' implicitly has an 'any' type.
export const sendInteractionEvent = (type, rundown = {}, misc = {}) => {
  browserTrack.homeownerInvestmentRundownInteractionOccurred({
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    id: rundown.id,
    interaction_type: type,
    // @ts-expect-error TS(2339): Property 'description' does not exist on type '{}'... Remove this comment to see the full error message
    description: rundown.description,
    // @ts-expect-error TS(2339): Property 'is_offer' does not exist on type '{}'.
    document_type: rundown.is_offer ? 'offer' : 'rundown',
    version_number: getVersion(),
    ...misc,
  });
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const viewRundown = async id => {
  await apiWithAuth.v1.post(`/rundowns/${id}/actions/view/`);
};
