// @ts-expect-error TS(7006): Parameter 'prefix' implicitly has an 'any' type.
const formatCookieDataForApi = (cookieList = [], cookieData = {}, prefixProperties = [], prefix) => {
  return Object.keys(cookieData).reduce((utmData, cookieKey) => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    if (cookieList.includes(cookieKey)) {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      if (prefixProperties.includes(cookieKey)) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        utmData[`${prefix}${cookieKey}`] = cookieData[cookieKey];
      } else {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        utmData[cookieKey] = cookieData[cookieKey];
      }
    }
    return utmData;
  }, {});
};

export default formatCookieDataForApi;
