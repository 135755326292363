import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import { Button, SlideSide, Icon } from '@hometap/htco-components';

import AddNewPropertyModal from 'components/modals/AddNewPropertyModal/AddNewPropertyModal';
import NavPrimaryMenu from 'components/nav/NavPrimaryMenu/NavPrimaryMenu';
import PrimaryMobileHeader from 'components/Headers/PrimaryMobileHeader/PrimaryMobileHeader';
import NavHomePickerDropdown from 'components/nav/NavHomePickerDropdown/NavHomePickerDropdown';

import { ANIMATION_VARIANT, headerVariants, sidebarVariants } from './animationVariants';
import usePrimaryNavItems from './hooks/usePrimaryNavItems';
import usePrimaryNav from './hooks/usePrimaryNav';
import PrimaryDesktopHeader from 'components/Headers/PrimaryDesktopHeader/PrimaryDesktopHeader';
import { twMerge } from 'tailwind-merge';
import { useCurrentHome, useUserHomes } from 'hooks/useCurrentHome';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import PageTitle from 'components/PageTitle';
import { ProgressBar } from 'components/ProgressProvider/ProgressProvider';

// @ts-expect-error TS(7031): Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
const DesktopSidebarCollapseButton = ({ onClick, isDesktopNavOpen }) => {
  return (
    <Button
      data-testid="sidebar-toggle-button"
      theme="link"
      className="[&.htco-isLink]:text-neutral-dark-75 [&.htco-isLink]:hover:text-neutral-dark-75 [&.htco-isLink]:hover:!no-underline"
      onClick={onClick}
    >
      <Icon
        name="chevron-right-double"
        size="2x"
        className={cx('transition-all', { 'rotate-180': isDesktopNavOpen })}
      />

      {isDesktopNavOpen && 'Collapse'}
    </Button>
  );
};

// @ts-expect-error TS(7031): Binding element 'slideState' implicitly has an 'an... Remove this comment to see the full error message
const DesktopSidebarContent = ({ slideState, children }) => {
  // @ts-expect-error TS(2339): Property 'onSlideStateChange' does not exist on ty... Remove this comment to see the full error message
  const { onSlideStateChange, onChangeIsDesktopNavOpen, isDesktopNavOpen } = usePrimaryNav();

  useEffect(() => {
    onSlideStateChange(slideState);
  }, [slideState, onSlideStateChange]);

  return (
    <div className="flex min-h-screen flex-col overflow-y-auto overflow-x-hidden">
      {children}
      <div className="relative mt-auto flex w-full flex-col items-center before:absolute before:left-0 before:right-0 before:h-px before:bg-neutral-light-100 before:content-['']">
        <div className="flex h-full w-full max-w-[calc(100%-theme(spacing.12))] flex-col items-start gap-3 py-4 transition-all duration-500">
          <DesktopSidebarCollapseButton
            isDesktopNavOpen={isDesktopNavOpen}
            onClick={() => onChangeIsDesktopNavOpen(!isDesktopNavOpen)}
          />
        </div>
      </div>
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const SidebarContainer = ({ children, mobileNavMenuControls }) => {
  // @ts-expect-error TS(2339): Property 'isDesktopNavOpen' does not exist on type... Remove this comment to see the full error message
  const { isDesktopNavOpen, isMobile, isInitialSmallDesktopRender, onChangeIsDesktopNavOpen, shouldOverlaySidebar } =
    usePrimaryNav();

  const baseClassName =
    'left-0 top-0 flex flex-shrink-0 flex-col border border-y-0 border-l-0 border-r-0 border-solid border-neutral-light-100 bg-white';

  return isMobile ? (
    <motion.aside
      className={twMerge(cx(baseClassName, 'absolute inset-0 z-10 min-h-screen'))}
      initial={ANIMATION_VARIANT.sidebarClosed}
      animate={mobileNavMenuControls}
      variants={sidebarVariants}
    >
      {children}
    </motion.aside>
  ) : (
    <>
      <SlideSide
        visible={isDesktopNavOpen}
        className={twMerge(
          cx(
            baseClassName,
            'sticky z-20 min-h-screen w-full min-w-[80px] border-r [&_+_.htco-SlideSideBackdrop]:z-[11]',
            {
              fixed: shouldOverlaySidebar,
            },
          ),
        )}
        appear={false}
        minWidth={80}
        maxWidth={360}
        duration={isInitialSmallDesktopRender ? 0 : 500}
        isOverlay={shouldOverlaySidebar}
        displayBackdrop={shouldOverlaySidebar}
        onBackdropClick={() => {
          onChangeIsDesktopNavOpen(false);
        }}
      >
        {/* @ts-expect-error TS(7031): Binding element 'slideState' implicitly has an 'an... Remove this comment to see the full error message */}
        {({ slideState }) => {
          return <DesktopSidebarContent slideState={slideState}>{children}</DesktopSidebarContent>;
        }}
      </SlideSide>
      {/* Placeholder width for sidebar overlay */}
      <div className={cx(baseClassName, 'w-[80px]', { hidden: !shouldOverlaySidebar })} />
    </>
  );
};

const PrimaryLayout = () => {
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ home:... Remove this comment to see the full error message
  const { loading, home, setHomeById, userCanAccessHome } = useCurrentHome();
  const { data: homes } = useUserHomes();
  const [isNewPropertyModalOpen, setNewPropertyModalOpen] = useState(false);
  // @ts-expect-error TS(2339): Property 'isDesktopNavOpen' does not exist on type... Remove this comment to see the full error message
  const { isDesktopNavOpen, onChangeIsDesktopNavOpen, isMobile, isMobileNavOpen, onMobileNavOpen, isSmallDesktop } =
    usePrimaryNav();

  const mobileHeaderControls = useAnimation();
  const mobileNavMenuControls = useAnimation();

  const handleLocationChange = () => {
    if (isMobileNavOpen && isMobile) {
      onMobileNavOpen(false);
      mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarClosed);
    }
  };

  const handleNavItemClick = () => {
    if (!isDesktopNavOpen) {
      onChangeIsDesktopNavOpen(true);
    } else if (isSmallDesktop || (isMobile && !isMobileNavOpen)) {
      onChangeIsDesktopNavOpen(false);
    }
  };

  const { navSectionGroups, openSection, navLinks, onToggleOpenSection, activePage } = usePrimaryNavItems({
    isMobile,
    onLocationChange: handleLocationChange,
    home: home ?? {},
    userCanAccessHome,
  });

  const SideBarHeader = NavHomePickerDropdown[isMobile ? 'Mobile' : 'Desktop'];

  const pageRef = useRef();

  return (
    // @ts-expect-error TS(2739): Type '{ children: (false | Element)[]; loading: an... Remove this comment to see the full error message
    <SectionLoadingWrapper loading={loading}>
      {isNewPropertyModalOpen && <AddNewPropertyModal setIsOpen={setNewPropertyModalOpen} />}

      <div
        className={twMerge(
          cx('relative h-screen overflow-y-auto sm:flex sm:overflow-x-auto', {
            'overflow-x-hidden': !isMobile,
            'h-auto min-h-screen overflow-x-hidden': isMobile && isMobileNavOpen,
            'overflow-y-hidden': isSmallDesktop && isDesktopNavOpen,
          }),
        )}
        id="primary-layout-scroll"
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={pageRef}
      >
        {isMobile && (
          <motion.div
            initial={ANIMATION_VARIANT.show}
            animate={isMobile && mobileHeaderControls}
            className={cx('sticky top-0 w-full border-0 border-b border-solid border-neutral-light-100 bg-white', {
              'z-20': !isMobileNavOpen,
            })}
            variants={headerVariants}
          >
            <PrimaryMobileHeader
              pageRef={pageRef}
              onSlideDown={() => mobileHeaderControls.start(ANIMATION_VARIANT.hidden)}
              onSlideUp={() => mobileHeaderControls.start(ANIMATION_VARIANT.show)}
              // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
              title={home?.address.street}
              onIconClick={() => {
                mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarOpen);
                onMobileNavOpen(true);
              }}
            />
            <ProgressBar className="relative z-20 -mt-[1px]" />
          </motion.div>
        )}

        <SidebarContainer mobileNavMenuControls={mobileNavMenuControls}>
          <div className="flex items-center border-0 border-b border-solid border-neutral-light-100 sm:min-h-[87px] sm:p-[1.125rem]">
            {home && (
              <SideBarHeader
                // @ts-expect-error TS(2322): Type 'AxiosResponse<any, any> | undefined' is not ... Remove this comment to see the full error message
                homes={homes}
                onToggleOpenNavSection={onToggleOpenSection}
                selectedHome={home}
                onSelectHome={setHomeById}
                onAddNewProperty={setNewPropertyModalOpen}
                onIconClick={() => {
                  mobileNavMenuControls.start(ANIMATION_VARIANT.sidebarClosed);
                  onMobileNavOpen(false);
                }}
              />
            )}
          </div>

          {/* @ts-expect-error TS(2741): Property 'dataTestId' is missing in type '{ openSe... Remove this comment to see the full error message */}
          <NavPrimaryMenu
            openSection={isDesktopNavOpen ? openSection : null}
            onToggleOpenSection={onToggleOpenSection}
            navSectionGroups={navSectionGroups}
            navLinks={navLinks}
            onNavItemClick={handleNavItemClick}
          />
        </SidebarContainer>

        <div className={cx('w-full', { 'h-screen overflow-hidden': isMobile && isMobileNavOpen })}>
          {/* @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'. */}
          <PrimaryDesktopHeader title={!isDesktopNavOpen && home?.address.street} />
          <PrimaryLayoutGrid className="mt-8">
            {!activePage?.pageTitleHidden && (
              <PageTitle className={activePage?.pageTitleClassName} title={activePage?.title} />
            )}
          </PrimaryLayoutGrid>
          <Outlet />
        </div>
      </div>
    </SectionLoadingWrapper>
  );
};

export default PrimaryLayout;
