import { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';

import { Button, SlideDown, useAsync } from '@hometap/htco-components';
import LegalLink from 'components/LegalLink/LegalLink';
import useLogAsyncError from 'hooks/useLogAsyncError';
import google from 'images/equity-planner/onboarding/google.png';
import homeRenovation from 'images/equity-planner/onboarding/home-renovation.png';
import { formatFullAddress } from 'utils/address';
import env from 'utils/env';
import { sendPasswordInitializationLink } from 'apps/dashboard/data/sendPasswordInitializationLink';
import { OnboardingLayout, Panel } from '../template/OnboardingTemplate';
import OnboardingPanelCard from './OnboardingPanelCard';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { generateMagicLink } from '../../../data/magicLink';
import './OnboardingSignup.scss';

// @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
const OnboardingSignup = ({ form, onNext }) => {
  const { formData, isFormValid } = form;
  const { street, unit, city, state, zip_code, email } = formData;
  const emailContainsPlus = email.includes('+');

  const {
    error: magicLinkError,
    loading: magicLinkLoading,
    results: magicLinkResults,
    execute: generateNewMagicLink,
  } = useAsync(generateMagicLink);

  const {
    error: initPasswordError,
    loading: initPasswordLoading,
    results: initPasswordResults,
    execute: initPassword,
  } = useAsync(sendPasswordInitializationLink);

  const [cookies] = useCookies(['portalscsrftoken']);
  const [csrfToken, setCsrfoken] = useState('');
  const formRef = useRef();

  useLogAsyncError('Magic link generation failed', magicLinkError);
  useLogAsyncError('Password initialization failed', initPasswordError);

  const onMagicLinkCreate = async () => {
    if (!isFormValid) return;

    await generateNewMagicLink({ email, use_case: 'hed_signup' });
  };

  const onSocialLinkSignup = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    formRef.current.submit();
  };

  const onPasswordSignup = async () => {
    if (!isFormValid) return;

    await initPassword(email);
  };

  useEffect(() => {
    setCsrfoken(cookies?.portalscsrftoken);
  }, [cookies.portalscsrftoken]);

  useEffect(() => {
    if (magicLinkResults || initPasswordResults) onNext();
  }, [magicLinkResults, initPasswordResults, onNext]);

  const API_BASE = () => {
    if (process.env.REACT_APP_BACKEND_BASE) {
      return `${process.env.REACT_APP_BACKEND_BASE}`;
    }
    return env.getBackendUrlForEnv();
  };

  const showError = !!magicLinkError || !!initPasswordError;

  return (
    <div className="OnboardingSignup" data-testid="signup-page">
      <OnboardingLayout>
        {/* @ts-expect-error TS(2739): Type '{ children: Element[]; }' is missing the fol... Remove this comment to see the full error message */}
        <Panel>
          <h1>Ready to take control of your equity?</h1>
          <div className="OnboardingSignupSubtitle">Select a method to sign into your account.</div>
          <div>
            <div className="OnboardingSignupMagicLink">
              <Button
                data-testid="magic-link-button"
                loading={magicLinkLoading}
                theme="primary"
                onClick={onMagicLinkCreate}
                disabled={!isFormValid}
              >
                Sign in with a magic link
              </Button>
            </div>
            <div className="OnboardingSignupPassword">
              <Button
                data-testid="password-button"
                loading={initPasswordLoading}
                theme="secondary"
                disabled={!isFormValid}
                onClick={onPasswordSignup}
              >
                Sign in with a password
              </Button>
            </div>

            {/*
              If email address contains "+", we assume it is someone using the "+" trick with a
              Google account, and don't show the Google sign in option (since it won't work).
            */}
            {!emailContainsPlus && (
              // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
              <form ref={formRef} action={`${API_BASE()}accounts/google/login/`} method="post">
                <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
                <button
                  data-testid="google-auth-button"
                  className="OnboardingSignupGoogle"
                  type="button"
                  onClick={onSocialLinkSignup}
                >
                  <img src={google} alt="Google logo" />
                  Sign in with Google
                </button>
              </form>
            )}

            {showError && (
              <div className="OnboardingSignupError">
                <SlideDown visible={showError}>
                  {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
                  <ErrorMessage>
                    <span>We are unable to reach the server, please try again.</span>
                  </ErrorMessage>
                </SlideDown>
              </div>
            )}
          </div>
          <div className="OnboardingSignupTerms">
            By signing in, you agree to Hometap’s{' '}
            <LegalLink section="terms-of-use" text="Terms of Use" data-testid="terms-of-use" />, and{' '}
            <LegalLink section="privacy-policy" text="Privacy Policy" data-testid="privacy-policy" />,{' '}
          </div>
        </Panel>
        {/* @ts-expect-error TS(2739): Type '{ children: Element; containsVisual: true; }... Remove this comment to see the full error message */}
        <Panel containsVisual>
          {/* @ts-expect-error TS(2741): Property 'dataTestId' is missing in type '{ header... Remove this comment to see the full error message */}
          <OnboardingPanelCard
            headerText="Imagine the possibilities by exploring renovation scenarios that match your needs and budget."
            footerText={formatFullAddress(street, unit, city, state, zip_code)}
            imgSrc={homeRenovation}
            imgAlt="annual appreciation scenarios"
            data-testid="panel-image"
          />
        </Panel>
      </OnboardingLayout>
    </div>
  );
};

export default OnboardingSignup;
