import { APPLICATION_SECTION_URL_NAME, buildApplicationRoute } from 'apps/application/utils';
import { STAGES } from 'apps/dashboard/constants';
import { startApplication } from 'apps/application/data/applicationRequests';
import { APPLICATION_CONFIG } from 'apps/application/utils';

import { Button } from '@hometap/htco-components';

import { INVESTMENT_AMOUNT_LABEL, PLACEHOLDER_TODO, UI_STAGE } from '../constants';
import { getNewInquiryUrl, getSettlementCalculatorUrl } from 'utils/links';
import TrackDetailsReviewCard from '../../components/TrackDetailsReviewCard/TrackDetailsReviewCard';

import {
  getAppraisalTypeTodoConfig,
  getMappedEstimateRundowns,
  getMappedOfferRundown,
  mapAsyncTodoToConfig,
} from './todoMappings';

import { isTodayAfterDate } from 'utils/date';
import { ROLLOUT_FLAGS } from 'hooks/useTrack';
import { determineShouldShowSettlementCalculator } from '../helpers';

export const config = {
  [UI_STAGE.Apply.key]: {
    title: 'Submit your information.',
    getSubtitle() {
      return 'Please review your remaining to-dos.';
    },
    badge: {
      text: '3 days',
      theme: 'primaryTeal15',
    },
    investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.ESTIMATE,
    showWithoutOtherStages: false,
    // @ts-expect-error TS(7031): Binding element 'asyncTodos' implicitly has an 'an... Remove this comment to see the full error message
    getTodos({ asyncTodos, application, rundowns, applicants, track, isRolloutFlagEnabled }) {
      const placeholderTodos = !application?.has_consented
        ? [
            PLACEHOLDER_TODO.GOV_ID_UPLOAD,
            PLACEHOLDER_TODO.MORTGAGE_STATEMENT_UPLOAD,
            PLACEHOLDER_TODO.HOMEOWNERS_INSURANCE_DECLARATION_UPLOAD,
          ]
        : [PLACEHOLDER_TODO.DOC_UPLOAD];

      const todosToShow = asyncTodos?.length
        ? // @ts-expect-error TS(7006): Parameter 'todo' implicitly has an 'any' type.
          asyncTodos.map(todo => mapAsyncTodoToConfig(todo, track))
        : placeholderTodos;

      return [
        {
          title: 'Start your Application.',
          subtitle: 'Let’s get started! Complete your Application in less than five minutes.',
          isComplete: !!application?.has_consented,
          icon: 'edit',
          timeToComplete: '5 minutes',
          isActive: true,
          cta: {
            // @ts-expect-error TS(7031): Binding element 'navigate' implicitly has an 'any'... Remove this comment to see the full error message
            onClickAsyncFunc: async ({ navigate, fetchTrack }) => {
              const applicationRoute = buildApplicationRoute(track?.id);
              const [applicationFirstPageData] = APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.applicants];
              const applicationFirstPage = applicationRoute({
                section: APPLICATION_SECTION_URL_NAME.applicants,
                pageKey: applicationFirstPageData.pageKey,
              });

              if (track?.active_application_id) {
                return navigate(applicationFirstPage);
              }

              await startApplication({ trackId: track?.id });
              await fetchTrack();

              return navigate(applicationFirstPage);
            },
            text: applicants?.length ? 'Edit your application' : 'Apply now',
          },
        },
        // dynamic from rundown
        ...getMappedEstimateRundowns({ rundowns, track }),
        ...todosToShow,
        isRolloutFlagEnabled(ROLLOUT_FLAGS.self_serve_appraisal_type) && getAppraisalTypeTodoConfig(track),
      ];
    },
  },

  [UI_STAGE.Review.key]: {
    // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
    determineIsActive({ track }) {
      return !!track?.initial_application_reviewed;
    },
    title: 'We’re working hard to review your Application.',
    getSubtitle() {
      return 'At this stage in the process, we may request additional documentation.';
    },
    badge: {
      text: '3-4 weeks',
      theme: 'primaryTeal15',
    },
    investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.ESTIMATE,

    showWithoutOtherStages: false,
    // @ts-expect-error TS(7031): Binding element 'asyncTodos' implicitly has an 'an... Remove this comment to see the full error message
    getTodos({ asyncTodos, rundowns, isActive, track }) {
      const placeholderTodos = [PLACEHOLDER_TODO.DOC_UPLOAD];

      const shouldShowAsyncData = (asyncData = []) => isActive && !!asyncData?.length;
      const todosToShow = shouldShowAsyncData(asyncTodos) ? asyncTodos.map(mapAsyncTodoToConfig) : placeholderTodos;
      const rundownsToShow = (shouldShowAsyncData(rundowns) && getMappedEstimateRundowns({ rundowns, track })) || [];
      return [...todosToShow, ...rundownsToShow];
    },
  },

  [UI_STAGE.Accept.key]: {
    title: 'Review and accept your Investment Offer.',
    // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
    getTitleOverride({ track }) {
      if (track?.current_stage === STAGES.OFFER_DECLINED) {
        return 'Offer declined.';
      }
    },
    // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
    getSubtitle({ track }) {
      if (track?.current_stage === STAGES.OFFER_DECLINED) {
        return 'We hope we can work together to help you reach your goals in the future! If anything changes, please reach out to your Investment Manager.';
      }
      return 'Congratulations! You’ve been approved. There are just a few more steps to go.';
    },
    badge: {
      text: '10 minutes',
      theme: 'primaryTeal15',
    },
    investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.OFFER,
    showWithoutOtherStages: false,

    // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
    getTodos({ track, offer }) {
      // @ts-expect-error TS(2345): Argument of type '{ offer: any; track: any; }' is ... Remove this comment to see the full error message
      const offerTodo = getMappedOfferRundown({ offer, track });

      if (!offerTodo) return [];
      return [offerTodo];
    },
  },

  [UI_STAGE.Sign.key]: {
    title: 'Sign and receive your funds.',
    getSubtitle() {
      return 'You’re so close!';
    },
    badge: {
      text: '1 week',
      theme: 'primaryTeal15',
    },
    showWithoutOtherStages: false,
    // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
    getTodos({ track }) {
      return [
        {
          title: 'Your funds are just a few steps away!',
          subtitle:
            'Once you schedule and complete your signing, you should receive the money in your account within 5-7 business days. If you’d like to rescind within the 3-day window, please let us know immediately.',
          icon: 'money-payment',
          isActive: !!track?.signing_datetime && isTodayAfterDate(track.signing_datetime),
          cta: {
            text: 'Contact us',
            href: 'mailto:withdraw@hometap.com',
          },
        },
      ];
    },

    investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.INVEST,
  },

  [UI_STAGE.Invested.key]: {
    title: 'Keep us in the loop!',
    badge: {
      text: 'Invested',
      theme: 'secondaryGreyOutlined',
    },
    showWithoutOtherStages: true,
    getSubtitle() {
      return (
        <div className="TrackDetailsStageSectionSubtitleSmall">
          <p>
            Please contact us BEFORE you take any of the following actions at any time during the 10-year effective
            period of your Investment:
          </p>
          <ol>
            <li>Refinancing your mortgage</li>
            <li>Renting or leasing your home to another party</li>
            <li>Listing your home for sale</li>
            <li>Accepting an offer to purchase your home (within 24 hours of receiving the offer)</li>
            <li>Taking another lien on your property, as we’ll need to approve it</li>
            <li>Settling your Hometap Investment</li>
          </ol>
          <p>
            Contact us at{' '}
            <Button theme="link" href="mailto:homeowners@hometap.com">
              homeowners@hometap.com
            </Button>{' '}
            or give us a call at (617) 604-6985.
          </p>
          <p>
            If you have any questions about your Investment, take a look at these{' '}
            <Button theme="link" href="/support">
              Frequently Asked Questions
            </Button>{' '}
            on the Support page. Can’t find what you’re looking for? The Investment Support team is here to help.
          </p>
        </div>
      );
    },
    // @ts-expect-error TS(7031): Binding element 'investmentSummary' implicitly has... Remove this comment to see the full error message
    getTodos({ investmentSummary }) {
      const shouldShowSettlementCalculator = determineShouldShowSettlementCalculator({ investmentSummary });

      if (!shouldShowSettlementCalculator) {
        return [];
      }
      return [
        {
          isActive: true,
          title: (
            <>
              <span className="TrackDetailsCardDescriptionTitlePrefix">New!</span> Check out the Settlement Calculator.
            </>
          ),
          subtitle: 'Find out how much it might cost you to settle your Investment.',
          icon: 'icon-money-calculator',
          cta: {
            text: 'Try it out',
            to: getSettlementCalculatorUrl(investmentSummary.id),
          },
        },
      ];
    },
    extraSectionContent: <TrackDetailsReviewCard />,
    investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.INVEST,
  },

  [UI_STAGE.Settled.key]: {
    title: 'Thanks for working with us!',
    badge: {
      text: 'Settled',
      theme: 'primaryTeal100',
    },
    investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.INVEST,
    showWithoutOtherStages: true,
    getSubtitle() {
      return (
        <span className="TrackDetailsStageSectionSubtitleSmall">
          You’ve officially settled your Investment. We appreciate you choosing Hometap, and hope we can work together
          again in the future.
        </span>
      );
    },
    getTodos() {
      return [
        {
          isActive: true,
          title: (
            <>
              <span className="TrackDetailsCardDescriptionTitlePrefix">New!</span> Start a new Investment.
            </>
          ),
          subtitle: 'Access your home equity without having to sell, stress, or borrow.',
          icon: 'icon-hand-money',
          cta: {
            text: 'Apply now',
            href: getNewInquiryUrl(),
          },
        },
      ];
    },
  },
};
