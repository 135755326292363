import { memo } from 'react';
import { Accordion } from '@hometap/htco-components';

import './FAQList.scss';

// @ts-expect-error TS(2339): Property 'heading' does not exist on type '{}'.
const FAQList = ({ heading, faqs, footer }) => (
  <div className="FAQContainer">
    <h2 className="FAQHeading">{heading}</h2>
    {/* @ts-expect-error TS(7006): Parameter 'faq' implicitly has an 'any' type. */}
    {faqs.map(faq => (
      <Accordion header={faq.question} key={faq.question} theme="with-plus">
        {faq.answer}
      </Accordion>
    ))}
    {footer}
  </div>
);

export default memo(FAQList);
