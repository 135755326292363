import { useCallback } from 'react';
import { MuiTextInput } from '@hometap/htco-components';
import { PHONE_NUMBER_PREFIX, phoneValidator } from 'utils/constants/phoneNumberUtils';

const PhoneNumberField = ({
  width = '100%',
  label = 'Phone Number',
  name = 'phone_number',
  required = false,
  ...props
}) => {
  const { onBlur, value, onChange } = props;

  const handleBlur = useCallback(
    (value: string, fieldKey: string, errMsg?: string) => {
      // If the field is unedited and only contains the pre-populated prefix, set the value back to empty string
      if (value === PHONE_NUMBER_PREFIX) {
        onChange('', name);
      }
      onBlur?.(value, fieldKey, errMsg);
    },
    [name, onBlur, onChange],
  );

  const handleFocus = useCallback(() => {
    // When the field becomes active, add the country code prefix to the value
    if (!value) {
      onChange(PHONE_NUMBER_PREFIX.concat(value), name);
    }
  }, [name, onChange, value]);

  return (
    <MuiTextInput
      type="tel"
      inputMode="tel"
      mask="+1 (000) 000-0000"
      unmask={false}
      maxLength={14}
      validator={phoneValidator}
      name={name}
      label={label}
      width={width}
      required={required}
      {...props}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export default PhoneNumberField;
