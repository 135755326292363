import { useEffect } from 'react';

/**
 * Handles the reinquiry data stored within the session storage and updating the form
 * @param {object} reinquiryData the reinquiry data stored within the session storage
 * @param {function} handleAutoFillValidAddress the function used to autofill address in the inquiry form
 */
// @ts-expect-error TS(7006): Parameter 'reinquiryData' implicitly has an 'any' ... Remove this comment to see the full error message
const useReinquiryDataHandleForm = (reinquiryData, handleAutoFillValidAddress) => {
  useEffect(() => {
    if (reinquiryData.isNewProperty) {
      const { isNewProperty: _, ...newReinquiryData } = reinquiryData;
      handleAutoFillValidAddress(newReinquiryData);
    } else if (reinquiryData.address) {
      // Since we're autofilling the address with the session data in re-inquiry, we validate the address automatically
      handleAutoFillValidAddress(reinquiryData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reinquiryData]);
};

export default useReinquiryDataHandleForm;
