import { Button, Container } from '@hometap/htco-components';
import Hero from './Hero';

import './ImageCallout.scss';

// @ts-expect-error TS(7031): Binding element 'initialText' implicitly has an 'a... Remove this comment to see the full error message
const ImageCallout = ({ initialText, linkText, heading, buttonText, disclaimerText, image, imageAlt }) => {
  return (
    <div className="ImageCalloutWrapper">
      <Container row className="ImageCalloutContainer">
        <Container grid="12" className="ImageCalloutContent">
          {initialText && (
            <div className="ImageCalloutInitialText">
              {initialText}{' '}
              <Button
                theme="link"
                href="https://www.hometap.com/homeowner-resources/good-vs-bad-debt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkText}
              </Button>
            </div>
          )}
          <Hero image={image} heading={heading} buttonText={buttonText} altText={imageAlt} />
          {disclaimerText && <div className="ImageCalloutDisclaimer">{disclaimerText}</div>}
        </Container>
      </Container>
    </div>
  );
};

export default ImageCallout;
