import { getVersion } from 'utils/env';
import { apiWithAuth } from 'utils/api';
import { KIND_OTHER_DISPLAY, KIND_OTHER_VALUE } from '../constants';
import { showNotification } from 'utils/toasts';
import { DOCUMENT_STATUS } from '../utils';

const DEFAULT_UPLOAD_ERROR = 'Oops, something went wrong! Try again or contact us if having repeated trouble.';

const getDocNameForManyFiles = (files = []) => {
  // @ts-expect-error TS(2339): Property 'file' does not exist on type 'never'.
  const formattedFileName = files[0]?.file?.name;
  // this would likely never get to the point where we are actually sending this data over the wire
  // but prevents the frontend from crashing.
  if (!formattedFileName) return 'Unknown';

  const shouldAddPdfExtension = files.length > 1;

  if (shouldAddPdfExtension && formattedFileName) {
    return formattedFileName.replace(/\.[^/.]+$/, '.pdf');
  }
  return formattedFileName;
};

// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const bulkFileUpload = async (files = [], trackId, kind, personId) => {
  const formData = new FormData();

  const fileName = getDocNameForManyFiles(files);
  files.forEach(({ file }) => formData.append('file', file));
  formData.append('name', fileName);
  // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
  formData.append('internal', false);

  if (!kind) {
    formData.append('kind_other', 'Unknown');
  } else if (kind === KIND_OTHER_VALUE) {
    // we don't send `kind` because the backend expects kind === "" for "other".
    formData.append('kind_other', KIND_OTHER_DISPLAY);
  } else {
    formData.append('kind', kind);
    if (personId) {
      formData.append('person_id', personId);
    }
  }

  return await apiWithAuth.v2
    .post(`/tracks/${trackId}/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'HT-CLIENT-VERSION': `client_portal:${getVersion()}`,
      },
    })
    .catch(error => {
      // Multiple files being uploaded result in a single 400 error response. The API doesn't return
      // an error for individual files. We just label each file with a rejected status and return an empty error the UI
      // at least highlights the error state for each file.
      const individualFileError = { status: DOCUMENT_STATUS.REJECTED, errors: [{ message: '' }] };
      if (
        error?.response?.status === 400 &&
        Array.isArray(error?.response?.data) &&
        typeof error?.response?.data[0] === 'string'
      ) {
        const errorText = error?.response?.data.join(', ');
        // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
        showNotification({
          type: 'error',
          title: 'Something went wrong',
          description: errorText,
        });

        return individualFileError;
      }
      // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
      showNotification({
        type: 'error',
        title: 'Something went wrong',
        description: error?.message || DEFAULT_UPLOAD_ERROR,
      });

      return individualFileError;
    });
};

// @ts-expect-error TS(7006): Parameter 'docId' implicitly has an 'any' type.
export const handleRelatedDocumentTasks = async docId => {
  return await apiWithAuth.v2.post(`/documents/${docId}/actions/handle-review-tasks`);
};
