import { useContext, useEffect } from 'react';
import { ProgressContext } from 'components/ProgressProvider/ProgressProvider';

// @ts-expect-error TS(7031): Binding element 'steps' implicitly has an 'any' ty... Remove this comment to see the full error message
const useProgressBar = ({ steps, currStep, hide }) => {
  // @ts-expect-error TS(2339): Property 'setProgress' does not exist on type '{ s... Remove this comment to see the full error message
  const { setProgress, clear } = useContext(ProgressContext);

  useEffect(() => {
    setProgress({ steps, currStep, visible: !hide });
  }, [steps, currStep, hide, setProgress]);

  useEffect(() => () => clear(), [clear]);
};

export default useProgressBar;
