import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useWindowSize, useBoolState, useAsync } from '@hometap/htco-components';

import sentry from 'utils/sentry';
import useTrack from 'hooks/useTrack';
import { completeTask } from 'hooks/useTask';
import { currency } from 'utils/numbers';
import { useFilteredTodos } from 'hooks/useTrackTodos';

import Footer from 'components/Footer/Footer';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import appraisalIllustration from 'images/appraisal-illustration.webp';
import appraisalIllustrationTablet from 'images/appraisal-illustration-tablet.webp';

import AppraisalSelectionPricingMatrix from './components/AppraisalSelectionPricingMatrix';
import AppraisalSelectionModal from './components/AppraisalSelectionModal';
import AppraisalSelectionFAQ from './components/AppraisalSelectionFAQ/AppraisalSelectionFAQ';

import './AppraisalSelectionController.scss';
import { showNotification } from 'utils/toasts';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { TASK_DEFINITION_KEY } from 'utils/constants/taskDefinitionKeys';

const AppraisalSelectionController = () => {
  const { trackId } = useParams();
  const navigate = useNavigate();
  // @ts-expect-error TS(2339): Property 'track' does not exist on type '{}'.
  const { track, loading: isTrackLoading, trackError, patch: patchTrack } = useTrack();
  const [selectedAppraisalType, setSelectedAppraisalType] = useState();
  const {
    todos,
    isLoading: isTodosLoading,
    fetchFilteredTodos,
  } = useFilteredTodos({
    taskDefinitionKey: TASK_DEFINITION_KEY.SELECT_APPRAISAL_TYPE,
  });

  const { loading: isCompleteTaskLoading, ...completeTaskAsync } = useAsync(completeTask);

  useEffect(() => {
    fetchFilteredTodos(trackId);
  }, [trackId, fetchFilteredTodos]);

  // Redirect the user if they don't have a corresponding Select Appraisal Type task open.
  if (!isTodosLoading && todos.length < 1) {
    navigate(`/track/${trackId}`);
  }

  const isAppraisalModalOpen = useBoolState();
  const { isScreenSize } = useWindowSize();

  // @ts-expect-error TS(7006): Parameter 'appraisalType' implicitly has an 'any' ... Remove this comment to see the full error message
  const handleAppraisalTypeClick = appraisalType => {
    setSelectedAppraisalType(appraisalType);
    isAppraisalModalOpen.setTrue();
  };

  const handleAppraisalModalClose = () => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setSelectedAppraisalType(null);
    isAppraisalModalOpen.setFalse();
  };

  const isBetweenTabletAndSmallLaptop = !isScreenSize('xs') && (isScreenSize('md') || isScreenSize('sm'));
  const appraisalImage = isBetweenTabletAndSmallLaptop ? appraisalIllustrationTablet : appraisalIllustration;

  const virtualValuationDollars =
    track?.relevant_home_valuation?.is_avm && currency(track?.relevant_home_valuation.value);

  return (
    // @ts-expect-error TS(2739): Type '{ children: Element; loading: any; error: an... Remove this comment to see the full error message
    <SectionLoadingWrapper loading={(isTrackLoading && !track) || isTodosLoading} error={trackError}>
      <ScrollToTop>
        <div className="AppraisalSelectionContainer">
          <div className="AppraisalSelectionWrapper">
            <div className="AppraisalSelectionHero">
              <div className="AppraisalSelectionHeroHeadingContainer">
                <h1 className="AppraisalSelectionHeroHeading">Choose your appraisal method.</h1>
                <p className="AppraisalSelectionHeroSubheading">
                  An appraisal helps Hometap understand the market value of your home in order to determine your
                  Investment amount. Select the appraisal method that works best for you.
                </p>
              </div>
              <figure className="AppraisalSelectionHeroImage">
                <img src={appraisalImage} alt="Man taking a pic of home" />
              </figure>
            </div>
            <div className="AppraisalSelectionPricingMatrixContainer">
              <AppraisalSelectionPricingMatrix
                onAppraisalTypeClick={handleAppraisalTypeClick}
                virtualValuationDollars={virtualValuationDollars}
              />
            </div>
            <AppraisalSelectionFAQ />
          </div>
        </div>

        <div className="PageBodyCenteredContainer">
          {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see */}
          <Footer />
        </div>

        <AppraisalSelectionModal
          selectedAppraisalType={selectedAppraisalType}
          isSubmitting={patchTrack.loading || isCompleteTaskLoading}
          onSubmitAppraisalType={() => {
            patchTrack
              .execute({ selected_appraisal_type: selectedAppraisalType })
              .then(() => {
                completeTaskAsync.execute(todos[0].id).then(() => {
                  // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
                  showNotification({
                    type: 'success',
                    title: 'Appraisal method selected.',
                    description: 'Thank you for submitting.',
                  });
                  navigate(`/track/${trackId}`);
                });
              })
              .catch(error => {
                sentry.logError('Call to set selected_appraisal_type failed.', {
                  tags: {
                    statusCode: error.response.status,
                    statusText: error.response.statusText,
                    trackId,
                  },
                  extra: {
                    errorMessage: error.message,
                    requestUrl: error.config?.url,
                  },
                });
                // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
                showNotification({
                  type: 'error',
                  title: 'Unable to select appraisal type.',
                  description: 'Please try again.',
                });
                handleAppraisalModalClose();
              });
          }}
          isOpen={isAppraisalModalOpen.value}
          onClose={handleAppraisalModalClose}
          virtualValuationDollars={virtualValuationDollars}
        />
      </ScrollToTop>
    </SectionLoadingWrapper>
  );
};

export default AppraisalSelectionController;
