import ResourcesTileSection from './ResourcesTileSection';
import {
  FEATURED_RESOURCE,
  FEATURED_RESOURCES_LIST,
  RESOURCE_ARTICLE_TILES,
} from 'apps/dashboard/content/resourceArticles';
import FeaturedResources from './FeaturedResources';
import Footer from 'components/Footer/Footer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import './ResourcesController.scss';

const ResourcesController = () => {
  return (
    <ScrollToTop>
      <PrimaryLayoutGrid className="ResourcesControllerFeaturedResources">
        <div className="col-span-full">
          <h2 className="ResourcesControllerHeader">Featured resources</h2>
          <FeaturedResources featuredResource={FEATURED_RESOURCE} resourcesList={FEATURED_RESOURCES_LIST} />
        </div>
      </PrimaryLayoutGrid>

      <div className="ResourcesControllerSectionWrapper bg-blue-dark-100">
        {/* @ts-expect-error TS(2322): Type '{ title: string; content: string; excerpt: s... Remove this comment to see the full error message */}
        <ResourcesTileSection resources={RESOURCE_ARTICLE_TILES} />
        {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
        <PrimaryLayoutGrid>
          <Footer theme="dark" className="col-span-full mt-26" />
        </PrimaryLayoutGrid>
      </div>
    </ScrollToTop>
  );
};

export default ResourcesController;
