// @ts-expect-error TS(7031): Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
export const SecondaryHeader = ({ label, value }) => {
  return (
    <div className="flex flex-row pb-6 text-neutral-dark-75">
      <p className="m-0 text-sm font-bold">{label}</p>
      {value && <p className="m-0 pl-1 text-sm">{value}</p>}
    </div>
  );
};
export default SecondaryHeader;
