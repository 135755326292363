import PropTypes from 'prop-types';

// @ts-expect-error TS(7031): Binding element 'address' implicitly has an 'any' ... Remove this comment to see the full error message
const ReInquiryAddress = ({ address }) => {
  const { street, unit, city, state, zip_code } = address;
  return (
    <>
      {street}
      {unit && `, ${unit}`}
      <br />
      {city && `${city}, `} {state} {zip_code}
    </>
  );
};

ReInquiryAddress.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string.isRequired,
    unit: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReInquiryAddress;
