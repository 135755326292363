import { useState } from 'react';

import { Button, SlideDown, Loader, useAsync } from '@hometap/htco-components';
import cx from 'classnames';
import { refinancingHomeFAQ, requestSettlementFAQ, sellingHomeFAQ, STAGES } from 'apps/dashboard/constants';
import useUserTracksContext from 'hooks/useUserTracksContext';
import Footer from 'components/Footer/Footer';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { useNavigate } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';
import useCurrentUser from 'hooks/useCurrentUser';
import InvestmentCard from 'components/InvestmentCard/InvestmentCard';
import InvestmentSupportCards from 'apps/dashboard/components/dashboard-support-page/InvestmentSupportCards';
import useInvestmentSupportContacts from 'apps/dashboard/hooks/useInvestmentSupportContacts';
import ReInquiryCTA from './ReInquiryCTA';
import FAQList from 'components/FAQList/FAQList';
import { fetchEquityPlannerHomes } from 'apps/dashboard/data';
import succulent from 'images/dashboard/succulent.png';
import { useFlags } from 'launchdarkly-react-client-sdk';

const InvestmentController = () => {
  const { showOverviewPage } = useFlags();
  const equityPlannerHomesAsync = useAsync(fetchEquityPlannerHomes);
  const [inactiveOpen, setInactiveOpen] = useState(false);
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ home:... Remove this comment to see the full error message
  const { home, loading: homeLoading } = useCurrentHome();
  const navigate = useNavigate();
  // @ts-expect-error TS(2339): Property 'tracks' does not exist on type '{}'.
  const { tracks, loading: tracksLoading, pendingTracks } = useUserTracksContext();
  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const currentHomeTracks = tracks?.filter(track => {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
    return track?.home.address.business_key === home?.address.business_key;
  });
  const contacts = useInvestmentSupportContacts(currentHomeTracks);

  useEnableGlia(true, false);
  useGliaIdentification();

  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const closedTracks = currentHomeTracks?.filter(track => track.current_stage === STAGES.CLOSED_LOST);
  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const activeTracks = currentHomeTracks?.filter(track => track.current_stage !== STAGES.CLOSED_LOST);

  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const currentHomeHasInvestedTracks = currentHomeTracks?.some(track => track.current_stage === STAGES.INVESTED);

  const inactiveApplicationsLabel = inactiveOpen ? 'Hide inactive applications' : 'View inactive applications';

  const hasClosedOrActiveTracks = closedTracks?.length > 0 || activeTracks?.length > 0;
  const showWelcomeHeader = hasClosedOrActiveTracks && !showOverviewPage;

  const reInquiryTitle = hasClosedOrActiveTracks ? (
    <span>
      Get more from homeownership, <span style={{ color: '#20A277' }}>get more from life</span>
    </span>
  ) : (
    'You don’t have any Investments started at this time.'
  );

  if (homeLoading || tracksLoading) {
    return (
      <div className="PageLoader">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <ScrollToTop>
      <PrimaryLayoutGrid
        className={cx({ 'mb-16': currentHomeHasInvestedTracks, 'mb-20': !currentHomeHasInvestedTracks })}
      >
        <div className="col-span-full">
          {showWelcomeHeader && <h2 className="mb-2">Welcome, {user.first_name}!</h2>}
          <p className="m-0 mb-6 text-neutral-dark-100 sm:text-lg">
            See all in-progress, invested, and settled Home Equity Investments
          </p>
          {activeTracks?.length > 0 && (
            <div className="pb-8 md:pb-12">
              {/* @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type. */}
              {activeTracks.map(track => (
                <InvestmentCard key={track.friendly_id} className="mb-4" track={track} />
              ))}
            </div>
          )}
          {closedTracks?.length > 0 && (
            <Button className="!mb-6 !p-0" theme="link" onClick={() => setInactiveOpen(!inactiveOpen)}>
              {inactiveApplicationsLabel}
            </Button>
          )}
          <SlideDown visible={inactiveOpen}>
            {/* @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type. */}
            {closedTracks?.map(track => <InvestmentCard className="mb-4" key={track.friendly_id} track={track} />)}
          </SlideDown>
          <ReInquiryCTA
            title={reInquiryTitle}
            text="Use your equity to reach your financial goals."
            image={succulent}
            tracksLoading={tracksLoading}
            pendingTracks={pendingTracks}
            tracks={tracks}
            equityPlannerHomes={equityPlannerHomesAsync?.results}
          />
          <h3 className="mb-6 mt-12  md:mt-16">Your Investment team</h3>
          {/* @ts-expect-error TS(2786): 'InvestmentSupportCards' cannot be used as a JSX c... Remove this comment to see */}
          <InvestmentSupportCards contacts={contacts} />
        </div>
      </PrimaryLayoutGrid>

      {currentHomeHasInvestedTracks && (
        <div className="InvestedFAQ border-0 border-t border-solid border-neutral-dark-100 bg-[linear-gradient(180deg,_rgba(225,_233,_252,_0.75)_15%,_theme(colors.white))]">
          <PrimaryLayoutGrid className="m-auto pb-20 pt-16 sm:pb-26 sm:pt-20">
            <div className="col-span-full">
              <FAQList
                heading="Investment questions"
                faqs={[requestSettlementFAQ, sellingHomeFAQ, refinancingHomeFAQ]}
                footer={
                  <Button
                    className="mt-14"
                    theme="secondary"
                    label="View all FAQs"
                    size="large"
                    onClick={() => navigate(`/${HOMEOWNER_DASHBOARD.SUPPORT}`, { replace: true })}
                  />
                }
              />
            </div>
          </PrimaryLayoutGrid>
        </div>
      )}

      <PrimaryLayoutGrid className={cx({ 'bg-white': currentHomeHasInvestedTracks })}>
        <Footer theme="light" className="col-span-full" />
      </PrimaryLayoutGrid>
    </ScrollToTop>
  );
};

export default InvestmentController;
