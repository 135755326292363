import { Button } from '@hometap/htco-components';
import { browserTrack } from 'utils/segment';
import PropTypes from 'prop-types';

// @ts-expect-error TS(7031): Binding element 'eventProperties' implicitly has a... Remove this comment to see the full error message
const TrackButton = ({ eventProperties, ...props }) => {
  // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
  const handleTrack = e => {
    browserTrack.cTAClicked({
      destination: props.href,
      page_url: window.location.href,
      style: props.theme || 'primary',
      text: e.target.innerText,
      ...eventProperties,
    });
    if (props.onClick) props.onClick(e);
  };
  return <Button {...props} onClick={handleTrack} />;
};

TrackButton.propTypes = {
  eventProperties: PropTypes.object,
  props: PropTypes.object,
};

export default TrackButton;
