import { Button, MuiSelect, Modal, MuiTextInput, Grid } from '@hometap/htco-components';
import { parseDataToAddressString } from 'utils/googleAutocompleteAddress';
import useStates from 'hooks/useStates';
import './InquiryAddressModal.scss';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';

// @ts-expect-error TS(7031): Binding element 'open' implicitly has an 'any' typ... Remove this comment to see the full error message
const InquiryAddressModal = ({ open, onNext, handleClose, form }) => {
  const { isFormValid, formData, registerField, handleSubmit, updateFormData } = form;
  const { statesForDropdown } = useStates();

  const onSubmit = () => {
    const { street, city, state, zip_code } = formData;
    const address = parseDataToAddressString({ street, city, state, zip_code });
    updateFormData({ address });
    onNext();
  };

  const valid = () => {
    const { zip_code } = formData;
    const zipCodeValid = zip_code && zip_code.length === 5;
    return isFormValid && zipCodeValid;
  };
  const ctaText = 'Submit Address';
  useCTAChangedNotification(ctaText, open && valid());

  return (
    <Modal open={open} onClose={handleClose}>
      <div>
        <h1>Enter your address</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="InquiryAddressModalForm">
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} sm={6}>
              <MuiTextInput
                name="street"
                label="Street Address"
                maxLength={50}
                required
                width="100%"
                {...registerField('street')}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <MuiTextInput
                name="unit"
                label="Unit (optional)"
                maxLength={50}
                width="100%"
                {...registerField('unit')}
              />
            </Grid>
            <Grid xs={12} sm={12}>
              <MuiTextInput name="city" label="City" maxLength={50} required width="100%" {...registerField('city')} />
            </Grid>
            <Grid xs={12} sm={6} className="InquiryAddressModalState">
              <MuiSelect
                name="state"
                label="State"
                required
                options={statesForDropdown}
                {...registerField('state')}
                width="100%"
              />
            </Grid>
            <Grid xs={12} sm={6} className="InquiryAddressModalZip">
              <MuiTextInput
                name="zip_code"
                label="Zip Code"
                type="number"
                inputMode="number"
                maxLength={5}
                required
                mask="00000"
                // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
                validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
                {...registerField('zip_code')}
                width="100%"
              />
            </Grid>
            <Grid xs={12}>
              <Button className="InquiryAddressModalSubmit" disabled={!valid()} width="100%" type="submit">
                {ctaText}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default InquiryAddressModal;
