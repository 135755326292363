import { Fragment, useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { Container, Loader, NotFoundBlock, useAsync, useWindowSize } from '@hometap/htco-components';
import useApplicants from 'apps/application/hooks/useApplicants';
import useApplication from 'apps/application/hooks/useApplication';
import useTrackDocuments from 'apps/documents/hooks/useTrackDocuments';
import { fetchOffer } from 'apps/offers/data/offerRequests';
import { fetchInvestmentSummary } from 'apps/settlement/data/settlementRequests';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import useTrack from 'hooks/useTrack';
import useTrackTodos from 'hooks/useTrackTodos';
import useInvestmentStatementList from 'apps/quarterly-statement/hooks/useInvestmentStatementList';
import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';

import sentry from 'utils/sentry';
import { getInvestmentDashboardUrl } from 'utils/links';
import TrackDetailsProgressStepper from './components/TrackDetailsProgressStepper/TrackDetailsProgressStepper';
import TrackDetailsSidebar from './components/TrackDetailsSidebar/TrackDetailsSidebar';
import TrackDetailsResourceList from './components/TrackDetailsSidebar/components/TrackDetailsResourceList/TrackDetailsResourceList';
import TrackDetailsStageSection from './components/TrackDetailsStageSection/TrackDetailsStageSection';
import { fetchTrackRundowns } from './data/trackDetailsRequests';
import Footer from 'components/Footer/Footer';
import { isTrackFunded } from './utils/helpers';

import {
  getDefaultTrackDetailsSidebarBottomComponent,
  getDefaultTrackDetailsSidebarTopComponent,
  getResourceSections,
} from './utils/sidebar';
import SecondaryHeader from 'components/Headers/SecondaryHeader/SecondaryHeader';

import './TrackDetailsController.scss';
import { getCurrentStageConfig } from './utils/helpers';

const TrackDetailsController = () => {
  const { track, isRolloutFlagEnabled, loading: isTrackLoading } = useTrack();
  const {
    // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ error... Remove this comment to see the full error message
    loading: isTrackDocumentsLoading,
    hasUnreadDocs,
  } = useTrackDocuments({ trackId: track?.id, fetchImmediately: true });
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  const { application, ...applicationState } = useApplication();
  const { applicants, ...applicantsState } = useApplicants();
  // TODO: replace this with a useTrackInvestment hook
  const { results: investmentSummary, ...fetchInvestmentSummaryAsync } = useAsync(fetchInvestmentSummary);
  const { results: rundowns = [], ...fetchTrackRundownsAsync } = useAsync(fetchTrackRundowns);
  const { isScreenSize } = useWindowSize();

  useEnableGlia(true);
  useGliaIdentification();

  const { statements, statementsQuery } = useInvestmentStatementList({
    track,
    investmentId: track?.active_investment_id,
  });

  const { error: statementsError } = statementsQuery;

  const { todos = [], fetchTodos } = useTrackTodos();

  // Only used for an offer rundown
  const { results: offer, ...fetchOfferAsync } = useAsync(fetchOffer);
  // TODO: Add other async data loading states here or possibly more fine grained loading states for
  // where we know todos will be located. E.g., show place holder card where we know the
  // application card would be located.
  const isLoading =
    isTrackLoading ||
    applicationState.isLoading ||
    applicantsState.isLoading ||
    isTrackDocumentsLoading ||
    statementsQuery.isLoading;

  const loadAsyncData = useCallback(async () => {
    const asyncs = [fetchTrackRundownsAsync.execute(track?.id), fetchTodos(track?.id)];

    if (track?.active_application_id) {
      asyncs.push(applicationState.executeFetchApplication({ applicationId: track?.active_application_id }));
      asyncs.push(applicantsState.executeFetchApplicants({ applicationId: track?.active_application_id }));
    }

    if (track?.active_offer_rundown_id) {
      asyncs.push(fetchOfferAsync.execute(track?.active_offer_rundown_id));
    }

    if (track?.active_investment_id) {
      asyncs.push(fetchInvestmentSummaryAsync.execute(track?.active_investment_id));
    }

    return await Promise.all(asyncs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track?.active_application_id]);

  useEffect(() => {
    if (!isTrackLoading && track) {
      loadAsyncData();
    }
  }, [isTrackLoading, track, loadAsyncData]);

  if (isLoading) {
    return <Loader type="dot-pulse" className="TrackDetailsLoader" />;
  }

  if (!track) {
    return <NotFoundBlock />;
  }
  const asyncData = { rundowns, application, offer, track, applicants, investmentSummary, asyncTodos: todos };
  const isFunded = isTrackFunded(track);

  const {
    lastActiveStageIndex,
    allUIProgressStepperStages,
    renderedStagesConfigs,
    currentUIStage,
    canShowProgressStepper,
  } = getCurrentStageConfig(track);
  if (!currentUIStage && !isFunded) {
    // NOTE: ClosedLost tracks are handled in the useTrack() hook. This would account for any other
    // unaccounted stages added to the backend and we would want to be made aware that the new stage slipped
    // through the cracks.
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    sentry.logWarning(`A track stage has been unaccounted for on the front-end: ${track?.current_stage}.`);
    return <Navigate to={getInvestmentDashboardUrl()} />;
  }

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const renderedStages = renderedStagesConfigs.map((section, i) => {
    // first section in the list should be active
    const isActive = i === 0;

    const todos = section
      .getTodos({ isActive, ...asyncData, isRolloutFlagEnabled })
      .filter(Boolean)
      .filter(({ isComplete }) => (typeof isComplete === 'function' ? !isComplete(asyncData) : !isComplete));

    return (
      <Fragment key={`${section.title}-${i}`}>
        <TrackDetailsStageSection
          isActive={isActive}
          sectionConfigData={section}
          sectionContext={asyncData}
          fetchAsyncTodos={fetchTodos}
          todos={todos}
        />
        {isActive && section.extraSectionContent && section.extraSectionContent}
      </Fragment>
    );
  });

  return (
    <ScrollToTop>
      <div className="TrackDetailsControllerContainer PageBodyCenteredContainer">
        <SecondaryHeader label={'Investment ID:'} value={track?.friendly_id} />
        <Container className="TrackDetailsController">
          <div className="TrackDetailsControllerSidebarContainer">
            <TrackDetailsSidebar
              topContent={getDefaultTrackDetailsSidebarTopComponent({ track, isScreenSize })}
              middleContent={
                <TrackDetailsResourceList
                  // @ts-expect-error TS(2322): Type '{ sectionHeading: string; sectionResources: ... Remove this comment to see the full error message
                  resources={getResourceSections({
                    rundowns,
                    offer,
                    statements,
                    statementsError,
                    track,
                    showDocumentsSection: true,
                    isRolloutFlagEnabled,
                  })}
                  track={track}
                  isRolloutFlagEnabled={isRolloutFlagEnabled}
                  hasUnreadDocs={hasUnreadDocs}
                />
              }
              bottomContent={getDefaultTrackDetailsSidebarBottomComponent({ track, isScreenSize })}
            />
          </div>
          {canShowProgressStepper && (
            <div className="TrackDetailsControllerProgressStepperContainer">
              {/* @ts-expect-error TS(2739): Type '{ steps: string[]; activeIndex: number; }' i... Remove this comment to see the full error message */}
              <TrackDetailsProgressStepper steps={allUIProgressStepperStages} activeIndex={lastActiveStageIndex} />
            </div>
          )}
          <div>{renderedStages}</div>
        </Container>
        {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default TrackDetailsController;
