import { Helmet } from 'react-helmet-async';

import { Button } from '@hometap/htco-components';

import InquiryFormRow from 'apps/progressive-inquiry/components/InquiryFormRow';
import InquiryOutcomeUnsuccessfulRiskFactors from './InquiryOutcomeUnsuccessfulRiskFactors';

// @ts-expect-error TS(7031): Binding element 'ficoScore' implicitly has an 'any... Remove this comment to see the full error message
const InquiryOutcomeUnsuccessfulFicoScoreAA = ({ riskFailFactors = [], ficoScore, firstName, lastName }) => {
  let ficoScoreCopy;
  if (ficoScore === 9002) {
    ficoScoreCopy = 'No activity in the past 6 months';
  } else if (ficoScore === 9003) {
    ficoScoreCopy = 'No account more than 6 months old';
  } else {
    ficoScoreCopy = ficoScore;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} />
      <div className="InquiryWrapper">
        <div className="InquiryUnsuccessfulOutcomeAAWrapper">
          <InquiryFormRow>
            <h1>Thanks for taking a few minutes to learn about Hometap.</h1>
          </InquiryFormRow>
          <InquiryFormRow>
            At this time, Hometap is unable to make an investment in your home. We’d like to help you understand the
            possible reasons for that.
          </InquiryFormRow>
          <InquiryFormRow>
            In making investment decisions, Hometap considers information obtained by a “soft credit pull” and/or a
            “hard credit pull” through a consumer credit reporting agency and considers information we collect from
            other third parties. Hometap’s decision was based in whole or in part on information obtained from the
            consumer credit reporting agency, Experian.
          </InquiryFormRow>
          <InquiryFormRow>
            <div>
              We encourage everyone to check their credit report to ensure that the information is accurate and up to
              date. You can do that for free,
              <Button
                target="_blank"
                theme="link"
                href="http://www.annualcreditreport.com"
                className="InquiryAdverseActionButton"
              >
                here
              </Button>
              .
            </div>
          </InquiryFormRow>
          <InquiryFormRow>
            You have the right under the Fair Credit Reporting Act to make a written request, within 60 days, for a
            disclosure of the nature of the information about you that we received from Experian. You also have the
            right to know the information contained in your file at the consumer credit reporting agency. Experian
            played no part in our decision and is unable to supply specific reasons why we have denied your request. You
            also have a right to a free copy of your credit report from Experian, if you request it no later than 60
            days after you receive this notice.
          </InquiryFormRow>
          <InquiryFormRow>
            In addition, if you find that any information contained in the report is inaccurate or incomplete, you have
            the right to dispute the matter with the reporting agency:
          </InquiryFormRow>
          <InquiryFormRow>
            <div>
              <div>Experian</div>
              <div>P.O. Box 9701</div>
              <div>Allen, TX 75013</div>
              <div>1-888-397-3742 </div>
            </div>
          </InquiryFormRow>
          <InquiryFormRow>
            We also obtained your credit score from the consumer credit reporting agency and used it in making our
            decision. Your credit score is a number that reflects the information in your consumer report. Your credit
            score can change, depending on how the information in your consumer report changes.
          </InquiryFormRow>
          <InquiryFormRow>
            <div>
              <div>
                {firstName} {lastName}
              </div>
              <div>
                Your credit score: <b>{ficoScoreCopy}</b>
              </div>
            </div>
          </InquiryFormRow>
          <InquiryFormRow>
            <div>Date: {new Date().toDateString()}</div>
          </InquiryFormRow>
          <InquiryFormRow>Scores range from a low of 300 to 850.</InquiryFormRow>
          <InquiryOutcomeUnsuccessfulRiskFactors riskFailFactors={riskFailFactors} />
          <InquiryFormRow>
            If you have any questions regarding your credit score, please contact Experian at the phone number above.
          </InquiryFormRow>
          <InquiryFormRow>
            If you have any questions regarding this notice, please contact us at homeowners@hometap.com.
          </InquiryFormRow>
        </div>
      </div>
    </>
  );
};

export default InquiryOutcomeUnsuccessfulFicoScoreAA;
