import cx from 'classnames';

import { Badge } from '@hometap/htco-components';
import './PercentageBadge.scss';

const PercentageBadge = ({
  percentage = '90',
  theme = 'primaryGreen15',
  className,
}: {
  percentage?: string;
  theme?: string;
  className?: string;
}) => (
  <div className="PercentageBadgeWrapper">
    <Badge
      label={`${percentage}% COMPLETE`}
      theme={theme}
      className={cx('ResourcesBadge', 'ResourcesCardBadge', className)}
    />
  </div>
);

export default PercentageBadge;
