import { Link } from 'react-router-dom';
import { getLogoutUrl } from 'utils/links';
import { userSessionStorageKeys } from 'hooks/useSessionStorage';

// Clear all user-specific session storage data. This is performed on the SPA side as session storage is domain
// specific, so we can't clear it from the MPA side.
export const clearStorage = () => {
  const sessionStorageKeys = Object.keys(window.sessionStorage);
  sessionStorageKeys.forEach(key => {
    if (userSessionStorageKeys.includes(key)) {
      window.sessionStorage.removeItem(key);
    }
  });
};

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
export const LogOutLink = ({ className }) => (
  <Link className={className} onClick={() => clearStorage()} to={getLogoutUrl()}>
    Log out
  </Link>
);
