import { useState, useCallback } from 'react';

import { Button, Modal } from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { percentToDecimal } from 'utils/numbers';
import EquityViewSettingsForm from './EquityViewSettingsForm';

const FORECAST_OPTIONS = [
  { optionLabel: '1 year', selectedLabel: '1yr', value: '1' },
  { optionLabel: '3 years', selectedLabel: '3yrs', value: '3' },
  { optionLabel: '5 years', selectedLabel: '5yrs', value: '5' },
  { optionLabel: '10 years', selectedLabel: '10yrs', value: '10' },
];

const APPRECIATION_RATE_OPTIONS = [
  { optionLabel: '2%', selectedLabel: '2%', value: 2 },
  { optionLabel: '3%', selectedLabel: '3%', value: 3 },
  { optionLabel: '4%', selectedLabel: '4%', value: 4 },
  { optionLabel: '5%', selectedLabel: '5%', value: 5 },
  { optionLabel: '6%', selectedLabel: '6%', value: 6 },
  { optionLabel: '7%', selectedLabel: '7%', value: 7 },
];

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const EquityViewSettings = ({ className, modal }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { currentScenario, chartForecast, setChartForecast, isLoading, changeScenarioRate } =
    useEquityScenarioContext();

  // @ts-expect-error TS(2339): Property 'appreciation_rate' does not exist on typ... Remove this comment to see the full error message
  const currentAppreciationRate = Math.round(Number(currentScenario?.appreciation_rate * 100));

  const handleSubmit = useCallback(
    // @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
    formData => {
      if (formData.chartForecast !== chartForecast) {
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        setChartForecast(formData.chartForecast);
      }

      const newAppreciationRate = percentToDecimal(formData.currentAppreciationRate);
      if (newAppreciationRate !== currentAppreciationRate && !isLoading) {
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        changeScenarioRate(newAppreciationRate);
      }

      setModalOpen(false);
    },
    [isLoading, chartForecast, currentAppreciationRate, setChartForecast, changeScenarioRate],
  );

  if (modal) {
    return (
      <>
        <Button className="text-sm/6" theme="link" onClick={() => setModalOpen(true)}>
          Settings +
        </Button>
        <Modal theme="small" open={isModalOpen} allowClose onClose={() => setModalOpen(false)}>
          <h1 className="mb-6">Chart Settings</h1>
          {/* @ts-expect-error TS(2741): Property 'isInline' is missing in type '{ chartFor... Remove this comment to see the full error message */}
          <EquityViewSettingsForm
            chartForecast={chartForecast}
            forecastOptions={FORECAST_OPTIONS}
            currentAppreciationRate={currentAppreciationRate}
            appreciationRateOptions={APPRECIATION_RATE_OPTIONS}
            onSubmit={handleSubmit}
          />
        </Modal>
      </>
    );
  }

  return (
    <div className={className}>
      <EquityViewSettingsForm
        chartForecast={chartForecast}
        forecastOptions={FORECAST_OPTIONS}
        currentAppreciationRate={currentAppreciationRate}
        appreciationRateOptions={APPRECIATION_RATE_OPTIONS}
        onSubmit={handleSubmit}
        isInline
      />
    </div>
  );
};

export default EquityViewSettings;
