import bathroom from 'images/renovation/bathroom.png';
import kitchen from 'images/renovation/kitchen.png';
import roof from 'images/renovation/roof.png';
import siding from 'images/renovation/siding.png';
import window from 'images/renovation/window.png';

import flooring from 'images/renovation/flooring.png';

export const RENOVATION_FINISH_LEVELS = {
  value: 'Value',
  quality: 'Quality',
  midrange: 'Mid-range',
  luxury: 'Luxury',
  composite: 'Composite',
  cosmetic: 'Cosmetic',
  asphaltShingles: 'Asphalt Shingles',
  metal: 'Metal',
  fiberCement: 'Fiber Cement',
  vinyl: 'Vinyl',
  wood: 'Wood',
  standard: 'Standard',
  minor: 'Minor',
  major: 'Major',
};

const {
  value,
  quality,
  asphaltShingles,
  cosmetic,
  fiberCement,
  luxury,
  major,
  metal,
  midrange,
  minor,
  standard,
  vinyl,
  wood,
} = RENOVATION_FINISH_LEVELS;

export const FINISH_LEVEL_OPTION_LABELS = {
  [value]: 'Value ($)',
  [quality]: 'Quality ($$)',
};

const THINGS_TO_CONSIDER = {
  bathroom: [
    'To tub or not to tub — a shower and bathtub combo is desired by some homebuyers, but it ultimately comes down to personal preference.',
    'Pay special attention to the shower; not only do you likely use it every day, but buyers increasingly seek out walk-in showers with features like built-in storage nooks and massage jets.',
    'Unless it’s a powder room (i.e. half bath), bypass wallpaper, as it won’t fare well in the humidity, and opt for tile or wainscotting instead.',
  ],
  kitchen: [
    'Find out whether your contractor will oversee all permits and inspections; changes to plumbing, electrical, and structural changes will require inspection approvals.',
    'It’s a smart idea to establish milestones that align with your payment schedule to encourage the contractors to stay on track. For example, you can break your project and your payment into thirds, and make your next payment once a set segment of work is complete.',
  ],
  roofing: [
    'Roof replacement is never quiet, so be prepared for a noisy period of two to three days.',
    'When it comes to replacing your roof, the old adage “you get what you pay for” holds true. While it may be tempting to cut costs and go with the least expensive option, you may be sacrificing quality as well.',
    'Most roofing companies handle disposal of your old roofing materials, but it’s worth checking with them ahead of time to make sure.',
  ],
  siding: [
    'While natural and manufactured wood can be used as alternative siding material, both are considered high-maintenance, and are susceptible to rot and termites.',
    'Most reputable providers will offer a lifetime warranty for their vinyl siding, including protection for fading.',
  ],
  windows: [
    'Since new windows can be quite expensive, take some time to decide whether it makes more sense to completely replace or just repair your windows.',
    'Windows can be replaced at any time of year, but spring and summer are more popular seasons due to better weather. However, this might mean a longer wait, so booking in fall or winter can sometimes work to your advantage.',
  ],
  flooring: [
    'One thing you might not think about when picking flooring? Light exposure. Some flooring materials can lighten or darken depending on the amount of sunlight they receive throughout the day, so consider UV-resistant flooring if you’re purchasing for a particularly sunny area.',
  ],
};

const FINISH_LEVEL_VALUE_TITLE = (
  <>
    Material Type: <span className="RenovationTitleFinishLevel">Value ($)</span>
  </>
);
const FINISH_LEVEL_QUALITY_TITLE = (
  <>
    Material Type: <span className="RenovationTitleFinishLevel">Quality ($$)</span>
  </>
);

const textKitchen = {
  title: `Kitchen Renovation`,
  description:
    'You’ve probably heard the expression that kitchens sell homes. That’s because kitchen quality and usability will often make or break a home sale, and it can be one of the costliest and most involved rooms to renovate. Discover how material quality and scale can impact your bottom line.',
  subtitles: {
    [value]: [
      {
        title: FINISH_LEVEL_VALUE_TITLE,
        list: {
          items: [
            '<b>Cabinets:</b> Repaint/refinish existing',
            '<b>Counters:</b> Basic laminate or ceramic tile',
            '<b>Backsplash:</b> Basic laminate or ceramic tile',
            '<b>Appliances:</b> White finish bundle in base-level lines from Amana, Frigidaire and GE: 28" wide fridge 30" stove /oven, 24" dishwasher, and microwave',
            '<b>Floor:</b> Discount/sale-priced vinyl sheet or vinyl tile',
            '<b>Lighting:</b> Budget-priced surface mounted ceiling lights',
            '<b>Faucet(s) &amp; sink(s):</b> Budget-priced faucet and stainless steel sink from brands like Delta, Kingston Brass, and Moen',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [quality]: [
      {
        title: FINISH_LEVEL_QUALITY_TITLE,
        list: {
          items: [
            '<b>Cabinets:</b> Basic pre-assembled, premium refinishing, or simple reface of existing (no custom cabinetry)',
            '<b>Counters:</b> Premium laminate, stone tile, or pre-fabricated granite',
            '<b>Backsplash:</b> Premium laminate, stone tile, or pre-fabricated granite',
            '<b>Appliances:</b> Stainless bundle in base-level lines from brands like Samsung, LG, and Whirlpool: 36" fridge, 30" range & oven, with microwave and dishwasher',
            '<b>Floor:</b> Linoleum, luxury vinyl tile, or plank',
            '<b>Lighting:</b> Well-priced ceiling surface light, LED rope, plug-in wall sconce',
            '<b>Faucet(s) &amp; sink(s):</b> Mid-level faucet from Delta, Moen, or American Standard. Base-level ceramic or from value brands, stainless sink from Kohler or similar.',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [standard]: [
      {
        title: `${standard} Quality`,
        descriptions: [
          'A standard renovation involves replacing floors, counters, appliances, and fixtures with cost-efficient, builder-grade materials and can keep costs to a minimum. ',
        ],
        list: {
          title: 'Materials often used in standard-grade renovations:',
          items: [
            'Vinyl, linoleum, or laminate flooring ',
            'Formica, butcher block, or marble laminate countertops',
            'Stock-pressed wood, plywood, or particle board cabinets',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [midrange]: [
      {
        title: `${midrange} Quality`,
        descriptions: [
          'Mid-range renovations are often the sweet spot for homeowners looking to spruce up their kitchen while being mindful of their budget. Choosing where to splurge and where to save is key. ',
        ],
        list: {
          title: 'Materials used:',
          items: [
            'Laminate, tile, or engineered/hardwood flooring',
            'Granite, concrete, or quartz countertops',
            'Stock or semi-custom plywood box and hardwood front cabinets',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [luxury]: [
      {
        title: `${luxury} Quality`,
        descriptions: [
          'Upscale renovations involve top-of-the-line finishes, appliances, and materials, and you can expect the labor costs to be at a premium, too. But with quality comes durability. ',
        ],
        list: {
          title: 'Materials used:',
          items: [
            'Hardwood, natural stone, or ceramic tile flooring ',
            'Granite, marble, or quartz countertops',
            'Custom hardwood cabinetry such as cherry, oak, or maple',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [cosmetic]: [
      {
        title: `${cosmetic} Quality`,
        descriptions: [
          'A standard renovation involves replacing floors, counters, appliances, and fixtures with cost-efficient, builder-grade materials and can keep costs to a minimum.',
        ],
        list: {
          title: 'Materials used:',
          items: [
            'Vinyl, linoleum, or laminate flooring',
            'Formica, butcher block, or marble laminate countertops',
            'Stock-pressed wood, plywood, or particle board cabinets',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [minor]: [
      {
        title: `${minor} Quality`,
        descriptions: [
          'Refacing cabinetry or replacing them entirely with semi-custom cabinets, updating flooring, replacing the sink or other fixtures that will require professional installation are all minor upgrades that can have a meaningful impact on your kitchen’s quality and resale value. ',
        ],
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
    [major]: [
      {
        title: `${major} Quality`,
        descriptions: [
          'Major changes to your kitchen’s structure can include adding or removing walls, installing gas lines and pipes for plumbing, installing custom cabinets and appliances, and updating materials using high-quality alternatives. ',
        ],
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.kitchen,
        },
      },
    ],
  },
  tapToInsights:
    'With any renovation, it’s important to think about the return on investment. A fancy kitchen with all the bells and whistles may improve your quality of life, but it may not increase your home value by as much as you put into it. ',
};
const textBathroom = {
  title: `Bathroom Renovation`,
  description:
    'A bathroom is an obvious necessity, but it can also be a major selling point for your home. Like kitchen renovations, however, the materials you select for the project can range significantly in terms of cost. With some careful planning, you can strike a balance between achieving your own personal design goals and boosting your home’s value.',
  subtitles: {
    [value]: [
      {
        title: FINISH_LEVEL_VALUE_TITLE,
        list: {
          items: [
            '<b>Cabinets:</b> Repaint/refinish existing',
            '<b>Counters:</b> Basic laminate or ceramic tile',
            '<b>Backsplash:</b> Basic laminate or ceramic tile',
            '<b>Floor:</b> Discount/sale-priced vinyl sheet or vinyl tile',
            '<b>Faucet(s), sink(s), &amp; toilet:</b> Budget-priced faucet and sink from brands like Delta, Kingston Brass, and Moen. Base-level porcelain toilet from American Standard.',
            '<b>Lighting:</b> Budget-priced, surface-mounted ceiling lights',
            '<b>Shower/tub &amp; surround:</b> Fiberglass panels/sheets, or pre-fabricated acrylic shower, no tub',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.bathroom,
        },
      },
    ],
    [quality]: [
      {
        title: FINISH_LEVEL_QUALITY_TITLE,
        list: {
          items: [
            '<b>Cabinets:</b> Basic pre-assembled, premium refinishing, or simple reface of existing (no custom cabinetry)',
            '<b>Counters:</b> Premium laminate, stone tile, or pre-fabricated granite',
            '<b>Backsplash:</b> Premium laminate, stone tile, or pre-fabricated granite',
            '<b>Floor:</b> Linoleum, luxury vinyl tile, or plank',
            '<b>Faucet(s), sink(s), &amp; toilet:</b> Mid-level faucet & sink from Delta, Moen, American Standard. Mid-level porcelain toilet from American Standard, base-level from Kohler, or similar',
            '<b>Lighting:</b> Well-priced ceiling surface light, LED rope, plug-in wall sconce',
            '<b>Shower/tub &amp; surround:</b> Pre-fabricated fiberglass shower/tub combo, or premium acrylic sheet surround with base-level acrylic or fiberglass tub',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.bathroom,
        },
      },
    ],
    [midrange]: [
      {
        title: `${midrange} Quality`,
        descriptions: [
          'If you’re hoping to give your bathroom a full facelift that includes some upscale extras — without breaking the bank — opt for a happy medium in terms of upgrade materials and features. This means that you’ll likely be able to splurge on an item or two from your personal wishlist while keeping costs reasonable.',
        ],
        list: {
          title: 'Materials used:',
          items: [
            'Vinyl, linoleum, or ceramic tile flooring',
            'Fiberglass or acrylic shower',
            'Brass fixtures and faucets',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.bathroom,
        },
      },
    ],
    [luxury]: [
      {
        title: `${luxury} Quality`,
        descriptions: [
          'A high-end bathroom upgrade outfits your space with all of the bells and whistles. While it often comes at a cost, it can boost your home’s resale value quite significantly — and provide you with a relaxing and spa-like environment that you can enjoy on a daily basis for years to come.\n',
        ],
        list: {
          title: 'Materials used:',
          items: [
            'Natural stone or porcelain tile flooring',
            'Natural stone or porcelain tile shower',
            'Stainless steel or zinc/zinc-alloy fixtures and faucets',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.bathroom,
        },
      },
    ],
  },
  tapToInsights: () => (
    <span>
      In 2022, the No. 1 reason{' '}
      <a
        href="https://www.statista.com/statistics/1077721/bathroom-remodel-motivations-in-the-us/"
        target="_blank"
        rel="noreferrer"
      >
        homeowners
      </a>{' '}
      renovated their master bathrooms was to refresh an old or outdated style (48%), followed by repairing a
      deteriorating bathroom (33%).
    </span>
  ),
};
const textRoofing = {
  title: `Roofing Replacement Renovation`,
  description:
    'There’s a reason the phrase “a roof over your head” is so ubiquitous: roofs are critical to a home’s structural integrity and one of the most important features for a homeowner to maintain. Unfortunately, this can mean that it’s also one of the most expensive renovations, but it’s a worthy and smart investment in the long term.',
  subtitles: {
    [value]: [
      {
        title: FINISH_LEVEL_VALUE_TITLE,
        list: {
          items: ['Remove existing roof, install asphalt-rolled roofing with stone aggregate facing'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.roofing,
        },
      },
    ],
    [quality]: [
      {
        title: FINISH_LEVEL_QUALITY_TITLE,
        list: {
          items: ['Remove existing roof, install Bitumen asphalt built-up with insulation for energy efficiency'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.roofing,
        },
      },
    ],
    [asphaltShingles]: [
      {
        title: `${asphaltShingles} Quality`,
        descriptions: [
          `Asphalt shingles have long been one of the most popular materials to use for residential roof replacement, with <a href="https://modernize.com/roof/shingles/asphalt">as many as 80% of homeowners</a> choosing them due to their affordability, variety, and durability — with some brands boasting up to a 50-year lifespan. They’re widely available in many colors and styles and relatively easy to install.`,
        ],
        list: {
          title: 'Materials used:',
          items: ['Asphalt-coated paper', 'Resin-treated fiberglass', 'Asphalt-coated fiberglass'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.roofing,
        },
      },
    ],
    [metal]: [
      {
        title: `${metal} Quality`,
        descriptions: [
          'While metal roofing was once primarily the domain of industrial buildings and warehouses, it is now the second most common residential roofing material. Metal roofs can last up to three times as long as asphalt, tile, or wood roofs, and they’re strong enough to withstand fire, hail, and hurricane-force winds. ',
        ],
        list: {
          title: 'Materials used:',
          items: ['Aluminum', 'Corrugated or stone-coated steel', 'Copper'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.roofing,
        },
      },
    ],
  },
  tapToInsights:
    'Some types of asphalt shingles can be installed over old shingles, bypassing the need for a complete roof replacement and saving you money.',
};
const textSiding = {
  title: `Siding Replacement Renovation`,
  description:
    'Like a garage door replacement, a siding facelift not only boosts your home’s resale value, but it can earn you more than a 68% return on investment, too. It also provides one of the first impressions of your home and is key to enhancing your curb appeal, so it pays off to choose wisely.',
  subtitles: {
    [fiberCement]: [
      {
        title: `${fiberCement} Quality`,
        descriptions: [
          'Fiber-cement siding is not only easy to maintain and durable, but versatile as well: with finishes, it can often be made to match just about any exterior home material, from stucco to stone or brick. It’s also fire resistant and extremely sturdy against many elements like humidity, sun, and salt air.',
        ],
        list: {
          title: 'Materials used:',
          items: ['Cellulose fibers', 'Cement', 'Sand'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.siding,
        },
      },
    ],
    [vinyl]: [
      {
        title: `${vinyl} Quality`,
        descriptions: [
          'Vinyl siding is popular for a reason: it’s durable, easy to customize, and maintenance-free. It can be the least expensive choice due to material and labor costs, as installation is often faster than that of fiber-cement siding. And since it won’t crack, chip, or peel, it boosts curb appeal and home value.',
        ],
        list: {
          title: 'Materials used:',
          items: ['Polyvinyl chloride (PVC)'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.siding,
        },
      },
    ],
  },
  tapToInsights:
    'Keep vegetation pruned back away from your home regardless of which siding material you choose. This will help siding dry out faster after precipitation.',
};
const textWindows = {
  title: `Window Replacement`,
  description:
    'Often, homeowners replace windows out of necessity because one or two have functional, moisture, or cosmetic issues. However, as a rule of thumb, you might want to consider replacing your windows if they’re more than 15–20 years old to save on energy costs and make your home more efficient overall. ',
  subtitles: {
    [value]: [
      {
        title: FINISH_LEVEL_VALUE_TITLE,
        list: {
          items: [
            'Aluminum or bargain-priced vinyl construction from brands like Ply Gem, Simonton, BetterBilt, & Thermastar. Windows that open are sliding.',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.windows,
        },
      },
    ],
    [quality]: [
      {
        title: FINISH_LEVEL_QUALITY_TITLE,
        list: {
          items: [
            'Value-priced vinyl construction from brands like ReliaBilt, Tafco, Ply Gem, Simonton & Thermastar. Windows that open are sliding.',
          ],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.windows,
        },
      },
    ],
    [vinyl]: [
      {
        title: `${vinyl} Quality`,
        descriptions: [
          'Cost effective, low maintenance, and energy efficient, vinyl is a popular pick with homeowners looking for windows that are scratch-resistant and easy to clean. These windows also have high durability and won’t corrode, making for a long lifespan. They’re also customizable in terms of color, size, and style.',
        ],
        list: {
          title: 'Materials used:',
          items: ['Polyvinyl chloride (PVC)', 'Glass'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.windows,
        },
      },
    ],
    [wood]: [
      {
        title: `${wood} Quality`,
        descriptions: [
          'Wood windows can look attractive, add value to your home, and be great for saving energy, though they’re typically costlier and require more maintenance — including painting and sealing both at installation and throughout their lifespan. They’re not as popular as vinyl windows, thus supply is lower and it can take longer to obtain them. ',
        ],
        list: {
          title: 'Materials used:',
          items: ['Commonly pine or fir (interior)', 'Aluminum or fiberglass (exterior)', 'Glass'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.windows,
        },
      },
    ],
  },
  tapToInsights:
    'In addition to window style and size, replacement costs can be affected by window location. Those that are easier to access — on the ground floor of a home, for example — are less expensive to replace than skylights or second-story windows.  ',
};
const textFlooring = {
  title: 'Flooring Replacement',
  description:
    'When replacing your floors, you’ll want to make sure that you consider the function of the room and your preferred style equally. If it’s a room that gets a lot of foot traffic, like a kitchen or bathroom, it may be worth looking at more durable materials that aren’t prone to scratches or wear and tear.',
  subtitles: {
    [value]: [
      {
        title: FINISH_LEVEL_VALUE_TITLE,
        list: {
          items: ['Discount/sale-priced vinyl sheet or vinyl tile'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.flooring,
        },
      },
    ],
    [quality]: [
      {
        title: FINISH_LEVEL_QUALITY_TITLE,
        list: {
          items: ['Carpet, refinish existing hardwood, linoleum, luxury vinyl tile, or plank'],
        },
      },
      {
        title: 'Things to consider',
        list: {
          items: THINGS_TO_CONSIDER.flooring,
        },
      },
    ],
  },
  tapToInsights:
    'When you calculate the square footage to determine the amount you’ll need, plan to buy 10% more than you need. Not only will it save the day in case of a miscalculation, but if you don’t need it right away, it could come in handy for future replacement and repair needs.',
};
// const textDeck = {
//   title: `Deck Renovation`,
//   description: 'Whether you’re adding a new deck on or it’s time to replace your existing one, this outdoor space can serve as an important gathering spot for entertaining, outdoor dining, and relaxing in warmer months. While wood has historically been the standard material, more options have now emerged, giving homeowners choices based on their budget and style.',
//   subtitles: {
//     [composite]: [
//       {
//         title: `${composite} Quality`,
//         descriptions: [
//           'Composite decks are an increasingly popular choice for homeowners looking for a more durable and weather-resistant option. Unlike wood, composite decking won’t rot or break down over time, making for easier upkeep — plus, no splinters! While generally more expensive up front, you can save money on maintenance or replacement in the long run.',
//         ],
//         list: {
//           title: 'Materials used:',
//           items: [
//             'Composite board (wood and recycled plastics)',
//             'Concrete'
//           ],
//         },
//       }],
//     [wood]: [
//       {
//         title: `${wood} Quality`,
//         descriptions: [
//           'Tried-and-true wood plank decking is still a popular option for many homeowners due to its appealing traditional appearance and affordability. While it does require a fair amount of maintenance, there are more options than ever — like pressure-treated fir or pine — that can make it easier to preserve.',
//         ],
//         list: {
//           title: 'Materials used:',
//           items: [
//             'Cedar, redwood, mahogany, and teak',
//             'Pressure-treated wood (often pine)',
//             'Concrete'
//           ],
//         },
//       },
//       {
//         title: 'Things to consider',
//         list: {
//           items: [
//             'Decide how you’re going to use your deck before you begin planning your addition — e.g. for entertaining, gardening, or as a pool-adjacent area — as defining its purpose can help with design.',
//             'Location is especially important to consider, since you’ll want to balance sun and shade, determine wind patterns, and maximize privacy. ',
//             'A wood deck should be sanded, stained, and sealed every 2 to 3 years to be properly maintained. If that sounds like too much upkeep, you may want to opt for composite.'],
//         },
//       },
//     ]
//   },
//
//   tapToInsights: 'Composite decking can be hollow or solid. Solid is heavier, more susceptible to fluctuation in temperatures, and pricier than hollow composite, but it tends to look more natural. ',
// };

export const RENOVATION_PROJECT_ICONS = {
  bathroom: 'bathroom',
  kitchen: 'kitchen',
  roofing: 'roof',
  siding: 'siding',
  windows: 'window',
  flooring: 'flooring',
  // deck: 'deck',
  // garage: 'garage',
  // hvac: 'hvac',
};

const RENOVATION_UI = {
  bathroom: {
    image: bathroom,
    copy: textBathroom,
  },
  kitchen: {
    image: kitchen,
    copy: textKitchen,
  },
  roofing: {
    image: roof,
    copy: textRoofing,
  },
  siding: {
    image: siding,
    copy: textSiding,
  },
  windows: {
    image: window,
    copy: textWindows,
  },
  flooring: {
    image: flooring,
    copy: textFlooring,
  },
  // deck: {
  //   image: deck,
  //   copy: textDeck,
  // },
  // garage: {
  //   image: garage,
  //   copy: text2,
  // },
  // hvac: {
  //   image: hvac,
  //   copy: text,
  // },
};

export const RENOVATION_GOAL_OPTIONS = [
  {
    key: 'comfort',
    icon: 'avatar-2',
    label: 'Increase personal comfort',
  },
  {
    key: 'house',
    icon: 'house-singlefamily',
    label: 'Improve home functionality',
  },
  {
    key: 'sale',
    icon: 'realestate',
    label: 'Prepare my home for sale',
  },
  {
    key: 'value',
    icon: 'house-value',
    label: `Maximize my home's value`,
  },
  {
    key: 'other',
    icon: 'other',
    label: 'Other',
    description: 'You tell us!',
  },
];

export const RENOVATIONS_FINANCES_OPTIONS = [
  {
    title: 'Home Equity Investment',
    description: 'Gives you cash in exchange for a share of your home’s future value.',
    financeCalculatorOption: 'HEI',
    // @ts-expect-error TS(7006): Parameter 'isEligibleForHEI' implicitly has an 'an... Remove this comment to see the full error message
    ctaButtonText: isEligibleForHEI => (isEligibleForHEI ? 'Get an Estimate' : null),
    // @ts-expect-error TS(7006): Parameter 'isEligibleForHEI' implicitly has an 'an... Remove this comment to see the full error message
    secondaryButtonText: isEligibleForHEI => (isEligibleForHEI ? 'Add to my scenario' : 'Learn More'),
    secondaryButtonLink:
      'https://www.hometap.com/blog/compare-common-renovation-financing-options#home-equity-investment',
    // @ts-expect-error TS(7006): Parameter 'isEligibleForHEI' implicitly has an 'an... Remove this comment to see the full error message
    tagText: isEligibleForHEI => (isEligibleForHEI ? 'Get an estimate' : null),
    fees: 'Vary by provider',
    timeToSettle: '10–30 years',
    monthlyPayment: 'None',
    interest: 'None',
  },
  {
    title: 'Home equity line of credit (HELOC)',
    description:
      'A line of credit borrowed against the equity you have in your home — allowing you to borrow, spend, and repay as you go.',
    financeCalculatorOption: 'HELOC',
    secondaryButtonText: 'Add to my scenario',
    secondaryButtonLink:
      'https://www.hometap.com/blog/compare-common-renovation-financing-options#home-equity-line-of-credit',
    fees: '2–5% of line of credit amount',
    timeToSettle: '10–30 years',
    monthlyPayment: 'Vary by term, interest rate, and outstanding balance',
    interest: 'Variable',
  },
  {
    title: 'Home Equity Loan',
    description:
      'Allows you to borrow against the equity you’ve accrued, using your home as collateral to guarantee the loan.',
    financeCalculatorOption: 'HEL',
    secondaryButtonText: 'Add to my scenario',
    secondaryButtonLink: 'https://www.hometap.com/blog/compare-common-renovation-financing-options#home-equity-loan',
    fees: '2–5% of total loan amount',
    timeToSettle: '5–30 years',
    monthlyPayment: 'Vary by term, interest rate, and outstanding balance',
    interest: 'Typically fixed',
  },
  {
    title: 'Cash-out refinance',
    description: 'Replaces your existing mortgage with a new home loan for more than you currently owe on your house.',
    secondaryButtonText: 'Learn More',
    secondaryButtonLink: 'https://www.hometap.com/blog/compare-common-renovation-financing-options#cash-out-refinance',
    fees: '3-5% of total loan amount',
    timeToSettle: '10–30 years',
    monthlyPayment: 'Vary by term, interest rate, and outstanding balance',
    interest: 'Fixed or variable',
  },
  {
    title: 'Credit card',
    description:
      'Often used in place of cash or a check and gives you access to an unsecured, revolving line of credit with a maximum limit.',
    secondaryButtonText: 'Learn More',
    secondaryButtonLink: 'https://www.hometap.com/blog/compare-common-renovation-financing-options#credit-card',
    fees: 'Varies by provider',
    timeToSettle: 'N/A',
    monthlyPayment: 'Typically a minimum of 1–5% of outstanding balance',
    interest: 'Varies by provider',
  },
  {
    title: 'Personal loan',
    description:
      'Provided by a traditional lender like a bank, and isn’t based on home equity, but can still be used to renovate your home.',
    secondaryButtonText: 'Learn More',
    secondaryButtonLink: 'https://www.hometap.com/blog/compare-common-renovation-financing-options#personal-loan',
    fees: 'Vary by lender, state, and loan amount',
    timeToSettle: '2–7 years',
    monthlyPayment: 'Vary by term, interest rate, and outstanding balance',
    interest: 'Varies by provider',
  },
];

export const LEGACY_RENOVATION_PROJECT_NAMES = {
  kitchen: 'Kitchen',
  bathroom: 'Bathroom',
  roofing: 'Roofing',
  siding: 'Siding',
  windows: 'Windows',
  // deck: 'Deck Addition',
  // garage: 'Garage Door',
  // hvac: 'HVAC System',
};

export const getRenovationGoalOptions = () => {
  return RENOVATION_GOAL_OPTIONS.map(({ key, label, icon, description }) => {
    return {
      label,
      value: key,
      icon,
      description,
    };
  });
};

// @ts-expect-error TS(7006): Parameter 'renovationTemplate' implicitly has an '... Remove this comment to see the full error message
export const createRenovationFromTemplate = renovationTemplate => ({
  renovation_kind: renovationTemplate.code,
  estimated_cost: renovationTemplate.average_cost,
  added_value: renovationTemplate.average_value_increase,
});

// @ts-expect-error TS(7006): Parameter 'kind' implicitly has an 'any' type.
export const parseRenovationKind = kind => {
  const [project, finishLevel] = kind.split(':');
  return { project, finishLevel };
};

// @ts-expect-error TS(7006): Parameter 'renovation' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getRenovationSummary = renovation => {
  if (!renovation) return null;

  const { project, finishLevel } = parseRenovationKind(renovation.renovation_kind);

  const costRecouped = renovation.estimated_cost && renovation.added_value / renovation.estimated_cost;

  return {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    project: LEGACY_RENOVATION_PROJECT_NAMES[project] || project,
    finishLevel,
    addedValue: renovation.added_value,
    costRecouped,
  };
};

// @ts-expect-error TS(7006): Parameter 'renovation' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getRenovationDetailsUI = renovation => {
  if (!renovation) return null;

  const { project } = parseRenovationKind(renovation.renovation_kind);
  const projectKey = project.toLowerCase();
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const foundUI = RENOVATION_UI[projectKey];

  if (!foundUI) return null;
  return {
    ...foundUI,
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    icon: RENOVATION_PROJECT_ICONS[projectKey],
  };
};

// @ts-expect-error TS(7006): Parameter 'finishLevel' implicitly has an 'any' ty... Remove this comment to see the full error message
export const getRenovationFinishLevelLabel = finishLevel => FINISH_LEVEL_OPTION_LABELS[finishLevel] || finishLevel;
