import { MuiTextInput, Button, InputMask } from '@hometap/htco-components';
import { useState } from 'react';
import chartImg from 'images/equity-planner/dashboard/missing-chart.png';
import { useSelfReportedHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';
import BuildProfileFlowLayout from '../dashboard-build-profile/BuildProfileFlowLayout';

const ValueMissingPanel = () => {
  const { home } = useCurrentHome();
  const update = useSelfReportedHomeValuationMutation(home);
  const [value, setValue] = useState<number | ''>('');

  const onChange = (value: string) => setValue(parseInt(value));
  // @ts-expect-error TS(2345): Argument of type 'number | ""' is not assignable to parameter of type 'void'.
  const onClick = async () => await update.mutateAsync(value);

  return (
    <BuildProfileFlowLayout stepImage={chartImg} stepImageAlt="chart-image">
      <div>
        {/* @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'. */}
        <p className="!text-sm uppercase">Finish setup for {home?.address?.street}</p>
        <h2>
          We’re having some trouble fetching your <span>home value</span>
        </h2>
        <p className="mt-[16px] text-base">
          We were unable to calculate your home value, but you still can use the Home Equity Dashboard! To continue,
          please provide us with an estimate of your current home value.
        </p>
        <div className="mt-[40px]">
          <MuiTextInput
            value={value}
            onChange={onChange}
            width="280px"
            label="Current Home Value"
            type="number"
            inputMode="decimal"
            data-testid="current-home-value"
            mask={InputMask.MONEY_USD_MASK}
            startAdornment="$"
            unmask="typed"
            required
          />
        </div>
        <div className="mt-[48px]">
          <Button width="280px" onClick={onClick} disabled={!value} data-testid="save-home-value-button">
            Save home value
          </Button>
        </div>
      </div>
    </BuildProfileFlowLayout>
  );
};

export default ValueMissingPanel;
