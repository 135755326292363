import { createContext, useCallback, useContext, useState } from 'react';

import { ProgressBar as ProgressBarBase } from '@hometap/htco-components';
import { twMerge } from 'tailwind-merge';

const PROGRESS_DEFAULT = { steps: 0, currStep: 0, visible: false };

export const ProgressContext = createContext(PROGRESS_DEFAULT);

export const useProgressData = () => {
  // @ts-expect-error TS(2339): Property 'progress' does not exist on type '{ step... Remove this comment to see the full error message
  const { progress } = useContext(ProgressContext);
  return progress;
};

// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
export const ProgressBar = props => {
  const { visible, currStep, steps } = useProgressData();
  return (
    visible && (
      // TODO: PAT-883 remove wrapper div after the component lib change
      <div className={twMerge(props.className, 'w-full')}>
        <ProgressBarBase {...props} curStep={currStep} steps={steps} />
      </div>
    )
  );
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(PROGRESS_DEFAULT);
  const clear = useCallback(() => setProgress(PROGRESS_DEFAULT), []);
  // @ts-expect-error TS(2322): Type '{ progress: { steps: number; currStep: numbe... Remove this comment to see the full error message
  return <ProgressContext.Provider value={{ progress, setProgress, clear }}>{children}</ProgressContext.Provider>;
};

export default ProgressProvider;
