import logo from 'images/hometap-logomark.svg';
import { Link } from 'react-router-dom';

const HometapLogo = ({ to = '/dashboard' }) => (
  <Link to={to} className="flex">
    <img className="min-w-[48px]" src={logo} alt="hometap logo" />
  </Link>
);

export default HometapLogo;
