import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button, Loader } from '@hometap/htco-components';
import Footer from 'components/Footer/Footer';
import FEATURED_OFFERS from './featuredOffers';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { browserTrack } from 'utils/segment';

import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

// @ts-expect-error TS(7031): Binding element 'offer' implicitly has an 'any' ty... Remove this comment to see the full error message
const FeaturedOffer = ({ offer }) => {
  const { name, image, title, body, bodyAlt, logo, logoAlt, ctaText, ctaLink, disclaimer } = offer;

  const onOfferClick = () => {
    browserTrack.featuredOffersClick({
      offer: name,
    });
    window.open(ctaLink, '_blank');
  };

  return (
    <div className="group m-auto flex flex-col gap-4 border-0 border-b border-solid border-neutral-light-100 pb-12 pt-12 even:justify-end first-of-type:pt-0 last-of-type:border-b-0 sm:pb-16 sm:pt-22 sm:last-of-type:pb-0 sm:max-md:mx-8 md:max-w-[90%] md:gap-6">
      <div className="flex flex-col-reverse items-start justify-between gap-x-12 sm:flex-row sm:group-even:flex-row-reverse">
        <div className="mt-12 flex w-full max-w-full xs:max-sm:min-w-full sm:mt-0 sm:w-1/2 sm:max-md:min-w-[386px]">
          <img className="m-auto block w-full max-w-[488px]" src={image} alt={bodyAlt} />
        </div>
        <div>
          <div className="max-w-[560px] md:max-w-[616px]">
            <img className="max-w-[176px] sm:max-w-[220px]" src={logo} alt={logoAlt} />
            <h4 className="mb-4 mt-6">{title}</h4>

            <ul className="ml-11 list-disc">
              {/* @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type. */}
              {body?.map(item => {
                return (
                  <li
                    className="mt-2 text-sm leading-6 text-neutral-dark-100 marker:text-green-medium sm:text-base"
                    key={item}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>

            <Button className="my-6 w-full xs:w-auto" size="small" onClick={onOfferClick}>
              {ctaText}
            </Button>
            <div className="text-xs text-neutral-dark-50">{disclaimer}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeaturedOffersController = () => {
  const flags = useFlags();

  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ home:... Remove this comment to see the full error message
  const { home, loading } = useCurrentHome();

  if (loading || !home) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  const configContext = { flags, home };

  const renderedOffers = FEATURED_OFFERS.filter(offer => {
    return offer.shouldShowOffer ? offer.shouldShowOffer(configContext) : true;
  });

  return (
    <ScrollToTop>
      <div className="to-transparent via-transparent bg-gradient-to-t from-blue-15">
        {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
        <PrimaryLayoutGrid>
          <div className="col-span-full">
            <div className="mb-2 text-sm uppercase tracking-wider text-neutral-dark-100">
              Offers for you from Hometap’s
            </div>
            <h2>
              <span className="text-green-medium">Best-in-class </span>partners
            </h2>
            <div className="mb-[86px] mt-2 max-w-[560px] text-sm leading-6 xs:text-base sm:text-lg sm:leading-8 md:max-w-[744px]">
              <strong>As a homeowner, you already have plenty to think about.</strong> That’s why we’ve done the work to
              find and select the best partners possible to help you get the most out of homeownership — industry
              leaders who put their customers first.
            </div>
            <div className="mb-8 sm:mb-26">
              {renderedOffers.map(offer => {
                const configuredCtaLink =
                  // @ts-expect-error TS(2349): This expression is not callable.
                  typeof offer.ctaLink === 'function' ? offer.ctaLink(configContext) : offer.ctaLink;
                // @ts-expect-error TS(2339): Property 'componentOverride' does not exist on typ... Remove this comment to see the full error message
                const componentOverride = offer.componentOverride?.(configContext);

                if (componentOverride) {
                  return componentOverride;
                }

                return <FeaturedOffer key={offer.id} offer={{ ...offer, ctaLink: configuredCtaLink }} />;
              })}
            </div>
            {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ theme: ... Remove this comment to see the full error message */}
            <Footer theme="light" />
          </div>
        </PrimaryLayoutGrid>
      </div>
    </ScrollToTop>
  );
};

export default FeaturedOffersController;
