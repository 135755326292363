import NavNestedItems from 'components/nav/NavNestedItems/NavNestedItems';
import NavItemLink from 'components/nav/NavItemLink/NavItemLink';

import cx from 'classnames';
import PropTypes from 'prop-types';
import usePrimaryNav from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';

// @ts-expect-error TS(7031): Binding element 'groupIndex' implicitly has an 'an... Remove this comment to see the full error message
const NavSectionGroup = ({ groupIndex, section, active, onToggle, onNavItemClick, open }) => {
  // @ts-expect-error TS(2339): Property 'isMobileNavOpen' does not exist on type ... Remove this comment to see the full error message
  const { isMobileNavOpen } = usePrimaryNav();

  return (
    <>
      <div
        className={cx(
          'relative w-full before:absolute before:-left-6 before:-right-6 before:top-0 before:h-px before:bg-neutral-light-100',
          {
            'pt-6 before:content-[""]': groupIndex !== 0 && isMobileNavOpen,
          },
        )}
      >
        <NavNestedItems
          title={section.section}
          icon={section.icon}
          active={active}
          open={open}
          onToggle={onToggle}
          navItems={section.items}
          onNavItemClick={onNavItemClick}
          disabled={section.disabled}
        />
      </div>
      {section?.extraContent?.()}
    </>
  );
};

const NavPrimaryMenu = ({
  // @ts-expect-error TS(7031): Binding element 'openSection' implicitly has an 'a... Remove this comment to see the full error message
  openSection,
  // @ts-expect-error TS(7031): Binding element 'onToggleOpenSection' implicitly h... Remove this comment to see the full error message
  onToggleOpenSection,
  // @ts-expect-error TS(7031): Binding element 'navLinks' implicitly has an 'any'... Remove this comment to see the full error message
  navLinks,
  // @ts-expect-error TS(7031): Binding element 'navSectionGroups' implicitly has ... Remove this comment to see the full error message
  navSectionGroups,
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(7031): Binding element 'onNavItemClick' implicitly has an... Remove this comment to see the full error message
  onNavItemClick,
  // @ts-expect-error TS(7031): Binding element 'dataTestId' implicitly has an 'an... Remove this comment to see the full error message
  dataTestId,
}) => {
  // @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type.
  const getActiveSection = section => section.items.some(item => item.active);
  const {
    // @ts-expect-error TS(2339): Property 'navItemsExpanded' does not exist on type... Remove this comment to see the full error message
    navItemsExpanded,
    // @ts-expect-error TS(2339): Property 'isSidebarOpeningOrOpen' does not exist o... Remove this comment to see the full error message
    isSidebarOpeningOrOpen,
    // @ts-expect-error TS(2339): Property 'isMobile' does not exist on type '{}'.
    isMobile,
    // @ts-expect-error TS(2339): Property 'isSidebarClosingOrClosed' does not exist... Remove this comment to see the full error message
    isSidebarClosingOrClosed,
    // @ts-expect-error TS(2339): Property 'onChangeIsDesktopNavOpen' does not exist... Remove this comment to see the full error message
    onChangeIsDesktopNavOpen,
    // @ts-expect-error TS(2339): Property 'onChangeIsHomePickerOpen' does not exist... Remove this comment to see the full error message
    onChangeIsHomePickerOpen,
  } = usePrimaryNav();

  // @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type.
  const handleNavSectionClick = (section, isSectionOpen) => {
    if (isSectionOpen || !navItemsExpanded) {
      onToggleOpenSection(section);
    } else {
      onToggleOpenSection(null);
    }
    onChangeIsDesktopNavOpen(true);
    onChangeIsHomePickerOpen(false);
  };

  return (
    <div className={cx('relative flex w-full flex-col items-center bg-white', className)} data-testid={dataTestId}>
      <div
        className={cx('flex w-full flex-col items-center gap-3 pt-6 transition-all duration-500', {
          'max-w-[calc(100%-theme(spacing.6))]': isSidebarOpeningOrOpen,
          'max-w-[calc(100%-theme(spacing.4))]': isMobile || isSidebarClosingOrClosed,
        })}
      >
        {/* @ts-expect-error TS(7006): Parameter 'link' implicitly has an 'any' type. */}
        {navLinks.map(link => (
          <NavItemLink
            key={link.title}
            icon={link.icon}
            title={navItemsExpanded && link.title}
            hoverTitle={link.title}
            to={link.path}
            active={link.active}
            onClick={onNavItemClick}
          />
        ))}
        {/* @ts-expect-error TS(7006): Parameter 'sectionGroup' implicitly has an 'any' t... Remove this comment to see the full error message */}
        {navSectionGroups.map((sectionGroup, groupIndex) => {
          return (
            <div key={`nav-sectionGroup-${groupIndex}`} className="flex w-full flex-col items-center gap-3 pb-3">
              {/* @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type. */}
              {sectionGroup.map(section => {
                return (
                  <NavSectionGroup
                    key={section.section}
                    groupIndex={groupIndex}
                    active={getActiveSection(section)}
                    open={openSection === section.section}
                    section={section}
                    // @ts-expect-error TS(7006): Parameter 'isSectionOpen' implicitly has an 'any' ... Remove this comment to see the full error message
                    onToggle={isSectionOpen => handleNavSectionClick(section.section, isSectionOpen)}
                    onNavItemClick={onNavItemClick}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

NavPrimaryMenu.propTypes = {
  openSection: PropTypes.string,
  onToggleOpenSection: PropTypes.func,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ),
  navSectionGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        section: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            active: PropTypes.bool,
          }),
        ).isRequired,
        extraContent: PropTypes.func,
      }),
    ),
  ),
  className: PropTypes.string,
};

export default NavPrimaryMenu;
