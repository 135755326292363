// TODO: We don't cover edge cases like not providing certain fields and then rendering a strange string like " , , "

export const formatInlineAddress = (address = {}) => {
  // @ts-expect-error TS(2339): Property 'street' does not exist on type '{}'.
  const { street = '', unit, city, state = '', zip_code = '' } = address;
  return `${street}${unit ? `, ${unit}` : ''} | ${city ? `${city}, ` : ''}${state}${zip_code ? ` ${zip_code}` : ''}`;
};

// @ts-expect-error TS(7006): Parameter 'address' implicitly has an 'any' type.
export const formatInlineAddressForMobile = (address, isScreenSize) => {
  const addressParts = address.split('|');
  // @ts-expect-error TS(7006): Parameter 'part' implicitly has an 'any' type.
  return isScreenSize('sm') ? addressParts.map((part, i) => <p key={`${part}-${i}`}>{part}</p>) : address;
};

// @ts-expect-error TS(7006): Parameter 'unit' implicitly has an 'any' type.
// eslint-disable-next-line max-params
export const formatFullAddress = (street = '', unit, city = '', state = '', zipCode = '') => {
  return `${street}${unit && unit.length > 0 ? `, ${unit}` : ''}, ${city}, ${state} ${zipCode}`;
};

export const formatFullAddressWithCommas = (address = {}) => {
  // @ts-expect-error TS(2339): Property 'street' does not exist on type '{}'.
  const { street = '', unit, city, state = '', zip_code = '' } = address;
  return `${street}${unit && unit.length > 0 ? `, ${unit}` : ''}, ${city ? `${city}, ` : ''}${state}, ${zip_code}`;
};

export const formatOnlyStreetAddress = (address = {}) => {
  // @ts-expect-error TS(2339): Property 'street' does not exist on type '{}'.
  const { street = '', unit } = address;
  return `${street}${unit && unit.length > 0 ? `, ${unit}` : ''}`;
};
