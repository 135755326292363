import cx from 'classnames';

import { Button, Container, FormError, Icon, NumberInput, useForm, useWindowSize } from '@hometap/htco-components';
import { getInvalidCalculationErrorMessage, isValidDebtInput } from '../../utils/debtCalculations';
import { trackAddDebt } from '../../utils/debtCalculatorTracks';

import './DebtForm.scss';

// @ts-expect-error TS(7031): Binding element 'icon' implicitly has an 'any' typ... Remove this comment to see the full error message
const DebtForm = ({ icon, title, buttonText, onDetailsSubmit, typeOfDebt, debts, onCancel, allowCancel }) => {
  const { registerField, handleSubmit, formData, errors, setErrors, isFormValid, handleFieldChange } = useForm({});
  const { isScreenSize } = useWindowSize();

  // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
  const handleSubmitWithDebtValidationError = e => {
    const { balance, interestRate, monthlyPayment } = formData;

    if (!isFormValid) {
      handleSubmit(onDetailsSubmit)(e);
    } else if (!isValidDebtInput(balance, interestRate / 100, monthlyPayment)) {
      e.preventDefault();
      setErrors(
        'invalid_calculation',
        getInvalidCalculationErrorMessage(formData.balance, formData.monthlyPayment),
        true,
      );
    } else {
      trackAddDebt({ balance, interestRate, monthlyPayment, debts, typeOfDebt });
      handleSubmit(onDetailsSubmit)(e);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
  const handleInputValidation = (val, name, error) => {
    handleFieldChange(val, name, error);
    if (isValidDebtInput(formData.balance, formData.interestRate / 100, val)) {
      setErrors('invalid_calculation', null);
    }
  };

  return (
    <Container className={cx('DebtDetailsContainer', { isIndex: allowCancel })}>
      <div style={{ maxWidth: '460px', width: '100%', margin: '0 auto' }}>
        <Container className="DebtDetailsIconContainer" justify="flex-start" align="flex-start">
          <Icon name={icon} pullLeft={true} color="#366ced" size={isScreenSize('sm') ? '2x' : '3x'} />
        </Container>
        <Container className="DebtDetailsTitleContainer" row justify="flex-start" align="flex-start">
          <h4>{title}</h4>
        </Container>
        <form onSubmit={handleSubmitWithDebtValidationError} data-testid="debt-form">
          <NumberInput
            label="Current balance"
            type="currency"
            placeholder="$"
            precision={0}
            required={true}
            data-testid="debt-balance"
            {...registerField('balance')}
            onChange={handleInputValidation}
          />
          <NumberInput
            label="Interest rate"
            type="percent"
            placeholder="%"
            min={0}
            max={100}
            required={true}
            data-testid="debt-interest-rate"
            {...registerField('interestRate')}
            onChange={handleInputValidation}
          />
          <NumberInput
            label="Total monthly payment"
            type="currency"
            placeholder="$"
            precision={0}
            required={true}
            data-testid="debt-monthly-payment"
            {...registerField('monthlyPayment')}
            onChange={handleInputValidation}
          />
          {errors.invalid_calculation?.message && <FormError error={errors.invalid_calculation?.message} />}
          <Container className="DebtDetailsButtonContainer">
            <Button type="submit" className="DebtDetailsButtonSubmit" data-testid="add-debt-button">
              {buttonText}
            </Button>
            {!allowCancel && (
              <Button theme="link" className="DebtDetailsButtonCancel" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Container>
        </form>
      </div>
    </Container>
  );
};

export default DebtForm;
