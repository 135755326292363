import { useEffect, useState } from 'react';
import { SelectionSet, Button } from '@hometap/htco-components';
import { difference } from 'lodash';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { LIEN_TYPE_SELECTIONS } from 'apps/dashboard/constants/buildYourProfile';
import buildProfileLandingImg from 'images/dashboard/build-profile-house-1.jpg';
import './SelectDebts.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';
import BuildProfileFlowLayout from './BuildProfileFlowLayout';

// @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
const SelectDebts = ({ onNext, onBack, form, headerText = 'Do you have any new debt attached to your property?' }) => {
  const { formData, registerField, updateFormData } = form;
  const { liens: selectedLiens } = formData;
  const fieldProps = registerField('liens');
  const [disableNextButton, setDisableNextButton] = useState(true);

  const { home } = useCurrentHome();
  const { currentScenario, getEquityScenarioLiensAsync, deleteLien } = useEquityScenarioContext();

  // @ts-expect-error TS(2339): Property 'results' does not exist on type '{}'.
  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;
  // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;

  useEffect(() => {
    const getLiens = async () => {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  useEffect(() => {
    setDisableNextButton(!selectedLiens?.length);
  }, [selectedLiens]);

  const removeLienFromFormData = () => {
    return Object.keys(formData).reduce((data, key) => {
      // If the field is for a lien that is currently selected, leave it in the form
      // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
      if (selectedLiens.some(lien => key.includes(lien)) || key === 'liens') {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key] = formData[key];
      } else {
        // For any unselected liens, remove the associated field values form the form
        delete formData[key];

        if (!selectedLiens.includes('mortgage_loan')) {
          localStorage.setItem('is_confirmed_mortgage_loan', 'false');
        }
      }
      return data;
    }, {});
  };

  const onSelectDebtsNext = () => {
    // Determine which liens should be removed by comparing the selected liens to the liens saved to the scenario
    // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
    const scenarioLienTypes = equityScenarioLiens?.map(lien => lien.lien_type);
    const liensToRemove = difference(scenarioLienTypes, selectedLiens);

    if (!liensToRemove.length) {
      return onNext();
    }

    // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
    equityScenarioLiens?.forEach(async lien => {
      if (liensToRemove.includes(lien.lien_type)) {
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
        await deleteLien(currentScenario?.id, lien.id);
        const updatedFormData = removeLienFromFormData();
        updateFormData({ ...updatedFormData, liens: formData.liens });
        onNext();
        // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
        await getEquityScenarioLiensAsync.execute(home?.id, currentScenario?.id);
      }
    });
  };

  return (
    <BuildProfileFlowLayout stepImage={buildProfileLandingImg} stepImageAlt="home-equity">
      <h3>{headerText}</h3>
      <div className="mb-8 mt-2 text-sm text-neutral-dark-75 sm:mb-10 sm:text-base">Select all that apply.</div>
      <SelectionSet
        name="liens"
        data-testid="liens"
        labelWidth="100%"
        options={LIEN_TYPE_SELECTIONS}
        className="[&_label]:mr-0 [&_label]:text-left"
        // @ts-expect-error TS7006
        onChange={(value, ...args) => {
          fieldProps.onChange(value, ...args);
        }}
        value={selectedLiens}
        multiple
      />
      <div className="flex flex-col-reverse justify-between xs:flex-row xs:items-center">
        <Button className="!pl-0" theme="link" onClick={onBack} data-testid="back-button">
          Back
        </Button>
        <Button
          className="!ml-0"
          size="large"
          disabled={disableNextButton}
          onClick={onSelectDebtsNext}
          data-testid="next-button"
        >
          Next
        </Button>
      </div>
    </BuildProfileFlowLayout>
  );
};

export default SelectDebts;
