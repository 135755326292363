import PropTypes from 'prop-types';

import { Button } from '@hometap/htco-components';
import cx from 'classnames';
import './HomeEquityCard.scss';

const NumberPlaceHolder = () => {
  return (
    <div className="NumberPlaceholder">
      <span className={cx('NumberPlaceHolderText', 'NumberPlaceHolderDollar')}>$</span>
      <div className="Square"></div>
      <div className="Square"></div>
      <div className="Square"></div>
      <span className={cx('NumberPlaceHolderText', 'NumberPlaceHolderComma')}>,</span>
      <div className="Square"></div>
      <div className="Square"></div>
      <div className="Square"></div>
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const HomeEquityCardBlank = ({ title, description, linkText, onLinkButtonClick, showLinkButton = true }) => {
  return (
    <div className={cx('HomeEquityCard', 'isBlankTheme')}>
      <div className="HomeEquityCardDescription">
        <div className={cx('HomeEquityCardInfoContainer')}>
          <h2>{title}</h2>
        </div>
      </div>
      <NumberPlaceHolder />
      <div className="HomeEquityCardForecast">{description}</div>
      {showLinkButton && (
        <Button className="HomeEquityCardCTA" theme="link" onClick={onLinkButtonClick}>
          {linkText}
        </Button>
      )}
    </div>
  );
};

HomeEquityCardBlank.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showLinkButton: PropTypes.bool.isRequired,
  linkText: PropTypes.string,
  onLinkButtonClick: PropTypes.func,
};

export default HomeEquityCardBlank;
