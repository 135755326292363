import { Button } from '@hometap/htco-components';
import { useNavigate } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';

const BackToDashBoardButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      theme="link"
      className="!p-0"
      onClick={() => navigate(`/${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`, { replace: true })}
    >
      Back to home equity forecast
    </Button>
  );
};

export default BackToDashBoardButton;
