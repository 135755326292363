import { apiWithAuth } from 'utils/api';

/*
  HOME ENDPOINTS 
*/

export const fetchHomes = () => {
  return apiWithAuth.v1.get(`/homes/`);
};

// @ts-expect-error TS(7006): Parameter 'home_id' implicitly has an 'any' type.
export const fetchHome = async home_id => {
  const home = await apiWithAuth.v1.get(`/homes/${home_id}`);
  return home;
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const updateHome = (homeId, data) => {
  return apiWithAuth.v1.put(`/homes/${homeId}/`, data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const patchHome = (homeId, data) => {
  return apiWithAuth.v1.patch(`/homes/${homeId}/`, data);
};

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export const addNewProperty = data => {
  return apiWithAuth.v1.post('/homes/', data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const fetchHomeValuation = homeId => apiWithAuth.v1.post(`/homes/${homeId}/get-or-request-home-valuation/`);
// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const getSelfReportedHomeValuation = homeId => apiWithAuth.v1.get(`/homes/${homeId}/self-reported-valuation/`);

/*
  EQUITY SCENARIO ENDPOINTS
*/

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const postEquityScenario = (homeId, data) => {
  return apiWithAuth.v1.post(`/homes/${homeId}/equity-scenarios/`, data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const getEquityScenario = (homeId, equityScenarioId) => {
  return apiWithAuth.v1.get(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/`);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const getEquityScenarios = homeId => {
  return apiWithAuth.v1.get(`/homes/${homeId}/equity-scenarios/`);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const patchEquityScenario = (homeId, equityScenarioId, data) => {
  return apiWithAuth.v1.patch(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/`, data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const deleteEquityScenario = (homeId, equityScenarioId) => {
  return apiWithAuth.v1.delete(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/`);
};

/*
  SELF REPORTED LIEN ENDPOINTS
*/

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const postSelfReportedLien = (homeId, data) => {
  return apiWithAuth.v1.post(`/homes/${homeId}/self-reported-liens/`, data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const getSelfReportedLiens = homeId => {
  return apiWithAuth.v1.get(`/homes/${homeId}/self-reported-liens/`);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const patchSelfReportedLien = (homeId, selfReportedLienId, data) => {
  return apiWithAuth.v1.patch(`/homes/${homeId}/self-reported-liens/${selfReportedLienId}/`, data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const deleteSelfReportedLien = (homeId, selfReportedLienId) => {
  return apiWithAuth.v1.delete(`/homes/${homeId}/self-reported-liens/${selfReportedLienId}/`);
};

/*
  EQUITY SCENARIO LIEN ENDPOINTS
*/

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const postEquityScenarioLien = (homeId, equityScenarioId, data) => {
  return apiWithAuth.v1.post(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/`, data);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const getEquityScenarioLiens = (homeId, equityScenarioId) => {
  return apiWithAuth.v1.get(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/`);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const patchEquityScenarioLien = (homeId, equityScenarioId, equityScenarioLienId, data) => {
  return apiWithAuth.v1.patch(
    `/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/${equityScenarioLienId}/`,
    data,
  );
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const deleteEquityScenarioLien = (homeId, equityScenarioId, equityScenarioLienId) => {
  return apiWithAuth.v1.delete(
    `/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/${equityScenarioLienId}/`,
  );
};

/*
  PRICING ENDPOINTS
*/

// @ts-expect-error TS(7006): Parameter 'requestBody' implicitly has an 'any' ty... Remove this comment to see the full error message
export const postAnnualAppreciation = async requestBody => {
  const annualAppreciation = await apiWithAuth.v1.post(`/pricing/annual-appreciation/`, requestBody);
  return annualAppreciation;
};

/*
  PRODUCT ENDPOINTS
*/

export const getDefaultProduct = async () => {
  return await apiWithAuth.v1.get(`/products/default/`);
};

/*
  TRACK ENDPOINTS
*/

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchTrack = async id => {
  return await apiWithAuth.v1.get(`/tracks/${id}`);
};
