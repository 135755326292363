import { twMerge } from 'tailwind-merge';

import cx from 'classnames';

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const PageTitle = ({ className, title }) => {
  return (
    <p className={twMerge(cx('col-span-full m-0 mb-2 text-sm font-bold text-neutral-dark-75', className))}>{title}</p>
  );
};

export default PageTitle;
