import { twMerge } from 'tailwind-merge';
import cx from 'classnames';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const PrimaryLayoutGrid = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        cx(
          'relative m-auto grid w-full max-w-[1552px] grid-cols-4 gap-6 px-4 sm:grid-cols-8 sm:px-8 md:grid-cols-12 md:px-12',
          className,
        ),
      )}
    >
      {children}
    </div>
  );
};

export default PrimaryLayoutGrid;
