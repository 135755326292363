import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Badge, Loader } from '@hometap/htco-components';
import { convertDateStringToHumanReadable } from 'utils/date';
import logo from 'images/hometap-logomark.svg';
import CMSContent from './components/CMSContent';
import { CATEGORY_COLOR_MAP } from 'apps/dashboard/constants/resources';
import Insight from 'components/insight/Insight';
import Footer from 'components/Footer/Footer';
import { useSanityData } from 'apps/dashboard/hooks';
import './ResourcesPost.scss';

const DEFAULT_AUTHOR_NOTE = `Hometap is made up of a collaborative team of underwriters, investment managers, financial analysts, 
    and—most importantly—homeowners—in the home financing field that understand the challenges that come with owning a home.`;

const ResourcesPost = ({ note = DEFAULT_AUTHOR_NOTE }) => {
  const { articles } = useSanityData();
  const { postTitle } = useParams();
  const post = articles.find(post => post.urlSlug === postTitle);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!post) return <Loader type="dot-pulse" />;

  const { author, category, content, coverImage, date, length, title } = post;

  return (
    <div className="ResourcesPost">
      <div className="ResourcesPostContainer PageBodyCenteredContainer">
        <div className="ResourcesPost">
          <Badge label={category} theme={CATEGORY_COLOR_MAP[category]} className="ResourceCardCategory" />
          <h1 className="ResourcePostTitle">{title}</h1>
          {length && <div className="ResourcePostLength">{length} min read</div>}
          <img className="ResourcePostCoverImage" src={coverImage} alt="" />
          <div className="ResourcePostAuthor">
            <img src={logo} alt="Hometap logo" />
            By {author} on {convertDateStringToHumanReadable(date)}
          </div>
          <div className="ResourcePostInner">
            <div className="ResourcePostContent">
              <CMSContent content={content} />
            </div>
            <div className="ResourcesPostNote">
              <Insight
                header="YOU SHOULD KNOW"
                text="We do our best to make sure that the information in this post is as accurate as possible as of the date
                it is published, but things change quickly sometimes. Hometap does not endorse or monitor any linked
                websites. Individual situations differ, so consult your own finance, tax or legal professional to
                determine what makes sense for you."
              />
            </div>
            <div className="ResourcePostDivider" />
            <div className="ResourcePostDisclaimer">{note}</div>
          </div>
        </div>
        {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
        <Footer />
      </div>
    </div>
  );
};

export default ResourcesPost;
