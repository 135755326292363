import { useQuery } from '@tanstack/react-query';
import { fetchInvestmentSummary } from 'apps/settlement/data/settlementRequests';

// @ts-expect-error TS(7031): Binding element 'investmentId' implicitly has an '... Remove this comment to see the full error message
const useTrackInvestment = ({ investmentId }) => {
  return useQuery({
    queryFn: () => fetchInvestmentSummary(investmentId),
    enabled: !!investmentId,
    queryKey: ['track.investment', investmentId],
  });
};

export default useTrackInvestment;
