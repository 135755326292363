import * as Sentry from '@sentry/react';
import env from './env';

const init = () => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY,
      release: process.env.REACT_APP_VERSION,
      environment: env.getEnvLabel(),
      debug: process.env.NODE_ENV === 'development',
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // Issue with the 3rd party address widget (mobile)
        "Can't find variable: __AutoFillPopupClose__",
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Friend Buy
        /https?:\/\/((cdn|www)\.)?(?:public|campaign|static).fbot.me.*/i,
      ],
    });
  }
};

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
const setUser = data => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.setUser(data);
  }
};

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const log = (message, data, is_error) => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    if (is_error) {
      return Sentry.captureException(new Error(message), data);
    }
    Sentry.captureMessage(message, data);
  }
};

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const logError = (message, metadata) => {
  log(message, { level: 'error', ...metadata }, true);
};

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const logWarning = (message, metadata) => {
  // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
  log(message, { level: 'warning', ...metadata });
};

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const logDebug = (message, metadata) => {
  // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
  log(message, { level: 'debug', ...metadata });
};

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const logErrorWrapper = (message, error) => {
  if (error) {
    logError(message, {
      tags: {
        statusCode: error?.response?.status ?? error?.request?.status,
        statusText: error?.response?.statusText ?? error?.request?.statusText,
      },
      extra: {
        errorMessage: error.message,
        requestUrl: error.config?.url,
        ...(error?.response?.data && { responseData: JSON.stringify(error.response.data) }),
      },
    });
  }
};

const sentryMethods = {
  init,
  setUser,
  logError,
  logWarning,
  logDebug,
  logErrorWrapper,
};

export default sentryMethods;
