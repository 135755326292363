import PricingMatrixHeading from '../PricingMatrixHeading/PricingMatrixHeading';
import cx from 'classnames';

import './PricingMatrixCard.scss';
import PricingMatrixValue from '../PricingMatrixValue/PricingMatrixValue';

// @ts-expect-error TS(7031): Binding element 'rows' implicitly has an 'any' typ... Remove this comment to see the full error message
const PricingMatrixCard = ({ rows, cardData }) => {
  const { title, iconName, subtitle, buttonProps } = cardData.heading;
  // @ts-expect-error TS(7031): Binding element 'key' implicitly has an 'any' type... Remove this comment to see the full error message
  const formattedRows = rows.map(({ key, label }, i) => {
    return (
      <div className="PricingMatrixRow PricingMatrixCardRow" key={`${key}-${i}`}>
        <span>{label}</span> <PricingMatrixValue valueData={cardData[key]} />
      </div>
    );
  });

  return (
    <div className={cx('PricingMatrixCard', { isPreferred: cardData.isPreferred })}>
      <header>
        <PricingMatrixHeading
          title={title}
          iconName={iconName}
          subtitle={subtitle}
          buttonProps={buttonProps}
          badge={cardData.badge}
        />
      </header>
      {formattedRows}
    </div>
  );
};
export default PricingMatrixCard;
