import cx from 'classnames';

import { Accordion, Button, Paper } from '@hometap/htco-components';

import './FAQList.scss';

const FAQList = ({
  // @ts-expect-error TS(7031): Binding element 'heading' implicitly has an 'any' ... Remove this comment to see the full error message
  heading,
  // @ts-expect-error TS(7031): Binding element 'faqs' implicitly has an 'any' typ... Remove this comment to see the full error message
  faqs,
  // @ts-expect-error TS(7031): Binding element 'includeSectionTitle' implicitly h... Remove this comment to see the full error message
  includeSectionTitle,
  // @ts-expect-error TS(7031): Binding element 'sectionTitle' implicitly has an '... Remove this comment to see the full error message
  sectionTitle,
  // @ts-expect-error TS(7031): Binding element 'showFaqsPageLink' implicitly has ... Remove this comment to see the full error message
  showFaqsPageLink,
  useArrowTheme = false,
  theme = 'plain',
}) => {
  return (
    <Paper theme={theme}>
      <div
        className={cx('FAQContainer', {
          useArrowTheme: useArrowTheme,
          usePlusTheme: !useArrowTheme,
        })}
      >
        {(heading || sectionTitle) && (
          <h1
            className={cx('FAQHeading', {
              isTiempos: includeSectionTitle && !heading,
            })}
          >
            {heading || sectionTitle}
          </h1>
        )}
        {/* @ts-expect-error TS(7031): Binding element 'faqGroup' implicitly has an 'any'... Remove this comment to see */}
        {faqs.map(({ faqGroup }) => (
          <Accordion
            header={faqGroup.question}
            key={faqGroup.question}
            theme={useArrowTheme ? 'with-arrow' : 'with-plus'}
          >
            <span>{faqGroup.answer}</span>
          </Accordion>
        ))}
        {showFaqsPageLink && (
          <Button href="/faqs" theme="secondary" style={{ marginTop: '50px' }}>
            View All FAQs
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default FAQList;
