import { useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { currency, percent } from 'utils/numbers';

import { HomeValueShare, Icon, Loader, MuiSlider, Paper, useScroll, useWindowSize } from '@hometap/htco-components';
import HomeGraph from 'components/HomeGraph/HomeGraph';

import { formatHomeShares } from 'apps/rundowns/utils/rundownShareUtils';
import { hardcodedAppreciation, hardcodedAppreciationWithFlagEnabled } from 'apps/rundowns/utils/sliderUtils';
import useTrack from 'hooks/useTrack';

import './Rundown.scss';

const RundownInvestmentCalculator = ({
  // @ts-expect-error TS(7031): Binding element 'homeValue' implicitly has an 'any... Remove this comment to see the full error message
  homeValue,
  // @ts-expect-error TS(7031): Binding element 'scenarioCalculation' implicitly h... Remove this comment to see the full error message
  scenarioCalculation,
  // @ts-expect-error TS(7031): Binding element 'isFetchingScenarioCalculation' im... Remove this comment to see the full error message
  isFetchingScenarioCalculation,
  homeShareRatios = [],
  // @ts-expect-error TS(7031): Binding element 'isFetchingHomeShareRatios' implic... Remove this comment to see the full error message
  isFetchingHomeShareRatios,
  // @ts-expect-error TS(7031): Binding element 'isInvestmentAmountSliderEnabled' ... Remove this comment to see the full error message
  isInvestmentAmountSliderEnabled,
  // @ts-expect-error TS(7031): Binding element 'investmentAmountSlider' implicitl... Remove this comment to see the full error message
  investmentAmountSlider,
  // @ts-expect-error TS(7031): Binding element 'investmentAmount' implicitly has ... Remove this comment to see the full error message
  investmentAmount,
  // @ts-expect-error TS(7031): Binding element 'setInvestmentAmount' implicitly h... Remove this comment to see the full error message
  setInvestmentAmount,
  // @ts-expect-error TS(7031): Binding element 'investmentSliderValue' implicitly... Remove this comment to see the full error message
  investmentSliderValue,
  // @ts-expect-error TS(7031): Binding element 'investmentLengthSlider' implicitl... Remove this comment to see the full error message
  investmentLengthSlider,
  // @ts-expect-error TS(7031): Binding element 'investmentLength' implicitly has ... Remove this comment to see the full error message
  investmentLength,
  // @ts-expect-error TS(7031): Binding element 'setInvestmentLength' implicitly h... Remove this comment to see the full error message
  setInvestmentLength,
  // @ts-expect-error TS(7031): Binding element 'investmentTermUnit' implicitly ha... Remove this comment to see the full error message
  investmentTermUnit,
}) => {
  const { isRolloutFlagEnabled } = useTrack();
  const appreciationDefaultFlagEnabled = isRolloutFlagEnabled('appreciation_default');

  const {
    homeowner_share: homeownerShare,
    final_mortgage_balance_share: finalMortgageBalanceShare,
    hometap_share: hometapShare,
    is_share_capped: isShareCapped,
    crr_percent: crrPercent,
    appreciated_home_value: appreciatedHomeValue,
  } = scenarioCalculation;

  const appreciationConfig = appreciationDefaultFlagEnabled
    ? hardcodedAppreciationWithFlagEnabled
    : hardcodedAppreciation;

  const { upShareValues, downShareValues } = formatHomeShares({
    homeShareRatios,
    investmentLength,
    isShareCapped,
    investmentTermUnit,
    // @ts-expect-error TS(2345): Argument of type '{ homeShareRatios: never[]; inve... Remove this comment to see the full error message
    appreciationRate: appreciationConfig.value,
  });

  const [isPinned, setIsPinned] = useState(false);
  const [pinnedAt, setPinnedAt] = useState(-1);

  const containerRef = useRef();
  const { isScreenSize } = useWindowSize();
  const isNotMobile = !isScreenSize('sm');

  // Track scroll position where "current" is that of `containerRef`
  useScroll(
    // @ts-expect-error TS(7031): Binding element 'current' implicitly has an 'any' ... Remove this comment to see the full error message
    ({ current }) => {
      // Pin when the containerRef is at 0
      if (!isPinned && current.y <= 0) {
        // Keep track of the position of the element when pinned
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        setPinnedAt(containerRef.current.getBoundingClientRect().top + document.documentElement.scrollTop);
        setIsPinned(true);
      }
    },
    [isPinned, setIsPinned, containerRef],
    0,
    containerRef,
  );

  // Track scroll position where "current" is that of the body
  useScroll(
    // @ts-expect-error TS(7031): Binding element 'current' implicitly has an 'any' ... Remove this comment to see the full error message
    ({ current }) => {
      // Unpin when the scroll hits the original pinnedAt value
      if (pinnedAt >= current.y) {
        setIsPinned(false);
      }
    },
    [pinnedAt, setIsPinned],
  );

  const appreciationPercent = appreciationDefaultFlagEnabled
    ? percent(appreciationConfig.value, '0.00')
    : percent(appreciationConfig.value, '0');

  const appreciationRateCopy = appreciationDefaultFlagEnabled
    ? `The Projected Home Value and Hometap Share above assume a ${appreciationPercent} annual appreciation rate, based 
    on the Case-Shiller Index 20-year national average. Appreciation rates calculate annual increases in value, 
    while depreciation rates reflect total value decline over time.`
    : `The Projected Home Value and Hometap Share amounts shown above assume a low 
    ${appreciationPercent} annual appreciation for the length of the Investment.`;

  return (
    <Paper theme="plain" className="RundownCalculatorPaper">
      <div className="RundownCalculatorContainer">
        {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
        <div ref={containerRef} className={cx('RundownCalculatorSlidersContainer', isNotMobile && { isPinned })}>
          <div className="RundownCalculatorSlidersContainerInner">
            <div id="InvestmentAmountSlider" className="RundownCalculatorSlider">
              {isInvestmentAmountSliderEnabled ? (
                <MuiSlider
                  title={investmentAmountSlider.title}
                  value={investmentSliderValue}
                  onChange={setInvestmentAmount}
                  min={investmentAmountSlider.min}
                  max={investmentAmountSlider.max}
                  step={investmentAmountSlider.step}
                  breakPoints={investmentAmountSlider.breakpoints}
                  valueRenderer={investmentAmountSlider.valueRenderer}
                  subtitleRenderer={investmentAmountSlider.subtitleRenderer}
                />
              ) : (
                <div className="RundownCalculatorSliderLockedInvestment">
                  <div>
                    <Icon size="lg" name="icon-lock" />
                    <span className="RundownCalculatorSliderLockedInvestmentTitle">Investment Amount</span>
                  </div>
                  <h2 className="RundownCalculatorSliderLockedInvestmentAmount">{currency(investmentAmount)}</h2>
                  <h5 className="RundownCalculatorSliderLockedInvestmentPercent">{`${percent(
                    investmentAmount / homeValue,
                    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                    0,
                  )} of home value`}</h5>
                </div>
              )}
            </div>
            <div id="InvestmentLengthSlider" className="RundownCalculatorSlider">
              <MuiSlider
                title={investmentLengthSlider.title}
                value={investmentLength}
                onChange={setInvestmentLength}
                min={investmentLengthSlider.min}
                max={investmentLengthSlider.max}
                step={investmentLengthSlider.step}
                breakPoints={investmentLengthSlider.breakpoints}
                valueRenderer={investmentLengthSlider.valueRenderer}
              />
            </div>
          </div>
        </div>
        <div className={cx('RundownGraphsContainer', isNotMobile && { isPinned })}>
          {(isFetchingScenarioCalculation || isFetchingHomeShareRatios) && (
            <div className="RundownGraphsLoadingContainer">
              <Loader type="dot-pulse" />
            </div>
          )}
          <div className="RundownHomeValueShareContainer">
            <h3>Share of home value</h3>
            {homeShareRatios && (
              <>
                <p>Hometap’s Share of your home’s value depends on if your home goes up or down in value.</p>
                <HomeValueShare upShareValues={upShareValues} downShareValues={downShareValues} />
              </>
            )}
          </div>
          <div className="RundownHomeGraphContainer">
            {homeownerShare && (
              <HomeGraph
                homeownerShare={{
                  ...homeownerShare,
                  percent: homeownerShare.percent * 100,
                }}
                mortgageBalance={
                  finalMortgageBalanceShare
                    ? {
                        dollars: finalMortgageBalanceShare.dollars,
                        percent: finalMortgageBalanceShare.percent * 100,
                      }
                    : null
                }
                hometapShare={{
                  ...hometapShare,
                  percent: hometapShare.percent * 100,
                  isShareCapped,
                  crrPercent,
                }}
                homeValue={appreciatedHomeValue}
                configOverrides={config => {
                  return { ...config, container: { className: 'max-w-full md:max-w-[776px] flex-1' } };
                }}
              />
            )}
          </div>
        </div>
        <div className="RundownDisclaimerContainer">
          <div>{appreciationRateCopy}</div>
          <div>
            Hometap’s Share has been rounded to one decimal place for presentation purposes. If you accept an Offer, you
            will receive the Investment Disclosure by email, which will contain the final Hometap Share reflected to
            three decimal places and settlement scenarios with the corresponding dollar values, as well as estimates of
            your signing costs and paydowns, if applicable. Please review your Investment Disclosure closely.
          </div>
        </div>
      </div>
    </Paper>
  );
};

RundownInvestmentCalculator.propTypes = {
  homeValue: PropTypes.number.isRequired,
  isFetchingScenarioCalculation: PropTypes.bool.isRequired,

  homeShareRatios: PropTypes.arrayOf(
    PropTypes.shape({
      share_up_fraction: PropTypes.number.isRequired,
      share_down_fraction: PropTypes.number.isRequired,
      range_years_upper: PropTypes.number.isRequired,
    }),
  ),
  isFetchingHomeShareRatios: PropTypes.bool.isRequired,

  isInvestmentAmountSliderEnabled: PropTypes.bool.isRequired,
  investmentAmountSlider: PropTypes.object.isRequired,
  investmentAmount: PropTypes.number.isRequired,
  setInvestmentAmount: PropTypes.func.isRequired,
  investmentSliderValue: PropTypes.number.isRequired,

  investmentLengthSlider: PropTypes.object.isRequired,
  investmentLength: PropTypes.number,
  setInvestmentLength: PropTypes.func.isRequired,
};

export default RundownInvestmentCalculator;
