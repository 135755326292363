import { TestimonialsCarousel, Paper, Banner, Icon } from '@hometap/htco-components';
import { Helmet } from 'react-helmet-async';
import md5 from 'md5';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useCurrentUser from 'hooks/useCurrentUser';
import useSearchParams from 'hooks/useSearchParams';
import InquirySuccessfulEstimateOutcome from './successfulOutcomes/InquirySuccessfulEstimateOutcome';
import InquiryUndeterminedOutcome from './successfulOutcomes/InquiryUndeterminedOutcome';
import InquirySuccessfulCTAOutcome from './successfulOutcomes/InquirySuccessfulCTAOutcome';
import InquiryUndeterminedCTAOutcome from './successfulOutcomes/InquiryUndeterminedCTAOutcome';
import InquiryCTAOutcomeButton from './successfulOutcomes/InquiryCTAOutcomeButton';
import InquiryGetStartedButton from './successfulOutcomes/InquiryGetStartedButton';
import { PREQUAL_OUTCOME_CODES } from 'apps/progressive-inquiry/constants/APIConstants';
import AudacyPixel from 'components/AudacyPixel/AudacyPixel';
import env from 'utils/env';

const InquiryOutcomeSuccessful = ({
  // @ts-expect-error TS(7031): Binding element 'upToAmount' implicitly has an 'an... Remove this comment to see the full error message
  upToAmount,
  // @ts-expect-error TS(7031): Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031): Binding element 'email' implicitly has an 'any' ty... Remove this comment to see the full error message
  email,
  // @ts-expect-error TS(7031): Binding element 'resentMagicLink' implicitly has a... Remove this comment to see the full error message
  resentMagicLink,
  // @ts-expect-error TS(7031): Binding element 'onClickResendMagicLink' implicitl... Remove this comment to see the full error message
  onClickResendMagicLink,
  // @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
  onNext,
  // @ts-expect-error TS(7031): Binding element 'isAdditionalTrack' implicitly has... Remove this comment to see the full error message
  isAdditionalTrack,
  // @ts-expect-error TS(7031): Binding element 'isPartnerOutcome' implicitly has ... Remove this comment to see the full error message
  isPartnerOutcome,
  // @ts-expect-error TS(7031): Binding element 'outcomeCode' implicitly has an 'a... Remove this comment to see the full error message
  outcomeCode,
  // @ts-expect-error TS(7031): Binding element 'inquiryId' implicitly has an 'any... Remove this comment to see the full error message
  inquiryId,
  // @ts-expect-error TS(7031): Binding element 'utmSource' implicitly has an 'any... Remove this comment to see the full error message
  utmSource,
  // @ts-expect-error TS(7031): Binding element 'trackId' implicitly has an 'any' ... Remove this comment to see the full error message
  trackId,
}) => {
  const query = useSearchParams();
  const outcomeKey = query.get('key') ?? outcomeCode;
  const investmentAmount = query.get('investment_amount');
  const undeterminedOutcome = outcomeKey === PREQUAL_OUTCOME_CODES.UNDETERMINED;
  const emailHash = md5(email || 'unknown');
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const isLoggedIn = !!user;
  const flags = useFlags();
  // if it's a partner outcome or they're logged in we want to use the code not flagged with showInquirySignInMethods
  const showInquirySignInMethods = flags.showInquirySignInMethods && !isPartnerOutcome && !isLoggedIn;

  return (
    <div className={cx({ 'InquiryOutcome--WithMarginBottom': !showInquirySignInMethods && !isAdditionalTrack })}>
      <Helmet bodyAttributes={{ class: '' }} title="Estimate | Investment Application | Hometap Hub">
        {/* LiveConnect Event Tag */}
        <script type="text/javascript">
          {`
          window.liQ = window.liQ || [];
          window.liQ.push({
              "event": "conversion",
              "name": "email_signup",
              "emailHash": "${emailHash}"
          });
        `}
        </script>

        {/* tvScientific script */}
        {env.isProd() && inquiryId && utmSource && (
          <script type="text/javascript">
            {/* @ts-expect-error TS(2322): Type 'void' is not assignable to type 'ReactNode'. */}
            {(function () {
              // eslint-disable-next-line
              var p, s, d, w;
              d = document;
              w = window.location;
              p = d.createElement('IMG');
              s =
                // eslint-disable-next-line
                w.protocol +
                '//tvspix.com/t.png?&t=' +
                new Date().getTime() +
                '&l=tvscientific-pix-o-92deb6b2-a1c1-40f1-8dd6-03381eeedad7&u3=' +
                encodeURIComponent(w.href) +
                `&u1=complete_registration&u4={${inquiryId}}&u5={${utmSource}}`;
              p.setAttribute('src', s);
              p.setAttribute('height', '0');
              p.setAttribute('width', '0');
              p.setAttribute('alt', '');
              p.style.setProperty('display', 'none');
              p.style.setProperty('position', 'absolute');
              p.style.setProperty('visibility', 'hidden');
              d.body.appendChild(p);
            })()}
          </script>
        )}
      </Helmet>
      {resentMagicLink && (
        <Banner className="InquiryOutcomeBanner">
          We’ve sent a magic link to {email}. Please note, this link will expire after 24 hours.
        </Banner>
      )}

      <div className="InquiryOutcomeWrapper" data-testid="inquiry_outcome_page">
        <div className="InquiryOutcomeContainer">
          {showInquirySignInMethods &&
            (undeterminedOutcome ? (
              <InquiryUndeterminedOutcome isAdditionalTrack={isAdditionalTrack} />
            ) : (
              <InquirySuccessfulEstimateOutcome
                isAdditionalTrack={isAdditionalTrack}
                upToAmount={upToAmount}
                name={name}
                isPartnerOutcome={isPartnerOutcome}
                investmentAmount={investmentAmount}
              />
            ))}
          {!showInquirySignInMethods &&
            (undeterminedOutcome ? (
              <InquiryUndeterminedCTAOutcome isAdditionalTrack={isAdditionalTrack} />
            ) : (
              <InquirySuccessfulCTAOutcome
                isAdditionalTrack={isAdditionalTrack}
                upToAmount={upToAmount}
                name={name}
                isPartnerOutcome={isPartnerOutcome}
                investmentAmount={investmentAmount}
              />
            ))}
          {!isPartnerOutcome &&
            (showInquirySignInMethods ? (
              <InquiryGetStartedButton onNext={onNext} isAdditionalTrack={isAdditionalTrack} trackId={trackId} />
            ) : (
              <div className="InquiryOutcomeSubTextContainer">
                <InquiryCTAOutcomeButton
                  onClickResendMagicLink={onClickResendMagicLink}
                  isAdditionalTrack={isAdditionalTrack}
                  trackId={trackId}
                />
              </div>
            ))}
          <div className="InquiryOutcomePrequalLegalNote">
            The estimated amount above is nonbinding and subject to Hometap’s review of your application, including a
            third-party valuation of your home.
          </div>
        </div>
      </div>
      <div className="InquiryOutcomeSuccessfulInfo">
        <div className="InquiryOutcomeSuccessfulInfoFact">
          <div>
            <Icon name="icon-money-payment" size="2x" />
          </div>
          <div className="InquiryOutcomeSuccessfulInfoText">
            <strong>No debt or restrictions on how you use the funds,</strong> so you can meet your goals, stress-free
          </div>
        </div>
        <div className="InquiryOutcomeSuccessfulInfoFact">
          <div>
            <Icon name="icon-work-life" size="2x" />
          </div>
          <div className="InquiryOutcomeSuccessfulInfoText">
            <strong>No monthly payments,</strong> pre-payment penalties, or blackout periods — just settle anytime
            during the 10-year term
          </div>
        </div>
        <div className="InquiryOutcomeSuccessfulInfoFact">
          <div>
            <Icon name="icon-investment-manager" size="2x" />
          </div>
          <div className="InquiryOutcomeSuccessfulInfoText">
            <strong>Fast, simple, and transparent process,</strong> with a dedicated Investment Manager to answer your
            questions
          </div>
        </div>
      </div>

      <Paper
        className={cx('InquiryResultsCarouselAndTrustPilot isLightGradientBackground', {
          'InquiryResultsCarouselAndTrustPilot--WithPadding': showInquirySignInMethods,
        })}
        theme="medium"
      >
        <div className="InquiryResultsCarouselContainer">
          <TestimonialsCarousel />
        </div>
      </Paper>

      <AudacyPixel goalName={'formfill'} goalId={'42889'} />
    </div>
  );
};

export default InquiryOutcomeSuccessful;
