import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import cx from 'classnames';

import { SlideSide } from '@hometap/htco-components';
import env from 'utils/env';
import logo from 'images/hometap-logomark.svg';
import { getPortalsUrl } from 'utils/links';
import './Configurator.scss';

// @ts-expect-error TS(7006): Parameter 'pathname' implicitly has an 'any' type.
const matchPathWithOptionalTrailingSlash = (pathname, path) => {
  let match = matchPath(`${path}/`, pathname);
  if (!match) {
    match = matchPath(path, pathname);
  }
  return match;
};

const Configurator = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const match = matchPathWithOptionalTrailingSlash(location.pathname, '/track/:trackId');
  const trackId = match?.params?.trackId ?? null;

  let configuratorUrl = `${getPortalsUrl()}configurator/settings/`;
  if (trackId) {
    configuratorUrl += `track/${trackId}/`;
  }

  useEffect(() => {
    // @ts-expect-error TS(7006): Parameter 'event' implicitly has an 'any' type.
    const handleMessageAction = event => {
      if (event.data.source !== 'configurator') {
        return;
      }
      if (event.data.action === 'redirect') {
        if (event.data.destination === '.') {
          window.location.reload();
        } else {
          window.location = event.data.destination;
        }
      } else if (event.data.action === 'copy') {
        navigator.clipboard.writeText(event.data.content);
      }
    };
    window.addEventListener('message', handleMessageAction);
    return () => window.removeEventListener('message', handleMessageAction);
  }, []);

  if (env.isProd() || env.isStage()) {
    return null;
  }

  const toggle = (
    <button className={cx('ConfiguratorToggle', { ClosedConfiguratorToggle: !open })} onClick={() => setOpen(!open)}>
      <img src={logo} alt="Hometap logo" />
    </button>
  );

  return (
    <>
      {!open && toggle}
      <SlideSide
        className="Configurator"
        maxWidth={430}
        visible={open}
        isOverlay={true}
        displayBackdrop={true}
        direction="right"
      >
        <div className="ConfiguratorLeft">{toggle}</div>
        <div className="ConfiguratorRight">
          <iframe
            className="ConfiguratorIframe"
            title="configurator"
            src={configuratorUrl}
            width="100%"
            style={{ border: 'none' }}
          />
        </div>
      </SlideSide>
    </>
  );
};

export default Configurator;
