import useTrack from 'hooks/useTrack';
import { formatOnlyStreetAddress } from 'utils/address';
import SecondaryLayout from './SecondaryLayout';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const TrackSecondaryLayout = ({ children, prevUrl, prevTrackUrl, disableNativeBack }) => {
  const { track, loading } = useTrack();

  const title = formatOnlyStreetAddress(track?.home.address ?? {});
  const isNestedPage = !!prevTrackUrl;

  return (
    <SecondaryLayout
      disableNativeBack={disableNativeBack}
      isNestedPage={isNestedPage}
      prevUrl={prevTrackUrl ? `/track/${track?.id}${prevTrackUrl}` : prevUrl}
      title={!loading && title}
    >
      {children}
    </SecondaryLayout>
  );
};

export default TrackSecondaryLayout;
