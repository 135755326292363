// @ts-expect-error TS(7006): Parameter 'renovation' implicitly has an 'any' typ... Remove this comment to see the full error message
export const showRenovation = renovation => {
  renovation.hidden = false;
};

// @ts-expect-error TS(7006): Parameter 'renovation' implicitly has an 'any' typ... Remove this comment to see the full error message
export const hideRenovation = renovation => {
  renovation.hidden = true;
};

// @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
export const getVisibleRenovations = scenario => {
  // @ts-expect-error TS(7006): Parameter 'renovation' implicitly has an 'any' typ... Remove this comment to see the full error message
  return scenario?.renovations?.filter(renovation => !renovation.hidden);
};
