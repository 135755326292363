import { useLocation, useNavigate } from 'react-router-dom';
import { SelectionSet, Button } from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { SELECTION_OPTIONS, MAX_LIENS, PROFILE_STAGES } from 'apps/dashboard/constants/buildYourProfile';
import buildProfileLandingImg from 'images/dashboard/build-profile-landing.png';
import PercentageBadge from '../dashboard-shared/PercentageBadge';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';
import BuildProfileFlowLayout from './BuildProfileFlowLayout';

// @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
const BuildProfileLandingPage = ({ onNext, onSetupLater, selections }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = useCurrentHome();
  const homeMutation = useCurrentHomeMutation();
  const { currentScenario, getEquityScenarioLiensAsync, deleteLien } = useEquityScenarioContext();
  const { removeDelayLocalStorageKey } = useDelayHomeEquityProfile();

  // @ts-expect-error TS(2339): Property 'results' does not exist on type '{}'.
  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;

  const onNoDebts = async () => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    removeDelayLocalStorageKey(home?.id);

    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    if (equityScenarioLiens?.length && currentScenario?.id) {
      // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
      await Promise.all(equityScenarioLiens.map(lien => deleteLien(currentScenario.id, lien.id)));
    }

    await Promise.all([
      // @ts-expect-error TS(2345): Argument of type '{ hed_debts_confirmed: boolean; ... Remove this comment to see the full error message
      homeMutation.mutateAsync({ hed_debts_confirmed: true }),
      // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
      getEquityScenarioLiensAsync.execute(home?.id, currentScenario?.id),
    ]);

    navigate(
      getNewDashboardUrl(
        location.state?.referrer === HOMEOWNER_DASHBOARD.OVERVIEW
          ? HOMEOWNER_DASHBOARD.OVERVIEW
          : HOMEOWNER_DASHBOARD.HOME_EQUITY,
      ),
    );
  };

  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  const handleSelect = async value => {
    switch (value) {
      case SELECTION_OPTIONS.USE_EXISTING:
        return onNext(PROFILE_STAGES.DEBT_SELECTION_STEP);
      case SELECTION_OPTIONS.NO_DEBTS:
        return await onNoDebts();
      default:
        return null;
    }
  };

  /*
    - Disable the mortgage loan option one already exists on the equity scenario
    - Disable the button to manually add debts if the equity scenario contains the max number of liens (one of each type)
   */
  // @ts-expect-error TS(7006): Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
  const options = selections?.map(selection => {
    const hasAllLiens = equityScenarioLiens?.length === MAX_LIENS;
    const shouldDisableManualDebt = selection.value === SELECTION_OPTIONS.USE_EXISTING && hasAllLiens;

    if (shouldDisableManualDebt) {
      return { ...selection, disabled: true };
    }
    return selection;
  });

  return (
    <BuildProfileFlowLayout stepImage={buildProfileLandingImg} stepImageAlt="home-equity">
      {/*
       // @ts-expect-error TS2339 */}
      {home?.address && (
        <div className="mb-4 flex flex-wrap justify-between gap-x-2 gap-y-1">
          <div className="text-sm uppercase tracking-[1px] text-neutral-dark-75">
            {/*
             // @ts-expect-error TS2339 */}
            Finish setup for {home.address.street}
          </div>
          <PercentageBadge className="static" />
        </div>
      )}
      <h2>Build your home profile</h2>
      <div className="mb-8 mt-2 text-sm text-neutral-dark-75 sm:mb-10 sm:text-base">
        By capturing all debts attached to your property, we’ll get the most complete picture of your home equity
        possible.
      </div>

      <SelectionSet
        className="[&_label]:mr-0"
        align="left"
        error={null}
        labelWidth="100%"
        name="fieldName"
        onChange={handleSelect}
        options={options}
      />
      <Button className="!mt-3" size="normal" theme="link" onClick={onSetupLater}>
        I’ll do this later
      </Button>
    </BuildProfileFlowLayout>
  );
};

export default BuildProfileLandingPage;
