import { Button, Icon, useAsync, useWindowSize } from '@hometap/htco-components';
import { onSaveError } from 'apps/application/utils';
import useTrack from 'hooks/useTrack';
import { useNavigate, Link } from 'react-router-dom';
import cx from 'classnames';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import './TrackDetailsTodoCard.scss';
import { TASK_DEFINITION_KEY } from '../../../../utils/constants/taskDefinitionKeys';

// @ts-expect-error TS(7031): Binding element 'shouldHideMobile' implicitly has ... Remove this comment to see the full error message
const TimeToCompleteSection = ({ shouldHideMobile, timeToComplete }) => {
  if (!timeToComplete) return null;

  return (
    <small className={cx('TrackDetailsCardTimeToComplete', { shouldHideMobile })}>
      <Icon name="clock" /> &nbsp;
      {timeToComplete}
    </small>
  );
};

const TrackDetailsTodoCard = ({
  // @ts-expect-error TS(7031): Binding element 'todo' implicitly has an 'any' typ... Remove this comment to see the full error message
  todo,
  // @ts-expect-error TS(7031): Binding element 'notes' implicitly has an 'any' ty... Remove this comment to see the full error message
  notes,
  // @ts-expect-error TS(7031): Binding element 'isActive' implicitly has an 'any'... Remove this comment to see the full error message
  isActive,
  // @ts-expect-error TS(7031): Binding element 'showWithoutOtherStages' implicitl... Remove this comment to see the full error message
  showWithoutOtherStages,
  // @ts-expect-error TS(7031): Binding element 'onSetIsTodoModalOpen' implicitly ... Remove this comment to see the full error message
  onSetIsTodoModalOpen,
  // @ts-expect-error TS(7031): Binding element 'setMaritalStatusTodoModalOpen' im... Remove this comment to see the full error message
  setMaritalStatusTodoModalOpen,
}) => {
  const { title, cta = {}, icon, description, timeToComplete } = todo;
  const { onClickAsyncFunc: _, ...ctaButtonProps } = cta;
  const navigate = useNavigate();
  const asyncState = useAsync(cta?.onClickAsyncFunc, { executeThrow: true });
  const { fetch: fetchTrack } = useTrack();
  const { isScreenSize } = useWindowSize({ delay: 1000 });
  const showNotes = todo.task_definition_key === TASK_DEFINITION_KEY.UPLOAD_FILE && notes;
  return (
    <div className={cx('TrackDetailsTodoCard', { isActive, showWithoutOtherStages })}>
      <div className="TrackDetailsTodoCardContentContainer">
        <div className="TrackDetailsTodoCardDescriptionContainer">
          <div className="TrackDetailsTodoCardIconContainer">
            <Icon className="TrackDetailsTodoCardIcon" name={isActive && icon ? icon : 'lock'} />
            {/* @ts-expect-error TS(2741): Property 'shouldHideMobile' is missing in type '{ ... Remove this comment to see the full error message */}
            <TimeToCompleteSection timeToComplete={timeToComplete} />
          </div>
          <div className="TrackDetailsCardDescription">
            <p className="TrackDetailsCardDescriptionTitle">{title}</p>
            <p className="TrackDetailsCardDescriptionSubtitle">{description || todo?.notes || todo?.subtitle}</p>
            {showNotes && (
              <div className="TrackDetailsTodoNoteWrapper">
                <p className="TrackDetailsTodoNoteTitle">Note</p>
                <p className="TrackDetailsTodoNoteSubtitle">{notes}</p>
              </div>
            )}
            <TimeToCompleteSection shouldHideMobile timeToComplete={timeToComplete} />
          </div>
        </div>
        {cta && isActive && (
          <Button
            className="TrackDetailsTodoCardCTA sm_cobrowsing_disabled_field" // This is the class that is being checked in the useGlia.js file
            size={isScreenSize('xs') ? 'small' : 'normal'}
            href={cta.href}
            loading={asyncState.loading}
            onClick={async () => {
              if (cta.href || cta.to) return;

              const isAsyncFunc = !!cta.onClickAsyncFunc;
              if (isAsyncFunc) {
                // pass history to allow for redirect after async function success
                return await asyncState.execute({ navigate, fetchTrack }).catch(onSaveError());
              }

              if (cta.todo_type === 'marital_status') {
                browserTrack.maritalStateTodoViewDetailsClicked({
                  version: getVersion(),
                });
                setMaritalStatusTodoModalOpen(true);
                return;
              }

              return onSetIsTodoModalOpen(todo);
            }}
            {...ctaButtonProps}
          >
            {/* Button component doesn't support SPA routing */}
            {cta.to && <Link className="TrackDetailsTodoCardCTALink" to={cta.to} />}
            {cta.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TrackDetailsTodoCard;
