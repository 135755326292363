import PropTypes from 'prop-types';

import { Grid, Paper } from '@hometap/htco-components';

import RundownCostEstimate from '../RundownCostEstimate/RundownCostEstimate';
import RundownScenarioCard from '../RundownScenarioCard/RundownScenarioCard';

import './RundownScenariosAndCosts.scss';

// @ts-expect-error TS(7031): Binding element 'rundown' implicitly has an 'any' ... Remove this comment to see the full error message
const RundownScenariosAndCosts = ({ rundown, investmentAmount, scenarios = [] }) => {
  const renderCostEstimate = () => {
    return !rundown.costs ? null : (
      <>
        <div className="RundownCostsContainer">
          <RundownCostEstimate costs={rundown.costs} investmentAmount={investmentAmount} />
        </div>
        <p className="RundownCostsFinePrint">
          This estimate is nonbinding and subject to final approval by Hometap according to its current underwriting
          criteria and may be rescinded for any reason, including but not limited to additional information that is
          submitted or obtained as part of Hometap’s review process.
        </p>
      </>
    );
  };

  return (
    <Paper theme="plain" className="RundownScenariosAndCostsContainer">
      <div className="RundownScenariosAndCostsWrapper">
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          {scenarios.map(scenario => {
            return (
              // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
              <Grid key={scenario?.id} className="RundownScenarioCardContainer" xs={12} sm={12} md={4} lg={4}>
                <RundownScenarioCard
                  // @ts-expect-error TS(2322): Type '{ appreciationIndicator: any; appreciationPe... Remove this comment to see the full error message
                  appreciationIndicator={scenario.appreciation_indicator}
                  // @ts-expect-error TS(2339): Property 'appreciation_percent' does not exist on ... Remove this comment to see the full error message
                  appreciationPercent={scenario.appreciation_percent}
                  // @ts-expect-error TS(2339): Property 'description' does not exist on type 'nev... Remove this comment to see the full error message
                  description={scenario.description}
                  // @ts-expect-error TS(2339): Property 'hometap_share' does not exist on type 'n... Remove this comment to see the full error message
                  hometapShare={scenario.hometap_share}
                  // @ts-expect-error TS(2339): Property 'homeowner_share' does not exist on type ... Remove this comment to see the full error message
                  homeownerShare={scenario.homeowner_share}
                  // @ts-expect-error TS(2339): Property 'investment_amount' does not exist on typ... Remove this comment to see the full error message
                  investmentAmount={scenario.investment_amount}
                  // @ts-expect-error TS(2339): Property 'effective_period' does not exist on type... Remove this comment to see the full error message
                  effectivePeriod={scenario.effective_period}
                  // @ts-expect-error TS(2339): Property 'is_share_capped' does not exist on type ... Remove this comment to see the full error message
                  isShareCapped={scenario.is_share_capped}
                  // @ts-expect-error TS(2339): Property 'final_mortgage_balance' does not exist o... Remove this comment to see the full error message
                  mortgageBalance={scenario.final_mortgage_balance}
                  // @ts-expect-error TS(2339): Property 'appreciated_home_value' does not exist o... Remove this comment to see the full error message
                  projectedHomeValue={scenario.appreciated_home_value}
                />
              </Grid>
            );
          })}
        </Grid>

        {renderCostEstimate()}
      </div>
    </Paper>
  );
};

RundownScenariosAndCosts.propTypes = {
  rundown: PropTypes.shape({
    current_mortgage_balance: PropTypes.number,
    original_mortgage_balance: PropTypes.number,
    mortgage_term: PropTypes.number,
    mortgage_rate: PropTypes.number,
    costs: PropTypes.array,
    show_and_use_mortgage_info: PropTypes.bool,
  }).isRequired,
  scenarios: PropTypes.array,
  investmentAmount: PropTypes.number,
};

export default RundownScenariosAndCosts;
