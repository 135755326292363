import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const CardWrapper = ({ children, className, testId }) => {
  return (
    <div
      className={twMerge(cx('rounded-xl bg-white p-4 shadow-[0_2px_14px_rgba(32,53,104,0.08)] xs:p-6', className))}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
