import { toHTML } from '@portabletext/to-html';
import { RESOURCE_CATEGORIES, RESOURCE_TYPES } from 'apps/dashboard/constants/resources';
import { serializers } from '../serializers/sanitySerializers';

const mapSanityResourceTypes = {
  article: RESOURCE_TYPES.ARTICLE,
  tools: RESOURCE_TYPES.TOOLS,
  'promotional-offer': RESOURCE_TYPES.PROMOTIONAL_OFFER,
  resources: RESOURCE_TYPES.RESOURCES,
};

const mapSanityResourceCategories = {
  'financial-goals': RESOURCE_CATEGORIES.FINANCIAL_GOALS,
  'homeowner-tips': RESOURCE_CATEGORIES.HOMEOWNER_TIPS,
  'home-financing': RESOURCE_CATEGORIES.HOME_FINANCING,
  'market-insights': RESOURCE_CATEGORIES.MARKET_INSIGHTS,
  'exclusive-discount': RESOURCE_CATEGORIES.EXCLUSIVE_DISCOUNT,
  calculators: RESOURCE_CATEGORIES.CALCULATORS,
};

// @ts-expect-error TS(7006): Parameter 'htmlString' implicitly has an 'any' typ... Remove this comment to see the full error message
const estimateReadingTime = htmlString => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const textContent = doc.body.textContent ?? '';
  const wordCount = textContent.split(/\s+/).filter(word => word.length > 0).length;
  // 200 words per minute is the average reading speed
  return Math.ceil(wordCount / 200);
};

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export const mapDataToResources = data => {
  if (!data) return null;

  const htmlContent = toHTML(data.content, { components: serializers });
  const readingTime = estimateReadingTime(htmlContent);

  return {
    title: data.title,
    content: htmlContent,
    excerpt: data.excerpt,
    coverImage: data.featuredImage.image.asset.url,
    date: new Date(data.date).toISOString().split('T')[0],
    author: data.author.name,
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    category: mapSanityResourceCategories[data?.category],
    subCategory: '',
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    type: mapSanityResourceTypes[data.type],
    urlSlug: data.slug.current,
    length: readingTime,
  };
};

// Helper function to transform results to HTML and clean sanity data
// @ts-expect-error TS(7006): Parameter 'results' implicitly has an 'any' type.
export const transformResultsToHtml = results => {
  if (!results) return [];
  // @ts-expect-error TS(7006): Parameter 'article' implicitly has an 'any' type.
  return results.map(article => {
    if (article.content) {
      const parsedArticle = mapDataToResources(article);
      return {
        ...parsedArticle,
      };
    }
    return article;
  });
};

// @ts-expect-error TS(7006): Parameter 'articles' implicitly has an 'any' type.
export const filterArticlesBySlug = (articles, slug) => {
  // @ts-expect-error TS(7006): Parameter 'article' implicitly has an 'any' type.
  return articles.filter(article => article.urlSlug === slug);
};

// @ts-expect-error TS(7006): Parameter 'sanityArticles' implicitly has an 'any'... Remove this comment to see the full error message
export const combineArticles = (sanityArticles, hardcodedArticles) => {
  return [...hardcodedArticles, ...sanityArticles];
};
