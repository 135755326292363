import { apiWithAuth } from 'utils/api';

export const fetchTracks = async () => {
  return await apiWithAuth.v1.get(`/tracks/`);
};

export const fetchPendingTracks = async () => {
  return await apiWithAuth.v1.get('/tracks/pending/');
};

export const fetchEquityPlannerHomes = async () => {
  const homes = await apiWithAuth.v1.get('/homes/');
  // @ts-expect-error TS(2339): Property 'filter' does not exist on type 'AxiosRes... Remove this comment to see the full error message
  return homes.filter(home => {
    if (!home?.tracks?.length) {
      return true;
    }
    return false;
  });
};
