import TrackButton from '../../../components/TrackButton/TrackButton';
import '../PartnerInquiry.scss';

// @ts-expect-error TS(7031): Binding element 'header' implicitly has an 'any' t... Remove this comment to see the full error message
const PartnerInquiryUnsuccessful = ({ header, subText, buttonText, buttonHref }) => {
  return (
    <div className="PartnerInquiryUnsuccessfulContainer">
      <h1>{header}</h1>
      <div className="PartnerInquiryUnsuccessfulSubtitle">{subText}</div>
      <div className="PartnerInquiryUnsuccessfulButton">
        {/* @ts-expect-error TS(2741): Property 'eventProperties' is missing in type '{ c... Remove this comment to see the full error message */}
        <TrackButton className="PartnerInquiryUnsuccessfulButton" href={buttonHref}>
          {buttonText}
        </TrackButton>
      </div>
    </div>
  );
};

export default PartnerInquiryUnsuccessful;
