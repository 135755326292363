import { Routes, Route, Navigate } from 'react-router-dom';
import { PlannerOnboard } from '../equity-planner-onboarding';
import { ONBOARD_URL_WITH_PARAMS } from 'apps/dashboard/constants';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import { getLoginUrlWithEquityDashboardNextStep } from 'utils/links';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import { Helmet } from 'react-helmet-async';
import './EquityPlanner.scss';

const EquityPlannerRouter = () => {
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const seoTitle = 'Hometap | Manage your home equity';
  const seoDescription =
    'Access all of the digital tools and resources you need to better manage, monitor, and forecast your home equity in order to maximize the value of your largest financial asset.';

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription}></meta>
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
      </Helmet>
      <Routes>
        {!user && (
          <>
            <Route path="" element={<Navigate to="/equity-planner/onboard" replace />} />
            {/* @ts-expect-error TS(2741): Property 'stepOverride' is missing in type '{}' bu... Remove this comment to see the full error message */}
            <Route path="onboard" element={<PlannerOnboard />} />
            {/* @ts-expect-error TS(2741): Property 'stepOverride' is missing in type '{}' bu... Remove this comment to see the full error message */}
            <Route path="onboard/welcome" element={<PlannerOnboard />} />
            <Route path="onboard/address" element={<PlannerOnboard stepOverride={1} />} />
            <Route path="share" element={<Navigate to={ONBOARD_URL_WITH_PARAMS} replace />} />
          </>
        )}
        <Route
          path="*"
          element={
            <PrivateRoute redirect={getLoginUrlWithEquityDashboardNextStep()}>
              <Navigate to={`/${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`} replace />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default EquityPlannerRouter;
