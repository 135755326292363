import React from 'react';
import { USER_ROLES } from 'utils/globalConstants';
import { STAGES } from 'apps/dashboard/constants';
import StackedEquityGraphController from '../overview-controller/components/StackedEquityGraphCard/StackedEquityGraphController';
import OverviewTrackDetailsCard from '../overview-controller/components/OverviewTrackDetailsCard';

import { TRACK_STAGE_TO_UI_STAGE } from 'apps/track-details/utils/constants';

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const DynamicContentController = ({ track, homeValuation }) => {
  const isPrimaryApplicant = track?.membership.role === USER_ROLES.PRIMARY;
  const isCoApplicant = track?.membership.role === USER_ROLES.CO_APPLICANT;

  const isTrackInvestedSettledClosed = [STAGES.INVESTED, STAGES.SETTLED, STAGES.CLOSED_LOST].includes(
    track?.current_stage,
  );
  const isTrackInInvestmentFunnel =
    Object.keys(TRACK_STAGE_TO_UI_STAGE).includes(track?.current_stage) && !isTrackInvestedSettledClosed;

  const isPrimaryInStage = isPrimaryApplicant && isTrackInInvestmentFunnel;
  const isCoAppInStage = isCoApplicant && (isTrackInInvestmentFunnel || isTrackInvestedSettledClosed);

  const DYNAMIC_CONTENT = {
    INVESTMENT_CARD: 'investment-card',
    BAR_GRAPH: 'bar-graph',
  };

  // TODO: As we combine multiple components, replace conditional components with pre-defined templates of components
  const dynamicContent = () => ({
    [DYNAMIC_CONTENT.INVESTMENT_CARD]: {
      key: DYNAMIC_CONTENT.INVESTMENT_CARD,
      component: <OverviewTrackDetailsCard track={track} homeValuation={homeValuation} />,
      visible: isPrimaryInStage || isCoAppInStage,
    },
    [DYNAMIC_CONTENT.BAR_GRAPH]: {
      key: DYNAMIC_CONTENT.BAR_GRAPH,
      component: <StackedEquityGraphController track={track} homeValuation={homeValuation} />,
      visible: !isCoAppInStage && (isTrackInvestedSettledClosed || !track),
    },
  });

  // Filter the pieces of dynamic content that should be visible
  let content = Object.values(dynamicContent()).filter(({ visible }) => !!visible);

  if (content.length === 0) {
    content = [dynamicContent()[DYNAMIC_CONTENT.BAR_GRAPH]];
  }

  return content.map(content => <React.Fragment key={content.key}>{content.component}</React.Fragment>);
};
export default DynamicContentController;
