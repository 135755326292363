import { Modal } from '@hometap/htco-components';
import { APPLICANT_FORM_FIELD } from 'apps/application/constants/formFields';

// @ts-expect-error TS(7031): Binding element 'isOpen' implicitly has an 'any' t... Remove this comment to see the full error message
const ApplicantInProgressWarningModal = ({ isOpen, onClose, applicant }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      {/* @ts-expect-error TS(7006): Parameter 'ariaProps' implicitly has an 'any' type... Remove this comment to see the */}
      {ariaProps => {
        const warningHeaderBase = applicant[APPLICANT_FORM_FIELD.firstName]
          ? `${applicant.first_name}'s`
          : 'You still have an applicant whose';

        return (
          <>
            <b id={ariaProps.label}>{warningHeaderBase} application is still in progress.</b>
            <div id={ariaProps.description}>
              Please make sure to finish filling out the application for{' '}
              {applicant?.[APPLICANT_FORM_FIELD.firstName] || 'the in progress applicant.'}
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default ApplicantInProgressWarningModal;
