import { useEffect } from 'react';
import { scrollTop } from 'utils/domScripts';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ScrollToTop = ({ children }) => {
  useEffect(() => {
    scrollTop();
  }, []);

  return children;
};

export default ScrollToTop;
