import houseHei from 'images/finance/house-hei.png';

import houseHeloc from 'images/finance/house-heloc.png';
import houseHel from 'images/finance/house-hel.png';

export const FINANCE_QUALIFICATION_CARD_TYPE = {
  FIXED: 'fixed',
  PERCENT: 'percent',
};

export const FINANCE_CALCULATOR_TYPE = {
  HEI: 'HEI',
  HELOC: 'HELOC',
  HEL: 'HEL',
};

export const FINANCE_OPTIONS = [
  {
    label: 'Home Equity Investment',
    value: FINANCE_CALCULATOR_TYPE.HEI,
    calculatorCardFooterCopy: "Share amount is a percent of the home's value at time of settlement.",
  },
  {
    label: 'Home equity line of credit (HELOC)',
    value: FINANCE_CALCULATOR_TYPE.HELOC,
    calculatorCardFooterCopy: 'Assumes good credit standing and using the national average as of 10/1/2023',
  },
  {
    label: 'Home equity loan',
    value: FINANCE_CALCULATOR_TYPE.HEL,
    calculatorCardFooterCopy: 'Assumes good credit standing and using the national average as of 10/1/2023',
  },
];

export const FINANCE_OPTIONS_OVERVIEW = {
  HEI: {
    title: 'Home Equity Investment',
    description:
      'HEIs allow you to access the equity you’ve built in your home without a loan. You receive cash in exchange for a share of your home’s future value.',
    primarySection: {
      bulletPoints: [
        {
          copy: 'You receive the cash in a lump sum',
        },
        {
          copy: 'There is no debt, no monthly payments, and no restrictions on how the funds are used for the life of the Investment',
        },
        {
          copy: 'You typically need at least 25% equity in your home to qualify',
        },
        {
          copy: 'Because it’s an investment and not a loan, you have until the end of the established timeline to settle via refinance, buyout with savings, or home sale',
        },
      ],
      whoQualifies: [
        {
          title: 'Typical Credit Score',
          value: '600+',
          type: FINANCE_QUALIFICATION_CARD_TYPE.FIXED,
        },
        {
          title: 'Average LTV MAx',
          value: '75%',
          type: FINANCE_QUALIFICATION_CARD_TYPE.PERCENT,
        },
      ],
    },
    otherSections: [
      {
        title: 'Is a Home Equity Investment a fit for me?',
        description: 'A home equity investment is often a good fit if you:',
        bulletPoints: [
          {
            copy: 'Need cash relatively quickly. For example, many homeowners use the cash from a home equity investment to make critical repairs or renovations.',
          },
          {
            copy: 'Don’t want or can’t afford monthly payments',
          },
          {
            copy: 'Are looking to fund a major renovation, as you may be able to qualify for up to 75% of your home’s value',
          },
        ],
      },
      {
        title: 'When is a home equity investment not a fit for me? ',
        description: 'A home equity investment might not be a good fit if you:',
        bulletPoints: [
          {
            copy: 'Don’t have enough equity in your home to qualify',
          },
        ],
      },
    ],
    coverImg: houseHei,
    tapToInsights:
      'For many homeowners, a home equity investment can be a smart way to fund life expenses without taking on debt.',
  },
  HELOC: {
    title: 'Home equity line of credit (HELOC)',
    description:
      'A HELOC is a line of credit borrowed against the equity you have in your home — allowing you to borrow, spend, and repay as you go.',
    primarySection: {
      bulletPoints: [
        {
          copy: 'As a line of credit, a HELOC gives you the flexibility to access cash as often and as much as you need it, up to a maximum amount',
        },
        {
          copy: 'Interest rates are typically variable',
        },
        {
          copy: 'You pull money out during a designated draw period, during which you’ll typically make lower payments, and pay back the balance during a subsequent repayment period',
        },
      ],
      whoQualifies: [
        {
          title: 'Typical Credit Score',
          value: '620 min.',
          type: FINANCE_QUALIFICATION_CARD_TYPE.FIXED,
        },
        {
          title: 'Average LTV Max',
          valueToDisplay: '80-85%',
          value: '82.5%',
          type: FINANCE_QUALIFICATION_CARD_TYPE.PERCENT,
        },
        {
          title: 'Average DTI',
          value: '43%',
          type: FINANCE_QUALIFICATION_CARD_TYPE.PERCENT,
        },
      ],
    },
    otherSections: [
      {
        title: 'Is a HELOC a fit for me?',
        description: 'A home equity line of credit is often a good fit if you:',

        bulletPoints: [
          {
            copy: 'Want or need the ability to access cash on an ongoing basis for recurring expenses',
          },
          {
            copy: 'Aren’t sure of the exact amount of money you’ll need for your project',
          },
        ],
      },
      {
        title: 'When is a HELOC not a fit for me?',
        description: 'A home equity line of credit might not be a good fit if you:',
        bulletPoints: [
          {
            copy: 'Aren’t comfortable with the unpredictability of monthly payments due to variable interest rates',
          },
          {
            copy: 'Need a lump sum of cash for a one-time expense',
          },
        ],
      },
    ],
    coverImg: houseHeloc,
    href: 'https://www.lendingtree.com/home/home-equity/heloc/what-is-a-home-equity-line-credit/',
    tapToInsights:
      'If you use funds from a HELOC to improve your home, the interest may be tax-deductible — up to a designated threshold. Individual situations differ, so consult your own finance, tax or legal professional to determine what makes sense for you.',
  },
  HEL: {
    title: 'Home equity loan',
    description:
      'Home equity loans allow you to borrow against the equity you’ve accrued, using your home as collateral to guarantee the loan.',
    primarySection: {
      bulletPoints: [
        {
          copy: 'You receive the cash in a lump sum',
        },
        {
          copy: 'The interest rate is typically fixed',
        },
        {
          copy: 'The loan doesn’t replace your original mortgage, but is a second mortgage',
        },
        {
          copy: 'The loan is paid back via monthly payments',
        },
      ],
      whoQualifies: [
        {
          title: 'Typical Credit Score',
          value: '620 min.',
          type: FINANCE_QUALIFICATION_CARD_TYPE.FIXED,
        },
        {
          title: 'Average LTV Max',
          valueToDisplay: '80-85%',
          value: '82.5%',
          type: FINANCE_QUALIFICATION_CARD_TYPE.PERCENT,
        },
        {
          title: 'Average DTI',
          value: '43%',
          type: FINANCE_QUALIFICATION_CARD_TYPE.PERCENT,
        },
      ],
    },
    otherSections: [
      {
        title: 'Is a home equity loan a fit for me?',
        description: 'A home equity loan is often a good fit if you:',
        bulletPoints: [
          {
            copy: 'Need a lump sum of cash for one-time expenses, like home renovations',
          },
          {
            copy: 'Have built up a significant amount of equity in your home',
          },
          {
            copy: 'Want to access your equity while keeping your low-interest mortgage rate',
          },
        ],
      },
      {
        title: 'When is a home equity loan not a fit?',
        description: 'A home equity loan might not be a good fit if you:',
        bulletPoints: [
          {
            copy: 'Don’t want to or can’t afford to take on additional monthly payments beyond your original mortgage',
          },
          {
            copy: 'Aren’t prepared to handle the often lengthy and stringent application and approval process',
          },
        ],
      },
    ],
    coverImg: houseHel,
    href: 'https://www.lendingtree.com/home/home-equity/home-equity-loan-requirements/',
    tapToInsights:
      'While home equity loans have monthly payments attached, the typically-fixed interest rate can make budgeting a bit easier than with other options.',
  },
};
