import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { isEmpty } from 'lodash';

import { Button, Grid, MuiSelect, MuiTextInput, useWindowSize } from '@hometap/htco-components';
import { PREQUAL_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';
import 'apps/progressive-inquiry/components/InquiryProperty.scss';
import {
  CallToAction,
  ContentPanel,
  ImageContentPanel,
  ProgressiveUITemplate,
} from 'apps/progressive-inquiry/components/ProgressiveUITemplate';
import {
  ADDRESS_ERROR_MESSAGES,
  ADDRESS_FORMAT_REGEX,
  CONTINUE_BUTTON_ID,
  DQ_PAGES,
  PAGE_TITLES,
  PREQUAL_OUTCOME,
  PROGRESSIVE_STEP_NAMES,
  scrollIntoViewByElementId,
} from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import useGooglePlaceInput from 'hooks/useGooglePlaceInput';
import useStates from 'hooks/useStates';
import houseImg from 'images/inquiry/house2.png';
import { parsePrequalificationsToMap } from 'apps/progressive-inquiry/utils/parsePrequalificationOutcomes';
import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import useReinquiryDataHandleForm from 'apps/progressive-inquiry/hooks/useReinquiryDataHandleForm';
import { useAsyncCreateInquiry } from '../hooks/inquiryMutations';
import useCurrentUser from 'hooks/useCurrentUser';

const InquiryPropertyAddressVariation = ({
  // @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
  form,
  // @ts-expect-error TS(7031): Binding element 'trackingData' implicitly has an '... Remove this comment to see the full error message
  trackingData,
  // @ts-expect-error TS(7031): Binding element 'autoFillValidAddress' implicitly ... Remove this comment to see the full error message
  autoFillValidAddress,
  // @ts-expect-error TS(7031): Binding element 'step' implicitly has an 'any' typ... Remove this comment to see the full error message
  step,
  // @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
  onNext,
  // @ts-expect-error TS(7031): Binding element 'onDequalify' implicitly has an 'a... Remove this comment to see the full error message
  onDequalify,
  // @ts-expect-error TS(7031): Binding element 'handleAutoFillValidAddress' impli... Remove this comment to see the full error message
  handleAutoFillValidAddress,
}) => {
  const { registerField, handleSubmit, updateFormData, formData } = form;

  const { isScreenSize } = useWindowSize();

  const onAddress = useCallback(
    // @ts-expect-error TS(7006): Parameter 'address' implicitly has an 'any' type.
    (address, addressObj) => {
      updateFormData({ address, ...addressObj });
      Object.keys(addressObj).forEach(field => form?.setErrors(field, null));
    },
    [form, updateFormData],
  );

  const { statesForDropdown } = useStates();
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
  const { ref, isValidGoogleAddress } = useGooglePlaceInput({ onAddress, flags: ['inquiryAddressUx'] });

  const [touchedFields, setTouchedFields] = useState({
    street: false,
    unit: false,
    city: false,
    state: false,
    zip_code: false,
  });

  const {
    results: inquiryResults,
    loading,
    execute: postInquiry,
    // @ts-expect-error TS(2345): Argument of type '{ defaultSentryErrorMessage: str... Remove this comment to see the full error message
  } = useAsyncCreateInquiry({ defaultSentryErrorMessage: 'Failed to update inquiry address' });

  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const { reinquiryData } = useInitialInquiryData(user);

  useReinquiryDataHandleForm(reinquiryData, handleAutoFillValidAddress);

  const onSubmit = useCallback(async () => {
    const { city, state, street, zip_code, unit } = formData;
    const data = {
      address: { street, city, state, zip_code, unit },
    };

    await handleSubmit(() => {
      return postInquiry({ ...data, ...trackingData }, [PREQUAL_TYPES.ZIP, PREQUAL_TYPES.STATE]);
    })();
  }, [handleSubmit, postInquiry, trackingData, formData]);

  useEffect(() => {
    if (inquiryResults) {
      const { prequalifications, id } = inquiryResults;
      const prequalMap = parsePrequalificationsToMap(prequalifications);
      // @ts-expect-error TS(2339): Property 'StatePrequalification' does not exist on... Remove this comment to see the full error message
      const { StatePrequalification, ZipPrequalification } = prequalMap?.prequalifications || {};
      // @ts-expect-error TS(7006): Parameter 'outcome_code' implicitly has an 'any' t... Remove this comment to see the full error message
      const checkFailedPrequal = outcome_code => outcome_code === PREQUAL_OUTCOME.FAIL;

      if (
        checkFailedPrequal(StatePrequalification.outcome_code) ||
        checkFailedPrequal(ZipPrequalification.outcome_code)
      ) {
        onDequalify(DQ_PAGES.LOCATION);
      } else {
        onNext(step + 1, id);
      }
    }
  }, [inquiryResults, onNext, step, onDequalify, onSubmit, formData.address]);

  useEffect(() => {
    const isAddressValid = isValidGoogleAddress || autoFillValidAddress;
    const canContinueToTheNextPage = formData.address && isAddressValid;
    if (canContinueToTheNextPage) {
      scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
    }
  }, [formData?.address, isValidGoogleAddress, autoFillValidAddress]);

  const validateStreet = () => {
    if (!formData.street?.trim()) {
      return ADDRESS_ERROR_MESSAGES.street.empty;
    }
    return null;
  };

  const validateCity = () => {
    const isValidAddressComponent = ADDRESS_FORMAT_REGEX.test(formData.city);
    if (!formData.city?.trim()) {
      return ADDRESS_ERROR_MESSAGES.city.empty;
    } else if (!isValidAddressComponent) {
      return ADDRESS_ERROR_MESSAGES.city.invalid;
    }
    return null;
  };

  const validateState = () => {
    if (!formData.state) {
      return ADDRESS_ERROR_MESSAGES.state.empty;
    }
    return null;
  };

  const validateZipCode = () => {
    if (!formData.zip_code?.trim()) {
      return ADDRESS_ERROR_MESSAGES.zip_code.empty;
    } else if (formData.zip_code.length !== 5) {
      return ADDRESS_ERROR_MESSAGES.zip_code.invalid;
    }
    return null;
  };

  const buttonOnSubmit = () => {
    const customValidators = {
      street: validateStreet,
      city: validateCity,
      state: validateState,
      zip_code: validateZipCode,
    };

    const validationErrors = {};

    Object.keys(customValidators).forEach(field => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const fieldError = customValidators[field]();
      if (fieldError) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        validationErrors[field] = fieldError;
      }
    });

    const isValid = isEmpty(validationErrors);
    if (isValid) {
      onSubmit();
    } else {
      Object.keys(validationErrors).forEach(field => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        form?.setErrors(field, validationErrors[field], true);
      });
    }
  };

  const streetField = registerField('street');
  const unitField = registerField('unit');
  const cityField = registerField('city');
  const stateField = registerField('state');
  const zipField = registerField('zip_code');

  return (
    <div className="Property PropertyAddress" data-testid="inquiry_property_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.PROPERTY]} />
      <ProgressiveUITemplate>
        {/* @ts-expect-error TS(2739): Type '{ children: Element[]; }' is missing the fol... Remove this comment to see the full error message */}
        <ContentPanel>
          <h1>Is your property eligible for a Hometap Investment?</h1>
          <p className="PropertyBodyText InquiryBodyText">
            You can prequalify for a Hometap investment in{' '}
            <span className="InquiryEmphasisBold">less than two minutes.</span> First, we need your address.
          </p>
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} sm={!isScreenSize('md') ? 8 : 12}>
              <MuiTextInput
                name="street"
                label="Street
                address"
                maxLength={50}
                required
                width="100%"
                ref={ref}
                data-testid="property-address-input"
                {...streetField}
                validator={() => touchedFields.street && validateStreet()}
                // @ts-expect-error TS(7019): Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
                onChange={(...args) => {
                  streetField.onChange(...args);
                  setTouchedFields({ ...touchedFields, street: true });
                }}
                showRequiredAsterisk={false}
              />
            </Grid>
            <Grid xs={12} sm={!isScreenSize('md') ? 4 : 12}>
              <MuiTextInput
                name="unit"
                label="Unit (optional)"
                maxLength={50}
                width="100%"
                data-testid="property-unit-input"
                {...unitField}
              />
            </Grid>
            <Grid xs={12} sm={12}>
              <MuiTextInput
                name="city"
                label="City"
                maxLength={50}
                required
                width="100%"
                data-testid="property-city-input"
                {...cityField}
                validator={() => touchedFields.city && validateCity()}
                // @ts-expect-error TS(7019): Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
                onChange={(...args) => {
                  cityField.onChange(...args);
                  setTouchedFields({ ...touchedFields, city: true });
                }}
                showRequiredAsterisk={false}
              />
            </Grid>
            <Grid xs={6} sm={!isScreenSize('md') ? 8 : 6} className="InquiryAddressModalState">
              <MuiSelect
                name="state"
                label="State"
                required
                options={statesForDropdown}
                className="property-state-input"
                {...stateField}
                validator={() => touchedFields.state && validateState()}
                // @ts-expect-error TS(7019): Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
                onChange={(...args) => {
                  stateField.onChange(...args);
                  setTouchedFields({ ...touchedFields, state: true });
                }}
                width="100%"
                showRequiredAsterisk={false}
              />
            </Grid>
            <Grid xs={6} sm={!isScreenSize('md') ? 4 : 6} className="InquiryAddressModalZip">
              <MuiTextInput
                name="zip_code"
                label="ZIP code"
                type="number"
                inputMode="number"
                maxLength={5}
                required
                mask="00000"
                validator={() => touchedFields.zip_code && validateZipCode()}
                data-testid="property-zip-input"
                {...zipField}
                // @ts-expect-error TS(7019): Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
                onChange={(...args) => {
                  zipField.onChange(...args);
                  setTouchedFields({ ...touchedFields, zip_code: true });
                }}
                width="100%"
                showRequiredAsterisk={false}
              />
            </Grid>
          </Grid>
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} onClick={buttonOnSubmit} loading={loading}>
              Continue
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel imageSource={houseImg} imgAlt="car parked in driveway" includeTrustPilot={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryPropertyAddressVariation;
