// @ts-expect-error TS(7031): Binding element 'disclaimer' implicitly has an 'an... Remove this comment to see the full error message
const HomeValueDisclaimer = ({ disclaimer }) => {
  return (
    <p className="m-0 text-xs text-neutral-dark-75" data-testid="home-value-disclaimer">
      {disclaimer}
    </p>
  );
};

export default HomeValueDisclaimer;
