import cx from 'classnames';

import { Icon } from '@hometap/htco-components';
import './ErrorMessage.scss';

// @ts-expect-error TS(7031): Binding element 'fullWidth' implicitly has an 'any... Remove this comment to see the full error message
const ErrorMessage = ({ fullWidth, className, children }) => {
  return (
    <div className={cx('ErrorMessage', className, { ErrorMessageFullWith: fullWidth })}>
      <Icon name="error" />
      {children}
    </div>
  );
};

export default ErrorMessage;
