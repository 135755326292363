import TrackButton from '../../../../../../components/TrackButton/TrackButton';
import { USE_CASE_TEXT } from '../../../constants/useCases';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  Container,
  EmailInput,
  FormError,
  PhoneInput,
  TextInput,
  useErrorScroll,
  useForm,
} from '@hometap/htco-components';

import './QuizPersonalForm.scss';

// @ts-expect-error TS(7031): Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
const QuizBasicForm = ({ onSubmit, useCase }) => {
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const { handleSubmit, registerField, formSubmitError } = useForm({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
  });
  useErrorScroll(formSubmitError);

  return (
    <div className="QuizPersonalForm">
      <FormError standalone error={formSubmitError} />
      <h1>You’re so close! </h1>
      <div className="QuizPersonalFormSubText">
        Fill in a few more details to receive your personalized results and find out how a Hometap Investment can help
        you <span>{USE_CASE_TEXT[useCase].infoText}</span>.
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container row style={{ padding: 0 }}>
          <Container grid="6" style={{ padding: 0, paddingRight: 5 }}>
            <TextInput
              disabled={!!user?.first_name}
              required
              label="First Name"
              placeholder="First"
              maxLength={30}
              {...registerField('first_name')}
            />
          </Container>
          <Container grid="6" style={{ padding: 0, paddingLeft: 5 }}>
            <TextInput
              disabled={!!user?.last_name}
              required
              label="Last Name"
              placeholder="Last"
              maxLength={30}
              {...registerField('last_name')}
            />
          </Container>
        </Container>
        <EmailInput
          disabled={!!user?.email}
          required
          label="Email Address"
          placeholder="your@email.com"
          {...registerField('email')}
        />
        <PhoneInput required label="Phone Number" placeholder="e.g. (###) ###-####" {...registerField('phone')} />
        <br />
        <br />
        <div className="QuizPersonalFormSmalltext">
          By submitting this information, I agree that Hometap may send me automated texts regarding my response and
          that data rates may apply.{' '}
        </div>

        <TrackButton eventProperties={{ destination: '/fit-quiz' }} type="submit">
          View Results
        </TrackButton>
      </form>
    </div>
  );
};

export default QuizBasicForm;
