import { useEffect } from 'react';
import cx from 'classnames';

import { Button, MuiTextInput, useAsync, SlideDown } from '@hometap/htco-components';
import { OnboardingLayout, CallToAction, Panel } from '../template/OnboardingTemplate';
import OnboardingPanelCard from './OnboardingPanelCard';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PhoneNumberField from 'components/PhoneNumber/PhoneNumberField';
import useLogAsyncError from 'hooks/useLogAsyncError';
import annualAppreciation from 'images/equity-planner/onboarding/annual-appreciation.png';
import { formatFullAddress } from 'utils/address';
import { getLoginUrlWithEquityDashboardNextStep } from 'utils/links';
import { browserIdentify, browserTrack } from 'utils/segment';
import { buildAccountCreateRequestData, buildSegmentData, createAccount } from '../../../data/createAccount';
import EmailInputWithValidation from 'components/EmailInputWithValidation/EmailInputWithValidation';
import { formatName } from 'utils/stringFormatters';
import './OnboardingHomeownerInfo.scss';
import { formatPhoneNumber } from '../../../../../utils/constants/phoneNumberUtils';

// @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
const OnboardingHomeownerInfo = ({ form, onPrev, onNext }) => {
  const { formData, isFormValid, registerField, tracking_data, handleFieldChange } = form;
  const { first_name, last_name, email, street, unit, city, state, zip_code } = formData;
  const { error, loading, results, execute: createNewAccount } = useAsync(createAccount);

  const emailError = error?.response?.data?.email;
  const shouldLog = emailError && emailError[0] === 'email_in_use';
  useLogAsyncError('Account creation failed', !shouldLog);

  // PAT-376: We don't want to use useEffect for results/error handling due to potential bugs
  // This refactor ticket will remove useAsync and useEffect for this use case.
  useEffect(() => {
    if (results && !error) {
      // send account created segment event
      const segmentData = buildSegmentData({ ...formData, tracking_data });
      browserIdentify(formData.email, {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
      });
      browserTrack.equityDashboardAccountCreatedBrowser(segmentData);
      onNext();
    }
  }, [results, onNext, error, formData, tracking_data]);

  // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
  const onAccountCreate = async data => {
    if (data) {
      // create account
      const userData = buildAccountCreateRequestData({
        ...formData,
        phone_number: formatPhoneNumber(formData.phone_number),
        tracking_data,
      });
      await createNewAccount(userData);
    }
  };

  const enableNext = first_name && last_name && email;

  // @ts-expect-error TS(7006): Parameter 'property' implicitly has an 'any' type.
  const inputClasses = property => cx({ OnboardingInputWithValue: formData[property] });
  return (
    <div className="OnboardingHomeownerInfo" data-testid="signup-page">
      <OnboardingLayout>
        {/* @ts-expect-error TS(2739): Type '{ children: Element[]; }' is missing the fol... Remove this comment to see the full error message */}
        <Panel>
          <h1>Now, tell us a bit about yourself</h1>
          <div className="OnboardingHomeownerInfoPanelBodyText" data-testid="subtitle">
            We just need a little more information to set up your account.
          </div>
          <div className="OnboardingHomeownerInfoFirstLastName">
            <MuiTextInput
              tabIndex="0"
              className={inputClasses('first_name')}
              name="first_name"
              label="First name"
              data-testid="first-name"
              maxLength={30}
              width="100%"
              required
              {...registerField('first_name')}
              // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
              onChange={(val, name, error) => handleFieldChange(formatName(val), 'first_name', error)}
            />
            <MuiTextInput
              className={inputClasses('last_name')}
              name="last_name"
              label="Last name"
              data-testid="last-name"
              maxLength={30}
              width="100%"
              required
              {...registerField('last_name')}
              // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
              onChange={(val, name, error) => handleFieldChange(formatName(val), 'last_name', error)}
            />
          </div>
          <EmailInputWithValidation
            className={inputClasses('email')}
            name="email"
            label="Email address"
            data-testid="email"
            width="100%"
            required
            handleFieldChange={handleFieldChange}
            {...registerField('email')}
          />
          <PhoneNumberField
            className={inputClasses('phone_number')}
            name="phone_number"
            data-testid="phone-number"
            label="Mobile number"
            width="100%"
            {...registerField('phone_number')}
          />
          <div className="OnboardingWhyWeNeedContent">
            Don’t miss out on important updates, exclusive offers, and real-time reminders for managing your home. By
            submitting your phone number, you agree to receive automated texts from Hometap. Data rates may apply.
          </div>
          <div className="OnboardingSignupError">
            <SlideDown visible={!!error}>
              {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
              <ErrorMessage>
                {error?.response?.data?.email?.[0] === 'email_in_use' ? (
                  <span>
                    Looks like that email is already in use.{' '}
                    <a href={getLoginUrlWithEquityDashboardNextStep()}>Log in here.</a>
                  </span>
                ) : (
                  <span>We are unable to reach the server, please try again.</span>
                )}
              </ErrorMessage>
            </SlideDown>
          </div>
          <CallToAction>
            <div className="OnboardingLinkButton">
              <Button theme="link" onClick={onPrev} data-testid="back-button">
                Back
              </Button>
            </div>
            <Button
              id="OnboardingHomeownerInfoNext"
              onClick={onAccountCreate}
              disabled={!enableNext || !isFormValid || loading}
              data-testid="next-button"
              loading={loading}
            >
              Next
            </Button>
          </CallToAction>
        </Panel>
        {/* @ts-expect-error TS(2739): Type '{ children: Element; containsVisual: true; }... Remove this comment to see the full error message */}
        <Panel containsVisual>
          {/* @ts-expect-error TS(2741): Property 'dataTestId' is missing in type '{ header... Remove this comment to see the full error message */}
          <OnboardingPanelCard
            headerText="Create and compare scenarios that visualize potential growth in your equity over time."
            footerText={formatFullAddress(street, unit, city, state, zip_code)}
            imgSrc={annualAppreciation}
            imgAlt="annual appreciation scenarios"
            data-testid="panel-image"
          />
        </Panel>
      </OnboardingLayout>
    </div>
  );
};

export default OnboardingHomeownerInfo;
