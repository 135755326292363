import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAsync } from '@hometap/htco-components';
import { useHomeEquityQueryParamData } from 'hooks/useQueryParamData';
import { getDashboardUrl, getNewDashboardUrl, getNewHEDUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import AddFinancingOption from './AddFinancingOption';
import Debt from '../Debt';
import { PROFILE_INSTANCES, FINANCE_GOALS_FIELDS, FINANCE_OPTION_STAGES } from '../../../constants/buildYourProfile';
import useTrackingData from '../../../hooks/useTrackingData';
import { getEquityScenarioLiens } from '../../../data';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import useDebtForm from 'apps/dashboard/hooks/useDebtForm';
import ProgressiveFormSlides from '../../ProgressiveFormSlides';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ModalPageLayoutLite from 'components/Layouts/ModalPageLayoutLite/ModalPageLayoutLite';
import RouterPrompt from 'apps/progressive-inquiry/components/RouterPrompt';

// @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
const getFormsToRender = ({ form, updateStages, onBack, onNext, initialEditConfig }) => [
  {
    // @ts-expect-error TS(7006): Parameter 'step' implicitly has an 'any' type.
    component: <AddFinancingOption form={form} onNext={step => updateStages(step)} />,
  },
  {
    component: (
      <Debt
        onNext={onNext}
        onBack={onBack()}
        form={form}
        instance={PROFILE_INSTANCES.EDIT_PROFILE}
        // @ts-expect-error TS(2322): Type '{ onNext: any; onBack: any; form: any; insta... Remove this comment to see the full error message
        singleLien={initialEditConfig?.lienType}
        onConfirm={() => {
          return;
        }}
      />
    ),
  },
];

const FinanceGoalsController = ({ initialEditConfig = {} }) => {
  const navigate = useNavigate();
  const trackingData = useTrackingData();

  // @ts-expect-error TS(2339): Property 'step' does not exist on type '{}'.
  const { step: initialStage, ...initialFormData } = useHomeEquityQueryParamData(FINANCE_GOALS_FIELDS);

  const [showPrompt, setShowPrompt] = useState(false);
  // @ts-expect-error TS(2339): Property 'stage' does not exist on type '{}'.
  const [curStage, setCurStage] = useState(parseInt(initialStage) || initialEditConfig?.stage || 1);
  const [prevStage, setPrevStage] = useState(FINANCE_OPTION_STAGES.SELECT_FINANCE_OPTION);

  const { home } = useCurrentHome();
  const { currentScenario } = useEquityScenarioContext();
  const { results: equityScenarioLiens } = useAsync(getEquityScenarioLiens, {
    immediate: true,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    params: [home?.id, currentScenario?.id],
  });

  const form = { ...useDebtForm(equityScenarioLiens, initialFormData, initialEditConfig), tracking_data: trackingData };

  // @ts-expect-error TS(7006): Parameter 'step' implicitly has an 'any' type.
  const updateStages = step => {
    setShowPrompt(step === 2);
    setPrevStage(curStage);
    setCurStage(step);
  };

  const onNext = () => {
    updateStages(prevStage);
    navigate(`/${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`);
  };

  const onBack = () => {
    // @ts-expect-error TS(2339): Property 'lienType' does not exist on type '{}'.
    if (initialEditConfig?.lienType) {
      return null;
    }
    return () => updateStages(prevStage);
  };

  // @ts-expect-error TS(2345): Argument of type '{ curStage: any; form: any; upda... Remove this comment to see the full error message
  const formsToRender = getFormsToRender({ curStage, form, updateStages, onBack, onNext, initialEditConfig });

  return (
    // @ts-expect-error TS(2741): Property 'headerContent' is missing in type '{ children: Element; logoLink: string; onModalClose: () => void; }'
    <ModalPageLayoutLite logoLink={getNewDashboardUrl()} onModalClose={() => navigate(getNewHEDUrl())}>
      <div className="flex-grow border-0 border-t border-solid border-neutral-light-100" />
      <RouterPrompt
        when={showPrompt}
        onOK={() => {
          setShowPrompt(false);
          navigate(getDashboardUrl());
        }}
      />
      <ProgressiveFormSlides currentSlide={formsToRender[curStage - 1].component} />
    </ModalPageLayoutLite>
  );
};

export default FinanceGoalsController;
