import { formatInlineAddress } from 'utils/address';
import { STAGES } from '../apps/dashboard/constants/trackConstants';

// @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
export const isTrackExpiredOrClosed = track => {
  return track.has_expired || track.current_stage === STAGES.CLOSED_LOST;
};

// TODO: this is not used anywhere so we can remove it
// @ts-expect-error TS(7006): Parameter 'tracks' implicitly has an 'any' type.
export const groupTracksByAddress = tracks => {
  // @ts-expect-error TS(7006): Parameter 'map' implicitly has an 'any' type.
  return tracks.reduce((map, track) => {
    const formattedKey = formatInlineAddress(track.home.address);
    const data = map[formattedKey] || { tracks: [], inactiveTracks: [] };

    const organizedTracks = isTrackExpiredOrClosed(track)
      ? {
          tracks: data.tracks,
          inactiveTracks: [...data.inactiveTracks, track],
        }
      : {
          tracks: [...data.tracks, track],
          inactiveTracks: data.inactiveTracks,
        };

    map[formattedKey] = organizedTracks;
    return map;
  }, {});
};
