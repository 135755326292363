import { useState } from 'react';
import { isEqual } from 'lodash';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { CHECKLISTS_DEFAULT_DATA } from 'apps/dashboard/data';
import {
  updateHomeMaintenanceTasks,
  getCurrentSeasonChecklist,
  getOutOfSeasonChecklists,
} from '../utils/homeMaintenanceTasksUtils';

const useHomeMaintenanceTasks = () => {
  const SESSION_KEY = 'HomeMaintenanceTasks';
  const [checklistsData, setChecklistsData] = useState([...CHECKLISTS_DEFAULT_DATA]);
  const [sessionPropertyData, setSessionPropertyData] = useSessionStorage(SESSION_KEY, checklistsData);

  const onLoadTasks = () => {
    const newChecklistData = sessionPropertyData;
    if (!isEqual(newChecklistData, checklistsData)) {
      setChecklistsData(newChecklistData);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'taskSelected' implicitly has an 'any' t... Remove this comment to see the full error message
  const onSelectTask = taskSelected => {
    const newChecklistData = updateHomeMaintenanceTasks(checklistsData, taskSelected);
    setChecklistsData(newChecklistData);
    setSessionPropertyData(newChecklistData);
  };

  // @ts-expect-error TS(2345): Argument of type '{ season: string; tasks: { label... Remove this comment to see the full error message
  const curSeasonChecklist = getCurrentSeasonChecklist(checklistsData);
  // @ts-expect-error TS(2345): Argument of type '{ season: string; tasks: { label... Remove this comment to see the full error message
  const outOfSeasonChecklists = getOutOfSeasonChecklists(checklistsData);

  return {
    onLoadTasks,
    onSelectTask,
    curSeasonChecklist,
    outOfSeasonChecklists,
  };
};

export default useHomeMaintenanceTasks;
