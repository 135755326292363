// @ts-expect-error Could not find a declaration file for module './analytics/segment'.
import typewriter from './analytics/segment';

/*
This is set up to help manage our segment tracking in one place.

More info can be found here:
https://github.com/HomeTapDev/client_portal/blob/develop/docs/Analytics.md

It will additionally log all segment calls if SEGMENT_LOGGING
is set to true in your .env - which is for easier development.
*/
// @ts-expect-error TS(7006): Parameter 'prefix' implicitly has an 'any' type.
const debug = (prefix, event, props) => {
  if (process.env.REACT_APP_SEGMENT_LOGGING) {
    // eslint-disable-next-line no-console
    console.log(prefix, event, JSON.stringify(props, null, 2));
  }
};

const getSegment = () => {
  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.analytics) {
    // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
    return window.analytics;
  }
  console.error('Segment Error: Cannot find window.analytics');
};

// Initialize Segment with the write key from environment variables
export const init = () => {
  return getSegment()?.load(process.env.REACT_APP_SEGMENT_KEY);
};

let DISABLED = false;
export const disable = () => {
  DISABLED = true;
};

// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
export const browserTrackPage = props => {
  if (!DISABLED) {
    debug('SEGMENT PAGE |', 'Page Viewed', props);
    return getSegment()?.page('Page Viewed', props);
  }
};

// Explicitly send these flags along with browserIdentifyAnonymousFlag
// There are some instances where these flags might change might change and the user might
// experience two different things before and after login/signup.
// e.g. inquiry and re-inquiry for inquiryAddressUx
// We don't track this because the real fix we should be doing is getting the flag
// to stay the same for the user before and after login/signup.
export const ANONYMOUS_FLAGS = ['inquiryAddressUx', 'showInquirySignInMethods'];

// LaunchDarkly doesn't keep track of anonymous users being the same as the logged in users
// so we observed that the flags in Heap's (by way of Segment) user profile were incorrectly
// updating because LaunchDarkly was re-evaluating the flags for these users.
// This is particularly annoying for tracking flags that affect conversion to a logged in user
// because we are unable to measure the impact of the flag on the conversion.
// We exclude these flags from browserIdentify so they do not change post-login/signup.
// Instead we explicitly control sending these flags over with browserIdentifyAnonymousFlag
// @ts-expect-error TS(7006): Parameter 'flagAndValue' implicitly has an 'any' t... Remove this comment to see the full error message
export const browserIdentifyAnonymousFlag = flagAndValue => {
  if (!DISABLED) {
    const traits = { flags: flagAndValue };
    debug('SEGMENT IDENTIFY |', undefined, traits);
    return getSegment()?.identify(traits);
  }
};

// @ts-expect-error TS(7006): Parameter 'userID' implicitly has an 'any' type.
export const browserIdentify = (userID, traits) => {
  if (!DISABLED) {
    const traitsToSend = { ...traits };
    if (traitsToSend.flags) {
      const flags = { ...traitsToSend.flags };
      for (const flag of ANONYMOUS_FLAGS) {
        delete flags[flag];
      }
      traitsToSend.flags = flags;
    }
    debug('SEGMENT IDENTIFY |', userID, traitsToSend);
    if (userID) {
      return getSegment()?.identify(userID, traitsToSend);
    }
    return getSegment()?.identify(traitsToSend);
  }
};

/**
 * @typedef homeownerApplicationCompletedBrowserTrackProps
 * @type {object}
 * @property {string[]} flags
 * @property {string} email
 * @property {string} friendlyId
 * @property {number} numberOfCoApplicants
 */

/**
 * homeownerApplicationCompletedBrowserTrack
 * @param {homeownerApplicationCompletedBrowserTrackProps} props
 * @returns
 */
// @ts-expect-error TS(7031): Binding element 'flags' implicitly has an 'any' ty... Remove this comment to see the full error message
export const homeownerApplicationCompletedBrowserTrack = ({ flags, email, friendlyId, numberOfCoApplicants }) => {
  if (!DISABLED) {
    const props = {
      enabled_rollout_flags: flags,
      email,
      friendly_id: friendlyId,
      to_stage: 'applicationinreview',
      from_stage: 'applicationinprogress',
      'number_of_co-applicants': numberOfCoApplicants,
    };
    debug('SEGMENT TRACK |', 'Application Completed Browser', props);
    return getSegment()?.track('Homeowner Application Completed Browser', props);
  }
};

// Setup typewriter with debug logging and error handling.
// @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
const analyticsWithDebug = { ...window.analytics };
// @ts-expect-error TS(7019): Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
analyticsWithDebug.track = (...args) => {
  if (!DISABLED) {
    debug('SEGMENT TRACK | ', args[0], args[1]);
    return getSegment()?.track(...args);
  }
};

typewriter.setTypewriterOptions({
  analytics: analyticsWithDebug,
  // @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
  onViolation: (message, violations) => {
    console.error(`SEGMENT VIOLATION | For - ${message.event}`, JSON.stringify(violations, null, 2));
  },
});

// Exporting this with the following exposes the jsDoc data on browserTrack
// @ts-expect-error Could not find a declaration file for module './analytics/segment'.
export { default as browserTrack } from './analytics/segment';

const methods = { init, disable };
export default methods;
