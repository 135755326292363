import { useEffect } from 'react';

import { MuiSelect, MuiTextInput } from '@hometap/htco-components';
import useStates from 'hooks/useStates';

import BoolField from '../../components/BoolField/BoolField';
import useApplicantForm from '../../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD } from '../../constants/formFields';

import './MailingAddressForm.scss';

const MailingAddressForm = () => {
  const { registerApplicantField, applicantFormData, setErrors } = useApplicantForm();
  const { statesForDropdown } = useStates();

  const isDifferentAddress = applicantFormData?.is_track_address_mailing_address === false;

  useEffect(() => {
    if (!isDifferentAddress) {
      setErrors('zip_code', '');
    }
  }, [isDifferentAddress, setErrors]);

  const yesNoQuestion = (
    <BoolField
      required
      labelWidth="100%"
      baseRegisterField={registerApplicantField(APPLICANT_FORM_FIELD.isTrackAddressMailingAddress)}
    />
  );

  const mailingAddressQuestion = (
    <div className="MailingAddress">
      <label className="MailingAddressPrompt">
        What is{' '}
        {applicantFormData?.isPrimaryApplicant ? 'your' : `${applicantFormData?.[APPLICANT_FORM_FIELD.firstName]}'s`}{' '}
        mailing address?
      </label>
      <div className="AddressContainer">
        <MuiTextInput
          className="StreetAddressInput"
          name="street"
          label="Street Address"
          maxLength={50}
          required={isDifferentAddress}
          {...registerApplicantField(APPLICANT_FORM_FIELD.street)}
        />
        <MuiTextInput
          className="UnitAddressInput"
          name="unit"
          label="Unit (optional)"
          maxLength={50}
          {...registerApplicantField(APPLICANT_FORM_FIELD.unit)}
        />
      </div>
      <div className="AddressContainer">
        <MuiTextInput
          className="CityAddressInput"
          name="city"
          label="City"
          maxLength={50}
          required={isDifferentAddress}
          {...registerApplicantField(APPLICANT_FORM_FIELD.city)}
        />
        <MuiSelect
          className="StateAddressInput"
          name="state"
          label="State"
          required={isDifferentAddress}
          options={statesForDropdown}
          {...registerApplicantField(APPLICANT_FORM_FIELD.state)}
        />
        <MuiTextInput
          className="ZipAddressInput"
          name="zip_code"
          label="Zip Code"
          type="number"
          inputMode="number"
          maxLength={5}
          required
          mask="00000"
          // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
          validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
          {...registerApplicantField(APPLICANT_FORM_FIELD.zipCode)}
        />
      </div>
    </div>
  );

  return (
    <div className="ApplicationInfoFormFields">
      {yesNoQuestion}
      {isDifferentAddress && mailingAddressQuestion}
    </div>
  );
};

export default MailingAddressForm;
