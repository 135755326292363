import { LIEN_FIELDS, EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { percent, currency, roundStringToDecimalPlaces, roundToDecimal, percentToDecimal } from 'utils/numbers';
import { omit } from 'lodash';
import moment from 'moment';

export const getRenovationSum = (renovations = []) => {
  // @ts-expect-error TS(2339): Property 'added_value' does not exist on type 'nev... Remove this comment to see the full error message
  return renovations.reduce((total, reno) => total + reno.added_value, 0);
};

// @ts-expect-error TS(7006): Parameter 'appreciationData' implicitly has an 'an... Remove this comment to see the full error message
export const formatAppreciationDataWithRenovations = appreciationData => {
  const currentYear = new Date().getFullYear();
  // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
  return appreciationData.map(data => {
    if (data.year < currentYear) {
      return omit(data, 'appreciated_home_value_with_renos');
    }
    return {
      ...data,
      appreciated_home_value_with_renos: data.appreciated_home_value,
    };
  });
};

// can probably remove if we optimize validator function for lien inputs
// @ts-expect-error TS(7006): Parameter 'lienData' implicitly has an 'any' type.
export const validateLienDataForApi = (lienData, lienType) => {
  switch (lienType) {
    case EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE:
      if (
        lienData?.[LIEN_FIELDS.LIEN_KIND] &&
        lienData?.[LIEN_FIELDS.LAST_KNOWN_CURRENT_BALANCE] &&
        lienData?.[LIEN_FIELDS.MONTHLY_PAYMENT]
      ) {
        return true;
      }
      return false;
    case EQUITY_SCENARIO_LIEN_TYPE.OTHER:
    case EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN:
    case EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT:
      if (lienData?.[LIEN_FIELDS.RATE] && lienData?.[LIEN_FIELDS.MONTHLY_PAYMENT]) {
        return true;
      }
      return false;
    case EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT:
      if (lienData?.[LIEN_FIELDS.ORIGINAL_BALANCE]) {
        return true;
      }
      return false;
    default:
      return false;
  }
};

// @ts-expect-error TS(7006): Parameter 'principal_amount' implicitly has an 'an... Remove this comment to see the full error message
const getInvestmentLienMonthlyPayment = (principal_amount, interest_amount) => {
  // TODO: @stuart Note that the investment seeded data is way off a lot of time (like pricipal values that exceed that house value)
  // for now easiest to just return a static amount if running locally.
  if (!principal_amount || !interest_amount) {
    return null;
  }
  const sum = parseFloat(principal_amount) + parseFloat(interest_amount);
  return sum.toFixed(2);
  // return '3333.00'; // testing value
};

// @ts-expect-error TS(7006): Parameter 'liens' implicitly has an 'any' type.
export const updateInvestmentLiensWithCurrentAppreciatedValues = (liens, annualizedData) => {
  const currentYear = new Date().getFullYear();
  const todayFormatted = moment().format('YYYY-MM-DD');
  // @ts-expect-error TS(7006): Parameter 'v' implicitly has an 'any' type.
  const curYearData = annualizedData.find(v => v.year === currentYear);
  // @ts-expect-error TS(7034): Variable 'updatedLiens' implicitly has type 'any[]... Remove this comment to see the full error message
  const updatedLiens = [];
  // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
  liens.forEach(lien => {
    // if the lien_type is not on the curYearData it means that the investment lien was paid off before the current year
    if (curYearData?.[lien.lien_type]) {
      lien.current_balance = curYearData[lien.lien_type];
      lien.last_known_current_balance = curYearData[lien.lien_type];
      lien.as_of_date = todayFormatted;
      updatedLiens.push(lien);
    }
  });
  // @ts-expect-error TS(7005): Variable 'updatedLiens' implicitly has an 'any[]' ... Remove this comment to see the full error message
  return updatedLiens;
};

// @ts-expect-error TS(7006): Parameter 'lienKind' implicitly has an 'any' type.
export const getLienReviewType = lienKind => {
  const lienReviewTypeMap = {
    mortgage: EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE,
    home_equity_loan: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN,
    home_equity_line_of_credit: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT,
    home_equity_investment: EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT,
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return lienReviewTypeMap[lienKind] || EQUITY_SCENARIO_LIEN_TYPE.OTHER;
};

// @ts-expect-error TS(7006): Parameter 'lienData' implicitly has an 'any' type.
export const formatExistingLienDataForApi = lienData => {
  lienData.balances = [
    {
      ...lienData,
      balance: roundStringToDecimalPlaces(lienData.last_known_current_balance),
      as_of_date: lienData.effective_date || lienData.last_known_current_balance_as_of || moment().format('YYYY-MM-DD'),
    },
  ];
  return lienData;
};

// @ts-expect-error TS(7006): Parameter 'forecastData' implicitly has an 'any' t... Remove this comment to see the full error message
export const getForecastedValueAndEquity = (forecastData, liens) => {
  const year = forecastData?.year;
  const forecastedHomeVal = Math.round(forecastData?.appreciated_home_value);
  const forecastedHometapShare = forecastData?.hometap_share?.dollars || 0;
  let forecastedLienSum = 0;
  // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
  liens.forEach(lien => {
    // checks to see if the lien_type exists on the forecast data. if it doesn't the lien has been paid off.
    if (Object.prototype.hasOwnProperty.call(forecastData, lien.lien_type)) {
      forecastedLienSum = forecastedLienSum + forecastData[lien.lien_type];
    }
  });
  return {
    year,
    forecastedHomeVal,
    forecastedEquity: Math.round(forecastedHomeVal - forecastedHometapShare - forecastedLienSum),
  };
};

// @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
export const getHometapShareAndPercent = (scenario, investment) => {
  const maturityYear = parseInt(investment?.maturity_date?.split('-')?.[0]);
  // @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
  const investmentEndAppreciatedHomeValue = scenario?.annualAppreciation?.find(obj => obj?.year === maturityYear);
  const hometapShare = currency(roundToDecimal(investmentEndAppreciatedHomeValue?.hometap_share?.dollars));
  const hometapSharePercent = percent(investmentEndAppreciatedHomeValue?.hometap_share?.percent);
  return [hometapShare, hometapSharePercent];
};

// @ts-expect-error TS(7006): Parameter 'homeVal' implicitly has an 'any' type.
export const getCurrentEquity = (homeVal, liens = [], scenario, investment) => {
  let lienTotal = 0;
  let hometapShareTotal = 0;
  const curYear = new Date().getFullYear();
  // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
  const curYearData = scenario?.annualAppreciation?.find(data => data.year === curYear);
  if (liens.length) {
    lienTotal = liens.reduce((amount, lien) => {
      // @ts-expect-error TS(2339): Property 'lien_type' does not exist on type 'never... Remove this comment to see the full error message
      const lienAmount = curYearData[lien.lien_type] || 0;
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      return amount + parseInt(parseFloat(lienAmount));
    }, 0);
  }
  // @ts-expect-error TS(2339): Property 'lien_type' does not exist on type 'never... Remove this comment to see the full error message
  if (investment || liens.find(lien => lien.lien_type === 'home_equity_investment')) {
    hometapShareTotal = curYearData?.hometap_share?.dollars;
  }
  return { currentEquity: Math.round(homeVal - lienTotal - hometapShareTotal) };
};

// @ts-expect-error TS(7006): Parameter 'num' implicitly has an 'any' type.
export const convertStringFloatToInt = num => Math.round(parseFloat(num));

// should consolidate validators in future, currently used in onboarding flow.
// @ts-expect-error TS(7006): Parameter 'mortgage' implicitly has an 'any' type.
export const hasValidMortgageData = mortgage => {
  if (
    (mortgage?.[LIEN_FIELDS.LIEN_KIND] || mortgage?.[LIEN_FIELDS.LIEN_KIND_OTHER]) &&
    mortgage?.[LIEN_FIELDS.LAST_KNOWN_CURRENT_BALANCE] &&
    mortgage?.[LIEN_FIELDS.MONTHLY_PAYMENT] &&
    mortgage?.[LIEN_FIELDS.RATE]
  ) {
    return true;
  }
  return false;
};

// @ts-expect-error TS(7006): Parameter 'homeEquityLoan' implicitly has an 'any'... Remove this comment to see the full error message
export const hasValidHomeEquityLoanData = homeEquityLoan => {
  if (
    homeEquityLoan?.[LIEN_FIELDS.LAST_KNOWN_CURRENT_BALANCE] &&
    homeEquityLoan?.[LIEN_FIELDS.RATE] &&
    homeEquityLoan?.[LIEN_FIELDS.MONTHLY_PAYMENT]
  ) {
    return true;
  }
  return false;
};

// @ts-expect-error TS(7006): Parameter 'heloc' implicitly has an 'any' type.
export const hasValidHomeEquityLineOfCreditData = heloc => {
  if (
    heloc?.[LIEN_FIELDS.LAST_KNOWN_CURRENT_BALANCE] &&
    heloc?.[LIEN_FIELDS.RATE] &&
    heloc?.[LIEN_FIELDS.MONTHLY_PAYMENT]
  ) {
    return true;
  }
  return false;
};

// @ts-expect-error TS(7006): Parameter 'otherLien' implicitly has an 'any' type... Remove this comment to see the full error message
export const hasValidOtherLien = otherLien => {
  if (
    otherLien?.[LIEN_FIELDS.LAST_KNOWN_CURRENT_BALANCE] &&
    otherLien?.[LIEN_FIELDS.MONTHLY_PAYMENT] &&
    otherLien?.[LIEN_FIELDS.RATE]
  ) {
    return true;
  }
  return false;
};

// @ts-expect-error TS(7006): Parameter 'mortgage' implicitly has an 'any' type.
export const getMortgageData = mortgage => {
  return {
    current_mortgage_balance: Math.round(parseFloat(mortgage?.last_known_current_balance)),
    mortgage_rate: mortgage?.rate || 0,
    monthly_mortgage_payment: mortgage?.monthly_payment,
  };
};

// @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
export const setLienData = scenario => {
  if (!scenario?.liens && scenario?.tentative_liens?.length) {
    scenario.liens = scenario?.tentative_liens;
    return scenario;
  }
  scenario.liens = [];
  return scenario;
};

// investment liens differ from scenario lien fields in the database, have to do some manipulation.
// intended to be used on first data fetch.
// @ts-expect-error TS(7006): Parameter 'investment' implicitly has an 'any' typ... Remove this comment to see the full error message
export const prepareInvestmentLiens = investment => {
  const investmentLiens = investment?.lien_reviews || [];
  return (
    investmentLiens
      // @ts-expect-error TS(7006): Parameter 'investmentLien' implicitly has an 'any'... Remove this comment to see the full error message
      .map(investmentLien => {
        const monthly_payment = getInvestmentLienMonthlyPayment(
          investmentLien.principal_amount,
          investmentLien.interest_amount,
        );
        let rate = percentToDecimal(investmentLien.rate);
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'nu... Remove this comment to see the full error message
        rate = !rate || rate === '0' ? null : rate.toFixed(4);
        const lien_type = getLienReviewType(investmentLien.kind);
        const investmentLienData = {
          balances: [
            {
              as_of_date: investment?.effective_date,
              balance: roundStringToDecimalPlaces(investmentLien.current_balance),
            },
          ],
          lien_type,
          rate,
          monthly_payment,
        };
        if (lien_type === EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE) {
          // @ts-expect-error TS(2339): Property 'lien_kind' does not exist on type '{ bal... Remove this comment to see the full error message
          investmentLienData.lien_kind = '30_year_fixed';
        }
        return investmentLienData;
      })
      // @ts-expect-error TS(7006): Parameter 'liens' implicitly has an 'any' type.
      .reduce((liens, curLien) => {
        // consider 0 as invalid value for monthly_payment
        if (!curLien.rate || !parseFloat(curLien.monthly_payment)) return liens;
        // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
        const duplicateLien = liens.find(lien => lien.lien_type === curLien.lien_type);
        return duplicateLien
          ? // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
            liens.map(lien => {
              if (lien.lien_type === curLien.lien_type) {
                lien.balances[0].balance = String(
                  parseFloat(lien.balances[0].balance) + parseFloat(curLien.balances[0].balance),
                );
                lien.monthly_payment = String(parseFloat(lien.monthly_payment) + parseFloat(curLien.monthly_payment));
              }
              return lien;
            })
          : [...liens, curLien];
      }, [])
  );
};

// logic for return HED user with an HEI
// we only want to show the user their investment liens if they also have that lien on their scenario
// @ts-expect-error TS(7006): Parameter 'investment' implicitly has an 'any' typ... Remove this comment to see the full error message
export const replaceMatchingLiens = (scenarioAndInvestmentLiens = [], investment, investmentLiens) => {
  return scenarioAndInvestmentLiens.map(scenarioLien => {
    const matchingLien = investmentLiens.find(
      // @ts-expect-error TS(7006): Parameter 'investLien' implicitly has an 'any' typ... Remove this comment to see the full error message
      investLien => scenarioLien.lien_type === getLienReviewType(investLien.kind),
    );
    if (matchingLien) {
      let monthly_payment = getInvestmentLienMonthlyPayment(
        matchingLien.principal_amount,
        matchingLien.interest_amount,
      );
      // use scenario values if investment lien values are null.
      let rate = percentToDecimal(matchingLien.rate);
      // consider 0 as invalid value for monthly_payment
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      if (!parseFloat(monthly_payment) && scenarioLien?.monthly_payment) {
        // @ts-expect-error TS(2339): Property 'monthly_payment' does not exist on type ... Remove this comment to see the full error message
        // eslint-disable-next-line
        monthly_payment = scenarioLien.monthly_payment;
      }
      // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
      if (!rate || rate === '0') {
        // @ts-expect-error TS(2339): Property 'rate' does not exist on type 'never'.
        // eslint-disable-next-line
        rate = scenarioLien.rate;
      } else {
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
        rate = rate.toFixed(4);
      }
      const investmentLienData = {
        // @ts-expect-error TS(2339): Property 'lien_type' does not exist on type 'never... Remove this comment to see the full error message
        lien_type: scenarioLien.lien_type,
        rate,
        balance: roundStringToDecimalPlaces(matchingLien.current_balance),
        monthly_payment,
        as_of_date: investment?.effective_date,
      };
      // @ts-expect-error TS(2339): Property 'balances' does not exist on type 'never'... Remove this comment to see the full error message
      scenarioLien.balances.unshift(investmentLienData);
    }
    return scenarioLien;
  });
};
