import { useEffect, useState } from 'react';

import { MuiTextInput, Button } from '@hometap/htco-components';

import { validateEmailSpelling } from 'utils/validateEmailSpelling';
import { emailValidator } from 'utils/validators';
import './EmailInputWithValidation.scss';
import usePrev from 'hooks/usePrev';
import { browserTrack } from 'utils/segment';
import { omit } from 'lodash';

const EmailInputWithValidation = ({
  // @ts-expect-error TS(7031): Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031): Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031): Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
  disabled,
  // @ts-expect-error TS(7031): Binding element 'handleFieldChange' implicitly has... Remove this comment to see the full error message
  handleFieldChange,
  // @ts-expect-error TS(7031): Binding element 'setFieldErrors' implicitly has an... Remove this comment to see the full error message
  setFieldErrors,
  validator = emailValidator,
  ...rest
}) => {
  const props = omit(rest, ['width', 'required']);
  const [emailSuggestion, setEmailSuggestion] = useState(false);
  const [error, setError] = useState();
  const theErrorShown = props.error ? props.error : error;
  const prevError = usePrev(theErrorShown);
  useEffect(() => {
    if (prevError !== theErrorShown && !!theErrorShown) {
      browserTrack.errorShown({ error: theErrorShown, field: name });
    }
  });

  return (
    <div className="EmailInputWithValidation">
      <MuiTextInput
        name={name}
        label={label}
        type="email"
        inputMode="email"
        maxLength={254}
        width="100%"
        required
        validator={validator}
        disabled={disabled}
        {...props}
        error={theErrorShown}
        // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
        onBlur={(val, _, err) => {
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          setEmailSuggestion(validateEmailSpelling(val));
          setError(err);
          if (setFieldErrors) setFieldErrors(name, err);
        }}
      />
      {!disabled && emailSuggestion && (
        <div className="EmailSuggestionMessage">
          Did you mean {emailSuggestion}?
          <Button
            theme="link"
            onClick={() => {
              handleFieldChange(emailSuggestion, name);
              setEmailSuggestion(false);
              setError(undefined);
            }}
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmailInputWithValidation;
