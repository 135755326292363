import { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import AccessHomeEquityCTA from 'components/AccessHomeEquityCTA/AccessHomeEquityCTA';
import { useFlags } from 'launchdarkly-react-client-sdk';

// @ts-expect-error TS(7031): Binding element 'isMobile' implicitly has an 'any'... Remove this comment to see the full error message
const usePrimaryNavItems = ({ isMobile, onLocationChange, home, userCanAccessHome }) => {
  const location = useLocation();
  const { showOverviewPage } = useFlags();

  const homeEquityBaseURL = `/dashboard/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`;

  const getActiveHomeEquityChildPages = () =>
    !!matchPath(`${homeEquityBaseURL}/${HOMEOWNER_DASHBOARD.EDIT_PROFILE}`, location.pathname) ||
    !!matchPath(`${homeEquityBaseURL}/accept-liens`, location.pathname);

  const navLinks = [
    {
      path: '/dashboard/overview',
      title: 'Overview',
      icon: 'house-multifamily',
      visible: showOverviewPage,
      active: !!matchPath('/dashboard/overview', location.pathname),
    },
    {
      path: '/dashboard/investments',
      title: 'Investments',
      icon: 'compensation',
      visible: !!home.tracks?.length || !userCanAccessHome,
      active: !!matchPath('/dashboard/investments', location.pathname),
    },
    {
      path: '/dashboard/home-equity',
      title: 'Home equity forecast',
      icon: 'normal-appreciation',
      visible: userCanAccessHome,
      pageTitleHidden: getActiveHomeEquityChildPages(),
      active: !!matchPath(homeEquityBaseURL, location.pathname) || getActiveHomeEquityChildPages(),
    },
  ];

  const scenarioPlannerSection = {
    section: 'Scenario planner',
    icon: 'renovate',
    disabled: !home.hed_debts_confirmed,
    visible: userCanAccessHome,
    items: [
      {
        pageTitleClassName: 'mb-6',
        path: '/dashboard/home-equity/renovations',
        title: 'Renovation',
        active: !!matchPath('/dashboard/home-equity/renovations', location.pathname),
      },
      {
        pageTitleClassName: 'mb-6',
        path: '/dashboard/home-equity/goals',
        title: 'Financing',
        active:
          !!matchPath('/dashboard/home-equity/goals', location.pathname) ||
          !!matchPath('/dashboard/home-equity/finance', location.pathname),
      },
    ],
  };

  const resourcesSection = {
    section: 'Resources',
    icon: 'bookmark',
    items: [
      {
        path: '/dashboard/maintenance',
        title: 'Maintenance checklist',
        visible: userCanAccessHome,
        active: !!matchPath('/dashboard/maintenance', location.pathname),
      },
      {
        path: '/dashboard/resources',
        title: 'Articles',
        active: !!matchPath('/dashboard/resources', location.pathname),
      },
      {
        pageTitleClassName: 'mb-6',
        path: '/dashboard/featured-offers',
        title: 'Featured Offers',
        active: !!matchPath('/dashboard/featured-offers', location.pathname),
      },
    ],
  };

  const mobileAccountSection = {
    section: 'Account',
    visible: isMobile,
    extraContent: () => (
      <AccessHomeEquityCTA
        onClick={onLocationChange}
        className="m-0 mt-3 w-full p-0 !text-base xs:mx-auto xs:w-1/2"
        size="large"
      />
    ),
    icon: 'avatar-1',
    items: [
      {
        path: '/profile',
        title: 'Account settings',
        active: !!matchPath('/profile', location.pathname),
      },
      {
        path: '/support',
        title: 'Get help',
        active: !!matchPath('/support', location.pathname),
      },
      {
        path: '/logout',
        title: 'Log out',
        active: false,
      },
    ],
  };

  // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
  const isNavItemVisible = item => item.visible ?? true;
  // @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type.
  const getActiveSection = section => section?.items.some(item => item.active);
  // @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type.
  const filterInvisibleSectionsAndLinks = section =>
    section
      .filter(isNavItemVisible)
      // @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type.
      .map(section => ({ ...section, items: section.items.filter(isNavItemVisible) }))
      // @ts-expect-error TS(7031): Binding element 'items' implicitly has an 'any' ty... Remove this comment to see the full error message
      .filter(({ items }) => items.length);
  const visibleNavLinks = navLinks.filter(isNavItemVisible);

  const navSectionGroups = [[scenarioPlannerSection, resourcesSection], [mobileAccountSection]];
  const visibleGroups = navSectionGroups.map(filterInvisibleSectionsAndLinks).filter(item => item.length);

  const activeSection = visibleGroups.flat().find(getActiveSection);

  const [openSection, setOpenSection] = useState(activeSection?.section || null);
  const previousLocation = useRef(null);

  useEffect(() => {
    if (location.pathname !== previousLocation.current) {
      onLocationChange(location.pathname);

      if (!activeSection) {
        setOpenSection(null);
      }
    }

    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'null'.
    previousLocation.current = location.pathname;
  }, [location, onLocationChange, activeSection]);

  // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
  const activePage = activeSection?.items.find(item => item.active) || navLinks.find(link => link.active);

  return {
    navLinks: visibleNavLinks,
    navSectionGroups: visibleGroups,
    openSection,
    onToggleOpenSection: setOpenSection,
    activePage,
  };
};

export default usePrimaryNavItems;
