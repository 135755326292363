import cx from 'classnames';

import { Button, IconButton, Icon, Loader } from '@hometap/htco-components';
import { DOCUMENT_STATUS } from '../../utils';
import { bytesToSize } from '../../utils';

import './FileStatus.scss';

// @ts-expect-error TS(7031): Binding element 'file' implicitly has an 'any' typ... Remove this comment to see the full error message
const FileStatus = ({ file, status, loading, errors = [], onRemove, onReplace }) => {
  const hasErrors = errors.length > 0;
  const isUploaded = status === DOCUMENT_STATUS.UPLOADED;
  const canRemove = !isUploaded && !loading && onRemove;

  const handleReplace = () => {
    onReplace();
    setTimeout(() => {
      onRemove();
    }, 1000);
  };

  return (
    <div className="FileStatus">
      <div className={cx('FileStatusDetails', { hasErrors })}>
        <strong>{file.name}</strong>
        <span className="FileStatusSize">{bytesToSize(file.size, 1)}</span>
        <div className="FileStatusErrors">
          {errors.map(error => {
            // @ts-expect-error TS(2339): Property 'message' does not exist on type 'never'.
            return error.message;
          })}
        </div>
      </div>

      <div className="FileStatusMeta">
        {hasErrors && (
          <Button theme="link" onClick={handleReplace}>
            Replace
          </Button>
        )}
        <div className="FileStatusIconWrapper">
          {canRemove && <IconButton icon="cancel" size="sm" onClick={onRemove} />}
          {loading && !isUploaded && <Loader size="small" />}
          {isUploaded && <Icon name="check-btl" className="FileStatusSuccess" size="sm" />}
        </div>
      </div>
    </div>
  );
};
export default FileStatus;
