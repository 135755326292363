import { percent } from 'utils/numbers';
import last from 'lodash/last';

import sentry from '../../../utils/sentry';
import { INVESTMENT_TERM_UNIT } from '../data/rundownUtils';

const SHARE_UP_FRACTION = 'share_up_fraction';
const SHARE_DOWN_FRACTION = 'share_down_fraction';

export const formatHomeShares = ({
  // @ts-expect-error TS(7031): Binding element 'homeShareRatios' implicitly has a... Remove this comment to see the full error message
  homeShareRatios,
  // @ts-expect-error TS(7031): Binding element 'investmentLength' implicitly has ... Remove this comment to see the full error message
  investmentLength,
  // @ts-expect-error TS(7031): Binding element 'isShareCapped' implicitly has an ... Remove this comment to see the full error message
  isShareCapped,
  investmentTermUnit = INVESTMENT_TERM_UNIT.YEARS,
}) => {
  return {
    upShareValues: formatShareValues({
      homeShareRatios,
      investmentLength,
      isShareCapped,
      investmentTermUnit,
      shareFieldName: SHARE_UP_FRACTION,
    }),
    downShareValues: formatShareValues({
      homeShareRatios,
      investmentLength,
      isShareCapped,
      investmentTermUnit,
      shareFieldName: SHARE_DOWN_FRACTION,
    }),
  };
};

const formatShareValues = ({
  // @ts-expect-error TS(7031): Binding element 'homeShareRatios' implicitly has a... Remove this comment to see the full error message
  homeShareRatios,
  // @ts-expect-error TS(7031): Binding element 'investmentLength' implicitly has ... Remove this comment to see the full error message
  investmentLength,
  // @ts-expect-error TS(7031): Binding element 'shareFieldName' implicitly has an... Remove this comment to see the full error message
  shareFieldName,
  // @ts-expect-error TS(7031): Binding element 'isShareCapped' implicitly has an ... Remove this comment to see the full error message
  isShareCapped,
  // @ts-expect-error TS(7031): Binding element 'investmentTermUnit' implicitly ha... Remove this comment to see the full error message
  investmentTermUnit,
}) => {
  const investmentLengthInYears =
    investmentTermUnit === INVESTMENT_TERM_UNIT.MONTHS ? investmentLength / 12 : investmentLength;
  // If the investmentLengthInYears is somehow larger than the maximum range_years_upper, error
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  if (investmentLengthInYears > last(homeShareRatios)?.range_years_upper) {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    sentry.logErrorWrapper('Investment length does not fit in any price bucket');
    return [];
  }
  const shareRanges = buildShareRanges(homeShareRatios, shareFieldName);

  return shareRanges.map(({ minInvestmentRange, maxInvestmentRange, share_fraction: shareFraction }) => {
    const isMultiTieredProduct = homeShareRatios.length > 1;

    let text;
    if (!isMultiTieredProduct) {
      text = null;
    } else if (shareRanges.length > 1) {
      text = `if you settle in ${minInvestmentRange}-${maxInvestmentRange} years`;
    } else {
      text = null;
    }

    const isLengthWithinRange = minInvestmentRange <= investmentLength && investmentLength <= maxInvestmentRange;

    return {
      text,
      percentage: percent(shareFraction),
      scenarioCardText: `${minInvestmentRange} - ${maxInvestmentRange} years`,
      isHighlighted: !isShareCapped && isLengthWithinRange,
    };
  });
};

// @ts-expect-error TS(7006): Parameter 'homeShareRatios' implicitly has an 'any... Remove this comment to see the full error message
const buildShareRanges = (homeShareRatios, shareFieldName) => {
  // Create an array of shareRanges with any sibling ranges with the same range_years_upper
  // collapsed into a single range. Implemented via iteration rather than de-duping by
  // share_fraction because we do not want to collapse non-sibling ranges even if they have
  // the same share_fraction.
  // @ts-expect-error TS(7034): Variable 'shareRanges' implicitly has type 'any[]'... Remove this comment to see the full error message
  const shareRanges = [];
  // @ts-expect-error TS(7006): Parameter 'shareRatio' implicitly has an 'any' typ... Remove this comment to see the full error message
  homeShareRatios.forEach(shareRatio => {
    // @ts-expect-error TS(7005): Variable 'shareRanges' implicitly has an 'any[]' t... Remove this comment to see the full error message
    const prevRange = last(shareRanges);
    if (prevRange && prevRange.share_fraction === shareRatio[shareFieldName]) {
      prevRange.maxInvestmentRange = shareRatio.range_years_upper;
    } else {
      shareRanges.push({
        share_fraction: shareRatio[shareFieldName],
        maxInvestmentRange: shareRatio.range_years_upper,
        minInvestmentRange: prevRange ? prevRange.maxInvestmentRange + 1 : 0,
      });
    }
  });

  // @ts-expect-error TS(7005): Variable 'shareRanges' implicitly has an 'any[]' t... Remove this comment to see the full error message
  return shareRanges;
};
