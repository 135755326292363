import { useAsync } from '@hometap/htco-components';
import { apiNoAuth } from 'utils/api';

// @ts-expect-error TS(7006): Parameter 'params' implicitly has an 'any' type.
const fetchData = params => {
  return apiNoAuth.get(`/core/configurations`, { params: params });
};

const useConfigurationOptions = (params = {}) => {
  const {
    error: asyncError,
    results,
    loading,
  } = useAsync(fetchData, {
    immediate: true,
    params: [params],
  });

  // @ts-expect-error TS(2339): Property 'names' does not exist on type '{}'.
  const dropdownConfigurationOptions = params.names.reduce((obj, param) => {
    if (results) {
      if (param === 'document_kinds_with_person') {
        obj[param] = results[param] || [];
      } else {
        // @ts-expect-error TS(7006): Parameter 'res' implicitly has an 'any' type.
        const result = results[param]?.choices?.map(res => ({
          label: res.display_name || res.label,
          value: res.value,
          ...(res.icon ? { icon: res.icon } : {}), // conditionally spread icon
          ...(res.description ? { description: res.description } : {}), // conditionally spread description
        }));
        obj[param] = result;
      }
    }
    return obj;
  }, {});

  // @ts-expect-error TS(7006): Parameter 'configurationOptionsField' implicitly h... Remove this comment to see the full error message
  const getConfigurationValueDisplay = (configurationOptionsField, optionValue) => {
    const fieldOptions = dropdownConfigurationOptions[configurationOptionsField] || [];
    // @ts-expect-error TS(7031): Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
    const { label } = fieldOptions.find(({ value }) => value === optionValue) || {};
    return label;
  };

  return { dropdownConfigurationOptions, getConfigurationValueDisplay, asyncError, configOptsLoading: loading };
};

export default useConfigurationOptions;
