import moment from 'moment';

import TrackDetailsTodoCard from '../TrackDetailsTodoCard/TrackDetailsTodoCard';
import { useState } from 'react';
import './TrackDetailsStageSection.scss';

import { Badge, useBoolState, useWindowSize, useAsync } from '@hometap/htco-components';
import cx from 'classnames';
import DocumentsUploadController from 'apps/documents/DocumentsUploadController';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useSearchParams from 'hooks/useSearchParams';
import useTrack from 'hooks/useTrack';
import { PLACEHOLDER_TODO } from 'apps/track-details/utils/constants';
import { MARITAL_SIGNATURE_TYPES } from 'apps/track-details/utils/config/todoMappings';
import { TASK_DEFINITION_KEY } from 'utils/constants/taskDefinitionKeys';
import { completeTask } from 'hooks/useTask';
import sentry from 'utils/sentry';
import InformationalTodoModal from 'components/modals/InformationalTodoModal/InformationalTodoModal';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';

// @ts-expect-error TS(7031): Binding element 'isActive' implicitly has an 'any'... Remove this comment to see the full error message
const TrackDetailsStageSection = ({ isActive, sectionContext = {}, fetchAsyncTodos, sectionConfigData, todos }) => {
  const { title: staticTitle, getSubtitle, badge, showWithoutOtherStages, getTitleOverride } = sectionConfigData;

  const { fetch: fetchTrack } = useTrack();

  const { trackId } = useParams();
  const isTodoModalOpen = useBoolState();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useSearchParams();
  const selectedTodoId = query.get('task_id');
  const [maritalStatusTodoModalOpen, setMaritalStatusTodoModalOpen] = useState(false);
  const { loading: _isCompleteTaskLoading, ...completeTaskAsync } = useAsync(completeTask);

  const handleTodoModalClose = () => {
    isTodoModalOpen.setFalse();
    navigate(location.pathname, { replace: true });
  };

  // Get the marital status todo if it exists to pass to InformationalTodoModal
  // @ts-expect-error TS(7006): Parameter 'todo' implicitly has an 'any' type.
  const maritalStatusTodo = todos.find(todo => todo.task_definition_key === TASK_DEFINITION_KEY.MARITAL_STATUS);
  const maritalSignatureType = maritalStatusTodo?.variables?.marital_state_signature_type;

  // @ts-expect-error TS(7006): Parameter 'todo' implicitly has an 'any' type.
  const getNotes = (todo, includeUrlParameter) => {
    if (todo?.notes !== todo?.description) {
      if (includeUrlParameter) {
        return `&notes=${todo?.notes}`;
      }
      return todo?.notes;
    }
    if (todo?.variables?.todo_description !== todo?.description) {
      if (includeUrlParameter) {
        return `&notes=${todo?.variables?.todo_description}`;
      }
      return todo?.variables?.todo_description;
    }
    return '';
  };

  // Complete the marital status todo, and error with sentry if it fails
  const completeMaritalStatusTodo = async () => {
    await completeTaskAsync
      .execute(maritalStatusTodo?.id)
      .then(() => {
        refreshTodos();
        setMaritalStatusTodoModalOpen(false);
      })
      // @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
      .catch(error => {
        sentry.logError('Call to complete marital status todo failed.', {
          tags: {
            statusCode: error.response.status,
            statusText: error.response.statusText,
            trackId,
          },
          extra: {
            errorMessage: error.message,
            requestUrl: error.config?.url,
          },
        });
        setMaritalStatusTodoModalOpen(false);
      });
  };

  // @ts-expect-error TS(7006): Parameter 'todo' implicitly has an 'any' type.
  const handleTodoModalOpen = todo => {
    isTodoModalOpen.setTrue();
    const personIdAssociatedWithTodo = todo?.variables?.person_id;
    const personString = personIdAssociatedWithTodo ? `&person_id=${personIdAssociatedWithTodo}` : '';
    const description = todo?.description;
    const notes = getNotes(todo, true);
    navigate(
      {
        pathname: location.pathname,
        search: `?title=${todo.title}&task_id=${todo.id}&kind=${todo.variables.doc_kind}${personString}&description=${description}${notes}`,
      },
      { replace: true },
    );
  };

  const activeTodos = todos
    // @ts-expect-error TS(7031): Binding element 'isActive' implicitly has an 'any'... Remove this comment to see the full error message
    .filter(({ isActive, isComplete }) => isActive && !isComplete)
    // Sort ESTIMATE TODOs first, then gov_id TODOs, then document TODOs, lastly marital status TODOs
    // @ts-expect-error TS(7006): Parameter 'lhsTodo' implicitly has an 'any' type.
    .sort((lhsTodo, rhsTodo) => {
      if (lhsTodo.title === rhsTodo.title) {
        return 0;
      }
      if (lhsTodo.title === PLACEHOLDER_TODO.ESTIMATE.title) {
        return -1;
      } else if (rhsTodo.title === PLACEHOLDER_TODO.ESTIMATE.title) {
        return 1;
      }
      if (lhsTodo?.variables?.marital_state_output) {
        return 1;
      } else if (rhsTodo?.variables?.marital_state_output) {
        return -1;
      }
      return lhsTodo.variables?.doc_kind === 'gov_id' ? -1 : 1;
    });

  // @ts-expect-error TS(7031): Binding element 'isActive' implicitly has an 'any'... Remove this comment to see the full error message
  const inactiveTodos = todos.filter(({ isActive }) => !isActive);
  const orderedTodos = [...activeTodos, ...inactiveTodos];
  const subtitle = getSubtitle(sectionContext);
  const title = getTitleOverride?.(sectionContext) || staticTitle;

  const { isScreenSize } = useWindowSize('sm');

  // Call to refresh the todos list after completing a todo
  const refreshTodos = async () => {
    // this prevents an unnecessary fetch for the track if the todo is a legacy hub task
    // @ts-expect-error TS(7006): Parameter 'todo' implicitly has an 'any' type.
    const areAllTodosLegacy = todos.every(todo => todo.is_legacy_hub_task);
    const updatedTodosList = await fetchAsyncTodos(trackId);
    const areAllTodosComplete = !updatedTodosList?.length;
    // if all todos are complete and not legacy, fetch the track to update the stage in the UI
    if (areAllTodosComplete && !areAllTodosLegacy) await fetchTrack();
    isTodoModalOpen.setFalse();
    navigate(location.pathname, { replace: true });
  };

  return (
    <section className={cx('TrackDetailsStageSection', { showWithoutOtherStages, isActive })}>
      <header className="TrackDetailsStageSectionHeader">
        <div className="TrackDetailsStageSectionHeaderMeta">
          {badge && (
            <Badge
              className="TrackDetailsStageSectionBadge"
              label={badge.text}
              theme={isActive ? badge.theme : 'neutralLight50'}
              large={!isScreenSize('tablet')}
            />
          )}
          {isActive && !showWithoutOtherStages && (
            <span className="TrackDetailsStageSectionLastUpdated">
              Last updated {/* @ts-expect-error TS(2339): Property 'track' does not exist on type '{}'. */}
              {sectionContext.track?.investment_manager && (
                <span className="TrackDetailsStageSectionUpdatedBy">
                  {/* @ts-expect-error TS(2339): Property 'track' does not exist on type '{}' */}
                  by {sectionContext.track.investment_manager.first_name}{' '}
                </span>
              )}
              {moment().format('M/D/YY')}{' '}
            </span>
          )}
        </div>
        <div>
          <h2 className="TrackDetailsStageSectionTitle">{title}</h2>
          {isActive && <div className="TrackDetailsStageSectionSubtitle">{subtitle}</div>}
        </div>
      </header>

      {!!orderedTodos.length && (
        <>
          <hr className={cx('TrackDetailsStageSectionSeparator', { showWithoutOtherStages })} />
          <ul>
            {orderedTodos.map((todo, i) => {
              return (
                <li key={`${todo.title}-${i}`}>
                  <TrackDetailsTodoCard
                    todo={todo}
                    notes={getNotes(todo, false)}
                    // Only show the active state if the to-do is active and the section is active
                    isActive={!!todo.isActive && isActive}
                    showWithoutOtherStages={showWithoutOtherStages}
                    onSetIsTodoModalOpen={handleTodoModalOpen}
                    setMaritalStatusTodoModalOpen={setMaritalStatusTodoModalOpen}
                  />
                </li>
              );
            })}
          </ul>
        </>
      )}
      {maritalStatusTodo && (
        <InformationalTodoModal
          todoHeader={'Your state has additional signing requirements'}
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          todoDescription={MARITAL_SIGNATURE_TYPES[maritalSignatureType]}
          informationalTodoModalOpen={maritalStatusTodoModalOpen}
          setInformationalTodoModalOpen={setMaritalStatusTodoModalOpen}
          completeInformationalTodo={completeMaritalStatusTodo}
          onDismiss={() => browserTrack.maritalStateTodoDismissed({ version: getVersion() })}
          onComplete={() => browserTrack.maritalStateTodoCompleted({ version: getVersion() })}
        />
      )}
      {isTodoModalOpen.value && (
        // @ts-expect-error TS(2741): Property 'includeKindSelect' is missing in type '{... Remove this comment to see the full error message
        <DocumentsUploadController
          key={selectedTodoId}
          asModalProps={{
            open: isTodoModalOpen.value,
            width: 1130,
            onClose: handleTodoModalClose,
          }}
          onUpload={refreshTodos}
        />
      )}
    </section>
  );
};

export default TrackDetailsStageSection;
