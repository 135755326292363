import { Button } from '@hometap/htco-components';

import './TrackDetailsContactBlock.scss';

// @ts-expect-error TS(7031): Binding element 'contactData' implicitly has an 'a... Remove this comment to see the full error message
const TrackDetailsContactBlock = ({ contactData }) => {
  const { contactImage, contactName, email, schedulingUrl, heading, phoneNumber } = contactData;
  return (
    <div className="TrackDetailsContactBlock">
      <small className="TrackDetailsContactBlockHeading">{heading}</small>
      {contactImage && contactName && (
        <div className="TrackDetailsContactBlockContactMeta">
          {contactImage && contactImage}
          {contactName && (
            <div>
              <p className="TrackDetailsContactBlockContactName">{contactName}</p>
              {phoneNumber && <p className="TrackDetailsContactBlockContactPhone">{phoneNumber}</p>}
            </div>
          )}
        </div>
      )}
      {(schedulingUrl || email) && (
        <div className="TrackDetailsContactBlockButtons">
          {schedulingUrl && (
            <Button
              className="TrackDetailsContactBlockButton"
              theme="secondary"
              size="small"
              href={schedulingUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              Schedule a call
            </Button>
          )}
          {email && (
            <Button className="TrackDetailsContactBlockButton" theme="secondary" size="small" href={`mailto:${email}`}>
              Email
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TrackDetailsContactBlock;
