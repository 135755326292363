import { Badge } from '@hometap/htco-components';
import cx from 'classnames';

// @ts-expect-error TS(7031): Binding element 'testId' implicitly has an 'any' t... Remove this comment to see the full error message
const NewBadge = ({ testId, className }) => {
  return (
    <Badge
      className={cx('[&.htco-Badge]:left-0', className)}
      label="NEW"
      theme="primaryGreen100"
      data-testid={testId}
    />
  );
};

export default NewBadge;
