import { Button } from '@hometap/htco-components';

import { getDashboardUrl, getPrimaryAppTrackDetailsUrl } from 'utils/links';

// @ts-expect-error TS(7031): Binding element 'onClickResendMagicLink' implicitl... Remove this comment to see the full error message
const InquiryCTAOutcomeButton = ({ onClickResendMagicLink, isAdditionalTrack, trackId }) => (
  <>
    {isAdditionalTrack ? (
      <Button
        href={trackId ? getPrimaryAppTrackDetailsUrl(trackId) : getDashboardUrl()}
        className="InquiryOutcomeDashboardButton"
      >
        Get started
      </Button>
    ) : (
      <>
        Didn’t get the link?{' '}
        <Button theme="link" onClick={onClickResendMagicLink}>
          Resend magic link
        </Button>
      </>
    )}
  </>
);

export default InquiryCTAOutcomeButton;
