import cx from 'classnames';

import { useAsync, SlideDown } from '@hometap/htco-components';
import { OnboardingLayout, Panel } from '../template/OnboardingTemplate';
import houseDesktopImg from 'images/equity-planner/onboarding/yellow-house.png';
import { generateMagicLink } from '../../../data/magicLink';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import './OnboardingVerify.scss';

// @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
const OnboardingVerify = ({ form }) => {
  const { email } = form.formData;
  const { error, execute, results, loading } = useAsync(generateMagicLink);
  const onClick = () => {
    execute({ email, use_case: 'hed_signup' });
  };

  const isNotificationVisible = !!results && !error;

  return (
    <OnboardingLayout>
      <div
        className={`VerifyNotification ${isNotificationVisible && 'VerifyVisible'}`}
        data-testid="verify-notification"
      >
        <div>
          We’ve sent a link to&nbsp;<b>{email}</b>. Please note, this link will expire in 30 minutes.
        </div>
      </div>
      {/* @ts-expect-error TS(2741): Property 'reducePadding' is missing in type '{ chi... Remove this comment to see the full error message */}
      <Panel className={cx('VerifyTextPanel', { VerifyResent: isNotificationVisible })} data-testid="verify-text-panel">
        <h1>An email is waiting for you!</h1>
        <p className="VerifyBody" data-testid="verify-body">
          We sent a link to <span className="VerifyBodyEmail">{email}</span>. Click the link to calculate your current
          and future equity, explore handpicked resources, and more.
        </p>
        <p className="VerifyAction" data-testid="verify-action">
          Didn’t get the email?&nbsp;
          <button
            className="VerifyResendButton"
            onClick={onClick}
            disabled={loading}
            data-testid="resend-magic-link-button"
          >
            Resend link
          </button>
        </p>
        <SlideDown visible={!!error}>
          <div className="VerifyError" data-testid="verify-error">
            {/* @ts-expect-error TS(2739): Type '{ children: string; }' is missing the follow... Remove this comment to see the full error message */}
            <ErrorMessage>We are unable to reach the server, please try again.</ErrorMessage>
          </div>
        </SlideDown>
      </Panel>
      {/* @ts-expect-error TS(2741): Property 'reducePadding' is missing in type '{ chi... Remove this comment to see the full error message */}
      <Panel containsVisual className="VerifyPicPanel" data-testid="verify-image-panel">
        <img src={houseDesktopImg} alt="house" data-testid="verify-image" />
      </Panel>
    </OnboardingLayout>
  );
};

export default OnboardingVerify;
