export const appreciationBreakpoints = [
  { label: 'Large Decline', value: -0.1, annualized: false, subtitle: '-10% total', breakpointLabel: '-10%' },
  { label: 'Moderate Decline', value: -0.05, annualized: false, subtitle: '-5% total', breakpointLabel: '-5%' },
  { label: 'No Change', value: 0, annualized: false, subtitle: '0%', breakpointLabel: '0%' },
  { label: 'Low', value: 0.03, annualized: true, subtitle: '3% annual', breakpointLabel: '3%' },
  { label: 'Moderate', value: 0.05, annualized: true, subtitle: '5% annual', breakpointLabel: '5%' },
];

export const hardcodedAppreciation = {
  value: 0.02,
  annualized: true,
};

export const hardcodedAppreciationWithFlagEnabled = {
  ...hardcodedAppreciation,
  value: 0.0434,
};
