import { useEffect, useState, useRef } from 'react';

import { MuiSelect, MuiSSNInput, MuiTextInput } from '@hometap/htco-components';

import useApplicantForm from '../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD, APPLICANT_DEMO_DATA } from '../constants/formFields';
import DemoButton from 'components/DemoButton/DemoButton';
import useApplicationContext from '../hooks/useApplicationContext';
import { useParams } from 'react-router';

// TODO: These options should be fetched from the backend using the core/configurations endpoint
const maritalStatusOptions = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Separated', value: 'separated' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
];
const relationshipToPrimaryOptions = [
  { label: 'Spouse', value: 'spouse' },
  { label: 'Significant Other', value: 'significant_other' },
  { label: 'Parent ', value: 'parent' },
  { label: 'Child ', value: 'child' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Grandchild', value: 'grandchild' },
  { label: 'Parent in Law', value: 'parent_in_law' },
  { label: 'Child in Law', value: 'child_in_law' },
  { label: 'Roommate', value: 'roommate' },
  { label: 'Other', value: 'other' },
];

const PersonalInfoForm = () => {
  const { registerApplicantField, applicantFormData, handleUpdateApplicantForm } = useApplicantForm();
  // @ts-expect-error TS(2339): Property 'primaryApplicant' does not exist on type... Remove this comment to see the full error message
  const { primaryApplicant } = useApplicationContext();
  const { applicantId } = useParams();

  const [isEditingSSN, setIsEditingSSN] = useState(false);

  // We need to format obfuscated by API SSN to look like a valid SSN number
  const ssnInputRef = useRef(null);
  const ssnFormField = registerApplicantField(APPLICANT_FORM_FIELD.ssn);
  const [editedSsnValue, setEditedSsnValue] = useState((applicantFormData?.isNew && ssnFormField.value) || '');
  const displaySsnValue = isEditingSSN ? editedSsnValue : ssnFormField.value?.replace(/\*/g, '0');

  useEffect(() => {
    if (applicantFormData?.isNew) {
      setIsEditingSSN(true);
    }
  }, [isEditingSSN, applicantFormData?.isNew]);

  useEffect(() => {
    if (!applicantFormData?.isNew && isEditingSSN) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      ssnInputRef.current.element.focus();
    }
  }, [applicantFormData?.isNew, isEditingSSN]);

  return (
    <div className="ApplicationInfoFormFields">
      <div className="ApplicationTextInputContainer">
        <MuiTextInput
          label="Legal First Name"
          width="100%"
          {...registerApplicantField(APPLICANT_FORM_FIELD.firstName)}
          required
          maxLength={30}
          disabled={applicantFormData?.isPrimaryApplicant}
        />
      </div>
      <div className="ApplicationTextInputContainer">
        <MuiTextInput
          label="Legal Middle Name (optional)"
          width="100%"
          maxLength={30}
          {...registerApplicantField(APPLICANT_FORM_FIELD.middleName)}
        />
      </div>
      <div className="ApplicationTextInputContainer">
        <MuiTextInput
          label="Legal Last Name"
          width="100%"
          {...registerApplicantField(APPLICANT_FORM_FIELD.lastName)}
          required
          maxLength={30}
          disabled={applicantFormData?.isPrimaryApplicant}
        />
      </div>

      <div className="ApplicationTextInputContainer">
        <MuiSSNInput
          className="sm_cobrowsing_masked_field"
          label="Social Security Number"
          width="100%"
          {...ssnFormField}
          value={displaySsnValue}
          obfuscate={isEditingSSN ? 'none' : 'partial'}
          required
          disabled={!isEditingSSN}
          ref={ssnInputRef}
          iconName="icon-edit"
          onIconClick={() => {
            ssnFormField.onChange('');
            setIsEditingSSN(true);
          }}
          // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
          onChange={(val, ...args) => {
            if (isEditingSSN) {
              setEditedSsnValue(val);
              ssnFormField.onChange(val, ...args);
            }
          }}
        />
      </div>
      <MuiSelect
        required
        label="Marital Status"
        options={maritalStatusOptions}
        width="100%"
        {...registerApplicantField(APPLICANT_FORM_FIELD.maritalStatus)}
      />
      {!applicantFormData?.isPrimaryApplicant && (
        <MuiSelect
          required={!applicantFormData?.isPrimaryApplicant}
          label={`Relationship to ${primaryApplicant?.first_name || 'primary applicant'}`}
          options={relationshipToPrimaryOptions}
          width="100%"
          {...registerApplicantField(APPLICANT_FORM_FIELD.relationshipToPrimary)}
        />
      )}
      {!applicantId && (
        // @ts-expect-error TS(2741): Property 'className' is missing in type '{ demoDat... Remove this comment to see the full error message
        <DemoButton
          demoData={() => APPLICANT_DEMO_DATA({ isPrimaryApplicant: applicantFormData?.isPrimaryApplicant })}
          onClickAction={handleUpdateApplicantForm}
        />
      )}
    </div>
  );
};

export default PersonalInfoForm;
