import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Banner = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        cx(
          'flex flex-col items-start justify-between rounded-lg border border-solid border-neutral-light-75 bg-neutral-light-30 p-4 xs:flex-row xs:items-center',
          className,
        ),
      )}
    >
      {children}
    </div>
  );
};

export default Banner;
