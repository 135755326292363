import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

import { Button, useAsync } from '@hometap/htco-components';
import { sendPasswordInitializationLink } from 'apps/dashboard/data/sendPasswordInitializationLink';
import LegalLink from 'components/LegalLink/LegalLink';
import houseDesktopImg from 'images/equity-planner/onboarding/yellow-house.png';
import google from 'images/equity-planner/onboarding/google.png';
import { showNotification } from 'utils/toasts';
import sentry from 'utils/sentry';
import { getApiBaseWithoutVersion } from 'utils/api';
import { ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import { PAGE_TITLES, PROGRESSIVE_STEP_NAMES } from '../constants/progressiveInquiryConstants';
import './InquirySignup.scss';

// @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
const InquirySignup = ({ form, onNext, inquiryId }) => {
  const { formData } = form;
  const { email } = formData;
  const emailContainsPlus = email.includes('+');

  const [csrfToken, setCsrfoken] = useState('');
  const [cookies] = useCookies(['portalscsrftoken']);
  const formRef = useRef();

  const { execute, loading } = useAsync(sendPasswordInitializationLink, {
    onSuccess: onNext,
    // @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
    onError: error => {
      // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; }... Remove this comment to see the full error message
      showNotification({
        type: 'error',
        title: 'Failed to sign up with password. Please retry.',
      });
      sentry.logError('Failed to sign up with password', error);
    },
  });

  useEffect(() => {
    setCsrfoken(cookies?.portalscsrftoken);
  }, [cookies.portalscsrftoken]);

  const onSocialLinkSignup = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    formRef.current.submit();
  };

  // Warning: The `next` URL param format must match NEXT_URL_INQUIRY_REDIRECT_REGEX in eng_portals
  const googleNextParam = encodeURIComponent(`redirect-for-inquiry-id=${inquiryId}`);
  const googleLoginUrl = `${getApiBaseWithoutVersion()}accounts/google/login/?next=${googleNextParam}`;

  return (
    <div className="SignUp" data-testid="inquiry_sign_up_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.SIGNUP]} />
      <ProgressiveUITemplate>
        {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
        <ContentPanel>
          <>
            <h1>Create your account</h1>
            <div className="SignUpSubtitle" data-testid="sign-up-email">
              You’re signing up as <span className="SignUpEmail">{email}</span>.
            </div>
            <Button
              data-testid="password-button"
              className="SignUpPasswordButton"
              onClick={async () => !loading && (await execute(email))}
              disabled={loading}
              loading={loading}
            >
              Sign up with password
            </Button>

            {/*
              If email address contains "+", we assume it is someone using the "+" trick with a
              Google account, and don't show the Google sign in option (since it won't work).
            */}
            {!emailContainsPlus && (
              <>
                <div className="SignUpDividerContainer">
                  <span className="SignUpDivider" />
                  <span className="SignUpOr">or</span>
                  <span className="SignUpDivider" />
                </div>
                {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
                <form ref={formRef} action={googleLoginUrl} method="post" data-testid="google-auth-form">
                  <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
                  <button
                    data-testid="google-auth-button"
                    className="SignupGoogleButton"
                    type="button"
                    onClick={onSocialLinkSignup}
                  >
                    <img src={google} alt="Google logo" />
                    Sign up with Google
                  </button>
                </form>
              </>
            )}

            <div className="SignUpPrivacyPolicy">
              By signing up, you agree to Hometap’s{' '}
              <LegalLink
                className="SignUpTerms"
                section="terms-of-use"
                text="Terms of Use"
                data-testid="terms-of-use"
              />{' '}
              and{' '}
              <LegalLink
                className="SignUpPrivacyPolicy"
                section="privacy-policy"
                text="Privacy Policy"
                data-testid="privacy-policy"
              />{' '}
            </div>
          </>
        </ContentPanel>
        <ImageContentPanel imgAlt="yellow house" imageSource={houseDesktopImg} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquirySignup;
