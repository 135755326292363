import { useEffect, useState } from 'react';

import { Grid, SelectionSet, Button } from '@hometap/htco-components';

import buildProfileLandingImg from 'images/dashboard/build-profile-house.jpg';
import { FINANCING_OPTIONS } from '../../../constants/buildYourProfile';
import './AddFinancingOption.scss';

// @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
const AddFinancingOption = ({ onNext, form }) => {
  const { formData, registerField } = form;
  const { financingOption } = formData;
  const fieldProps = registerField('financingOption');
  const [disableNextButton, setDisableNextButton] = useState(true);

  useEffect(() => {
    setDisableNextButton(!financingOption?.length);
  }, [financingOption]);

  return (
    <Grid
      className="AddFinancingOption"
      container
      rowSpacing={{ xs: 2 }}
      columnSpacing={{ xs: 2 }}
      data-testid="build-profile-debt-selection"
    >
      <Grid sm={12} md={6} className="DashboardBuildProfileLeftPanelWrapper">
        <div className="DashboardBuildProfileLeftPanel">
          <h3>Explore a financing scenario</h3>
          <div className="DashboardBuildProfileSubtitle">
            Choose a financing option to see how it might work for you.
          </div>
          <SelectionSet
            name="financingOption"
            data-testid="financingOption"
            labelWidth="100%"
            options={FINANCING_OPTIONS}
            align="left"
            // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
            onChange={(value, ...args) => {
              fieldProps.onChange(value, ...args);
            }}
            value={financingOption}
          />
          <div className="AddFinancingOptionCTA">
            <Button size="large" disabled={disableNextButton} onClick={() => onNext(2)} data-testid="next-button">
              Next
            </Button>
          </div>
        </div>
      </Grid>
      <Grid
        className="DashboardBuildProfileStepImage"
        sm={12}
        md={6}
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        <img className="DashboardBuildProfileImage" src={buildProfileLandingImg} alt="home-equity" />
      </Grid>
    </Grid>
  );
};

export default AddFinancingOption;
