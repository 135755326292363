import React, { useContext, useState, useCallback } from 'react';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
const Context = React.createContext({});

const useNotificationBannerContext = () => useContext(Context);
export default useNotificationBannerContext;

/**
 * This provider is used to show a notification banner at the top of the page.
 *
 * @function showNotification - function to show the notification banner
 * @param {string | React.ReactNode} title
 * @param {string | React.ReactNode} supportingText
 * @param {string} icon
 * @param {string | React.ReactNode} rightSideContent
 * @param {function} onDismiss
 */
// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const NotificationBannerProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  // @ts-expect-error TS(7006): Parameter 'notification' implicitly has an 'any' t... Remove this comment to see the full error message
  const showNotificationBanner = useCallback(notification => {
    setNotification(notification);
  }, []);

  const handleDismiss = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    notification.onDismiss();
    setNotification(null);
  };

  return (
    <Context.Provider value={{ showNotificationBanner }}>
      {/* @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message */}
      {notification && <NotificationBanner {...notification} onDismiss={handleDismiss} />}
      {children}
    </Context.Provider>
  );
};
