import { usePlacesWidget } from 'react-google-autocomplete';
import { parseGoogleAutocompleteComponents, parseDataToAddressString } from 'utils/googleAutocompleteAddress';
import { useState } from 'react';
import { browserTrack } from 'utils/segment';

// @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
const useGooglePlaceInput = ({ onAddress, flags = [] } = {}) => {
  const [address, setAddress] = useState(undefined);
  const [includesAllAddressComponents, setIncludesAllAddressComponents] = useState(false);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY,
    inputAutocompleteValue: 'off',
    onPlaceSelected: place => {
      const { address_components } = place;
      if (address_components?.length) {
        const addressObj = parseGoogleAutocompleteComponents(place.address_components);
        const addr = parseDataToAddressString(addressObj);

        // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
        if (ref?.current) ref.current.value = addr;

        if (onAddress) onAddress(addr, addressObj);
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setAddress(flags.includes('inquiryAddressUx') ? addressObj.street : addr);

        const includesAllComponents = !Object.values(addressObj).includes('');
        // Check if all address components are filled out
        // If any address component is empty, includesAllAddressComponents will be false
        setIncludesAllAddressComponents(includesAllComponents);
        browserTrack.googleAddressClicked({ full_address_returned: includesAllComponents });
      } else {
        browserTrack.googleAddressClicked({ full_address_returned: false });
      }
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'types'],
    },
  });

  const isAddressValid = (() => {
    // not initialized
    if (!ref?.current) return true;
    // not filled out yet
    // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
    if (!ref.current?.value) return true;
    // filling out currently
    // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
    return ref.current?.value === address;
  })();

  return {
    ref,
    address,
    isValidGoogleAddress: isAddressValid && includesAllAddressComponents,
  };
};

export default useGooglePlaceInput;
