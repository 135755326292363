import TrackDetailsCardLinks from 'components/TrackDetailsCard/TrackDetailsCardLinks';
import TrackDetailsProgressStepper from 'apps/track-details/components/TrackDetailsProgressStepper/TrackDetailsProgressStepper';
import { capitalize } from 'lodash';
import { isTrackFunded } from 'apps/track-details/utils/helpers';
import { currency } from 'utils/numbers';
import { getCurrentUIStageData } from 'components/TrackDetailsCard/uiStageModel';
import TrackDetailsKeyValues from '../TrackDetailsCard/TrackDetailsKeyValues';
import useTrackDetailsCardData from './useTrackDetailsCardData';

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const TrackDetailCardMainContent = ({ track }) => {
  const { config, lastActiveStageIndex, allUIProgressStepperStages, canShowProgressStepper } =
    getCurrentUIStageData(track);

  const { id, friendly_id, investment_amount, membership } = track;
  const cardData = useTrackDetailsCardData(track);
  const { links } = cardData;

  const isPrimaryMember = membership.role === 'primary';
  const showLinksAndAvatarSection = isPrimaryMember && !!links.length;

  const cta = {
    copy: isTrackFunded(track) ? 'View details' : 'Check status',
    link: isPrimaryMember ? `/track/${id}` : `/track/${id}/co-applicant`,
  };

  const { label: timeLabel, date } = cardData.timeStatus;
  const { label: stageLabel, theme } = cardData.badge;

  const trackKeyValues = [
    { key: timeLabel, value: date },
    {
      key: config.investmentAmountLabel,
      value: investment_amount ? currency(investment_amount) : 'More info needed',
    },
  ];

  return (
    <>
      {canShowProgressStepper && (
        <TrackDetailsProgressStepper
          // @ts-expect-error TS(2322): Type '{ isCardView: true; stepIndicatorClassName: ... Remove this comment to see the full error message
          isCardView
          stepIndicatorClassName="!p-1"
          containerClassName="mb-6"
          stepLabelClassName="hidden"
          steps={allUIProgressStepperStages}
          activeIndex={lastActiveStageIndex}
          // @ts-expect-error TS(7006): Parameter 'step' implicitly has an 'any' type.
          tooltipData={step => ({
            content: capitalize(step),
            position: 'bottom',
            action: 'hover',
          })}
        />
      )}

      <TrackDetailsKeyValues
        cta={cta}
        badge={{ label: stageLabel, theme }}
        // @ts-expect-error TS(2322): Type '{ key: any; value: any; }[]' is not assignab... Remove this comment to see the full error message
        keyValues={trackKeyValues}
        header={{ key: 'Investment ID:', value: friendly_id }}
      />

      {/* @ts-expect-error TS(2741): Property 'label' is missing in type '{ links: any;... Remove this comment to see the full error message */}
      {showLinksAndAvatarSection && <TrackDetailsCardLinks links={links} />}
    </>
  );
};

export default TrackDetailCardMainContent;
