import ContactInfo from './contact-info/ContactInfo';

const InvestmentSupportCards = ({ contacts = [] }) => {
  return contacts.map(contact => (
    <ContactInfo
      // @ts-expect-error TS(2339): Property 'friendly_id' does not exist on type 'nev... Remove this comment to see the full error message
      key={contact.friendly_id || contact.email || contact.phone_number}
      // @ts-expect-error TS(2339): Property 'friendly_id' does not exist on type 'nev... Remove this comment to see the full error message
      friendly_id={contact.friendly_id || ''}
      // @ts-expect-error TS(2339): Property 'card_title' does not exist on type 'neve... Remove this comment to see the full error message
      card_title={contact.card_title}
      // @ts-expect-error TS(2339): Property 'contact_information' does not exist on t... Remove this comment to see the full error message
      contact_information={contact.contact_information}
      // @ts-expect-error TS(2339): Property 'card_description' does not exist on type... Remove this comment to see the full error message
      card_description={contact.card_description}
    />
  ));
};

export default InvestmentSupportCards;
