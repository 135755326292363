import { Button, useForm, Tooltip, Icon } from '@hometap/htco-components';

const OnboardingFormWrapper = ({
  // @ts-expect-error TS(7031): Binding element 'Form' implicitly has an 'any' typ... Remove this comment to see the full error message
  form: Form,
  // @ts-expect-error TS(7031): Binding element 'buttons' implicitly has an 'any' ... Remove this comment to see the full error message
  buttons,
  // @ts-expect-error TS(7031): Binding element 'image' implicitly has an 'any' ty... Remove this comment to see the full error message
  image,
  // @ts-expect-error TS(7031): Binding element 'initialFormData' implicitly has a... Remove this comment to see the full error message
  initialFormData,
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031): Binding element 'subtitle' implicitly has an 'any'... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(7031): Binding element 'tooltipContent' implicitly has an... Remove this comment to see the full error message
  tooltipContent,
  // @ts-expect-error TS(7031): Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
  onSubmit,
  // @ts-expect-error TS(7031): Binding element 'header' implicitly has an 'any' t... Remove this comment to see the full error message
  header,
}) => {
  const { registerField, isFormValid, formData, handleSubmit, setErrors } = useForm(initialFormData);

  return (
    <div className="mb-8 flex w-full max-w-screen-lg flex-col bg-white sm:flex-row">
      <div className="w-full md:w-1/2">
        <div className="p-6 sm:p-10 md:p-16">
          <div className="mb-4 text-sm uppercase tracking-wider text-neutral-dark-75">{header}</div>
          {title && (
            <div className="relative my-2 sm:mb-4 sm:mt-0" data-testid="onboarding-form-title">
              <h2 className="mr-2 inline">{title}</h2>
              {tooltipContent && (
                <Tooltip content={tooltipContent} action="hover">
                  <span className="absolute mt-0 sm:mt-1.5">
                    <Icon name="info" size="sm" />
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          {subtitle && <div className="mb-8 leading-6 text-neutral-dark-75 sm:mb-10">{subtitle}</div>}
          <form
            onSubmit={handleSubmit(() => onSubmit({ formData, initialFormData, setErrors }))}
            className="md:max-w-2xl"
          >
            <Form registerField={registerField} formData={formData} />
            <div className="mt-10 flex flex-col items-center justify-center gap-7 xs:flex-row xs:justify-between">
              {/* @ts-expect-error TS(7031): Binding element 'text' implicitly has an 'any' typ... Remove this comment to */}
              {(buttons || []).map(({ text, onClick, isDisabled, type, ...buttonProps }, i) => {
                return (
                  <Button
                    type={type}
                    key={`onboarding-form-wrapper-button-${i}`}
                    className="m-0 w-full xs:w-auto"
                    onClick={() => type !== 'submit' && onClick({ formData })}
                    disabled={isDisabled({ isFormValid })}
                    {...buttonProps}
                  >
                    {text}
                  </Button>
                );
              })}
            </div>
          </form>
        </div>
      </div>

      <div className="flex w-full items-center justify-center md:w-1/2">
        <div className="sm:pl-10 sm:pr-18 md:pl-16 md:pr-26">
          <img className="w-full max-w-[559px]" src={image} alt="home-equity" />
        </div>
      </div>
    </div>
  );
};

export default OnboardingFormWrapper;
