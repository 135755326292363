import { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Badge } from '@hometap/htco-components';
import { getHomeGraphConfig, getFormattedPercent } from './utils';

import HomeGraphHatchOverlay from './HomeGraphHatchOverlay';

export const TEST_ID = {
  HOME_GRAPH_CONTAINER: 'home-graph-container',
  HOME_GRAPH_HOME_VALUE: 'home-graph-home-value',
  HOME_GRAPH_LEGEND_VALUE_ROOT: 'home-graph-legend-root',
  HOME_GRAPH_LEGEND_DESCRIPTION: 'home-graph-legend-description',
  HOME_GRAPH_LEGEND_VALUE_CONTAINER: 'home-graph-legend-value-container',
};

// @ts-expect-error TS(7031): Binding element 'content' implicitly has an 'any' ... Remove this comment to see the full error message
const HomeGraphBottomContent = ({ content, badge, className }) => {
  return (
    <div className={className}>
      <div className="flex items-start">
        <Badge label={badge.label} large theme="primaryBlue100" className="!left-0 max-xs:!text-[10px]" />
      </div>

      {content}
    </div>
  );
};

const HomeGraphLegendItem = ({
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031): Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  value,
  // @ts-expect-error TS(7031): Binding element 'titleClassName' implicitly has an... Remove this comment to see the full error message
  titleClassName,
  // @ts-expect-error TS(7031): Binding element 'bgClassName' implicitly has an 'a... Remove this comment to see the full error message
  bgClassName,
  // @ts-expect-error TS(7031): Binding element 'withOverlay' implicitly has an 'a... Remove this comment to see the full error message
  withOverlay,
  // @ts-expect-error TS(7031): Binding element 'formatter' implicitly has an 'any... Remove this comment to see the full error message
  formatter,
  // @ts-expect-error TS(7031): Binding element 'valueClassName' implicitly has an... Remove this comment to see the full error message
  valueClassName,
  // @ts-expect-error TS(7031): Binding element 'helpText' implicitly has an 'any'... Remove this comment to see the full error message
  helpText,
  // @ts-expect-error TS(7031): Binding element 'helpTextClassName' implicitly has... Remove this comment to see the full error message
  helpTextClassName,
  badgeFormatter = getFormattedPercent,
}) => {
  const badgeProps = {
    label: value.isShareCapped ? 'CAPPED' : badgeFormatter(value.percent),
    theme: value.isShareCapped ? 'primaryBlue100' : 'primaryBlueOutlined',
  };
  return (
    <div className="mb-8 flex last:mb-0">
      <div className="flex items-center" data-testid={TEST_ID.HOME_GRAPH_LEGEND_VALUE_ROOT}>
        <div
          className={cx('relative ml-1 mr-3 mt-px hidden h-4 w-4 self-start rounded xs:block', bgClassName)}
          data-cy="homeGraphLegendIcon"
        >
          {withOverlay && <HomeGraphHatchOverlay height={16} />}
        </div>

        <div data-testid={TEST_ID.HOME_GRAPH_LEGEND_DESCRIPTION}>
          <h4 className={titleClassName}>{title}</h4>
          <div className="flex items-center" data-testid={TEST_ID.HOME_GRAPH_LEGEND_VALUE_CONTAINER}>
            <h3 className={valueClassName}>{formatter(value.dollars)}</h3>
            <Badge label={badgeProps.label} theme={badgeProps.theme} />
          </div>
        </div>
        {helpText && <small className={helpTextClassName}>{helpText}</small>}
      </div>
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'homeownerShare' implicitly has an... Remove this comment to see the full error message
const HomeGraph = ({ homeownerShare, mortgageBalance, hometapShare, homeValue, configOverrides }) => {
  const homeGraphContainerRef = useRef(null);

  const config = getHomeGraphConfig({ homeownerShare, mortgageBalance, hometapShare, homeValue, configOverrides });

  // @ts-expect-error TS(7031): Binding element 'legend' implicitly has an 'any' t... Remove this comment to see the full error message
  const filteredShares = config.graphedShares.filter(({ legend }) => !!legend.value);

  return (
    <div className={config.container.className} data-testid={TEST_ID.HOME_GRAPH_CONTAINER}>
      {config.heading.title.value && (
        <div className={config.heading.className}>
          <h3 className={config.heading.title.className}>{config.heading.title.value}</h3>
          {config.heading.subtitle.value && (
            <p className={config.heading.subtitle.className}>{config.heading.subtitle.value}</p>
          )}
        </div>
      )}
      <div className="flex">
        <div className="hidden w-[50%] max-w-[360px] flex-col xs:flex">
          <div className="mt-1.5 h-[360px] overflow-hidden rounded-md" ref={homeGraphContainerRef}>
            {/* @ts-expect-error TS(7031): Binding element 'graph' implicitly has an 'any' ty... Remove this comment to */}
            {filteredShares.map(({ graph, bgClassName, withOverlay }, index) => {
              return (
                <div
                  data-testid={`home-graph-share-layer-${index}`}
                  key={`home-graph-share-layer-${index}`}
                  style={{ height: graph.height }}
                  className={bgClassName}
                >
                  {withOverlay && <HomeGraphHatchOverlay height="100%" />}
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full xs:ml-8 xs:w-[50%]">
          <div className="mb-9">
            <h4 className={config.legend.title.className}>{config.legend.title.value}</h4>
            <div>
              <div className={config.legend.homeValue.className} data-testid={TEST_ID.HOME_GRAPH_HOME_VALUE}>
                {config.legend.homeValue.formatter(config.legend.homeValue.value)}
              </div>
              {config.legend.subheading.value && (
                <small className={config.legend.subheading.className}>{config.legend.subheading.value}</small>
              )}
            </div>
          </div>
          {/* @ts-expect-error TS(7031): Binding element 'legend' implicitly has an 'any' t... Remove this comment to see */}
          {filteredShares.map(({ legend, bgClassName, withOverlay }, index) => {
            return (
              <HomeGraphLegendItem
                key={`home-graph-legend-${index}`}
                title={legend.title}
                value={legend.value}
                bgClassName={bgClassName}
                withOverlay={withOverlay}
                titleClassName={config.legend.labels.className}
                formatter={config.legend.values.formatter}
                valueClassName={config.legend.values.className}
                // @ts-expect-error TS(2322): Type '{ key: string; title: any; value: any; bgCla... Remove this comment to see the full error message
                badgeProps={legend.badge}
                helpText={legend.helpText}
                helpTextClassName={config.legend.helpText.className}
                badgeFormatter={config.legend.badge.formatter}
              />
            );
          })}
        </div>
      </div>
      {config.bottomContent.isVisible && (
        <HomeGraphBottomContent
          className={config.bottomContent.className}
          content={config.bottomContent.content}
          // @ts-expect-error TS(2322): Type '{ className: any; content: any; tooltip: any... Remove this comment to see the full error message
          tooltip={config.bottomContent.tooltip}
          badge={config.bottomContent.badge}
        />
      )}
    </div>
  );
};

HomeGraph.propTypes = {
  homeownerShare: PropTypes.shape({
    dollars: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  mortgageBalance: PropTypes.shape({
    dollars: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }),
  hometapShare: PropTypes.shape({
    dollars: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    isShareCapped: PropTypes.bool.isRequired,
    crrPercent: PropTypes.number.isRequired,
  }).isRequired,
  homeValue: PropTypes.number.isRequired,
  configOverrides: PropTypes.func,
};

export default HomeGraph;
