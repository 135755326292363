export const homeGraphFootnotesLegendNotes = [
  `To estimate your home value, we use an automated valuation model (AVM). If an AVM is not available, we use the most recent of your beginning home value or last valid AVM, plus appreciation data from the Federal Fair Housing Agency's Home Price Index.`,
  `This is the portion of your home’s value that you own and will keep at settlement. It may include borrowed funds, such as mortgages or other debts on your home.`,
  `This is the portion of your home’s value that Hometap owns, and we’ll keep when you settle your Investment. The Hometap Share is the lesser of the Hometap Percentage or the Hometap Cap.`,
];

export const homeGraphFootnotesGeneral = [
  <p className="font-bold" key="homeGraphFootnotesGeneral">
    For homeowners in bankruptcy: To the extent your original obligation has been discharged, or is subject to an
    automatic stay of bankruptcy under Title 11 of the United States Code, this notice is for compliance and/or
    informational purposes only and DOES NOT CONSTITUTE A DEMAND FOR REPAYMENT OR AN ATTEMPT TO IMPOSE PERSONAL
    LIABILITY FOR SUCH OBLIGATION.
  </p>,
  <>
    If you have any questions about this statement or wish to obtain a formal settlement amount, send an email to{' '}
    <a className="font-bold" href="mailto:homeowners@hometap.com">
      homeowners@hometap.com
    </a>{' '}
    or give us a call at <span className="font-bold">(617) 604-6985</span> or{' '}
    <span className="font-bold">(855) 223-3144 (toll free)</span> Monday through Friday, 9am - 5pm ET.
  </>,
];
