import axios from 'axios';

import { Button } from '@hometap/htco-components';

import sentry from 'utils/sentry';
import { showNotification } from 'utils/toasts';
import cx from 'classnames';
import PropTypes from 'prop-types';

const DownloadFileButton = ({
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(7031): Binding element 'filename' implicitly has an 'any'... Remove this comment to see the full error message
  filename,
  // @ts-expect-error TS(7031): Binding element 'fileURL' implicitly has an 'any' ... Remove this comment to see the full error message
  fileURL,
  // @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  icon = 'download',
  size = 'small',
  theme = 'link',
  ...buttonProps
}) => {
  const downloadFile = async () => {
    const showError = () =>
      // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
      showNotification({
        type: 'error',
        title: 'Failed to download file',
        description: 'Please try refreshing the page and try again.',
      });

    try {
      const { data } = await axios.get(fileURL, { responseType: 'blob' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      showError();
      sentry.logError('Failed to download file for homeowner', {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        extra: { filename, fileURL, errorMessage: e.message },
      });
    }
  };

  return (
    <Button
      className={cx('[&_i]:!font-bold', className)}
      size={size}
      theme={theme}
      icon={icon}
      onClick={downloadFile}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

DownloadFileButton.propTypes = {
  className: PropTypes.string,
  filename: PropTypes.string.isRequired,
  fileURL: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.string,
  buttonProps: PropTypes.object,
};

export default DownloadFileButton;
