import cx from 'classnames';

import './TrackDetailsSidebarIconWrapper.scss';

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const TrackDetailsSidebarIconWrapper = ({ className, children, isDisabled }) => {
  return <span className={cx('TrackDetailsSidebarIconWrapper', className, { isDisabled })}>{children}</span>;
};

export default TrackDetailsSidebarIconWrapper;
