import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, SelectionSet } from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { SCENARIO_PERIOD_OPTIONS } from 'apps/dashboard/constants';
import { getDateDifference } from 'utils/date';
import { currency, percent } from 'utils/numbers';
import { CONFIRM_DEBTS, LIEN_TYPE_SELECTIONS, MAX_LIENS } from 'apps/dashboard/constants/buildYourProfile';
import buildProfileLandingImg from 'images/dashboard/build-profile-house-3.jpg';
import { HOMEOWNER_DASHBOARD, getNewDashboardUrl } from 'utils/links';
import './ConfirmDebts.scss';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';
import BuildProfileFlowLayout from './BuildProfileFlowLayout';

const ConfirmDebts = ({
  // @ts-expect-error TS(7031): Binding element 'instance' implicitly has an 'any'... Remove this comment to see the full error message
  instance,
  // @ts-expect-error TS(7031): Binding element 'setCurStage' implicitly has an 'a... Remove this comment to see the full error message
  setCurStage,
  // @ts-expect-error TS(7031): Binding element 'showCTA' implicitly has an 'any' ... Remove this comment to see the full error message
  showCTA,
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031): Binding element 'alternateDescription' implicitly ... Remove this comment to see the full error message
  description: alternateDescription,
  // @ts-expect-error TS(7031): Binding element 'onSingleLienEdit' implicitly has ... Remove this comment to see the full error message
  onSingleLienEdit,
  // @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
  form,
}) => {
  const { formData, updateFormData } = form;
  const [confirming, setConfirming] = useState(false);
  const { home } = useCurrentHome();
  const { currentScenario, deleteLien, getEquityScenarioLiensAsync } = useEquityScenarioContext();
  // @ts-expect-error TS(2339): Property 'investment' does not exist on type '{}'.
  const { investment } = useHedInvestmentContext();
  const { removeDelayLocalStorageKey } = useDelayHomeEquityProfile();
  const location = useLocation();
  const navigate = useNavigate();

  const homeMutation = useCurrentHomeMutation();

  // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;
  // @ts-expect-error TS(2339): Property 'results' does not exist on type '{}'.
  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;

  useEffect(() => {
    const getLiens = async () => {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  const updateDebtsConfirmed = async () => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    removeDelayLocalStorageKey(home?.id);
    // @ts-expect-error TS(2345): Argument of type '{ hed_debts_confirmed: boolean; ... Remove this comment to see the full error message
    await homeMutation.mutateAsync({ hed_debts_confirmed: true });
  };

  const handleConfirm = async () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (home.hed_debts_confirmed) {
      navigate(
        getNewDashboardUrl(
          location.state?.referrer === HOMEOWNER_DASHBOARD.OVERVIEW
            ? HOMEOWNER_DASHBOARD.OVERVIEW
            : HOMEOWNER_DASHBOARD.HOME_EQUITY,
        ),
      );
    } else {
      setConfirming(true);
      await updateDebtsConfirmed();
    }
    setConfirming(false);
  };

  const originationDate = investment?.effective_date;
  const investmentAmount = investment?.investment_amount;
  const maturityDate = investment?.maturity_date;
  const investmentTerm = getDateDifference(originationDate, maturityDate, 'years');

  // @ts-expect-error TS(2339): Property 'description' does not exist on type '{ i... Remove this comment to see the full error message
  const { description, cta } = CONFIRM_DEBTS.find(item => item.instance === instance);

  const shouldDisableEdit = () => equityScenarioLiens?.length === MAX_LIENS;

  const renderSelectOptions = () => {
    const options = [
      {
        icon: 'icon-house-value',
        label: 'Add debts manually',
        value: 'add-manually',
        description: 'Select each lien type on your property, provide the details, and we’ll take it from there.',
        disabled: shouldDisableEdit(),
      },
    ];
    return options;
  };

  // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
  const onSingleLienDelete = async lien => {
    // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
    updateFormData({ ...formData, liens: formData.liens.filter(item => item !== lien.lien_type) });
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
    await deleteLien(currentScenario?.id, lien.id);
    // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
    await getEquityScenarioLiensAsync?.execute(home?.id, currentScenario?.id);
  };

  return (
    <BuildProfileFlowLayout stepImage={buildProfileLandingImg} stepImageAlt="home-equity">
      <h3>{title}</h3>
      <span className="mt-2 inline-block text-sm text-neutral-dark-75 sm:text-base" data-testid="debt-confirm-subtitle">
        {alternateDescription || description}
      </span>
      <ul className="m-6 mb-[14px] ml-4 list-outside list-disc text-sm sm:text-base" data-testid="debt-confirm-list">
        {investment && (
          <li className="mb-4 marker:text-green-medium">
            <div className="ml-1 flex flex-wrap gap-x-2 text-neutral-dark-100" data-testid="debt-confirm-item">
              <b>Home Equity Investment: </b>
              <div>
                {investmentTerm} years, {currency(investmentAmount)}
              </div>
            </div>
          </li>
        )}
        {equityScenarioLiens?.length > 0 &&
          // @ts-expect-error TS7006
          equityScenarioLiens?.map(lien => {
            const lienDict = LIEN_TYPE_SELECTIONS.find(l => l.value === lien.lien_type);
            const term = SCENARIO_PERIOD_OPTIONS.find(l => l.value === lien.lien_kind);
            return (
              <li className="mb-4 marker:text-green-medium" key={`${lien.lien_type}`}>
                <div className="ml-1 flex flex-wrap items-baseline gap-x-3 gap-y-1">
                  <div className="flex flex-wrap gap-x-2 text-neutral-dark-100" data-testid="debt-confirm-item">
                    <b>{lienDict?.label}: </b>
                    <div>
                      {term && `${term?.label}, `}
                      {percent(lien.rate, '0.[000]')}, {currency(lien.estimated_lien_balance)},{' '}
                      {currency(lien.monthly_payment)}{' '}
                    </div>
                  </div>
                  <div className="flex" data-testid="debt-confirm-actions">
                    <Button
                      className="border-l-0 text-sm sm:text-base [&.htco-Button]:py-0"
                      theme="link"
                      data-testid="debt-confirm-edit"
                      onClick={() => onSingleLienEdit(lien)}
                    >
                      Edit
                    </Button>
                    <div className="mx-3 my-auto h-4 w-[1px] bg-neutral-light-100" />
                    <Button
                      className="text-sm sm:text-base [&.htco-Button]:py-0"
                      theme="link"
                      data-testid="debt-confirm-delete"
                      onClick={() => onSingleLienDelete(lien)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
      {showCTA && (
        <Button loading={confirming} onClick={() => handleConfirm()} data-testid="debt-confirm-dashboard">
          {cta}
        </Button>
      )}
      <div className="mb-8 mt-8 flex items-center justify-between gap-4 text-sm uppercase tracking-[1px] text-neutral-dark-75">
        <div className="flex-grow border-0 border-t border-solid border-neutral-light-100" />
        <div className="text-nowrap">Have another debt to add?</div>
        <div className="flex-grow border-0 border-t border-solid border-neutral-light-100" />
      </div>
      <SelectionSet
        align="left"
        labelWidth="100%"
        name="confirmDebtCTA"
        onChange={() => {
          updateFormData({
            ...formData,
            // @ts-expect-error TS7006
            liens: equityScenarioLiens?.map(lien => lien.lien_type),
          });
          setCurStage(2);
        }}
        options={renderSelectOptions()}
      />
    </BuildProfileFlowLayout>
  );
};

export default ConfirmDebts;
