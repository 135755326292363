import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useCurrentUser from 'hooks/useCurrentUser';
import { getPortalsUrl } from 'utils/links';
import ProgressiveInquiryUI from './components/ProgressiveInquiryUI';
import { PROGRESSIVE_STEP_NAMES, PROGRESSIVE_UI_STEPS } from './constants/progressiveInquiryConstants';
import { TRUST_PILOT_SCRIPT } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';

const RedirectWithSearch = () => {
  const location = useLocation();
  return (
    <Navigate
      replace
      to={{
        pathname: PROGRESSIVE_UI_STEPS[PROGRESSIVE_STEP_NAMES.PROPERTY],
        search: location.search,
      }}
    />
  );
};

const ProgressiveInquiryController = () => {
  // @ts-expect-error TS(2339): Property 'isStaff' does not exist on type '{}'.
  const { isStaff } = useCurrentUser();

  if (isStaff) {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = getPortalsUrl('/staff/');
    return null;
  }

  return (
    <>
      <Helmet>{TRUST_PILOT_SCRIPT}</Helmet>
      <Routes>
        <Route path="info/*" element={<ProgressiveInquiryUI />} />
        <Route path="*" element={<RedirectWithSearch />} />
      </Routes>
    </>
  );
};

export default ProgressiveInquiryController;
