import cx from 'classnames';

import './PullQuote.scss';

// @ts-expect-error TS(7031): Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const PullQuote = ({ text, name, title, type = 'big' }) => {
  return (
    <div className={cx('PullQuote', type === 'small' && 'isSmallQuote')}>
      <div className={cx('PullQuoteText', type === 'small' && 'isSmallText')}>{text}</div>
      <div className="PullQuoteName">{name}</div>
      <div className="PullQuoteTitle">{title}</div>
    </div>
  );
};

export default PullQuote;
