import { useEffect } from 'react';

import { Paper, Button, useBoolState } from '@hometap/htco-components';
import { getDashboardUrl } from 'utils/links';
import { scrollTop } from 'utils/domScripts';
import { formatFullName } from 'utils/stringFormatters';

import CoApplicantConsentForm from './CoApplicantConsentForm';
import {
  mapApplicantKeysAndValues,
  mapApplicantKeysAndValuesV2,
  mapApplicantV2DerogatoryData,
} from '../data/coApplicantUtils';

// @ts-expect-error TS(7031): Binding element 'applicant' implicitly has an 'any... Remove this comment to see the full error message
const CoApplicantSummaryHeader = ({ applicant, shouldShowThankYouMessage }) => {
  return shouldShowThankYouMessage ? (
    <Paper pad={4} className="CoApplicantSummaryHeader">
      <h3>Thank you!</h3>
      <p>
        Your Investment Manager will follow up with {applicant.primary_applicant_first_name} on next steps for your
        Application.
      </p>
    </Paper>
  ) : (
    <Paper pad={4} className="CoApplicantSummaryHeader">
      <h3>Please review the following information</h3>
      <p>
        Take a look at the details below and make sure everything looks good to you. Have any questions?{' '}
        {applicant.investment_manager?.scheduling_url ? (
          <Button theme="link" href={applicant.investment_manager.scheduling_url}>
            Connect with your Investment Manager now.
          </Button>
        ) : (
          <Button theme="link" href="https://hometap.com/contact-us">
            Contact us.
          </Button>
        )}
      </p>
    </Paper>
  );
};

// @ts-expect-error TS(7031): Binding element 'applicant' implicitly has an 'any... Remove this comment to see the full error message
const CoApplicantFields = ({ applicant, fieldMapFn }) => {
  const formattedData = fieldMapFn(applicant);
  return (
    <div className="CoApplicantSummaryDataContainer">
      {/* @ts-expect-error TS(7031): Binding element 'heading' implicitly has an 'any' ... Remove this comment to see the */}
      {formattedData.map(({ heading, value }) => {
        return (
          <div className="CoApplicantSummaryData" key={heading}>
            <p className="CoApplicantSummaryDataHeading">{heading}</p>
            <p className="CoApplicantSummaryDataValue">{value || '-'}</p>
          </div>
        );
      })}
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'applicant' implicitly has an 'any... Remove this comment to see the full error message
const CoApplicantSummary = ({ applicant, onConsentSuccess }) => {
  const hasGivenConsent = useBoolState(applicant?.consent_given);
  const applicationIsV2 = applicant?.application_version === 'v2';
  const formattedApplicantFullName = formatFullName(applicant);

  useEffect(() => {
    scrollTop();
  }, [hasGivenConsent.value]);

  return (
    <div>
      <div>
        {
          // using the fetched value will allow the thank you message to appear only after initial form submission
          // since this will still be false in until the applicant response is refreshed
          !applicant.consent_given && (
            <CoApplicantSummaryHeader
              applicant={applicant}
              shouldShowThankYouMessage={applicant.consent_given !== hasGivenConsent.value}
            />
          )
        }
      </div>
      <Paper pad={4} className="CoApplicantSummary">
        <h3>Co-applicant Summary</h3>
        <div>
          <h4 className="CoApplicantSummaryFullName">{formattedApplicantFullName}</h4>
          <hr></hr>

          {applicationIsV2 ? (
            <CoApplicantFields applicant={applicant} fieldMapFn={mapApplicantKeysAndValuesV2} />
          ) : (
            <CoApplicantFields applicant={applicant} fieldMapFn={mapApplicantKeysAndValues} />
          )}
        </div>

        {applicationIsV2 && (
          <div>
            <b>In the past 10 years, have you ever...</b>
            <CoApplicantFields applicant={applicant} fieldMapFn={mapApplicantV2DerogatoryData} />
          </div>
        )}

        {!hasGivenConsent.value && (
          <CoApplicantConsentForm
            applicant={applicant}
            onConsentSuccess={async () => {
              await onConsentSuccess();
              hasGivenConsent.setTrue();
            }}
          />
        )}
      </Paper>

      {hasGivenConsent.value && (
        <Button href={getDashboardUrl()} theme="secondary" size="small" className="CoApplicantSummaryBackToDashboard">
          Go to My Dashboard
        </Button>
      )}
    </div>
  );
};

export default CoApplicantSummary;
