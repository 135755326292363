import useApplicationContext from '../hooks/useApplicationContext';
import BoolField from '../components/BoolField/BoolField';

import { APPLICATION_FORM_FIELD, GET_PROPERTY_DEMO_DATA } from '../constants/formFields';
import DemoButton from 'components/DemoButton/DemoButton';

const PropertyTitleFormFields = () => {
  // @ts-expect-error TS(2339): Property 'registerApplicationFormField' does not e... Remove this comment to see the full error message
  const { registerApplicationFormField, handleApplicationFormFieldChange } = useApplicationContext();

  // @ts-expect-error TS(7006): Parameter 'demoData' implicitly has an 'any' type.
  const handleDemoClick = demoData => {
    for (const field in demoData) {
      handleApplicationFormFieldChange(demoData[field], field);
    }
  };

  return (
    <div className="ApplicationInfoFormFields">
      <BoolField
        labelWidth="100%"
        labelGrow={false}
        align="left"
        required
        baseRegisterField={registerApplicationFormField(APPLICATION_FORM_FIELD.isOwnedByTrust)}
      />
      {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ demoDat... Remove this comment to see the full error message */}
      <DemoButton demoData={GET_PROPERTY_DEMO_DATA} onClickAction={handleDemoClick} />
    </div>
  );
};

export default PropertyTitleFormFields;
