import { currency, percent } from 'utils/numbers';
import { addToDateString, convertDateStringToHumanReadable } from 'utils/date';

export const investmentTerms = [
  {
    title: 'Beginning home value',
    // @ts-expect-error TS(7006): Parameter 'statement' implicitly has an 'any' type... Remove this comment to see the full error message
    value: statement => statement && currency(statement.investment.beginning_home_value),
    testId: 'quarterly_statement_beginning_home_value',
  },
  {
    title: 'Investment amount',
    // @ts-expect-error TS(7006): Parameter 'statement' implicitly has an 'any' type... Remove this comment to see the full error message
    value: statement => statement && currency(statement.investment.investment_amount),
    testId: 'quarterly_statement_investment_amount',
  },
  {
    title: 'Effective date',
    // @ts-expect-error TS(7006): Parameter 'statement' implicitly has an 'any' type... Remove this comment to see the full error message
    value: statement => statement && convertDateStringToHumanReadable(statement.investment.effective_date, true),
    testId: 'quarterly_statement_effective_date',
  },
  {
    title: 'Expiration date',
    // @ts-expect-error TS(7006): Parameter 'statement' implicitly has an 'any' type... Remove this comment to see the full error message
    value: statement =>
      statement &&
      convertDateStringToHumanReadable(addToDateString(statement.investment.effective_date, 10, 'y'), true),
    testId: 'quarterly_statement_expiration_date',
  },
];
export const hometapPercentagesTerms = [
  {
    // @ts-expect-error TS(7006): Parameter 'statement' implicitly has an 'any' type... Remove this comment to see the full error message
    title: statement => statement && `${currency(statement.investment.beginning_home_value)} or more`,
    // @ts-expect-error TS(7006): Parameter 'shareFraction' implicitly has an 'any' ... Remove this comment to see the full error message
    value: shareFraction => shareFraction && percent(shareFraction.share_up_fraction, '0.0[00]'),
    testId: 'quarterly_statement_ht_appreciation_percent_date',
  },
  {
    // @ts-expect-error TS(7006): Parameter 'statement' implicitly has an 'any' type... Remove this comment to see the full error message
    title: statement => statement && `Less than ${currency(statement.investment.beginning_home_value)}`,
    // @ts-expect-error TS(7006): Parameter 'shareFraction' implicitly has an 'any' ... Remove this comment to see the full error message
    value: shareFraction => shareFraction && percent(shareFraction.share_down_fraction, '0.0[00]'),
    testId: 'quarterly_statement_ht_depreciation_percent_date',
  },
];
