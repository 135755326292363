import { Dropdown } from '@hometap/htco-components';
import { Link } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import { LogOutLink } from 'components/LogOutLink/LogOutLink';

const AccountDropdown = () => {
  const options = [
    {
      component: (
        <Link to={`/${HOMEOWNER_DASHBOARD.PROFILE}`} className="htco- hover:no-underline">
          Account settings
        </Link>
      ),
    },
    {
      component: (
        <Link to={`/${HOMEOWNER_DASHBOARD.SUPPORT}`} className="htco- hover:no-underline">
          Get help
        </Link>
      ),
    },
    {
      component: <LogOutLink className="htco- hover:no-underline" />,
    },
  ];

  const configuredOptions = options.reduce(
    (acc, option, index) =>
      // @ts-expect-error TS(2769): No overload matches this call.
      option.visible ?? true
        ? [
            ...acc,
            {
              optionLabel: option.component,
              selectedLabel: '',
              value: index,
            },
          ]
        : acc,
    [],
  );

  return (
    <div
      className="inline-block hover:text-blue-100 active:text-blue-100 [&_.htco-DropdownSelectedInfoLabel]:mr-0 [&_.htco-DropdownSelectedInfoLabel]:font-bold [&_.htco-DropdownSelectedInfoLabel]:text-neutral-dark-100 [&_.htco-DropdownSelectedInfoLabel]:hover:text-blue-100 [&_.htco-isActive_.htco-DropdownSelectedInfoLabel]:text-blue-100 [&_a]:inline-block [&_a]:w-[220px] [&_a]:px-3 [&_a]:py-4 [&_a]:!text-neutral-dark-100 [&_a]:no-underline [&_li]:p-0"
      data-testid="header-nav-account-dropdown"
    >
      <Dropdown label="Account" options={configuredOptions} onChange={() => null} />
    </div>
  );
};

export default AccountDropdown;
