import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FinanceOptionDetails from './FinanceOptionDetails';
import Footer from 'components/Footer/Footer';
import { FINANCE_CALCULATOR_TYPE, FINANCE_OPTIONS, FINANCE_OPTIONS_OVERVIEW } from './data/financeData';
import { getNewInquiryUrl } from 'utils/links';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import { HOMEOWNER_DASHBOARD } from 'utils/links';

import { useAsync } from '@hometap/htco-components';
import { createAndFetchShareFractions } from 'apps/rundowns/data/rundownRequests';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import './FinanceOptionsController.scss';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import useProgressBar from 'hooks/useProgressBar';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';

/*
  This is the order of children components (HomeEquityController is parent):
    - FinanceOptionsController
    - FinanceOptionDetails
    - FinanceCalculator
    - SelectedFinanceCalculator
*/

const FinanceOptionsController = () => {
  const { home } = useCurrentHome();
  const { currentScenario, futureScenario, createFutureScenario, addLien, updateLien } = useEquityScenarioContext();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist o... Remove this comment to see the full error message
  const { displayedHomeValuation } = useHomeValuation();
  // @ts-expect-error TS(2339): Property 'appreciation_rate' does not exist on typ... Remove this comment to see the full error message
  const curAppreciationRate = Math.round(Number(currentScenario?.appreciation_rate * 100));
  const navigate = useNavigate();
  const [loanDetails, setLoanDetails] = useState({});
  const { execute: fetchFractions } = useAsync(createAndFetchShareFractions);
  const { showTappableEquityCard } = useFlags();
  const { prequalRange } = useHedPrequalRange();

  // Prevent the ability to spam requests and create multiple liens
  const [loadingRequests, setLoadingRequests] = useState(false);

  const financeData = useMemo(() => {
    return {
      [FINANCE_CALCULATOR_TYPE.HEI]: {
        estimatedHomeValue: displayedHomeValuation?.value,
        // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
        tappableAmount: prequalRange?.max,
        // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
        tappableAmountMax: prequalRange?.max,
        // @ts-expect-error TS(2339): Property 'min' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
        tappableAmountMin: prequalRange?.min,
        term: 10,
        appreciationRate: curAppreciationRate,
        averageInterestRate: 0,
        monthlyPayment: 0,
      },
      [FINANCE_CALCULATOR_TYPE.HELOC]: {
        estimatedHomeValue: displayedHomeValuation?.value,
        // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
        loanAmount: prequalRange?.max,
        averageInterestRate: 0,
        term: 10,
      },
      [FINANCE_CALCULATOR_TYPE.HEL]: {
        estimatedHomeValue: displayedHomeValuation?.value,
        // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
        loanAmount: prequalRange?.max,
        averageInterestRate: 0,
        term: 10,
      },
    };
    // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
  }, [curAppreciationRate, displayedHomeValuation?.value, prequalRange?.max, prequalRange?.min]);

  const { hasTappableEquity } = useAccessHomeEquityCTA();
  const [financeOptions, setFinanceOptions] = useState(FINANCE_OPTIONS);

  const [selectedFinanceOption, setSelectedFinanceOption] = useState(financeOptions[0]);
  const [fractions, setFractions] = useState([]);
  const [curTappableAmount, setCurTappableAmount] = useState();
  const [curValue, setCurValue] = useState();
  const location = useLocation();

  // If a user is eligible for HEI update the finance options to include the HEI calculator
  // @ts-expect-error TS(2345): Argument of type '() => () => null' is not assigna... Remove this comment to see the full error message
  useEffect(() => {
    if (hasTappableEquity && showTappableEquityCard) {
      setFinanceOptions(FINANCE_OPTIONS);
    } else {
      setFinanceOptions(FINANCE_OPTIONS.filter(option => option.value !== FINANCE_CALCULATOR_TYPE.HEI));
    }

    return () => null;
  }, [showTappableEquityCard, hasTappableEquity]);

  // When the list of finance options is updated, select the first option
  // @ts-expect-error TS(2345): Argument of type '() => () => null' is not assigna... Remove this comment to see the full error message
  useEffect(() => {
    const { option } = location.state || {};
    setSelectedFinanceOption(financeOptions.find(lien => lien?.value === option) ?? financeOptions[0]);

    return () => null;
  }, [financeOptions, location.state]);

  const [calculatorData, setCalculatorData] = useState(financeData[selectedFinanceOption?.value]);

  useEffect(() => {
    setCalculatorData(financeData[selectedFinanceOption?.value]);
  }, [financeData, selectedFinanceOption?.value]);

  // @ts-expect-error TS(7006): Parameter 'selectedOption' implicitly has an 'any'... Remove this comment to see the full error message
  const handleSelectFinanceOption = selectedOption => {
    setSelectedFinanceOption(selectedOption);
    setCalculatorData(financeData[selectedOption?.value] || {});
  };

  const handleGetPreQualified = () => {
    //  handle Getting pre-qualified
    navigate(getNewInquiryUrl());
  };

  // @ts-expect-error TS(2345): Argument of type '() => () => null' is not assigna... Remove this comment to see the full error message
  useEffect(() => {
    const hasNewHomeValuation = displayedHomeValuation?.value !== curValue;
    const hasNewTappableAmount = curTappableAmount !== calculatorData?.tappableAmount;
    const needsNewShareFractions = hasNewHomeValuation || hasNewTappableAmount;
    const isHEI = selectedFinanceOption?.value === FINANCE_CALCULATOR_TYPE.HEI;
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (needsNewShareFractions && isHEI && home.operationalState && calculatorData?.tappableAmount) {
      async function fetchData() {
        const newFractions = await fetchFractions(displayedHomeValuation?.value, calculatorData?.tappableAmount);
        setFractions(newFractions);
        setCurTappableAmount(calculatorData?.tappableAmount);
        setCurValue(displayedHomeValuation?.value);
      }
      fetchData();
    }

    return () => null;
  }, [
    calculatorData,
    curTappableAmount,
    curValue,
    fetchFractions,
    displayedHomeValuation?.value,
    selectedFinanceOption?.value,
    home,
  ]);

  const handleAddToEquityChart = async () => {
    setLoadingRequests(true);

    const financeMapping = {
      HEL: 'home_equity_loan',
      HELOC: 'home_equity_line_of_credit',
      HEI: 'home_equity_investment',
    };
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const lienType = financeMapping[selectedFinanceOption.value];
    let closestFraction;
    if (lienType === 'home_equity_investment') {
      // Filter the array to only contain objects where range_years_upper is less than or equal to term.
      // @ts-expect-error TS(2339): Property 'range_years_upper' does not exist on typ... Remove this comment to see the full error message
      const validFractions = fractions.filter(f => f.range_years_upper <= calculatorData?.term);

      // Select the object with the highest range_years_upper value.
      closestFraction = validFractions.reduce(
        (prev, curr) => {
          // @ts-expect-error TS(2339): Property 'range_years_upper' does not exist on typ... Remove this comment to see the full error message
          return prev.range_years_upper > curr.range_years_upper ? prev : curr;
        },
        { range_years_upper: -Infinity },
      );
    }

    const data = {
      // NON-HEI
      lien_type: lienType,
      last_known_current_balance:
        lienType !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? calculatorData?.loanAmount : null,
      rate: lienType !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? calculatorData?.averageInterestRate : null,
      monthly_payment:
        // @ts-expect-error TS(2339): Property 'monthlyPayment' does not exist on type '... Remove this comment to see the full error message
        lienType !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? loanDetails?.monthlyPayment : null,
      // HEI
      original_balance:
        lienType === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? calculatorData?.tappableAmount : null,
      share_up_fraction:
        // @ts-expect-error TS(2339): Property 'share_up_fraction' does not exist on typ... Remove this comment to see the full error message
        lienType === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? closestFraction?.share_up_fraction : null,
      share_down_fraction:
        // @ts-expect-error TS(2339): Property 'share_down_fraction' does not exist on t... Remove this comment to see the full error message
        lienType === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? closestFraction?.share_down_fraction : null,
      origination_date:
        lienType === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? new Date().toISOString().slice(0, 10) : null,
      term_length_in_months:
        lienType === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT ? calculatorData?.term * 12 : null,
    };

    let createdFutureScenario;
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    if (!futureScenario?.id) {
      createdFutureScenario = await createFutureScenario();
    }

    // @ts-expect-error TS(1345): An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
    const scenario = createdFutureScenario ? createdFutureScenario : futureScenario;

    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    const lien = scenario?.liens?.find(lien => lien.lien_type === lienType);
    if (lien) {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 3.
      await updateLien(scenario, lien.id, data);
    } else {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
      await addLien(scenario, data);
    }

    navigate(`/${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`);
    setLoadingRequests(false);
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const getLendingTreeUrl = () => FINANCE_OPTIONS_OVERVIEW[selectedFinanceOption.value].href;

  // @ts-expect-error TS(2345): Argument of type '{ currStep: number; steps: numbe... Remove this comment to see the full error message
  useProgressBar({ currStep: 3, steps: 3 });

  return (
    <PrimaryLayoutGrid className="FinanceOptionsController">
      {/* @ts-expect-error TS(2739): Type '{ children: Element[]; loading: any; }' is missing the following properties from type */}
      <SectionLoadingWrapper loading={!home}>
        <div>
          <FinanceOptionDetails
            calculatorData={calculatorData}
            financeOptions={financeOptions}
            selectedFinanceOption={selectedFinanceOption}
            setCalculatorData={setCalculatorData}
            setSelectedFinanceOption={handleSelectFinanceOption}
            onGetPreQualified={handleGetPreQualified}
            onAddToEquityChart={handleAddToEquityChart}
            isRequestLoading={loadingRequests}
            loanDetails={loanDetails}
            setLoanDetails={setLoanDetails}
            // @ts-expect-error TS(2339): Property 'hed_financial_goal' does not exist on type 'never'.
            financialGoal={home?.hed_financial_goal}
          />
          <div className="FinanceOptionsLegal">
            {selectedFinanceOption.value === FINANCE_CALCULATOR_TYPE.HEI ? (
              'This calculator is for illustrative purposes only and assumes a well-qualified homeowner. All Applications are subject to Hometap’s then-current underwriting criteria. Application terms and eligibility requirements are subject to change.'
            ) : (
              <>
                Qualification data source:{' '}
                <a href={getLendingTreeUrl()} target="_blank" rel="noreferrer">
                  LendingTree
                </a>
              </>
            )}
          </div>
        </div>
        {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the */}
        <Footer />
      </SectionLoadingWrapper>
    </PrimaryLayoutGrid>
  );
};

export default FinanceOptionsController;
