import { STAGES } from 'apps/dashboard/constants';
import { isDateAfterOtherDate } from './date';

const SCENARIOS = {
  NO_HOME_VALUATION: 'NoHomeValuation',
  TRACK_SETTLED: 'TrackSettled',
  TRACK_CLOSED_LOST: 'TrackClosedLost',
  TRACK_INVESTED: 'TrackInvested',
  SELF_REPORTED_HOME_VALUATION: 'SelfReportedHomeValuation',
  PHYSICAL_APPRAISAL_HOME_VALUATION: 'PhysicalAppraisalHomeValuation',
  AVM_HOME_VALUATION_WITH_TRACKS: 'AvmHomeValuation',
  AVM_HOME_VALUATION_NO_TRACKS: 'AvmHomeValuationNoTracks',
  DEFAULT: 'Default',
};

// @ts-expect-error TS(7031): Binding element 'homeValuation' implicitly has an ... Remove this comment to see the full error message
export const getDisclaimerCopy = ({ homeValuation, hasTracks, track }) => {
  // If a track is settled and we later get an AVM value, we want to display a different disclaimer for the AVM value
  const isAvmPostSettlement =
    track?.current_stage === STAGES.SETTLED &&
    homeValuation?.is_avm &&
    isDateAfterOtherDate(homeValuation?.as_of_date, track?.settlement_date);

  const getScenario = () => {
    if (!homeValuation) return SCENARIOS.NO_HOME_VALUATION;
    if (track) {
      if (track.current_stage === STAGES.SETTLED)
        return isAvmPostSettlement ? SCENARIOS.AVM_HOME_VALUATION_WITH_TRACKS : SCENARIOS.TRACK_SETTLED;
      if (track.current_stage === STAGES.CLOSED_LOST) return SCENARIOS.TRACK_CLOSED_LOST;
      if (track.current_stage === STAGES.INVESTED) return SCENARIOS.TRACK_INVESTED;
    }
    if (homeValuation.home_valuation_type === 'SelfReportedHomeValuation')
      return SCENARIOS.SELF_REPORTED_HOME_VALUATION;
    if (homeValuation.home_valuation_type === 'PhysicalAppraisalHomeValuation')
      return SCENARIOS.PHYSICAL_APPRAISAL_HOME_VALUATION;
    if (homeValuation.is_avm && hasTracks) return SCENARIOS.AVM_HOME_VALUATION_WITH_TRACKS;
    if (homeValuation.is_avm) return SCENARIOS.AVM_HOME_VALUATION_NO_TRACKS;
    return SCENARIOS.DEFAULT;
  };

  const scenario = getScenario();

  const copy = {
    [SCENARIOS.NO_HOME_VALUATION]: {
      trackDetailsCard: 'Sit tight — we’ll calculate your home value later in the Investment process',
    },
    [SCENARIOS.TRACK_SETTLED]: {
      trackDetailsCard:
        'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
      stackedEquityGraph:
        'These values are based on your final settlement amount, as reflected in your closing letter, and any other information you have provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
      homeValueTooltip:
        "We’ll use the most recent appraised home value from the Investment process or our automated valuation model (AVM). If current AVM data isn’t available, we’ll use your last valid home value, then calculate any appreciation using data from the Federal Fair Housing Agency's Home Price Index. If you’ve settled your Investment, we’ll use the settlement amount reflected in your closing letter.",
    },
    [SCENARIOS.TRACK_CLOSED_LOST]: {
      trackDetailsCard:
        'These values are based on the last appraisal or AVM value obtained and any other information you have provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
      stackedEquityGraph:
        'These values are based on the last appraisal or AVM value obtained and any other information you have provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
      homeValueTooltip: 'We regularly estimate your home’s value using our automated valuation model (AVM).',
    },
    [SCENARIOS.TRACK_INVESTED]: {
      stackedEquityGraph:
        "To estimate your home value, we use an automated valuation model (AVM). If an AVM is not available, we use the most recent of your beginning home value or last valid AVM, plus appreciation data from the Federal Fair Housing Agency's Home Price Index.",
      trackDetailsCard:
        "To estimate your home value, we use an automated valuation model (AVM). If an AVM is not available, we use the most recent of your beginning home value or last valid AVM, plus appreciation data from the Federal Fair Housing Agency's Home Price Index.",
      homeValueTooltip:
        "We’ll use the most recent appraised home value from the Investment process or our automated valuation model (AVM). If current AVM data isn’t available, we’ll use your last valid home value, then calculate any appreciation using data from the Federal Fair Housing Agency's Home Price Index. If you’ve settled your Investment, we’ll use the settlement amount reflected in your closing letter.",
      equityTooltip:
        'We calculate your current equity by taking the estimated home value, then subtracting Hometap’s Share and the outstanding debt you still have on the property. The data is pulled from your latest home profile details, and this value may change if debts are added or removed from your home profile.',
    },
    [SCENARIOS.SELF_REPORTED_HOME_VALUATION]: {
      trackDetailsCard: 'This number is based on a recent home valuation, or you provided it to Hometap.',
      stackedEquityGraph:
        'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
    },
    [SCENARIOS.PHYSICAL_APPRAISAL_HOME_VALUATION]: {
      trackDetailsCard: 'Your home value was determined by a third-party appraisal company.',
      stackedEquityGraph:
        'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
    },
    [SCENARIOS.AVM_HOME_VALUATION_WITH_TRACKS]: {
      trackDetailsCard:
        'Your home value was determined using Hometap’s Value Blend automated valuation model (AVM), which uses third-party data to calculate your home’s value without an in-person appraisal.',
      stackedEquityGraph:
        'We’ll use the most recent appraised home value from the Investment process or our automated valuation model (AVM). If current AVM data isn’t available, we’ll use your last valid home value, then calculate any appreciation using data from the Federal Fair Housing Agency’s Home Price Index.',
      homeValueTooltip:
        "We’ll use the most recent appraised home value from the Investment process or our automated valuation model (AVM). If current AVM data isn’t available, we’ll use your last valid home value, then calculate any appreciation using data from the Federal Fair Housing Agency's Home Price Index. If you’ve settled your Investment, we’ll use the settlement amount reflected in your closing letter.",
    },
    [SCENARIOS.AVM_HOME_VALUATION_NO_TRACKS]: {
      stackedEquityGraph:
        'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
      trackDetailsCard:
        'Your home value was determined using Hometap’s Value Blend automated valuation model (AVM), which uses third-party data to calculate your home’s value without an in-person appraisal.',
      homeValueTooltip: 'We regularly estimate your home’s value using our automated valuation model (AVM).',
    },
    [SCENARIOS.DEFAULT]: {
      trackDetailsCard: 'Sit tight — we’ll calculate your home value later in the Investment process.',
      stackedEquityGraph:
        'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.',
    },
  };

  return copy[scenario];
};

export default getDisclaimerCopy;
