import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { fetchHomeValuation, updateHome, getSelfReportedHomeValuation } from 'apps/dashboard/data/homeEquityApi';

import { useCurrentHome } from 'hooks/useCurrentHome';

// @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
const useHomeValuationQuery = home => {
  const queryKey = ['home.valuation', home?.id];
  return useQuery({
    queryFn: async () => (await fetchHomeValuation(home?.id)) || {},
    queryKey,
    enabled: !!home,
    // @ts-expect-error TS(2339): Property 'response' does not exist on type 'Error'... Remove this comment to see the full error message
    retry: (_, error) => error?.response?.status !== 404,
  });
};

// @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
const useSelfReportedHomeValuationQuery = home => {
  const queryKey = ['home.self-reported-valuation', home?.id];
  return useQuery({
    queryFn: async () => await getSelfReportedHomeValuation(home.id),
    queryKey,
    enabled: !!home,
  });
};

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
const getUpdatedSelfValuationObj = value => {
  const numberValue = value !== null ? Number(value) : null;
  return { value: numberValue, as_of_date: value ? new Date().toISOString() : null };
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
const updateSelfReportedHomeValuation = async (homeId, value) => {
  const { value: updatedValue, as_of_date } = getUpdatedSelfValuationObj(value);

  return await updateHome(homeId, {
    homeowner_estimated_home_value: updatedValue,
    homeowner_estimated_home_value_as_of: as_of_date,
  });
};

// @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
export const useSelfReportedHomeValuationMutation = home => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async value => await updateSelfReportedHomeValuation(home.id, value),
    onSuccess: updatedSelfValue => {
      // @ts-expect-error TS(2339): Property 'homeowner_estimated_home_value' does not... Remove this comment to see the full error message
      const { value, as_of_date } = getUpdatedSelfValuationObj(updatedSelfValue.homeowner_estimated_home_value);

      queryClient.setQueryData(['home.self-reported-valuation', home?.id], valuationData => {
        const valuation = value
          ? {
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              ...valuationData.valuation,
              value,
              as_of_date,
            }
          : null;

        return {
          valuation,
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          is_editable: valuationData.is_editable,
        };
      });
    },

    onSettled: () => {
      // @ts-expect-error TS(2559): Type 'any[]' has no properties in common with type... Remove this comment to see the full error message
      queryClient.invalidateQueries(['home.self-reported-valuation', home?.id]);
    },
  });
};

const Context = React.createContext({});

export const useHomeValuation = () => React.useContext(Context);

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const HomeValuationProvider = ({ children }) => {
  const { home } = useCurrentHome();

  const homeValuationQuery = useHomeValuationQuery(home);
  const selfReportedValuationQuery = useSelfReportedHomeValuationQuery(home);

  const isLoading = homeValuationQuery.isLoading || selfReportedValuationQuery.isLoading;
  const selfReportedHomeValuation = selfReportedValuationQuery?.data ?? {};
  const homeValuation = homeValuationQuery?.data ?? {};

  // @ts-expect-error TS(2339): Property 'valuation' does not exist on type '{}'.
  const displayedHomeValuation = selfReportedHomeValuation?.valuation
    ? // @ts-expect-error TS(2339): Property 'valuation' does not exist on type '{}'.
      selfReportedHomeValuation.valuation
    : homeValuation;
  return (
    <Context.Provider value={{ homeValuation, selfReportedHomeValuation, displayedHomeValuation, isLoading }}>
      {children}
    </Context.Provider>
  );
};
