import { Container } from '@hometap/htco-components';
import DataList from 'components/DataList/DataList';
import { currency } from 'utils/numbers';

import './RundownCostEstimate.scss';

// @ts-expect-error TS(7031): Binding element 'investmentAmount' implicitly has ... Remove this comment to see the full error message
const RundownCostEstimate = ({ costs = [], investmentAmount }) => {
  // @ts-expect-error TS(7006): Parameter 'cost' implicitly has an 'any' type.
  const formatCostAmount = cost => {
    if (cost.kind === 'percent_investment') {
      return cost.number * investmentAmount;
    }
    return cost.number;
  };

  const costData = costs.map(cost => {
    return {
      // @ts-expect-error TS(2339): Property 'description' does not exist on type 'nev... Remove this comment to see the full error message
      label: cost.description,
      value: currency(-formatCostAmount(cost)),
      // @ts-expect-error TS(2339): Property 'tooltip' does not exist on type 'never'.
      helpTip: cost.tooltip ? { text: cost.tooltip } : null,
    };
  });

  if (investmentAmount) {
    let netCash = investmentAmount;
    costs.forEach(cost => {
      netCash -= formatCostAmount(cost);
    });

    // @ts-expect-error TS(2345): Argument of type '{ label: string; value: any; }' ... Remove this comment to see the full error message
    costData.unshift({
      label: 'Investment Amount',
      value: currency(investmentAmount),
    });

    costData.push({
      label: 'Estimated Net Cash to Homeowner',
      value: currency(netCash),
      // @ts-expect-error TS(2345): Argument of type '{ label: string; value: any; row... Remove this comment to see the full error message
      rowClass: 'RundownCostEstimateTotal',
    });
  }

  return (
    <Container className="RundownCostEstimate" row justify="center">
      <div className="RundownCostEstimateContainer">
        <h3>Cost Estimate</h3>
        <div className="RundownCostEstimateDescription">
          <p>
            Please note that the fees below are for informational purposes only, and final costs are subject to change
            based on your state or county.
          </p>
          <p>All fees are deducted from Investment amount at signing.</p>
        </div>
        {/* @ts-expect-error TS(2322): Type '{ label: any; value: any; helpTip: { text: a... Remove this comment to see the full error message */}
        <DataList className="RundownCostEstimateDataList" data={costData} />
      </div>
    </Container>
  );
};

export default RundownCostEstimate;
