import ApplicationFormWrapper from './ApplicationFormWrapper/ApplicationFormWrapper';

import { useBoolState } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import { checkIsPrimaryApplicant, getInMemoryApplicants } from '../utils';
import { APPLICANT_FORM_FIELD } from '../constants/formFields';
import useApplicationContext from '../hooks/useApplicationContext';

import useTimedRender from 'hooks/useTimedRender';
import ApplicantSaveModal from '../components/modals/ApplicantSaveModal/ApplicantSaveModal';
import ApplicantInProgressWarningModal from '../components/modals/ApplicantInProgressWarningModal/ApplicantInProgressWarningModal';
import SuccessCheckMark from 'components/SuccessCheckMark/SuccessCheckMark';
import useApplicationNavContext from '../hooks/useApplicationNavContext';

import { APPLICATION_MODEL_FIELD } from '../constants/applicationDataFields';

// @ts-expect-error TS(7031): Binding element 'isSavePage' implicitly has an 'an... Remove this comment to see the full error message
const ApplicantFormSectionWrapper = ({ isSavePage, children }) => {
  const params = useParams();
  const applicantId = params.applicantId ?? '';

  const isApplicantWarningModalOpen = useBoolState();
  const isApplicantSaveModalOpen = useBoolState();
  const [shouldRenderSuccessMessage, triggerSuccessMessageRender] = useTimedRender();

  const {
    // @ts-expect-error TS(2339): Property 'applicants' does not exist on type 'unkn... Remove this comment to see the full error message
    applicants,
    // @ts-expect-error TS(2339): Property 'addNewApplicant' does not exist on type ... Remove this comment to see the full error message
    addNewApplicant,
    // @ts-expect-error TS(2339): Property 'getApplicantFormData' does not exist on ... Remove this comment to see the full error message
    getApplicantFormData,
    // @ts-expect-error TS(2339): Property 'application' does not exist on type 'unk... Remove this comment to see the full error message
    application,
    // @ts-expect-error TS(2339): Property 'saveApplicant' does not exist on type 'u... Remove this comment to see the full error message
    saveApplicant,
    // @ts-expect-error TS(2339): Property 'isLoading' does not exist on type 'unkno... Remove this comment to see the full error message
    isLoading,
    // @ts-expect-error TS(2339): Property 'updateApplication' does not exist on typ... Remove this comment to see the full error message
    updateApplication,
  } = useApplicationContext();
  // @ts-expect-error TS(2339): Property 'navigateToNextPage' does not exist on ty... Remove this comment to see the full error message
  const { navigateToNextPage, navigateToNewApplicant, isLastPageInSection } = useApplicationNavContext();

  const currentApplicantFormData = getApplicantFormData(applicantId);

  const hasInMemoryApplicant = getInMemoryApplicants(applicants).length;
  const isPrimaryApplicant = checkIsPrimaryApplicant(currentApplicantFormData, applicants);

  const hasUpdatedPersistedApplicantForm = !!currentApplicantFormData?.dirtyFields && applicantId;
  const isApplicantsSubmissionPage =
    isSavePage && isLastPageInSection && !application[APPLICATION_MODEL_FIELD.hasSubmittedApplicants];
  const shouldSaveApplicant =
    ((isSavePage && !applicantId) || hasUpdatedPersistedApplicantForm) && !isApplicantsSubmissionPage;

  const handleSave = async () => {
    const savedApplicant = await saveApplicant(applicantId);

    if (!savedApplicant) return;

    if (!hasUpdatedPersistedApplicantForm) {
      navigateToNewApplicant({
        applicantId: savedApplicant?.id,
        pageKey: 'other-applicants',
      });
    }

    // @ts-expect-error TS(2349): This expression is not callable.
    return triggerSuccessMessageRender();
  };

  const handleSaveConfirm = () => {
    handleSave();
    return isApplicantSaveModalOpen.setFalse();
  };

  const onProgressButtonClick = async () => {
    if (shouldSaveApplicant) {
      if (!isPrimaryApplicant) {
        return isApplicantSaveModalOpen.setTrue();
      }
      return handleSave();
    }

    if (isApplicantsSubmissionPage) {
      const updatedApplication = await updateApplication({
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
        [APPLICATION_MODEL_FIELD.hasSubmittedApplicants]: true,
      });

      if (!updatedApplication) return;
    }

    if (hasInMemoryApplicant && isLastPageInSection) {
      return isApplicantWarningModalOpen.setTrue();
    }

    if (currentApplicantFormData[APPLICANT_FORM_FIELD.hasOtherApplicants]) {
      addNewApplicant();
      return navigateToNewApplicant();
    }

    navigateToNextPage();
  };

  const isOnApplicantCreatePage = isSavePage && !applicantId;
  let buttonText;
  if (isOnApplicantCreatePage) {
    buttonText = 'Save Applicant';
  } else if (isApplicantsSubmissionPage) {
    buttonText = 'Submit Applicants';
  }

  return (
    <ApplicationFormWrapper
      onProgressButtonClick={onProgressButtonClick}
      buttonText={buttonText}
      isLoading={isLoading}
      isSavePage={shouldSaveApplicant || isApplicantsSubmissionPage}
      buttonDisabled={!currentApplicantFormData?.isCurrentFormValid || isLoading}
      buttonReplace={shouldRenderSuccessMessage && <SuccessCheckMark successText="Saved" />}
    >
      <ApplicantInProgressWarningModal
        applicant={getApplicantFormData()}
        isOpen={isApplicantWarningModalOpen.value}
        onClose={isApplicantWarningModalOpen.setFalse}
      />

      {/* @ts-expect-error TS(2741): Property 'isUpdating' is missing in type '{ applic... Remove this comment to see the full error message */}
      <ApplicantSaveModal
        applicant={currentApplicantFormData}
        isOpen={isApplicantSaveModalOpen.value}
        onClose={isApplicantSaveModalOpen.setFalse}
        onSave={handleSaveConfirm}
      />

      {children}
    </ApplicationFormWrapper>
  );
};

export default ApplicantFormSectionWrapper;
