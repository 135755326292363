import { useParams, Navigate } from 'react-router-dom';
import useApplicationContext from '../hooks/useApplicationContext';
import {
  APPLICATION_CONFIG,
  APPLICATION_SECTION_URL_NAME,
  filterConditionalPages,
  getInMemoryApplicants,
} from '../utils';

import { NotFoundBlock } from '@hometap/htco-components';
import { buildApplicationRoute } from '../utils';
import ApplicantFormSectionWrapper from '../forms/ApplicantFormSectionWrapper';
import ApplicationFormSectionWrapper from '../forms/ApplicationFormSectionWrapper';
import ApplicantDeleteModal from './modals/ApplicantDeleteModal/ApplicantDeleteModal';
import { APPLICANT_FORM_FIELD } from '../constants/formFields';
import { RouterPrompt } from 'apps/progressive-inquiry/components/RouterPrompt';
import useApplicationNavContext from '../hooks/useApplicationNavContext';

const ApplicationPageFromConfig = () => {
  const {
    // @ts-expect-error TS(2339): Property 'track' does not exist on type 'unknown'.
    track,
    // @ts-expect-error TS(2339): Property 'isLoading' does not exist on type 'unkno... Remove this comment to see the full error message
    isLoading,
    // @ts-expect-error TS(2339): Property 'applicants' does not exist on type 'unkn... Remove this comment to see the full error message
    applicants,
    // @ts-expect-error TS(2339): Property 'application' does not exist on type 'unk... Remove this comment to see the full error message
    application,
    // @ts-expect-error TS(2339): Property 'primaryApplicant' does not exist on type... Remove this comment to see the full error message
    primaryApplicant,
    // @ts-expect-error TS(2339): Property 'isInitialLoading' does not exist on type... Remove this comment to see the full error message
    isInitialLoading,
    // @ts-expect-error TS(2339): Property 'refetchApplicants' does not exist on typ... Remove this comment to see the full error message
    refetchApplicants,
    // @ts-expect-error TS(2339): Property 'applicationFormData' does not exist on t... Remove this comment to see the full error message
    applicationFormData,
    // @ts-expect-error TS(2339): Property 'getApplicantFormData' does not exist on ... Remove this comment to see the full error message
    getApplicantFormData,
    // @ts-expect-error TS(2339): Property 'deleteApplicant' does not exist on type ... Remove this comment to see the full error message
    deleteApplicant,
    // @ts-expect-error TS(2339): Property 'removeInMemoryApplicant' does not exist ... Remove this comment to see the full error message
    removeInMemoryApplicant,
    // @ts-expect-error TS(2339): Property 'selectedApplicantForDeletion' does not e... Remove this comment to see the full error message
    selectedApplicantForDeletion,
    // @ts-expect-error TS(2339): Property 'setSelectedApplicantForDeletion' does no... Remove this comment to see the full error message
    setSelectedApplicantForDeletion,
  } = useApplicationContext();
  // @ts-expect-error TS(2339): Property 'currentPageIsDisabled' does not exist on... Remove this comment to see the full error message
  const { currentPageIsDisabled, nextPage, nextAnticipatedPage } = useApplicationNavContext();
  const { page, applicantId, section: urlSection } = useParams();

  const section = urlSection ?? APPLICATION_SECTION_URL_NAME.applicants;

  const currentApplicantFormData = getApplicantFormData(applicantId);
  const applicantToDeleteFormData = getApplicantFormData(selectedApplicantForDeletion?.id);

  if (!APPLICATION_CONFIG[section]) return <NotFoundBlock />;

  const config = filterConditionalPages(APPLICATION_CONFIG[section], applicationFormData);
  const hasInMemoryApplicant = getInMemoryApplicants(applicants).length;
  const isApplicantSection = section === APPLICATION_SECTION_URL_NAME.applicants;
  const isPropertySection = section === APPLICATION_SECTION_URL_NAME.property;

  // Handle redirects for missing section, page or applicantId
  const firstPageKey = config[0]?.pageKey;
  const trackRoute = buildApplicationRoute(track?.id);
  if (!urlSection || !page || (!applicantId && !hasInMemoryApplicant && isApplicantSection)) {
    const trackRouteProps = { section, pageKey: page ?? firstPageKey };
    if (isApplicantSection) {
      // @ts-expect-error TS(2339): Property 'applicantId' does not exist on type '{ s... Remove this comment to see the full error message
      trackRouteProps.applicantId = applicantId ?? primaryApplicant?.id;
    }
    return <Navigate to={trackRoute(trackRouteProps)} />;
  }

  if (((!applicantId && hasInMemoryApplicant) || isPropertySection) && currentPageIsDisabled) {
    return <Navigate to={trackRoute({ section, pageKey: firstPageKey })} />;
  }

  // @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
  const applicantIdDoesNotExist = !isInitialLoading && applicantId && !applicants.find(({ id }) => id === applicantId);
  if (applicantIdDoesNotExist && !hasInMemoryApplicant) {
    return <NotFoundBlock />;
  }

  // @ts-expect-error TS(7031): Binding element 'pageKey' implicitly has an 'any' ... Remove this comment to see the full error message
  const pageConfig = config.find(({ pageKey }) => pageKey === page);

  if (!pageConfig && section in APPLICATION_SECTION_URL_NAME) {
    return <Navigate to={trackRoute({ section, pageKey: firstPageKey })} />;
  }

  if (!pageConfig) return <NotFoundBlock />;

  // @ts-expect-error TS(7031): Binding element 'pageKey' implicitly has an 'any' ... Remove this comment to see the full error message
  const currentPageIndex = config.findIndex(({ pageKey }) => pageKey === page);
  const isLastPage = currentPageIndex === config.length - 1;
  // lastPage is default save page but we allow for the config to set an isSavePage override
  // @ts-expect-error TS(7031): Binding element 'isSavePage' implicitly has an 'an... Remove this comment to see the full error message
  const sectionHasOverriddenSavePage = !!config.filter(({ isSavePage }) => !!isSavePage).length;
  const hasDirtyFields = !!currentApplicantFormData?.dirtyFields;
  const savePage =
    pageConfig.isSavePage && typeof pageConfig.isSavePage === 'function'
      ? pageConfig.isSavePage({ formData: currentApplicantFormData, application })
      : pageConfig.isSavePage;

  const isSavePage = (!sectionHasOverriddenSavePage && isLastPage) || savePage;

  let WrapperComponent = ApplicationFormSectionWrapper;
  if (section === APPLICATION_SECTION_URL_NAME.applicants) {
    WrapperComponent = ApplicantFormSectionWrapper;
  }
  const shouldNotNavigateAwayFromApplicantSection =
    !applicantId && !currentApplicantFormData?.isPrimaryApplicant && nextAnticipatedPage !== nextPage?.to;
  const hasEditedSavedApplicant = hasDirtyFields && applicantId;

  const { pageTitle } = pageConfig || {};
  return (
    <div>
      {pageTitle && (
        <h1>
          {typeof pageTitle === 'function' ? pageTitle({ data: { track, currentApplicantFormData } }) : pageTitle}
        </h1>
      )}

      <WrapperComponent
        // @ts-expect-error TS(2322): Type '{ children: any; pageConfig: any; currentPag... Remove this comment to see the full error message
        pageConfig={pageConfig}
        currentPageIndex={currentPageIndex}
        isSavePage={isSavePage}
        key={applicantId ?? pageConfig.pageKey}
      >
        {pageConfig.form()}
      </WrapperComponent>
      <ApplicantDeleteModal
        isOpen={!!selectedApplicantForDeletion}
        onClose={() => setSelectedApplicantForDeletion(null)}
        onConfirm={() => !isLoading && deleteApplicant(applicantId)}
        applicantFirstName={applicantToDeleteFormData?.[APPLICANT_FORM_FIELD.firstName]}
      />

      <RouterPrompt
        bodyText={
          (shouldNotNavigateAwayFromApplicantSection &&
            !currentApplicantFormData?.isPrimaryApplicant &&
            "You have an applicant that hasn't been saved. You will lose all changes you've made to the applicant if you navigate to this page.") ||
          undefined
        }
        when={!!hasEditedSavedApplicant}
        onOK={async () => {
          if (hasInMemoryApplicant) {
            if (!currentApplicantFormData?.isPrimaryApplicant) {
              removeInMemoryApplicant();
            }
            return;
          }
          await refetchApplicants();
        }}
      />
    </div>
  );
};

export default ApplicationPageFromConfig;
