import { Button } from '@hometap/htco-components';
import { getDashboardUrl, getPrimaryAppTrackDetailsUrl } from 'utils/links';
import { CONTINUE_BUTTON_ID } from '../../../../progressive-inquiry/constants/progressiveInquiryConstants';

// @ts-expect-error TS(7031): Binding element 'isAdditionalTrack' implicitly has... Remove this comment to see the full error message
const InquiryGetStartedButton = ({ isAdditionalTrack, onNext, trackId }) => (
  <>
    {isAdditionalTrack ? (
      <Button href={trackId ? getPrimaryAppTrackDetailsUrl(trackId) : getDashboardUrl()}>Get started</Button>
    ) : (
      <>
        <Button onClick={() => onNext()} id={CONTINUE_BUTTON_ID} className="InquirySignInButton">
          Get started
        </Button>
      </>
    )}
  </>
);

export default InquiryGetStartedButton;
