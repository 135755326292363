import { Toggle, useWindowSize } from '@hometap/htco-components';
import { EQUITY_VIEW } from '../../../constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import EquityViewSettings from '../equity-view-settings/EquityViewSettings';
import cx from 'classnames';
import './EquityViewSelector.scss';

const VIEW_OPTIONS = [
  { label: 'Today’s View', value: EQUITY_VIEW.TODAY },
  { label: 'Plan for the future', value: EQUITY_VIEW.FUTURE },
];

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const EquityViewSelector = ({ className }) => {
  const { isFutureView, setIsFutureView, futureScenario } = useEquityScenarioContext();
  const { setupProfileLater } = useDelayHomeEquityProfile();

  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const emptyFutureState = isFutureView && !futureScenario?.id;

  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  const onToggle = value => {
    if (value === 'today') {
      if (isFutureView) {
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        setIsFutureView(false);
      }
    } else if (value === 'future') {
      if (!isFutureView) {
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        setIsFutureView(true);
      }
    }
  };

  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize('sm');

  return (
    <div className={cx('EquityViewSelectorContainer', className)}>
      <div className="EquityViewSelector">
        <Toggle
          options={VIEW_OPTIONS}
          onChange={onToggle}
          value={isFutureView ? EQUITY_VIEW.FUTURE : EQUITY_VIEW.TODAY}
          className="EquityViewSelectorToggle"
          labelWidth="50%"
          disabled={setupProfileLater}
        />

        {/* @ts-expect-error TS(2322): Type '{ isInline: true; }' is not assignable to ty... Remove this comment to see the full error message */}
        {!isTablet && !emptyFutureState && <EquityViewSettings isInline />}
      </div>
    </div>
  );
};

export default EquityViewSelector;
