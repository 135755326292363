import { useAsync } from '@hometap/htco-components';
import { apiNoAuth } from '../../../utils/api';

const fetchStates = () => {
  return apiNoAuth.get(`/core/configurations/states/`);
};

const useStateInfo = () => {
  const { results: nodes } = useAsync(fetchStates, {
    immediate: true,
  });

  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  const operationalStateNames = nodes?.filter(state => state.status === 'Operational');

  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  const dropdownStates = nodes?.map(state => ({ label: state.abbreviation, value: state.abbreviation }));

  // @ts-expect-error TS(7006): Parameter 'stateNodes' implicitly has an 'any' typ... Remove this comment to see the full error message
  const stateMap = nodes?.reduce((stateNodes, state) => {
    stateNodes[state.abbreviation] = { status: state.status, name: state.name };
    return stateNodes;
  }, {});

  // @ts-expect-error TS(7006): Parameter 'stateNodes' implicitly has an 'any' typ... Remove this comment to see the full error message
  const abbreviationMap = nodes?.reduce((stateNodes, state) => {
    stateNodes[state.name] = state.abbreviation;
    return stateNodes;
  }, {});

  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  const isStateOperational = state => stateMap[state]?.status === 'Operational';

  return {
    states: nodes,
    operationalStateNames,
    dropdownStates,
    stateMap,
    isStateOperational,
    abbreviationMap,
  };
};

export default useStateInfo;
