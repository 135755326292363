import { useState } from 'react';
import cx from 'classnames';

import { Button, ContentHero, EmailInput, useForm } from '@hometap/htco-components';
import { browserTrack } from 'utils/segment';

import equityIncreaser from '../../../images/equity-increaser-phone.jpg';

import './ResultUnsupportedState.scss';

// @ts-expect-error TS(7031): Binding element 'state' implicitly has an 'any' ty... Remove this comment to see the full error message
const ResultUnsupportedState = ({ state }) => {
  const { registerField, handleSubmit } = useForm();
  const [submitted, setSubmitted] = useState(false);

  // @ts-expect-error TS(7031): Binding element 'email' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleEmailSubmitButton = ({ email }) => {
    setSubmitted(true);
    browserTrack.engagementFormCompleted({
      form_name: 'state_waitlist_fitquiz',
      email,
      state,
      version: '1.0',
      version_start_date: '11-18-2020',
    });
  };

  return (
    <div className="ResultUnsupportedState">
      <div className={cx('ResultUnsupportedStateCopy', submitted && 'isCompleted')}>
        <div>
          {!submitted ? (
            <>
              <h2>Unfortunately, Hometap isn’t yet able to serve your area.</h2>
              <h3>We’ll stay in touch and hope to be there soon!</h3>
            </>
          ) : (
            <>
              <h2>Thanks for joining our waitlist</h2>
              <h3>We’ll be sure to notify you when we expand to your area. </h3>
            </>
          )}

          <form className="ResultUnsupportedStateWaitlist">
            <div className="ResultUnsupportedStateEmailWrap">
              <EmailInput
                label=" "
                required
                className="ResultUnsupportedStateEmail"
                placeholder="your@email.com"
                {...registerField('email')}
              />
            </div>
            <Button onClick={handleSubmit(handleEmailSubmitButton)} size="small" type="submit">
              Join Waitlist
            </Button>
          </form>
        </div>
      </div>

      <div className="ResultUnsupportedStateGuide">
        <ContentHero
          title="In the meantime, feel free to download our Equity Increaser Guide"
          imagePosition="left"
          buttonLabel="Download Guide"
          buttonLink="//hometap.com/homeowner-resources/equity-increaser-guide"
          image={equityIncreaser}
        >
          The guide includes:
          <ul>
            <li>
              A full year’s worth of home maintenance tasks designed to preserve, and even grow, the value of your home
            </li>
            <li>Easy-to-follow, step-by-step instructions for each task</li>
            <li>All of the resources you need to start saving thousands of dollars per year in home repairs</li>
          </ul>
        </ContentHero>
      </div>
    </div>
  );
};

export default ResultUnsupportedState;
