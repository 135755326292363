import React, { useContext, useEffect, useState } from 'react';

import { useAsync } from '@hometap/htco-components';
import { STAGES } from 'apps/dashboard/constants';
import { fetchInvestmentSummary } from 'apps/settlement/data/settlementRequests';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { prepareInvestmentLiens } from 'apps/dashboard/utils/dataUtils';
import { useCurrentHome } from 'hooks/useCurrentHome';

// Hook to get the current user's active investment for HED if they have one

const Context = React.createContext({});

const useHedInvestmentContext = () => useContext(Context);
export default useHedInvestmentContext;

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const HedInvestmentProvider = ({ children }) => {
  const { execute, loading, error } = useAsync(fetchInvestmentSummary);
  // @ts-expect-error TS(2339): Property 'tracks' does not exist on type '{}'.
  const { tracks } = useUserTracksContext();
  const { home } = useCurrentHome();
  const [investment, setInvestment] = useState(null);

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (!tracks?.length || !home?.id) return;

    // @ts-expect-error TS(7006): Parameter 'tracks' implicitly has an 'any' type.
    const getActiveInvestmentSummary = async tracks => {
      const activeInvestmentTrack = tracks.find(
        // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
        track => !!track.active_investment_id && track.current_stage === STAGES.INVESTED,
      );
      if (!activeInvestmentTrack) return;

      const investmentSummary = await execute(activeInvestmentTrack.active_investment_id);
      if (investmentSummary?.effective_date && investmentSummary?.beginning_home_value) {
        return investmentSummary;
      }
    };

    // @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
    const processTracksAndGetInvestmentSummary = async (homeId, tracks = []) => {
      // @ts-expect-error TS(2339): Property 'home' does not exist on type 'never'.
      const homeTracks = tracks?.filter(track => track?.home?.id === homeId);
      if (!homeTracks.length) {
        setInvestment(null);
        return;
      }

      // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
      const activeInvestmentSummary = await getActiveInvestmentSummary(homeTracks, homeId);
      if (!activeInvestmentSummary) {
        setInvestment(null);
        return;
      }

      const editedLienReviews = prepareInvestmentLiens(activeInvestmentSummary);
      activeInvestmentSummary.editedLienReviews = editedLienReviews;
      setInvestment(activeInvestmentSummary);
    };

    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    processTracksAndGetInvestmentSummary(home.id, tracks);
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
  }, [tracks, execute, home?.id]);

  const contextData = { investment, loading, error };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
