import { mapKeys, pickBy } from 'lodash';
import { apiNoAuth } from 'utils/api';
import {
  hasValidHomeEquityLineOfCreditData,
  hasValidHomeEquityLoanData,
  hasValidMortgageData,
  hasValidOtherLien,
} from '../utils/dataUtils';
import { getVersion } from 'utils/env';

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export const createAccount = async data => {
  return await apiNoAuth.post(`/auth/users/`, data);
};

// @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
export const buildSelfReportedLienRequestData = formData => {
  const { lienType } = formData;

  // @ts-expect-error TS(7006): Parameter 'lienData' implicitly has an 'any' type.
  const validateLienTypes = lienData => {
    const VALIDATION_RULES = {
      mortgage_loan: hasValidMortgageData,
      home_equity_loan: hasValidHomeEquityLoanData,
      home_equity_line_of_credit: hasValidHomeEquityLineOfCreditData,
      judgement_tax_or_other: hasValidOtherLien,
    };
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return VALIDATION_RULES[lienData.lien_type](lienData);
  };

  // Find form data properties that are associated with the lien type
  const containsMatchingProperty = pickBy(formData, (value, key) => key.includes(lienType));

  // Remove the lien type from the string (ex: mortgage_loan_rate --> rate)
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  const formatKey = (value, key) => key.replace(`${lienType}_`, '');
  const lienData = {
    lien_type: lienType,
    ...mapKeys(containsMatchingProperty, formatKey),
  };

  // @ts-expect-error TS(2339): Property 'lien_kind_other' does not exist on type ... Remove this comment to see the full error message
  if (lienData.lien_kind_other && !lienData.lien_kind) {
    // @ts-expect-error TS(2339): Property 'lien_kind' does not exist on type '{ lie... Remove this comment to see the full error message
    lienData.lien_kind = 'lien_kind_other';
  }

  if (validateLienTypes(lienData)) {
    return lienData;
  }
};

// @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
export const buildAccountCreateRequestData = formData => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    street,
    unit,
    city,
    state,
    zip_code,
    tracking_data,
    onboarding_goal,
    onboarding_goal_other,
  } = formData;

  const data = {
    first_name,
    last_name,
    email,
    homeowner_goal_onboarding: onboarding_goal,
    home: {
      address: { street, unit, city, state, zip_code },
    },
    tracking_data,
  };

  if (phone_number) {
    // @ts-expect-error TS(2339): Property 'phone_number' does not exist on type '{ ... Remove this comment to see the full error message
    data.phone_number = phone_number;
  }

  if (onboarding_goal === 'other') {
    // @ts-expect-error TS(2339): Property 'homeowner_goal_onboarding_other' does no... Remove this comment to see the full error message
    data.homeowner_goal_onboarding_other = onboarding_goal_other;
  }

  return data;
};

// @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
export const buildSegmentData = formData => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    street,
    unit,
    city,
    state,
    zip_code,
    onboarding_goal,
    onboarding_goal_other,
    tracking_data,
  } = formData;

  // append "account_" to tracking data for product
  const trackingDataAppended = {};
  Object.keys(tracking_data).forEach(key => {
    const appendedKey = `account_${key}`;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    trackingDataAppended[appendedKey] = tracking_data[key];
  });

  const segmentData = {
    first_name,
    last_name,
    email,
    phone_number,
    street,
    unit,
    city,
    state,
    zip_code,
    onboarding_goal,
    onboarding_goal_other,
    ...trackingDataAppended,
    version_number: getVersion(),
  };

  return segmentData;
};
