import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Button, Icon, MuiTextInput, MuiSSNInput, SlideDown } from '@hometap/htco-components';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
} from '../constants/progressiveInquiryConstants';
import yellowHouse from '../../../images/equity-planner/onboarding/yellow-house.png';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';
import { useAsyncPatchInquiry } from '../hooks/inquiryMutations';
import { formatName } from '../../../utils/stringFormatters';
import './InquiryHomeowner.scss';

// @ts-expect-error TS(7031): Binding element 'isLoggedIn' implicitly has an 'an... Remove this comment to see the full error message
const InquiryHomeowner = ({ isLoggedIn, inquiryId, form, step, onNext }) => {
  const { registerField, formData, handleSubmit, isFormValid, updateFormData, setErrors, handleFieldChange } = form;

  const { results, loading, execute: updateInquiry } = useAsyncPatchInquiry();

  useEffect(() => {
    if (results) {
      onNext(step + 1);
    }
  }, [results, onNext, step]);

  const onSubmit = () => {
    const { first_name, last_name, ssn } = formData;
    const updateApi = handleSubmit(() => updateInquiry(inquiryId, { first_name, last_name, ssn }));
    updateApi();
  };

  const [visible, setVisible] = useState(false);
  const onToggle = () => {
    setVisible(v => !v);
  };

  const { street } = formData;
  const ctaText = 'You’re almost there!';
  useCTAChangedNotification(ctaText, isFormValid);

  return (
    <div className="HomeownerInfo" data-testid="inquiry_homeowner_info_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.INFO]} />
      <ProgressiveUITemplate>
        {/* @ts-expect-error TS(2739): Type '{ children: Element[]; }' is missing the fol... Remove this comment to see the full error message */}
        <ContentPanel>
          <h1>
            <span className="InquiryEmphasisGreen">Good news!</span> We’re investing in properties like yours.{' '}
          </h1>
          <p className="InquiryBodyText HomeownerInfoBodyText">
            If you’d like to see a no-obligation Investment Estimate for{' '}
            <span className="InquiryEmphasisBold">{street}</span>, we just need a little information about the owner of
            the property.
          </p>
          <div className="HomeownerInfoFields">
            <MuiTextInput
              required
              label="Legal First Name"
              width="100%"
              disabled={isLoggedIn && formData?.first_name}
              maxLength={30}
              {...registerField(FIELDS.FIRST_NAME)}
              // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
              onChange={(val, name, error) => handleFieldChange(formatName(val), name, error)}
            />
            <MuiTextInput
              required
              label="Legal Last Name"
              width="100%"
              disabled={isLoggedIn && formData?.last_name}
              maxLength={30}
              {...registerField(FIELDS.LAST_NAME)}
              // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
              onChange={(val, name, error) => handleFieldChange(formatName(val), name, error)}
            />
            <MuiSSNInput
              required
              label="Social Security Number"
              helperText="e.g ###-##-####"
              width="100%"
              type="number"
              value={formData.ssn || ''}
              // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
              onChange={val => updateFormData({ [FIELDS.SSN]: val })}
              // @ts-expect-error TS(7006): Parameter 'isError' implicitly has an 'any' type.
              onError={isError => {
                // MuiSSNInput doesn't work with useForm so we have to handle the error state manually
                // It doesn't report whether the error is shown, so we have to intuit it from if the value also.
                // Without this conditional check for !formData.ssn, the initial typing of the first digit will call
                // onChange with the newVal and also report no error for a brief second (blank is considered no error
                // for MuiSSNInput) then call onError setting the error to true.
                // Similarly since isError doesn't accurately represent if the error is shown, we do not fire a segment
                // event for it.
                // It would be nice if MuiSSNInput just worked with useForm / registerField.
                if (!formData[FIELDS.SSN]) {
                  setErrors(FIELDS.SSN, 'SSN is required', true);
                  return;
                }
                if (formData[FIELDS.SSN] && isError) {
                  setErrors(FIELDS.SSN, 'Please enter a valid SSN', true);
                  return;
                }
                setErrors(FIELDS.SSN, '', false);
              }}
            />
          </div>
          <div className="HomeownerInfoWhySSN">
            {/* @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'. */}
            <div onClick={onToggle} onKeyDown={onToggle} role="button" tabIndex="0" className="HomeownerInfoSSNTitle">
              Why do we need this information?
            </div>
            <SlideDown visible={visible}>
              <div className="HomeownerInfoSSNDescription">
                We need to confirm your identity before we can prequalify you for a Hometap Investment. Your social
                security number lets us verify that you’re you.
              </div>
            </SlideDown>
          </div>
          <CallToAction>
            {/* @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type. */}
            <Button id={CONTINUE_BUTTON_ID} disabled={!isFormValid} onClick={data => onSubmit(data)} loading={loading}>
              {ctaText}
            </Button>
          </CallToAction>
          <div className="HomeownerInfoEncrypted">
            <Icon name="icon-lock" size="lg" />
            <p>
              All of your data is AES-256 encrypted and stored securely. We do not sell your personally identifiable
              information. For more information about our security, please check out our{' '}
              <a
                href="https://www.hometap.com/legal/?section=privacy-policy#privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </ContentPanel>
        <ImageContentPanel imageSource={yellowHouse} imgAlt="yellow house" />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryHomeowner;
