import numeral from 'numeral';
// TODO: currency(), percent() functions do not check for NaN or undefined

export const currency = (num = 0, includeCurrencySymbol = true) => {
  const format = includeCurrencySymbol ? '$0,0' : '0,0';
  return numeral(num).format(format);
};

// @ts-expect-error TS(7006): Parameter 'num' implicitly has an 'any' type.
export const percent = (num, format = '0.0') => {
  return numeral(num).format(`${format}%`);
};

// @ts-expect-error TS(7006): Parameter 'percentInteger' implicitly has an 'any'... Remove this comment to see the full error message
export const percentToDecimal = percentInteger => {
  const parsed = parseFloat(percentInteger);

  if (!Number.isNaN(parsed)) {
    return parsed / 100;
  }
  return 0;
};

// @ts-expect-error TS(7006): Parameter 'num' implicitly has an 'any' type.
export const roundToDecimal = (num, decimal = 10) => {
  return Math.round(num / decimal) * decimal;
};

export const formatShareFraction = (num = 0) => {
  return numeral(num).format('0[.]0%');
};

// TODO: this is not used anywhere, should be removed
// @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
export const forceValueWithinRange = (n, min, max) => {
  return Math.min(Math.max(n, min), max);
};

// TODO: this is not used anywhere, should be removed
// @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
export const isWithinRange = (val, min, max) => val > min && val < max;

// @ts-expect-error TS(7006): Parameter 'str' implicitly has an 'any' type.
export const roundStringToDecimalPlaces = (str, decimals = 2) => parseFloat(str).toFixed(decimals);
