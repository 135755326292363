import apiWithAuth, { apiNoAuth } from 'utils/api';
import { browserTrack, browserIdentify } from 'utils/segment';
import { PREQUAL_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';
import { getVersion } from 'utils/env';

// @ts-expect-error TS(7006): Parameter 'prequalificationKinds' implicitly has a... Remove this comment to see the full error message
export const createInquiry = (body = {}, prequalificationKinds, reinquiryId = null, reinquiryFields = []) => {
  const defaultPrequalificationKinds = [PREQUAL_TYPES.ZIP, PREQUAL_TYPES.STATE, PREQUAL_TYPES.PROPERTY_TYPE];
  const params = {
    prequalification_kinds: prequalificationKinds || defaultPrequalificationKinds,
  };
  if (reinquiryId) {
    // @ts-expect-error TS(2339): Property 'reinquiry_id' does not exist on type '{ ... Remove this comment to see the full error message
    params.reinquiry_id = reinquiryId;
  }
  if (reinquiryFields.length > 0) {
    // @ts-expect-error TS(2339): Property 'reinquiry_fields' does not exist on type... Remove this comment to see the full error message
    params.reinquiry_fields = reinquiryFields;
  }

  return apiNoAuth.post(`/inquiries/`, body, { params });
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const patchInquiry = (id, body = {}, prequalificationKinds = []) => {
  const params = {
    prequalification_kinds: prequalificationKinds,
  };
  return apiNoAuth.patch(`/inquiries/${id}/`, body, { params });
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const convertInquiry = (id, body = {}) => {
  return apiNoAuth.post(`/inquiries/${id}/actions/convert/`, body);
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchPrequalification = async (id, prequalToRetrieve) => {
  const prequals = await apiNoAuth.get(`/inquiries/${id}/prequalifications`);
  if (prequals) {
    // @ts-expect-error TS(2339): Property 'filter' does not exist on type 'AxiosRes... Remove this comment to see the full error message
    return prequals.filter(prequal => prequal?.status === 'active' && prequal?.kind === prequalToRetrieve)?.[0] || {};
  }
  return {};
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchMultiplePrequalifications = async (id, prequalsToRetrieve) => {
  const prequals = (await apiNoAuth.get(`/inquiries/${id}/prequalifications`)) || [];
  const prequalFilter = new Set(prequalsToRetrieve);
  // @ts-expect-error TS(2339): Property 'filter' does not exist on type 'AxiosRes... Remove this comment to see the full error message
  return prequals.filter(prequal => prequal?.status === 'active' && prequalFilter.has(prequal.kind));
};

// @ts-expect-error TS(7031): Binding element 'first_name' implicitly has an 'an... Remove this comment to see the full error message
export const sendUnqualifiedFormEvent = ({ first_name, last_name, email }) => {
  browserTrack.homeownerPreQualificationOutcomeFormSubmitted({
    first_name,
    last_name,
    email,
  });
};

export const sendInquiryConvertedSegmentEvents = ({
  // @ts-expect-error TS(7031): Binding element 'first_name' implicitly has an 'an... Remove this comment to see the full error message
  first_name,
  // @ts-expect-error TS(7031): Binding element 'last_name' implicitly has an 'any... Remove this comment to see the full error message
  last_name,
  // @ts-expect-error TS(7031): Binding element 'email' implicitly has an 'any' ty... Remove this comment to see the full error message
  email,
  // @ts-expect-error TS(7031): Binding element 'isAdditionalTrack' implicitly has... Remove this comment to see the full error message
  isAdditionalTrack,
  adTrackingData = {},
  flags = {},
}) => {
  const userInfo = { email: email.toLowerCase(), first_name, last_name };
  // friendly_id is also a property of these segment events but unsupported at this time.
  const segmentData = {
    ...userInfo,
    version_number: getVersion(),
    ...adTrackingData,
  };
  browserTrack.homeownerTrackCreatedBrowser(segmentData);
  if (!isAdditionalTrack) {
    // since we no longer log the user in after inquiry conversion, we have to call identify
    browserIdentify(email.toLowerCase(), {
      ...userInfo,
      flags,
    });

    browserTrack.homeownerAccountCreatedBrowser(segmentData);
  }
};

export const fetchInquiries = () => {
  return apiWithAuth.v1.get('/current-user/inquiries/');
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchInquiry = async id => {
  return await apiWithAuth.v1.get(`/current-user/inquiries/${id}/`);
};
