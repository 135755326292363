import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';
import useSearchParams from 'hooks/useSearchParams';
import { getLoginUrl } from 'utils/links';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const PrivateRoute = ({ children, redirect }) => {
  const location = useLocation();
  const query = useSearchParams();
  // @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'.
  const { error } = useCurrentUser();
  const redirectTo = redirect || getLoginUrl();

  if (error?.response?.status === 403) {
    if (!query.has('next')) {
      query.append('next', location.pathname);
    }
    const queryString = query.toString() ? `?${query.toString()}` : '';
    const redirectWithQuery = `${redirectTo}${queryString}`;

    // @ts-expect-error TS(2339): Property 'api' does not exist on type 'Window & ty... Remove this comment to see the full error message
    if (window.api?.redirect) {
      // @ts-expect-error TS(2339): Property 'api' does not exist on type 'Window & ty... Remove this comment to see the full error message
      window.api.redirect(redirectWithQuery);
      return null;
    }
    return <Navigate to={redirectWithQuery} state={{ from: location }} replace />;
  }

  return children || <Outlet />;
};

export default PrivateRoute;
