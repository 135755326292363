import { useRef, useState, useEffect } from 'react';
import Slide from '@mui/material/Slide';
import { InputMask, MuiTextInput, Button, MuiSelect } from '@hometap/htco-components';
import { LIEN_FIELDS, SCENARIO_PERIOD_OPTIONS } from 'apps/dashboard/constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { buildSelfReportedLienRequestData } from 'apps/dashboard/data';
import { FORM_TITLES, PROFILE_INSTANCES } from 'apps/dashboard/constants/buildYourProfile';
import debtInfo from 'images/dashboard/build-profile-house-2.jpg';
import { positiveValueValidator } from 'utils/validators';
import { useCurrentHome } from 'hooks/useCurrentHome';
import './Debt.scss';
import BuildProfileFlowLayout from './BuildProfileFlowLayout';

// @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
const Debt = ({ onNext, onBack, form, instance }) => {
  const { formData } = form;
  const { registerField, isFormValid } = form;

  const [curLienStep, setCurLienStep] = useState(0);
  const [prevLienStep, setPrevLienStep] = useState(0);

  const { home } = useCurrentHome();
  const containerRef = useRef(null);
  const { currentScenario, isFutureView, addLien, updateLien, getEquityScenarioLiensAsync } =
    useEquityScenarioContext();

  // @ts-expect-error TS(2339): Property 'results' does not exist on type '{}'.
  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;
  // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{}'.
  const isLoading = getEquityScenarioLiensAsync.loading;
  const liens = formData?.liens;

  useEffect(() => {
    const getLiens = async () => {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  const getTitle = () => {
    const titleConfig = FORM_TITLES.find(lien => lien.lien_type === liens?.[curLienStep]);
    return instance === PROFILE_INSTANCES.EDIT_PROFILE ? titleConfig?.editTitle : titleConfig?.title;
  };

  // @ts-expect-error TS(7006): Parameter 'onNext' implicitly has an 'any' type.
  const updateLienStages = onNext => {
    const increment = onNext ? curLienStep + 1 : curLienStep - 1;
    setPrevLienStep(curLienStep);
    setCurLienStep(increment);
  };

  const singleLien = liens?.length === 1;

  // @ts-expect-error TS(7006): Parameter 'lienType' implicitly has an 'any' type.
  const onDebtNext = async lienType => {
    // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
    const existingLien = equityScenarioLiens?.find(lien => lien.lien_type === lienType);
    const requestData = buildSelfReportedLienRequestData({ lienType, ...form?.formData });
    const scenario = { ...currentScenario };

    if (existingLien) {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 3.
      await updateLien(scenario, existingLien?.id, requestData);
    } else {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
      await addLien(scenario, requestData);
    }

    // @ts-expect-error TS(2339): Property 'execute' does not exist on type '{}'.
    await getEquityScenarioLiensAsync?.execute(home?.id, currentScenario?.id);

    onNext(lienType);
    if (!singleLien) {
      updateLienStages(true);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'lienType' implicitly has an 'any' type.
  const onDebtBack = lienType => {
    const index = liens.indexOf(lienType);

    // The homeowner is transitioning from one lien info page to the other
    if (index > 0) {
      return updateLienStages(false);
    }
    setPrevLienStep(0);
    onBack(false);
  };

  // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
  const getDebtInfoDirection = lien => {
    const isForward = prevLienStep < curLienStep;
    const isBack = prevLienStep > curLienStep;
    const lienStep = liens.indexOf(lien);

    // The logic below ensures that both the old and new pages transition in to the left.
    // The "direction" property indicates how the page will enter, so for the page the homeowner clicks
    // "Back" or "Next" on needs to be set to the opposite to ensure the correct "exit" direction

    if (isBack) {
      return lienStep > curLienStep ? 'left' : 'right';
    }

    if (isForward || !prevLienStep) {
      return lienStep < curLienStep ? 'right' : 'left';
    }
  };

  const getTermField = () => {
    // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
    const mortgage = equityScenarioLiens?.find(lien => lien.lien_type === 'mortgage_loan');
    const hasLienKindOther = mortgage?.lien_kind === LIEN_FIELDS.LIEN_KIND_OTHER;
    return hasLienKindOther ? 'mortgage_loan_lien_kind_other' : 'mortgage_loan_lien_kind';
  };

  // Manually validating the fields are populated, because useForm's `isFormValid` doesn't always seem up to date
  const enableNext = () => {
    const currentLienType = liens?.[curLienStep];
    const formDataKeys = [
      `${currentLienType}_rate`,
      `${currentLienType}_last_known_current_balance`,
      `${currentLienType}_monthly_payment`,
    ];

    const hasRequiredFields = formDataKeys.every(key => !!formData?.[key]);

    if (currentLienType === 'mortgage_loan') {
      return (
        hasRequiredFields &&
        (formData?.[`${currentLienType}_lien_kind`] || formData?.[`${currentLienType}_lien_kind_other`])
      );
    }

    return hasRequiredFields;
  };

  // @ts-expect-error TS(7031): Binding element 'containerRef' implicitly has an '... Remove this comment to see the full error message
  const renderLienForms = ({ containerRef }) => {
    // @ts-expect-error TS7006
    return liens?.map((lien, i) => (
      <Slide
        key={`${lien}_${i}`}
        direction={getDebtInfoDirection(lien)}
        in={curLienStep === i}
        timeout={400}
        mountOnEnter
        unmountOnExit
        easing={{
          enter: 'linear',
          exit: 'linear',
        }}
        container={containerRef.current}
        appear={i !== 0}
      >
        <div className="w-full py-8" data-testid={`${lien}_info`}>
          {lien === 'mortgage_loan' && (
            <MuiSelect
              className="mb-6"
              name="mortgage_loan_lien_kind"
              label="Term"
              aria-label="Term"
              data-testid="mortgage_loan_term"
              width="100%"
              options={SCENARIO_PERIOD_OPTIONS}
              required
              {...registerField(getTermField())}
            />
          )}
          <MuiTextInput
            className="mb-6"
            name={`${lien}_rate`}
            label="Rate"
            aria-label="Rate"
            type="number"
            inputMode="decimal"
            data-testid={`${lien}-rate`}
            width="100%"
            mask={InputMask.extend(InputMask.PERCENT_MASK, { max: 0.9999, min: 0.0001 })}
            endAdornment="%"
            unmask="typed"
            placeholder=" "
            required
            {...registerField(`${lien}_rate`)}
          />
          <MuiTextInput
            className="mb-6"
            name={`${lien}_last_known_current_balance`}
            label="Current balance"
            aria-label="Current balance"
            type="number"
            inputMode="decimal"
            data-testid={`${lien}-last-known-current-balance`}
            mask={InputMask.MONEY_USD_MASK}
            startAdornment="$"
            unmask="typed"
            required
            width="100%"
            placeholder=" "
            validator={positiveValueValidator}
            {...registerField(`${lien}_last_known_current_balance`)}
          />
          <MuiTextInput
            name={`${lien}_monthly_payment`}
            label="Monthly payment"
            aria-label="Monthly payment"
            type="number"
            inputMode="decimal"
            data-testid={`${lien}-monthly-payment`}
            helperText="Only include principal and interest"
            mask={InputMask.MONEY_USD_MASK}
            startAdornment="$"
            unmask="typed"
            required
            width="100%"
            placeholder=" "
            validator={positiveValueValidator}
            {...registerField(`${lien}_monthly_payment`)}
          />
        </div>
      </Slide>
    ));
  };

  return (
    <BuildProfileFlowLayout stepImage={debtInfo} stepImageAlt="debt-info">
      <div className="mb-0.5 flex flex-col-reverse justify-between text-sm sm:flex-row sm:items-start">
        <h3>{getTitle()}</h3>
        {liens?.length > 1 && (
          <div className="mb-2 flex sm:mb-0 sm:ml-6 sm:mt-2">
            <span className="font-bold">0{curLienStep + 1}</span>&nbsp;/&nbsp;
            <span>0{liens.length}</span>
          </div>
        )}
      </div>
      {isFutureView && (
        <span className="mt-2 inline-block text-sm text-neutral-dark-75 sm:text-base">
          These will be added to your future equity scenario.
        </span>
      )}
      <div className="relative flex overflow-hidden">{renderLienForms({ containerRef })}</div>
      <div className="flex flex-col-reverse justify-between xs:flex-row xs:items-center">
        {onBack && (
          <Button
            className="!pl-0"
            theme="link"
            onClick={() => onDebtBack(liens[curLienStep])}
            data-testid="back-button"
          >
            Back
          </Button>
        )}
        <Button
          className="!ml-0 xs:!ml-auto"
          size="large"
          loading={isLoading}
          disabled={!isFormValid || !enableNext() || isLoading}
          onClick={() => onDebtNext(liens[curLienStep])}
          data-testid="next-button"
        >
          {singleLien ? 'Save' : 'Next'}
        </Button>
      </div>
    </BuildProfileFlowLayout>
  );
};

export default Debt;
