import BoolField from 'apps/application/components/BoolField/BoolField';
import { APPLICATION_FORM_FIELD } from 'apps/application/constants/formFields';
import useApplicationContext from 'apps/application/hooks/useApplicationContext';

const PropertyHOABoolForm = () => {
  // @ts-expect-error TS(2339): Property 'registerApplicationFormField' does not e... Remove this comment to see the full error message
  const { registerApplicationFormField } = useApplicationContext();

  return (
    <div className="ApplicationInfoFormFields">
      <BoolField
        required
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        labelWidth="100%"
        baseRegisterField={registerApplicationFormField(APPLICATION_FORM_FIELD.hasHoa)}
      />
    </div>
  );
};

export default PropertyHOABoolForm;
