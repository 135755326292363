import { uniq } from 'lodash';
import { useNavigate, useLocation, matchPath, useResolvedPath } from 'react-router-dom';

import {
  getApplicantFormKey,
  APPLICATION_CONFIG,
  APPLICATION_SECTION_URL_NAME,
  filterConditionalPages,
  buildApplicationRoute,
  hasInMemoryApplicant,
  getFullApplicationPath,
} from '../utils';
import useApplicationContext from './useApplicationContext';

import { APPLICATION_MODEL_FIELD } from '../constants/applicationDataFields';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const ApplicationNavContext = createContext();

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const ApplicationNavProvider = ({ children }) => {
  // @ts-expect-error TS(2339): Property 'applicants' does not exist on type 'unkn... Remove this comment to see the full error message
  const { applicants, applicationFormData, setSelectedApplicantForDeletion, track, application } =
    useApplicationContext();

  const url = useResolvedPath('');
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath(getFullApplicationPath(url.pathname), location.pathname);
  const { section, page, applicantId } = match?.params ?? {};

  const [activePages, setActivePages] = useState();
  const [nextAnticipatedPage, setNextAnticipatedPage] = useState(null);
  const [shouldDisableOtherSections, setShouldDisableOtherSections] = useState(false);

  const hasApplicationChanged = Object.keys(applicationFormData?.dirtyFields || {})?.length;

  useEffect(() => {
    if (!applicantId && hasInMemoryApplicant(applicants)) {
      setShouldDisableOtherSections(true);
    } else if (section === APPLICATION_SECTION_URL_NAME.property && hasApplicationChanged) {
      setShouldDisableOtherSections(true);
    } else {
      setShouldDisableOtherSections(false);
    }
  }, [applicantId, applicants, hasApplicationChanged, section]);

  const applicantConfig = filterConditionalPages(
    APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.applicants],
    applicationFormData,
  );
  const propertyConfig = filterConditionalPages(
    APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.property],
    applicationFormData,
  );
  const consentConfig = filterConditionalPages(
    APPLICATION_CONFIG[APPLICATION_SECTION_URL_NAME.consent],
    applicationFormData,
  );

  // @ts-expect-error TS(7006): Parameter 'applicant' implicitly has an 'any' type... Remove this comment to see the full error message
  const applicantSectionLinks = applicants.map((applicant, i) => {
    const applicantFormData = applicationFormData[getApplicantFormKey(applicant.id)];
    const applicantFirstName = applicantFormData?.first_name || applicant.first_name || 'Applicant';

    // @ts-expect-error TS(7031): Binding element 'pageKey' implicitly has an 'any' ... Remove this comment to see the full error message
    const items = applicantConfig.map(({ pageKey, navLabel }, i) => {
      const url = buildApplicationRoute(track?.id)({
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey,
        applicantId: applicant.id || '',
      });
      return { navLabel, page: pageKey, key: `${pageKey}-${i}`, to: url };
    });

    // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
    const nextAnticipatedUrlIndex = items.findIndex(item => item.to === nextAnticipatedPage);

    return {
      sectionKind: APPLICATION_SECTION_URL_NAME.applicants,
      sectionLabel: `About ${applicantFirstName}`,
      id: applicant?.id,
      key: applicant?.id || `applicantSection-${i}`,
      disabled: Boolean(shouldDisableOtherSections && applicant?.id),
      icon: !applicantFormData?.isPrimaryApplicant && {
        action: () => setSelectedApplicantForDeletion(applicant),
        name: 'bin',
        color: '#b41a1a',
      },
      // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
      navItems: items.map((item, i) => {
        // Disable nav items only for a new applicant.
        // Don't disable the first page in a section.
        // Disable pages that have not yet been viewed
        // If the current form has errors then prevent from going ahead to previously viewed pages.
        // @ts-expect-error TS(2339): Property 'includes' does not exist on type 'never'... Remove this comment to see the full error message
        const canViewAhead = activePages?.includes(item.to) && applicantFormData?.isCurrentFormValid;
        const isEnabled = !!applicant?.id || i === 0 || i < nextAnticipatedUrlIndex || canViewAhead;
        return { ...item, disabled: !isEnabled };
      }),
    };
  });

  const address = track?.home?.address?.street;
  const propertySectionLinks = useMemo(
    () => ({
      sectionKind: APPLICATION_SECTION_URL_NAME.property,
      sectionLabel: `About ${address}`,
      disabled:
        !application?.[APPLICATION_MODEL_FIELD.hasSubmittedApplicants] ||
        (shouldDisableOtherSections && section !== APPLICATION_SECTION_URL_NAME.property),
      key: address,
      // @ts-expect-error TS(7031): Binding element 'pageKey' implicitly has an 'any' ... Remove this comment to see the full error message
      navItems: propertyConfig.map(({ pageKey, navLabel }, i) => {
        const url = buildApplicationRoute(track?.id)({
          section: APPLICATION_SECTION_URL_NAME.property,
          pageKey,
        });

        const isEnabled =
          // @ts-expect-error TS(2339): Property 'includes' does not exist on type 'never'... Remove this comment to see the full error message
          i === 0 || activePages?.includes(url) || application?.[APPLICATION_MODEL_FIELD.hasSubmittedLiens];

        return {
          key: pageKey,
          page: pageKey,
          navLabel,
          to: url,
          disabled: !isEnabled,
        };
      }),
    }),
    [address, application, shouldDisableOtherSections, propertyConfig, track?.id, activePages, section],
  );

  const consentSectionLinks = useMemo(
    () => ({
      sectionKind: APPLICATION_SECTION_URL_NAME.consent,
      sectionLabel: 'Accept Terms and Submit',
      disabled: !application?.[APPLICATION_MODEL_FIELD.hasSubmittedLiens] || shouldDisableOtherSections,
      key: APPLICATION_SECTION_URL_NAME.consent,
      // @ts-expect-error TS(7031): Binding element 'pageKey' implicitly has an 'any' ... Remove this comment to see the full error message
      navItems: consentConfig.map(({ pageKey, navLabel }) => {
        return {
          key: pageKey,
          page: pageKey,
          navLabel: navLabel,
          to: buildApplicationRoute(track?.id)({
            section: APPLICATION_SECTION_URL_NAME.consent,
            pageKey: pageKey,
          }),
          disabled: false,
        };
      }),
    }),
    [application, track?.id, shouldDisableOtherSections, consentConfig],
  );

  const allSectionLinks = useMemo(
    () => [...applicantSectionLinks, propertySectionLinks, consentSectionLinks],
    [propertySectionLinks, applicantSectionLinks, consentSectionLinks],
  );

  const currentSectionIdx = allSectionLinks.findIndex(({ sectionKind, id }) => {
    return sectionKind === section && (id === applicantId || section !== APPLICATION_SECTION_URL_NAME.applicants);
  });
  const currentSection = allSectionLinks[currentSectionIdx];
  // @ts-expect-error TS(7006): Parameter 'link' implicitly has an 'any' type.
  const pageIndex = currentSection?.navItems?.findIndex(link => link.page === page);
  const nextPage = currentSection?.navItems[pageIndex + 1];

  useEffect(() => {
    allSectionLinks.forEach(link => {
      // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
      link.navItems.forEach((item, i) => {
        if (item.to === location.pathname) {
          setNextAnticipatedPage(link.navItems[i + 1]?.to);
        }
      });
    });
  }, [location.pathname, setNextAnticipatedPage, allSectionLinks, nextAnticipatedPage, nextPage]);

  const lastSection = allSectionLinks[allSectionLinks.length - 1];
  const lastPageOfApplicationLink = lastSection.navItems[lastSection?.navItems.length - 1].to;
  const isLastPageOfApplication = matchPath(
    {
      path: lastPageOfApplicationLink,
      end: true,
    },
    location.pathname,
  );

  const contextData = {
    allSectionLinks,
    nextPage,
    isLastPageInSection: !nextPage,
    isLastPageOfApplication,
    currentPageIsDisabled: currentSection?.disabled || currentSection?.navItems[pageIndex]?.disabled,
    // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
    navigateToNewApplicant: ({ applicantId, pageKey } = {}) => {
      const nextPageRoute = buildApplicationRoute(track?.id)({
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey: pageKey || applicantConfig[0].pageKey,
        applicantId,
      });
      // @ts-expect-error TS(2345): Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
      setActivePages([]);
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setNextAnticipatedPage(nextPageRoute);
      navigate(nextPageRoute);
    },
    // @ts-expect-error TS(7006): Parameter 'pageKey' implicitly has an 'any' type.
    navigateToFirstApplicant: pageKey => {
      const nextPageRoute = buildApplicationRoute(track?.id)({
        section: APPLICATION_SECTION_URL_NAME.applicants,
        pageKey: pageKey || applicantConfig[0].pageKey,
        applicantId: applicants[0].id,
      });

      navigate(nextPageRoute, { replace: true });
    },
    navigateToNextPage: () => {
      let nextPageRoute = nextPage?.to;
      let newActivePages = [].concat(activePages ?? []);

      if (nextPage) {
        // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'.
        newActivePages = uniq([...newActivePages, nextPageRoute]);
      } else {
        nextPageRoute = allSectionLinks[currentSectionIdx + 1]?.navItems[0]?.to;
        newActivePages = [];
      }

      // @ts-expect-error TS(2345): Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
      setActivePages(newActivePages);
      if (nextPageRoute) {
        setNextAnticipatedPage(nextPageRoute);
        navigate(nextPageRoute);
      } else {
        // @ts-expect-error TS(2339): Property 'htap' does not exist on type 'Window & t... Remove this comment to see the full error message
        window.htap.redirectToTrackInnerIndex({ id: track.id });
      }
    },
  };

  return <ApplicationNavContext.Provider value={contextData}>{children}</ApplicationNavContext.Provider>;
};

const useApplicationNavContextContext = () => useContext(ApplicationNavContext);
export default useApplicationNavContextContext;
