import { Line } from '@visx/shape';

// @ts-expect-error TS(7031): Binding element 'height' implicitly has an 'any' t... Remove this comment to see the full error message
const ValueChartPin = ({ data = {}, height, color, hideLine, children }) => {
  // @ts-expect-error TS(2339): Property 'd' does not exist on type '{}'.
  if (!data.d || !data.v) {
    return null;
  }

  return (
    <g>
      {hideLine && (
        <Line
          // @ts-expect-error TS(2339): Property 'd' does not exist on type '{}'.
          from={{ x: data.d, y: height }}
          // @ts-expect-error TS(2339): Property 'd' does not exist on type '{}'.
          to={{ x: data.d, y: data.v }}
          stroke={color}
          strokeDasharray="3,2"
          strokeWidth={1}
        />
      )}
      <svg
        // @ts-expect-error TS(2339): Property 'd' does not exist on type '{}'.
        x={data.d - 17}
        // @ts-expect-error TS(2339): Property 'v' does not exist on type '{}'.
        y={data.v - 62}
        width="35"
        height="69"
        viewBox="0 0 35 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8813 30.3111C27.3766 32.8527 18.5938 39.7468 18.5938 47.7269V51.0596C18.5938 54.4447 21.3719 56.671 22.1375 57.4479C23.3297 58.6466 24.0625 60.3114 24.0625 62.1538C24.0625 65.8276 21.1313 68.8132 17.5 68.8132C13.8687 68.8132 10.9375 65.8276 10.9375 62.1538C10.9375 60.3114 11.6703 58.6466 12.8516 57.4479C13.6281 56.671 16.4062 54.4447 16.4062 51.0596C16.4062 47.6744 16.4062 47.7269 16.4062 47.7269C16.4062 39.7468 7.62344 32.8527 5.11875 30.3111C1.95781 27.1035 0 22.664 0 17.7582C0 7.94681 7.82031 0.0110989 17.5 0C27.1797 0.0110989 35 7.94681 35 17.7582C35 22.664 33.0422 27.1035 29.8813 30.3111Z"
          fill={color}
        />
        {children}
      </svg>
    </g>
  );
};

export default ValueChartPin;
