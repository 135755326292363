import { Checkbox } from '@hometap/htco-components';
import { FORM_FIELDS } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import LegalLink from 'components/LegalLink/LegalLink';
import './InquiryConsent.scss';

// @ts-expect-error TS(7031): Binding element 'registerField' implicitly has an ... Remove this comment to see the full error message
const InquiryConsent = ({ registerField }) => {
  return (
    <div className="InquiryConsentCheckbox">
      <Checkbox
        label="By checking this box, I agree to the following: "
        required
        {...registerField(FORM_FIELDS.CONSENT_GIVEN, 'checked')}
      />
      <ul className="InquiryConsentList">
        <li>
          <span>I understand this is not an application for credit and will not impact my credit score.</span>
        </li>
        <li>
          <span>
            I authorize Hometap to obtain information from credit bureaus and other information consistent with
            Hometap’s <LegalLink text="Credit Authorization Policy" section="credit-authorization" />.
          </span>
        </li>
        <li>
          <span>
            I have read and agreed to Hometap’s <LegalLink text="Terms of Use" section="terms-of-use" />,{' '}
            <LegalLink text="Privacy Policy" section="privacy-policy" />,{' '}
            <LegalLink
              text="Consent to Electronic Communications and Disclosures"
              section="consent-to-electronic-communications-and-disclosures"
            />
            , and <LegalLink section="sharing-of-information-disclosure" text="Sharing of Information Disclosure" />.
          </span>
        </li>
        <li>
          <span>
            By submitting this information, I agree that Hometap may send me automated texts regarding my response and
            that data rates may apply.
          </span>
        </li>
      </ul>
    </div>
  );
};

export default InquiryConsent;
