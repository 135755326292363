import dayjs from 'dayjs';

import { Container, useForm, DatePicker, NumberInput } from '@hometap/htco-components';
import TrackButton from '../../../components/TrackButton/TrackButton';
import '../SettlementCalculator.scss';

// @ts-expect-error TS(7031): Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
const SettlementForm = ({ onSubmit, isFetching, expirationDate, initialValues }) => {
  const { registerField, handleSubmit } = useForm({
    date: initialValues.date || '',
    estimatedHomeValue: initialValues.estimatedHomeValue || '',
  });

  // When parsing the expiration date to a date, use the end of the day as the time. Otherwise,
  // the time will default to the beginning of the date, and the expiration date will be one day
  // earlier than expected.
  const parsedExpirationDate = dayjs(expirationDate).endOf('day').toDate();

  return (
    <div className="SettlementForm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container row className="SettlementFormGridContainer">
          <Container row grid="6" className="SettlementFormGridContainerLeft">
            <DatePicker
              label="When do you plan to settle? *"
              required
              valueFormat="MM-dd-yyyy"
              placeholderText={'MM/DD/YYYY'}
              minDate={new Date()}
              maxDate={parsedExpirationDate}
              {...registerField('date')}
            />
          </Container>
          <Container grid="6" className="SettlementFormGridContainerRight">
            <NumberInput
              className="SettlementFormInput"
              label="What do you think your home will be worth? *"
              type="currency"
              placeholder="$XXX,XXX"
              required={true}
              precision={0}
              {...registerField('estimatedHomeValue')}
            />
          </Container>
        </Container>
        {/* @ts-expect-error TS(2741): Property 'eventProperties' is missing in type '{ c... Remove this comment to see the full error message */}
        <TrackButton type="submit" loading={isFetching}>
          Calculate my settlement amount
        </TrackButton>
      </form>
    </div>
  );
};

export default SettlementForm;
