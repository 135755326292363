import PropTypes from 'prop-types';

import { Container, Icon } from '@hometap/htco-components';
import './OnboardingPanelCard.scss';

const propTypes = {
  footerText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

const defaultProps = {
  icon: 'house-2',
  iconColor: '#366ced',
};

// @ts-expect-error TS(7031): Binding element 'dataTestId' implicitly has an 'an... Remove this comment to see the full error message
const OnboardingPanelCard = ({ dataTestId, headerText, imgSrc, imgAlt, icon, iconColor, footerText }) => {
  return (
    <>
      <Container className="PanelVisualContainer" data-testid={dataTestId}>
        <div className="PanelVisualTop">
          <div className="PanelVisualHeaderContainer">
            <span className="PanelVisualHeader">{headerText}</span>
          </div>
        </div>
        <div className="PanelVisualCenter">
          <div className="PanelVisualImageContainer">
            <img className="PanelVisualImage" src={imgSrc} alt={imgAlt} />
          </div>
        </div>
        {footerText && (
          <div className="PanelVisualBottom" data-testid="footer-address">
            <Icon className="PanelVisualIcon" color={iconColor} name={icon} />
            <span className="PanelVisualFooterText" data-testid="footer-text">
              {footerText}
            </span>
          </div>
        )}
      </Container>
    </>
  );
};

OnboardingPanelCard.propTypes = propTypes;
OnboardingPanelCard.defaultProps = defaultProps;
export default OnboardingPanelCard;
