import { useEffect, useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import { Paper, SlideSide, useWindowSize, Loader, NotFoundBlock } from '@hometap/htco-components';

import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

import ApplicationHeader from './components/ApplicationHeader/ApplicationHeader';
import ApplicationNav from './components/ApplicationNav/ApplicationNav';
import useApplicationContext from './hooks/useApplicationContext';
import ApplicationPageFromConfig from './components/ApplicationPageFromConfig';
import useApplicationNavContext from './hooks/useApplicationNavContext';
import './Application.scss';

const ApplicationController = () => {
  const [isNavVisible, setIsNavVisible] = useState(true);
  const { isScreenSize, screenWidth } = useWindowSize();

  const { trackId } = useParams();

  // @ts-expect-error TS(2339): Property 'isInitialLoading' does not exist on type... Remove this comment to see the full error message
  const { isInitialLoading, hasFetchError, application } = useApplicationContext();

  const applicationIsV2 = application?.application_version === 'v2';
  // @ts-expect-error TS(2339): Property 'allSectionLinks' does not exist on type ... Remove this comment to see the full error message
  const { allSectionLinks } = useApplicationNavContext();

  const isScreenMd = isScreenSize('md');
  const isScreenXs = isScreenSize('xs');

  useEnableGlia(true, false);
  useGliaIdentification();

  useEffect(() => {
    if (!isScreenMd) {
      setIsNavVisible(true);
    } else {
      setIsNavVisible(false);
    }
  }, [isScreenMd]);

  if (application?.has_consented) {
    // @ts-expect-error TS(2339): Property 'htap' does not exist on type 'Window & t... Remove this comment to see the full error message
    window.htap.redirectToTrackInnerIndex({ id: trackId });
  }

  if (isInitialLoading) {
    return <Loader className="ApplicationLoader" type="spinner" size="large" />;
  }

  if (!!application && (!applicationIsV2 || hasFetchError)) {
    return (
      <div className="ApplicationFlagDisabledOrError">
        <NotFoundBlock />
      </div>
    );
  }

  return (
    <div className="ApplicationController">
      <ApplicationHeader
        trackId={trackId}
        // @ts-expect-error TS(2322): Type '{ trackId: string | undefined; visibleNav: b... Remove this comment to see the full error message
        visibleNav={isNavVisible}
        onToggleNavVisible={() => setIsNavVisible(!isNavVisible)}
      />
      <Paper className="ApplicationContainer">
        <SlideSide
          className="ApplicationNavContainer"
          isOverlay={isScreenMd}
          visible={isNavVisible}
          maxWidth={isScreenXs ? screenWidth : 300}
          displayBackdrop={isScreenMd}
          onBackdropClick={() => isScreenMd && setIsNavVisible(false)}
        >
          <ApplicationNav sections={allSectionLinks} onCloseNav={() => setIsNavVisible(false)} />
        </SlideSide>

        <div className="ApplicationContentContainer">
          <div className="ApplicationContent">
            <Routes>
              <Route
                path=":section?/:page?/:applicantId?"
                element={
                  // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
                  <PrivateRoute>
                    <ApplicationPageFromConfig />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
          <figure className="ApplicationPlant" />
        </div>
      </Paper>
    </div>
  );
};

export default ApplicationController;
