import { Badge, Button } from '@hometap/htco-components';
import KeyValue from 'components/KeyValue/KeyValue';
import { Link } from 'react-router-dom';

// @ts-expect-error TS(7031): Binding element 'keyName' implicitly has an 'any' ... Remove this comment to see the full error message
const TrackDetailsKeyValue = ({ keyName, value, className }) => {
  return (
    <KeyValue
      className={className}
      keyName={keyName}
      keyClassName="pr-1"
      value={value}
      valueClassName="font-bold text-neutral-dark-100"
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      separator={null}
    />
  );
};

// @ts-expect-error TS(7031): Binding element 'cta' implicitly has an 'any' type... Remove this comment to see the full error message
const TrackDetailsKeyValues = ({ cta, badge, keyValues = [], header }) => {
  return (
    <div className="flex flex-col justify-between xs:flex-row">
      <div>
        <div className="mb-2 mt-0 flex flex-row flex-wrap-reverse items-center sm:mb-6">
          <TrackDetailsKeyValue
            className="mr-4 min-w-full pt-0 text-base text-neutral-dark-75 sm:min-w-0 sm:text-lg"
            keyName={header.key}
            value={header.value}
          />

          <Badge className="static mb-4 sm:mb-0" label={badge.label} theme={badge.theme} />
        </div>

        {keyValues.map(({ key, value }) => (
          <TrackDetailsKeyValue
            key={key}
            className="text-sm text-neutral-dark-75 sm:text-base [&:not(:first-child)]:mt-2"
            keyName={key}
            value={value}
          />
        ))}
      </div>
      {/* TODO: PAT-1047: htco- is a hack to fix a css bug that is set in base.scss */}
      <Link to={cta.link} className="htco- mt-4 max-h-max w-full xs:mt-0 xs:flex xs:w-auto">
        <Button className="[&.htco-Button]:m-0 [&.htco-Button]:w-full">{cta.copy}</Button>
      </Link>
    </div>
  );
};

export default TrackDetailsKeyValues;
