import { Icon } from '@hometap/htco-components';
import PropTypes from 'prop-types';

// @ts-expect-error TS(7031): Binding element 'icon' implicitly has an 'any' typ... Remove this comment to see the full error message
const NavIcon = ({ icon }) => {
  return (
    <div data-testid="nav-icon" className="flex items-center text-2xl">
      <Icon name={icon} />
    </div>
  );
};

export default NavIcon;

NavIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};
