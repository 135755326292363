import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Container } from '@hometap/htco-components';
import TrustPilot from 'apps/progressive-inquiry/components/TrustPilot';
import './ProgressiveUITemplate.scss';

const DEFAULT_GRID_SIZE = 6;

const layoutPropTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const panelPropTypes = {
  containsVisual: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  gridSize: PropTypes.number,
  header: PropTypes.node,
};

const imagePanelPropTypes = {
  imageSource: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  includeTrustPilot: PropTypes.bool,
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ProgressiveUITemplate = ({ children }) => {
  // This effect will scope a few style overrides to this component in
  // order to achieve a full page layout
  useEffect(() => {
    const FULL_PAGE_CLASSNAME = 'ProgressiveUIFullPageHtml';
    const HEADER_CLASSNAME = 'ProgressiveUIHeader';

    const htmlElement = document.querySelector('html');
    const headerElement = document.querySelector('header');

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    htmlElement.classList.add(FULL_PAGE_CLASSNAME);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    headerElement.classList.add(HEADER_CLASSNAME);
  }, []);

  return (
    <div className="ProgressiveUIPageLayout">
      <Container className="ProgressiveUIPageContainer" row space={0}>
        {children}
      </Container>
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'imageSource' implicitly has an 'a... Remove this comment to see the full error message
const ImageContentPanel = ({ imageSource, imgAlt, includeTrustPilot = false, isCentered = false }) => {
  return (
    <div className={cx('ProgressiveUIImageContentPanel', { hasTrustPilot: includeTrustPilot, isCentered })}>
      <img src={imageSource} alt={imgAlt} />
      {includeTrustPilot && (
        <div className="ProgressiveUIImageContentTrustPilot">
          <div className="GradientLayer" />
          {/* @ts-expect-error TS(2739): Type '{ divider: true; }' is missing the following... Remove this comment to see the full error message */}
          <TrustPilot divider={true} />
        </div>
      )}
    </div>
  );
};

const ContentPanel = ({
  // @ts-expect-error TS(7031): Binding element 'cropImage' implicitly has an 'any... Remove this comment to see the full error message
  cropImage,
  // @ts-expect-error TS(7031): Binding element 'containsVisual' implicitly has an... Remove this comment to see the full error message
  containsVisual,
  greyTheme = false,
  // @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  gridSize = DEFAULT_GRID_SIZE,
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
}) => {
  const containerClasses = cx(
    {
      ProgressiveUIPagePanel: true,
      FlexVisualParent: containsVisual,
      greyTheme,
    },
    className,
  );
  const childClasses = cx({ FlexVisualChild: containsVisual, CroppedImage: cropImage });

  return (
    <Container className="ProgressiveUIPagePanelContainer" grid={gridSize}>
      <div className={containerClasses}>
        <div className={childClasses}>{children}</div>
      </div>
    </Container>
  );
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const CallToAction = ({ children }) => {
  return <div className="ProgressiveUIPageCTA">{children}</div>;
};

ProgressiveUITemplate.propTypes = layoutPropTypes;
ContentPanel.propTypes = panelPropTypes;
ImageContentPanel.propTypes = imagePanelPropTypes;

export { ProgressiveUITemplate, CallToAction, ContentPanel, ImageContentPanel };
