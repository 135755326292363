import { useCallback, useState } from 'react';
import {
  createApplicant,
  deleteApplicant,
  fetchApplicationApplicants,
  updateApplicant,
} from '../data/applicationRequests';

import { useAsync } from '@hometap/htco-components';
import { getFormattedApplicantFieldErrors, onSaveError } from '../utils';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
const getSaveErrorCopy = error => {
  const formattedErrors = getFormattedApplicantFieldErrors(error);
  const errorCopy = (
    <p>
      Something went wrong while creating this applicant.
      {Boolean(formattedErrors?.length) && (
        <>
          {' '}
          Please address the following fields: <b>{formattedErrors}</b>
        </>
      )}
    </p>
  );
  return errorCopy;
};

const useApplicants = () => {
  const [selectedApplicantForDeletion, setSelectedApplicantForDeletion] = useState(null);

  const fetchApplicantsAsync = useAsync(fetchApplicationApplicants);
  const deleteApplicantAsync = useAsync(deleteApplicant, { executeThrow: true });
  const createApplicantAsync = useAsync(createApplicant, { executeThrow: true });
  const updateApplicantAsync = useAsync(updateApplicant, { executeThrow: true });

  const { execute: executeFetchApplicants, loading: fetchLoading } = fetchApplicantsAsync;

  const isLoading =
    fetchLoading || deleteApplicantAsync.loading || createApplicantAsync.loading || updateApplicantAsync.loading;

  // @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
  const deleteApplicantAndRefetch = async ({ applicationId }) => {
    await deleteApplicantAsync
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      .execute({ applicationId, applicantId: selectedApplicantForDeletion?.id })
      .catch(onSaveError('We are having trouble deleting this applicant. Please try refresh and try again.'));

    return await executeFetchApplicants({ applicationId });
  };

  // @ts-expect-error TS(7031): Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  const saveApplicantAndRefetch = async ({ data, applicantId, applicationId }) => {
    let savedApplicant;

    if (applicantId) {
      savedApplicant = await updateApplicantAsync
        .execute({
          applicantId,
          applicationId,
          data,
        })
        // @ts-expect-error TS(2345): Argument of type '(error: any) => Element' is not ... Remove this comment to see the full error message
        .catch(onSaveError(getSaveErrorCopy));
    } else {
      // @ts-expect-error TS(2345): Argument of type '(error: any) => Element' is not ... Remove this comment to see the full error message
      savedApplicant = await createApplicantAsync.execute({ applicationId, data }).catch(onSaveError(getSaveErrorCopy));
    }

    if (!savedApplicant) return;
    await executeFetchApplicants({ applicationId });
    return savedApplicant;
  };

  return {
    isLoading,
    fetchLoading,
    saveApplicantAndRefetch,
    executeFetchApplicants: useCallback(
      // @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
      ({ applicationId }) => executeFetchApplicants({ applicationId }),
      [executeFetchApplicants],
    ),
    selectedApplicantForDeletion,
    setSelectedApplicantForDeletion,
    deleteApplicantAndRefetch,
    applicants: fetchApplicantsAsync.results,
    fetchError: fetchApplicantsAsync.error,
  };
};

export default useApplicants;
