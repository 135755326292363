// @ts-expect-error TS(7006): Parameter 'word' implicitly has an 'any' type.
export const capitalizeFirstLetter = word => word?.charAt(0).toUpperCase() + word?.slice(1);

/**
 *
 * @param {string} phone expected to be in format 555-555-1234
 * @param {boolean} proper if true will return in format (555) 555-1234, if false 555.555.1234
 * @param {string} join what character to use as separator when proper is false
 * @returns
 */
// @ts-expect-error TS(7006): Parameter 'phone' implicitly has an 'any' type.
export const formatPhoneNumberSeparator = (phone, proper = true, join = '.') => {
  if (proper) {
    const digits = phone?.split('-');
    return `(${digits[0]}) ${digits[1]}-${digits[2]}`;
  }
  return phone?.split('-').join(join);
};

// @ts-expect-error TS(7031): Binding element 'first_name' implicitly has an 'an... Remove this comment to see the full error message
export const formatFullName = ({ first_name, last_name, middle_name }) => {
  // TODO: we should probably handle cases when first_name or last_name could be undefined
  const formattedMiddleName = !middle_name || middle_name.toLowerCase() === 'n/a' ? '' : middle_name;

  const fullName = `${first_name} ${formattedMiddleName} ${last_name}`;
  return fullName
    .split(' ')
    .filter(n => n)
    .map(name => capitalizeFirstLetter(name))
    .join(' ');
};

// @ts-expect-error TS(7006): Parameter 'name' implicitly has an 'any' type.
export const formatName = name => {
  // these separators require capitalization after the delimiter
  const separators = [' ', '-', "'"];
  // to use split with multiple separators, we need to use a regex
  const separatorsRegex = /['-\s]/;
  if (name.split(separatorsRegex).length === 1) {
    return capitalizeFirstLetter(name);
  }
  return separators.reduce((formattedName, separator) => {
    if (formattedName.includes(separator)) {
      return (
        formattedName
          .split(separator)
          // @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
          .map(n => capitalizeFirstLetter(n))
          .join(separator)
      );
    }
    return formattedName;
  }, name);
};
