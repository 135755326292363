import { MuiTextInput } from '@hometap/htco-components';
import { SHARED_INPUT_PROPS, TEXT_INPUTS } from './sharedFields';
import { positiveValueValidator } from 'utils/validators';

// @ts-expect-error TS(7031): Binding element 'registerField' implicitly has an ... Remove this comment to see the full error message
const HouseCanaryHELoanForm = ({ registerField }) => {
  return TEXT_INPUTS.map(input => (
    <MuiTextInput
      key={input.label}
      {...SHARED_INPUT_PROPS}
      label={input.label}
      aria-label={input.label}
      mask={input.mask}
      startAdornment={input.startAdornment}
      endAdornment={input.endAdornment}
      type={input.type}
      inputMode={input.inputMode}
      placeholder={input.placeholder}
      // @ts-expect-error TS(2339): Property 'required' does not exist on type '{ labe... Remove this comment to see the full error message
      required={input.required || true}
      // @ts-expect-error TS(2339): Property 'helperText' does not exist on type '{ la... Remove this comment to see the full error message
      helperText={input.helperText}
      validator={positiveValueValidator}
      {...registerField(input.name)}
    />
  ));
};

export default HouseCanaryHELoanForm;
