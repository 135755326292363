import { useState, useEffect } from 'react';

const useTimedRender = (duration = 1250) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timer = null;

    if (shouldRender) {
      timer = setTimeout(() => {
        setShouldRender(false);
      }, duration);
    }

    // @ts-expect-error TS(7005): Variable 'timer' implicitly has an 'any' type.
    return () => clearTimeout(timer);
  }, [shouldRender, duration]);

  const triggerRender = () => {
    setShouldRender(true);
  };

  return [shouldRender, triggerRender];
};

export default useTimedRender;
