// Calculates total balance and interest by using logic for an amortization table.
// This loops through periodic loan payments, calculating the balance and interest
// that comprise each payment until the loan is paid off at the end of its term.

// Calculations are based off https://docs.google.com/spreadsheets/d/1BYcWaZoUSCokh86bYWgCy5ZJt2uvUBTjMFTCADKvrqA/edit#gid=0

// @ts-expect-error TS(7006): Parameter 'balance' implicitly has an 'any' type.
export const buildAmortizationTable = (balance, rate, payment) => {
  let i = 0;
  const initialBalance = balance;
  let totalInterest = 0;

  // Determine how many payments will be needed for the specified balance,
  // rate and payment.  We will stop our while loop once we've made more
  // than 360 monthly payments (30 years).
  while (balance > 0 && i <= 360) {
    const interest = balance * (rate / 12);
    const periodPayment = payment - interest > balance ? balance + interest : payment;
    const principal = periodPayment - interest;
    // const endingValue = balance - principal;
    totalInterest = totalInterest + interest;
    balance -= principal;
    i++;
  }

  return {
    totalBalance: initialBalance + totalInterest,
    totalInterest,
    paymentsToZero: i,
  };
};

// @ts-expect-error TS(7006): Parameter 'balance' implicitly has an 'any' type.
export const isValidDebtInput = (balance, rate, payment) => {
  const interest = balance * (rate / 12);
  const periodPayment = payment - interest > balance ? balance + interest : payment;
  return periodPayment - interest > 1;
};

// @ts-expect-error TS(7006): Parameter 'balance' implicitly has an 'any' type.
export const getInvalidCalculationErrorMessage = (balance, payment) => {
  if (balance > payment) {
    return 'The estimate is based on a 30-year payoff period, and the monthly payment you entered won’t cover the balance within that time frame. Please adjust your monthly payment amount.';
  }
  return 'The current balance is too low, try increasing the amount.';
};

// @ts-expect-error TS(7006): Parameter 'debts' implicitly has an 'any' type.
export const totalDebtCalculations = debts => {
  return debts.reduce(
    // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
    (acc, { balance, interestRate, monthlyPayment }) => {
      acc.totalMonthlyPayment = acc.totalMonthlyPayment + monthlyPayment;
      const { totalBalance, paymentsToZero, totalInterest } = buildAmortizationTable(
        balance,
        interestRate / 100,
        monthlyPayment,
      );
      acc.totalBalance = acc.totalBalance + totalBalance;
      acc.totalInterestPayments = acc.totalInterestPayments + totalInterest;
      acc.paymentsToZero = Math.max(acc.paymentsToZero, paymentsToZero);
      acc.totalPrincipalBalance = acc.totalPrincipalBalance + balance;
      return acc;
    },
    { totalBalance: 0, paymentsToZero: 0, totalInterestPayments: 0, totalMonthlyPayment: 0, totalPrincipalBalance: 0 },
  );
};

// @ts-expect-error TS(7006): Parameter 'debts' implicitly has an 'any' type.
export const barChartDebtCalculations = debts => {
  // @ts-expect-error TS(7031): Binding element 'xAxisLabel' implicitly has an 'an... Remove this comment to see the full error message
  return debts?.map(({ xAxisLabel, balance, interestRate, monthlyPayment, colors, icon }) => {
    const { totalInterest } = buildAmortizationTable(balance, interestRate / 100, monthlyPayment);
    return {
      balance,
      xAxisLabel,
      icon,
      balanceColor: colors.balance,
      interestColor: colors.interest,
      interest: totalInterest,
    };
  });
};

// @ts-expect-error TS(7006): Parameter 'monthsLeft' implicitly has an 'any' typ... Remove this comment to see the full error message
export const calculatePayoffDate = monthsLeft => {
  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date();
  date.setMonth(date.getMonth() + monthsLeft);
  const ttpdString = date.toLocaleDateString();
  const ttpdParts = ttpdString.split('/');
  // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  return `${MONTHS[ttpdParts[0] - 1]} ${ttpdParts.slice(-1)}`;
};
