import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Button, Icon, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';
import { getDateDifference } from 'utils/date';
import { currency, percent } from 'utils/numbers';
import { buildNewHEDUrl, editHomeProfileUrl, getNewHEDUrl } from 'utils/links';
import { EQUITY_SCENARIO_LIEN_TYPE, LIEN_FIELDS, SCENARIO_PERIOD_OPTIONS } from '../../../constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { getRenovationSummary, parseRenovationKind } from '../equity-renovations/data/renovationUtils';
import { deleteRenovation } from '../equity-renovations/data/requests';
import { FINANCE_CALCULATOR_TYPE } from '../equity-finance/data/financeData';
import './HomeInfoCards.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';

const LIEN_TITLE_MAP = {
  [EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE]: 'Mortgage',
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN]: 'Home Equity Loan',
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT]: 'Home Equity Line of Credit',
  [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT]: 'Home Equity Investment',
  [EQUITY_SCENARIO_LIEN_TYPE.OTHER]: 'Judgement, Tax, or Other Lien',
};

const ScenarioActionsDropdown = ({
  // @ts-expect-error TS(7031): Binding element 'onToggleScenario' implicitly has ... Remove this comment to see the full error message
  onToggleScenario,
  // @ts-expect-error TS(7031): Binding element 'onDeleteScenario' implicitly has ... Remove this comment to see the full error message
  onDeleteScenario,
  // @ts-expect-error TS(7031): Binding element 'onEditScenario' implicitly has an... Remove this comment to see the full error message
  onEditScenario,
  // @ts-expect-error TS(7031): Binding element 'isCardEnabled' implicitly has an ... Remove this comment to see the full error message
  isCardEnabled,
  // @ts-expect-error TS(7031): Binding element 'activeCardId' implicitly has an '... Remove this comment to see the full error message
  activeCardId,
  // @ts-expect-error TS(7031): Binding element 'setActiveCardId' implicitly has a... Remove this comment to see the full error message
  setActiveCardId,
  // @ts-expect-error TS(7031): Binding element 'cardId' implicitly has an 'any' t... Remove this comment to see the full error message
  cardId,
}) => {
  const actions = [
    { label: 'Edit details', onClick: onEditScenario },
    { label: isCardEnabled ? 'Hide from chart' : 'Show on chart', onClick: onToggleScenario },
    { label: 'Delete from scenario', onClick: onDeleteScenario },
  ].filter(action => !!action.onClick);

  if (!actions.length) return null;

  const onMenuChange = () => {
    setActiveCardId(cardId);
  };

  return (
    <MenuDropdown
      onMenuChange={onMenuChange}
      isMenuOpen={activeCardId && activeCardId === cardId}
      size="2x"
      theme="dark"
    >
      {actions.map(({ onClick, label }, i) => (
        <MenuDropdownItem key={`${label}-${i}`} onClick={onClick} href={null}>
          {label}
        </MenuDropdownItem>
      ))}
    </MenuDropdown>
  );
};

const ScenarioCard = ({
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031): Binding element 'content' implicitly has an 'any' ... Remove this comment to see the full error message
  content,
  // @ts-expect-error TS(7031): Binding element 'cta' implicitly has an 'any' type... Remove this comment to see the full error message
  cta,
  // @ts-expect-error TS(7031): Binding element 'exploreOptionsCtaClick' implicitl... Remove this comment to see the full error message
  exploreOptionsCtaClick,
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(7031): Binding element 'isCardEnabled' implicitly has an ... Remove this comment to see the full error message
  isCardEnabled,
  // @ts-expect-error TS(7031): Binding element 'onToggleScenario' implicitly has ... Remove this comment to see the full error message
  onToggleScenario,
  // @ts-expect-error TS(7031): Binding element 'onDeleteScenario' implicitly has ... Remove this comment to see the full error message
  onDeleteScenario,
  // @ts-expect-error TS(7031): Binding element 'onEditScenario' implicitly has an... Remove this comment to see the full error message
  onEditScenario,
  // @ts-expect-error TS(7031): Binding element 'cardId' implicitly has an 'any' t... Remove this comment to see the full error message
  cardId,
  // @ts-expect-error TS(7031): Binding element 'setActiveCardId' implicitly has a... Remove this comment to see the full error message
  setActiveCardId,
  // @ts-expect-error TS(7031): Binding element 'activeCardId' implicitly has an '... Remove this comment to see the full error message
  activeCardId,
  // @ts-expect-error TS(7031): Binding element 'dataTestId' implicitly has an '... Remove this comment to see the full error message
  dataTestId,
}) => {
  return (
    <div className={cx('PropertyScenarioCard', className)} data-testid={dataTestId}>
      <div className="PropertyDetailsContent">
        <div className={cx('PropertyScenarioCardTitle', { disableTitle: !isCardEnabled })}>{title}</div>
        <div className={cx('PropertyScenarioCardDetails', { disableDetails: !isCardEnabled })}>{content}</div>
        {cta && (
          <Button className="PropertyScenarioCardCTA" size="small" theme="link" onClick={exploreOptionsCtaClick}>
            <span>
              {cta} <Icon name="arrow-forward" />
            </span>
          </Button>
        )}
      </div>
      <ScenarioActionsDropdown
        onToggleScenario={onToggleScenario}
        onDeleteScenario={onDeleteScenario}
        onEditScenario={onEditScenario}
        isCardEnabled={isCardEnabled}
        cardId={cardId}
        setActiveCardId={setActiveCardId}
        activeCardId={activeCardId}
      />
    </div>
  );
};

// @ts-expect-error TS(7031): Binding element 'lien' implicitly has an 'any' typ... Remove this comment to see the full error message
export const LienCard = ({ lien, exploreOptionsCtaClick, ...props }) => {
  const hasLienKindOther = lien.lien_kind === LIEN_FIELDS.LIEN_KIND_OTHER;
  const lienKind = hasLienKindOther ? lien.lien_kind_other : lien.lien_kind;
  let term = SCENARIO_PERIOD_OPTIONS.find(option => option.value === lienKind);

  // For external liens (such as Plaid) we receive a variety of lien kinds,
  // so if we don't find it in our internal SCENARIO_PERIOD_OPTIONS we label it by the lien_kind_other field
  if (hasLienKindOther && !term) {
    // @ts-expect-error TS(2741): Property 'value' is missing in type '{ label: any;... Remove this comment to see the full error message
    term = { label: lien.lien_kind_other };
  }

  const navigate = useNavigate();

  const { currentScenario, futureScenario, deleteLien, hideLien, unhideLien, isFutureView } =
    useEquityScenarioContext();

  const contentText = `${percent(lien.rate, '0.0[00]')}, ${currency(lien.estimated_lien_balance)}, ${currency(
    lien.monthly_payment,
  )}`;
  const cardContent =
    lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE ? `${term?.label}, ${contentText}` : contentText;

  const financeOptionMap = {
    [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN]: FINANCE_CALCULATOR_TYPE.HEL,
    [EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT]: FINANCE_CALCULATOR_TYPE.HELOC,
  };
  const financeOption = financeOptionMap[lien.lien_type] || FINANCE_CALCULATOR_TYPE.HEI;

  const handleEditScenario = () => {
    // Go to the add debts flow for the future scenario
    if (lien?.lien_type && isFutureView) {
      return navigate(getNewHEDUrl('/explore-financing'), { state: { lienType: lien.lien_type, stage: 2 } });
    }

    // Go to the edit home profile flow for the current scenario
    return navigate(editHomeProfileUrl(), { state: { lienType: lien.lien_type, step: 3, noConfirm: true } });
  };

  const handleDeleteLienFromScenario = () => {
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
    deleteLien(currentScenario?.id, lien.id);
  };

  const handleToggleScenario = () => {
    const scenarioToUpdate = isFutureView ? futureScenario : currentScenario;

    if (!lien.hidden) {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
      hideLien(scenarioToUpdate?.id, lien);
    } else {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
      unhideLien(scenarioToUpdate?.id, lien);
    }
  };

  const title = LIEN_TITLE_MAP[lien.lien_type];

  return (
    // @ts-expect-error TS(2739): Type '{ className: string; title: string; content:... Remove this comment to see the full error message
    <ScenarioCard
      className="WithLien"
      title={title}
      content={cardContent}
      onToggleScenario={handleToggleScenario}
      onDeleteScenario={isFutureView && handleDeleteLienFromScenario}
      onEditScenario={handleEditScenario}
      isCardEnabled={!lien?.hidden}
      // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
      exploreOptionsCtaClick={e => exploreOptionsCtaClick(e, financeOption)}
      {...props}
    />
  );
};

// @ts-expect-error TS(7031): Binding element 'renovation' implicitly has an 'an... Remove this comment to see the full error message
export const RenoCard = ({ renovation, toggleChartRenovations, ...props }) => {
  // @ts-expect-error TS(2339): Property 'project' does not exist on type '{ proje... Remove this comment to see the full error message
  const { project, finishLevel, addedValue, costRecouped } = getRenovationSummary(renovation);
  const { home } = useCurrentHome();
  const { futureScenario, changeScenarioRenovations, isFutureView } = useEquityScenarioContext();
  const navigate = useNavigate();

  const content = [finishLevel, currency(addedValue), costRecouped && percent(costRecouped)].filter(Boolean).join(', ');

  const handleDeleteRenoFromScenario = async () => {
    // @ts-expect-error TS(2339): Property 'renovations' does not exist on type '{}'... Remove this comment to see the full error message
    const remainingRenos = futureScenario?.renovations?.filter(({ id }) => id !== renovation.id);
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    changeScenarioRenovations([...remainingRenos]);
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    await deleteRenovation(home?.id, futureScenario?.id, renovation.id);
  };

  const handleEditScenario = () => {
    return navigate(
      buildNewHEDUrl({
        renoType: parseRenovationKind(renovation.renovation_kind).project.toLowerCase(),
        renoId: renovation.id,
      }),
    );
  };

  const handleToggleScenario = () => {
    toggleChartRenovations(renovation);
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    changeScenarioRenovations(futureScenario?.renovations);
  };
  return (
    <ScenarioCard
      title={project}
      content={content}
      onToggleScenario={handleToggleScenario}
      onDeleteScenario={isFutureView && handleDeleteRenoFromScenario}
      onEditScenario={handleEditScenario}
      className="WithReno"
      isCardEnabled={!renovation?.hidden}
      // @ts-expect-error TS(2322): Type '{ title: any; content: string; onToggleScena... Remove this comment to see the full error message
      isFuture={isFutureView}
      {...props}
    />
  );
};

// @ts-expect-error TS(7031): Binding element 'investment' implicitly has an 'an... Remove this comment to see the full error message
export const InvestmentCard = ({ investment, exploreOptionsCtaClick, showHEI, toggleChartHEI, ...props }) => {
  const { futureScenario, isFutureView, deleteLien } = useEquityScenarioContext();

  const term = getDateDifference(investment.effective_date, investment.maturity_date, 'years');
  const content = `${term} years, ${currency(investment.investment_amount)}`;

  const lien = isFutureView
    ? // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
      futureScenario?.liens?.find(lien => lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT)
    : null;

  const handleDeleteLienFromScenario = () => {
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
    deleteLien(futureScenario?.id, lien.id);
  };

  const handleToggleScenario = () => {
    return toggleChartHEI(!showHEI);
  };

  return (
    <ScenarioCard
      title="Home Equity Investment"
      content={content}
      // @ts-expect-error TS(2322): Type '{ title: string; content: string; cardType: ... Remove this comment to see the full error message
      cardType="hei"
      className="WithHEI"
      investment={investment}
      onToggleScenario={handleToggleScenario}
      onDeleteScenario={lien && isFutureView && handleDeleteLienFromScenario}
      isCardEnabled={showHEI}
      // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
      exploreOptionsCtaClick={e => exploreOptionsCtaClick(e, 'hei')}
      {...props}
    />
  );
};
