import { Button, Container, Paper } from '@hometap/htco-components';
import SecondaryLayout from 'components/Layouts/SecondaryLayout/SecondaryLayout';
import { getNewInquiryUrl, HOMEOWNER_DASHBOARD } from 'utils/links';

const TrackExpiredClosed = () => {
  return (
    // @ts-expect-error TS(2739): Type '{ children: Element; prevUrl: string; allowA... Remove this comment to see the full error message
    <SecondaryLayout prevUrl={`/${HOMEOWNER_DASHBOARD.ROOT}`} allowAccountDropdown={true}>
      <Container row space={5} justify="center">
        <Container grid="8">
          <Paper pad={5}>
            <h1>Glad you’re back!</h1>
            <p>
              It’s been a little while and this investment opportunity is no longer available. Would you like to start a
              new investment?
            </p>
            <Button href={getNewInquiryUrl()}>Start a new Investment</Button>
            <Button theme="secondary" href="/dashboard">
              Back to My Dashboard
            </Button>
          </Paper>
        </Container>
      </Container>
    </SecondaryLayout>
  );
};

export default TrackExpiredClosed;
