import KeyValue from 'components/KeyValue/KeyValue';
import { currency } from 'utils/numbers';
import cx from 'classnames';

// @ts-expect-error TS(7031): Binding element 'homeValue' implicitly has an 'any... Remove this comment to see the full error message
const HomeValKeyValue = ({ homeValue, ...props }) => {
  return (
    // @ts-expect-error TS(2739): Type '{ className: string; keyName: string; valueC... Remove this comment to see the full error message
    <KeyValue
      className="font-serif text-xl sm:text-2xl sm:leading-9"
      keyName={homeValue ? 'Home value' : 'Est. home value'}
      valueClassName={cx({ 'text-green-medium': homeValue, 'text-neutral-dark-100': !homeValue })}
      value={homeValue && currency(homeValue)}
      testId="home-value-key-value"
      {...props}
    />
  );
};

export default HomeValKeyValue;
