import { Container, Icon, Paper } from '@hometap/htco-components';

import './Hero.scss';

// @ts-expect-error TS(7031): Binding element 'image' implicitly has an 'any' ty... Remove this comment to see the full error message
const Hero = ({ image, heading, buttonText, altText }) => {
  return (
    <Container className="HeroContainer" row justify="flex-end">
      <Container className="HeroInnerContainer" grid="12">
        <Paper theme="medium" className="Hero">
          <div className="HeroQuote">
            <div className="HeroImage">
              <img src={image} alt={altText} />
            </div>
            <div className="HeroContentWrapper">
              <div className="HeroHeadingWrapper">
                <h2 className="HeroHeading">{heading}</h2>
              </div>
              <a
                href="https://www.hometap.com/homeowner-resources/good-vs-bad-debt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="HeroButtonWrapper">
                  {buttonText} <Icon name="arrow-right" className="HeroArrowIcon" size="lg" />
                </div>
              </a>
            </div>
          </div>
        </Paper>
      </Container>
    </Container>
  );
};

export default Hero;
