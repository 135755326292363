import sentry from 'utils/sentry';

import { useAsync } from '@hometap/htco-components';
import { createInquiry, patchInquiry } from '../api/inquiryRequests';
import { showNotification } from 'utils/toasts';

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export const buildNotificationFieldErrorFromResponse = (data, notifications = []) => {
  let currentNotifications = notifications;
  if (typeof data === 'object') {
    Object.keys(data).forEach(key => {
      const value = data[key];
      if (Array.isArray(value)) {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        currentNotifications.push(`${key}: ${value.join(' ')}`);
        return;
      }
      if (typeof value === 'object') {
        currentNotifications = buildNotificationFieldErrorFromResponse(value, currentNotifications);
      }
    });
  }

  return currentNotifications;
};

// @ts-expect-error TS(7006): Parameter 'axiosRequest' implicitly has an 'any' t... Remove this comment to see the full error message
const useAsyncWithErrorNotification = (axiosRequest, defaultSentryMessage, options) => {
  const useAsyncInstance = useAsync(axiosRequest, {
    // @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
    onError: error => {
      if (error.response?.status === 400) {
        const { data } = error.response;
        const messages = buildNotificationFieldErrorFromResponse(data);

        if (messages?.length > 0) {
          // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
          showNotification({
            type: 'error',
            title: 'Error',
            description: messages.join(' '),
          });
        }
        return;
      }
      // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
      showNotification({
        type: 'error',
        title: 'Error',
        description: 'Something went wrong. Please refresh and try again.',
      });
      sentry.logErrorWrapper(defaultSentryMessage, error);
    },
    ...options,
  });

  return useAsyncInstance;
};

export const useAsyncCreateInquiry = (defaultSentryErrorMessage = 'Failed to create inquiry', options = {}) =>
  useAsyncWithErrorNotification(createInquiry, defaultSentryErrorMessage, options);

export const useAsyncPatchInquiry = (defaultSentryErrorMessage = 'Failed to patch inquiry', options = {}) =>
  useAsyncWithErrorNotification(patchInquiry, defaultSentryErrorMessage, options);
