import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useAsync } from '@hometap/htco-components';
import { apiWithAuth } from 'utils/api';
import { HomeownerUpdateTrack, ReadTrack, RolloutFlagsEnum } from 'api-spec';
import { AxiosResponse } from 'axios';

export const ROLLOUT_FLAGS = {
  self_serve_appraisal_type: 'self_serve_appraisal_type',
  appreciation_default: 'appreciation_default',
};

const fetchTrack = async (id: string): Promise<AxiosResponse<ReadTrack>> => {
  return await apiWithAuth.v1.get(`/tracks/${id}`);
};

export const patchTrack = async <T extends HomeownerUpdateTrack>(id: string, data: T): Promise<AxiosResponse<T>> => {
  return await apiWithAuth.v1.patch(`/tracks/${id}/`, data);
};

type TrackContextType = {
  track: ReadTrack | undefined;
  fetch: () => Promise<ReadTrack | undefined>;
  loading: boolean;
  error: unknown;
  setTrackId: (id: string) => void;
  isRolloutFlagEnabled: (flag: RolloutFlagsEnum) => boolean;
  patch: {
    execute: (data: HomeownerUpdateTrack) => Promise<ReadTrack | undefined>;
    results: HomeownerUpdateTrack | undefined;
    loading: boolean;
    error: unknown;
  };
};

const TrackContext = createContext<TrackContextType>({} as TrackContextType);

type ProviderProps = {
  trackId?: string;
};

export const TrackProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({ children, trackId }) => {
  // allows trackId to be an optional prop that can be set by a consumer.
  const [currentTrackId, setCurrentTrackId] = useState(trackId);
  const { loading, error, results, execute: fetchExecute } = useAsync(fetchTrack, { defaultLoading: true });
  const patchAsync = useAsync(patchTrack, { executeThrow: true });

  useEffect(() => {
    if (currentTrackId) {
      fetchExecute(currentTrackId);
    }
  }, [currentTrackId, fetchExecute]);

  const fetch = async (): Promise<ReadTrack | undefined> => await fetchExecute(currentTrackId);
  const patch = async (data: HomeownerUpdateTrack): Promise<ReadTrack | undefined> => {
    await patchAsync.execute(currentTrackId, data);
    return await fetch();
  };

  const isRolloutFlagEnabled = (flag: RolloutFlagsEnum): boolean => results?.enabled_rollout_flags.includes(flag);

  const contextData: TrackContextType = {
    patch: {
      ...patchAsync,
      execute: patch,
    },
    track: results,
    fetch,
    loading,
    error,
    setTrackId: useCallback(
      (id: string) => {
        if (trackId) {
          console.error("Can't set a trackId when defined as props");
          return;
        }

        setCurrentTrackId(id);
      },
      [trackId],
    ),
    isRolloutFlagEnabled,
  };

  return <TrackContext.Provider value={contextData}>{children}</TrackContext.Provider>;
};

const useTrack = () => useContext(TrackContext);

export default useTrack;
