import { isTrackExpiredOrClosed } from 'utils/tracksUtils';
import TrackExpiredClosed from 'components/TrackExpiredClosed/TrackExpiredClosed';
import useTrack from 'hooks/useTrack';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const ActiveTrackCheck = ({ children, ...rest }) => {
  const { track } = useTrack();
  const fromRenderProps = rest?.render?.(rest);
  if (fromRenderProps) return fromRenderProps;

  if (track && isTrackExpiredOrClosed(track)) {
    return <TrackExpiredClosed />;
  }

  return children;
};
