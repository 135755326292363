import React, { useEffect, useState, useContext } from 'react';

import { useAsync } from '@hometap/htco-components';
import {
  fetchAndFormatAppreciationData,
  fetchChartData,
  getEquityScenarioLiens,
  postScenarioAndLiens,
  deleteEquityScenarioLien,
  deleteEquityScenario,
  postEquityScenarioLien,
  patchEquityScenarioLien,
} from 'apps/dashboard/data';
import { formatAppreciationDataWithRenovations } from 'apps/dashboard/utils/dataUtils';
import { saveRenovationToScenario } from 'apps/dashboard/components/home-equity-controller/equity-renovations/data/requests';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { showNotification } from 'utils/toasts';
import sentry from 'utils/sentry';
import { useQuery } from '@tanstack/react-query';
import { useHomeValuation } from './useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';

/*
  This file handles the state logic for the home equity portion of the dashboard.
  equityScenarioRequests.js handles a lot of the API logic.
*/

const EquityScenarioDefaultContext = {
  currentScenario: {},
  futureScenario: {},
  initialScenario: {},
  getEquityScenarioLiensAsync: {},
  chartForecast: '10',
  isLoading: false,
  isFutureView: false,
  hideChartPanel: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsFutureView: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createFutureScenario: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeScenarioRenovations: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeScenarioRate: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteLien: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addLien: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateLien: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideLien: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unhideLien: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteFutureScenario: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setChartForecast: () => {},
};

const Context = React.createContext(EquityScenarioDefaultContext);

export const useEquityScenarioContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useEquityScenarioContext must be used within a HomePropertyProvider');
  }
  return context;
};

const useChartData = () => {
  const { home } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist o... Remove this comment to see the full error message
  const { displayedHomeValuation, isLoading: homeValuationLoading } = useHomeValuation();

  // @ts-expect-error TS(2339): Property 'investment' does not exist on type '{}'.
  const { investment, loading: investmentLoading } = useHedInvestmentContext();
  return useQuery({
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    queryFn: () => fetchChartData({ homeId: home?.id, homeValuation: displayedHomeValuation, investment }),
    queryKey: [
      'hed-chart-data',
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      { homeId: home?.id, homeValue: displayedHomeValuation?.value, investmentId: investment?.id },
    ],
    enabled: !!home && !investmentLoading && !homeValuationLoading,
  });
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const EquityScenarioContextProvider = ({ children }) => {
  const [chartForecast, setChartForecast] = useState('10');

  const [initialScenario, setInitialScenario] = useState({});
  const [futureScenario, setFutureScenario] = useState({});

  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ home:... Remove this comment to see the full error message
  const { home, loading: homeLoading } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'investment' does not exist on type '{}'.
  const { investment } = useHedInvestmentContext();

  // @ts-expect-error TS(2339): Property displayedHomeValuation does not exist on type {
  const { displayedHomeValuation, isLoading: homeValuationLoading } = useHomeValuation();

  const { data: chartData, isLoading: chartDataLoading } = useChartData();

  const fetchAppreciationDataAsync = useAsync(fetchAndFormatAppreciationData);
  const postScenarioAsync = useAsync(postScenarioAndLiens, { executeThrow: true });
  const postRenoToScenarioAsync = useAsync(saveRenovationToScenario);
  const getEquityScenarioLiensAsync = useAsync(getEquityScenarioLiens);
  const deleteEquityScenarioLiensAsync = useAsync(deleteEquityScenarioLien);
  const deleteEquityScenarioAsync = useAsync(deleteEquityScenario);
  const postEquityScenarioLienAsync = useAsync(postEquityScenarioLien);
  const patchEquityScenarioLienAsync = useAsync(patchEquityScenarioLien);

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'scenarios' does not exist on type '{}'.
    const initialScenario = chartData?.scenarios?.find(scenario => scenario.initial_scenario);
    setInitialScenario(initialScenario || {});
    // @ts-expect-error TS(2339): Property 'scenarios' does not exist on type '{}'.
    const futureScenario = chartData?.scenarios?.find(scenario => scenario.initial_scenario_future) || {};
    setFutureScenario(futureScenario || {});
  }, [chartData]);

  // "Today's View" and "Plan for the future" toggle
  const [isFutureView, setIsFutureView] = useState(false);
  // Reset toggle to "Today's View" if debts are not confirmed for the home
  useEffect(() => {
    // @ts-expect-error TS2339
    if (home && !home.hed_debts_confirmed && isFutureView) {
      setIsFutureView(false);
    }
    // @ts-expect-error TS2339
  }, [home?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentScenario = isFutureView ? futureScenario : initialScenario;

  // Remove scenario from the list and switch current scenario if deleted.
  // @ts-expect-error TS(7006): Parameter 'scenarioId' implicitly has an 'any' typ... Remove this comment to see the full error message
  const deleteFutureScenario = async scenarioId => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    if (scenarioId === futureScenario?.id) {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      await deleteEquityScenarioAsync.execute(home?.id, scenarioId);
      setFutureScenario({});
    }
  };

  // Edit scenario appreciation rate and get new appreciation data. (Slider values)
  // @ts-expect-error TS(7006): Parameter 'newRate' implicitly has an 'any' type.
  const changeScenarioRate = async newRate => {
    try {
      const scenario = { ...currentScenario, appreciation_rate: newRate };
      // using execute to access loading state on slider
      const scenarioWithAppreciationData = await fetchAppreciationDataAsync.execute(
        scenario,
        displayedHomeValuation,
        investment,
        false,
      );
      if (isFutureView) {
        setFutureScenario(scenarioWithAppreciationData);
      } else {
        setInitialScenario(scenarioWithAppreciationData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Edit renovations and get new appreciation data.
  // `renovations` argument is an array of the new renovations (does not edit current list).
  // @ts-expect-error TS(7006): Parameter 'renovations' implicitly has an 'any' ty... Remove this comment to see the full error message
  const changeScenarioRenovations = async renovations => {
    try {
      const scenario = { ...futureScenario, renovations };
      const scenarioWithAppreciationData = await fetchAppreciationDataAsync.execute(
        scenario,
        displayedHomeValuation,
        investment,
        false,
      );

      scenarioWithAppreciationData.annualAppreciation = formatAppreciationDataWithRenovations(
        scenarioWithAppreciationData.annualAppreciation,
      );
      setFutureScenario({ ...scenarioWithAppreciationData, renovations });
      setIsFutureView(true);
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'lienId' implicitly has an 'any' type.
  const removeLienFetchAppreciationData = async (lienId, isInitialScenario) => {
    const scenarioToUpdate = isInitialScenario ? { ...initialScenario } : { ...futureScenario };
    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    const newLiens = scenarioToUpdate.liens.filter(lien => lien?.id !== lienId);
    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    scenarioToUpdate.liens = newLiens;
    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  // @ts-expect-error TS(7006): Parameter 'scenarioId' implicitly has an 'any' typ... Remove this comment to see the full error message
  const deleteLien = async (scenarioId, lienId) => {
    try {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      const isInitialScenario = scenarioId === initialScenario?.id;
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      await deleteEquityScenarioLiensAsync.execute(home?.id, scenarioId, lienId);
      const scenarioWithAppreciationData = await removeLienFetchAppreciationData(lienId, isInitialScenario);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
  const toggleHideLienFetchAppreciationData = async (lien, isInitialScenario, hide) => {
    const scenarioToUpdate = isInitialScenario ? { ...initialScenario } : { ...futureScenario };

    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    const newLiens = scenarioToUpdate.liens.map(existingLien => {
      const toggleLien = existingLien?.lien_type === lien?.lien_type;
      if (toggleLien && hide) {
        return { ...existingLien, hidden: true };
      } else if (toggleLien && !hide) {
        return { ...existingLien, hidden: false };
      }
      return existingLien;
    });

    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    scenarioToUpdate.liens = newLiens;

    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  // @ts-expect-error TS(7006): Parameter 'scenarioId' implicitly has an 'any' typ... Remove this comment to see the full error message
  const hideLien = async (scenarioId, lien) => {
    try {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      const isInitialScenario = scenarioId === initialScenario?.id;
      const scenarioWithAppreciationData = await toggleHideLienFetchAppreciationData(lien, isInitialScenario, true);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'scenarioId' implicitly has an 'any' typ... Remove this comment to see the full error message
  const unhideLien = async (scenarioId, lien) => {
    try {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      const isInitialScenario = scenarioId === initialScenario?.id;
      const scenarioWithAppreciationData = await toggleHideLienFetchAppreciationData(lien, isInitialScenario, false);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
  const addLienToAppreciationData = async (scenario, lien) => {
    const scenarioToUpdate = { ...scenario };
    scenarioToUpdate.liens.push(lien);
    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  // @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
  const addLien = async (scenario, lien, onSuccess) => {
    try {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      const isInitialScenario = scenario?.id === initialScenario?.id;
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      const newLien = await postEquityScenarioLienAsync.execute(home?.id, scenario?.id, lien);
      const scenarioWithAppreciationData = await addLienToAppreciationData(scenario, newLien);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      sentry.logErrorWrapper(`Unable to create lien for home: ${home.id}`, error);
      // @ts-expect-error TS(2345): Argument of type '{ type: string; title: string; d... Remove this comment to see the full error message
      return showNotification({
        type: 'error',
        title: "Sorry we couldn't save your lien details. Please try again.",
        description: 'If the problem persists, please contact support.',
      });
    }
  };

  // @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
  const updateLienAndGetAppreciationData = async (scenario, lien) => {
    const scenarioToUpdate = { ...scenario };
    scenarioToUpdate.liens = [...scenarioToUpdate.liens].map(existingLien =>
      existingLien?.id === lien?.id ? lien : existingLien,
    );
    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  // @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
  const updateLien = async (scenario, existingLienId, lienData) => {
    try {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      const isInitialScenario = scenario?.id === initialScenario?.id;
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      const patchedLien = await patchEquityScenarioLienAsync.execute(home?.id, scenario?.id, existingLienId, lienData);
      const scenarioWithAppreciationData = await updateLienAndGetAppreciationData(
        scenario,
        patchedLien,
        // @ts-expect-error TS(2554): Expected 2 arguments, but got 3.
        isInitialScenario,
      );
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Create future scenario from initial scenario
  // @ts-expect-error TS(7006): Parameter 'renovation' implicitly has an 'any' typ... Remove this comment to see the full error message
  const createFutureScenario = async renovation => {
    const initialScenarioCopy = { ...initialScenario, initial_scenario: false, initial_scenario_future: true };
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ initial_sc... Remove this comment to see the full error message
    delete initialScenarioCopy.id;

    const newFutureScenario = await postScenarioAsync
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      .execute(home?.id, initialScenarioCopy)
      // @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
      .catch(error => console.error(error));

    // get appreciation data for future scenario
    if (renovation) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const reno = await postRenoToScenarioAsync.execute(home.id, newFutureScenario?.id, renovation);
      newFutureScenario.renovations = [reno];
    }

    try {
      const futureScenarioWithAppreciationData = await fetchAppreciationDataAsync.execute(
        newFutureScenario,
        displayedHomeValuation,
        investment,
        false,
      );
      setFutureScenario(futureScenarioWithAppreciationData);
      setIsFutureView(true);
      return futureScenarioWithAppreciationData;
    } catch (error) {
      console.error(error);
    }
  };

  // this should be changed when we add more detailed error states
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const isLoadingHed = !initialScenario?.id || homeLoading || homeValuationLoading;

  // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
  const hideInitialScenarioPanel = !isFutureView && !initialScenario?.liens?.length && !investment;

  const hideFutureScenarioPanel =
    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    isFutureView && !futureScenario?.liens?.length && !futureScenario?.renovations?.length;

  const hideChartPanel = hideInitialScenarioPanel || hideFutureScenarioPanel;

  const data = {
    currentScenario,
    chartDataLoading,
    getEquityScenarioLiensAsync,
    futureScenario,
    initialScenario,
    chartForecast,
    isLoadingHed,
    isFutureView,
    hideChartPanel,
    setIsFutureView,
    createFutureScenario,
    changeScenarioRenovations,
    changeScenarioRate,
    deleteLien,
    addLien,
    updateLien,
    hideLien,
    unhideLien,
    deleteFutureScenario,
    setChartForecast,
  };

  // @ts-expect-error TS(2741): Property 'isLoading' is missing in type '{ current... Remove this comment to see the full error message
  return <Context.Provider value={data}>{children}</Context.Provider>;
};
