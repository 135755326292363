import { Container } from '@hometap/htco-components';
import { Routes, Route, useLocation } from 'react-router-dom';

import InquiryOutcomeUnsuccessfulRiskBandAA from 'apps/progressive-inquiry/components/outcome/adverseAction/InquiryOutcomeUnsuccessfulRiskBandAA';
import InquiryOutcomeUnsuccessfulFicoScoreAA from 'apps/progressive-inquiry/components/outcome/adverseAction/InquiryOutcomeUnsuccessfulFicoScoreAA';

const InquiryAdverseActionRouter = () => {
  const location = useLocation();
  const { ficoScore, riskFailFactors = [], firstName, lastName } = location.state || {};

  return (
    <Container align="center">
      <Container grid={7}>
        <Routes>
          <Route
            path="risk-band"
            element={<InquiryOutcomeUnsuccessfulRiskBandAA riskFailFactors={riskFailFactors} />}
          />
          <Route
            path="fico"
            element={
              <InquiryOutcomeUnsuccessfulFicoScoreAA
                ficoScore={ficoScore}
                firstName={firstName}
                lastName={lastName}
                riskFailFactors={riskFailFactors}
              />
            }
          />
        </Routes>
      </Container>
    </Container>
  );
};

export default InquiryAdverseActionRouter;
