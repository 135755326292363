import PropTypes from 'prop-types';

import { Button, Icon, Tooltip, useWindowSize } from '@hometap/htco-components';
import cx from 'classnames';
import './HomeEquityCard.scss';

const APPRECIATION_OPTIONS = {
  appreciation: {
    iconName: 'icon-arrow-increase-2',
    value: 'appreciation',
  },
  noChange: {
    iconName: 'icon-arrow-no-change-2',
    value: 'noChange',
  },
  depreciation: {
    iconName: 'icon-arrow-decrease-2',
    value: 'depreciation',
  },
};

const THEMES = {
  brightBlue: 'brightBlue',
  purple: 'purple',
  darkBlue: 'darkBlue',
};

const HomeEquityCard = ({
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031): Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  value,
  // @ts-expect-error TS(7031): Binding element 'percentage' implicitly has an 'an... Remove this comment to see the full error message
  percentage,
  // @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
  theme = THEMES.brightBlue,
  // @ts-expect-error TS(7031): Binding element 'ctaText' implicitly has an 'any' ... Remove this comment to see the full error message
  ctaText,
  // @ts-expect-error TS(7031): Binding element 'appreciationDirection' implicitly... Remove this comment to see the full error message
  appreciationDirection,
  // @ts-expect-error TS(7031): Binding element 'forecastLabel' implicitly has an ... Remove this comment to see the full error message
  forecastLabel,
  // @ts-expect-error TS(7031): Binding element 'forecastValue' implicitly has an ... Remove this comment to see the full error message
  forecastValue,
  // @ts-expect-error TS(7031): Binding element 'toolTipContent' implicitly has an... Remove this comment to see the full error message
  toolTipContent,
  // @ts-expect-error TS(7031): Binding element 'onLinkButtonClick' implicitly has... Remove this comment to see the full error message
  onLinkButtonClick,
  showLinkButton = true,
  shouldPercentageDrop = false,
  ...props
}) => {
  const { screenWidth } = useWindowSize();
  const customStyles = {
    brightBlueTheme: theme === THEMES.brightBlue,
    purpleTheme: theme === THEMES.purple,
    darkBlueTheme: theme === THEMES.darkBlue,
  };

  const shouldDrop = shouldPercentageDrop && screenWidth <= 600;

  const percentageElement = percentage && (
    <div className="HomeEquityCardPercentage">
      {Object.values(APPRECIATION_OPTIONS).some(item => item.value === appreciationDirection) && (
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        <Icon name={APPRECIATION_OPTIONS[appreciationDirection].iconName} size="lg" />
      )}
      <span>{percentage}%</span>
    </div>
  );

  const showForecastValue = forecastLabel && forecastValue;

  return (
    <div className={cx('HomeEquityCard', className, customStyles)} {...props}>
      <div className="HomeEquityCardDescription">
        <div className={cx('HomeEquityCardInfoContainer', [shouldDrop && 'isResponsive'])}>
          <h2>{title}</h2>
          <Tooltip content={toolTipContent} action="hover" position="bottom">
            <Icon name="icon-info" />
          </Tooltip>
        </div>
        {!shouldDrop && percentageElement}
      </div>
      <div className="HomeEquityCardBottom">
        <h2 className="HomeEquityCardValue">{value}</h2>
        {shouldDrop && percentageElement}
      </div>
      {showForecastValue && (
        <div className="HomeEquityCardForecast">
          {forecastLabel}
          <span>{forecastValue}</span>
        </div>
      )}
      {showLinkButton && (
        <Button className="HomeEquityCardCTA" theme="link-alt" onClick={onLinkButtonClick}>
          {ctaText}
        </Button>
      )}
    </div>
  );
};

HomeEquityCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldPercentageDrop: PropTypes.bool,
  theme: PropTypes.oneOf([THEMES.brightBlue, THEMES.purple, THEMES.darkBlue]),
  appreciationDirection: PropTypes.oneOf([
    APPRECIATION_OPTIONS.appreciation.value,
    APPRECIATION_OPTIONS.noChange.value,
    APPRECIATION_OPTIONS.depreciation.value,
  ]),
  className: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  toolTipContent: PropTypes.node.isRequired,
  onLinkButtonClick: PropTypes.func.isRequired,
  showLinkButton: PropTypes.bool,
  forecastLabel: PropTypes.string,
  forecastValue: PropTypes.string,
};

export default HomeEquityCard;
