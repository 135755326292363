import { apiWithAuth, apiNoAuth } from 'utils/api';
import { INVESTMENT_TERM_UNIT } from './rundownUtils';

// @ts-expect-error TS(7006): Parameter 'type' implicitly has an 'any' type.
export const getCalculateScenarioEndpoint = (type, id) => `/${type}/${id}/actions/calculate-scenario/`;

export const fetchScenarios = async (
  // @ts-expect-error TS(7006): Parameter 'type' implicitly has an 'any' type.
  type,
  // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
  id,
  // @ts-expect-error TS(7031): Binding element 'investmentAmount' implicitly has ... Remove this comment to see the full error message
  { investmentAmount, investmentLength, investmentTermUnit = INVESTMENT_TERM_UNIT.YEARS },
) => {
  return await apiWithAuth.v1.get(`/${type}/${id}/scenarios`, {
    params: {
      investment_amount: investmentAmount,
      effective_period_length: investmentLength,
      effective_period_unit: investmentTermUnit,
    },
  });
};

// @ts-expect-error TS(7006): Parameter 'type' implicitly has an 'any' type.
export const fetchScenarioCalculation = async (type, rundownId, body, params) => {
  return await apiNoAuth.post(getCalculateScenarioEndpoint(type, rundownId), body, { params });
};

// @ts-expect-error TS(7006): Parameter 'amount' implicitly has an 'any' type.
export const fetchDefaultFutureHomeValue = async (amount, starting_home_value, term) => {
  const request = await apiNoAuth.post('/pricing/scenarios', {
    scenarios: [
      {
        idx: 0,
        investment_amount: amount,
        starting_home_value,
        appreciation_percent: {
          value: 0.03,
          annualized: false,
        },
        effective_period: {
          unit: 'years',
          length: parseInt(term),
        },
      },
    ],
  });

  // @ts-expect-error TS(2339): Property 'scenarios' does not exist on type 'Axios... Remove this comment to see the full error message
  if (request?.scenarios[0]) {
    // @ts-expect-error TS(2339): Property 'scenarios' does not exist on type 'Axios... Remove this comment to see the full error message
    return request?.scenarios[0].appreciated_home_value;
  }
  return starting_home_value + 10000;
};
