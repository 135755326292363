import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Button, SelectionSet } from '@hometap/htco-components';
import womanImg from 'images/inquiry/woman.png';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';
import { useAsyncPatchInquiry } from '../hooks/inquiryMutations';
import './InquiryReceiveFunds.scss';

// @ts-expect-error TS(7031): Binding element 'inquiryId' implicitly has an 'any... Remove this comment to see the full error message
const InquiryReceiveFunds = ({ inquiryId, whenInterestedOptions, form, step, onNext }) => {
  const { registerField, handleSubmit, isFormValid, formData } = form;

  const { results, loading, execute: updateInquiry } = useAsyncPatchInquiry();

  useEffect(() => {
    if (results) {
      onNext(step + 1);
    }
  }, [results, onNext, step]);

  const canContinueToTheNextPage = !!(isFormValid && formData.when_interested);
  if (canContinueToTheNextPage) scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
  const ctaText = 'Continue';
  useCTAChangedNotification(ctaText, canContinueToTheNextPage);

  const onSubmit = () => {
    const { when_interested } = formData;
    const updateApi = handleSubmit(() => updateInquiry(inquiryId, { when_interested }));
    updateApi();
  };

  return (
    <div className="ReceiveFunds" data-testid="inquiry_receive_funds_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.RECEIVE_FUNDS]} />
      <ProgressiveUITemplate>
        {/* @ts-expect-error TS(2739): Type '{ children: Element[]; }' is missing the fol... Remove this comment to see the full error message */}
        <ContentPanel>
          <h1>When would you like to receive your funds?</h1>
          <p className="InquiryBodyText">If Hometap seems like a good fit, when would you want to start?</p>
          <div className="InquirySelectionSet ReceiveFundsSelection">
            <SelectionSet
              required
              labelWidth="100%"
              options={whenInterestedOptions}
              {...registerField(FIELDS.WHEN_INTERESTED)}
            />
          </div>
          <CallToAction>
            <Button
              id={CONTINUE_BUTTON_ID}
              disabled={!canContinueToTheNextPage}
              onClick={onSubmit}
              loading={loading}
              className="ReceiveFundsCTAButton"
            >
              {ctaText}
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel imageSource={womanImg} imgAlt="woman sitting at desk" isCentered={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryReceiveFunds;
