import { useEffect, useRef } from 'react';

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
const usePrev = value => {
  const prev = useRef();

  useEffect(() => {
    prev.current = value;
  }, [value]);

  return prev.current;
};

export default usePrev;
