import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { Routes, Route } from 'react-router-dom';
import ReInquiryOutcome from './components/ReInquiryOutcome';
import ReInquiryReview from './components/ReInquiryReview';

import './ReInquiry.scss';

const ReInquiryRouter = () => {
  return (
    <Routes>
      <Route
        path=":identifier"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            <ReInquiryReview />
          </PrivateRoute>
        }
      />
      <Route
        path=":identifier/outcome"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            <ReInquiryOutcome />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default ReInquiryRouter;
