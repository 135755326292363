import { useAsync } from '@hometap/htco-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import useSearchParams from 'hooks/useSearchParams';
import { apiWithAuth } from 'utils/api';
import useLocalState from 'apps/htdc/utils/useLocalState';
import { COLOR_ORDER } from '../constants/smartFactConstants';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { useCurrentHome } from 'hooks/useCurrentHome';

// @ts-expect-error TS(7006): Parameter 'dateStr1' implicitly has an 'any' type.
const isLessThan24Hours = dateStr1 => {
  if (!dateStr1) {
    return false;
  }
  const date1 = new Date(dateStr1);
  const date2 = new Date();

  // Calculate the difference in milliseconds
  const diff = date2.getTime() - date1.getTime();

  // Check if the difference is more than 24 hours
  return diff < 24 * 60 * 60 * 1000;
};

const fetchSmartFacts = async (requestedSmartFacts = [], excludedSmartFacts = [], smartContexts = [], amount = 4) => {
  return await apiWithAuth.v1.post(`/smart-facts/`, {
    contexts: [...smartContexts],
    amount,
    requested_fact_ids: [...requestedSmartFacts],
    excluded_fact_ids: [...excludedSmartFacts],
  });
};

const useSmartFacts = () => {
  const [smartCardsDate, setSmartCardsDate] = useLocalState(null, 'SmartCardDate');
  const [hasCompletedSmartCards, setHasCompletedSmartCards] = useLocalState(null, 'SmartCardsCompleted');
  const [unhelpfulSmartFacts, setUnhelpfulSmartFacts] = useLocalState([], 'UnhelpfulSmartFacts');
  const [helpfulSmartFacts, setHelpfulSmartFacts] = useState([]);
  const { home } = useCurrentHome();
  const { initialScenario } = useEquityScenarioContext();

  const {
    error: smartFactsError,
    loading: smartFactsLoading,
    execute,
  } = useAsync(fetchSmartFacts, { immediate: false });

  const recentlyViewedSmartFacts = isLessThan24Hours(smartCardsDate);
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const [sessionSmartFacts, setSessionSmartFacts] = useSessionStorage(`SessionSmartFacts-${home?.id}`);

  const query = useSearchParams();
  const requestedFactIds = query.get('hometap_insight');

  const shouldRefetchSmartFacts = !sessionSmartFacts || !recentlyViewedSmartFacts;

  const handleCompletion = useCallback(() => {
    if (!hasCompletedSmartCards) {
      browserTrack.smartCardsCompleted({
        version: getVersion(),
      });

      const timeout = setTimeout(() => {
        setHasCompletedSmartCards(true);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [hasCompletedSmartCards, setHasCompletedSmartCards]);

  // Use useEffect to handle the completion state and cleanup
  useEffect(() => {
    if (!hasCompletedSmartCards && !recentlyViewedSmartFacts && !shouldRefetchSmartFacts) {
      return handleCompletion();
    }
  }, [hasCompletedSmartCards, handleCompletion, recentlyViewedSmartFacts, shouldRefetchSmartFacts]);

  const fetchAndStoreSmartFacts = useCallback(
    // @ts-expect-error TS(7031): Binding element 'homeId' implicitly has an 'any' t... Remove this comment to see the full error message
    async ({ homeId, scenarioId }) => {
      if (smartFactsLoading || !homeId) return;

      const requestedSmartFacts = requestedFactIds?.split(',').map(smartFact => smartFact.trim());

      const contexts = [{ kind: 'system' }, { kind: 'user' }, { kind: 'home', id: homeId }];
      if (scenarioId) {
        contexts.push({ kind: 'equity_scenario', id: scenarioId });
      }

      // If a user has not recently viewed smart facts fetch the new smart facts with exclusions
      if (shouldRefetchSmartFacts) {
        const updatedSmartFacts = await execute(requestedSmartFacts, unhelpfulSmartFacts, contexts);

        setHasCompletedSmartCards(false);
        setSessionSmartFacts(updatedSmartFacts);
        setSmartCardsDate(new Date());
      }
    },
    [
      smartFactsLoading,
      requestedFactIds,
      shouldRefetchSmartFacts,
      execute,
      unhelpfulSmartFacts,
      setSessionSmartFacts,
      setSmartCardsDate,
      setHasCompletedSmartCards,
    ],
  );

  // Fetch smart facts
  useEffect(
    () => {
      if (!shouldRefetchSmartFacts) return;
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      fetchAndStoreSmartFacts({ homeId: home?.id, scenarioId: initialScenario?.id });
    },
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRefetchSmartFacts, home?.id, initialScenario?.id],
  );

  const wasLastUnhelpfulFactFirstRef = useRef(false);
  const markFactAsUnhelpfulAndFetchMore = useCallback(
    // @ts-expect-error TS(7006): Parameter 'newUnhelpfulFact' implicitly has an 'an... Remove this comment to see the full error message
    async newUnhelpfulFact => {
      if (unhelpfulSmartFacts.includes(newUnhelpfulFact) || !home) return;
      wasLastUnhelpfulFactFirstRef.current = newUnhelpfulFact === sessionSmartFacts?.[0]?.id;

      const newUnhelpfulSmartFacts = [...unhelpfulSmartFacts, newUnhelpfulFact];
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      const contexts = [{ kind: 'system' }, { kind: 'user' }, { kind: 'home', id: home.id }];
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      if (initialScenario?.id) {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        contexts.push({ kind: 'equity_scenario', id: initialScenario.id });
      }
      const additionalSmartFacts = await fetchSmartFacts(
        [],
        // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        [...newUnhelpfulSmartFacts, ...sessionSmartFacts.map(fact => fact.id)],
        contexts,
        1,
      );
      setSessionSmartFacts([
        // @ts-expect-error TS(7006): Parameter 'fact' implicitly has an 'any' type.
        ...sessionSmartFacts.filter(fact => fact.id !== newUnhelpfulFact),
        // @ts-expect-error TS(2488): Type 'AxiosResponse<any, any>' must have a '[Symbo... Remove this comment to see the full error message
        ...additionalSmartFacts,
      ]);
      setUnhelpfulSmartFacts(newUnhelpfulSmartFacts);
    },
    [home, initialScenario, sessionSmartFacts, setSessionSmartFacts, setUnhelpfulSmartFacts, unhelpfulSmartFacts],
  );

  const smartFacts = useMemo(
    () =>
      // @ts-expect-error TS(7006): Parameter 'smartFact' implicitly has an 'any' type... Remove this comment to see the full error message
      (sessionSmartFacts || []).map((smartFact, i) => {
        const hasCta = !!(smartFact?.cta?.text && smartFact?.cta?.url);
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        const isHelpful = helpfulSmartFacts.includes(smartFact.id);

        return {
          id: smartFact?.id,
          theme: COLOR_ORDER[i % COLOR_ORDER.length],
          header: smartFact?.fact,
          hasCta: hasCta,
          isHelpful,
          ctaText: smartFact?.cta?.text,
          onCtaClick: () => window.open(smartFact?.cta?.url, '_blank'), // maybe incorporate react router history
          // @ts-expect-error TS(7006): Parameter 'newHelpfulFact' implicitly has an 'any'... Remove this comment to see the full error message
          onHelpful: newHelpfulFact =>
            // @ts-expect-error TS(2345): Argument of type '(prev: never[]) => any[]' is not... Remove this comment to see the full error message
            setHelpfulSmartFacts(prev => (prev.includes(newHelpfulFact) ? prev : [...prev, newHelpfulFact])),
          // @ts-expect-error TS(7006): Parameter 'newUnhelpfulFact' implicitly has an 'an... Remove this comment to see the full error message
          onUnhelpful: newUnhelpfulFact => markFactAsUnhelpfulAndFetchMore(newUnhelpfulFact),
          ...smartFact,
        };
      }),
    [sessionSmartFacts, helpfulSmartFacts, markFactAsUnhelpfulAndFetchMore],
  );

  // Smart cards viewed segment event logic
  // Send event when first smart fact changed but not because the first one was marked as unhelpful
  const initialSmartFactId = smartFacts?.[0]?.id;
  useEffect(() => {
    if (initialSmartFactId && !wasLastUnhelpfulFactFirstRef.current && !hasCompletedSmartCards) {
      browserTrack.smartCardViewed({
        card_id: initialSmartFactId,
        version: getVersion(),
      });
    }
  }, [initialSmartFactId, hasCompletedSmartCards]);

  return {
    smartFacts,
    smartFactsError,
    smartFactsLoading,
    hasCompletedSmartCards,
    setHasCompletedSmartCards,
    handleCompletion,
    recentlyViewedSmartFacts,
    updateSmartFacts: fetchAndStoreSmartFacts,
  };
};

export default useSmartFacts;
