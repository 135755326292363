// TODO: moment is not maintained anymore, we may want to replace it with using pure Date with date-fns
// TODO: there's no enforcement to using the correct date types in most of these functions
import moment from 'moment';

// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date).format(format);
};

// @ts-expect-error TS(7006): Parameter 'firstDate' implicitly has an 'any' type... Remove this comment to see the full error message
export const getDateDifference = (firstDate, secondDate, unit = 'days') => {
  // dates must be moment date objects or formatted YYYY-MM-DD
  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const dateDifference = moment(secondDate).diff(moment(firstDate), unit);
  if (dateDifference < 0) {
    return 1; // TODO: confirm if this is the expected behavior, or if we should multiply dateDifference by -1
  }
  return dateDifference;
};

// dateString has format: "2022-02-11"          -> Output: "2022-02-11T00:00:00-05:00"
// dateString has format: "2022-02-11T14:12:01" -> Output: "2022-02-11T00:00:00-05:00"
// @ts-expect-error TS(7006): Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
export const convertDateStringToDateTimeEST = dateString => `${dateString.split('T')[0]}T00:00:00-05:00`;

// dateString has format: "2022-02-11" -> Output: "February 11, 2022" or "Feb. 11, 2022"
/**
 *
 * @param {string} dateString String in the format "YYYYY-MM-DD"
 * @param {boolean} truncateMonth Truncate month name? Deprecated
 * @returns {string}
 */
// @ts-expect-error TS(7006): Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
export const convertDateStringToHumanReadable = (dateString, truncateMonth = false) => {
  const date = new Date(Date.parse(convertDateStringToDateTimeEST(dateString))); // create a new Date object from the date string
  const options = { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'America/New_York' }; // formatting options
  // @ts-expect-error TS(2345): Argument of type '{ month: string; day: string; ye... Remove this comment to see the full error message
  const formatter = new Intl.DateTimeFormat('en-US', options); // create new formatter with the specified options
  let formattedDate = formatter.format(date); // format the date using the formatter
  if (truncateMonth) {
    formattedDate = moment(convertDateStringToDateTimeEST(dateString)).format('MMMM D, YYYY');
  }
  return formattedDate;
};

/**
 *
 * @param {string} dateString Date string in the format "YYYY-MM-DD"
 * @returns {string}
 */
// @ts-expect-error TS(7006): Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
export const convertDateStringToHumanReadableMonth = dateString => {
  // TODO: this is essentially the same as convertDateStringToHumanReadable() so we should drop one of them
  return moment(convertDateStringToDateTimeEST(dateString)).format('MMMM D, YYYY');
};

// dateString has format: "2024-08-14T22:24:26.404745Z" -> Output: "Wed Aug 14, 2024
// @ts-expect-error TS(7006): Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
export const convertDateStringToWeekday = dateString => {
  if (!dateString) return;
  return moment(dateString).format('ddd MMM DD, YYYY');
};

// dateString has format: "2024-08-14T22:24:26.404745Z" -> Output: "Aug 14 2024
// @ts-expect-error TS(7006): Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
export const convertDateStringToShortDate = dateString => {
  if (!dateString) return;
  return moment(dateString).format('MMM DD YYYY');
};

// get difference in years between two date strings in format "yyyy-mm-dd"
/**
 * Returns difference between two dates in years
 * @param {string} higherDate Higher date formatted as a string "YYYY-MM-DD"
 * @param {string} lowerDate Lower date formatted as a string "YYYY-MM-DD"
 * @param {boolean} roundUp Round years difference up?
 * @returns {number}
 */
// @ts-expect-error TS(7006): Parameter 'higherDate' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getYearsBetweenDates = (higherDate, lowerDate, roundUp = false) => {
  // TODO: this is very similar to getDateDifference() and we can probably drop one of them with a very small refactor
  const date1 = moment(higherDate);
  const date2 = moment(lowerDate);
  if (roundUp) {
    // Convert days to years and round up to the nearest whole year
    const diffDays = date1.diff(date2, 'days');
    return Math.ceil(diffDays / 365);
  }
  return date1.diff(date2, 'years');
};

/**
 *
 * @param {string} date date string in "YYYY-MM-DD" format
 * @returns {number} year
 */
// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
export const getYearFromDateString = date => {
  const momentDate = moment(date, 'YYYY-MM-DD');
  return momentDate.year();
};

/**
 * Takes a UTC date object or date string and returns it as a formatted string in local time
 * Examples:
 *   Input: new Date("2022-02-02") -> Output: "2/2/22"
 *   Input: "2024-02-25" -> Output: "2/25/24"
 */
// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
export const getFormattedDate = (date, format = 'M/D/YY') => {
  return moment(date).format(format);
};

/**
 * Takes a UTC dateTime object or dateTime string and returns it as a formatted string in local time
 * Examples:
 *   Input: new Date("2022-02-02T14:48:00Z") -> Output: "6:48 AM PDT"
 *   Input: "2022-02-02T14:48:00Z" -> Output: "6:48 AM PDT"
 */
// @ts-expect-error TS(7006): Parameter 'dateTime' implicitly has an 'any' type.
export const getFormattedTime = (dateTime, includeTimezone = true) => {
  const options = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    ...(includeTimezone ? { timeZoneName: 'short' } : {}),
  };
  // @ts-expect-error TS(2345): Argument of type '{ timeZoneName?: string | undefi... Remove this comment to see the full error message
  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(new Date(dateTime));
};

// returns true if the current (local) date is after the given date
// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
export const isTodayAfterDate = date => {
  return moment().isAfter(moment(date).endOf('day'));
};

// returns true if the first date is after the second date
// @ts-expect-error TS(7006): Parameter 'firstDate' implicitly has an 'any' type... Remove this comment to see the full error message
export const isDateAfterOtherDate = (firstDate, secondDate) => {
  return moment(firstDate).isAfter(moment(secondDate).endOf('day'));
};

/**
 *
 * @param {string} dateString Date string in "YYYY-MM-DD" format
 * @param {number} amount
 * @param {string} period unit to add
 * @returns
 */
// @ts-expect-error TS(7006): Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
export const addToDateString = (dateString, amount, period) => {
  return moment(convertDateStringToDateTimeEST(dateString)).add(amount, period).format('YYYY-MM-DD');
};
