import { useAsync } from '@hometap/htco-components';
import React, { useContext, useEffect } from 'react';
import apiWithAuth from 'utils/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentHome } from 'hooks/useCurrentHome';

const Context = React.createContext({});
const useHouseCanaryLiensContext = () => useContext(Context);
export default useHouseCanaryLiensContext;

// @ts-expect-error TS(7031): Binding element 'homeId' implicitly has an 'any' t... Remove this comment to see the full error message
const fetchHouseCanaryLiens = async ({ homeId }) => {
  return await apiWithAuth.v1.get(`homes/${homeId}/house-canary-liens/`, { params: { purpose: 'HED' } });
};

// @ts-expect-error TS7031
export const HouseCanaryLiensProvider = ({ children }) => {
  // @ts-expect-error TS2339
  const { home, hasHEDAccessToCurrentHome } = useCurrentHome();
  const flags = useFlags();
  const isHCFeatureOn = !!flags.useHCinHedOnboarding;
  // @ts-expect-error TS2339
  const hasConfirmedHomeDebts = home?.hed_debts_confirmed;
  const canFetch = isHCFeatureOn && !hasConfirmedHomeDebts && hasHEDAccessToCurrentHome;

  const { execute: fetchHCLiens, ...fetchHouseCanaryLiensAsync } = useAsync(fetchHouseCanaryLiens, {
    defaultLoading: canFetch,
  });

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (home?.id && canFetch) {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      fetchHCLiens({ homeId: home.id });
    }
  }, [home, fetchHCLiens, canFetch]);

  // Return no data if can fetch has changed to false after liens were fetched
  const liensData = canFetch ? fetchHouseCanaryLiensAsync.results : null;

  const contextData = { liens: liensData?.liens, asOfDate: liensData?.as_of_date };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
