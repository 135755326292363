import moment from 'moment';
import {
  formatAppreciationDataWithRenovations,
  formatExistingLienDataForApi,
  getCurrentEquity,
  getForecastedValueAndEquity,
  getRenovationSum,
  setLienData,
  updateInvestmentLiensWithCurrentAppreciatedValues,
  replaceMatchingLiens,
} from 'apps/dashboard/utils/dataUtils';
import { getYearFromDateString } from 'utils/date';
import sentry from 'utils/sentry';
import { getLoginUrl } from 'utils/links';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { getVisibleRenovations } from 'apps/dashboard/utils/renovationUtils';
import {
  getDefaultProduct,
  postAnnualAppreciation,
  postEquityScenario,
  postEquityScenarioLien,
  getEquityScenario,
  getSelfReportedLiens,
  getEquityScenarios,
} from 'apps/dashboard/data/homeEquityApi';

/*
  This file contains abstract data methods to account for home equity business logic
*/

export const fetchAndFormatAppreciationData = async (
  // @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
  scenario,
  // @ts-expect-error TS(7006): Parameter 'homeValuation' implicitly has an 'any' ... Remove this comment to see the full error message
  homeValuation,
  investment = null,
  firstVisitHEI = false,
) => {
  /*
    Every time there is an edit on the dashboard to the liens or scenario (excluding name),
    we need to get new appreciation data for the scenario. 
  */
  const liens = scenario.liens ? [...scenario.liens] : [];
  const renovationSum = getRenovationSum(getVisibleRenovations(scenario));
  const evalRenoSum = scenario?.initial_scenario ? 0 : renovationSum;

  try {
    const todayFormatted = moment().format('YYYY-MM-DD');
    const currentYear = new Date().getFullYear();
    const home_values = [];
    if (homeValuation) {
      home_values.push({
        as_of_date: todayFormatted,
        home_value: homeValuation.value + evalRenoSum,
      });
    }

    const visibleLiens = liens.filter(lien => !lien.hidden);
    let scenarioAndInvestmentLiens = [
      ...visibleLiens.map(lien =>
        formatExistingLienDataForApi({
          ...lien,
          as_of_date: lien.last_known_current_balance_as_of,
          current_balance: lien.last_known_current_balance,
        }),
      ),
    ];
    let hei;

    // Investment logic
    let investmentYear;
    let investmentLiens;
    if (investment) {
      hei = investment;
      home_values.push({
        // @ts-expect-error TS(2339): Property 'effective_date' does not exist on type '... Remove this comment to see the full error message
        as_of_date: investment.effective_date,
        // @ts-expect-error TS(2339): Property 'beginning_home_value' does not exist on ... Remove this comment to see the full error message
        home_value: investment.beginning_home_value,
      });
      // @ts-expect-error TS(2339): Property 'effective_date' does not exist on type '... Remove this comment to see the full error message
      investmentYear = getYearFromDateString(investment?.effective_date);
      // @ts-expect-error TS(2339): Property 'lien_reviews' does not exist on type 'ne... Remove this comment to see the full error message
      investmentLiens = investment?.lien_reviews;
      if (investmentLiens.length) {
        if (firstVisitHEI) {
          // logic for first time HED users who have an HEI
          // @ts-expect-error TS(2339): Property 'editedLienReviews' does not exist on typ... Remove this comment to see the full error message
          scenarioAndInvestmentLiens = investment.editedLienReviews;
        } else {
          // logic for return HED user with an HEI
          // we only want to show the user their investment liens if they also have that lien on their scenario
          // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          scenarioAndInvestmentLiens = replaceMatchingLiens(scenarioAndInvestmentLiens, investment, investmentLiens);
        }
      }
    }

    let pastTimelineDiff = 0;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (currentYear >= investmentYear) {
      // if the user has an investment, we want to start the timeline from the investment year
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      pastTimelineDiff = currentYear - investmentYear || 0;
    }

    const length = 10 + pastTimelineDiff;

    const appreciationRequestBody = {
      effective_period: {
        length,
        unit: 'years',
      },
      appreciation_percent: {
        annualized: true,
        value: scenario?.appreciation_rate,
      },
    };

    // Check if we have a HEI Lien
    const heiLien = visibleLiens.find(lien => {
      return lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT;
    });

    // For hypothetical HEI's, we need to add the lien data to the hei body rather than the liens array
    if (heiLien) {
      const product = await getDefaultProduct();
      // @ts-expect-error TS(2339): Property 'hei' does not exist on type '{ effective... Remove this comment to see the full error message
      appreciationRequestBody.hei = {
        investment_amount: heiLien.original_balance,
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosRespons... Remove this comment to see the full error message
        product_id: product?.id, //HOOT-2457, remove this when we make backend changes
        effective_date: new Date().toISOString().slice(0, 10),
      };
    }

    if (home_values.length) {
      // @ts-expect-error TS(2339): Property 'home_values' does not exist on type '{ e... Remove this comment to see the full error message
      appreciationRequestBody.home_values = home_values;
    }

    if (hei) {
      // @ts-expect-error TS(2339): Property 'hei' does not exist on type '{ effective... Remove this comment to see the full error message
      appreciationRequestBody.hei = hei;
    }

    if (scenarioAndInvestmentLiens.length) {
      // We don't want to include liens of HEI type in the appreciation request body
      // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{ effecti... Remove this comment to see the full error message
      appreciationRequestBody.liens = scenarioAndInvestmentLiens.filter(lien => {
        return lien.lien_type !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT;
      });
    }
    // get appreciation data for scenario
    const annualAppreciationResults = await postAnnualAppreciation(appreciationRequestBody);
    // @ts-expect-error TS(2339): Property 'annual_data' does not exist on type 'Axi... Remove this comment to see the full error message
    const annualAppreciation = annualAppreciationResults?.annual_data;
    scenario.annualAppreciation = formatAppreciationDataWithRenovations(annualAppreciation);

    // utils to get more equity data for dashboard
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    const currentEquity = getCurrentEquity(homeValuation?.value, visibleLiens, scenario, investment);

    let forecastedAppreciationValues = {};
    if (annualAppreciation.length > 0) {
      // @ts-expect-error TS(7006): Parameter 'appreciation' implicitly has an 'any' t... Remove this comment to see the full error message
      forecastedAppreciationValues = annualAppreciation.map(appreciation => {
        return getForecastedValueAndEquity(appreciation, visibleLiens);
      });
    }

    scenario.valuations = {
      ...currentEquity,
      forecastedValues: forecastedAppreciationValues || [],
    };
    return { ...scenario };
  } catch (error) {
    const message = 'Failed to get annual appreciation data for scenario';
    console.error(message, error);

    sentry.logErrorWrapper(message, error);
    throw error;
  }
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const postScenarioAndLiens = async (homeId, scenarioData) => {
  const { liens } = scenarioData;
  try {
    const scenario = await postEquityScenario(homeId, scenarioData);

    const savedLiens = await Promise.all(
      // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
      liens.map(lien => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosRespons... Remove this comment to see the full error message
        return postEquityScenarioLien(homeId, scenario?.id, lien);
      }),
    );

    // @ts-expect-error TS(2339): Property 'liens' does not exist on type 'AxiosResp... Remove this comment to see the full error message
    scenario.liens = savedLiens;
    return scenario;
  } catch (error) {
    sentry.logErrorWrapper('Failed to save new scenario', error);
    throw error;
  }
};

/*
  EQUITY SCENARIO METHODS
*/

// Fetches existing equity scenarios
// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
const fetchExistingEquityScenarios = async (homeId, chartData) => {
  try {
    const scenarios = await getEquityScenarios(homeId);
    // @ts-expect-error TS(2339): Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
    if (scenarios?.length) {
      chartData.scenarios = scenarios;
      return scenarios;
    }
  } catch (error) {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (error.response?.status === 403) {
      // Redirect to the login page if error status is 403
      // This is to handle the case where the user's session has expired and chrome tab is idle
      // This should be removed once a more robust solution is implemented for our auto-loggout functionality
      window.location.href = getLoginUrl();
    } else {
      sentry.logErrorWrapper('Failed to fetch equity scenarios', error);
      throw error;
    }
  }
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
const createEquityScenariosForHome = async (homeId, homeValuation, investment, chartData) => {
  if (investment) {
    try {
      const newScenario = await postEquityScenario(homeId, {
        name: 'Moderate Appreciation',
        appreciation_rate: '0.05',
        duration_months: 120,
      });

      setLienData(newScenario);
      // @ts-expect-error TS(2339): Property 'unsavedChanges' does not exist on type '... Remove this comment to see the full error message
      newScenario.unsavedChanges = false;
      // @ts-expect-error TS(2339): Property 'renovations' does not exist on type 'Axi... Remove this comment to see the full error message
      newScenario.renovations = [];
      // @ts-expect-error TS(2339): Property 'liens' does not exist on type 'AxiosResp... Remove this comment to see the full error message
      newScenario.liens = [];

      // we call pricing endpoint now to get the current appreciated values for the investment liens
      // which we will use to create the scenario liens
      const newScenarioWithAppreciationRates = await fetchAndFormatAppreciationData(
        newScenario,
        homeValuation,
        investment,
        true,
      );
      const investmentLiensWithCurrentAppreciatedValues = updateInvestmentLiensWithCurrentAppreciatedValues(
        investment?.editedLienReviews,
        newScenarioWithAppreciationRates.annualAppreciation,
      );

      // Create liens on the scenario with the current appreciated values
      await Promise.all(
        investmentLiensWithCurrentAppreciatedValues.map(lien => {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosRespons... Remove this comment to see the full error message
          return postEquityScenarioLien(homeId, newScenario?.id, lien);
        }),
      );

      // Fetch new scenario with liens
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosRespons... Remove this comment to see the full error message
      const newScenarioFetched = await getEquityScenario(homeId, newScenario?.id);
      chartData.scenarios = [newScenarioFetched];
    } catch (error) {
      const message = 'Failed to create equity scenario and liens for first time HEI user';
      console.error(message, error);
      sentry.logErrorWrapper(message, error);
    }
  } else {
    // this logic is for onboarding flow users
    try {
      const newScenario = await postEquityScenario(homeId, {
        name: 'Moderate Appreciation',
        appreciation_rate: '0.05',
        duration_months: 120,
      });
      // Attempt to fetch self reported liens (liens created from onboarding flow).
      const selfReportedLiens = await getSelfReportedLiens(homeId);
      // @ts-expect-error TS(2339): Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
      if (selfReportedLiens?.length) {
        // Post new equity scenario liens from self reported liens
        await Promise.all(
          // @ts-expect-error TS(2339): Property 'map' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
          selfReportedLiens.map(selfReportedLien => {
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosRespons... Remove this comment to see the full error message
            return postEquityScenarioLien(homeId, newScenario?.id, selfReportedLien);
          }),
        );
      }
      // Fetch new scenario with liens
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosRespons... Remove this comment to see the full error message
      const newScenarioFetched = await getEquityScenario(homeId, newScenario?.id);
      chartData.scenarios = [newScenarioFetched];
    } catch (error) {
      const message = 'Failed to create initial equity scenario and lien';
      console.error(message, error);
      sentry.logErrorWrapper(message, error);
    }
  }
};

// Fetches or creates equity scenarios
// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
const fetchOrCreateEquityScenarios = async (homeId, homeValuation, investment, chartData) => {
  const equityScenarios = await fetchExistingEquityScenarios(homeId, chartData);
  // @ts-expect-error TS(2339): Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
  if (!equityScenarios?.length) {
    await createEquityScenariosForHome(homeId, homeValuation, investment, chartData);
  }
};

/*
  LIEN AND ANNUAL APPRECIATION
*/

// @ts-expect-error TS(7006): Parameter 'homeValuation' implicitly has an 'any' ... Remove this comment to see the full error message
const setLiensFetchAppreciationData = async (homeValuation, investment, chartData) => {
  if (homeValuation?.value && chartData.scenarios.length) {
    const scenariosWithAppreciationRates = await Promise.all(
      // @ts-expect-error TS(7006): Parameter 'scenario' implicitly has an 'any' type.
      chartData.scenarios.map(scenario => {
        setLienData(scenario);
        scenario.unsavedChanges = false;
        return fetchAndFormatAppreciationData(scenario, homeValuation, investment, false);
      }),
    );
    chartData.scenarios = scenariosWithAppreciationRates;
  }
};

// Main function to fetch data for home equity tab on dashboard
// @ts-expect-error TS(7031): Binding element 'homeId' implicitly has an 'any' t... Remove this comment to see the full error message
export const fetchChartData = async ({ homeId, homeValuation, investment }) => {
  const chartData = {};

  await fetchOrCreateEquityScenarios(homeId, homeValuation, investment, chartData);
  await setLiensFetchAppreciationData(homeValuation, investment, chartData);

  return chartData;
};
