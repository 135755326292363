import cx from 'classnames';

import { Icon, Button, SlideDown } from '@hometap/htco-components';
import { NavLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import './ApplicationNavSection.scss';

// @ts-expect-error TS(7031): Binding element 'sectionLabel' implicitly has an '... Remove this comment to see the full error message
const ApplicationNavSection = ({ sectionLabel, navItems, disabled: sectionDisabled, icon }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isSectionOpen =
    !sectionDisabled &&
    // @ts-expect-error TS(7031): Binding element 'to' implicitly has an 'any' type.
    !!navItems.find(({ to }) => {
      const match = matchPath(
        {
          path: to,
          end: true,
          caseSensitive: false,
        },
        pathname,
      );
      return !!match;
    });

  return (
    <ul className="ApplicationNavSection">
      <div className="ApplicationNavSectionHeaderContainer">
        <Button
          theme="link"
          disabled={sectionDisabled}
          className="ApplicationNavSectionHeader"
          onClick={() => {
            navigate(navItems[0].to);
          }}
        >
          {sectionLabel}
        </Button>
        {icon && !sectionDisabled && (
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
          <span onClick={icon.action} onKeyDown={icon.action} role="button" tabIndex="0">
            <Icon className="ApplicationNavSectionIcon" name={icon.name} color={icon?.color} size="lg" />
          </span>
        )}
      </div>
      <SlideDown visible={isSectionOpen} maxHeight={350}>
        <div className="ApplicationNavSectionItems">
          {/* @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type. */}
          {navItems.map(item => {
            return (
              item && (
                <li className="ApplicationNavSectionItem" key={item.key}>
                  <NavLink
                    end
                    className={({ isActive }) =>
                      cx('ApplicationNavSectionLink', {
                        isActive,
                        isDisabled: item.disabled,
                      })
                    }
                    to={item.to}
                  >
                    {item.navLabel}
                  </NavLink>
                </li>
              )
            );
          })}
        </div>
      </SlideDown>
    </ul>
  );
};

export default ApplicationNavSection;
