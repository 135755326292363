import { useEffect } from 'react';

import { useAsync } from '@hometap/htco-components';
import { apiWithAuth } from 'utils/api';

// @ts-expect-error TS(7006): Parameter 'home_value' implicitly has an 'any' typ... Remove this comment to see the full error message
const createAndFetchShareFractions = async (home_value, investment_amount, product_id) => {
  const body = { home_value, investment_amount };
  if (product_id) {
    // @ts-expect-error TS(2339): Property 'product_id' does not exist on type '{ ho... Remove this comment to see the full error message
    body.product_id = product_id;
  }
  return await apiWithAuth.v1.post('/pricing/share-fractions', body);
};

// @ts-expect-error TS(7006): Parameter 'homeValue' implicitly has an 'any' type... Remove this comment to see the full error message
const useShareFractions = (homeValue, investmentAmount, productId) => {
  const { execute: fetchFractions, results: shareFractions } = useAsync(createAndFetchShareFractions);

  useEffect(() => {
    if (homeValue && investmentAmount && productId) {
      fetchFractions(homeValue, investmentAmount, productId);
    }
  }, [fetchFractions, homeValue, investmentAmount, productId]);

  return { shareFractions };
};

export default useShareFractions;
