const SettlingHeiWithCash = `<h1>Settling Your Home Equity Investment with Cash Savings</h1>
<p>Are you considering settling your Hometap Investment with savings? It can be a great option for many homeowners. But first, let&rsquo;s make sure you understand all of your options and what to consider when buying out Hometap&rsquo;s share of your home.</p>
<h2><strong>2 Things to Consider Before Settling</strong></h2>
<p>Settling your Hometap Investment is a big step, and if you&rsquo;re settling with savings, there are two things you should keep in mind before initiating the process.</p>
<ol>
<li>Make sure that you won&rsquo;t be depleting your&nbsp;<strong>emergency savings</strong>&nbsp;or other reserves by settling your Investment with cash. You should aim to have&nbsp;<a href="https://www.hometap.com/blog/how-to-get-started-with-an-emergency-fund" rel="noreferrer noopener">at least three months</a>&nbsp;worth of living expenses in your emergency savings so that unexpected costs or life events don&rsquo;t require you to take on more debt.</li>
<li>Weigh all of the<strong>&nbsp;settlement options</strong>&nbsp;available to you for settling your home equity investment so that the process goes smoothly and makes sense for your circumstances. Your other options include:</li>
</ol>
<ul>
<li><strong><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-home-sale" rel="noreferrer noopener">Home sale</a>:&nbsp;</strong>Do you want to stay in your home for the foreseeable future? If not, selling it may be a better option. After selling, you&rsquo;ll use the proceeds to settle your Investment.</li>
<li><strong><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-refinance" rel="noreferrer noopener">Cash-out refinance</a>:</strong>&nbsp;A cash-out refinance involves&nbsp;<a href="https://www.hometap.com/blog/when-is-a-good-time-to-refinance-your-home/" rel="noreferrer noopener">replacing your current mortgage</a>&nbsp;with one that carries a higher balance, and gives you the difference between the two in cash. If you&rsquo;re able to secure a lower interest rate with the new mortgage, this may be a good choice for you.</li>
<li><strong><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-home-loan-or-heloc" rel="noreferrer noopener">Home equity loan or line of credit</a>:</strong>&nbsp;Home equity loans and lines of credit are types of financing that can quickly provide you with funds. A home equity loan provides you with a lump sum of cash upfront, while a home equity line of credit is flexible and ongoing, and cash can be drawn from it like a credit card.</li>
</ul>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F313824c49b97ddcbfc9ec183b499a31cce4e2870-1000x295.png&w=2048&q=75"/>
<h2><strong>Determine What You Owe</strong></h2>
<p>After you&rsquo;ve decided to settle your Hometap Investment, the next step is to determine the approximate amount you owe, also known as the Hometap Share. You can use our Settlement Calculator to check your estimated balance by logging into your Hometap account.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F4aeec4632c743b1e88e110fd8d1bdb46ea4e73f6-1024x746.gif&w=2048&q=75"/>
<h2><strong>Initiating Your Settlement</strong></h2>
<p>Once you’ve confirmed the Hometap Share and know what you owe, it’s time to initiate the settlement process. Note that whole settling with savings tends to be the quickest method since their are no closing dates to consider, you should still contact us at least 30 days in advance to give us ample time.</p>
<p>If you plan to settle at the end of the ten year term, you can find your settlement date by selecting the Settlement Calculator.</p>
<p>Begin by contacting our Investment Support team at <a href="mailto:homeowners@hometap.com">homeowners@hometap.com</a> or calling +1 (617) 604-6985. Make sure you have the following information ready to provide:</p>
<ul>
<li>Your Settlement Statement&rsquo;s good-through date &mdash; this should be at least 30 days out.</li>
<li>The method of settlement &mdash; in this case, a buyout with savings.</li>
</ul>
<p>We&rsquo;ll then check the&nbsp;<a href="https://www.rocketmortgage.com/learn/fair-market-value" target="_blank" rel="noreferrer noopener">fair market value</a>&nbsp;(FMV) of your property using an&nbsp;<a href="https://www.rocketmortgage.com/learn/automated-valuation-model" target="_blank" rel="noreferrer noopener">automated valuation model</a>&nbsp;(AVM) or in-person appraisal if necessary (usually in cases of an unavailable AVM or recently completed renovations). Once finalized, a member of Investment Support will contact you via email. Read the email closely, as it will include your final payoff amount and details on how to submit your payment.</p>
<h2><strong>Completing Your Settlement</strong></h2>
<p>Now that you know your final payoff amount, the next step is to pay off the Hometap Share. There are four payment methods you can choose from to complete the settlement.</p>
<ul>
<li>A&nbsp;<strong><a href="https://www.consumerfinance.gov/ask-cfpb/what-is-a-wire-transfer-en-1163/" target="_blank" rel="noreferrer noopener">wire transfer</a>&nbsp;</strong>is an electronic method for sending money, and can be completed at a bank or cash office. This is often the preferred choice for homeowners because it&rsquo;s the quickest.</li>
<li>A<strong>&nbsp;<a href="https://www.pnc.com/insights/personal-finance/spend/cashiers-check-vs-certified-check.html" target="_blank" rel="noreferrer noopener">certified check</a>&nbsp;</strong>is a check drawn from your personal funds that is guaranteed by a bank after they confirm you have a sufficient amount of money.</li>
<li>A&nbsp;<strong><a href="https://www.cnn.com/cnn-underscored/money/what-is-a-cashiers-check" target="_blank" rel="noreferrer noopener">cashier&rsquo;s check</a>&nbsp;</strong>is also guaranteed by a bank, but in this case, the bank takes the funds, holds them in escrow, and prints a check against those funds.</li>
<li>A<strong>&nbsp;<a href="https://www.experian.com/blogs/ask-experian/what-is-money-order/" target="_blank" rel="noreferrer noopener">money order</a></strong>&nbsp;is a secure way to send a payment up to $1,000, so this is an option&nbsp;<strong>only if you have a settlement amount under $1,000</strong>. Money orders also won&rsquo;t bounce and have a quick timeline.</li>
</ul>
<p>After we&rsquo;ve received your funds via your chosen payment method, an Investment Support team member will reach out with confirmation. Next, we will file to lift the lien placed on your property when the initial Investment was made.</p>
<p>We&rsquo;ll also send you a letter verifying that your funds were received and your Hometap Investment has been settled. It will look something like this:</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F54faaf3cf7b24b04dfb3774b477059c7facd3c77-1000x413.png&w=2048&q=75"/>
<p>We always aim for a seamless, transparent settlement process. Below is a summary of what that entails.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2Fc8232c1c0a89f963b1e7a9d8adf2d785f8066479-1000x1084.png&w=2048&q=75"/>
<h2>After the Investment</h2>
<p>Once your Investment has been settled, you&rsquo;ll still have free access to your Home Equity Dashboard. With its resources and tools, your Dashboard is always available to help you protect and grow your home&rsquo;s value and equity and support you on your homeownership journey.</p>`;

export default SettlingHeiWithCash;
