import FileStatus from '../FileStatus/FileStatus';

// @ts-expect-error TS(7031): Binding element 'onRemove' implicitly has an 'any'... Remove this comment to see the full error message
const DocumentsFileList = ({ files = [], loading = false, onRemove, onReplace }) => {
  if (files.length === 0) {
    return null;
  }

  return (
    <div className="DocumentsFileList">
      <h4>Files</h4>
      <ul>
        {files.map(item => {
          const { id, file, status, errors } = item;
          return (
            <li key={id}>
              <FileStatus
                file={file}
                status={status}
                errors={errors}
                loading={loading}
                onRemove={() => onRemove(item)}
                onReplace={() => onReplace(item)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DocumentsFileList;
