import { createContext, useContext, useEffect, useState } from 'react';
import { apiV2NoAuth } from 'utils/api';
import MaintenanceModeScreenOverlay from 'components/MaintenanceModeScreenOverlay/MaintenanceModeScreenOverlay';
import Pusher from 'pusher-js';

const Context = createContext({});

const useSystemStatus = () => useContext(Context);
export default useSystemStatus;

const initialSystemStatusObject = {
  maintenanceMode: false,
};

/**
 * @description Provider which fetches the current system status on initial page loads
 * and subscribes to system-status pusher websocket channel and passes that data through to context.
 */
// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const SystemStatusProvider = ({ children }) => {
  // context state that represents if maintenance mode is enabled or not
  const [maintenanceMode, setMaintenanceMode] = useState(initialSystemStatusObject.maintenanceMode);

  // on mount fetch the initial system status
  useEffect(() => {
    apiV2NoAuth
      .get('system-status/')
      // @ts-expect-error TS(2339): Property 'system_status' does not exist on type 'A... Remove this comment to see the full error message
      .then(({ system_status }) => {
        setMaintenanceMode(system_status.maintenance_mode);
      })
      .catch(() => {
        return;
      });
  }, []);

  // on mount subscribe to the system status channel and listen for changes
  useEffect(() => {
    if (!process.env.REACT_APP_PUSHER_KEY || !process.env.REACT_APP_PUSHER_CLUSTER) return;
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const systemStatusChannel = pusher.subscribe('system-status');

    // listen to maintenance mode event changes within the system-status channel
    // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
    systemStatusChannel.bind('maintenance-mode', data => {
      setMaintenanceMode(data.maintenance_mode);
    });

    // unsubscribe from the system-status channel on unmount
    return () => {
      systemStatusChannel.unbind('maintenance-mode');
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
      systemStatusChannel.unsubscribe('system-status');
    };
  }, []);

  return (
    <Context.Provider value={{ maintenanceMode }}>
      <MaintenanceModeScreenOverlay show={maintenanceMode} />

      {/* when maintenance mode is enabled, do not allow the background to be displayed while maintaining state */}
      <div style={{ display: maintenanceMode ? 'none' : 'block', height: '100%' }}>{children}</div>
    </Context.Provider>
  );
};
