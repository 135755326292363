import houses from '../../../../../../images/houses-illustration.png';

import { Button } from '@hometap/htco-components';

import './ResultNotOwner.scss';
import '../../../FitQuiz_Fixed.scss';

const ResultNotOwner = () => {
  return (
    <div className="ResultNotOwner">
      <div className="ResultNotOwnerContent">
        <h3>Unfortunately, Hometap wouldn’t be a good fit for you at this time.</h3>
        <p>
          Hometap works with homeowners to access the equity they’ve built up in their homes. You must own your home in
          order for Hometap to be able to make an investment.
        </p>
      </div>
      <Button href="https://hometap.com">Back to the homepage</Button>
      <div className="gatsby-image-wrapper">
        <img src={houses} alt="houses" />
      </div>
    </div>
  );
};

export default ResultNotOwner;
