import apiWithAuth from 'utils/api';

// @ts-expect-error TS(7031): Binding element 'trackId' implicitly has an 'any' ... Remove this comment to see the full error message
export const startApplication = ({ trackId }) => {
  return apiWithAuth.v1.post(`tracks/${trackId}/start-application/`);
};

// @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
export const fetchApplication = ({ applicationId }) => {
  return apiWithAuth.v1.get(`/applications/${applicationId}/`);
};

// @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
export const updateApplication = ({ applicationId, data }) => {
  return apiWithAuth.v1.patch(`/applications/${applicationId}/`, data);
};

// @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
export const fetchApplicationApplicants = ({ applicationId }) => {
  return apiWithAuth.v1.get(`/applications/${applicationId}/applicants/`);
};

// @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
export const createApplicant = ({ applicationId, data }) => {
  return apiWithAuth.v1.post(`applications/${applicationId}/applicants/`, data);
};

// @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
export const updateApplicant = ({ applicationId, applicantId, data }) => {
  return apiWithAuth.v1.patch(`applications/${applicationId}/applicants/${applicantId}/`, data);
};

// @ts-expect-error TS(7031): Binding element 'applicantId' implicitly has an 'a... Remove this comment to see the full error message
export const deleteApplicant = ({ applicantId, applicationId }) => {
  return apiWithAuth.v1.delete(`applications/${applicationId}/applicants/${applicantId}/`);
};

// @ts-expect-error TS(7031): Binding element 'applicationId' implicitly has an ... Remove this comment to see the full error message
export const createApplicationConsent = ({ applicationId }) => {
  return apiWithAuth.v1.post(`applications/${applicationId}/actions/consent/`);
};
