import { Button } from '@hometap/htco-components';
import { useNavigate } from 'react-router-dom';
import { Fade } from '@mui/material';

import { Icon } from '@hometap/htco-components';
import { buildHomeProfileUrl } from 'utils/links';
import PercentageBadge from 'apps/dashboard/components/dashboard-shared/PercentageBadge';
import './EmptyStatePanel.scss';

const EmptyStatePanel = () => {
  const navigate = useNavigate();

  const handleDetailsCTAClick = () => {
    navigate(buildHomeProfileUrl());
  };

  return (
    <div className="EmptyStatePanel">
      <div className="EmptyStatePanelWrapper">
        <Fade in timeout={600}>
          <div className="EmptyStatePanelContainer">
            <PercentageBadge />
            <div className="EmptyStatePanelIconContainer">
              <Icon size="4x" color="#20A277" name="house-singlefamily" />
            </div>
            <h4>You’re almost there!</h4>
            <div className="EmptyStatePanelDetails">
              Finish adding your property details to complete your profile and see a full picture of your home equity.
            </div>
            <Button
              size="small"
              onClick={handleDetailsCTAClick}
              data-testid="complete-profile-button"
              data-id="complete-my-profile-btn"
            >
              Complete my profile
            </Button>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default EmptyStatePanel;
