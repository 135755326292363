import CardWrapper from '../CardWrapper';
import TrackDetailsCardMainContent from '../TrackDetailsCard/TrackDetailsCardMainContent';

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const InvestmentCard = ({ track, className }) => {
  return (
    // @ts-expect-error TS(2741): Property 'testId' is missing in type '{ children: ... Remove this comment to see the full error message
    <CardWrapper className={className}>
      <TrackDetailsCardMainContent track={track} />
    </CardWrapper>
  );
};

export default InvestmentCard;
