import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { Container, Paper, useWindowSize } from '@hometap/htco-components';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import DebtFormController from './DebtFormController';
import DebtResults from '../components/debtResults/DebtResults';
import TwoColumnDebtChart from '../components/TwoColumnDebtChart/TwoColumnDebtChart';
import DebtCTA from '../components/debtCTA/DebtCTA';
import useLocalState from 'apps/htdc/utils/useLocalState';
import useIsVisible from 'apps/htdc/utils/useIsVisible';
import { useQueryParams } from 'apps/htdc/utils/urlUtilities';
import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';
import { calculatePayoffDate, totalDebtCalculations } from '../utils/debtCalculations';
import { setUserPropertiesForPortals } from 'apps/htdc/utils/utmData';
import { trackFinishClick, trackRemoveDebt } from '../utils/debtCalculatorTracks';
import DebtDisclaimer from '../components/debtDisclaimer/DebtDisclaimer';
import HeaderAndTextHero from 'apps/htdc/components/HeaderAndTextHero';
import WomanAtDesk from 'images/debt-calculator/woman-at-desk.png';
import ToolKitThumb from 'images/debt-calculator/toolkit-thumb.jpg';
import FAQList from 'apps/htdc/components/FAQList';
import { DEBT_CALCULATOR_FAQS } from '../constants/faqs';
import ImageCallout from 'apps/htdc/components/ImageCallout';

import './DebtCalculatorController.scss';

const DebtCalculatorController = ({
  heading = 'How much is your debt actually costing you?',
  subheading = `The average American has over $90,000 of revolving debt from credit cards, mortgages, student loans, and personal loans. But most people don’t realize how much they’re paying in interest. Use our debt calculator below to see how much your debt might actually be costing you:`,
  // @ts-expect-error TS(7031): Binding element 'cookies' implicitly has an 'any' ... Remove this comment to see the full error message
  cookies,
}) => {
  const { isScreenSize } = useWindowSize();
  const { ref, isVisible } = useIsVisible({ threshold: [0, 1] });
  const [isFinished, onFinish] = useState(false);
  const params = useQueryParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [debts, setDebts] = useLocalState([], 'DebtCalculatorDebts');
  const [lastUpdatedDebts, setLastUpdatedDebts] = useLocalState(null, 'LastUpdatedDebts');
  const { totalBalance, paymentsToZero, totalInterestPayments, totalMonthlyPayment, totalPrincipalBalance } = useMemo(
    () => totalDebtCalculations(debts),
    [debts],
  );

  const payOffDate = useMemo(() => calculatePayoffDate(paymentsToZero), [paymentsToZero]);

  const isIndex = !debts.length;
  // @ts-expect-error TS(7006): Parameter 'debtInfo' implicitly has an 'any' type.
  const onDebtSubmit = debtInfo => {
    setIsEditing(false);
    setDebts([...debts, debtInfo]);
    setLastUpdatedDebts(new Date());
  };

  // @ts-expect-error TS(7006): Parameter 'index' implicitly has an 'any' type.
  const onDebtDelete = index => {
    const newDebtList = [...debts].filter((debt, debtToDelete) => index !== debtToDelete);
    const data = { ...debts[index], debts };
    trackRemoveDebt(data);
    setDebts(newDebtList);
    setLastUpdatedDebts(new Date());
  };

  const handleFinishClick = () => {
    navigate('/debt-calculator/?results=true');
    trackFinishClick({
      debts,
      totalBalance,
      totalInterestPayments,
      totalMonthlyPayment,
      payOffDate,
      totalPrincipalBalance,
    });
    onFinish(true);
    setUserPropertiesForPortals(cookies, {
      'Debt Calculator Total Debts': debts.length,
      'Debt Calculator Monthly Payments': NUMBER_FORMATS.currency(totalMonthlyPayment, 0),
      'Debt Calculator Total Interest': NUMBER_FORMATS.currency(totalInterestPayments, 0),
      'Debt Calculator Total Balance': NUMBER_FORMATS.currency(totalBalance, 0),
      'Debt Calculator Debt Free Date': payOffDate,
      // @ts-expect-error TS(7006): Parameter 'debt' implicitly has an 'any' type.
      'Debt Calculator Debt Types': debts.map(debt => debt.typeOfDebt).join(','),
    });
  };

  // if local storage debts are more than 24 hours old delete them.
  useEffect(() => {
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const localStorageDate = new Date(lastUpdatedDebts);
    if (!localStorageDate || localStorageDate < yesterday) {
      setDebts([]);
      setLastUpdatedDebts(new Date());
    }
  }, [lastUpdatedDebts, setDebts, setLastUpdatedDebts]);

  return (
    <div
      className={cx('DebtCalculator', {
        DebtCalculatorIndex: isIndex,
      })}
    >
      {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div className="DebtCalculatorHeading" ref={ref}>
        <Container row justify="center">
          <Container grid={9} space={2}>
            <article>
              <h1>{heading}</h1>
              <p>{subheading}</p>
            </article>
          </Container>
        </Container>
      </div>
      {/*eslint-disable no-nested-ternary */}
      {isIndex ? (
        <div>
          <Paper theme="medium" pad={isScreenSize('sm') ? { top: 3, bottom: 3 } : isEditing ? 5 : 0}>
            <Container className={cx({ DebtCalculatorIndexSelect: isIndex && !isEditing })}>
              <Paper theme={isEditing ? 'elevated' : 'medium'} pad={isScreenSize('sm') ? 1 : 5}>
                {/* @ts-expect-error TS(2739): Type '{ onDebtSubmit: (debtInfo: any) => void; tit... Remove this comment to see the full error message */}
                <DebtFormController
                  onDebtSubmit={onDebtSubmit}
                  title="To get started, select a debt you carry:"
                  buttonText="Add my debt"
                  onSelectDebtType={setIsEditing}
                  debts={debts}
                  allowCancel={isIndex}
                />
              </Paper>
              <div className="DebtCalculatorDisclaimerContainer">{isEditing && <DebtDisclaimer />}</div>
            </Container>
            {!isEditing && <figure className="DebtCalculatorBgImage"></figure>}
          </Paper>
          {isEditing || (
            <div className="DebtCalculatorBlocksContainer">
              <HeaderAndTextHero
                heading="What is a Debt Payoff Calculator?"
                text="There’s a reason it’s called a debt snowball. Between credit card interest, car loans, school loans, a mortgage, and other liens, it’s easy to lose track of them all and determine what debt to pay off first. Our Cost of Debt Calculator (or debt payoff calculator) is designed to help you conceptualize the real cost of debt, so that you can identify which debts have the greatest interest and how long it will take you to pay them off."
                hasDivider={true}
                image={WomanAtDesk}
                imageAlt="woman working joyously"
              />
              {/* @ts-expect-error TS(2739): Type '{ faqs: { fieldGroupName: string; faqGroup: ... Remove this comment to see the full error message */}
              <FAQList faqs={DEBT_CALCULATOR_FAQS} useArrowTheme={false} />
              <ImageCallout
                initialText="For more information on consolidating debt and different strategies to help get you there, check out our guide on"
                linkText="Good vs. Bad Debt."
                heading="Your guide to leveraging good debt and eliminating bad debt."
                buttonText="DOWNLOAD THE GUIDE"
                disclaimerText="To determine the best option for your personal circumstances and goals, consult with a qualified financial advisor."
                image={ToolKitThumb}
                imageAlt="man viewing house data"
              />
            </div>
          )}
        </div>
      ) : // @ts-expect-error TS(2339): Property 'results' does not exist on type '{}'.
      isFinished && params?.results ? (
        <DebtResults
          debts={debts}
          totalBalance={totalBalance}
          totalInterestPayments={totalInterestPayments}
          totalMonthlyPayment={totalMonthlyPayment}
          payOffDate={payOffDate}
        />
      ) : (
        <div>
          <Container align="center" pad={0} className="DebtCalculatorTwoColumnChart">
            <TwoColumnDebtChart
              debts={debts}
              onDebtSubmit={onDebtSubmit}
              onDebtDelete={onDebtDelete}
              isSticky={!isVisible}
              onFinish={handleFinishClick}
              totalBalance={totalBalance}
              payOffDate={payOffDate}
              showDisclaimer={!isIndex}
            />
          </Container>

          <Paper theme="medium" pad={{ bottom: 6 }}>
            <DebtCTA />
          </Paper>
        </div>
      )}
    </div>
  );
};

export default withCookies(DebtCalculatorController);
