import cx from 'classnames';

import { Banner, Button, useBoolState } from '@hometap/htco-components';
import { formatDate } from 'utils/date';
import useCurrentUser from 'hooks/useCurrentUser';
import { getCoAppTrackDetailsUrl, getPrimaryAppTrackDetailsUrl } from '../../../utils/links';

const textStyles = 'OfferBannerText';
const btnStyles = 'OfferBannerBtn';

const OfferBanner = ({
  // @ts-expect-error TS(7031): Binding element 'offer' implicitly has an 'any' ty... Remove this comment to see the full error message
  offer,
  // @ts-expect-error TS(7031): Binding element 'trackId' implicitly has an 'any' ... Remove this comment to see the full error message
  track: { id: trackId, membership, sign_by_date, current_stage: stage },
  // @ts-expect-error TS(7031): Binding element 'onAccept' implicitly has an 'any'... Remove this comment to see the full error message
  onAccept,
  // @ts-expect-error TS(7031): Binding element 'onDecline' implicitly has an 'any... Remove this comment to see the full error message
  onDecline,
}) => {
  // @ts-expect-error TS(2339): Property 'isStaff' does not exist on type '{}'.
  const { isStaff } = useCurrentUser();
  const acceptedStages = ['ConditionsInReview', 'ClosingInProgress', 'Invested', 'Settled'];
  const changedDecline = useBoolState();

  const isLegacyBannerCopy = !offer.accepted_by_date || !sign_by_date;
  const isPrimaryApplicant = membership?.role === 'primary';

  const backToTrackDetailsButton = () => {
    const trackDetailsUrl = isPrimaryApplicant
      ? getPrimaryAppTrackDetailsUrl(trackId)
      : getCoAppTrackDetailsUrl(trackId);
    return (
      <Button className={btnStyles} theme="alternative" href={trackDetailsUrl}>
        Back to Investment details
      </Button>
    );
  };

  const offerDeclinedButton = () => {
    if (isPrimaryApplicant) {
      return changedDecline.value ? (
        <Button className={cx(btnStyles, 'isDeclined')} onClick={onAccept}>
          Accept Offer
        </Button>
      ) : (
        <Button className={cx(btnStyles, 'isDeclined')} theme="link" onClick={changedDecline.setTrue}>
          Change your mind?
        </Button>
      );
    }
    return backToTrackDetailsButton();
  };

  const bannerCopy = isLegacyBannerCopy ? (
    <div>
      Please review your final Investment offer and click the “accept offer” button to schedule your signing. If you
      have any questions, just let us know!
    </div>
  ) : (
    <>
      <div className="OfferExpirationCopy">
        This Offer can be accepted through <strong>{formatDate(offer.accepted_by_date, 'MMMM D, YYYY')}</strong>, and
        you’ll need to sign your Investment documents by <strong>{formatDate(sign_by_date, 'MMMM D, YYYY')}</strong>.
      </div>
      <div>
        If you’re still interested in receiving an Investment after the Offer expires, please reach out to your
        Investment Manager or log into the Hub to request a new Investment.
      </div>
    </>
  );

  if (isStaff) {
    return (
      <Banner>
        <span className={textStyles}>
          This is an <b>{offer.active ? 'active' : 'inactive'} offer.</b>
        </span>
      </Banner>
    );
  }

  if (!offer.active) {
    return (
      <Banner>
        <span className={textStyles}>This offer is no longer active.</span>
        <span>{backToTrackDetailsButton()}</span>
      </Banner>
    );
  }

  if (stage === 'OfferReady' && isPrimaryApplicant) {
    return (
      <Banner>
        <span className={textStyles}>{bannerCopy}</span>
        <span>
          <Button className={btnStyles} onClick={onAccept}>
            Accept Offer
          </Button>
          <Button className={btnStyles} theme="link" onClick={onDecline}>
            Decline Offer
          </Button>
        </span>
      </Banner>
    );
  } else if (stage === 'OfferDeclined') {
    return (
      <Banner>
        <span className={cx(textStyles, 'isDeclined')}>
          Offer <b>declined.</b> While we’re sorry that a Hometap Investment isn’t a fit for you at the moment, we’re so
          happy you considered us! Please reach out with any additional questions.
        </span>
        <span>{offerDeclinedButton()}</span>
      </Banner>
    );
  } else if (acceptedStages.includes(stage)) {
    return (
      <Banner>
        <span className={textStyles}>
          Offer <b>accepted.</b> Congratulations and welcome to the Hometap community! We’re looking forward to helping
          you reach your financial goals and get more out of homeownership.
        </span>
        <span>{backToTrackDetailsButton()}</span>
      </Banner>
    );
  }

  return (
    isPrimaryApplicant && (
      <Banner>
        <span className={cx(textStyles, 'isDeclined')}>
          Please review this investment offer and if you have any questions, just let us know!
        </span>
        <span>{backToTrackDetailsButton()}</span>
      </Banner>
    )
  );
};

export default OfferBanner;
