import { useWindowSize } from '@hometap/htco-components';
import PricingMatrixTable from './components/PricingMatrixTable/PricingMatrixTable';

import './PricingMatrix.scss';
import PricingMatrixCard from './components/PricingMatrixCard/PricingMatrixCard';

// @ts-expect-error TS(7031): Binding element 'rows' implicitly has an 'any' typ... Remove this comment to see the full error message
const PricingMatrix = ({ rows, data }) => {
  const { isScreenSize } = useWindowSize({ delay: 250 });

  return isScreenSize('sm') ? (
    // @ts-expect-error TS(7006): Parameter 'el' implicitly has an 'any' type.
    data.map((el, i) => {
      return <PricingMatrixCard rows={rows} cardData={el} key={`pricing-matrix-${i}`} />;
    })
  ) : (
    <PricingMatrixTable rows={rows} data={data} />
  );
};

export default PricingMatrix;
