import { useEffect, useState } from 'react';

import './MaintenanceModeScreenOverlay.scss';
import image from 'images/maintenance_mode.webp';

// @ts-expect-error TS(7031): Binding element 'show' implicitly has an 'any' typ... Remove this comment to see the full error message
const MaintenanceModeScreenOverlay = ({ show }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
      return;
    }
    setTimeout(() => {
      setVisible(false);
    }, 250);
  }, [show]);

  return (
    <div
      className={`MaintenanceModeScreenOverlayContainer ${show ? 'fade-in' : 'fade-out'}`}
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <div className="MaintenanceModeScreenOverlayContentContainer">
        {/* image */}
        <img className="MaintenanceModeScreenOverlayContainerImage" src={image} alt="Maintenance Mode graphic" />
        {/* text */}
        <div className="MaintenanceModeScreenOverlayBody">
          <h1 className="MaintenanceModeScreenOverlayContentHeader">Website under maintenance.</h1>
          <p className="MaintenanceModeScreenOverlayContentDescription">
            We apologize for the inconvenience while your Hometap Dashboard undergoes planned maintenance. We’ll refresh
            this page automatically when finished.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceModeScreenOverlay;
