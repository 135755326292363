import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
  A hook that returns a function to navigate back to the previous page or to a default page if there
  is no previous page. Useful for pages that have direct links from outside the app (e.g. emails).
 */
// @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
const useGoBackWithDefault = ({ defaultUrl, replace = true } = {}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isCurrentRouteOrigin = location.key === 'default';
  // useful to check if the origin page was not a primary page NOTE: this could we lose reference to
  // the ref when we have a new instance of a layout calling this hook
  const isOriginNestedPageRef = useRef(isCurrentRouteOrigin);
  const isFromNestedPage = isOriginNestedPageRef.current || location.state?.isFromNestedPage;
  const canGoBack = !isCurrentRouteOrigin && !isFromNestedPage && !location.state?.notFromBackButton;

  const goBack = () => {
    if (canGoBack) {
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      navigate(-1, { replace });
    } else {
      navigate(defaultUrl);
    }
  };

  return { goBack, canGoBack };
};

export default useGoBackWithDefault;
