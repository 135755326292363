import { omit, pick, startCase } from 'lodash';

import LiensFormFields from './forms/LiensFormFields';
import LienTypesFormFields from './forms/LienTypesFormFields';
import OtherLienDetailsFormFields from './forms/OtherLienDetailsFormFields';
import PropertyTitleFormFields from './forms/PropertyTitleFormFields';
import PropertyHOAForm from './forms/PropertyHOAForm/PropertyHOAForm';
import BackgroundInfoForm from './forms/BackgroundInfoForm';
import ContactInfoForm from './forms/ContactInfoForm/ContactInfoForm';
import EmploymentInfoForm from './forms/EmploymentInfoForm';
import MailingAddressForm from './forms/MailingAddressForm/MailingAddressForm';
import OtherApplicantsForm from './forms/OtherApplicantsForm';
import PersonalInfoForm from './forms/PersonalInfoForm';
import SubmitApplicationForm from './forms/SubmitApplicationForm/SubmitApplicationForm';
import { APPLICANT_FORM_FIELD, APPLICANT_MAILING_ADDRESS_FIELDS, APPLICATION_FORM_FIELD } from './constants/formFields';
import { APPLICATION_MODEL_FIELD } from './constants/applicationDataFields';
import { showNotification } from 'utils/toasts';
import PropertyHOABoolForm from './forms/PropertyHOABoolForm/PropertyHOABoolForm';
import LegalLink from 'components/LegalLink/LegalLink';

// @ts-expect-error TS(7006): Parameter 'basePath' implicitly has an 'any' type.
export const getFullApplicationPath = basePath => `${basePath}/:section?/:page?/:applicantId?`;

// @ts-expect-error TS(7006): Parameter 'applicants' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getInMemoryApplicants = applicants => applicants.filter(applicant => applicant.isNew);
// @ts-expect-error TS(7006): Parameter 'applicants' implicitly has an 'any' typ... Remove this comment to see the full error message
export const hasInMemoryApplicant = applicants => !!getInMemoryApplicants(applicants).length;

// @ts-expect-error TS(7006): Parameter 'applicant' implicitly has an 'any' type... Remove this comment to see the full error message
export const checkIsPrimaryApplicant = (applicant, applicants = []) =>
  applicant?.is_primary_applicant || (hasInMemoryApplicant(applicants) && applicants.length === 1);

// @ts-expect-error TS(7006): Parameter 'section' implicitly has an 'any' type.
export const filterConditionalPages = (section, formData) =>
  // @ts-expect-error TS(7031): Binding element 'onlyShowWhen' implicitly has an '... Remove this comment to see the full error message
  section.filter(({ onlyShowWhen }) => {
    return typeof onlyShowWhen === 'function' ? onlyShowWhen(formData) : true;
  });

// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const buildApplicationRoute = trackId => {
  const basePath = `/track/${trackId}/application`;
  // @ts-expect-error TS(7031): Binding element 'section' implicitly has an 'any' ... Remove this comment to see the full error message
  return ({ section, pageKey, applicantId = '' }) => `${basePath}/${section}/${pageKey}/${applicantId}`;
};

// @ts-expect-error TS(7006): Parameter 'applicantId' implicitly has an 'any' ty... Remove this comment to see the full error message
export const getApplicantFormKey = applicantId => {
  let selector = applicantId;
  if (!applicantId) {
    selector = 'new';
  }

  return `applicant:${selector}`;
};

export const APPLICATION_SECTION_URL_NAME = {
  applicants: 'applicants',
  property: 'property',
  consent: 'consent',
};

// @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
export const shouldRenderMortgagesAndLienTypes = formData => formData?.[APPLICATION_FORM_FIELD.hasLiens] === true;
// @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
export const shouldRenderOtherLienDetails = formData =>
  formData[APPLICATION_FORM_FIELD.lienTypes]?.includes('other') && shouldRenderMortgagesAndLienTypes(formData);

// @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
const getStreetAddressCopy = track => {
  return track.home?.address?.street ?? 'this address';
};

// @ts-expect-error TS(7006): Parameter 'applicant' implicitly has an 'any' type... Remove this comment to see the full error message
const getApplicantFirstNameOrDefault = applicant => {
  return applicant?.first_name || 'this applicant';
};

export const APPLICATION_CONFIG = {
  [APPLICATION_SECTION_URL_NAME.applicants]: [
    {
      pageKey: 'personal',
      navLabel: 'Personal Info',
      // @ts-expect-error TS(7031): Binding element 'currentApplicantFormData' implici... Remove this comment to see the full error message
      pageTitle: ({ data: { currentApplicantFormData } }) =>
        currentApplicantFormData?.isPrimaryApplicant
          ? 'Can you tell us a bit about yourself?'
          : `Can you tell us a bit about ${getApplicantFirstNameOrDefault(currentApplicantFormData)}?`,
      form: () => <PersonalInfoForm />,
    },
    {
      pageKey: 'contact',
      navLabel: 'Contact Info',
      // @ts-expect-error TS(7031): Binding element 'currentApplicantFormData' implici... Remove this comment to see the full error message
      pageTitle: ({ data: { currentApplicantFormData } }) =>
        currentApplicantFormData?.isPrimaryApplicant
          ? 'What’s the best way for us to contact you?'
          : `What’s the best way for us to contact ${getApplicantFirstNameOrDefault(currentApplicantFormData)}?`,
      form: () => <ContactInfoForm />,
    },
    {
      pageKey: 'mailing-address',
      navLabel: 'Mailing Address',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track, currentApplicantFormData } }) =>
        currentApplicantFormData?.isPrimaryApplicant
          ? `Is ${getStreetAddressCopy(track)} your mailing address?`
          : `Is ${getStreetAddressCopy(track)} ${getApplicantFirstNameOrDefault(
              currentApplicantFormData,
            )}’s mailing address?`,
      form: () => <MailingAddressForm />,
    },
    {
      pageKey: 'employment',
      navLabel: 'Employment Info',
      // @ts-expect-error TS(7031): Binding element 'currentApplicantFormData' implici... Remove this comment to see the full error message
      pageTitle: ({ data: { currentApplicantFormData } }) =>
        currentApplicantFormData?.isPrimaryApplicant
          ? `Can you share some employment details about yourself?`
          : `We just need a couple employment details about ${getApplicantFirstNameOrDefault(
              currentApplicantFormData,
            )}.`,
      form: () => <EmploymentInfoForm />,
    },
    {
      pageKey: 'background',
      navLabel: 'Background Info',
      // @ts-expect-error TS(7031): Binding element 'currentApplicantFormData' implici... Remove this comment to see the full error message
      pageTitle: ({ data: { currentApplicantFormData } }) =>
        currentApplicantFormData?.isPrimaryApplicant
          ? `In the past 10 years, have you ever...`
          : `In the past 10 years, has ${getApplicantFirstNameOrDefault(currentApplicantFormData)} ever...`,
      form: () => <BackgroundInfoForm />,
      isSavePage: true,
    },
    {
      pageKey: 'other-applicants',
      navLabel: 'Other Applicants',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track } }) => {
        return `Are there other people on the property title for ${getStreetAddressCopy(track)}?`;
      },
      form: () => <OtherApplicantsForm />,
      // @ts-expect-error TS(7031): Binding element 'formData' implicitly has an 'any'... Remove this comment to see the full error message
      isSavePage: ({ formData, application }) =>
        formData?.[APPLICANT_FORM_FIELD.hasOtherApplicants] === false &&
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        !application[APPLICATION_MODEL_FIELD.hasSubmittedApplicants],
    },
  ],

  [APPLICATION_SECTION_URL_NAME.property]: [
    {
      pageKey: 'property-title',
      navLabel: 'Property Title',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track } }) => `Is the property title for ${getStreetAddressCopy(track)} held in a trust?`,
      form: () => <PropertyTitleFormFields />,
    },
    {
      pageKey: 'property-hoa',
      navLabel: 'Homeowners Association',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track } }) => `Is ${getStreetAddressCopy(track)} a part of a Homeowners Association (HOA)?`,
      form: () => <PropertyHOABoolForm />,
    },
    {
      pageKey: 'property-hoa-info',
      navLabel: 'HOA Info',
      pageTitle: 'We need a little more info about your Homeowners Association.',
      form: () => <PropertyHOAForm />,
      // @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
      onlyShowWhen: formData => formData[APPLICATION_FORM_FIELD.hasHoa] === true,
    },
    {
      pageKey: 'mortgages-liens',
      navLabel: 'Mortgages & Liens',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track } }) =>
        `Are there any outstanding mortgages or liens on ${getStreetAddressCopy(track)}?`,
      form: () => <LiensFormFields />,
    },
    {
      pageKey: 'mortgages-liens-types',
      navLabel: 'Mortgage & Lien Types',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track } }) => `Which of these types of liens are on ${getStreetAddressCopy(track)}?`,
      form: () => <LienTypesFormFields />,
      onlyShowWhen: shouldRenderMortgagesAndLienTypes,
    },
    {
      pageKey: 'other-lien-details',
      navLabel: 'Other Lien Details',
      // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
      pageTitle: ({ data: { track } }) =>
        `Can you tell us a little bit more about the “other” lien that is on ${getStreetAddressCopy(track)}?`,
      form: () => <OtherLienDetailsFormFields />,
      onlyShowWhen: shouldRenderOtherLienDetails,
    },
  ],
  [APPLICATION_SECTION_URL_NAME.consent]: [
    {
      pageKey: 'accept',
      navLabel: 'Submit Application',
      pageTitle: 'Accept Terms and Submit',
      form: () => <SubmitApplicationForm />,
    },
  ],
};

// @ts-expect-error TS(7006): Parameter 'applicant' implicitly has an 'any' type... Remove this comment to see the full error message
export const buildDataForApplicantSave = (applicant, track) => {
  const fields = omit(
    pick({ ...applicant }, Object.values(APPLICANT_FORM_FIELD)),
    // omit mailing address fields being sent in the payload. The applicant's mailing address will be populated as a
    // separate field
    APPLICANT_MAILING_ADDRESS_FIELDS,
  );

  let mailingAddress;
  if (applicant[APPLICANT_FORM_FIELD.isTrackAddressMailingAddress] === false) {
    mailingAddress = {
      street: applicant[APPLICANT_FORM_FIELD.street],
      city: applicant[APPLICANT_FORM_FIELD.city],
      zip_code: applicant[APPLICANT_FORM_FIELD.zipCode],
      state: applicant[APPLICANT_FORM_FIELD.state],
      unit: applicant[APPLICANT_FORM_FIELD.unit] || '',
    };
  } else {
    mailingAddress = track.home.address;
  }

  const obfuscatedSSNRegex = /^(\*{3,})-(\*{2,})-\d{4}$/;
  const isObfuscatedSSN = obfuscatedSSNRegex.test(applicant[APPLICANT_FORM_FIELD.ssn]);

  if (isObfuscatedSSN) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    delete fields[APPLICANT_FORM_FIELD.ssn];
  }
  // @ts-expect-error TS(2339): Property 'mailing_address' does not exist on type ... Remove this comment to see the full error message
  fields.mailing_address = mailingAddress;
  return fields;
};

// @ts-expect-error TS(7006): Parameter 'application' implicitly has an 'any' ty... Remove this comment to see the full error message
export const getOtherLienDescription = application => {
  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  const savedApplicationDescription = application[APPLICATION_MODEL_FIELD.lienTypes].find(
    // @ts-expect-error TS(7006): Parameter 'lienType' implicitly has an 'any' type.
    lienType => lienType.kind === 'other',
  );

  return savedApplicationDescription
    ? savedApplicationDescription[APPLICATION_MODEL_FIELD.otherLienDetails]
    : // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
      application[APPLICATION_MODEL_FIELD.otherLienDetails];
};

// @ts-expect-error TS(7006): Parameter 'applicationFormData' implicitly has an ... Remove this comment to see the full error message
export const buildDataForApplicationUpdate = applicationFormData => {
  const fields = pick(applicationFormData, Object.values(APPLICATION_MODEL_FIELD));
  const liensTypes =
    applicationFormData?.[APPLICATION_FORM_FIELD.hasLiens] === true &&
    applicationFormData?.[APPLICATION_FORM_FIELD.lienTypes]?.length
      ? applicationFormData?.[APPLICATION_FORM_FIELD.lienTypes]
      : [];
  // backend expects and array of objects for lien_types
  // @ts-expect-error TS(7006): Parameter 'lienType' implicitly has an 'any' type.
  const formattedLienTypes = liensTypes.map(lienType => {
    return {
      kind: lienType,
      // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
      [APPLICATION_MODEL_FIELD.otherLienDetails]:
        lienType === 'other' ? getOtherLienDescription(applicationFormData) : '',
    };
  });

  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  fields[APPLICATION_MODEL_FIELD.lienTypes] = formattedLienTypes;
  // we can assume if the user has made it to where they are saving the application that they have
  // submitted liens
  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  fields[APPLICATION_MODEL_FIELD.hasSubmittedLiens] = true;

  // clear HOA fields when it is selected that there is no HOA in case they were already filled out
  if (applicationFormData?.[APPLICATION_FORM_FIELD.hasHoa] === false) {
    fields[APPLICATION_FORM_FIELD.hoaContactName] = '';
    fields[APPLICATION_FORM_FIELD.hoaContactPhone] = '';
    fields[APPLICATION_FORM_FIELD.hoaStreet] = '';
    fields[APPLICATION_FORM_FIELD.hoaUnit] = '';
    fields[APPLICATION_FORM_FIELD.hoaCity] = '';
    fields[APPLICATION_FORM_FIELD.hoaState] = '';
    fields[APPLICATION_FORM_FIELD.hoaZipCode] = '';
  }

  return fields;
};

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export const getFormattedApplicantFieldErrors = error => {
  const errorMap = {
    ssn: 'Social Security Number',
  };

  for (const field of Object.values(APPLICANT_FORM_FIELD)) {
    if (field === APPLICANT_FORM_FIELD.ssn) continue;

    const sentenceCaseField = field
      .split('_')
      .filter(word => !!word)
      .map(word => startCase(word))
      .join(' ');

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    errorMap[field] = sentenceCaseField;
  }

  if (!error) return null;

  const responseError = error.response?.data || {};
  // if the response error is a string, we won't enumerate over the field errors
  if (typeof responseError === 'string') {
    return null;
  }
  if (!Object.keys(responseError).length) {
    return null;
  }

  const errors = Object.keys(error.response.data)
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .map(errorKey => errorMap[errorKey])
    .join(', ');

  return errors;
};

export const onSaveError =
  (errorCopy = 'Something went wrong. Please refresh and try again.') =>
  // @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
  error => {
    console.error(error, error?.response?.data);

    if (typeof errorCopy === 'function') {
      // @ts-expect-error TS(2349): This expression is not callable.
      errorCopy = errorCopy(error);
    }

    // @ts-expect-error TS(2345): Argument of type '{ type: string; description: str... Remove this comment to see the full error message
    showNotification({
      type: 'error',
      description: errorCopy,
    });
  };

export const applicationConsentLanguage = [
  <li key="consent-1">
    I authorize Hometap to obtain information from credit bureaus, including a full credit inquiry (commonly referred to
    as a “hard inquiry”) that may impact my credit score, consistent with Hometap’s{' '}
    <LegalLink text="Credit Authorization Policy" section="credit-authorization" />.
  </li>,
  <li key="consent-2">
    I authorize Hometap to verify any information contained in this application at any time, from any available source
    including a background check.
  </li>,
  <li key="consent-3">
    I attest that I have read and agree to Hometap’s <LegalLink text="Terms of Use" section="terms-of-use" />,{' '}
    <LegalLink text="Privacy Policy" section="privacy-policy" />,{' '}
    <LegalLink
      text="Consent to Electronic Communications and Disclosures"
      section="consent-to-electronic-communications-and-disclosures"
    />
    , and <LegalLink text="Sharing of Information Disclosure" section="sharing-of-information-disclosure" />.
  </li>,
  <li key="consent-4">
    I agree that Hometap may call or text me at the telephone number provided in this application using automated
    systems regarding my application, and that data rates may apply.
  </li>,
  <li key="consent-5">
    I acknowledge that I have reviewed all information related to me, and that information, as well as any other
    information I have provided in the application, is accurate and truthful. I further acknowledge that, to the extent
    I have provided information about other applicants, I am authorized to provide that information, and that
    information is accurate and truthful to the best of my knowledge.
  </li>,
  <li key="consent-6">
    I understand that submitting an application does not imply a commitment from Hometap to invest.
  </li>,
];
