import Layout from '../components/Layout';
import PageSEO from '../components/PageSEO';
import DebtCalculatorController from './controllers/DebtCalculatorController';

const DebtCalculator = () => {
  return (
    <PageSEO
      description="Our Cost of Debt Calculator (or debt payoff calculator) is designed to help you conceptualize how much of your debt is interest."
      title="Debt Calculator"
      canonicalUrl="https://go.hometap.com/debt-calculator"
      opengraphTitle="Hometap | Debt Calculator"
      opengraphDescription="Our Cost of Debt Calculator (or debt payoff calculator) is designed to help you conceptualize how much of your debt is interest."
    >
      {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
      <Layout>
        <DebtCalculatorController />
      </Layout>
    </PageSEO>
  );
};

export default DebtCalculator;
