import BackToDashBoardButton from './BackToDashboardButton';
import React from 'react';

type ProgressiveFormSlidesProps = {
  canGoBackToDashboard?: boolean;
  currentSlide: React.ReactNode;
};

const ProgressiveFormSlides: React.FC<ProgressiveFormSlidesProps> = ({
  canGoBackToDashboard = false,
  currentSlide,
}) => {
  return (
    <>
      {canGoBackToDashboard && <BackToDashBoardButton />}
      {currentSlide}
    </>
  );
};

export default ProgressiveFormSlides;
