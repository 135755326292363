import { useAsync } from '@hometap/htco-components';
import { useCallback } from 'react';

import apiWithAuth from 'utils/api';

// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const fetchTrackTasks = trackId => {
  return apiWithAuth.v2.get('tasks', { params: { track_ids: [trackId], status: ['in_process', 'not_started'] } });
};

const useTrackTodos = ({ trackId = '', immediate = false } = {}) => {
  const {
    results = [],
    loading,
    ...fetchTrackTasksAsync
  } = useAsync(fetchTrackTasks, { defaultLoading: true, params: [trackId], immediate });

  // @ts-expect-error TS(7006): Parameter 'task' implicitly has an 'any' type.
  const todos = results.map(task => {
    // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
    const formattedVariables = task.variables?.reduce((acc, variable) => {
      acc[variable.name] = variable.value;
      return acc;
    }, {});

    return { ...task, variables: formattedVariables };
  });

  return {
    todos,
    isLoading: loading,
    fetchTodos: fetchTrackTasksAsync.execute,
  };
};

// @ts-expect-error TS(7031): Binding element 'taskDefinitionKey' implicitly has... Remove this comment to see the full error message
export const useFilteredTodos = ({ taskDefinitionKey }) => {
  const { todos = [], isLoading, fetchTodos } = useTrackTodos();
  const fetchFilteredTodos = useCallback(fetchTodos, [fetchTodos]);

  let filteredTodos = todos;
  if (taskDefinitionKey) {
    // @ts-expect-error TS(7031): Binding element 'task_definition_key' implicitly h... Remove this comment to see the full error message
    filteredTodos = filteredTodos.filter(({ task_definition_key }) => task_definition_key === taskDefinitionKey);
  }

  return {
    todos: filteredTodos,
    isLoading,
    fetchFilteredTodos,
  };
};

export default useTrackTodos;
