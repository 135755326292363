export const PHONE_NUMBER_PREFIX = '+1 (';
export const PHONE_ERROR_MESSAGE = 'Please enter a valid phone number.';
export const PHONE_ERROR_MESSAGE_AREA_CODE = 'Please enter a valid area code.';

// Regular expression to match and extract the area code from a phone number in the format "+1 (000) 000-0000"
export const startsWithInvalidDigit = /^[01]/;

export const formatPhoneNumber = (value = '') => {
  // Remove the "+1 (" prefix if present
  const withoutCountryCode = value.startsWith(PHONE_NUMBER_PREFIX) ? value.slice(PHONE_NUMBER_PREFIX.length) : value;

  // Remove all non-numeric characters (e.g., parentheses, spaces, and dashes)
  const cleanedNumber = withoutCountryCode.replace(/\D/g, '');

  // Format the phone number in the expected API format: "000-000-0000"
  return cleanedNumber
    ? `${cleanedNumber.slice(0, 3)}-${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)}`
    : cleanedNumber;
};

/**
 * Validates and formats a phone number input.
 *
 * This function ensures that a phone number entered in the UI as "+1 (000) 000-0000"
 * is properly validated and transformed into the format "000-000-0000" for the backend.
 *
 * @param {string} value - The input phone number value.
 * @param {string} message - The default error message to return if validation fails.
 * @returns {string|undefined} - Returns an error message if validation fails, otherwise undefined.
 */
export const phoneValidator = (value: string, message: string = PHONE_ERROR_MESSAGE): string | undefined => {
  const formattedNumber = formatPhoneNumber(value);
  // Ensure the formatted number is exactly 12 characters long (including dashes)
  if (formattedNumber.length !== 12) {
    return message;
  }

  // Validate that the area code does not start with "1"
  if (startsWithInvalidDigit.test(formattedNumber)) {
    return PHONE_ERROR_MESSAGE_AREA_CODE;
  }
};
