import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiWithAuth from 'utils/api';
import sentry from 'utils/sentry';

const useReadDocument = () => {
  const queryClient = useQueryClient();

  return useMutation({
    // @ts-expect-error TS(2339): Property 'documentId' does not exist on type 'void... Remove this comment to see the full error message
    mutationFn: ({ documentId }) => {
      return apiWithAuth.v2.post(`/documents/${documentId}/actions/read/`);
    },
    onError: error => {
      const {
        // @ts-expect-error TS(2339): Property 'response' does not exist on type 'Error'... Remove this comment to see the full error message
        response: { status, statusText },
        message,
        // @ts-expect-error TS(2339): Property 'config' does not exist on type 'Error'.
        config,
      } = error;
      const requestUrl = config?.url;

      sentry.logError(`Failed to read document.`, {
        tags: {
          statusCode: status,
          statusText: statusText,
        },
        extra: {
          errorMessage: message,
          requestUrl: requestUrl,
        },
      });
    },
    onSuccess: () => {
      // @ts-expect-error TS(2559): Type 'string[]' has no properties in common with t... Remove this comment to see the full error message
      queryClient.invalidateQueries(['track.documents']);
    },
  });
};

export default useReadDocument;
