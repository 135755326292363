import { useAsync } from '@hometap/htco-components';
import { apiNoAuth } from 'utils/api';

const fetchStates = () => {
  return apiNoAuth.get(`/core/configurations/states/`);
};

const useStates = () => {
  const { results } = useAsync(fetchStates, {
    immediate: true,
  });

  results?.sort(compareState);

  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  const statesForDropdown = results?.map(state => ({ label: state.abbreviation, value: state.abbreviation }));
  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  const operationalStatesList = results?.filter(state => state.status === 'Operational');

  return { statesForDropdown, operationalStatesList };
};

/**
 * Compare two states by abbreviation
 */
// @ts-expect-error TS(7006): Parameter 'stateA' implicitly has an 'any' type.
export const compareState = (stateA, stateB) => {
  return stateA.abbreviation.localeCompare(stateB.abbreviation);
};

export default useStates;
