import PropTypes from 'prop-types';

// @ts-expect-error TS(7031): Binding element 'fileName' implicitly has an 'any'... Remove this comment to see the full error message
const ExportLink = ({ fileName, data = [], children, ...props }) => {
  const csvHeader = 'data:text/csv;charset=utf-8,';
  // @ts-expect-error TS(2339): Property 'map' does not exist on type 'never'.
  const dataString = data.map(rows => rows.map(JSON.stringify).join(',')).join('\r\n');
  const downloadLink = encodeURI(csvHeader + dataString);

  return (
    <a href={downloadLink} download={`${fileName}.csv`} {...props}>
      {children || 'Export to CSV'}
    </a>
  );
};

ExportLink.propTypes = {
  fileName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.array),
};

export default ExportLink;
