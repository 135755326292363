import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cloneDeepWith, isPlainObject, toString } from 'lodash';

import { useAsync } from '@hometap/htco-components';
import { getNewDashboardUrl, getNewHEDUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import houseImage from 'images/dashboard/build-profile-house-3.jpg';
import equityIncreaseImage from 'images/dashboard/build-profile-landing.png';
import OnboardingFormWrapper from '../OnboardingFormWrapper';
import ProgressiveFormSlides from '../ProgressiveFormSlides';
import { useDelayHomeEquityProfile, useEquityScenarioContext } from '../../hooks';
import { LIEN_FIELDS } from 'apps/dashboard/constants';
import numeral from 'numeral';
import { getVersion } from 'utils/env';
import { browserTrack } from 'utils/segment';
import useCurrentUser from 'hooks/useCurrentUser';
import { formatDate } from 'utils/date';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';
import useProgressBar from 'hooks/useProgressBar';
import useHouseCanaryLiens from 'apps/dashboard/hooks/useHouseCanaryLiens';
import ModalPageLayoutLite from 'components/Layouts/ModalPageLayoutLite/ModalPageLayoutLite';
import { ProgressBar } from 'components/ProgressProvider/ProgressProvider';
import { getHouseCanaryLienForms } from './utils';

const LIEN_ACTION_TYPE = {
  CONFIRMED: 'confirmed',
  EDITED: 'edited',
  REMOVED: 'removed',
};

// @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
const convertObjectToValidFormData = obj => {
  return cloneDeepWith(obj, value => {
    return !isPlainObject(value) ? toString(value) : undefined;
  });
};

// @ts-expect-error TS(7031): Binding element 'propertyDataAsOf' implicitly has ... Remove this comment to see the full error message
const TooltipContent = ({ propertyDataAsOf }) => {
  return (
    <div className="p-2" data-testid="onboarding-form-tooltip-content">
      <h4 className="mb-2.5">How did we come up with this data?</h4>
      <div>
        Hometap pulls comprehensive public data on estimated property LTV (loan-to-value) ratio from HouseCanary’s
        database. The below details have been populated based on their most recent data. If anything looks incorrect,
        please make changes before saving.
      </div>
      {propertyDataAsOf && (
        <div className="mt-2.5 text-[10px]/[16px] italic text-neutral-dark-75">
          Property data last updated on {formatDate(propertyDataAsOf, 'M/D/Y')}
        </div>
      )}
    </div>
  );
};

const HouseCanaryLiensController = ({ referrer }: { referrer: string }) => {
  // @ts-expect-error TS2339
  const { liens, asOfDate: liensAsOfDate } = useHouseCanaryLiens();
  const [currentLienFormIndex, setCurrentLienIndex] = useState(0);
  const navigate = useNavigate();
  const { initialScenario, addLien } = useEquityScenarioContext();
  const addLienAsync = useAsync(addLien, { executeThrow: true });
  const { home } = useCurrentHome();
  const currentHomeMutation = useCurrentHomeMutation();
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const { forceSetupProfileLater } = useDelayHomeEquityProfile();

  const lienForms = useMemo(() => {
    // @ts-expect-error TS2339
    return getHouseCanaryLienForms(liens, initialScenario?.liens);
    // @ts-expect-error TS2339
  }, [liens, initialScenario?.liens]);

  const nextUrl =
    referrer === HOMEOWNER_DASHBOARD.OVERVIEW ? getNewDashboardUrl(HOMEOWNER_DASHBOARD.OVERVIEW) : getNewHEDUrl();

  useEffect(() => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (!lienForms.length || home?.hed_debts_confirmed) {
      navigate(nextUrl, { replace: true });
      return;
    }
    // @ts-expect-error TS(2531): Object is possibly 'null'.
  }, [navigate, nextUrl, home?.hed_debts_confirmed, lienForms.length]);

  const currentLienForm = lienForms[currentLienFormIndex];
  const isLastLien = currentLienFormIndex === lienForms.length - 1;
  const isFirstLien = currentLienFormIndex === 0;

  // @ts-expect-error TS(7006): Parameter 'lienType' implicitly has an 'any' type.
  const buildSegmentData = (lienType, action) => ({
    email: user.email,
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    home_id: home.id,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    equity_scenario_id: initialScenario.id,
    lien_type: lienType,
    action: action,
    version: getVersion(),
  });

  // @ts-expect-error TS(7006): Parameter 'formData' implicitly has an 'any' type.
  const isLienDataChanged = (formData, initialData) =>
    initialData.lien_kind !== formData.lien_kind ||
    numeral(initialData.estimated_lien_balance).value() !== numeral(formData.estimated_lien_balance).value() ||
    numeral(initialData.rate).value() !== numeral(formData.rate).value() ||
    numeral(initialData.monthly_payment).value() !== numeral(formData.monthly_payment).value();

  const handleClose = () => {
    forceSetupProfileLater();
    navigate(nextUrl);
  };

  // @ts-expect-error TS(7006): Parameter 'initialFormData' implicitly has an 'any... Remove this comment to see the full error message
  const handleSkip = async initialFormData => {
    browserTrack.houseCanaryUpdateLienInformation(
      buildSegmentData(initialFormData.lien_type, LIEN_ACTION_TYPE.REMOVED),
    );
    await handleNext();
  };

  const handleNext = async () => {
    if (!isLastLien) return setCurrentLienIndex(prev => prev + 1);

    // @ts-expect-error TS(2345): Argument of type '{ hed_debts_confirmed: boolean; ... Remove this comment to see the full error message
    const res = await currentHomeMutation.mutateAsync({ hed_debts_confirmed: true });
    navigate(nextUrl);
    return res;
  };

  // @ts-expect-error TS(7031): Binding element 'formData' implicitly has an 'any'... Remove this comment to see the full error message
  const handleSave = async ({ formData, initialFormData }) => {
    const actionType = isLienDataChanged(formData, initialFormData)
      ? LIEN_ACTION_TYPE.EDITED
      : LIEN_ACTION_TYPE.CONFIRMED;
    browserTrack.houseCanaryUpdateLienInformation(buildSegmentData(formData.lien_type, actionType));

    const formattedRate = parseFloat(formData.rate) / 100;
    const formattedFormData = {
      ...formData,
      rate: numeral(formattedRate.toFixed(4)).value(),
      monthly_payment: numeral(formData.monthly_payment).value(),
      last_known_current_balance: numeral(formData.estimated_lien_balance).value(),
    };

    await addLienAsync.execute(initialScenario, formattedFormData, handleNext);
  };

  // @ts-expect-error TS(2339): Property 'liens' does not exist on type 'never'.
  const { record_date: _, ...mostRecentLienData } = currentLienForm?.liens[0] || {};
  const initialFormData = {
    ...mostRecentLienData,
    lien_type: currentLienForm?.relativeScenarioLienType,
    last_known_current_balance: mostRecentLienData.estimated_lien_balance,
    lien_kind: mostRecentLienData.lien_kind !== LIEN_FIELDS.LIEN_KIND_OTHER ? mostRecentLienData.lien_kind : '',
  };

  const isButtonLoading = addLienAsync.loading;
  const mappedLienSlides = lienForms.map((form, i) => {
    return {
      component: (
        <OnboardingFormWrapper
          key={`house-canary-lien-form-${i}`}
          form={form.form}
          title={form.title}
          tooltipContent={<TooltipContent propertyDataAsOf={liensAsOfDate} />}
          subtitle={form.subtitle}
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          header={`Finish setup for ${home.address.street}`}
          initialFormData={convertObjectToValidFormData(initialFormData)}
          image={isFirstLien ? equityIncreaseImage : houseImage}
          onSubmit={handleSave}
          buttons={[
            {
              text: 'Save details',
              type: 'submit',
              loading: isButtonLoading,
              // @ts-expect-error TS(7031): Binding element 'isFormValid' implicitly has an 'a... Remove this comment to see the full error message
              isDisabled: ({ isFormValid }) => {
                return !isFormValid;
              },
            },
            {
              text: form.skipButtonText,
              onClick: () => handleSkip(initialFormData),
              isDisabled: () => isButtonLoading,
              theme: 'link',
            },
          ]}
        />
      ),
    };
  });

  useProgressBar({ hide: !lienForms.length, steps: lienForms.length, currStep: currentLienFormIndex + 1 });

  return (
    // @ts-expect-error TS2741
    <ModalPageLayoutLite logoLink={getNewDashboardUrl()} onModalClose={handleClose}>
      <ProgressBar className="relative z-20 -mt-[1px]" />
      <div className="ml-0 xs:ml-2 sm:ml-5 md:ml-10">
        <ProgressiveFormSlides
          currentSlide={mappedLienSlides[currentLienFormIndex]?.component}
          // @ts-expect-error TS2322
          shouldShowPrompt={false}
        />
      </div>
    </ModalPageLayoutLite>
  );
};

export default HouseCanaryLiensController;
