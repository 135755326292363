import ModalPageHeader from './ModalPageHeader';
import useCurrentUser from 'hooks/useCurrentUser';

// @ts-expect-error TS(7031): Binding element 'onModalClose' implicitly has an '... Remove this comment to see the full error message
const ModalPageLayout = ({ onModalClose, headerContent, children }) => {
  // @ts-expect-error TS(2339): Property 'isClient' does not exist on type '{}'.
  const { isClient } = useCurrentUser();

  return (
    <>
      {isClient && <ModalPageHeader onClose={onModalClose}>{headerContent}</ModalPageHeader>}
      <main>{children}</main>
    </>
  );
};

export default ModalPageLayout;
