// @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
const numberFormatter = (n, options) => {
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(n);
};

export const NUMBER_FORMATS = {
  // @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
  currency: (n, precision = 2) => {
    return numberFormatter(n, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  },
  // @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
  integer: n => {
    return numberFormatter(n, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },
  // @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
  decimal: (n, precision = 2) => {
    return numberFormatter(n, {
      style: 'decimal',
      maximumFractionDigits: precision,
    });
  },
  // @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
  percent: (n, precision = 2) => {
    return numberFormatter(n / 100, {
      style: 'percent',
      maximumFractionDigits: precision,
    });
  },
};
