import { MuiSelect, MuiTextInput } from '@hometap/htco-components';
import useStates from 'hooks/useStates';

import useApplicationContext from '../../hooks/useApplicationContext';
import { APPLICATION_FORM_FIELD } from '../../constants/formFields';
import { phoneValidator } from 'utils/validators';

import './PropertyHOAForm.scss';

const PropertyHOAForm = () => {
  // @ts-expect-error TS(2339): Property 'registerApplicationFormField' does not e... Remove this comment to see the full error message
  const { registerApplicationFormField } = useApplicationContext();
  const { statesForDropdown } = useStates();

  const hoaQuestions = (
    <div className="HOAQuestions">
      <div className="HOAFieldContainer">
        <MuiTextInput
          required
          label="Contact Name"
          className="HOAContactName"
          maxLength={100}
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaContactName)}
        />
        <MuiTextInput
          required
          label="Contact Phone Number"
          className="HOAContactPhone"
          mask="000-000-0000"
          validator={phoneValidator}
          width="100%"
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaContactPhone)}
        />
      </div>

      <div className="HOAFieldContainer">
        <MuiTextInput
          required
          className="HOAStreetAddressInput"
          name="street"
          label="Street Address"
          maxLength={50}
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaStreet)}
        />
        <MuiTextInput
          className="HOAUnitAddressInput"
          name="unit"
          label="Unit (optional)"
          maxLength={50}
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaUnit)}
        />
      </div>
      <div className="HOAFieldContainer">
        <MuiTextInput
          required
          className="HOACityAddressInput"
          name="city"
          label="City"
          maxLength={50}
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaCity)}
        />
        <MuiSelect
          required
          className="HOAStateAddressInput"
          name="state"
          label="State"
          options={statesForDropdown}
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaState)}
        />
        <MuiTextInput
          required
          className="HOAZipAddressInput"
          name="zip_code"
          type="number"
          inputMode="number"
          label="Zip Code"
          maxLength={5}
          // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
          validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
          mask="00000"
          {...registerApplicationFormField(APPLICATION_FORM_FIELD.hoaZipCode)}
        />
      </div>
    </div>
  );

  return <div className="ApplicationInfoFormFields">{hoaQuestions}</div>;
};

export default PropertyHOAForm;
