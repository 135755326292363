import { apiWithAuth } from 'utils/api';

export const fetchInquiries = () => {
  return apiWithAuth.v1.get('/current-user/inquiries/');
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchInquiry = async id => {
  return await apiWithAuth.v1.get(`/current-user/inquiries/${id}/`);
};
