import PropTypes from 'prop-types';

// @ts-expect-error TS(7031): Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const TextHighlighter = ({ text, highlight, highlightClassName = 'text-green-medium' }) => {
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {/* @ts-expect-error TS(7006): Parameter 'part' implicitly has an 'any' type. */}
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={`text-highlight-${index}`} className={`font-bold ${highlightClassName}`}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};

export default TextHighlighter;

TextHighlighter.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  highlightClassName: PropTypes.string,
};
