import { useState, useEffect } from 'react';

import { Modal } from '@hometap/htco-components';
import env from '../../../utils/env';
import DelayedLoader from 'components/DelayedLoader/DelayedLoader';

// don't make tests wait around for a timer that should be instant
let UPPER_LIMIT_LOADING_TIME = 0;
let LOWER_LIMIT_LOADING_TIME = 0;

if (env.isProd()) {
  UPPER_LIMIT_LOADING_TIME = 1200;
  LOWER_LIMIT_LOADING_TIME = 400;
}

// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const ProgressLoadingModal = ({ show = false, title, footer, spinners, data, onFinish }) => {
  const [hasDataLoaded, setHasDataLoaded] = useState(false);
  const [activeLoader, setActiveLoader] = useState(0);

  useEffect(() => {
    if (data) {
      setHasDataLoaded(true);
    }
  }, [data, setHasDataLoaded]);

  const onClose = () => {
    setTimeout(() => {
      if (onFinish) onFinish();
    }, 0);
  };

  const onDone = () => {
    setTimeout(() => {
      if (onFinish) onFinish();
    }, LOWER_LIMIT_LOADING_TIME);
  };

  return (
    <div>
      <Modal
        allowClose={false}
        open={show}
        width={430}
        className="FlashLoadingModal"
        onClose={onClose}
        data-testid="loading-modal"
      >
        <div>
          <h3>{title}</h3>
          <div className="LoadingSteps">
            {// @ts-expect-error TS(7006): Parameter 'spinner' implicitly has an 'any' type.
            spinners?.map((spinner, index) => {
              const { label, async } = spinner;
              return (
                <DelayedLoader
                  key={`loader_${index}`}
                  index={index}
                  label={label}
                  isNext={activeLoader === index}
                  minLoadTime={index === 0 ? UPPER_LIMIT_LOADING_TIME : LOWER_LIMIT_LOADING_TIME}
                  onStatusChange={index => setActiveLoader(index + 1)}
                  isDone={async ? hasDataLoaded : true}
                  onDone={async ? onDone : null}
                />
              );
            })}
          </div>
          <hr />
          <p>{footer}</p>
        </div>
      </Modal>
    </div>
  );
};

export default ProgressLoadingModal;
