import ApplicationFormWrapper from './ApplicationFormWrapper/ApplicationFormWrapper';
import useApplicationContext from '../hooks/useApplicationContext';
import { buildDataForApplicationUpdate, onSaveError } from '../utils';
import useApplicationNavContext from '../hooks/useApplicationNavContext';
import { homeownerApplicationCompletedBrowserTrack } from 'utils/segment';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ApplicationFormSectionWrapper = ({ children, isSavePage }) => {
  const {
    // @ts-expect-error TS(2339): Property 'track' does not exist on type 'unknown'.
    track,
    // @ts-expect-error TS(2339): Property 'applicants' does not exist on type 'unkn... Remove this comment to see the full error message
    applicants,
    // @ts-expect-error TS(2339): Property 'primaryApplicant' does not exist on type... Remove this comment to see the full error message
    primaryApplicant,
    // @ts-expect-error TS(2339): Property 'isCurrentFormValid' does not exist on ty... Remove this comment to see the full error message
    isCurrentFormValid,
    // @ts-expect-error TS(2339): Property 'applicationFormData' does not exist on t... Remove this comment to see the full error message
    applicationFormData,
    // @ts-expect-error TS(2339): Property 'updateApplication' does not exist on typ... Remove this comment to see the full error message
    updateApplication,
    // @ts-expect-error TS(2339): Property 'consentApplication' does not exist on ty... Remove this comment to see the full error message
    consentApplication,
    // @ts-expect-error TS(2339): Property 'isLoading' does not exist on type 'unkno... Remove this comment to see the full error message
    isLoading,
  } = useApplicationContext();

  // @ts-expect-error TS(2339): Property 'isLastPageOfApplication' does not exist ... Remove this comment to see the full error message
  const { isLastPageOfApplication, navigateToNextPage } = useApplicationNavContext();

  const handleProgressButtonClick = async () => {
    if (isLastPageOfApplication) {
      return await consentApplication()
        .then(() => {
          const { enabled_rollout_flags: flags, friendly_id: friendlyId } = track;
          const { invited_email } = primaryApplicant;
          const numberOfCoApplicants = applicants.length - 1;
          homeownerApplicationCompletedBrowserTrack({
            flags,
            // @ts-expect-error TS(2345): Argument of type '{ flags: any; invited_email: any... Remove this comment to see the full error message
            invited_email,
            friendlyId,
            numberOfCoApplicants,
          });
          navigateToNextPage();
        })
        .catch(onSaveError());
    } else if (isSavePage) {
      return await updateApplication(buildDataForApplicationUpdate(applicationFormData))
        .then(() => navigateToNextPage())
        // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
        .catch(e => {
          onSaveError(e.response?.data?.non_field_errors)(e);
        });
    }
  };

  return (
    // @ts-expect-error TS(2741): Property 'buttonReplace' is missing in type '{ chi... Remove this comment to see the full error message
    <ApplicationFormWrapper
      onProgressButtonClick={isSavePage && handleProgressButtonClick}
      isSavePage={isSavePage}
      isLoading={isLoading}
      buttonDisabled={!isCurrentFormValid}
      buttonText={isLastPageOfApplication && 'Submit Application'}
    >
      {children}
    </ApplicationFormWrapper>
  );
};

export default ApplicationFormSectionWrapper;
