import { Icon, IconButton } from '@hometap/htco-components';
import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';

import './DebtListItem.scss';

// @ts-expect-error TS(7031): Binding element 'debtInfo' implicitly has an 'any'... Remove this comment to see the full error message
const DebtListItem = ({ debtInfo, onDebtDelete, index }) => {
  const { balance, interestRate, listTitle, icon } = debtInfo;
  return (
    <div className="DebtListItemContainer" data-testid="debt-item">
      <div className="DebtListItemIconContainer">
        <Icon name={icon} size="lg" color="#366ced" />
      </div>
      <div className="DebtListItemContentContainer">
        <div>
          <div className="DebtListItemTitle" data-testid="debt-item-title">
            {listTitle}
          </div>
          <div className="DebtListItemSmallText" data-testid="debt-item-info">
            Balance: {NUMBER_FORMATS.currency(balance)} · Rate: {NUMBER_FORMATS.percent(interestRate)}
          </div>
        </div>
        <div>
          <IconButton icon="cancel" size="lg" onClick={() => onDebtDelete(index)} data-testid="remove-debt-button" />
        </div>
      </div>
    </div>
  );
};

export default DebtListItem;
