import { useMemo } from 'react';
import useHouseCanaryLiensContext from './useHouseCanaryLiens';
import { useHomeValuation } from './useHomeValuation';
import useHedInvestmentContext from './useHedInvestmentContext';
import { getHouseCanaryLienForms } from '../components/dashboard-house-canary-liens/utils';
import { useEquityScenarioContext } from './useEquityScenarioContext';

const useHouseCanaryLiensAvailable = () => {
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist on type '{}'.
  const { displayedHomeValuation, isLoading: isHomeValuationLoading } = useHomeValuation();
  const homeValueExists = !!displayedHomeValuation?.value;
  // @ts-expect-error TS(2339): Property 'investment' does not exist on type '{}'.
  const { investment, loading: isInvestmentLoading } = useHedInvestmentContext();
  // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
  const { liens, loading: isHCLiensLoading } = useHouseCanaryLiensContext();
  const { initialScenario } = useEquityScenarioContext();

  // a user may have already added a lien that hc returns, so we need to check the length of
  // the forms to determine if we should still redirect to the hc flow
  const lienForms = useMemo(() => {
    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
    return getHouseCanaryLienForms(liens, initialScenario?.liens);
    // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
  }, [liens, initialScenario?.liens]);

  return {
    available: homeValueExists && lienForms?.length && !investment,
    loading: isHomeValuationLoading || isInvestmentLoading || isHCLiensLoading,
  };
};

export default useHouseCanaryLiensAvailable;
