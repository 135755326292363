import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';
import { buildAmortizationTable, calculatePayoffDate } from './debtCalculations';

// @ts-expect-error TS(7031): Binding element 'debts' implicitly has an 'any' ty... Remove this comment to see the full error message
const formatCSV = ({ debts, principalBalance, totalInterestPayments, totalMonthlyPayment, payOffDate }) => {
  const curDate = new Date().toDateString();
  // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
  const csvArray = debts.reduce((acc, cur) => {
    const { totalInterest, paymentsToZero } = buildAmortizationTable(
      cur.balance,
      cur.interestRate / 100,
      cur.monthlyPayment,
    );
    const payOffDate = calculatePayoffDate(paymentsToZero);
    acc.push([
      cur.xAxisLabel,
      NUMBER_FORMATS.currency(cur.balance, 2),
      NUMBER_FORMATS.percent(cur.interestRate),
      NUMBER_FORMATS.currency(cur.monthlyPayment, 2),
      NUMBER_FORMATS.currency(totalInterest, 2),
      NUMBER_FORMATS.currency(cur.balance + totalInterest, 2),
      payOffDate,
    ]);
    return acc;
  }, []);

  return [
    ['Current Date', curDate],
    ['Debt Free By', payOffDate],
    ['Total Monthly Payments', NUMBER_FORMATS.currency(totalMonthlyPayment, 2)],
    ['Total Interest', NUMBER_FORMATS.currency(totalInterestPayments, 2)],
    ['Total Principal Balance', NUMBER_FORMATS.currency(principalBalance, 2)],
    [''],
    ['Type of Debt', 'Principal Balance', 'Rate', 'Monthly Payment', 'Interest', 'Total', 'Pay-off Time'],
    ...csvArray,
    [''],
    [''],
    [''],
    [''],
    [
      'Disclaimer: The estimate above is based solely on information you provided and is to be used for illustrative purposes only. This estimate is not to be relied upon; consult a qualified financial advisor about your exact circumstances.',
    ],
  ];
};

export default formatCSV;
