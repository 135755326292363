import { Container, Helptip } from '@hometap/htco-components';
import { formatDate } from 'utils/date';
import { currency } from 'utils/numbers';
import '../SettlementCalculator.scss';

// @ts-expect-error TS(7031): Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
const Cell = ({ label, value, helpText }) => (
  <Container grid="6" className="InvestmentInfoGridContainer">
    <div className="InvestmentInfoLabelWrapper">
      <div className="InvestmentInfoLabel">{label}:</div>
      <div className="InvestmentInfoValue">{value}</div>
      {helpText && <Helptip text={helpText} />}
    </div>
  </Container>
);

const InvestmentInfo = ({
  // @ts-expect-error TS(7031): Binding element 'hometapShare' implicitly has an '... Remove this comment to see the full error message
  hometapShare,
  // @ts-expect-error TS(7031): Binding element 'effectiveDate' implicitly has an ... Remove this comment to see the full error message
  effectiveDate,
  // @ts-expect-error TS(7031): Binding element 'expirationDate' implicitly has an... Remove this comment to see the full error message
  expirationDate,
  // @ts-expect-error TS(7031): Binding element 'beginningHomeValue' implicitly ha... Remove this comment to see the full error message
  beginningHomeValue,
  // @ts-expect-error TS(7031): Binding element 'investmentAmount' implicitly has ... Remove this comment to see the full error message
  investmentAmount,
  // @ts-expect-error TS(7031): Binding element 'settlementDate' implicitly has an... Remove this comment to see the full error message
  settlementDate,
  // @ts-expect-error TS(7031): Binding element 'appreciatedHomeValue' implicitly ... Remove this comment to see the full error message
  appreciatedHomeValue,
}) => {
  const effectiveDateFormatted = formatDate(effectiveDate, 'MMMM DD, YYYY');
  const expirationDateFormatted = formatDate(expirationDate, 'MMMM DD, YYYY');

  return (
    <div className="InvestmentInfo">
      <Container row className="InvestmentInfoGridContainerRow">
        <Cell
          label="Effective date"
          value={effectiveDateFormatted}
          helpText="The start of your Investment period, which is the fourth business day after you signed the Investment Documents."
        />
        {/* @ts-expect-error TS(2741): Property 'helpText' is missing in type '{ label: s... Remove this comment to see the full error message */}
        <Cell label="Investment amount" value={currency(investmentAmount)} />
      </Container>

      <Container row className="InvestmentInfoGridContainerRow">
        <Cell
          label="Expiration date"
          value={expirationDateFormatted}
          helpText="The 10th anniversary of the effective date."
        />
        {/* @ts-expect-error TS(2741): Property 'helpText' is missing in type '{ label: s... Remove this comment to see the full error message */}
        <Cell label="Beginning home value" value={currency(beginningHomeValue)} />
      </Container>

      {hometapShare && (
        <Container row className="InvestmentInfoGridContainerRow">
          {/* @ts-expect-error TS(2741): Property 'helpText' is missing in type '{ label: s... Remove this comment to see the full error message */}
          <Cell label="Settlement date" value={formatDate(settlementDate, 'MMMM DD, YYYY')} />
          {/* @ts-expect-error TS(2741): Property 'helpText' is missing in type '{ label: s... Remove this comment to see the full error message */}
          <Cell label="Anticipated home value" value={currency(appreciatedHomeValue)} />
        </Container>
      )}
    </div>
  );
};

export default InvestmentInfo;
