import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD, getNewDashboardUrl, getNewHEDUrl } from 'utils/links';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { useCurrentHome } from 'hooks/useCurrentHome';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';

const useHEDRedirect = () => {
  const navigate = useNavigate();
  const { showOverviewPage } = useFlags();
  // @ts-expect-error TS(2339): Property 'tracks' does not exist on type '{}'.
  const { tracks, loading: loadingTracks } = useUserTracksContext();
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ home:... Remove this comment to see the full error message
  const { home, loading: loadingHome, userCanAccessHome, hasHEDAccessToCurrentHome } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist on type '{}'.
  const { isLoading: isHomeValuationLoading, displayedHomeValuation } = useHomeValuation();

  const location = useLocation();
  const isHomeEquityPathMatch = useMatch(`${getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY)}/*`);
  const isBuildProfileMatch = useMatch(getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`));
  const isMaintenancePathMatch = useMatch(getNewDashboardUrl(HOMEOWNER_DASHBOARD.MAINTENANCE));
  const isInvestmentsPathMatch = useMatch(getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS));

  const isHomeEquityOrMaintenance = Boolean(isHomeEquityPathMatch) || Boolean(isMaintenancePathMatch);

  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const currentHomeTracks = tracks?.filter(track => {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'never'.
    return track?.home.address.business_key === home?.address.business_key;
  });
  const currentHomeTracksLength = currentHomeTracks?.length || 0;

  useEffect(() => {
    if (loadingTracks || loadingHome || isHomeValuationLoading) return;

    if (!displayedHomeValuation.value && isBuildProfileMatch) {
      navigate(getNewDashboardUrl(HOMEOWNER_DASHBOARD.MISSING_HOME_VALUATION), {
        replace: true,
        state: location.state,
      });
    }

    const isNotPrimaryUser = userCanAccessHome === false;
    if (isHomeEquityOrMaintenance && isNotPrimaryUser) {
      navigate(getNewDashboardUrl(showOverviewPage ? HOMEOWNER_DASHBOARD.OVERVIEW : HOMEOWNER_DASHBOARD.INVESTMENTS), {
        replace: true,
      });
      return;
    }

    if (
      Boolean(isInvestmentsPathMatch) &&
      tracks &&
      !loadingTracks &&
      !currentHomeTracksLength &&
      hasHEDAccessToCurrentHome
    ) {
      navigate(getNewDashboardUrl(showOverviewPage ? HOMEOWNER_DASHBOARD.OVERVIEW : HOMEOWNER_DASHBOARD.HOME_EQUITY), {
        replace: true,
      });
    }
  }, [
    navigate,
    loadingTracks,
    loadingHome,
    userCanAccessHome,
    isHomeEquityOrMaintenance,
    isBuildProfileMatch,
    isInvestmentsPathMatch,
    tracks,
    currentHomeTracksLength,
    home,
    hasHEDAccessToCurrentHome,
    showOverviewPage,
    isHomeValuationLoading,
    displayedHomeValuation,
    location.state,
  ]);
};

export default useHEDRedirect;
