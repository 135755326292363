import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { fetchPrequalInvestmentRange } from 'apps/dashboard/data/pricing';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import { useHomeValuation } from './useHomeValuation';
import { useQuery } from '@tanstack/react-query';
import { useCurrentHome } from 'hooks/useCurrentHome';

const useHedPrequalRange = () => {
  const { home } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist o... Remove this comment to see the full error message
  const { displayedHomeValuation } = useHomeValuation();
  const { currentScenario } = useEquityScenarioContext();

  const { data, isLoading, error } = useQuery({
    queryFn: async () => {
      const body = {
        home_value: displayedHomeValuation.value,
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        state: home.operationalState.abbreviation,
        effective_period: {
          length: 10,
          unit: 'years',
        },
      };
      const filteredLiens =
        // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
        currentScenario?.liens?.filter(lien => lien.lien_type !== EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT) ||
        [];

      if (filteredLiens.length) {
        // @ts-expect-error TS(2339): Property 'loan_balance' does not exist on type '{ ... Remove this comment to see the full error message
        body.loan_balance = filteredLiens.reduce((sum, lien) => {
          const balance = lien.estimated_lien_balance || lien.current_balance;
          sum = Number(sum) + Number(balance);
          return sum;
        }, 0);
      } else {
        // @ts-expect-error TS(2339): Property 'loan_balance' does not exist on type '{ ... Remove this comment to see the full error message
        body.loan_balance = 0;
      }
      return await fetchPrequalInvestmentRange(body);
    },
    queryKey: [
      'hed-prequal-range',
      {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        scenarioId: currentScenario?.id,
        homeValue: displayedHomeValuation?.value,
        // @ts-expect-error TS(2339): Property 'operationalState' does not exist on type... Remove this comment to see the full error message
        operationalState: home?.operationalState?.abbreviation,
      },
    ],
    // @ts-expect-error TS(2339): Property 'operationalState' does not exist on type... Remove this comment to see the full error message
    enabled: !!home && !!home.operationalState && !!displayedHomeValuation?.value && !!currentScenario,
  });

  return { prequalRange: data, preQualLoading: isLoading, preQualError: error };
};

export default useHedPrequalRange;
