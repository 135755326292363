import { Container, Loader } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';

import TrackDetailsStageSection from 'apps/track-details/components/TrackDetailsStageSection/TrackDetailsStageSection';
import { PLACEHOLDER_TODO } from 'apps/track-details/utils/constants';
import { STAGES } from 'apps/dashboard/constants';
import {
  getMappedOfferRundown,
  mapAsyncTodoToConfig,
  getMappedEstimateRundowns,
} from 'apps/track-details/utils/config/todoMappings';
import useTrackTodos from 'hooks/useTrackTodos';

import './CoApplicantTodos.scss';

const config = {
  title: 'Complete your remaining to-dos.',
  getSubtitle() {
    return 'The sooner these items are completed, the faster we can work toward funding your Investment.';
  },

  // @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
  getTodos({ track, rundowns, offer, asyncTodos }) {
    const configuredOffer = getMappedOfferRundown({
      track,
      offer,
      placeholderOverrides: {
        subtitle:
          'Please take a look at your Investment Offer and reach out to your Investment Manager with any questions.',
      },
    });

    const configuredAsyncTodos = asyncTodos?.length
      ? // @ts-expect-error TS(7006): Parameter 'todo' implicitly has an 'any' type.
        asyncTodos.map(todo => mapAsyncTodoToConfig(todo, track))
      : [PLACEHOLDER_TODO.DOC_UPLOAD];

    return [...configuredAsyncTodos, ...getMappedEstimateRundowns({ rundowns, track }), configuredOffer];
  },

  [STAGES.OFFER_DECLINED]: {
    title: 'Offer declined.',
    getSubtitle() {
      return 'We hope we can work together to help you reach your goals in the future! If anything changes, please reach out to your Investment Manager.';
    },
    getTodos() {
      return [];
    },
  },
};

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const CoApplicantTodos = ({ track, rundowns, offer }) => {
  const { trackId } = useParams();
  const { todos: asyncTodos = [], fetchTodos, ...todosAsyncState } = useTrackTodos({ trackId, immediate: true });
  const sectionContext = { track, rundowns, offer, asyncTodos };

  const configurationForTrackStage = config[track?.current_stage] || config;

  // @ts-expect-error TS(2339): Property 'getTodos' does not exist on type 'string... Remove this comment to see the full error message
  const todos = configurationForTrackStage.getTodos(sectionContext);

  if (todosAsyncState.isLoading) {
    return (
      <Container className="CoApplicant" align="center" space={5}>
        <Loader overlay />
      </Container>
    );
  }

  return (
    <div className="CoApplicantTodosStageSectionContainer">
      <TrackDetailsStageSection
        isActive
        sectionConfigData={configurationForTrackStage}
        todos={todos}
        fetchAsyncTodos={fetchTodos}
        sectionContext={sectionContext}
      />
    </div>
  );
};

export default CoApplicantTodos;
