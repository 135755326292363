/**
 * Demo data to prefill fields in the homeowner form
 *
 * @returns {{when_interested: string, primary_use_case: string, last_name: string,
 *            phone_number: string, consent_given: boolean, first_name: string, email: string}}
 */
export const demoInquiryHomeownerData = () => {
  return {
    first_name: 'Bob',
    last_name: 'Smith',
    ssn: '123-45-6789',
    email: `test+${Math.random().toString(36).substring(5)}@hometap.com`,
    phone_number: '617-399-0604',
    primary_use_case: 'use_case_renovate',
    when_interested: '3_to_6_months',
    consent_given: true,
  };
};

/**
 * Demo data to prefill ssn field on risk band returning no data
 *
 * @returns {{ssn: string}}
 */
export const demoInquirySSNData = () => {
  return {
    ssn: '123-45-6789',
  };
};

/**
 * Demo data to populate fields in the property form
 *
 * @returns {{unit: string, city: string, street: string, residence_type: string,
 *            property_type: string, state: string, zip_code: string}}
 */
export const demoInquiryPropertyData = () => {
  return {
    zip_code: '02199',
    city: 'Boston',
    street: '790 Boylston Street',
    state: 'MA',
    unit: '20i',
    residence_type: 'pr',
    property_type: 'sf',
    mortgage_balance: 140000,
  };
};

/**
 * Demo data to populate fields in the property form on progressive inquiry
 *
 * @returns {{unit: string, address: string}}
 */
// @ts-expect-error TS(7006): Parameter 'overrides' implicitly has an 'any' type... Remove this comment to see the full error message
export const demoProgressiveInquiryPropertyData = overrides => {
  return {
    address: '790 Boylston St, Boston, MA 02199, USA',
    street: '790 Boylston St',
    city: 'Boston',
    state: 'MA',
    zip_code: '02199',
    unit: '20i',
    residence_type: 'pr',
    property_type: 'sf',
    mortgage_balance: 140000,
    primary_use_case: 'use_case_debts',
    when_interested: 'asap',
    first_name: 'Bob',
    last_name: 'Smith',
    ssn: '987-65-4321',
    email: `test+${Math.random().toString(36).substring(5)}@hometap.com`,
    phone_number: '617-399-0604',
    how_heard: 'facebook',
    consent_given: true,
    ...overrides,
  };
};
