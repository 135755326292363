import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import { Button, Container, MuiTextInput, SlideDown, useBoolState } from '@hometap/htco-components';
import useGooglePlaceInput from 'hooks/useGooglePlaceInput';
import houseImg from 'images/inquiry/house2.png';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import InquiryAddressModal from './InquiryAddressModal';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import { DQ_PAGES } from '../constants/progressiveInquiryConstants';
import { PREQUAL_OUTCOME } from '../constants/progressiveInquiryConstants';
import { PREQUAL_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';
import { parsePrequalificationsToMap } from 'apps/progressive-inquiry/utils/parsePrequalificationOutcomes';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';
import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import useReinquiryDataHandleForm from 'apps/progressive-inquiry/hooks/useReinquiryDataHandleForm';
import useCurrentUser from 'hooks/useCurrentUser';
import { useAsyncCreateInquiry } from '../hooks/inquiryMutations';
import './InquiryProperty.scss';

const InquiryProperty = ({
  // @ts-expect-error TS(7031): Binding element 'form' implicitly has an 'any' typ... Remove this comment to see the full error message
  form,
  // @ts-expect-error TS(7031): Binding element 'trackingData' implicitly has an '... Remove this comment to see the full error message
  trackingData,
  // @ts-expect-error TS(7031): Binding element 'step' implicitly has an 'any' typ... Remove this comment to see the full error message
  step,
  // @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
  onNext,
  // @ts-expect-error TS(7031): Binding element 'onDequalify' implicitly has an 'a... Remove this comment to see the full error message
  onDequalify,
  // @ts-expect-error TS(7031): Binding element 'manuallyEnteredValidAddress' impl... Remove this comment to see the full error message
  manuallyEnteredValidAddress,
  // @ts-expect-error TS(7031): Binding element 'autoFillValidAddress' implicitly ... Remove this comment to see the full error message
  autoFillValidAddress,
  // @ts-expect-error TS(7031): Binding element 'onValidAddressChange' implicitly ... Remove this comment to see the full error message
  onValidAddressChange,
  // @ts-expect-error TS(7031): Binding element 'handleAutoFillValidAddress' impli... Remove this comment to see the full error message
  handleAutoFillValidAddress,
}) => {
  const { registerField, handleSubmit, updateFormData, formData } = form;

  // @ts-expect-error TS(7006): Parameter 'address' implicitly has an 'any' type.
  const onAddress = (address, addressObj) => {
    updateFormData({ address, ...addressObj });
  };
  // @ts-expect-error object literal may only specify known properties, and 'onAddress' does not exist in type '{ flags?: never[] | undefined; }'.ts(2353)
  const { ref, isValidGoogleAddress } = useGooglePlaceInput({ onAddress });
  const manualOn = useBoolState(false);
  const [focus, setFocus] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const { reinquiryData } = useInitialInquiryData(user);

  useReinquiryDataHandleForm(reinquiryData, handleAutoFillValidAddress);
  const { results, loading, execute: postInquiry } = useAsyncCreateInquiry();

  const onSubmit = useCallback(() => {
    const { city, state, street, zip_code, unit } = formData;
    const data = {
      address: { street, city, state, zip_code, unit },
    };

    const requiredFields = [FIELDS.STREET, FIELDS.CITY, FIELDS.STATE, FIELDS.ZIP_CODE];
    const emptyFields = Object.keys(data.address).find(key => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return requiredFields.includes(key) && data.address[key]?.length === 0;
    });

    if (emptyFields) {
      setInvalidAddress(true);
    } else {
      const createApi = handleSubmit(() => {
        postInquiry({ ...data, ...trackingData }, [PREQUAL_TYPES.ZIP, PREQUAL_TYPES.STATE]);
      });
      createApi();
    }
  }, [handleSubmit, postInquiry, trackingData, formData]);

  useEffect(() => {
    if (manuallyEnteredValidAddress && formData.address && !results) {
      onSubmit();
    }
    if (results) {
      const { prequalifications, id } = results;
      const prequalMap = parsePrequalificationsToMap(prequalifications);
      // @ts-expect-error TS(2339): Property 'StatePrequalification' does not exist on... Remove this comment to see the full error message
      const { StatePrequalification, ZipPrequalification } = prequalMap?.prequalifications || {};
      // @ts-expect-error TS(7006): Parameter 'outcome_code' implicitly has an 'any' t... Remove this comment to see the full error message
      const checkFailedPrequal = outcome_code => outcome_code === PREQUAL_OUTCOME.FAIL;
      if (
        checkFailedPrequal(StatePrequalification.outcome_code) ||
        checkFailedPrequal(ZipPrequalification.outcome_code)
      ) {
        onDequalify(DQ_PAGES.LOCATION);
      } else {
        onNext(step + 1, id);
      }
    }
  }, [results, onNext, step, onDequalify, onSubmit, manuallyEnteredValidAddress, formData.address]);

  const isAddressValid = isValidGoogleAddress || autoFillValidAddress;
  const canContinueToTheNextPage = formData.address && isAddressValid && !invalidAddress;
  if (canContinueToTheNextPage) scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
  const ctaText = 'Continue';
  useCTAChangedNotification(ctaText, !!canContinueToTheNextPage);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // @ts-expect-error TS(7006): Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = event => {
    if (event.key === 'Escape' || event.key === 'Tab') {
      setFocus(false);
    }
  };

  const PropertyUnitLabel = () => (
    <>
      Unit <span className={'PropertyUnitLabel'}>(optional)</span>
    </>
  );

  return (
    <div className="Property" data-testid="inquiry_property_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.PROPERTY]} />
      <ProgressiveUITemplate>
        {/* @ts-expect-error TS(2739): Type '{ children: any[]; }' is missing the followi... Remove this comment to see the full error message */}
        <ContentPanel>
          <h1>Is your property eligible for a Hometap Investment?</h1>
          <p className="PropertyBodyText InquiryBodyText">
            You can prequalify for a Hometap investment in{' '}
            <span className="InquiryEmphasisBold">less than two minutes.</span> First, we need your address.
          </p>
          <Container className="InquiryPropertyAddressRow" row>
            <Container className="InquiryInputPropertyAddress">
              <MuiTextInput
                data-testid="property-address-input"
                required
                label="Property Address"
                ref={ref}
                name="address"
                value={formData.address}
                width="100%"
                // @ts-expect-error TS(7006): Parameter 'address' implicitly has an 'any' type.
                onChange={address => {
                  setInvalidAddress(false);
                  onValidAddressChange(false);
                  updateFormData({ address });
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
            </Container>
            <Container className="InquiryInputPropertyUnit">
              <MuiTextInput maxLength={50} width="100%" label={<PropertyUnitLabel />} {...registerField(FIELDS.UNIT)} />
            </Container>
          </Container>
          <div className="InquiryError">
            <SlideDown visible={invalidAddress}>
              {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
              <ErrorMessage>
                <span>Please enter a valid address.</span>
              </ErrorMessage>
            </SlideDown>
          </div>
          {!focus && formData.address && !isAddressValid && (
            <div className="PropertyManualAddress">
              <p className="PropertyNotFound">Address not found, please try again.</p>
              <button type="button" onClick={manualOn.setTrue}>
                I’ll enter my address manually
              </button>
              <InquiryAddressModal
                open={manualOn.value}
                handleClose={manualOn.setFalse}
                form={form}
                onNext={onSubmit}
              />
            </div>
          )}
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} disabled={!canContinueToTheNextPage} onClick={onSubmit} loading={loading}>
              {ctaText}
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel imageSource={houseImg} imgAlt="car parked in driveway" includeTrustPilot={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryProperty;
