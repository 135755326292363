import React, { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

import { ModalConfirm } from '@hometap/htco-components';
import './RouterPrompt.scss';

type RouterPromptProps = {
  when: boolean;
  onOK?: () => void;
  onCancel?: () => void;
  bodyText?: string;
};
export const RouterPrompt: React.FC<RouterPromptProps> = ({ when, onOK, onCancel, bodyText }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'unblocked' || !when) return;

    setShowPrompt(true);
  }, [blocker, blocker.state, when]);

  const handleNavigate = async () => {
    setShowPrompt(false);

    if (onOK) {
      await onOK();
    }
    if (blocker.proceed && blocker.reset) {
      blocker.proceed();
      blocker.reset();
    }
  };

  const handleStay = async () => {
    setShowPrompt(false);

    if (onCancel) {
      await onCancel();
    }

    if (!blocker.reset) return;
    blocker.reset();
  };

  return showPrompt ? (
    <ModalConfirm
      className="UnsavedChangesModal"
      open={true}
      width="580px"
      header="Are you sure you want to leave?"
      confirmText="No, continue to edit"
      cancelText="Yes, I want to leave the page"
      onCancel={handleNavigate}
      onClose={handleStay}
      onConfirm={handleStay}
    >
      {bodyText ?? 'You have unsaved changes, if you leave your updates will be lost.'}
    </ModalConfirm>
  ) : null;
};

export default RouterPrompt;
