import { IconButton } from '@hometap/htco-components';
import { getPrimaryAppTrackDetailsUrl } from 'utils/links';
import useTrack from 'hooks/useTrack';
import './ModalPageHeader.scss';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ModalPageHeader = ({ children, onClose }) => {
  const { track } = useTrack();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (track) {
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
      window.location = getPrimaryAppTrackDetailsUrl(track.id);
    } else {
      window.history.back();
    }
  };

  return (
    <div className="ModalPageHeader">
      {children}
      <IconButton className="ModalPageHeaderCloseButton" icon="icon-cancel" theme="primary" onClick={handleClose} />
    </div>
  );
};

export default ModalPageHeader;
