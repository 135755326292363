import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon, Paper } from '@hometap/htco-components';

import DataList from 'components/DataList/DataList';
import { currency, percent } from 'utils/numbers';

import './RundownScenarioCard.scss';

// @ts-expect-error TS(7031): Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const getAppreciationSubtitle = ({ value, annualized: isAnnualized }) => {
  return `${value > 0 ? '+' : ''}
  ${percent(value, '0')} 
  ${isAnnualized ? ' annual ' : ' total '}
  ${value < 0 ? 'depreciation' : ' appreciation'}`;
};

// @ts-expect-error TS(7031): Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const getRundownIconName = ({ value }) => {
  if (value > 0) {
    return 'icon-arrow-increase';
  } else if (value < 0) {
    return 'icon-arrow-decrease';
  }

  return 'icon-arrow-no-change';
};

// @ts-expect-error TS(7031): Binding element 'percentage' implicitly has an 'an... Remove this comment to see the full error message
const HometapShareWithLabel = ({ percentage, dollars, isShareCapped }) => {
  return (
    <>
      {currency(dollars)}
      {isShareCapped ? (
        <div className="RundownScenarioCardCapLabel">CAPPED</div>
      ) : (
        <div className="RundownScenarioCardPercentageLabel">{percent(percentage)}</div>
      )}
    </>
  );
};

const RundownScenarioCard = ({
  // @ts-expect-error TS(7031): Binding element 'appreciationPercent' implicitly h... Remove this comment to see the full error message
  appreciationPercent,
  // @ts-expect-error TS(7031): Binding element 'description' implicitly has an 'a... Remove this comment to see the full error message
  description,
  // @ts-expect-error TS(7031): Binding element 'homeownerShare' implicitly has an... Remove this comment to see the full error message
  homeownerShare,
  // @ts-expect-error TS(7031): Binding element 'hometapShare' implicitly has an '... Remove this comment to see the full error message
  hometapShare,
  // @ts-expect-error TS(7031): Binding element 'investmentAmount' implicitly has ... Remove this comment to see the full error message
  investmentAmount,
  // @ts-expect-error TS(7031): Binding element 'effectivePeriod' implicitly has a... Remove this comment to see the full error message
  effectivePeriod,
  // @ts-expect-error TS(7031): Binding element 'isShareCapped' implicitly has an ... Remove this comment to see the full error message
  isShareCapped,
  // @ts-expect-error TS(7031): Binding element 'mortgageBalance' implicitly has a... Remove this comment to see the full error message
  mortgageBalance,
  // @ts-expect-error TS(7031): Binding element 'projectedHomeValue' implicitly ha... Remove this comment to see the full error message
  projectedHomeValue,
}) => {
  const data = [
    {
      label: 'Projected Home Value',
      value: currency(projectedHomeValue),
      rowClass: cx('RundownScenarioCardProjectedHomeValue', 'RundownScenarioCardDataRow'),
    },
    { label: 'Investment', value: currency(investmentAmount), rowClass: 'RundownScenarioCardDataRow' },
    {
      label: 'Length of Investment',
      value: `${effectivePeriod.length} ${effectivePeriod.unit}`,
      rowClass: 'RundownScenarioCardDataRow',
    },
    mortgageBalance && {
      label: 'Mortgage Balance',
      value: currency(mortgageBalance),
      rowClass: 'RundownScenarioCardDataRow',
    },
    { label: 'Your Share', value: currency(homeownerShare.dollars), rowClass: 'RundownScenarioCardDataRow' },
    {
      label: 'Hometap Share',
      value: (
        <HometapShareWithLabel
          percentage={hometapShare.percent}
          dollars={hometapShare.dollars}
          isShareCapped={isShareCapped}
        />
      ),
      rowClass: cx('RundownScenarioCardDataRow', 'RundownScenarioCardHometapShare'),
      helpTip: {
        text: 'The percentage of the final market value of the home that Hometap receives when you sell the property or settle the investment.',
      },
    },
  ].filter(Boolean);

  return (
    <Paper className="RundownScenarioCard">
      <div className="RundownScenarioCardHeader">
        <Icon size="3x" name={getRundownIconName(appreciationPercent)} />
        <div className="RundownScenarioCardHeaderText">
          <div className="RundownScenarioCardTitle">{description}</div>
          <div className="RundownScenarioCardSubtitle">{getAppreciationSubtitle(appreciationPercent)}</div>
        </div>
      </div>
      {/* @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'. */}
      <DataList className="RundownDataList" data={data} />
    </Paper>
  );
};

export default RundownScenarioCard;

RundownScenarioCard.propTypes = {
  appreciationPercent: PropTypes.shape({
    value: PropTypes.number,
    annualized: PropTypes.bool,
  }).isRequired,
  description: PropTypes.string.isRequired,
  hometapShare: PropTypes.shape({
    percent: PropTypes.number,
    dollars: PropTypes.number,
  }).isRequired,
  homeownerShare: PropTypes.shape({
    percent: PropTypes.number,
    dollars: PropTypes.number,
  }).isRequired,
  investmentAmount: PropTypes.number.isRequired,
  effectivePeriod: PropTypes.shape({
    unit: PropTypes.string,
    length: PropTypes.number,
  }).isRequired,
  isShareCapped: PropTypes.bool,
  mortgageBalance: PropTypes.number,
  projectedHomeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
