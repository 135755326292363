import { Button, Icon } from '@hometap/htco-components';
import { formatPhoneNumberSeparator } from 'utils/stringFormatters';
import cx from 'classnames';

// @ts-expect-error TS(7031): Binding element 'friendly_id' implicitly has an 'a... Remove this comment to see the full error message
const ContactInfo = ({ friendly_id, contact_information, card_description, card_title }) => {
  const { first_name, last_name, email, phone_number, headshot_url, scheduling_url } = contact_information;

  // If there is a CTA button or both phone number and email, use two column layout
  const twoColumn = scheduling_url || (phone_number && email);

  return (
    <div
      className={cx(
        'mb-4 flex flex-col items-center justify-between rounded-xl bg-[white] p-6 [box-shadow:0_2px_14px_rgba(32,_53,_104,_0.08)] sm:flex-row sm:px-10 sm:py-10',
        {
          'max-w-[744px]': !twoColumn,
        },
      )}
    >
      <div
        className={cx('flex w-full flex-col xs:flex-row sm:pb-0 sm:pr-4', {
          'pb-6 sm:max-w-[65%] md:max-w-[50%]': twoColumn,
        })}
      >
        <div className="pb-6 pr-6 xs:pb-0 xs:pr-12">
          <img className="h-26 w-26 rounded-xl xs:h-[168px] xs:w-[168px]" alt="investment manager" src={headshot_url} />
        </div>
        <div className={cx('my-auto flex flex-col', { 'lg:w-[448px]': twoColumn })}>
          {first_name && last_name ? (
            <>
              <span
                className="tracking-px pb-4 text-sm font-normal uppercase text-neutral-dark-100"
                data-testid="contact-info-im"
              >
                {card_title}
              </span>
              <span
                className="font-[serif] text-2xl font-semibold text-neutral-dark-75 xs:text-3xl"
                data-testid="contact-info-name"
              >
                {first_name} {last_name}
              </span>
            </>
          ) : (
            <span
              className="font-[serif] text-2xl font-semibold text-neutral-dark-100 xs:text-3xl"
              data-testid="contact-info-name"
            >
              {card_title}
            </span>
          )}
          <span className="pb-4 text-sm font-normal text-neutral-dark-75 xs:text-base">{card_description}</span>
          {phone_number && !twoColumn && (
            <div className="content-center">
              <Icon className="align-middle text-neutral-dark-100" size="2x" name="phone" />
              <span className="ml-[8px] text-sm font-bold" data-testid="contact-info-phone">
                {formatPhoneNumberSeparator(phone_number)}
              </span>
            </div>
          )}
          {email && !twoColumn && (
            <div className="content-center whitespace-nowrap text-sm font-bold">
              <Icon
                className="text-3xltext-neutral-dark-100 m-auto align-middle text-neutral-dark-100"
                size="2x"
                name="email"
              />
              <a href={`mailto:${email}`} className="ml-3" data-testid="contact-info-email">
                {email}
              </a>
            </div>
          )}
          {friendly_id && (
            <span className="text-xs font-normal text-neutral-dark-75" data-testid="contact-info-investment-id">
              Investment ID: {friendly_id}
            </span>
          )}
        </div>
      </div>
      {twoColumn && (
        <div
          className={cx('flex w-full flex-col justify-between gap-5 xs:flex-row sm:flex-col md:flex-row', {
            'sm:w-auto': scheduling_url,
            'sm:max-w-[40%]': !scheduling_url,
          })}
        >
          <div className="flex w-auto flex-col gap-5 self-baseline xs:w-full xs:justify-between xs:self-auto sm:w-auto">
            {email && (
              <div className="content-center whitespace-nowrap text-sm font-bold">
                <Icon className="m-auto align-middle text-3xl text-neutral-dark-100" size="2x" name="email" />
                <a href={`mailto:${email}`} className="ml-3" data-testid="contact-info-email">
                  {email}
                </a>
              </div>
            )}
            {phone_number && (
              <div className="content-center">
                <Icon className="align-middle text-neutral-dark-100" size="2x" name="phone" />
                <span className="ml-[8px] text-sm font-bold" data-testid="contact-info-phone">
                  {formatPhoneNumberSeparator(phone_number)}
                </span>
              </div>
            )}
          </div>
          {scheduling_url && (
            <div className="block md:hidden">
              <Button className="m-0 xs:my-4 sm:my-0" theme="secondary" href={scheduling_url}>
                Schedule a call
              </Button>
            </div>
          )}
        </div>
      )}
      {scheduling_url && (
        <div className="hidden md:block">
          <Button theme="secondary" href={scheduling_url}>
            Schedule a call
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContactInfo;
