import { Button } from '@hometap/htco-components';
import { useNavigate, useMatch } from 'react-router-dom';

import docHand from '../../../../images/document-hand.svg';

import './NoDocuments.scss';

const NoDocuments = () => {
  const navigate = useNavigate();
  const match = useMatch('/track/:trackId/*');

  return (
    <div className="NoDocuments">
      <figure>
        <img src={docHand} alt="Hand holding a document" />
      </figure>
      <div className="NoDocumentsCopy">
        <p className="NoDocumentsCopyHeading">No documents found.</p>
        <p className="NoDocumentsCopyDescription">You haven’t uploaded any documents yet.</p>{' '}
        <p className="NoDocumentsCopyDescription">Once you do, you’ll see them here.</p>
      </div>
      <Button
        className="NoDocumentsBackButton"
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        onClick={() => navigate(match.pathnameBase, { state: { notFromBackButton: true } })}
      >
        Back to Investment details
      </Button>
    </div>
  );
};

export default NoDocuments;
