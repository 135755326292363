import { useBoolState } from '@hometap/htco-components';
import cx from 'classnames';
import usePrimaryNav from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';
import { twMerge } from 'tailwind-merge';

// @ts-expect-error TS(7031): Binding element 'active' implicitly has an 'any' t... Remove this comment to see the full error message
const useNavItem = ({ active, open, disabled }) => {
  const isPressed = useBoolState();
  // @ts-expect-error TS(2339): Property 'isSidebarOpeningOrOpen' does not exist o... Remove this comment to see the full error message
  const { isSidebarOpeningOrOpen } = usePrimaryNav();

  // htco- is a hack to remove the overrides in base.scss to avoid overriding font-color and hover
  // states with important tags. In some cases the styles in base.scss are use !important too so it
  // would be even more hacky to override them.
  const className = twMerge(
    cx(
      'htco- relative flex items-center rounded-xl p-4 min-h-[63px] font-bold hover:bg-blue-5 border border-solid border-[transparent] transition-[padding] duration-500',
      {
        'text-blue-100': active || isPressed.value,
        'pb-8': open && isSidebarOpeningOrOpen,
        'text-neutral-dark-75': !active && !isPressed.value,
        'border-blue-15 bg-blue-5': open || active,
        'w-full': isSidebarOpeningOrOpen,
        'bg-neutral-light-30 hover:bg-neutral-light-30 text-neutral-dark-30 cursor-not-allowed': disabled,
      },
    ),
  );

  return {
    onMouseDown: isPressed.setTrue,
    onMouseUp: isPressed.setFalse,
    onTouchStart: isPressed.setTrue,
    onTouchEnd: isPressed.setFalse,
    isPressed: isPressed.value,
    className,
  };
};

export default useNavItem;
