import moment from 'moment';
import { browserWindow } from './window';
import { getCookieDomain } from 'utils/cookies';

const UTM_COOKIE_NAME = 'ht_utm_data';

// @ts-expect-error TS(7006): Parameter 'cookies' implicitly has an 'any' type.
export const getCachedUTMData = cookies => {
  return cookies.get(UTM_COOKIE_NAME) || {};
};

// Update the cookie using this method so that previous data
// is persisted and new data is added. Also forces consistent domain logic.
// @ts-expect-error TS(7006): Parameter 'cookies' implicitly has an 'any' type.
export const updateUTMCookie = (cookies, newCookiesData) => {
  const originalCookieData = getCachedUTMData(cookies);
  // @ts-expect-error TS(2339): Property 'location' does not exist on type '{}'.
  const { hostname } = browserWindow.location;

  cookies.set(
    UTM_COOKIE_NAME,
    {
      ...originalCookieData,
      ...newCookiesData,
    },
    {
      // @ts-expect-error TS(2769): No overload matches this call.
      expires: moment().add(1, 'Y').toDate(),
      domain: getCookieDomain(hostname),
    },
  );
};

// These properties will be saved in portals database and displayed in the Info section
// They should be set as {Key: value} where key is a human readable label and value is a
// string or a number, but not an object.
// For example: { "Amount Calculator Home Value": "$1,000,000" }
// @ts-expect-error TS(7006): Parameter 'cookies' implicitly has an 'any' type.
export const setUserPropertiesForPortals = (cookies, data) => {
  const { marketing_site_user_properties } = getCachedUTMData(cookies);

  const parsedSiteUserProperties =
    typeof marketing_site_user_properties === 'string'
      ? JSON.parse(marketing_site_user_properties)
      : marketing_site_user_properties;

  const siteUserProperties = { ...parsedSiteUserProperties, ...data };

  updateUTMCookie(cookies, {
    marketing_site_user_properties: JSON.stringify(siteUserProperties),
  });
};
