import { useEffect } from 'react';
import HomeEquityHedData from './HomeEquityHedData';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { Navigate } from 'react-router-dom';
import Footer from 'components/Footer/Footer';

import { buildHomeProfileUrl, getNewHEDUrl, HOMEOWNER_DASHBOARD } from 'utils/links';

import { useFlags } from 'launchdarkly-react-client-sdk';
import SmartCardCarousel from './smart-cards/SmartCardCarousel';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import useSmartFacts from 'apps/dashboard/components/home-equity-controller/smart-cards/hooks/useSmartFacts';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import { useHomeValuation, useSelfReportedHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import useHouseCanaryLiensAvailable from 'apps/dashboard/hooks/useHouseCanaryLiensAvailable';

const HomeEquityController = () => {
  const { home } = useCurrentHome();
  const { showOverviewPage } = useFlags();
  // @ts-expect-error TS(2339): Property 'isLoadingHed' does not exist on type '{ ... Remove this comment to see the full error message
  const { currentScenario, isLoadingHed, initialScenario, chartDataLoading } = useEquityScenarioContext();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist o... Remove this comment to see the full error message
  const { displayedHomeValuation, selfReportedHomeValuation, isLoading: isHomeValuationLoading } = useHomeValuation();

  const { setupProfileLater, isForcedSetupProfileLater, sendSkipBuildProfileEvent } = useDelayHomeEquityProfile();
  const { prequalRange } = useHedPrequalRange();

  const { smartFacts, updateSmartFacts, smartFactsLoading, hasCompletedSmartCards, handleCompletion } = useSmartFacts();

  const homeValueExists = !!displayedHomeValuation?.value;
  const showSmartCards = !showOverviewPage && homeValueExists && smartFacts?.length > 1;

  const areScenariosLoading = chartDataLoading || !initialScenario;

  const { mutate } = useSelfReportedHomeValuationMutation(home);

  const shouldUpdateSelfReportedHomeValuation =
    selfReportedHomeValuation && !selfReportedHomeValuation.is_editable && selfReportedHomeValuation.valuation?.value;
  useEffect(() => {
    // homeowners can enter the funnel from just being an HED user with a self reported value. If they
    // were to go all the way through the funnel, they would still have a self reported value from
    // potentially years ago. As a product requirement we want to all homeowners to update their self
    // reported value if they're settled. So we need to nullify the value if they have a value but
    // it's not editable. Ideally we'd do this on the backend but that is a larger lift. We also plan
    // on refactoring the way the self reported value is stored in the future. So we can remove this
    // at that time
    if (shouldUpdateSelfReportedHomeValuation) {
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      mutate(null);
    }
  }, [mutate, shouldUpdateSelfReportedHomeValuation]);

  const { available: isHouseCanaryFlowAvailable, loading: isHCLinesInfoLoading } = useHouseCanaryLiensAvailable();

  const isLoading = isLoadingHed || isHCLinesInfoLoading || smartFactsLoading || areScenariosLoading;

  const renderDashboard = () => {
    return (
      !isHomeValuationLoading && (
        <>
          {showSmartCards && (
            // Wrapping containers to prevent ResizeObserver issue with Embla
            <div className="h-auto overflow-visible">
              <div className="flex w-full">
                <div className="w-full">
                  <div className="mx-auto my-0 mt-2 w-full overflow-visible border-0 border-b border-solid border-b-neutral-light-100">
                    {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
                    <PrimaryLayoutGrid>
                      <div className="col-span-full">
                        <SmartCardCarousel
                          cards={smartFacts}
                          handleCompletion={handleCompletion}
                          hasCompleted={hasCompletedSmartCards}
                        />
                      </div>
                    </PrimaryLayoutGrid>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
          <PrimaryLayoutGrid>
            <div className="col-span-full">
              <HomeEquityHedData
                updateSmartFacts={updateSmartFacts}
                // @ts-expect-error TS(2339): Property 'valuations' does not exist on type '{}'.
                currentEquity={currentScenario?.valuations?.currentEquity}
                // @ts-expect-error TS(2339): Property 'max' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
                tappableEquity={prequalRange?.max || 0}
                homeValueMissing={!homeValueExists}
              />
              {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ theme: ... Remove this comment to see the full error message */}
              <Footer theme="light" />
            </div>
          </PrimaryLayoutGrid>
        </>
      )
    );
  };

  if (isHouseCanaryFlowAvailable && !isForcedSetupProfileLater && !isHCLinesInfoLoading) {
    return <Navigate to={getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.ACCEPT_LIENS}`)} replace />;
  }

  if (!isHCLinesInfoLoading) {
    sendSkipBuildProfileEvent();
  }

  return (
    <ScrollToTop>
      {/* @ts-expect-error TS(2739): Type '{ children: Element; loading: any; }' is mis... Remove this comment to see the full error message */}
      <SectionLoadingWrapper loading={isLoading}>
        {
          // @ts-expect-error TS(2339): Property 'hed_debts_confirmed' does not exist on t... Remove this comment to see the full error message
          home?.hed_debts_confirmed || setupProfileLater ? renderDashboard() : <Navigate to={buildHomeProfileUrl()} />
        }
      </SectionLoadingWrapper>
    </ScrollToTop>
  );
};

export default HomeEquityController;
