export const scrollTop = () => {
  // Scroll container of PrimaryLayout instead of body element
  const scrollEl = document.querySelector('#primary-layout-scroll');
  if (scrollEl) {
    scrollEl.scrollTop = 0;
    return;
  }

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

// @ts-expect-error TS(7006): Parameter 'elemId' implicitly has an 'any' type.
export const scrollIntoViewByElementId = elemId => {
  document.getElementById(elemId)?.scrollIntoView({
    block: 'end',
    behavior: 'smooth',
  });
};
