// Use in callbacks and util functions as browserWindow.location.pathname
export const browserWindow = typeof window !== 'undefined' ? window : {};
export const browserDocument = typeof document !== 'undefined' ? document : {};

// @ts-expect-error TS(7006): Parameter 'loc' implicitly has an 'any' type.
export const browserRedirect = loc => {
  if (typeof window !== 'undefined') {
    window.history.pushState(null, '', loc);
  }
};

export const isBrowser = () => {
  if (typeof window !== 'undefined') {
    return true;
  }
  return false;
};
