import { useCallback, useEffect, useState, useRef } from 'react';
import { useCurrentHome } from 'hooks/useCurrentHome';
import cx from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import PropTypes from 'prop-types';

import { Icon, Button, Grid, useWindowSize } from '@hometap/htco-components';

import helpfulButton from 'images/equity-planner/dashboard/icons/helpful_button.png';

import unhelpfulButton from 'images/equity-planner/dashboard/icons/unhelpful_button.png';
import ConfettiExplosion from 'react-confetti-explosion';
import { themeMap, themeHighlightTextMap } from './constants/smartFactConstants';
import { motion } from 'framer-motion';
import './SmartCardCarousel.scss';

/*
  This Carousel was made with Embla Carousel. Docs are here: https://www.embla-carousel.com/guides/
*/

// @ts-expect-error TS(7006): Parameter 'text' implicitly has an 'any' type.
const highlightText = (text, theme) => {
  // Matches dollar amounts, percentages (including decimals), percentage ranges, and the phrase "up to"
  const regex = /(\$\d+(?:,\d{3})*(?:\.\d{1,2})?|\d+(?:\.\d+)?(?:-\d+(?:\.\d+)?)?%|up to)/gi;
  const parts = text.split(regex);
  const matches = new Set(text.match(regex)); // Use a Set for unique matches

  // Combine parts and matches into an array of elements
  // @ts-expect-error TS(7034): Variable 'combined' implicitly has type 'any[]' in... Remove this comment to see the full error message
  const combined = [];
  // @ts-expect-error TS(7006): Parameter 'part' implicitly has an 'any' type.
  parts.forEach((part, i) => {
    if (matches.has(part)) {
      // If part is a match, style it
      combined.push(
        <span key={`match-${i}`} className={cx(themeHighlightTextMap[theme])}>
          {part}
        </span>,
      );
      matches.delete(part); // Remove the match to avoid duplication
    } else {
      // If part is not a match, include it as normal
      combined.push(part);
    }
  });

  // @ts-expect-error TS(7005): Variable 'combined' implicitly has an 'any[]' type... Remove this comment to see the full error message
  return combined;
};

// @ts-expect-error TS(7031): Binding element 'selected' implicitly has an 'any'... Remove this comment to see the full error message
const DotButton = ({ selected }) => (
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
  <button className={cx('embla__dot', { is_selected: !!selected })} type="button" tabIndex="-1" />
);

const SmartCardFinalCard = () => {
  return (
    <div className="SmartCardFinalCard">
      <Icon name="icon-check" size="2x" />
      <h2>You’re all caught up!</h2>
      <p>Come back soon for your next set of Hometap Insights.</p>
    </div>
  );
};

const SmartCardCarouselCard = ({
  // @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
  id,
  // @ts-expect-error TS(7031): Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
  theme,
  // @ts-expect-error TS(7031): Binding element 'header' implicitly has an 'any' t... Remove this comment to see the full error message
  header,
  // @ts-expect-error TS(7031): Binding element 'hasCta' implicitly has an 'any' t... Remove this comment to see the full error message
  hasCta,
  // @ts-expect-error TS(7031): Binding element 'isHelpful' implicitly has an 'any... Remove this comment to see the full error message
  isHelpful,
  // @ts-expect-error TS(7031): Binding element 'ctaText' implicitly has an 'any' ... Remove this comment to see the full error message
  ctaText,
  // @ts-expect-error TS(7031): Binding element 'onCtaClick' implicitly has an 'an... Remove this comment to see the full error message
  onCtaClick,
  // @ts-expect-error TS(7031): Binding element 'onHelpful' implicitly has an 'any... Remove this comment to see the full error message
  onHelpful,
  // @ts-expect-error TS(7031): Binding element 'onUnhelpful' implicitly has an 'a... Remove this comment to see the full error message
  onUnhelpful,
  // @ts-expect-error TS(7031): Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
  onNext,
}) => {
  const classes = cx('SmartCardCarouselCard', themeMap[theme], 'hasMultipleCards');
  const styledHeader = highlightText(header, theme);
  const [lastUnhelpfulId, setLastUnhelpfulId] = useState(null);
  const isUnhelpful = lastUnhelpfulId === id;
  const feedbackSubmitted = isHelpful || isUnhelpful;

  // @ts-expect-error TS(7006): Parameter 'handler' implicitly has an 'any' type.
  const createCardEventHandler = handler => {
    // @ts-expect-error TS(7006): Parameter 'event' implicitly has an 'any' type.
    return event => {
      event.stopPropagation();
      if (handler) {
        handler(id);
      }
    };
  };

  const handleHelpfulClick = createCardEventHandler(onHelpful);

  // To animate unhelpful transition we call onUnhelpful only after the exit animation is completed.
  // When the button is clicked we just initiate the animation.
  // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
  const handleUnhelpfulClick = createCardEventHandler(id => {
    setLastUnhelpfulId(id);
  });

  const isExitAnimationCompleted = useRef(false);
  let handleAnimationComplete = null;
  if (lastUnhelpfulId) {
    handleAnimationComplete = isExitAnimationCompleted.current
      ? () => {
          setLastUnhelpfulId(null);
          isExitAnimationCompleted.current = false;
        }
      : () => {
          isExitAnimationCompleted.current = true;
          onUnhelpful(id);
        };
  }

  return (
    <motion.div
      initial={{ opacity: lastUnhelpfulId && lastUnhelpfulId !== id ? 0 : 1 }}
      animate={{ opacity: isUnhelpful ? 0 : 1 }}
      // @ts-expect-error TS(2322): Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
      onAnimationComplete={handleAnimationComplete}
      transition={isUnhelpful ? { duration: 1.3, ease: 'backIn' } : { ease: 'easeOut' }}
      className={classes}
      onClick={isUnhelpful ? null : onNext}
      onKeyDown={isUnhelpful ? null : onNext}
      role="button"
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
      tabIndex="0"
      smart-card-id={id}
    >
      <div className="SmartCardCarouselCardInnerContainer">
        <Grid container columnSpacing={2}>
          <Grid sm={12} md={8} lg={9}>
            <div className="InsightsTitle">Hometap Insights</div>
            <h2 className="InsightsText">{styledHeader}</h2>
            {hasCta && (
              <Button
                theme="link"
                className="SmartCardCarouselCtaBtn min-w-0"
                onClick={createCardEventHandler(onCtaClick)}
              >
                <span>{ctaText}</span>
                <Icon name="arrow-forward" className="IconProperties" />
              </Button>
            )}
          </Grid>
          <Grid sm={12} md={4} lg={3}>
            <div className="SmartCardCarouselCardReviewSection">
              {feedbackSubmitted ? (
                <span className="SmartCardCarouselFeedback">Thanks for the feedback!</span>
              ) : (
                <>
                  <span>Was this helpful?</span>
                  <button onClick={handleHelpfulClick} aria-label="thumbs up" className="SmartCardButtonImageWrapper">
                    <img src={helpfulButton} alt="thumbs up" />
                  </button>
                  <button
                    onClick={handleUnhelpfulClick}
                    aria-label="thumbs down"
                    className="SmartCardButtonImageWrapper"
                  >
                    <img src={unhelpfulButton} alt="thumbs down" />
                  </button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </motion.div>
  );
};

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const SmartCardCarousel = ({ className, cards = [], handleCompletion, hasCompleted }) => {
  const editedCards = [...cards, { theme: 'FINAL' }];
  // watchResize was causing an error in the latest embla release, using an earlier version of embla seems to have fixed
  const { isScreenSize } = useWindowSize();
  const { home } = useCurrentHome();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    // @ts-expect-error TS(2345): Argument of type '{ draggable: any; }' is not assi... Remove this comment to see the full error message
    draggable: isScreenSize('sm'),
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onLastCard = selectedIndex >= editedCards.length - 1;

  useEffect(() => {
    emblaApi?.scrollTo(0, true);
    setSelectedIndex(0);
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
  }, [emblaApi, home?.id]);

  useEffect(() => {
    if (onLastCard) {
      handleCompletion();
    }
  }, [handleCompletion, onLastCard]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);

    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, onSelect]);

  return (
    <div className={cx('SmartCardCarousel', className)}>
      <div className="SmartCardCarouselConfetti">
        {onLastCard && !hasCompleted ? (
          <ConfettiExplosion zIndex={10000000} colors={['#366CED', '#7070FF', '#25BE8A', '#70DAFF', '#35B2AD']} />
        ) : null}
      </div>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {editedCards.map((card, i) => (
              <div className="embla__slide" key={card?.theme + i}>
                {card.theme === 'FINAL' ? (
                  <SmartCardFinalCard />
                ) : (
                  <SmartCardCarouselCard
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ theme: str... Remove this comment to see the full error message
                    id={card?.id}
                    theme={card?.theme}
                    // @ts-expect-error TS(2339): Property 'header' does not exist on type '{ theme:... Remove this comment to see the full error message
                    header={card?.header}
                    // @ts-expect-error TS(2339): Property 'hasCta' does not exist on type '{ theme:... Remove this comment to see the full error message
                    hasCta={card?.hasCta}
                    // @ts-expect-error TS(2339): Property 'ctaText' does not exist on type '{ theme... Remove this comment to see the full error message
                    ctaText={card?.ctaText}
                    // @ts-expect-error TS(2339): Property 'isHelpful' does not exist on type '{ the... Remove this comment to see the full error message
                    isHelpful={card?.isHelpful}
                    // @ts-expect-error TS(2339): Property 'onCtaClick' does not exist on type '{ th... Remove this comment to see the full error message
                    onCtaClick={card?.onCtaClick}
                    // @ts-expect-error TS(2339): Property 'onHelpful' does not exist on type '{ the... Remove this comment to see the full error message
                    onHelpful={card?.onHelpful}
                    // @ts-expect-error TS(2339): Property 'onUnhelpful' does not exist on type '{ t... Remove this comment to see the full error message
                    onUnhelpful={card?.onUnhelpful}
                    onNext={scrollNext}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        {cards.length > 1 && (
          <div className="SmartCardCarouselNavContainer hasMultipleCards">
            <button
              className={cx('SmartCardCarouselButton', 'embla__prev', { hideCarouselButton: selectedIndex < 1 })}
              onClick={scrollPrev}
            >
              <Icon name="chevron2-left" size="sm" />
            </button>
            <div className="embla__dots">
              {editedCards.map((_, index) => (
                <DotButton key={index} selected={index === selectedIndex} />
              ))}
            </div>
            <button
              className={cx('SmartCardCarouselButton', 'embla__next', {
                hideCarouselButton: selectedIndex === cards.length,
              })}
              onClick={scrollNext}
            >
              <Icon name="chevron2-right" size="sm" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

SmartCardCarousel.propTypes = {
  className: PropTypes.string,
  handleCompletion: PropTypes.func,
  hasCompleted: PropTypes.bool,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      theme: PropTypes.oneOf(['teal', 'lightBlue', 'purple', 'blue']),
      header: PropTypes.string.isRequired,
      hasCta: PropTypes.bool,
      ctaText: PropTypes.string,
      onCtaClick: PropTypes.func,
      onHelpful: PropTypes.func,
      onUnhelpful: PropTypes.func,
    }),
  ),
};

export default SmartCardCarousel;
