import cx from 'classnames';

import { Tooltip } from '@hometap/htco-components';

import './TrackDetailsProgressStepper.scss';

const TrackDetailsProgressStepper = ({
  // @ts-expect-error TS(7031): Binding element 'steps' implicitly has an 'any' ty... Remove this comment to see the full error message
  steps,
  // @ts-expect-error TS(7031): Binding element 'activeIndex' implicitly has an 'a... Remove this comment to see the full error message
  activeIndex,
  // @ts-expect-error TS(7031): Binding element 'tooltipData' implicitly has an 'a... Remove this comment to see the full error message
  tooltipData,
  // @ts-expect-error TS(7031): Binding element 'stepIndicatorClassName' implicitl... Remove this comment to see the full error message
  stepIndicatorClassName,
  // @ts-expect-error TS(7031): Binding element 'containerClassName' implicitly ha... Remove this comment to see the full error message
  containerClassName,
  // @ts-expect-error TS(7031): Binding element 'stepLabelClassName' implicitly ha... Remove this comment to see the full error message
  stepLabelClassName,
}) => {
  const gradients = [
    ['#70DD7D', '#70DBFF'],
    ['#70DBFF', '#B770FF'],
    ['#B770FF', '#7070FF'],
    ['#7070FF', '#3C4CFF'],
  ];

  const hasTooltipData = typeof tooltipData === 'function';

  return (
    <div className={cx('TrackDetailsProgressStepperContainer', containerClassName)}>
      {/* @ts-expect-error TS(7006): Parameter 'step' implicitly has an 'any' type. */}
      {steps.map((step, stepIndex) => {
        const isActive = stepIndex === activeIndex;
        const isDisabled = stepIndex > activeIndex;

        const formattedGradients = gradients[stepIndex % gradients.length]?.join(', ');
        const styleObj = {
          ...(!isDisabled && { backgroundImage: `linear-gradient(to right, ${formattedGradients})` }),
        };

        const stepComponent = (
          <>
            <div className={cx('TrackDetailsProgressStepperStepIndicator', stepIndicatorClassName)} style={styleObj} />
            <div className={cx('TrackDetailsProgressStepperStepLabel', stepLabelClassName)}>{step}</div>
          </>
        );

        const { content, action, position } = hasTooltipData ? tooltipData(step) : {};

        return (
          <Tooltip
            content={content}
            action={hasTooltipData ? action : 'none'}
            position={position}
            className={cx('TrackDetailsProgressStepperStepContainer', { isActive, isDisabled })}
            key={stepIndex}
          >
            {stepComponent}
          </Tooltip>
        );
      })}
    </div>
  );
};

export default TrackDetailsProgressStepper;
