import { apiWithAuth } from 'utils/api';

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
export const fetchRenovationTemplates = (state, name) => {
  let url = `homes/renovation-templates/${state}`;
  if (name) {
    url += `?name=${name}`;
  }

  return apiWithAuth.v1.get(url);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const saveRenovationToScenario = (homeId, scenarioId, renovation) => {
  let url = `homes/${homeId}/equity-scenarios/${scenarioId}/renovations/`;
  if (renovation.id) {
    url += `${renovation.id}/`;
    return apiWithAuth.v1.patch(url, renovation);
  }
  return apiWithAuth.v1.post(url, renovation);
};

// @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
export const deleteRenovation = (homeId, scenarioId, renoId) => {
  return apiWithAuth.v1.delete(`homes/${homeId}/equity-scenarios/${scenarioId}/renovations/${renoId}/`);
};
