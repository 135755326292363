import { endsWith } from 'lodash';

/**
 * Get the domain for the cookie based on the hostname.
 * @param {string} hostname
 * @returns {string}
 */
// @ts-expect-error TS(7006): Parameter 'hostname' implicitly has an 'any' type.
export const getCookieDomain = hostname => {
  if (endsWith(hostname, '.hometap.com')) {
    return '.hometap.com';
  }
  if (endsWith(hostname, '.htap.sh')) {
    return '.htap.sh';
  }

  return hostname;
};
