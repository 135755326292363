import { Button } from '@hometap/htco-components';
import './TrackDetailsReviewCard.scss';
import mature_man_on_couch from 'images/inquiry/mature_man_on_couch.webp';

const TrackDetailsReviewCard = () => {
  return (
    <div className="TrackDetailsReviewCardContainer">
      <div className="TrackDetailsReviewCardLeft">
        <h2>
          <span className="TrackDetailsReviewTextGreen">Share</span> your experience.
        </h2>
        <p className="TrackDetailsReviewCardSubheading">
          We hope your Investment gets you closer to your goals. If you have a few minutes, we’d love to hear how we
          did!
        </p>
        <Button
          theme="secondary"
          href="https://www.trustpilot.com/evaluate/hometap.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Leave a review
        </Button>
      </div>
      <div className="TrackDetailsReviewCardRight">
        {/* @ts-expect-error TS(2322): Type '{ src: any; type: string; alt: string; }' is... Remove this comment to see the full error message */}
        <img src={mature_man_on_couch} type="image/webp" alt="Mature man sitting on a couch looking at a tablet" />
      </div>
    </div>
  );
};

export default TrackDetailsReviewCard;
