import { useEffect } from 'react';
import sentry from 'utils/sentry';

// @ts-expect-error TS(7006): Parameter 'message' implicitly has an 'any' type.
const useLogAsyncError = (message, error, shouldLog = true) => {
  useEffect(() => {
    if (error?.response && shouldLog) {
      sentry.logWarning(message, {
        tags: {
          statusCode: error.response.status,
          statusText: error.response.statusText,
        },
        extra: {
          errorMessage: error.message,
          requestUrl: error.config?.url,
          responseBody: error.response.data,
        },
      });
    }
  }, [message, error, shouldLog]);
};

export default useLogAsyncError;
