import { Button } from '@hometap/htco-components';
import { convertDateStringToWeekday } from 'utils/date';
import InquiryFormRow from './outcomeTextComponents/InquiryFormRow';
import InquiryFico from './InquiryFico';
import './InquiryDQ.scss';

const InquiryRiskBand = ({
  // @ts-expect-error TS(7031): Binding element 'firstName' implicitly has an 'any... Remove this comment to see the full error message
  firstName,
  // @ts-expect-error TS(7031): Binding element 'lastName' implicitly has an 'any'... Remove this comment to see the full error message
  lastName,
  // @ts-expect-error TS(7031): Binding element 'riskFailFactors' implicitly has a... Remove this comment to see the full error message
  riskFailFactors,
  // @ts-expect-error TS(7031): Binding element 'ficoScore' implicitly has an 'any... Remove this comment to see the full error message
  ficoScore,
  // @ts-expect-error TS(7031): Binding element 'onBack' implicitly has an 'any' t... Remove this comment to see the full error message
  onBack,
  // @ts-expect-error TS(7031): Binding element 'experianDataPulledAt' implicitly ... Remove this comment to see the full error message
  experianDataPulledAt,
  // @ts-expect-error TS(7031): Binding element 'ficoFail' implicitly has an 'any'... Remove this comment to see the full error message
  ficoFail,
}) => {
  return (
    <div className="DQAdverseActionWrapper">
      <div className="DQBackButtonWrapper">
        <Button theme="link" className="DQLinkBack" onClick={onBack}>
          Back
        </Button>
      </div>
      <InquiryFormRow>
        <h1 className="DQHeader" data-testid="dq-header-2">
          At this time, Hometap is unable to make an investment in your home.
        </h1>
      </InquiryFormRow>
      <InquiryFormRow>
        <div className="DQSubtitleHeader">We’d like to help you understand the possible reasons for that.</div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div>
          In making investment decisions, Hometap considers information obtained through a consumer credit reporting
          agency. A Hometap Investment is not a credit product, but Hometap’s decision was based in whole or in part on
          information obtained from the below consumer credit reporting agency on{' '}
          {convertDateStringToWeekday(experianDataPulledAt)}.
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div>
          You have a right under the Fair Credit Reporting Act to know the information contained in your credit file at
          the consumer reporting agency. The reporting agency played no part in our decision and is unable to supply
          specific reasons why we have denied your request. You also have a right to a free copy of your credit report
          from the reporting agency, if you request it no later than 60 days after you receive this notice. In addition,
          if you find that any information contained in the report you receive is inaccurate or incomplete, you have the
          right to dispute the matter with the reporting agency.
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div>
          We encourage everyone to check their credit report to ensure that the information is accurate and up to date.
          You can do that for free{' '}
          <Button href="http://www.annualcreditreport.com" theme="link" rel="noreferrer" target="_blank">
            here
          </Button>
          , or by contacting the consumer credit reporting agency.
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div className="DQAdverseActionAddress">
          <div>Experian</div>
          <div>P.O. Box 9701</div>
          <div>Allen, TX 75013</div>
          <div>(888) 397-3742</div>
        </div>
      </InquiryFormRow>
      {ficoFail && (
        <InquiryFico
          firstName={firstName}
          lastName={lastName}
          riskFailFactors={riskFailFactors}
          ficoScore={ficoScore}
          experianDataPulledAt={experianDataPulledAt}
          // @ts-expect-error TS(2322): Type '{ firstName: any; lastName: any; riskFailFac... Remove this comment to see the full error message
          onBack={onBack}
        />
      )}
    </div>
  );
};

export default InquiryRiskBand;
