import { useParams } from 'react-router-dom';
import { TrackProvider } from 'hooks/useTrack';
import { ActiveTrackCheck } from 'components/TrackRoute/TrackRoute';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const TrackDetailsProviderWrapper = ({ children }) => {
  const { trackId } = useParams();

  return (
    <TrackProvider trackId={trackId}>
      <ActiveTrackCheck>{children}</ActiveTrackCheck>
    </TrackProvider>
  );
};

export default TrackDetailsProviderWrapper;
