import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import house from 'images/equity-planner/onboarding/three-houses-desktop.png';

const PageSEO = ({
  // @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031): Binding element 'meta' implicitly has an 'any' typ... Remove this comment to see the full error message
  meta,
  // @ts-expect-error TS(7031): Binding element 'links' implicitly has an 'any' ty... Remove this comment to see the full error message
  links,
  // @ts-expect-error TS(7031): Binding element 'lang' implicitly has an 'any' typ... Remove this comment to see the full error message
  lang,
  // @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031): Binding element 'description' implicitly has an 'a... Remove this comment to see the full error message
  description,
  // @ts-expect-error TS(7031): Binding element 'canonicalUrl' implicitly has an '... Remove this comment to see the full error message
  canonicalUrl,
  // @ts-expect-error TS(7031): Binding element 'opengraphTitle' implicitly has an... Remove this comment to see the full error message
  opengraphTitle,
  // @ts-expect-error TS(7031): Binding element 'opengraphDescription' implicitly ... Remove this comment to see the full error message
  opengraphDescription,
  // @ts-expect-error TS(7031): Binding element 'opengraphImageUrl' implicitly has... Remove this comment to see the full error message
  opengraphImageUrl,
  // @ts-expect-error TS(7031): Binding element 'opengraphType' implicitly has an ... Remove this comment to see the full error message
  opengraphType,
}) => {
  const canonicalPage = canonicalUrl
    ? [
        {
          rel: 'canonical',
          href: canonicalUrl,
        },
      ]
    : [];

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={canonicalPage.concat(links)}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: opengraphTitle,
          },
          {
            property: `og:description`,
            content: opengraphDescription,
          },
          {
            property: `og:type`,
            content: opengraphType,
          },
          {
            property: `og:image`,
            content: opengraphImageUrl,
          },
        ].concat(meta)}
      />
      {children}
    </div>
  );
};

PageSEO.defaultProps = {
  lang: `en`,
  meta: [],
  links: [],
  title: 'Hometap | Access Your Home Equity | No Monthly Payments',
  description:
    'Hometap provides homeowners with an opportunity to tap into their home’s equity without monthly payments or having to take out another loan.',
  opengraphTitle: 'Hometap | Access Your Home Equity | No Monthly Payments',
  opengraphDescription:
    'Hometap provides homeowners with an opportunity to tap into their home’s equity without monthly payments or having to take out another loan.',
  // TODO: Should this point to somewhere else?
  opengraphImageUrl: house,
  opengraphType: 'website',
};

PageSEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  opengraphTitle: PropTypes.string,
  opengraphDescription: PropTypes.string,
  opengraphImageUrl: PropTypes.string,
  opengraphType: PropTypes.string,
};

export default PageSEO;
