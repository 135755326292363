import { useEffect, useState } from 'react';

import { Button, MuiTextInput, SelectionSet, Loader } from '@hometap/htco-components';
import Footer from 'components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Insight from 'components/insight/Insight';
import useConfigurationOptions from 'hooks/useConfigurationOptions';
import { scrollIntoViewByElementId, scrollTop } from 'utils/domScripts';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';
import useProgressBar from 'hooks/useProgressBar';
import './DashboardGoalsPage.scss';

const financeGoalButtonId = 'finance-continue-button-id';

const DashboardGoalsPage = () => {
  const navigate = useNavigate();
  const { home } = useCurrentHome();
  const currentHomeMutation = useCurrentHomeMutation();
  const [selectedItem, setSelectedItem] = useState();
  const [extraDetail, setExtraDetail] = useState('');
  const [showMoreDetailBlock, setShowMoreDetailBlock] = useState(false);
  const { dropdownConfigurationOptions } = useConfigurationOptions({ names: ['financial_goal'] });
  if (selectedItem) {
    scrollIntoViewByElementId(financeGoalButtonId);
  }

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'hed_financial_goal' does not exist on ty... Remove this comment to see the full error message
    if (home?.hed_financial_goal) {
      // @ts-expect-error TS(2339): Property 'hed_financial_goal' does not exist on ty... Remove this comment to see the full error message
      setSelectedItem(home.hed_financial_goal);
      // @ts-expect-error TS(2339): Property 'hed_financial_goal_other' does not exist... Remove this comment to see the full error message
      if (home.hed_financial_goal_other) {
        // @ts-expect-error TS(2339): Property 'hed_financial_goal_other' does not exist... Remove this comment to see the full error message
        setExtraDetail(home.hed_financial_goal_other);
      }
    }
  }, [home]);

  // @ts-expect-error TS(2345): Argument of type '{ currStep: number; steps: numbe... Remove this comment to see the full error message
  useProgressBar({ currStep: showMoreDetailBlock ? 2 : 1, steps: 3 });

  const handleContinueClick = async () => {
    if (selectedItem === null) {
      return;
    }
    const data = {
      hed_financial_goal: selectedItem,
      hed_financial_goal_other: selectedItem === 'other' && showMoreDetailBlock ? extraDetail : '',
    };
    if (selectedItem !== 'other' || (selectedItem === 'other' && showMoreDetailBlock)) {
      // @ts-expect-error TS(2345): Argument of type '{ hed_financial_goal: undefined;... Remove this comment to see the full error message
      await currentHomeMutation.mutateAsync(data);
      navigate('/dashboard/home-equity/finance');
      return;
    }
    setShowMoreDetailBlock(true);
  };

  const noSelectionOptions = !Object.keys(dropdownConfigurationOptions).length;
  if (currentHomeMutation.isPending || noSelectionOptions) {
    return (
      <div className="PageLoader">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <div className="col-span-full">
      <div className="DashboardGoalsContainer DashboardPage">
        <div className="DashboardGoalsInnerContainer">
          <h3 className="pageTitle">What is the primary financial goal you want to achieve?</h3>
          {showMoreDetailBlock ? (
            <MuiTextInput
              onChange={setExtraDetail}
              name="hed_financial_goal_other"
              type="text"
              label="Other (You tell us!)"
              width="auto"
              maxLength={255}
              value={extraDetail}
              helperText="Let us know which goal you want to acheive"
              className="OtherDetailBlock"
            />
          ) : (
            <SelectionSet
              align="left"
              labelWidth="50%"
              name="hed_financial_goal"
              // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
              onChange={value => {
                setSelectedItem(value);
              }}
              value={selectedItem}
              options={dropdownConfigurationOptions?.financial_goal}
              theme="normal"
            />
          )}
          <Button
            id={financeGoalButtonId}
            className="GoalsContinueButton"
            disabled={!selectedItem || (showMoreDetailBlock && !extraDetail)}
            type="button"
            loading={currentHomeMutation.isPending}
            onClick={handleContinueClick}
            data-testid="goals-continue-button"
          >
            Continue
          </Button>
          <div className="GoalInsights">
            <Insight text="Understanding your needs as a homeowner can help you choose the right product to meet your financial goals. Each financial product is different, and offers pros and cons for each personal use case." />
          </div>
        </div>
      </div>
      {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ theme: ... Remove this comment to see the full error message */}
      <Footer theme="light" />
    </div>
  );
};

export default DashboardGoalsPage;
