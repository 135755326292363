import { createContext, useContext, useEffect, useState } from 'react';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const NavAccordionContext = createContext();

const useNavAccordion = () => {
  const context = useContext(NavAccordionContext);
  if (!context) throw new Error('useNavAccordion must be used within a NavAccordionProvider');
  return context;
};

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const NavAccordionProvider = ({ children, onToggle, canExpand = true, open }) => {
  const [isExpanded, setIsExpanded] = useState(open || false);

  useEffect(() => {
    if (open !== null) {
      setIsExpanded(open);
    }
  }, [open]);

  const handleClick = () => {
    if (canExpand) setIsExpanded(!isExpanded);
    if (onToggle) onToggle(canExpand ? !isExpanded : undefined);
  };

  return (
    <NavAccordionContext.Provider value={{ open: isExpanded && canExpand, onToggle: handleClick, canExpand }}>
      {children}
    </NavAccordionContext.Provider>
  );
};

export default useNavAccordion;
