import { LOCAL_STORAGE_LATER_EVENT_KEY, LOCAL_STORAGE_LATER_KEY } from 'apps/dashboard/constants';
import { useCurrentHome } from 'hooks/useCurrentHome';
import useHedInvestmentContext from './useHedInvestmentContext';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';

const useDelayHomeEquityProfile = () => {
  // @ts-expect-error TS2339
  const { home, loading: isHomeLoading } = useCurrentHome();
  // @ts-expect-error TS2339
  const { investment, loading: isInvestmentLoading } = useHedInvestmentContext();

  // the following methods are for handling the delay of build profile setup by using local storage variables.
  // this can be improved upon by storing on the backend.
  // @ts-expect-error TS(7006): Parameter 'homeId' implicitly has an 'any' type.
  const removeDelayLocalStorageKey = homeId => {
    const setupProfileLaterIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) ?? '[]');
    // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
    const updatedIds = setupProfileLaterIds.filter(id => id !== homeId);
    localStorage.setItem(LOCAL_STORAGE_LATER_KEY, JSON.stringify(updatedIds));

    const eventSenIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_EVENT_KEY) ?? '[]');
    // @ts-expect-error TS7006
    const updatedEventIds = eventSenIds.filter(id => id !== homeId);
    localStorage.setItem(LOCAL_STORAGE_LATER_EVENT_KEY, JSON.stringify(updatedEventIds));
  };
  const setupProfileLaterLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) ?? '[]');
  // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
  const setupProfileLaterHomeMatch = setupProfileLaterLocalStorage?.find(id => id === home?.id);
  // @ts-expect-error TS(2339): Property 'hed_debts_confirmed' does not exist on t... Remove this comment to see the full error message
  const setupProfileLater = !home?.hed_debts_confirmed && (setupProfileLaterHomeMatch || !investment);

  const loading = isHomeLoading || isInvestmentLoading;

  const sendSkipBuildProfileEvent = () => {
    const eventSentIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_EVENT_KEY) ?? '[]');
    // @ts-expect-error TS2339
    if (!loading && setupProfileLater && home?.id && !eventSentIds.includes(home?.id)) {
      browserTrack.skipBuildProfile({ version: getVersion() });
      // @ts-expect-error TS2339
      localStorage.setItem(LOCAL_STORAGE_LATER_EVENT_KEY, JSON.stringify([...eventSentIds, home.id]));
    }
  };

  const forceSetupProfileLater = () => {
    const setupProfileLaterIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) ?? '[]');
    // @ts-expect-error TS2339
    if (home?.id && !setupProfileLaterIds.includes(home.id)) {
      // @ts-expect-error TS2339
      setupProfileLaterIds.push(home.id);
    }
    localStorage.setItem(LOCAL_STORAGE_LATER_KEY, JSON.stringify(setupProfileLaterIds));
    sendSkipBuildProfileEvent();
  };

  return {
    setupProfileLater,
    isForcedSetupProfileLater: !!setupProfileLaterHomeMatch,
    loading,
    forceSetupProfileLater,
    removeDelayLocalStorageKey,
    sendSkipBuildProfileEvent,
  };
};

export default useDelayHomeEquityProfile;
