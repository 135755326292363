import { Button, Icon } from '@hometap/htco-components';

import './PricingMatrixHeading.scss';

// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const PricingMatrixHeading = ({ title, iconName, subtitle, buttonProps = {}, badge }) => {
  return (
    <div className="PricingMatrixHeading">
      {badge && <div className="PricingMatrixHeadingBadge">{badge}</div>}
      {iconName && (
        <div className="PricingMatrixHeadingIconContainer">
          <div className="PricingMatrixHeadingIconWrapper">
            <Icon className="PricingMatrixHeadingIcon" name={iconName} />
          </div>
        </div>
      )}
      <h2 className="PricingMatrixHeadingTitle">{title}</h2>
      <p className="PricingMatrixHeadingSubtitle">{subtitle}</p>
      {Object.keys(buttonProps).length > 0 && <Button {...buttonProps} />}
    </div>
  );
};

export default PricingMatrixHeading;
