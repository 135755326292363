// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
const useStackedBarGraph = data => {
  // @ts-expect-error TS(2769): No overload matches this call.
  const valuesTotal = Object.values(data).reduce((acc, { value, hidden }) => (hidden ? acc : acc + Number(value)), 0);

  const formattedData = Object.entries(data)
    .map(([key, data]) => [
      key,
      {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        ...data,
        chartKey: key,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        percent: valuesTotal ? (Number(data.value) / valuesTotal) * 100 : null,
      },
    ])
    .reduce((accum, [k, v]) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      accum[k] = v;
      return accum;
    }, {});

  // @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
  const isSliceHidden = key => {
    return !!(!data[key] || data[key]?.hidden);
  };

  // @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
  const getSliceHeight = (key, chartHeight) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return (chartHeight * formattedData[key].percent) / 100;
  };

  // @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
  const getSliceData = (key, chartHeight) => {
    if (isSliceHidden(key)) {
      return { hidden: true, chartKey: key };
    }

    if (chartHeight) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return { ...formattedData[key], height: getSliceHeight(key, chartHeight) };
    }

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return formattedData[key];
  };

  return { valuesTotal, getSliceData, getSliceHeight, isSliceHidden };
};

export default useStackedBarGraph;
