import { Modal, useBoolState, Button } from '@hometap/htco-components';
import { currency } from 'utils/numbers';

// @ts-expect-error TS(7031): Binding element 'maxInvestmentAmount' implicitly h... Remove this comment to see the full error message
const TrackDetailsMaxInvestmentAmountSubtitle = ({ maxInvestmentAmount, homeValue }) => {
  const isModalOpen = useBoolState();

  const noInvestmentAmountCopyConfig = {
    subtitle: 'We’re sorry, but we’re unable to calculate your maximum Investment amount at this time.',
    modalCopy: {
      triggerCopy: "Here's why.",
      title: 'Why can’t my maximum Investment amount be determined?',
      body: (
        <p>
          Your maximum Investment amount is based on your home’s valuation. Unfortunately, we don’t have sufficient data
          to calculate it yet.
          <br />
          <br />
          Once we have an estimated value for your home, we’ll be able to determine your maximum Investment amount.
        </p>
      ),
    },
  };

  const investmentAmountCopyConfig = {
    subtitle: `Based on current home value of ${currency(homeValue)}.`,
    modalCopy: {
      triggerCopy: 'How are these amounts determined?',
      title: 'How are these amounts determined?',
      body: (
        <p>
          Hometap’s Value Blend automated valuation model (AVM) is software based and combines data from external
          models. This model is used to calculate your home’s value without an in-person appraisal.
          <br />
          <br />
          This value is then used to determine the total amount of potential tappable equity, known as your maximum
          Investment amount. This amount is conditional upon the Investment review process and is subject to change.
        </p>
      ),
    },
  };

  const { subtitle, modalCopy } = maxInvestmentAmount ? investmentAmountCopyConfig : noInvestmentAmountCopyConfig;

  return (
    <span className="TrackDetailsMaxInvestmentAmountSubtitle">
      {subtitle}
      <Button
        className="TrackDetailsMaxInvestmentAmountModalTrigger"
        theme="link"
        // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
        onClick={e => {
          e.stopPropagation();
          isModalOpen.setTrue();
        }}
      >
        {modalCopy.triggerCopy}
      </Button>
      <Modal
        className="TrackDetailsMaxInvestmentAmountModal"
        open={isModalOpen.value}
        target={document.body}
        onClose={isModalOpen.setFalse}
      >
        <h2>{modalCopy.title}</h2>
        {modalCopy.body}
        <Button
          className="TrackDetailsMaxInvestmentAmountModalButton"
          // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
          onClick={e => {
            e.stopPropagation();
            isModalOpen.setFalse();
          }}
        >
          Got it
        </Button>
      </Modal>
    </span>
  );
};
export default TrackDetailsMaxInvestmentAmountSubtitle;
