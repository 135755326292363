import { inRange } from 'lodash';

import { SEASONS } from 'apps/dashboard/constants';

// @ts-expect-error TS(7006): Parameter 'tasks' implicitly has an 'any' type.
export const updateHomeMaintenanceTasks = (tasks, taskNameToUpdate) => {
  // @ts-expect-error TS(7006): Parameter 'season' implicitly has an 'any' type.
  return tasks.map(season => {
    // @ts-expect-error TS(7006): Parameter 'task' implicitly has an 'any' type.
    season.tasks.map(task => {
      if (task.name === taskNameToUpdate) {
        task.complete = !task.complete;
      }
      return task;
    });
    return season;
  });
};

const getCurrentSeason = () => {
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 3.
  const curDayOfYear = Math.floor((Date.now() - Date.parse(new Date().getFullYear(), 0, 0)) / 86400000);
  if (inRange(curDayOfYear, 79, 172)) {
    return SEASONS.SPRING;
  } else if (inRange(curDayOfYear, 172, 265)) {
    return SEASONS.SUMMER;
  } else if (inRange(curDayOfYear, 265, 355)) {
    return SEASONS.FALL;
  }
  return SEASONS.WINTER;
};

export const getCurrentSeasonChecklist = (checklists = []) => {
  const curSeason = getCurrentSeason();
  // @ts-expect-error TS(2339): Property 'season' does not exist on type 'never'.
  return checklists.filter(checklist => checklist?.season === curSeason)?.[0];
};

export const getOutOfSeasonChecklists = (checklists = []) => {
  // Build an array of seasons ordered by their occurance starting with
  // The season that comes NEXT
  const curSeason = getCurrentSeason();
  // @ts-expect-error TS(2339): Property 'season' does not exist on type 'never'.
  const curSeasonOrder = checklists.filter(checklist => checklist?.season === curSeason)?.[0];

  const nextSeasons = checklists.filter(
    // @ts-expect-error TS(2339): Property 'season' does not exist on type 'never'.
    checklist => checklist?.season !== curSeason && checklist?.order > curSeasonOrder.order,
  );

  const prevSeasons = checklists.filter(
    // @ts-expect-error TS(2339): Property 'season' does not exist on type 'never'.
    checklist => checklist?.season !== curSeason && checklist?.order < curSeasonOrder.order,
  );

  return [...nextSeasons, ...prevSeasons];
};

export const getAmountOfTasksCompleted = (tasks = []) => {
  let completeTaskCount = 0;
  // @ts-expect-error TS(2339): Property 'complete' does not exist on type 'never'... Remove this comment to see the full error message
  tasks.forEach(task => task.complete && completeTaskCount++);
  return `${completeTaskCount}/${tasks.length}`;
};
