import { ModalConfirm } from '@hometap/htco-components';

// @ts-expect-error TS(7031): Binding element 'isOpen' implicitly has an 'any' t... Remove this comment to see the full error message
const ApplicantDeleteModal = ({ isOpen, onClose, onConfirm, applicantFirstName }) => {
  const formattedApplicantName = (applicantFirstName && <b>{applicantFirstName}</b>) || 'this applicant';
  return (
    <ModalConfirm
      header="Delete Applicant"
      open={isOpen}
      theme="danger"
      onClose={onClose}
      onConfirm={onConfirm}
      cancelText="No, go back"
      confirmText={<span>Yes, delete {formattedApplicantName}</span>}
    >
      You are about to delete all information for {formattedApplicantName}. This cannot be undone. Are you sure you want
      to proceed?
    </ModalConfirm>
  );
};

export default ApplicantDeleteModal;
