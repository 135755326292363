import ProgressLoadingModal from 'components/modals/ProgressLoadingModal/ProgressLoadingModal';

const loadingModalConfig = {
  title: 'We’re preparing your estimate...',
  footer:
    'Over 50,000 homeowners like you have taken the first step towards getting more from homeownership with Hometap!',
  spinners: [
    { label: 'Analyzing your property' },
    { label: 'Crunching the numbers' },
    { label: 'Working our magic', async: true },
  ],
};

const { title, footer, spinners } = loadingModalConfig;

// @ts-expect-error TS(7031): Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
const PreparingEstimateModal = ({ data, onFinish }) => {
  return (
    <ProgressLoadingModal
      show={true}
      title={title}
      footer={footer}
      spinners={spinners}
      data={data}
      onFinish={onFinish}
    />
  );
};

export default PreparingEstimateModal;
