import cx from 'classnames';

import { Container } from '@hometap/htco-components';
import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';
import DebtList from '../debtList/DebtList';
import DebtBarChart from '../debtBarChart/debtBarChart';
import DebtDisclaimer from '../debtDisclaimer/DebtDisclaimer';

import './TwoColumnDebtChart.scss';

const TwoColumnDebtChart = ({
  // @ts-expect-error TS(7031): Binding element 'isSticky' implicitly has an 'any'... Remove this comment to see the full error message
  isSticky,
  // @ts-expect-error TS(7031): Binding element 'debts' implicitly has an 'any' ty... Remove this comment to see the full error message
  debts,
  // @ts-expect-error TS(7031): Binding element 'onDebtSubmit' implicitly has an '... Remove this comment to see the full error message
  onDebtSubmit,
  // @ts-expect-error TS(7031): Binding element 'onDebtDelete' implicitly has an '... Remove this comment to see the full error message
  onDebtDelete,
  // @ts-expect-error TS(7031): Binding element 'onFinish' implicitly has an 'any'... Remove this comment to see the full error message
  onFinish,
  // @ts-expect-error TS(7031): Binding element 'payOffDate' implicitly has an 'an... Remove this comment to see the full error message
  payOffDate,
  // @ts-expect-error TS(7031): Binding element 'totalBalance' implicitly has an '... Remove this comment to see the full error message
  totalBalance,
  // @ts-expect-error TS(7031): Binding element 'showDisclaimer' implicitly has an... Remove this comment to see the full error message
  showDisclaimer,
}) => {
  return (
    <Container grid={12} className="TwoColumnDebtChartContainer">
      <Container className="TwoColumnDebtChart" grid={12} row justify="center" pad={3}>
        <Container className={cx('TwoColumnDebtChartCol TwoColumnDebtChartLeft', { isSticky })} grid={4} respondAt="md">
          <DebtList debts={debts} onDebtSubmit={onDebtSubmit} onDebtDelete={onDebtDelete} onFinish={onFinish} />
        </Container>
        <Container grid={7} className="TwoColumnDebtChartCol TwoColumnDebtChartRight" respondAt="md">
          <Container className="TwoColumnDebtChartTotalDebtContainer" justify="flex-end" row spacing={0}>
            Total Debt:{' '}
            <strong className="TwoColumnDebtChartTotalDebt">{NUMBER_FORMATS.currency(totalBalance, 0)}</strong>
          </Container>
          <DebtBarChart debts={debts} />
          <Container className="TwoColumnDebtChartPayoff" justify="center">
            <p>At your current payment rate, you will be debt free by</p>
            <h2 className="TwoColumnDebtChartPayoffDate">{payOffDate}</h2>
          </Container>
        </Container>
      </Container>
      {showDisclaimer && (
        <div className="TwoColumnDisclaimer">
          <DebtDisclaimer />
        </div>
      )}
    </Container>
  );
};

export default TwoColumnDebtChart;
