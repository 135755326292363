import { Helmet } from 'react-helmet-async';

import { Button, Icon, MuiTextInput, Tooltip } from '@hometap/htco-components';
import birdhouseImg from 'images/inquiry/birdhouse.png';
import {
  CONTINUE_BUTTON_ID,
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import { ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';
import { useAsyncPatchInquiry } from '../hooks/inquiryMutations';
import './InquiryMortgageBalance.scss';

const yesCTAText = 'Yes, I do';

// @ts-expect-error TS(7031): Binding element 'inquiryId' implicitly has an 'any... Remove this comment to see the full error message
const InquiryMortgageBalance = ({ inquiryId, form, step, onNext }) => {
  const { registerField, handleSubmit, isFormValid, formData } = form;

  const { loading, execute: updateInquiry } = useAsyncPatchInquiry('Failed to update mortgage balance from Inquiry', {
    onSuccess: () => {
      onNext(step + 1);
    },
  });

  const onSubmit = () => {
    // HOOT-1764
    // Here we check if form data has the mortgage_balance
    let { mortgage_balance } = formData;
    // If we don't have the mortgage data we need to set mortgage_balance
    if (mortgage_balance === undefined || mortgage_balance === '') {
      // we are setting it to the variable here because updateFormData isn't working
      // the field isn't showing up in form data for some reason.
      mortgage_balance = 0;
    }
    const updateApi = handleSubmit(() => {
      updateInquiry(inquiryId, { mortgage_balance });
    });
    updateApi();
  };

  const valid = () => {
    const { mortgage_balance } = formData;
    return isFormValid && mortgage_balance && mortgage_balance !== 0;
  };

  const canContinueToTheNextPage = !!valid();
  if (canContinueToTheNextPage) scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
  useCTAChangedNotification(yesCTAText, canContinueToTheNextPage);
  // note: "No" is not styled like the CTA and it mirrors the enablement state of the yes button.
  // since what we're measuring with the cta changed enablement is interaction on this page we don't need to track both.

  return (
    <div className="InquiryMortgageBalance" data-testid="inquiry_mortgage_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.INFO]} />
      <ProgressiveUITemplate>
        {/* @ts-expect-error TS(2741): Property 'cropImage' is missing in type '{ childre... Remove this comment to see the full error message */}
        <ContentPanel className="InquiryMortgageFormPanel">
          <div className="InquiryMortgageHeaderContainer">
            <h1>
              Do you have a mortgage or any other debts on your property?
              <Tooltip content="This will help us prepare the most accurate Investment Estimate possible.">
                <Icon name="question-circle" size="lg" />
              </Tooltip>
            </h1>
          </div>
          <p className="InquiryMortgageDescription">
            If yes, please provide the total balance below. If you own your home outright, skip to the next question.
          </p>
          <MuiTextInput
            label="Enter Total Debt Balance"
            type="number"
            inputMode="decimal"
            width="100%"
            precision={0}
            mask={[
              {
                mask: Number,
                signed: false,
                thousandsSeparator: ',',
              },
            ]}
            unmask="typed"
            startAdornment="$"
            maxLength={11}
            {...registerField(FIELDS.MORTGAGE_BALANCE)}
          />
          <div className="InquiryMortgageButtons">
            <Button id={CONTINUE_BUTTON_ID} disabled={!canContinueToTheNextPage} onClick={onSubmit} loading={loading}>
              {yesCTAText}
            </Button>
            <Button theme="link" onClick={onSubmit} disabled={canContinueToTheNextPage}>
              No, I own my home outright
            </Button>
          </div>
        </ContentPanel>
        <ImageContentPanel imageSource={birdhouseImg} imgAlt="birds in a birdhouse" isCentered={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryMortgageBalance;
