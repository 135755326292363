import useLogAsyncError from 'hooks/useLogAsyncError';

import { ModalConfirm, FormError, useAsync, useBoolState } from '@hometap/htco-components';
import { acceptOffer, declineOffer } from '../data/offerRequests';
import OfferBanner from './OfferBanner';
import { sendInteractionEvent } from 'apps/rundowns/data/rundownRequests';
import { getVersion } from 'utils/env';
import { browserTrack } from 'utils/segment';
import useCurrentUser from 'hooks/useCurrentUser';
import '../Offer.scss';

// @ts-expect-error TS(7006): Parameter 'offer' implicitly has an 'any' type.
const fireHomeownerOutcomeSetSegmentEvent = (offer, track, email) => {
  // 'hometap fee' is required to be input as a cost on the backend if costs are present
  // @ts-expect-error TS(7006): Parameter 'cost' implicitly has an 'any' type.
  const hometapFee = offer.costs?.find(cost => cost.description.toLowerCase() === 'hometap fee');

  browserTrack.homeownerOfferOutcomeSetAcceptedBrowser({
    hometap_fee: hometapFee?.cost_in_dollars || null,
    email: email,
    friendly_id: track.friendly_id,
    amount_gross: offer.amount_requested,
    description: offer.description,
    from_stage: 'offerready',
    id: offer.id,
    outcome: 'accepted',
    to_stage: 'conditionsinreview',
    version_number: getVersion(),
  });
};

// @ts-expect-error TS(7031): Binding element 'trackAsync' implicitly has an 'an... Remove this comment to see the full error message
const OfferBannerController = ({ offer = {}, trackAsync, onAccept }) => {
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const { id } = offer;
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const showDeclineModal = useBoolState(false);
  const showAcceptModal = useBoolState(false);
  const acceptOfferAsync = useAsync(acceptOffer);
  const declineOfferAsync = useAsync(declineOffer);

  useLogAsyncError('Offer Accept Failed', acceptOfferAsync.error);
  useLogAsyncError('Offer Decline Failed', declineOfferAsync.error);

  const errorMessage = (
    <FormError
      standalone
      className="OfferBannerError"
      error="Looks like we’re having some trouble at the moment. Please try again and contact us if the problem persists."
    />
  );

  return (
    <div
      id="offer-banner" // this is used for Glia monitoring and tests
      className="OfferBanner sm_cobrowsing_disabled_field"
    >
      {trackAsync.track && !trackAsync.loading && (
        <OfferBanner
          offer={offer}
          track={trackAsync.track}
          onAccept={showAcceptModal.setTrue}
          onDecline={showDeclineModal.setTrue}
        />
      )}

      <ModalConfirm
        id="accept-offer-modal" // this is used for Glia monitoring and tests
        className="sm_cobrowsing_disabled_field"
        open={showAcceptModal.value}
        header="Confirm your Offer"
        confirmText={acceptOfferAsync.loading ? 'Submitting...' : 'Accept Offer'}
        cancelText="Go back"
        onConfirm={async () => {
          const accepted = await acceptOfferAsync.execute(id);
          if (accepted) {
            await trackAsync.fetch();
            fireHomeownerOutcomeSetSegmentEvent(offer, trackAsync.track, user.email);
            showAcceptModal.setFalse();
            sendInteractionEvent('accept_offer', offer);
            onAccept();
          }
        }}
        onClose={showAcceptModal.setFalse}
      >
        {acceptOfferAsync.error && errorMessage}
        Clicking “Accept Offer” below means that you agree to the terms of the Hometap equity investment. We’ll still
        need to prepare documents and schedule a notary, but we’ll be one step closer to helping you tap into your
        home’s value.
        <br />
      </ModalConfirm>

      <ModalConfirm
        id="decline-offer-modal" // this is used for Glia monitoring and tests
        className="sm_cobrowsing_disabled_field"
        open={showDeclineModal.value}
        theme="danger"
        header="Decline Offer?"
        confirmText={declineOfferAsync.loading || trackAsync.loading ? 'Submitting...' : 'Decline Offer'}
        cancelText="Go back"
        onConfirm={async () => {
          const declined = await declineOfferAsync.execute(id);
          if (declined) {
            await trackAsync.fetch();
            showDeclineModal.setFalse();
            sendInteractionEvent('reject_offer', offer);
          }
        }}
        onClose={() => {
          showDeclineModal.setFalse();
        }}
      >
        {declineOfferAsync.error && errorMessage}
        Clicking <b>Decline Offer</b> will notify your Investment Manager of your decision not to move forward with the
        Investment.
      </ModalConfirm>
    </div>
  );
};

export default OfferBannerController;
