import useSearchParams from 'hooks/useSearchParams';

// @ts-expect-error TS(7006): Parameter 'fields' implicitly has an 'any' type.
const useQueryParamData = fields => {
  const query = useSearchParams();

  const data = {};
  for (const [paramKey, paramValue] of query.entries()) {
    if (fields.includes(paramKey)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[paramKey] = paramValue;
    }
  }

  return data;
};

// @ts-expect-error TS(7006): Parameter 'fields' implicitly has an 'any' type.
const useHomeEquityQueryParamData = fields => {
  const query = useSearchParams();

  const data = {};
  for (const [paramKey, paramValue] of query.entries()) {
    if (fields.includes(paramKey)) {
      if (paramKey === 'liens') {
        const decodedLienTypes = decodeURIComponent(paramValue);
        const lienTypeArray = decodedLienTypes.split(',');
        // @ts-expect-error TS(2339): Property 'liens' does not exist on type '{}'.
        data.liens = lienTypeArray;
      } else {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[paramKey] = paramValue;
      }
    }
  }

  return data;
};

export default useQueryParamData;
export { useHomeEquityQueryParamData };
