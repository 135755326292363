import { Helmet } from 'react-helmet-async';
import ModalPageHeaderLite from './ModalPageHeaderLite';

// TODO: (PAT-430) consolidate modal page layouts
// @ts-expect-error TS(7031): Binding element 'onModalClose' implicitly has an '... Remove this comment to see the full error message
const ModalPageLayoutLite = ({ logoLink, onModalClose, headerContent, children }) => {
  return (
    <>
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} />
      {/* @ts-expect-error TS(2322): Type '{ children: any; onClose: any; }' is not ass... Remove this comment to see the full error message */}
      <ModalPageHeaderLite logoLink={logoLink} onClose={onModalClose}>
        {headerContent}
      </ModalPageHeaderLite>
      <main>{children}</main>
    </>
  );
};

export default ModalPageLayoutLite;
