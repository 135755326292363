import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate, useNavigate } from 'react-router-dom';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import env from 'utils/env';
import { useCurrentHome } from 'hooks/useCurrentHome';
import useUserTracksContext from 'hooks/useUserTracksContext';
import useCurrentUser from 'hooks/useCurrentUser';

import { Loader } from '@hometap/htco-components';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import Footer from 'components/Footer/Footer';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import DynamicContentController from '../dynamic-content-controller/DynamicContentController';
import SmartCardCarousel from '../home-equity-controller/smart-cards/SmartCardCarousel';
import useSmartFacts from '../home-equity-controller/smart-cards/hooks/useSmartFacts';
import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';

const OverviewController = () => {
  useEnableGlia(true);
  useGliaIdentification();

  const navigate = useNavigate();
  const { showOverviewPage, hometapInsightsPlacement } = useFlags();
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  // @ts-expect-error TS(2339): Property 'hasHEDAccessToCurrentHome' does not exis... Remove this comment to see the full error message
  const { home, hasHEDAccessToCurrentHome } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'tracks' does not exist on type '{}'.
  const { tracks, loading: tracksLoading } = useUserTracksContext();
  // @ts-expect-error TS(2339): Property 'displayedHomeValuation' does not exist o... Remove this comment to see the full error message
  const { displayedHomeValuation, isLoading: homeValuationLoading } = useHomeValuation();
  const { smartFacts, smartFactsLoading, hasCompletedSmartCards, handleCompletion } = useSmartFacts();
  const loading = tracksLoading || smartFactsLoading || homeValuationLoading;
  const showSmartCards = smartFacts?.length > 1;
  // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
  const currentHomeTracks = tracks?.filter(track => track?.home.address.business_key === home?.address.business_key);
  const mostRecentTrack = currentHomeTracks?.sort(
    // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
    (a, b) => new Date(b.inquiry_created_date) - new Date(a.inquiry_created_date),
  )?.[0];

  if (loading) return <Loader type="dot-pulse" overlay />;

  // TODO: PAT-984 remove this check once overview page rolled out
  if (showOverviewPage === false && env.isProd()) {
    navigate(
      getNewDashboardUrl(
        !!mostRecentTrack || !hasHEDAccessToCurrentHome
          ? HOMEOWNER_DASHBOARD.INVESTMENTS
          : HOMEOWNER_DASHBOARD.HOME_EQUITY,
      ),
    );
    return <Navigate to={getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS)} />;
  }

  const insightsCarousel = (
    <div
      className={cx('overflow-visible', { 'pt-2': !hometapInsightsPlacement })}
      data-testid="insights-carousel-container"
    >
      {showSmartCards && (
        <SmartCardCarousel
          cards={smartFacts}
          handleCompletion={handleCompletion}
          hasCompleted={hasCompletedSmartCards}
        />
      )}
    </div>
  );

  return (
    // @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
    <PrimaryLayoutGrid>
      <div className="col-span-full" data-testid="overview-container">
        <h2 className="mb-6">Welcome home, {user.first_name}</h2>
        {/* Render insights carousel above/below dynamic content based on flag */}
        {hometapInsightsPlacement && insightsCarousel}
        <DynamicContentController track={mostRecentTrack} homeValuation={displayedHomeValuation} />
        {!hometapInsightsPlacement && insightsCarousel}
        <Footer className="mt-20" theme="light" />
      </div>
    </PrimaryLayoutGrid>
  );
};
export default OverviewController;
