import { Container, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';

import { emailValidator, phoneValidator } from 'utils/validators';

import useApplicantForm from '../../hooks/useApplicantForm';
import { APPLICANT_FORM_FIELD } from '../../constants/formFields';
import useApplicationContext from 'apps/application/hooks/useApplicationContext';

import './ContactInfoForm.scss';
import EmailInputWithValidation from 'components/EmailInputWithValidation/EmailInputWithValidation';

const preferredContactMethodOptions = [
  { label: 'Email', value: 'email' },
  { label: 'Text Message', value: 'sms' },
  { label: 'Phone Call', value: 'phone' },
];

const ContactInfoForm = () => {
  const { registerApplicantField, applicantFormData, handleFieldChange, setErrors } = useApplicantForm();
  // @ts-expect-error TS(2339): Property 'applicants' does not exist on type 'unkn... Remove this comment to see the full error message
  const { applicants } = useApplicationContext();
  const { applicantId } = useParams();

  /**
   * Validates an `invited_email` for an applicant by checking its format and verifying that it is
   * not already in use by another applicant.
   *
   * Ideally this would also validate that Person with `email=invited_email` does not belong to any
   * other applicant as well, but that is not currently implemented. It is extremely rare that this
   * would happen; it would only happen if an invited applicant changed their email, and the primary
   * attempted to add a new co-applicant with that new email. Ultimately the backend will error in
   * that situation upon attempted create/update to the Applicant.
   *
   * @param {string} val - The email address to validate.
   * @returns {boolean|string} - Returns `true` if the email is valid and unique among applicants,
   *                             otherwise returns an error message indicating a duplicate email.
   */
  // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
  const validateEmail = val => {
    // @ts-expect-error TS(7031): Binding element 'invited_email' implicitly has an ... Remove this comment to see the full error message
    const applicantsWithEmail = applicants.filter(({ invited_email, isNew, id }) => {
      const isCurrentApplicantPersisted = !isNew && id === applicantId;
      if (isCurrentApplicantPersisted) {
        return false;
      }
      return !isNew && invited_email.toLowerCase() === val.toLowerCase();
    });

    return emailValidator(val) ?? (applicantsWithEmail?.length && 'An applicant with this email already exists');
  };

  return (
    <div className="ApplicationInfoFormFields">
      <Container row className="ApplicationContactInfoContainer">
        <Container grid="8">
          <EmailInputWithValidation
            name={APPLICANT_FORM_FIELD.invitedEmail}
            className="EmailAddressInput"
            label="Email Address"
            {...registerApplicantField(APPLICANT_FORM_FIELD.invitedEmail)}
            handleFieldChange={handleFieldChange}
            setFieldErrors={setErrors}
            validator={validateEmail}
            disabled={applicantFormData?.isPrimaryApplicant}
            maxLength={254}
            width="100%"
            required
          />
        </Container>
        <Container grid="4" className="ApplicationPhoneNumberInput">
          <MuiTextInput
            required
            label="Phone Number"
            mask="000-000-0000"
            validator={phoneValidator}
            width="100%"
            {...registerApplicantField(APPLICANT_FORM_FIELD.phone)}
          />
        </Container>
      </Container>
      <Container>
        <MuiSelect
          className="PreferredMethodInput"
          label="Preferred Contact Method"
          options={preferredContactMethodOptions}
          width="100%"
          required
          {...registerApplicantField(APPLICANT_FORM_FIELD.preferredContactMethod)}
        />
      </Container>
    </div>
  );
};

export default ContactInfoForm;
