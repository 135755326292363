import React, { useContext, useEffect } from 'react';

import { useAsync } from '@hometap/htco-components';
import { fetchTracks, fetchPendingTracks } from 'apps/dashboard/data';
import useCurrentUser from './useCurrentUser';

const Context = React.createContext({});

// Hook to get the current users tracks, returns "contextData" below
const useUserTracksContext = () => useContext(Context);
export default useUserTracksContext;

// Provider which fetches the current users tracks and passes that down through context.
// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const UserTracksProvider = ({ children }) => {
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const { loading: isTracksLoading, results, error, execute } = useAsync(fetchTracks, { defaultLoading: true });
  const {
    execute: executeFetchPendingTracks,
    results: pendingTracks,
    loading: isPendingTracksLoading,
  } = useAsync(fetchPendingTracks, {
    defaultLoading: true,
  });
  const tracks = results;

  // get tracks
  useEffect(() => {
    if (user) {
      execute();
    }
  }, [user, execute]);

  // get pending tracks
  useEffect(() => {
    if (user) {
      executeFetchPendingTracks();
    }
  }, [user, executeFetchPendingTracks]);

  const contextData = {
    loading: isTracksLoading || isPendingTracksLoading,
    tracks,
    error,
    refetch: execute,
    results: tracks, // just adding here for legacy reference, use tracks for new data.
    pendingTracks,
    fetchPendingTracks: executeFetchPendingTracks,
    execute, // just adding here for legacy reference, use refetch for new data.
    // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
    getTrackById: id => tracks.find(track => track.id === id),
    // @ts-expect-error TS(7006): Parameter 'friendlyId' implicitly has an 'any' typ... Remove this comment to see the full error message
    getTrackByFriendlyId: friendlyId => tracks?.find(track => track.friendly_id === friendlyId),
    // @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
    getTracksByAddressKey: key => {
      // @ts-expect-error TS(7006): Parameter 'track' implicitly has an 'any' type.
      return tracks?.filter(track => track.home?.address?.business_key === key);
    },
    // @ts-expect-error TS(7006): Parameter 'stage' implicitly has an 'any' type.
    getTracksByStage: stage => tracks.filter(track => track.current_stage === stage),
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
