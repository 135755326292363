import { apiNoAuth, apiWithAuth } from 'utils/api';

// These are still authenticated requests but we don't want to redirect if we encounter a 403
// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const fetchTrackApplicant = async trackId =>
  await apiNoAuth.get(`tracks/${trackId}/current-applicant`, { withCredentials: true });

// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const verifySSN = async (trackId, data) =>
  await apiNoAuth.post(`tracks/${trackId}/current-applicant/actions/verify`, data, {
    withCredentials: true,
  });

// @ts-expect-error TS(7006): Parameter 'applicantId' implicitly has an 'any' ty... Remove this comment to see the full error message
export const giveCoApplicantConsent = async (applicantId, data) =>
  await apiWithAuth.v1.post(`applicants/${applicantId}/actions/consent`, data);
