import { useMemo } from 'react';

import { BarChart, Icon } from '@hometap/htco-components';
import { barChartDebtCalculations } from '../../utils/debtCalculations';
import { NUMBER_FORMATS } from 'apps/htdc/utils/formatters';

// @ts-expect-error TS(7031): Binding element 'debts' implicitly has an 'any' ty... Remove this comment to see the full error message
const DebtBarChart = ({ debts, height = 350 }) => {
  // @ts-expect-error TS(7031): Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  const SelectableIcon = ({ name, selectedColor }) => <Icon name={name} color={selectedColor} />;

  const barChartData = useMemo(() => barChartDebtCalculations(debts), [debts]);
  return (
    <BarChart
      height={height}
      isStacked
      // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
      tooltipFormatter={(value, name) => [
        NUMBER_FORMATS.currency(value, 0),
        name.charAt(0).toUpperCase() + name.slice(1),
      ]}
      // @ts-expect-error TS(7006): Parameter 'xTick' implicitly has an 'any' type.
      xTickFormatter={xTick => NUMBER_FORMATS.currency(xTick, 0)}
      chartData={barChartData}
      series={[
        // @ts-expect-error TS(7031): Binding element 'balanceColor' implicitly has an '... Remove this comment to see the full error message
        { label: 'Balance', key: 'balance', color: ({ balanceColor }) => balanceColor },
        // @ts-expect-error TS(7031): Binding element 'interestColor' implicitly has an ... Remove this comment to see the full error message
        { label: 'Interest', key: 'interest', color: ({ interestColor }) => interestColor },
      ]}
      // @ts-expect-error TS(7031): Binding element 'balanceColor' implicitly has an '... Remove this comment to see the full error message
      xAxisMobileTicks={({ balanceColor, icon }, isSelected) => (
        <SelectableIcon name={icon} selectedColor={isSelected ? balanceColor : '#0F172A'} />
      )}
      breaksOn="md"
    />
  );
};

export default DebtBarChart;
