import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie';
import { initTrustpilot } from '../utils/thirdParties';
import useTrackingData from '../../dashboard/hooks/useTrackingData';
import Pixels from './Pixels';

import '@hometap/htco-components/index.css';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Layout = ({ children, className }) => {
  const [, setCookie] = useCookies();
  const trackingData = useTrackingData();

  useEffect(() => {
    initTrustpilot();
    setCookie('ht_utm_data', { trackingData }, { path: '/' });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Pixels />
      <Helmet>
        {/* Trustpilot script */}
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
        <meta name="facebook-domain-verification" content="iv04j9xy7d38kkuv3brx7n7biistv5" />
      </Helmet>

      <main className={className || ''}>{children}</main>
    </div>
  );
};

export default Layout;
