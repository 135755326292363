import PricingMatrix from 'components/PricingMatrix/PricingMatrix';

import CardAccentBanner from 'components/CardAccentBanner/CardAccentBanner';

import { Icon, Tooltip } from '@hometap/htco-components';
import { APPRAISAL_TYPE } from 'utils/constants/appraisalTypes';

// @ts-expect-error TS(7031): Binding element 'onAppraisalTypeClick' implicitly ... Remove this comment to see the full error message
const AppraisalSelectionPricingMatrix = ({ onAppraisalTypeClick, virtualValuationDollars }) => {
  const pricingMatrixRows = [
    { key: 'estimatedValuation', label: 'Estimated valuation' },
    { key: 'typeOfAppraisal', label: 'Type of appraisal' },
    { key: 'turnAroundTime', label: 'Avg. turnaround time' },
    {
      key: 'cost',
      label: (
        <span>
          Appraisal costs{' '}
          <Tooltip action="hover" content="All costs are deducted from Investment amount at signing.">
            <Icon name="info" />
          </Tooltip>
        </span>
      ),
    },
    { key: 'timeToComplete', label: 'Avg. time to complete' },
    { key: 'process', label: 'Process' },
  ];

  const pricingMatrixData = [
    {
      heading: {
        title: 'Virtual appraisal',
        iconName: 'virtual-appraisal',
        subtitle:
          'Often easier and faster than the in-person option, a virtual appraisal can help speed up your Investment.',
        buttonProps: {
          label: 'Choose virtual',
          onClick: () => onAppraisalTypeClick(APPRAISAL_TYPE.VIRTUAL),
        },
      },
      isPreferred: true,
      badge: <CardAccentBanner label="FAST" />,
      estimatedValuation: {
        value: virtualValuationDollars,
        tooltip: 'Known as an AVM, this value is based on data from multiple external sources.',
      },
      typeOfAppraisal: { value: 'Online-only' },
      turnAroundTime: { value: '5-7 business days' },
      cost: { value: '$299' },
      timeToComplete: { value: '30 minutes' },
      process: { value: 'Take photos and send', tooltip: 'Submit photos via a third-party appraiser platform.' },
    },
    {
      heading: {
        title: 'Traditional appraisal',
        subtitle: 'Traditional appraisals can take longer but may be preferred if you recently renovated.',
        iconName: 'traditional-appraisal',
        buttonProps: {
          theme: 'secondary',
          label: 'Choose traditional',
          onClick: () => onAppraisalTypeClick(APPRAISAL_TYPE.TRADITIONAL),
        },
      },
      estimatedValuation: { value: 'To be determined' },
      typeOfAppraisal: { value: 'In-person' },
      turnAroundTime: { value: '3-4 weeks' },
      cost: { value: '$599', tooltip: 'Limited appraisers in the area may affect costs.' },
      timeToComplete: { value: '1-2 hours' },
      process: { value: '3rd-party appraisal' },
    },
  ];
  return <PricingMatrix rows={pricingMatrixRows} data={pricingMatrixData} />;
};

export default AppraisalSelectionPricingMatrix;
