import { Routes, Route } from 'react-router-dom';
import { getInvestmentDashboardUrl } from 'utils/links';
import CoApplicantController from 'apps/co-applicant/CoApplicantController';
import ResourcesTableController from 'apps/resources-table/ResourcesTableController';
import TrackDetailsController from 'apps/track-details/TrackDetailsController';
import QuarterlyStatementController from 'apps/quarterly-statement/QuarterlyStatementController';
import DocumentsUploadController from 'apps/documents/DocumentsUploadController';
import ApplicationController from 'apps/application/ApplicationController';
import AppraisalSelectionController from 'apps/appraisal-selection/AppraisalSelectionController';
import TrackSecondaryLayout from 'components/Layouts/SecondaryLayout/TrackSecondaryLayout';
import CommonLayout from 'components/Layouts/CommonLayout';
import ModalPageLayout from 'components/Layouts/ModalPageLayout/ModalPageLayout';
import { ApplicationProvider } from 'apps/application/hooks/useApplicationContext';
import { ApplicationNavProvider } from 'apps/application/hooks/useApplicationNavContext';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const TrackDetailsRouter = () => {
  return (
    <Routes>
      <Route
        index
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            {/* @ts-expect-error TS(2739): Type '{ children: Element; prevUrl: string; }' is ... Remove this comment to see the full error message */}
            <TrackSecondaryLayout prevUrl={getInvestmentDashboardUrl()}>
              <TrackDetailsController />
            </TrackSecondaryLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="application/*"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            <ApplicationProvider>
              <ApplicationNavProvider>
                <ApplicationController />
              </ApplicationNavProvider>
            </ApplicationProvider>
          </PrivateRoute>
        }
      />

      <Route
        path="documents"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            {/* @ts-expect-error TS(2739): Type '{ children: Element; prevTrackUrl: string; }... Remove this comment to see the full error message */}
            <TrackSecondaryLayout prevTrackUrl="/">
              <ResourcesTableController />
            </TrackSecondaryLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="documents"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            <CommonLayout />
          </PrivateRoute>
        }
      >
        {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
        <Route path=":kind" element={<DocumentsUploadController />} />
      </Route>

      <Route
        path="appraisal-selection"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
            <ModalPageLayout>
              <AppraisalSelectionController />
            </ModalPageLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="co-applicant"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            <CoApplicantController />
          </PrivateRoute>
        }
      />

      <Route
        path="investments/:investmentId/statements/:statementId"
        element={
          // @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message
          <PrivateRoute>
            {/* @ts-expect-error TS(2786): 'QuarterlyStatementController' cannot be used as a... Remove this comment to see the full error message */}
            <QuarterlyStatementController />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default TrackDetailsRouter;
