import cx from 'classnames';

import { Container, Loader, Paper } from '@hometap/htco-components';

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const ReInquiryContentContainer = ({ className, loading, children, pad = 4 }) => {
  return (
    <>
      <Container className={cx('ReInquiryContainer', className)} align="center" space={5}>
        {loading ? (
          <Loader />
        ) : (
          <Paper pad={pad} className="ReInquiryContent">
            {children}
          </Paper>
        )}
      </Container>
    </>
  );
};

export default ReInquiryContentContainer;
