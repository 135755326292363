import { apiWithAuth } from 'utils/api';

// IMPORTANT: Be sure to always call `viewOffer` when a homeowner views a Rundown. This is necessary
// for a legal audit trail.

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const fetchOffer = async id => {
  return await apiWithAuth.v1.get(`/offers/${id}`);
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const acceptOffer = async id => {
  return await apiWithAuth.v1.post(`/offers/${id}/actions/accept`);
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const declineOffer = async id => {
  return await apiWithAuth.v1.post(`/offers/${id}/actions/decline`);
};

// @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
export const viewOffer = async id => {
  await apiWithAuth.v1.post(`/offers/${id}/actions/view/`);
};
