import cx from 'classnames';

import { Helptip } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import './DataList.scss';

// @ts-expect-error TS(7031): Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const DataList = ({ data = [], className }) => {
  return (
    <table className={cx(className, 'DataList')}>
      <tbody>
        {data.map(row => {
          const { label, value, rowClass, helpTip } = row;
          return (
            <tr key={label} className={rowClass}>
              <td>
                <span className="DataListLabel">{label}</span>
                {/* @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment */}
                {helpTip && <Helptip {...helpTip} />}
              </td>
              <td className="DataListValue">{value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataList;

DataList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      rowClass: PropTypes.string,
      helpTip: PropTypes.shape({ text: PropTypes.string }),
    }),
  ),
};
