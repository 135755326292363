import cx from 'classnames';
import CardWrapper from 'components/CardWrapper';
import TrackDetailCardMainContent from 'components/TrackDetailsCard/TrackDetailsCardMainContent';
import HomeValKeyValue from './HomeValKeyValue';

import HomeValueDisclaimer from './HomeValueDisclaimer';

import HomeValueAsOf from './HomeValueAsOf';
import { getCurrentStageConfig } from 'apps/track-details/utils/helpers';
import { getDisclaimerCopy } from 'utils/home';

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const OverviewTrackDetailsCard = ({ track, homeValuation }) => {
  const disclaimer = getDisclaimerCopy({ track, hasTracks: true, homeValuation }).trackDetailsCard;
  const { canShowProgressStepper } = getCurrentStageConfig(track);

  return (
    // @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
    <CardWrapper testId="track-details-card-container">
      <div className={cx({ 'mb-6 md:mb-8': canShowProgressStepper })}>
        <div className="mb-2 flex flex-wrap items-center justify-between gap-1">
          <HomeValKeyValue homeValue={homeValuation.value} />
          {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ date: a... Remove this comment to see the full error message */}
          <HomeValueAsOf date={homeValuation.as_of_date} />
        </div>
        {/* @ts-expect-error TS(2322): Type '{ disclaimer: string; hasTracks: true; }' is... Remove this comment to see the full error message */}
        <HomeValueDisclaimer disclaimer={disclaimer} hasTracks />
      </div>

      {!canShowProgressStepper && <hr className="my-6" />}

      <TrackDetailCardMainContent track={track} />
    </CardWrapper>
  );
};

export default OverviewTrackDetailsCard;
