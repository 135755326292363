import { useRef } from 'react';
import { isEmpty } from 'lodash';

import { useForm } from '@hometap/htco-components';
import { showNotification } from 'utils/toasts';

/**
 * This hook is a wrapper around useForm that will show a toast notification if the form has errors
 * There are situations with hidden errors so this hook is useful for those situations
 * @param {object} defaultValues default values for the form
 * @param {object} options options for useForm
 * @param  {...any} args rest of args we would pass to useForm
 * @returns {object} { handleSubmit, errors, setErrors, hideErrors, registerFied, ...useFormInstance }
 */
// @ts-expect-error TS(7006): Parameter 'defaultValues' implicitly has an 'any' ... Remove this comment to see the full error message
export const useFormWithToastNotifications = (defaultValues, options, ...args) => {
  const shouldUpdateInitialErrors = useRef(false);
  const { handleSubmit, errors, setErrors, registerField, ...useFormInstance } = useForm(
    defaultValues,
    { shouldUpdateInitialErrors: shouldUpdateInitialErrors.current, showInitialErrors: false, ...options },
    ...args,
  );
  const registeredFields = useRef(new Set());

  // @ts-expect-error TS(7006): Parameter 'name' implicitly has an 'any' type.
  const customRegisterField = (name, valueProp) => {
    shouldUpdateInitialErrors.current = false;
    registeredFields.current.add(name);
    return registerField(name, valueProp);
  };

  // @ts-expect-error TS(7006): Parameter 'submitFn' implicitly has an 'any' type.
  const customHandleSubmit = submitFn => () => {
    if (!isEmpty(errors)) {
      // @ts-expect-error TS(2769): No overload matches this call.
      const fieldsWithErrors = Object.keys(errors).reduce((accum, field) => {
        if (errors[field] && !registeredFields.current.has(field)) {
          return [...accum, field];
        }
        return accum;
      }, []);

      if (fieldsWithErrors.length > 0) {
        const description =
          fieldsWithErrors.length > 1
            ? // @ts-expect-error TS(2339): Property 'join' does not exist on type 'string'.
              `There are issues with the following fields: ${fieldsWithErrors.join(', ')}`
            : `There is an issue with the following field: ${fieldsWithErrors[0]}`;
        // @ts-expect-error TS(2345): Argument of type '{ type: string; description: str... Remove this comment to see the full error message
        showNotification({ type: 'error', description });
      }

      console.warn('Form has errors:', errors);
    }

    handleSubmit(submitFn)();
  };

  const hideErrors = () => {
    shouldUpdateInitialErrors.current = true;
  };

  return {
    handleSubmit: customHandleSubmit,
    errors,
    setErrors,
    hideErrors,
    registerField: customRegisterField,
    ...useFormInstance,
  };
};
