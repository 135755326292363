export const SELECTION_OPTIONS = {
  USE_EXISTING: 'useExisting',
  NO_DEBTS: 'noDebts',
};

export const BUILD_PROFILE_SELECTIONS_FIRST_TIME = [
  {
    icon: 'house-value',
    label: 'Add debts manually',
    description: `Select each lien type on your property, provide the details, and we’ll take it from there.`,
    value: SELECTION_OPTIONS.USE_EXISTING,
  },
  {
    icon: 'house-3',
    label: 'There are no debts on my property',
    value: SELECTION_OPTIONS.NO_DEBTS,
  },
];

export const LIEN_TYPE_SELECTIONS = [
  {
    label: 'Mortgage',
    value: 'mortgage_loan',
  },
  {
    label: 'Home equity loan',
    value: 'home_equity_loan',
  },
  {
    label: 'Home equity line of credit',
    value: 'home_equity_line_of_credit',
  },
  {
    label: 'Judgement, tax, or other lien',
    value: 'judgement_tax_or_other',
  },
];

export const FINANCING_OPTIONS = [
  {
    label: 'Home equity loan',
    value: 'home_equity_loan',
  },
  {
    label: 'Home equity line of credit',
    value: 'home_equity_line_of_credit',
  },
];

export const BUILD_PROFILE_SELECTIONS_EXISTING_HEI = [
  {
    icon: 'house-value',
    label: 'Continue to use my existing debts',
    description: 'This will add the debts that were previously entered manually to your profile.',
    value: SELECTION_OPTIONS.USE_EXISTING,
  },
  {
    icon: 'house-3',
    label: 'I only have a Hometap Investment for my property',
    value: SELECTION_OPTIONS.NO_DEBTS,
  },
];

export const BUILD_PROFILE_FIELDS = [
  'step',
  'street',
  'useExisting',
  'noDebts',
  'liens',

  'mortgage_loan_lien_kind',
  'mortgage_loan_lien_kind_other',
  'mortgage_loan_rate',
  'mortgage_loan_last_known_current_balance',
  'mortgage_loan_monthly_payment',

  'home_equity_loan_rate',
  'home_equity_loan_last_known_current_balance',
  'home_equity_loan_monthly_payment',

  'home_equity_line_of_credit_last_known_current_balance',
  'home_equity_line_of_credit_rate',
  'home_equity_line_of_credit_monthly_payment',

  'judgement_tax_or_other_last_known_current_balance',
  'judgement_tax_or_other_monthly_payment',
  'judgement_tax_or_other_rate',
  'add-manually',
];

export const FINANCE_GOALS_FIELDS = [
  'financeOption',

  'home_equity_loan_rate',
  'home_equity_loan_last_known_current_balance',
  'home_equity_loan_monthly_payment',

  'home_equity_line_of_credit_last_known_current_balance',
  'home_equity_line_of_credit_rate',
  'home_equity_line_of_credit_monthly_payment',
];

export const MAX_LIENS = 4;

export const PROFILE_STAGES = {
  BUILD_HOME_PROFILE: 1,
  EDIT_YOUR_PROFILE: 1,
  DEBT_SELECTION_STEP: 2,
  DEBT_INFO_STEP: 3,
  DEBT_CONFIRMATION_STEP: 4,
};

export const FINANCE_OPTION_STAGES = {
  SELECT_FINANCE_OPTION: 1,
  FINANCE_OPTION_INFO: 2,
};

export const PROFILE_INSTANCES = {
  SETUP_PROFILE: 'setup_profile',
  EDIT_PROFILE: 'edit_profile',
  FINANCE_GOALS: 'finance_goals',
};

export const FORM_TITLES = [
  {
    lien_type: 'mortgage_loan',
    title: (
      <>
        Tell us about your <span className="text-green-medium">mortgage</span>
      </>
    ),
    editTitle: (
      <>
        Edit your <span className="text-green-medium">mortgage</span>
      </>
    ),
  },
  {
    lien_type: 'home_equity_loan',
    title: (
      <>
        Tell us about your <span className="text-green-medium">home equity loan</span>
      </>
    ),
    editTitle: (
      <>
        Edit your <span className="text-green-medium">home equity loan</span>
      </>
    ),
    financeOptionTitle: 'Enter home equity loan details',
  },
  {
    lien_type: 'home_equity_line_of_credit',
    title: (
      <>
        Tell us about your <span className="text-green-medium">home equity line of credit</span>
      </>
    ),
    editTitle: (
      <>
        Edit your <span className="text-green-medium">home equity line of credit</span>
      </>
    ),
    financeOptionTitle: 'Enter home equity line of credit details',
  },
  {
    lien_type: 'judgement_tax_or_other',
    title: (
      <>
        Tell us about your home <span className="text-green-medium">judgement, tax, or other</span> lien
      </>
    ),
    editTitle: (
      <>
        Edit your <span className="text-green-medium">home judgement, tax, or other</span> lien
      </>
    ),
  },
];

export const CONFIRM_DEBTS = [
  {
    instance: PROFILE_INSTANCES.SETUP_PROFILE,
    title: "Thanks! Let's review the information you’ve provided.",
    description: 'If everything looks good, click the button below to explore your equity.',
    cta: 'Continue to dashboard',
  },
  {
    instance: PROFILE_INSTANCES.EDIT_PROFILE,
    title: 'Edit your home profile',
    description: 'Capture all debts attached to your property to create the most complete picture of your home equity.',
    cta: 'See my equity',
  },
];
