import { Header, IconButton } from '@hometap/htco-components';
import useTrack from 'hooks/useTrack';
import { useNavigate } from 'react-router-dom';

// @ts-expect-error TS(7031): Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
const ModalPageHeaderLite = ({ logoLink, onClose }) => {
  const { track } = useTrack();
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose(track?.id);
    } else if (track) {
      navigate(`/track/${track.id}`);
    } else {
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      navigate(-1, { replace: true });
    }
  };

  return (
    <div>
      <Header logoLink={logoLink} className="border-none px-0">
        <div className="flex flex-grow justify-end">
          <IconButton
            data-testid="modal-page-close-button"
            className="-mr-1 text-base text-neutral-dark-100"
            icon="icon-close"
            size="2x"
            onClick={handleClose}
          />
        </div>
      </Header>
    </div>
  );
};

export default ModalPageHeaderLite;
