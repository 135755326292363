import CardWrapper from 'components/CardWrapper';
import StackedEquityGraph from './StackedEquityGraph';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { useCurrentHome } from 'hooks/useCurrentHome';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import TrackDetailsCardLinks from 'components/TrackDetailsCard/TrackDetailsCardLinks';
import useTrackDetailsCardData from 'components/TrackDetailsCard/useTrackDetailsCardData';
import { STAGES } from 'apps/dashboard/constants';
import FinancialSnapshotTypography from '../FinancialSnapshotTypography';
import getDisclaimerCopy from 'utils/home';
import { formatDate } from 'utils/date';
import { Content } from 'apps/dashboard/components/home-equity-controller/visx-chart/ValueTooltipInfo';
import HomeValueAsOf from '../HomeValueAsOf';
import HomeValueDisclaimer from '../HomeValueDisclaimer';
import CompleteProfileBanner from '../CompleteProfileBanner';

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const StackedEquityGraphLinks = ({ track }) => {
  const { links } = useTrackDetailsCardData(track);
  const trackDetailsLink = { label: 'View details', link: `/track/${track?.id}/` };
  return (
    <TrackDetailsCardLinks
      label={
        <span className="mt-px inline-block">
          Investment ID: <span className="font-bold">{track?.friendly_id}</span>
        </span>
      }
      links={[trackDetailsLink, ...links]}
    />
  );
};

// @ts-expect-error TS(7031): Binding element 'track' implicitly has an 'any' ty... Remove this comment to see the full error message
const StackedEquityGraphController = ({ track, homeValuation }) => {
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ home:... Remove this comment to see the full error message
  const { home, loading: homeLoading } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'chartDataLoading' does not exist on type... Remove this comment to see the full error message
  const { initialScenario, chartDataLoading } = useEquityScenarioContext();
  // @ts-expect-error TS(2339): Property 'investment' does not exist on type '{}'.
  const { investment, loading: investmentLoading } = useHedInvestmentContext();
  // @ts-expect-error TS(2339): Property 'hed_debts_confirmed' does not exist on t... Remove this comment to see the full error message
  const hasConfirmedHomeDebts = home?.hed_debts_confirmed;
  // @ts-expect-error TS(2339): Property 'annualAppreciation' does not exist on ty... Remove this comment to see the full error message
  const annualAppreciation = initialScenario?.annualAppreciation;
  const isTrackInvested = track?.current_stage === STAGES.INVESTED;
  const showQuickLinks = isTrackInvested && hasConfirmedHomeDebts;
  const isLoading = homeLoading || chartDataLoading || investmentLoading;

  // Get the current year's appreciation data from the scenario. This is to align with data shown in the HED, but we
  // should consider adding some sort of 'current' datasource that would be more precise than yearly data.
  // @ts-expect-error TS(7006): Parameter 'x' implicitly has an 'any' type.
  const currentAppreciation = annualAppreciation?.find(x => x.year === new Date().getFullYear());
  const isInvestmentActive = !!investment && isTrackInvested && currentAppreciation?.hometap_share?.dollars;
  const currentHometapShareDollars = isInvestmentActive ? currentAppreciation.hometap_share.dollars : 0;

  const { stackedEquityGraph, homeValueTooltip, equityTooltip } = getDisclaimerCopy({
    hasTracks: track,
    homeValuation: homeValuation,
    track,
  });

  const homeValueTooltipContent = () => {
    let tooltipContent = homeValueTooltip;
    const readableDate = formatDate(homeValuation?.as_of_date, 'MMMM DD, YYYY');
    let footer = `Updated on ${readableDate}`;

    if (homeValuation?.home_valuation_type === 'SelfReportedHomeValuation') {
      tooltipContent =
        'You’ve submitted your own home value. Please note, manually-submitted home values won’t update automatically.';
      footer = `Submitted on ${readableDate}`;
    }

    if (!tooltipContent) {
      return null;
    }

    return <Content header="Current home value" content={tooltipContent} footer={footer} />;
  };

  const graphData = {
    hometapShare: {
      colorClassName: 'bg-blue-medium-100',
      value: currentHometapShareDollars,
      hidden: !isInvestmentActive,
      showPercent: hasConfirmedHomeDebts,
    },
    equity: {
      colorClassName: 'bg-blue-75',
      // @ts-expect-error TS(2339): Property 'valuations' does not exist on type '{}'.
      value: initialScenario?.valuations?.currentEquity,
      showValue: hasConfirmedHomeDebts,
      showPercent: hasConfirmedHomeDebts,
    },
    debt: {
      colorClassName: 'bg-blue-30',
      // @ts-expect-error TS(2339): Property 'valuations' does not exist on type '{}'.
      value: Math.abs(homeValuation?.value - initialScenario?.valuations?.currentEquity - currentHometapShareDollars),
      showValue: hasConfirmedHomeDebts,
      showPercent: hasConfirmedHomeDebts,
      overlay: true,
    },
  };
  return (
    // @ts-expect-error TS(2739): Type '{ children: Element; loading: any; }' is mis... Remove this comment to see the full error message
    <SectionLoadingWrapper loading={isLoading}>
      {/* @ts-expect-error TS(2741): Property 'testId' is missing in type '{ children: ... Remove this comment to see the full error message */}
      <CardWrapper className={'mb-4 pt-5'}>
        <div className="mb-5 flex flex-row justify-between">
          <FinancialSnapshotTypography />
          <div className="hidden xs:block">
            <HomeValueAsOf className="hidden xs:block" date={homeValuation?.as_of_date} />
          </div>
        </div>
        <StackedEquityGraph
          graphData={graphData}
          homeValue={homeValuation?.value}
          locked={!hasConfirmedHomeDebts}
          legendDisclaimer={stackedEquityGraph}
          equityTooltipContent={equityTooltip}
          homeValueTooltipContent={homeValueTooltipContent()}
        />
        <div className="mt-8 block xs:hidden">
          {/* @ts-expect-error TS(2741): Property 'className' is missing in type '{ date: a... Remove this comment to see the full error message */}
          <HomeValueAsOf date={homeValuation?.as_of_date} />
        </div>
        <div className="mt-3 block xs:hidden">
          <HomeValueDisclaimer disclaimer={stackedEquityGraph} />
        </div>
        {showQuickLinks && <StackedEquityGraphLinks track={track} />}
        {!hasConfirmedHomeDebts && <CompleteProfileBanner />}
      </CardWrapper>
    </SectionLoadingWrapper>
  );
};
export default StackedEquityGraphController;
