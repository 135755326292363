import { useState, useRef } from 'react';
import { useNavigate, useLocation, Navigate, matchPath } from 'react-router-dom';
import { intersection } from 'lodash';
import useTrackingData from 'apps/dashboard/hooks/useTrackingData';
import { useHomeEquityQueryParamData } from 'hooks/useQueryParamData';
import { PROFILE_INSTANCES, PROFILE_STAGES } from 'apps/dashboard/constants/buildYourProfile';
import BuildProfileLandingPage from './BuildProfileLandingPage';
import SelectDebts from './SelectDebts';
import ConfirmDebts from './ConfirmDebts';
import {
  BUILD_PROFILE_FIELDS,
  BUILD_PROFILE_SELECTIONS_FIRST_TIME,
  SELECTION_OPTIONS,
} from 'apps/dashboard/constants/buildYourProfile';
import { useDelayHomeEquityProfile, useEquityScenarioContext } from 'apps/dashboard/hooks';
import {
  buildHomeProfileUrl,
  editHomeProfileUrl,
  getNewHEDUrl,
  getNewDashboardUrl,
  HOMEOWNER_DASHBOARD,
} from 'utils/links';
import useDebtForm from 'apps/dashboard/hooks/useDebtForm';
import ProgressiveFormSlides from '../ProgressiveFormSlides';
import DebtSlider from './DebtSlider';
import useProgressBar from 'hooks/useProgressBar';
import RouterPrompt from 'apps/progressive-inquiry/components/RouterPrompt';
import { ProgressBar } from 'components/ProgressProvider/ProgressProvider';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ModalPageLayoutLite from 'components/Layouts/ModalPageLayoutLite/ModalPageLayoutLite';
import useHouseCanaryLiensAvailable from 'apps/dashboard/hooks/useHouseCanaryLiensAvailable';

const BuildProfileController = () => {
  const LIEN_ORDER = ['mortgage_loan', 'home_equity_loan', 'home_equity_line_of_credit', 'judgement_tax_or_other'];

  const location = useLocation();
  const initialEditConfig = location.state;
  const matchObject = matchPath('dashboard/home-equity/:page', location.pathname);
  const instance =
    matchObject?.params?.page === HOMEOWNER_DASHBOARD.EDIT_PROFILE
      ? PROFILE_INSTANCES.EDIT_PROFILE
      : PROFILE_INSTANCES.SETUP_PROFILE;

  // @ts-expect-error TS(2339): Property 'step' does not exist on type '{}'.
  const { step: initialStage, ...initialFormData } = useHomeEquityQueryParamData(BUILD_PROFILE_FIELDS);
  const trackingData = useTrackingData();
  const navigate = useNavigate();
  const { currentScenario, getEquityScenarioLiensAsync } = useEquityScenarioContext();
  const [curStage, setCurStage] = useState(parseInt(initialStage) || initialEditConfig?.step || 1);
  const { forceSetupProfileLater } = useDelayHomeEquityProfile();

  // Boolean for tracking whether or not the confirmation page should display after editing liens
  const [noConfirm, setNoConfirm] = useState(initialEditConfig?.noConfirm || false);

  // Check if the build profile controller was navigated to with initial config with debt info step. Meaning it was called for single lien edit.
  const isSingleLienEditRef = useRef(
    initialEditConfig?.step === PROFILE_STAGES.DEBT_INFO_STEP && instance === PROFILE_INSTANCES.EDIT_PROFILE,
  );

  // Do not show prompt if we are in single lien edit mode and no confirm screen should be shown
  const showPrompt = 1 < curStage && curStage < 4 && !isSingleLienEditRef.current && !noConfirm;

  // @ts-expect-error TS(2339): Property 'results' does not exist on type '{}'.
  const { results: equityScenarioLiens } = getEquityScenarioLiensAsync;

  const handleClose = () => {
    if (instance === PROFILE_INSTANCES.SETUP_PROFILE) {
      forceSetupProfileLater();
    }

    navigate(
      location.state?.referrer === HOMEOWNER_DASHBOARD.OVERVIEW
        ? getNewDashboardUrl(HOMEOWNER_DASHBOARD.OVERVIEW)
        : getNewHEDUrl(),
    );
  };

  const filteredBuildProfileSelectionsFirstTime = BUILD_PROFILE_SELECTIONS_FIRST_TIME.filter(
    // @ts-expect-error TS(2339): Property 'LINK' does not exist on type '{ USE_EXIS... Remove this comment to see the full error message
    item => item.value !== SELECTION_OPTIONS.LINK,
  ).map(item => {
    if (item.value === SELECTION_OPTIONS.USE_EXISTING) {
      return {
        ...item,
        label: 'Add debts',
      };
    }
    return item;
  });

  const form = {
    ...useDebtForm(equityScenarioLiens, initialFormData, initialEditConfig),
    tracking_data: trackingData,
  };

  const [currentLienIndex, setCurrentLienIndex] = useState(0);

  // @ts-expect-error TS(7006): Parameter 'stage' implicitly has an 'any' type.
  const calculateProgressFromStageAndLiens = (stage, liens, lienIndex, isEdit) => {
    if (stage === PROFILE_STAGES.BUILD_HOME_PROFILE || isEdit) {
      return { hide: true };
    }

    const preDebtsStages = [PROFILE_STAGES.DEBT_SELECTION_STEP];
    const postDebtsStages = [PROFILE_STAGES.DEBT_CONFIRMATION_STEP];
    const steps = preDebtsStages.length + (liens?.length || 1) + postDebtsStages.length;

    let currStep = preDebtsStages.indexOf(stage) + 1;
    if (currStep !== 0) return { currStep, steps };

    currStep = postDebtsStages.indexOf(stage) + 1;
    if (currStep !== 0) return { currStep: currStep + liens.length + preDebtsStages.length, steps };

    return { currStep: preDebtsStages.length + lienIndex + 1, steps };
  };

  useProgressBar(
    // @ts-expect-error TS(2345): Argument of type '{ hide: boolean; currStep?: unde... Remove this comment to see the full error message
    calculateProgressFromStageAndLiens(
      curStage,
      form.formData?.liens,
      currentLienIndex,
      instance === PROFILE_INSTANCES.EDIT_PROFILE,
    ),
  );

  const onDebtSelect = () => {
    const orderedLiens = intersection(LIEN_ORDER, form?.formData?.liens);

    // Set the liens in the order we want the info pages to appear
    form.updateFormData({ liens: orderedLiens, hasOrderedLiens: true });
    setNoConfirm(false);
    setCurStage(PROFILE_STAGES.DEBT_INFO_STEP);
    setCurrentLienIndex(0);
  };

  // @ts-expect-error TS(7006): Parameter 'lienType' implicitly has an 'any' type.
  const onDebtNext = lienType => {
    const selectedLiens = form?.formData?.liens;
    const currentLienIdx = selectedLiens?.indexOf(lienType) ?? 0;
    if (currentLienIdx === selectedLiens?.length - 1 && !noConfirm) {
      return setCurStage(PROFILE_STAGES.DEBT_CONFIRMATION_STEP);
    }

    setCurrentLienIndex(currentLienIdx + 1);
  };

  // @ts-expect-error TS(7006): Parameter 'lien' implicitly has an 'any' type.
  const onSingleLienEdit = lien => {
    // Navigate to the edit page for the lien type
    const url = instance === PROFILE_INSTANCES.EDIT_PROFILE ? editHomeProfileUrl() : buildHomeProfileUrl();
    navigate(url, {
      state: { referrer: location.state?.referrer, lienType: lien.lien_type },
    });
    form.updateFormData({ liens: [lien.lien_type] });
    isSingleLienEditRef.current = true;
    setCurStage(PROFILE_STAGES.DEBT_INFO_STEP);
  };

  const { home } = useCurrentHome();
  const { available: canRedirectToHouseCanaryFlow } = useHouseCanaryLiensAvailable();

  // @ts-expect-error TS2339
  if (instance === PROFILE_INSTANCES.EDIT_PROFILE && home && !home.hed_debts_confirmed) {
    return <Navigate to={getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`)} replace state={location.state} />;
  }

  if (instance === PROFILE_INSTANCES.SETUP_PROFILE) {
    // @ts-expect-error TS2339
    if (home?.hed_debts_confirmed) {
      const nextUrl =
        location.state?.referrer === HOMEOWNER_DASHBOARD.OVERVIEW
          ? getNewDashboardUrl(HOMEOWNER_DASHBOARD.OVERVIEW)
          : getNewHEDUrl();
      return <Navigate to={nextUrl} />;
    }

    if (canRedirectToHouseCanaryFlow) {
      return <Navigate to={getNewHEDUrl(`/${HOMEOWNER_DASHBOARD.ACCEPT_LIENS}`)} replace state={location.state} />;
    }
  }

  const pagesToRender = [
    {
      component:
        instance === PROFILE_INSTANCES.SETUP_PROFILE ? (
          <BuildProfileLandingPage
            // @ts-expect-error TS(2322): Type '{ setCurStage: Dispatch<any>; onNext: (step:... Remove this comment to see the full error message
            setCurStage={setCurStage}
            onNext={setCurStage}
            selections={filteredBuildProfileSelectionsFirstTime}
            onSetupLater={handleClose}
            form={form}
          />
        ) : (
          <ConfirmDebts
            instance={instance}
            setCurStage={setCurStage}
            form={form}
            // @ts-expect-error TS(2322): Type '{ instance: any; setCurStage: Dispatch<any>;... Remove this comment to see the full error message
            liens={currentScenario?.liens}
            onSingleLienEdit={onSingleLienEdit}
            title="Edit your home profile"
          />
        ),
    },
    {
      component: (
        <SelectDebts
          onNext={onDebtSelect}
          onBack={() => setCurStage(PROFILE_STAGES.BUILD_HOME_PROFILE)}
          form={form}
          // @ts-expect-error TS(2322): Type '{ onNext: () => void; onBack: () => void; fo... Remove this comment to see the full error message
          instance={instance}
        />
      ),
    },

    {
      component: (
        <DebtSlider
          isSingleLienEdit={isSingleLienEditRef.current}
          initialEditConfig={initialEditConfig}
          instance={instance}
          noConfirm={noConfirm}
          updateStages={setCurStage}
          form={form}
          onDebtNext={onDebtNext}
        />
      ),
    },
    {
      component: (
        <ConfirmDebts
          setCurStage={setCurStage}
          form={form}
          // @ts-expect-error TS(2322): Type '{ setCurStage: Dispatch<any>; onNext: (step:... Remove this comment to see the full error message
          liens={currentScenario?.liens}
          instance={instance}
          onSingleLienEdit={onSingleLienEdit}
          title="Thanks! Let’s review the information you’ve provided."
          description="If everything looks good, click the button below to explore your equity."
          showCTA
        />
      ),
    },
  ];

  return (
    // @ts-expect-error TS2741
    <ModalPageLayoutLite logoLink={getNewDashboardUrl()} onModalClose={handleClose}>
      <div className="border-0 border-t border-solid border-neutral-light-100">
        <ProgressBar className="relative z-20 -mt-[1px]" />
      </div>
      <RouterPrompt
        when={showPrompt}
        onOK={() => {
          navigate(getNewDashboardUrl());
        }}
      />
      {/*
       // @ts-expect-error TS2322 */}
      <ProgressiveFormSlides currentSlide={pagesToRender[curStage - 1].component} shouldShowPrompt={showPrompt} />
    </ModalPageLayoutLite>
  );
};

export default BuildProfileController;
