// ------------------------------
// Trustpilot
// ------------------------------

export const initTrustpilot = () => {
  const [widget] = document.getElementsByClassName('trustpilot-widget');
  loadTrustPilotElement(widget);
  // Since iframe events don't bubble up this is
  // how we can integrate segment tracking with trustpilot
  // window.addEventListener('blur', trackIframeClick);
};

// @ts-expect-error TS(7006): Parameter 'node' implicitly has an 'any' type.
export const loadTrustPilotElement = node => {
  // @ts-expect-error TS(2339): Property 'Trustpilot' does not exist on type 'Wind... Remove this comment to see the full error message
  if (window.Trustpilot && node) {
    // @ts-expect-error TS(2339): Property 'Trustpilot' does not exist on type 'Wind... Remove this comment to see the full error message
    window.Trustpilot.loadFromElement(node, true);
  }
};
