import useCurrentUser from 'hooks/useCurrentUser';

import { Button } from '@hometap/htco-components';
import './InquiryLocationDQReengagementHED.scss';

const InquiryLocationReengagementHED = () => {
  // @ts-expect-error TS(2339): Property 'user' does not exist on type '{}'.
  const { user } = useCurrentUser();
  const futureHomeWorthYear = new Date().getFullYear() + 10;
  return (
    <div className="InquiryLocationReengagementHED">
      <h1 className="DQHeader" data-testid="dq-header">
        What could your home be worth in {futureHomeWorthYear}?
      </h1>
      <p className="InquiryDQBody" data-testid="inquiry-dq-row-1">
        To see your estimated home value, sign up for the Home Equity Dashboard — a free tool that helps you understand
        and make the most of your home finances.
      </p>
      <div className="InquiryLocationReengagementHEDButtons">
        <Button href={user ? '/dashboard' : '/equity-planner/onboard/address'}>Get your forecast</Button>
        {!user && (
          <Button theme="secondary" href="https://hometap.com/">
            Go home
          </Button>
        )}
      </div>
    </div>
  );
};

export default InquiryLocationReengagementHED;
