import { Modal, Button } from '@hometap/htco-components';
import './InformationalTodoModal.scss';

const InformationalTodoModal = ({
  // @ts-expect-error TS(7031): Binding element 'todoHeader' implicitly has an 'an... Remove this comment to see the full error message
  todoHeader,
  // @ts-expect-error TS(7031): Binding element 'todoDescription' implicitly has a... Remove this comment to see the full error message
  todoDescription,
  // @ts-expect-error TS(7031): Binding element 'informationalTodoModalOpen' impli... Remove this comment to see the full error message
  informationalTodoModalOpen,
  // @ts-expect-error TS(7031): Binding element 'setInformationalTodoModalOpen' im... Remove this comment to see the full error message
  setInformationalTodoModalOpen,
  // @ts-expect-error TS(7031): Binding element 'completeInformationalTodo' implic... Remove this comment to see the full error message
  completeInformationalTodo,
  // @ts-expect-error TS(7031): Binding element 'onComplete' implicitly has an 'an... Remove this comment to see the full error message
  onComplete,
  // @ts-expect-error TS(7031): Binding element 'onDismiss' implicitly has an 'any... Remove this comment to see the full error message
  onDismiss,
}) => {
  /**
   * This Modal is used for informational todos, where the User has to confirm that they have read the information,
   * but not take any extra action via the todo itself.
   */
  return (
    <Modal
      className="InformationalTodoModal"
      data-testid="InformationalTodoModal"
      open={informationalTodoModalOpen}
      onClose={() => {
        onDismiss?.();
        setInformationalTodoModalOpen(false);
      }}
    >
      <div className="InformationalTodoModalPopUp">
        <h3 className="InformationalTodoModalHeader">{todoHeader}</h3>
        <p>{todoDescription}</p>
        <p>If you have any questions, reach out to your Investment Manager.</p>
        <div className="InformationalTodoModalButtonContainer">
          <Button
            className="InformationalTodoModalButton"
            onClick={() => {
              onComplete?.();
              completeInformationalTodo();
            }}
            data-testid="complete-informational-todo"
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InformationalTodoModal;
