import { useWindowSize } from '@hometap/htco-components';

import './TrackDetailsSidebar.scss';

// @ts-expect-error TS(7031): Binding element 'topContent' implicitly has an 'an... Remove this comment to see the full error message
const TrackDetailsSidebar = ({ topContent, middleContent, bottomContent }) => {
  const { isScreenSize } = useWindowSize();

  return isScreenSize('sm') ? (
    <div className="TrackDetailsSidebar TrackDetailsSidebarMobile">
      {topContent}
      {middleContent}
      {bottomContent}
    </div>
  ) : (
    <div className="TrackDetailsSidebar TrackDetailsSidebarFull">
      <div className="TrackDetailsSidebarTop">{topContent}</div>
      <div className="TrackDetailsSidebarMiddle">{middleContent}</div>
      <div className="TrackDetailsSidebarBottom">{bottomContent}</div>
    </div>
  );
};

export default TrackDetailsSidebar;
