import { NotFoundBlock } from '@hometap/htco-components';

// @ts-expect-error TS(7031): Binding element 'error' implicitly has an 'any' ty... Remove this comment to see the full error message
const ExceededVerificationAttempts = ({ error }) => {
  return (
    <NotFoundBlock
      error=""
      className="CoApplicantExceededVerification"
      title="Whoops! Those details don’t match the ones we have on file for you."
    >
      <p>
        Please give us a call at {error?.response?.data?.reviewer_phone || '(855) 223-3144'} so we can straighten this
        out.
      </p>
    </NotFoundBlock>
  );
};

export default ExceededVerificationAttempts;
