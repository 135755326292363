import { useState, useEffect } from 'react';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useSearchParams from 'hooks/useSearchParams';

import { useBoolState } from '@hometap/htco-components';
import { PROPERTY_STORAGE_KEY } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import {
  PROPERTY_FORM_FIELDS,
  HOMEOWNER_FORM_FIELDS,
  INQUIRY_CREATED_TRACKING_PROPERTIES,
  INQUIRY_CONVERTED_TRACKING_PROPERTIES,
  INQUIRY_CONVERTED_PREFIX_PROPERTIES,
  INQUIRY_CREATED_PREFIX_PROPERTIES,
} from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import { COOKIES } from 'apps/dashboard/constants';
import formatCookieDataForApi from 'apps/progressive-inquiry/utils/formatCookieDataForApi';
import { getNewInquiryUrl } from 'utils/links';
import { parseDataToAddressString } from 'utils/googleAutocompleteAddress';

const FORM_FIELDS = [...PROPERTY_FORM_FIELDS, ...HOMEOWNER_FORM_FIELDS];

// @ts-expect-error TS(7006): Parameter 'user' implicitly has an 'any' type.
const useInitialInquiryData = user => {
  const [initialInquiryData, setInitialInquiryData] = useState({});
  const [inquiryCreatedTrackingData, setInquiryCreatedTrackingData] = useState({});
  const [inquiryConvertedTrackingData, setInquiryConvertedTrackingData] = useState({});
  const [sessionPropertyData, setSessionPropertyData] = useSessionStorage(PROPERTY_STORAGE_KEY, {});
  const [reinquiryData, setReinquiryData] = useState({});
  const [initialPropertyData, setInitialPropertyData] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies([
    'ht_fit_quiz_data',
    'ht_utm_data',
    COOKIES.DISABLE_PROPERTY_DATA,
    COOKIES.DISABLE_PROPERTY_MODAL,
  ]);
  const disablePropertyInputs = useBoolState(cookies.ht_disable_property_data === 'true');
  const disablePropertyModal = useBoolState(cookies.ht_disable_property_modal === 'true');
  const fitQuizCookies = cookies?.ht_fit_quiz_data;
  const htUtmDataCookie = cookies?.ht_utm_data; // tracking data
  const query = useSearchParams();
  const navigate = useNavigate();
  const isLoggedIn = !!user;

  // Gather initial data passed to the inquiry prioritizing by session data, query params, and then cookie data.
  // Should potentially think about conflicting data in the future. i.e. a partial address given by cookie and a different partial address given by params.
  useEffect(() => {
    let initialData = { ...fitQuizCookies };
    const utmCookieData = { ...htUtmDataCookie };
    const inquiryCreationData = formatCookieDataForApi(
      // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      INQUIRY_CREATED_TRACKING_PROPERTIES,
      { ...utmCookieData },
      INQUIRY_CREATED_PREFIX_PROPERTIES,
      'tracking_data_',
    );
    const inquiryConvertedData = formatCookieDataForApi(
      // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      INQUIRY_CONVERTED_TRACKING_PROPERTIES,
      { ...utmCookieData },
      INQUIRY_CONVERTED_PREFIX_PROPERTIES,
      'track_',
    );

    // if cookie is set to disable property data, remove cookie
    if (cookies?.ht_disable_property_data) {
      removeCookie('ht_disable_property_data', { path: '/' });
    }

    // if cookie is set to disable property modal, remove cookie
    if (cookies?.ht_disable_property_modal) {
      removeCookie(COOKIES.DISABLE_PROPERTY_MODAL, { path: '/' });
    }

    // check params
    for (const [paramKey, paramValue] of query.entries()) {
      if (FORM_FIELDS.includes(paramKey)) {
        initialData[paramKey] = paramValue;
      }

      if (INQUIRY_CREATED_TRACKING_PROPERTIES.includes(paramKey)) {
        if (INQUIRY_CREATED_PREFIX_PROPERTIES.includes(paramKey)) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          inquiryCreationData[`tracking_data_${paramKey}`] = paramValue;
        } else {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          inquiryCreationData[paramKey] = paramValue;
        }
        utmCookieData[paramKey] = paramValue;
      }

      if (INQUIRY_CONVERTED_TRACKING_PROPERTIES.includes(paramKey)) {
        if (INQUIRY_CONVERTED_TRACKING_PROPERTIES.includes(paramKey)) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          inquiryConvertedData[`tracking_data_${paramKey}`] = paramValue;
        } else {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          inquiryConvertedData[paramKey] = paramValue;
        }
        utmCookieData[paramKey] = paramValue;
      }
    }

    // check local session storage
    if (sessionPropertyData) {
      initialData = { ...initialData, ...sessionPropertyData };
      if (sessionPropertyData.isNewProperty) {
        const blankData = {
          address: '',
          primary_use_case: '',
          when_interested: '',
        };
        for (const key of PROPERTY_FORM_FIELDS.keys()) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          blankData[PROPERTY_FORM_FIELDS[key]] = '';
        }
        setReinquiryData({ isNewProperty: true, ...blankData });
      }
      // We want to check if a user is logged in before prefilling the form with any session data
      else if (isLoggedIn) {
        const { street, city, state, zip_code } = sessionPropertyData;
        if (
          street &&
          city &&
          state &&
          zip_code &&
          (user?.email === sessionPropertyData.email || user?.email === sessionPropertyData.coapplicant)
        ) {
          setReinquiryData({ address: parseDataToAddressString(sessionPropertyData), ...sessionPropertyData });
        }
      }
    }

    // set initial property data
    const initialPropertyDataObj = PROPERTY_FORM_FIELDS.reduce((propertyObj, field) => {
      if (Object.prototype.hasOwnProperty.call(initialData, field)) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        propertyObj[field] = initialData[field];
      }
      return propertyObj;
    }, {});

    setInitialPropertyData(initialPropertyDataObj);
    setCookie('ht_utm_data', { ...utmCookieData }, { path: '/' });
    setInitialInquiryData(initialData);
    setInquiryCreatedTrackingData(inquiryCreationData);
    setInquiryConvertedTrackingData(inquiryConvertedData);
    // eslint-disable-next-line
  }, [
    sessionPropertyData.city,
    sessionPropertyData.street,
    sessionPropertyData.state,
    sessionPropertyData.zip_code,
    sessionPropertyData.property_type,
    sessionPropertyData.residence_type,
  ]);

  const initialHomeownerData = HOMEOWNER_FORM_FIELDS.reduce((homeownerObj, field) => {
    if (Object.prototype.hasOwnProperty.call(initialInquiryData, field)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      homeownerObj[field] = initialInquiryData[field];
    }
    return homeownerObj;
  }, {});

  // @ts-expect-error TS(7006): Parameter 'home' implicitly has an 'any' type.
  const setAndRedirectWithSessionPropertyData = home => {
    const { address, property_type } = home;
    setSessionPropertyData({ ...address, property_type });
    return navigate(getNewInquiryUrl());
  };

  return {
    initialPropertyData,
    setInitialPropertyData,
    sessionPropertyData,
    setSessionPropertyData,
    disablePropertyInputs,
    disablePropertyModal,
    setAndRedirectWithSessionPropertyData,
    initialHomeownerData,
    inquiryCreatedTrackingData,
    inquiryConvertedTrackingData,
    reinquiryData,
  };
};

export default useInitialInquiryData;
