import { useEffect } from 'react';
import { Route, Navigate, createBrowserRouter } from 'react-router-dom';

import { NotFoundBlock } from '@hometap/htco-components';
import { getDashboardUrl, getLogoutUrl, getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { clearStorage } from 'components/LogOutLink/LogOutLink';
import RundownController from './apps/rundowns/RundownController';
import PartnerInquiryController from './apps/partner/PartnerInquiryController';
import OfferController from './apps/offers/OfferController';
import SettlementCalculatorController from './apps/settlement/SettlementCalculatorController';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import ReInquiryRouter from 'apps/re-inquiry/ReInquiryRouter';
import InquiryAdverseActionRouter from 'apps/progressive-inquiry/components/outcome/adverseAction/InquiryAdverseActionRouter';
import TrackDetailsRouter from 'components/TrackDetailsRouter/TrackDetailsRouter';
import { TrackProvider } from 'hooks/useTrack';
import FitQuiz from './apps/htdc/fit-quiz/FitQuiz';
import FitQuizResults from './apps/htdc/fit-quiz/FitQuizResults';
import DebtCalculator from 'apps/htdc/debt-calculator/DebtCalculator';
import DashboardProfile from 'apps/dashboard/components/dashboard-profile/DashboardProfile';
import DashboardSupport from 'apps/dashboard/components/dashboard-support-page/DashboardSupport';
import EquityPlannerRouter from 'apps/dashboard/components/equity-planner/EquityPlannerRouter';
import CommonLayout from 'components/Layouts/CommonLayout';
import ProgressiveInquiryController from 'apps/progressive-inquiry/ProgressiveInquiryController';
import ModalPageLayout from 'components/Layouts/ModalPageLayout/ModalPageLayout';
import { HomeValuationProvider } from 'apps/dashboard/hooks/useHomeValuation';
import { PrimaryNavProvider } from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';
import { HedInvestmentProvider } from 'apps/dashboard/hooks/useHedInvestmentContext';
import { EquityScenarioContextProvider } from 'apps/dashboard/hooks/useEquityScenarioContext';
import ResourcesPost from 'apps/dashboard/components/resources-controller/post/ResourcesPost';
import SecondaryLayout from 'components/Layouts/SecondaryLayout/SecondaryLayout';
import TrackDetailsProviderWrapper from 'components/TrackDetailsRouter/TrackDetailsProviderWrapper';
import TrackSecondaryLayout from 'components/Layouts/SecondaryLayout/TrackSecondaryLayout';
import RundownRedirector from 'components/RundownRedirector/RundownRedirector';
import { Outlet, RouterProvider, Routes, useLocation } from 'react-router';
import { browserTrackPage } from 'utils/segment';
import { useLogGliaMissingClassToSentry } from 'hooks/useGlia';
import FriendBuy404 from 'components/FriendBuy404/FriendBuy404';
import Configurator from 'components/Configurator/Configurator';
import useAppRedirect from 'hooks/useAppRedirect';
import { HouseCanaryLiensProvider } from 'apps/dashboard/hooks/useHouseCanaryLiens';
import ProgressProvider from 'components/ProgressProvider/ProgressProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHEDRedirect from 'hooks/useHEDRedirect';
import PrimaryLayout from 'components/Layouts/PrimaryLayout/PrimaryLayout';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import MissingHomeValuationModal from 'apps/dashboard/components/MissingHomeValuationModal';
import HouseCanaryLiensController from 'apps/dashboard/components/dashboard-house-canary-liens/HouseCanaryLiensController';
import BuildProfileController from 'apps/dashboard/components/dashboard-build-profile/BuildProfileController';
import OverviewController from 'apps/dashboard/components/overview-controller/OverviewController';
import InvestmentController from 'apps/dashboard/components/investment-controller/InvestmentController';
import HomeEquityController from 'apps/dashboard/components/home-equity-controller/HomeEquityController';
import FeaturedOffersController from 'apps/dashboard/components/featured-offers-controller/FeaturedOffersController';
import ResourcesController from 'apps/dashboard/components/resources-controller/mainTab/ResourcesController';
import MaintenanceController from 'apps/dashboard/components/maintenance-controller/MaintenanceController';
import DashboardGoalsPage from 'apps/dashboard/components/dashboard-goals-page/DashboardGoalsPage';
import FinanceOptionsController from 'apps/dashboard/components/home-equity-controller/equity-finance/FinanceOptionsController';
import FinanceGoalsController from 'apps/dashboard/components/dashboard-build-profile/finance-goals/FinanceGoalsController';
import EquityRenovationController from 'apps/dashboard/components/home-equity-controller/equity-renovations/EquityRenovationController';

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const DashboardProviders = ({ children }) => (
  <HomeValuationProvider>
    <HedInvestmentProvider>
      <PrimaryNavProvider>{children}</PrimaryNavProvider>
    </HedInvestmentProvider>
  </HomeValuationProvider>
);

// @ts-expect-error TS(7031): Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const PrivateRouteWithProviders = ({ children }) => (
  <DashboardProviders>
    {/* @ts-expect-error TS(2741): Property 'redirect' is missing in type '{ children... Remove this comment to see the full error message */}
    <PrivateRoute>{children}</PrivateRoute>
  </DashboardProviders>
);

const DashboardRouter = () => {
  useHEDRedirect();

  return (
    <EquityScenarioContextProvider>
      <HouseCanaryLiensProvider>
        <ProgressProvider>
          <PrimaryNavProvider>
            <Outlet />
          </PrimaryNavProvider>
        </ProgressProvider>
      </HouseCanaryLiensProvider>
    </EquityScenarioContextProvider>
  );
};

const RootRoute = () => {
  const location = useLocation();
  const { showOverviewPage } = useFlags();

  useEffect(() => {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    browserTrackPage();
  }, [location]);

  useAppRedirect();
  useLogGliaMissingClassToSentry();
  return (
    <>
      <Configurator />
      <Routes>
        <Route
          path="dashboard/resources/:postTitle"
          element={
            <PrivateRouteWithProviders>
              {/* @ts-expect-error TS(2739): Type '{ children: Element; prevUrl: string; }' is ... Remove this comment to see the full error message */}
              <SecondaryLayout prevUrl={`/${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}`}>
                <ResourcesPost />
              </SecondaryLayout>
            </PrivateRouteWithProviders>
          }
        />

        <Route
          path="profile"
          element={
            <PrivateRouteWithProviders>
              {/* @ts-expect-error TS(2739): Type '{ children: Element; prevUrl: string; allowA... Remove this comment to see the full error message */}
              <SecondaryLayout prevUrl={`/${HOMEOWNER_DASHBOARD.ROOT}`} allowAccountDropdown={true}>
                <DashboardProfile />
              </SecondaryLayout>
            </PrivateRouteWithProviders>
          }
        />

        <Route
          path="support"
          element={
            <PrivateRouteWithProviders>
              {/* @ts-expect-error TS(2739): Type '{ children: Element; prevUrl: string; allowA... Remove this comment to see the full error message */}
              <SecondaryLayout prevUrl={HOMEOWNER_DASHBOARD.ROOT} allowAccountDropdown={true}>
                <DashboardSupport />
              </SecondaryLayout>
            </PrivateRouteWithProviders>
          }
        />

        {/* Rundown Redirector */}
        <Route
          path="my-estimate/:friendlyId"
          element={
            <PrivateRouteWithProviders>
              <RundownRedirector />
            </PrivateRouteWithProviders>
          }
        />

        <Route
          path="investments/:id/settlement-calculator"
          element={
            <PrivateRouteWithProviders>
              <TrackProvider>
                {/* @ts-expect-error TS(2739): Type '{ children: Element; prevTrackUrl: string; }... Remove this comment to see the full error message */}
                <TrackSecondaryLayout prevTrackUrl="/">
                  <SettlementCalculatorController />
                </TrackSecondaryLayout>
              </TrackProvider>
            </PrivateRouteWithProviders>
          }
        />

        <Route
          path="re-inquiry/*"
          element={
            <PrivateRouteWithProviders>
              {/* @ts-expect-error TS(2739): Type '{ children: Element; prevUrl: string; }' is ... Remove this comment to see the full error message */}
              <SecondaryLayout prevUrl={`/${HOMEOWNER_DASHBOARD.ROOT}`}>
                <ReInquiryRouter />
              </SecondaryLayout>
            </PrivateRouteWithProviders>
          }
        />

        {/* Rundown Routes */}
        <Route
          path="rundown/:id"
          element={
            <PrivateRouteWithProviders>
              <TrackProvider>
                {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
                <ModalPageLayout>
                  <RundownController />
                </ModalPageLayout>
              </TrackProvider>
            </PrivateRouteWithProviders>
          }
        />

        <Route
          path="offer/:id?"
          element={
            <PrivateRouteWithProviders>
              <TrackProvider>
                {/* @ts-expect-error TS(2739): Type '{ children: Element; }' is missing the follo... Remove this comment to see the full error message */}
                <ModalPageLayout>
                  <OfferController />
                </ModalPageLayout>
              </TrackProvider>
            </PrivateRouteWithProviders>
          }
        />

        {/* Track Routes */}
        <Route
          path="track/:trackId/*"
          element={
            <PrivateRouteWithProviders>
              <TrackDetailsProviderWrapper>
                <TrackDetailsRouter />
              </TrackDetailsProviderWrapper>
            </PrivateRouteWithProviders>
          }
        />

        {/* Dashboard Routes */}
        <Route
          path="dashboard"
          element={
            <DashboardProviders>
              <DashboardRouter />
            </DashboardProviders>
          }
        >
          <Route path={HOMEOWNER_DASHBOARD.MISSING_HOME_VALUATION} element={<MissingHomeValuationModal />} />
          <Route path="home-equity">
            <Route
              path={HOMEOWNER_DASHBOARD.ACCEPT_LIENS}
              element={<HouseCanaryLiensController referrer={location.state?.referrer} />}
            />
            <Route path={HOMEOWNER_DASHBOARD.BUILD_PROFILE} element={<BuildProfileController />} />
            <Route path={HOMEOWNER_DASHBOARD.EDIT_PROFILE} element={<BuildProfileController />} />
            <Route
              path="explore-financing"
              element={
                // @ts-expect-error TS2741
                <PrivateRoute>
                  <FinanceGoalsController initialEditConfig={location.state} />
                </PrivateRoute>
              }
            />
            <Route
              element={
                // @ts-expect-error TS2741
                <PrivateRoute>
                  {/* @ts-expect-error TS2559 */}
                  <PrimaryLayout>
                    <Outlet />
                  </PrimaryLayout>
                </PrivateRoute>
              }
            >
              <Route index element={<HomeEquityController />} />
              <Route path="renovations/:project?/:renovationId?" element={<EquityRenovationController />} />
              <Route
                path="goals"
                element={
                  // @ts-expect-error TS(2741): Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
                  <PrimaryLayoutGrid>
                    <DashboardGoalsPage />
                  </PrimaryLayoutGrid>
                }
              />
              <Route path="finance" element={<FinanceOptionsController />} />
            </Route>
          </Route>
          <Route
            element={
              // @ts-expect-error TS2741
              <PrivateRoute>
                {/* @ts-expect-error TS2559 */}
                <PrimaryLayout>
                  <Outlet />
                </PrimaryLayout>
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <Navigate
                  to={getNewDashboardUrl(
                    showOverviewPage ? HOMEOWNER_DASHBOARD.OVERVIEW : HOMEOWNER_DASHBOARD.INVESTMENTS,
                  )}
                />
              }
            />
            <Route path="overview" element={<OverviewController />} />
            <Route path="investments" element={<InvestmentController />} />
            <Route
              path="maintenance"
              element={
                // @ts-expect-error TS2741
                <PrimaryLayoutGrid>
                  <MaintenanceController />
                </PrimaryLayoutGrid>
              }
            />
            <Route path="featured-offers" element={<FeaturedOffersController />} />
            <Route path="resources" element={<ResourcesController />} />
          </Route>
        </Route>

        {/* Root Routes */}
        <Route path="/" element={<CommonLayout />}>
          {/* @ts-expect-error TS(2322): Type '{ to: string; replace: true; onClick: () => ... Remove this comment to see the full error message */}
          <Route path="logout" element={<Navigate to={getLogoutUrl()} replace onClick={() => clearStorage()} />} />
          <Route path="inquiries/:id/convert" element={<PartnerInquiryController />} />
          <Route path="inquiry/*" element={<ProgressiveInquiryController />} />
          <Route path="adverse-action/*" element={<InquiryAdverseActionRouter />} />
          <Route path="equity-planner/*" element={<EquityPlannerRouter />} />
          <Route path="fit-quiz-results" element={<FitQuizResults />} />
          <Route path="fit-quiz" element={<FitQuiz />} />
          <Route path="debt-calculator" element={<DebtCalculator />} />
          <Route path="refer-a-friend-hed" element={<FriendBuy404 />} />
          <Route path="refer-a-friend" element={<FriendBuy404 />} />
          <Route path="friends" element={<FriendBuy404 />} />
          <Route index element={<Navigate to={`${getDashboardUrl()}${window.location.search}`} replace />} />
          <Route
            path="*"
            element={
              <div style={{ paddingTop: '50px' }}>
                <NotFoundBlock buttonLink="/" />
              </div>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

const router = createBrowserRouter([{ path: '*', element: <RootRoute /> }]);
const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
