import { browserIdentify, browserTrack } from 'utils/segment';

// @ts-expect-error TS(7031): Binding element 'eventData' implicitly has an 'any... Remove this comment to see the full error message
export const trackDebtData = ({ eventData, userProperties }) => {
  browserIdentify(undefined, {
    event: '',
    traits: {
      ...userProperties,
    },
  });
  return browserTrack.calculatorInteractionOccurred({
    calculator_name: 'cost of debt',
    ...eventData,
  });
};

// @ts-expect-error TS(7031): Binding element 'typeOfDebt' implicitly has an 'an... Remove this comment to see the full error message
export const trackAddDebt = ({ typeOfDebt, balance, interestRate, monthlyPayment, debts }) => {
  // @ts-expect-error TS(2345): Argument of type '{ eventData: { interaction_type:... Remove this comment to see the full error message
  return trackDebtData({
    eventData: {
      interaction_type: 'add debt',
      debt_type: typeOfDebt,
      debt_balance: balance,
      debt_rate: interestRate,
      debt_payment: monthlyPayment,
      number_of_debts: debts.length + 1,
    },
  });
};

// @ts-expect-error TS(7031): Binding element 'typeOfDebt' implicitly has an 'an... Remove this comment to see the full error message
export const trackRemoveDebt = ({ typeOfDebt, balance, interestRate, monthlyPayment, debts }) => {
  // @ts-expect-error TS(2345): Argument of type '{ eventData: { interaction_type:... Remove this comment to see the full error message
  return trackDebtData({
    eventData: {
      interaction_type: 'remove debt',
      debt_type: typeOfDebt,
      debt_balance: balance,
      debt_rate: interestRate,
      debt_payment: monthlyPayment,
      number_of_debts: debts.length - 1,
    },
  });
};

export const trackFinishClick = ({
  // @ts-expect-error TS(7031): Binding element 'debts' implicitly has an 'any' ty... Remove this comment to see the full error message
  debts,
  // @ts-expect-error TS(7031): Binding element 'totalInterestPayments' implicitly... Remove this comment to see the full error message
  totalInterestPayments,
  // @ts-expect-error TS(7031): Binding element 'totalMonthlyPayment' implicitly h... Remove this comment to see the full error message
  totalMonthlyPayment,
  // @ts-expect-error TS(7031): Binding element 'payOffDate' implicitly has an 'an... Remove this comment to see the full error message
  payOffDate,
  // @ts-expect-error TS(7031): Binding element 'totalBalance' implicitly has an '... Remove this comment to see the full error message
  totalBalance,
  // @ts-expect-error TS(7031): Binding element 'totalPrincipalBalance' implicitly... Remove this comment to see the full error message
  totalPrincipalBalance,
}) => {
  return trackDebtData({
    eventData: {
      interaction_type: 'complete',
      number_of_debts: debts.length,
      // @ts-expect-error TS(7006): Parameter 'debt' implicitly has an 'any' type.
      outcome_debt_types: debts.map(debt => debt.label).join(','),
      outcome_debt_balance: parseInt(totalPrincipalBalance),
      outcome_debt_interest: parseInt(totalInterestPayments),
      outcome_debt_monthly_payments: totalMonthlyPayment,
      outcome_debt_free_by_date: payOffDate,
      outcome_debt_total: parseInt(totalBalance),
    },
    userProperties: {
      debt_calculator_total_debts: debts.length,
      debt_calculator_monthly_payments: totalMonthlyPayment,
      debt_calculator_balance: parseInt(totalPrincipalBalance),
      debt_calculator_total_interest: parseInt(totalInterestPayments),
      debt_calculator_total_balance: parseInt(totalBalance),
      debt_calculator_debt_free_date: payOffDate,
      // @ts-expect-error TS(7006): Parameter 'debt' implicitly has an 'any' type.
      debt_calculator_debt_types: debts.map(debt => debt.typeOfDebt).join(','),
    },
  });
};

export const trackExportLink = ({
  // @ts-expect-error TS(7031): Binding element 'debts' implicitly has an 'any' ty... Remove this comment to see the full error message
  debts,
  // @ts-expect-error TS(7031): Binding element 'totalBalance' implicitly has an '... Remove this comment to see the full error message
  totalBalance,
  // @ts-expect-error TS(7031): Binding element 'totalInterestPayments' implicitly... Remove this comment to see the full error message
  totalInterestPayments,
  // @ts-expect-error TS(7031): Binding element 'totalMonthlyPayment' implicitly h... Remove this comment to see the full error message
  totalMonthlyPayment,
  // @ts-expect-error TS(7031): Binding element 'payOffDate' implicitly has an 'an... Remove this comment to see the full error message
  payOffDate,
  // @ts-expect-error TS(7031): Binding element 'totalPrincipalBalance' implicitly... Remove this comment to see the full error message
  totalPrincipalBalance,
}) => {
  // @ts-expect-error TS(2345): Argument of type '{ eventData: { interaction_type:... Remove this comment to see the full error message
  return trackDebtData({
    eventData: {
      interaction_type: 'export',
      // @ts-expect-error TS(7006): Parameter 'debt' implicitly has an 'any' type.
      outcome_debt_types: debts.map(debt => debt.label).join(','),
      outcome_debt_balance: parseInt(totalPrincipalBalance),
      outcome_debt_total_balance: parseInt(totalBalance),
      outcome_debt_interest: totalInterestPayments,
      outcome_debt_monthly_payment: totalMonthlyPayment,
      outcome_debt_free_by_date: payOffDate,
    },
  });
};
