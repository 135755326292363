import { useState } from 'react';

import { Button, SelectionSet, MuiTextInput } from '@hometap/htco-components';

import image_desktop from 'images/equity-planner/renovations/renovation-goals.png';
import { getRenovationGoalOptions } from './data/renovationUtils';
import Insight from 'components/insight/Insight';
import { scrollIntoViewByElementId } from 'utils/domScripts';

// logic could be cleaned up in this component by moving to the controller but because it is behind
// a feature flag, trying to keep logic contained here.

const renovationGoalButtonId = 'renovation-goal-continue-button-id';

const insightText =
  'Before starting any renovation, take a moment to assess your goals. Think about how this change could affect your quality of life right now and in the future.';

// @ts-expect-error TS(7031): Binding element 'onSelect' implicitly has an 'any'... Remove this comment to see the full error message
const EquityRenovationGoals = ({ onSelect, showOtherGoalQuestion, setShowOtherGoalQuestion }) => {
  const [selectedGoal, setSelectedGoal] = useState('');
  const [otherText, setOtherText] = useState('');

  if (selectedGoal) scrollIntoViewByElementId(renovationGoalButtonId);

  const onGoalSelection = () => {
    if (selectedGoal === 'other') {
      setShowOtherGoalQuestion(true);
    } else {
      onSelect(selectedGoal);
    }
  };

  const onOtherSubmit = () => {
    onSelect(selectedGoal, otherText);
  };

  return (
    <div className="EquityRenovationSelector">
      <div className="EquityRenovationSelectorRenovationTypes">
        <h3>What do you hope to achieve by renovating?</h3>
        <div className="EquityRenovationSelectionSetContainer">
          {showOtherGoalQuestion ? (
            <MuiTextInput
              label="Other (You tell us!)"
              value={otherText}
              maxLength={255}
              onChange={setOtherText}
              type="text"
              width="90%"
            />
          ) : (
            <SelectionSet
              name="renovationType"
              value={selectedGoal}
              labelWidth="100%"
              labelGrow={false}
              options={getRenovationGoalOptions()}
              // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
              onChange={value => {
                setSelectedGoal(value);
              }}
            />
          )}
        </div>
        <Button
          id={renovationGoalButtonId}
          className="EquityRenovationSelectorContinue"
          onClick={showOtherGoalQuestion ? onOtherSubmit : onGoalSelection}
        >
          Continue
        </Button>
        <Insight text={insightText} />
      </div>
      <div className="EquityRenovationGoalImgContainer">
        <img src={image_desktop} className="EquityRenovationGoalImg" alt="People planning" width="100%" />
      </div>
    </div>
  );
};

export default EquityRenovationGoals;
