import { Outlet } from 'react-router-dom';
import Header from 'components/Headers/Header/Header';

const CommonLayout = () => {
  return (
    <>
      {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
      <Header />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default CommonLayout;
