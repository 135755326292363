import { useMemo, useState } from 'react';

import { Button, Grid, Modal, MuiSelect, MuiTextInput, useForm } from '@hometap/htco-components';
import cx from 'classnames';
import { useNavigate } from 'react-router';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import useGooglePlaceInput from '../../../hooks/useGooglePlaceInput';
import useStates from '../../../hooks/useStates';
import { useAddPropertyMutation, useCurrentHome } from 'hooks/useCurrentHome';
import { getNewDashboardUrl } from 'utils/links';

import './AddNewPropertyModal.scss';
import { AxiosError } from 'axios';

export const ADD_NEW_HOME_ADDRESS_FIELDS = {
  ZIP_CODE: 'zip_code',
  CITY: 'city',
  STREET: 'street',
  STATE: 'state',
  UNIT: 'unit',
};

type ADDRESS_ERROR = 'Address already exists for this user' | 'You have reached your maximum number of homes';

const ADD_PROPERTY_ERROR_MAP = {
  'Address already exists for this user': 'Home already added to Home Equity Dashboard',
  'You have reached your maximum number of homes': 'You have reached your maximum number of homes.',
};

// @ts-expect-error TS(7031): Binding element 'errorMessage' implicitly has an '... Remove this comment to see the full error message
const AddPropertyError = ({ errorMessage }) => (
  // @ts-expect-error TS(2741): Property 'fullWidth' is missing in type '{ childre... Remove this comment to see the full error message
  <ErrorMessage className="AddNewPropertyErrorMessage">{errorMessage}</ErrorMessage>
);

// @ts-expect-error TS(7031): Binding element 'setIsOpen' implicitly has an 'any... Remove this comment to see the full error message
const AddNewPropertyModal = ({ setIsOpen }) => {
  const navigate = useNavigate();
  const [isManualAddressForm, setIsManualAddressForm] = useState(false);
  const { statesForDropdown } = useStates();

  // @ts-expect-error TS(2339): Property 'setHomeById' does not exist on type '{ h... Remove this comment to see the full error message
  const { setHomeById } = useCurrentHome();
  // @ts-expect-error TS(2339): Property 'isLoading' does not exist on type 'UseMu... Remove this comment to see the full error message
  const { isLoading, mutateAsync, error } = useAddPropertyMutation();
  const errorMessage = useMemo(() => {
    const axiosError = error as AxiosError<{ address: ADDRESS_ERROR }>;
    return axiosError?.response ? ADD_PROPERTY_ERROR_MAP[axiosError.response.data.address] : '';
  }, [error]);

  const { registerField, isFormValid, handleSubmit, updateFormData, formData } = useForm();

  // @ts-expect-error TS(7006): Parameter 'address' implicitly has an 'any' type.
  const onAddress = (address, addressObj) => {
    updateFormData({ address, ...addressObj });
  };

  // @ts-expect-error Object literal may only specify known properties, and 'onAddress' does not exist in type '{ flags?: never[] | undefined; }'.
  const { ref, isValidGoogleAddress } = useGooglePlaceInput({ onAddress });

  const isValidPropertyAddress = formData.address && isValidGoogleAddress;

  const handleModalClose = () => {
    const resetFields = Object.values(ADD_NEW_HOME_ADDRESS_FIELDS).reduce((acc, field) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[field] = '';
      return acc;
    }, {});
    updateFormData(resetFields);
    setIsOpen(false);
  };

  const handleAddProperty = async () => {
    try {
      const data = await mutateAsync({ address: formData });
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'AxiosResponse<any>'.
      setHomeById(data.id);
      handleModalClose();
      navigate(getNewDashboardUrl());
    } catch {
      /** Ignore the error as its handled in the errorMessage */
    }
  };

  return (
    <Modal
      className="AddNewPropertyModal"
      width={718}
      // Hack to allow the correct ref, for the google places api, to be present when this component is mounted. Parents
      // are responsible for conditionally rendering the Modal
      open={true}
      onClose={() => {
        handleModalClose();
        setIsManualAddressForm(false);
      }}
    >
      <div className={cx('AddAddressModalContent', { isManualAddressForm })}>
        {isManualAddressForm ? (
          <>
            <h1>Add home to dashboard</h1>
            <form onSubmit={handleSubmit(handleAddProperty)} className="AddManualAddressModalForm">
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid xs={12} sm={6}>
                  <MuiTextInput
                    name="street"
                    label="Street address"
                    maxLength={50}
                    width="100%"
                    required
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.STREET)}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <MuiTextInput
                    name="unit"
                    label="Unit (optional)"
                    maxLength={50}
                    width="100%"
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.UNIT)}
                  />
                </Grid>
                <Grid xs={12} sm={12}>
                  <MuiTextInput
                    name="city"
                    label="City"
                    maxLength={50}
                    width="100%"
                    required
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.CITY)}
                  />
                </Grid>
                <Grid xs={12} sm={6} className="AddManualAddressModalState">
                  <MuiSelect
                    name="state"
                    label="State"
                    options={statesForDropdown}
                    width="100%"
                    required
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.STATE)}
                  />
                </Grid>
                <Grid xs={12} sm={6} className="AddManualAddressModalZip">
                  <MuiTextInput
                    name="zip_code"
                    label="ZIP code"
                    maxLength={5}
                    required
                    mask="00000"
                    width="100%"
                    // @ts-expect-error TS(7006): Parameter 'val' implicitly has an 'any' type.
                    validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
                    {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.ZIP_CODE)}
                  />
                </Grid>
              </Grid>

              <Button
                className="AddManualAddressModalSubmit"
                disabled={!isFormValid || isLoading}
                width="100%"
                type="submit"
                loading={isLoading}
              >
                Add home
              </Button>
            </form>
          </>
        ) : (
          <>
            <h1>Add home to dashboard</h1>
            <div className="AddNewPropertyModalFields">
              <MuiTextInput
                label="Address"
                width="100%"
                name="address"
                ref={ref}
                value={formData.address}
                // @ts-expect-error TS(7006): Parameter 'address' implicitly has an 'any' type.
                onChange={address => updateFormData({ address })}
              />
              <MuiTextInput
                label="Unit (optional)"
                maxLength={50}
                name="unit"
                // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
                onChange={value => updateFormData({ unit: value }, ADD_NEW_HOME_ADDRESS_FIELDS.UNIT)}
                {...registerField(ADD_NEW_HOME_ADDRESS_FIELDS.UNIT)}
              />
            </div>
            <div className="AddNewPropertyButtonContainer">
              <Button
                onClick={handleAddProperty}
                width="100%"
                disabled={!isValidPropertyAddress || isLoading}
                loading={isLoading}
              >
                Add home
              </Button>
              <Button
                data-testid="manually-enter-new-property"
                theme="link"
                onClick={() => setIsManualAddressForm(true)}
              >
                Enter address manually
              </Button>
            </div>
          </>
        )}
        {errorMessage && <AddPropertyError errorMessage={errorMessage} />}
      </div>
    </Modal>
  );
};

export default AddNewPropertyModal;
