import { compact, range } from 'lodash';
import { currency } from 'utils/numbers';
import { formatInlineAddress } from 'utils/address';

export const INVESTMENT_TERM_UNIT = {
  MONTHS: 'months',
  YEARS: 'years',
};

// @ts-expect-error TS(7006): Parameter 'indicator' implicitly has an 'any' type... Remove this comment to see the full error message
export const getIcon = indicator => {
  const indicatorMap = {
    high: 'high-appreciation',
    medium: 'normal-appreciation',
    'no-change': 'no-change',
    low: 'large-decline',
    custom: 'custom',
    decline: 'large-decline',
  };
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return indicatorMap[indicator];
};

// @ts-expect-error TS(7006): Parameter 'rundown' implicitly has an 'any' type.
export const formatDataForCSV = (rundown, scenarios = [], includeInvestmentAmount = false) => {
  const showMortgage = rundown.show_and_use_mortgage_info;
  let headerContent = [
    ['Prepared for', rundown.description],
    ['Address', formatInlineAddress(rundown.address).replace(' |', ',')],
    ['Investment amount', currency(rundown.amount_requested)],
    [],
    ['Effective period', `${rundown.term_years} years`],
    ['Current home value', currency(rundown.home_value)],
    [],
  ];

  const tableHeader = ['Scenario', 'Length of investment', 'Home value', 'Hometap share', 'Your share'];

  if (showMortgage) {
    headerContent = headerContent.concat([
      ['Current mortgage balance', currency(rundown.current_mortgage_balance)],
      ['Mortgage balance at origination', currency(rundown.original_mortgage_balance)],
      ['Mortgage term length', currency(rundown.mortgage_term)],
      ['Mortgage rate', currency(rundown.mortgage_rate)],
      [],
    ]);
    tableHeader.splice(3, 0, 'Mortgage Balance');
  }

  if (includeInvestmentAmount) {
    tableHeader.splice(1, 0, 'Investment');
  }

  headerContent.push(tableHeader);
  headerContent = headerContent.map(row => {
    const fillers = tableHeader.length - row.length;
    return row.concat(range(fillers).map(() => ''));
  });

  const scenarioContent = scenarios.map(scenario => {
    const result = [
      // @ts-expect-error TS(2339): Property 'description' does not exist on type 'nev... Remove this comment to see the full error message
      scenario.description,
      // @ts-expect-error TS(2339): Property 'effective_period' does not exist on type... Remove this comment to see the full error message
      `${scenario.effective_period.length} ${scenario.effective_period.unit}`,
      // @ts-expect-error TS(2339): Property 'appreciated_home_value' does not exist o... Remove this comment to see the full error message
      currency(scenario.appreciated_home_value),
      // @ts-expect-error TS(2339): Property 'final_mortgage_balance' does not exist o... Remove this comment to see the full error message
      showMortgage && currency(scenario.final_mortgage_balance),
      // @ts-expect-error TS(2339): Property 'hometap_share' does not exist on type 'n... Remove this comment to see the full error message
      currency(scenario.hometap_share.dollars),
      // @ts-expect-error TS(2339): Property 'homeowner_share' does not exist on type ... Remove this comment to see the full error message
      currency(scenario.homeowner_share.dollars),
    ];
    if (includeInvestmentAmount) {
      // @ts-expect-error TS(2339): Property 'investment_amount' does not exist on typ... Remove this comment to see the full error message
      result.splice(1, 0, currency(scenario.investment_amount));
    }
    return compact(result);
  });

  return headerContent.concat(scenarioContent);
};
