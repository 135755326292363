import apiWithAuth from 'utils/api';

// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const fetchTrackRundowns = async trackId => {
  return await apiWithAuth.v1.get(`/tracks/${trackId}/rundowns/`);
};

// @ts-expect-error TS(7006): Parameter 'trackId' implicitly has an 'any' type.
export const fetchTrackTasks = trackId => {
  return apiWithAuth.v2.get(`tasks`, { params: { track_ids: [trackId], status: ['in_process', 'not_started'] } });
};
